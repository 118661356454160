export const TicketContentStatusLabels = {
  draft: '下書き',
  preview: '下書き',
  open: '公開',
  limited_open: '限定公開',
  close: '販売停止',
  sold: '売り切れ',
  expired: '期限切れ',
} as const

const TicketStatuses = {
  Inactive: 'inactive',
  Active: 'active',
  Used: 'used',
  Expired: 'expired',
  DailyUsed: 'daily_used',
  MonthlyUsed: 'monthly_used',
} as const

export const TicketStatusColors = {
  [TicketStatuses.Inactive]: '#ff9933',
  [TicketStatuses.Active]: '#888',
  [TicketStatuses.Used]: '#00ddb4',
  [TicketStatuses.Expired]: '#000',
  [TicketStatuses.DailyUsed]: '#00ddb4',
  [TicketStatuses.MonthlyUsed]: '#00ddb4',
} as const

export const TicketStatusLabels = {
  [TicketStatuses.Inactive]: '利用不可',
  [TicketStatuses.Active]: '利用可能',
  [TicketStatuses.Used]: '利用済み',
  [TicketStatuses.Expired]: '期限切れ',
  [TicketStatuses.DailyUsed]: '本日利用済み',
  [TicketStatuses.MonthlyUsed]: '本月利用済み',
} as const
