import React from 'react'
import { Alert } from 'react-bootstrap'
import { MembersCardStatusType } from '../../../containers/entities/MembersCardContentEntityContainer'

type Props = {
  status: MembersCardStatusType
}

const message = (status: MembersCardStatusType) => {
  switch (status) {
    case 'open':
      return '現在会員カードは公開されています。'
    case 'draft':
      return '会員カードはまだ公開されていません。'
    case 'close':
      return '現在会員カードは非公開になっています。'
    default:
      return ''
  }
}

const StatusAlert = ({ status }: Props): JSX.Element => (
  <Alert bsStyle={status === 'open' ? 'success' : 'warning'}>
    {message(status)}
  </Alert>
)

export default StatusAlert
