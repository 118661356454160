import React from 'react'
import ValueRangeFormItem from '../parts/ValueRangeFormItem'
import DateOptionFormItem from '../parts/DateOptionFormItem'
import { Condition, ConditionDiff } from '../../type'

type Props = {
  condition: Condition
  onChange: (diff: ConditionDiff) => void
}

const VisitCountCondition = ({ condition, onChange }: Props): JSX.Element => {
  return (
    <>
      <ValueRangeFormItem
        label='来店回数'
        unit='回'
        zeroLabel='来店していない'
        from={condition.value_from}
        to={condition.value_to}
        onChange={(value_from, value_to) => onChange({ value_from, value_to })}
      />

      <DateOptionFormItem
        style={{ marginTop: 8 }}
        condition={condition}
        label='期間'
        noneLabel='すべての期間'
        onChange={(
          scope_from,
          scope_to,
          relative_beginning_scope,
          relative_end_scope,
          time_from,
          time_to,
        ) => {
          onChange({
            scope_from,
            scope_to,
            relative_beginning_scope,
            relative_end_scope,
            time_from,
            time_to,
          })
        }}
      />
    </>
  )
}

export default VisitCountCondition
