import { useCallback, useEffect, useRef } from 'react'
import { useApi } from '../../lib/hooks'
import { TargetUserConditionResource } from '../../types/resource/targetUserCondition'
import { ConditionGroup } from '../../components/UserGroup/type'
import { TargetUserCondition } from '../../components/TargetUser'
import { useContentInstanceFetcher } from '../../components/UserGroup/hooks'
import { convertConditionGroupFrom } from '../../components/UserGroup/utils'
import { TargetGroupType, TargetUserConditionParams } from './type.d'

export const useTargetUserConditionResourceConverter = () => {
  const { api } = useApi()

  return async (
    targetUserCondition: TargetUserCondition,
  ): Promise<TargetUserConditionResource | null> => {
    let userGroupIds = targetUserCondition.userGroupIds
    if (
      userGroupIds.length === 0 &&
      targetUserCondition.conditionGroups.length > 0
    ) {
      const response = await api.post('/user_groups/bulk', {
        condition_groups: targetUserCondition.conditionGroups.map(
          ({ conditions }) => conditions,
        ),
      })
      userGroupIds =
        response?.data.user_groups.map(({ user_group }) => user_group.id) || []
    }

    return {
      user_group_collection_id:
        targetUserCondition.userGroupCollectionId || null,
      user_ids: targetUserCondition.userIds,
      user_group_ids: userGroupIds,
    }
  }
}

export const useTargetUserConditionEffect = (
  resource: TargetUserConditionResource | null,
  callback: (fetchedTargetUserCondition: TargetUserCondition) => void,
): boolean => {
  const { fetchAndSetInstance, loading: fetching } = useContentInstanceFetcher()
  const { api, loading } = useApi()
  const alreadyFetchedRef = useRef(false)

  const fetchUserGroups = useCallback(
    async (
      resource: TargetUserConditionResource,
    ): Promise<TargetUserCondition> => {
      let conditionGroups: ConditionGroup[] = []
      if (
        resource.user_group_collection_id === null &&
        resource.user_group_ids.length > 0
      ) {
        const promises = resource.user_group_ids.map((userGroupId) =>
          api.get(`/user_groups/${userGroupId}`),
        )
        const responses = await Promise.all(promises)
        conditionGroups = responses
          .filter((response) => response)
          .map((response) =>
            convertConditionGroupFrom(
              response.data.id,
              response.data.user_group_conditions,
            ),
          )
        await fetchAndSetInstance(conditionGroups)
      }

      const params = {
        userGroupCollectionId: resource.user_group_collection_id,
        userIds: resource.user_ids,
        conditionGroups,
      }

      return {
        targetGroup: calculateTargetGroup(params),
        userGroupIds: resource.user_group_ids,
        ...params,
      }
    },
    [api, fetchAndSetInstance],
  )

  useEffect(() => {
    if (!alreadyFetchedRef.current && resource !== null) {
      fetchUserGroups(resource).then(callback)
      alreadyFetchedRef.current = true
    }
  }, [resource, fetchUserGroups, callback])

  return loading || fetching
}

const calculateTargetGroup = (
  params: TargetUserConditionParams,
): TargetGroupType => {
  if (params.userGroupCollectionId) {
    return 'collections'
  }

  if ((params.userIds || []).length > 0) {
    return 'specific_user'
  }

  if ((params.conditionGroups || []).length > 0) {
    return 'conditions'
  }

  return 'all'
}

export const DefaultTargetUserCondition = {
  targetGroup: 'all',
  conditionGroups: [],
  userIds: [],
  userGroupIds: [],
  userGroupCollectionId: null,
} as TargetUserCondition
