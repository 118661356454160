import React, { FC, memo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { Card } from '../../../components/Card/Card'
import LoadingButton from '../../../components/CustomButton/LoadingButton'
import SwitchForm from '../../../components/FormInputs/SwitchForm'
import {
  AutoCouponStatusType,
  CouponStatus,
} from '../../../containers/entities/AutoCouponContentEntityContainer'
import { CouponContent } from '../../../containers/entities/CouponContentEntityContainer'

type Props = {
  coupon: CouponContent
  onClickAutoCouponContentPublishButton: (
    newStatus: AutoCouponStatusType,
  ) => Promise<void>
  editMode: boolean
}

const AutoCouponFormActiveSettingCard: FC<Props> = ({
  coupon,
  onClickAutoCouponContentPublishButton,
  editMode,
}) => {
  const { setValue, watch } = useFormContext<{
    coupon: CouponContent & { image: string }
  }>()

  const watchStatus = watch('coupon.status') as AutoCouponStatusType

  return (
    <Card
      title='有効設定'
      content={
        <>
          <Row>
            <Col md={12}>
              <SwitchForm
                checkedText='有効'
                uncheckedText='無効'
                checked={watchStatus === CouponStatus.Publish}
                onChange={(value: boolean): void => {
                  const status = value
                    ? CouponStatus.Publish
                    : CouponStatus.Unpublished
                  setValue('coupon.status', status, {
                    shouldDirty: true,
                  })
                }}
              />
            </Col>
          </Row>

          {editMode && (
            <AutoCouponFormActiveSettingCardButtonGroup
              coupon={coupon}
              watchStatus={watchStatus}
              onClickAutoCouponContentPublishButton={
                onClickAutoCouponContentPublishButton
              }
            />
          )}
        </>
      }
    />
  )
}

export default AutoCouponFormActiveSettingCard

type AutoCouponFormActiveSettingCardButtonGroupButtonGroupProps = Pick<
  Props,
  'onClickAutoCouponContentPublishButton' | 'coupon'
> & { watchStatus: AutoCouponStatusType }

const AutoCouponFormActiveSettingCardButtonGroup: FC<AutoCouponFormActiveSettingCardButtonGroupButtonGroupProps> =
  memo(({ watchStatus, onClickAutoCouponContentPublishButton, coupon }) => (
    <>
      <Row
        style={{
          marginRight: 0,
          display: 'flex',
          justifyContent: 'flex-end',
          flexWrap: 'wrap',
        }}>
        <Col
          style={{
            marginLeft: 10,
            marginTop: 10,
          }}>
          <LoadingButton
            label='変更する'
            loadingLabel='変更中...'
            color='info'
            fill
            pullRight
            loadingColor='red'
            disabled={coupon?.status === watchStatus}
            onClick={async (): Promise<void> => {
              await onClickAutoCouponContentPublishButton(watchStatus)
            }}
          />
        </Col>
      </Row>
    </>
  ))
