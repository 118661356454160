/* eslint-disable import/prefer-default-export */
import apiClient from './apiClient'
import { loadViewObj } from '../viewObj'

export const getMenus = async () => {
  const GET_MENUS = `/staff/stores/${loadViewObj()?.id}/menus`

  const response = await apiClient({
    method: 'GET',
    uri: GET_MENUS,
  })
  return response.data
}

export const getMenu = async (id: number) => {
  const GET_MENU = `/staff/stores/${loadViewObj()?.id}/menus/${id}`

  const response = await apiClient({
    method: 'GET',
    uri: GET_MENU,
  })
  return response.data
}

export const createMenu = async ({ params }) => {
  const CREATE_MENU = `/staff/stores/${loadViewObj()?.id}/menus`

  const requestParams = {
    menu: params,
  }

  const response = await apiClient({
    method: 'POST',
    uri: CREATE_MENU,
    params: requestParams,
  })
  return response.data
}

export const updateMenu = async ({ id, params }) => {
  const UPDATE_MENU = `/staff/stores/${loadViewObj()?.id}/menus/${id}`

  const requestParams = {
    menu: params,
  }

  const response = await apiClient({
    method: 'PUT',
    uri: UPDATE_MENU,
    params: requestParams,
  })
  return response.data
}

export const deleteMenu = async ({ id }) => {
  const DELETE_MENUS = `/staff/stores/${loadViewObj()?.id}/menus/${id}`

  const response = await apiClient({ method: 'DELETE', uri: DELETE_MENUS })
  return response.data
}
