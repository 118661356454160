import React, { ReactNode, useState, useEffect } from 'react'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

type Props = {
  disabled?: boolean
  show: boolean
  submitButtonLabel?: string
  onSubmit: () => void
  onCancel: () => void
  displayStatus?: 'info' | 'danger'
  danger?: boolean
  title?: string
  children: ReactNode
  needAgreement?: boolean
  agreementLabel?: string
}

const FormChangedAlertModal = ({
  disabled,
  show,
  submitButtonLabel = '変更を保存する',
  onSubmit,
  onCancel,
  displayStatus = 'info',
  danger,
  title,
  children,
  needAgreement = false,
  agreementLabel = '上記の内容を理解しました',
}: Props): JSX.Element => {
  const [isAgreed, setAgreed] = useState(false)
  const canSubmit = !needAgreement || isAgreed
  const color = displayStatus === 'danger' || danger ? 'danger' : 'submit'

  useEffect(() => {
    if (show) {
      setAgreed(false)
    }
  }, [show])

  return (
    <Dialog fullWidth open={show} onClose={onCancel} maxWidth='sm'>
      {Boolean(title) && (
        <StyledDialogTitle>
          {title}
          <i onClick={onCancel} className='ri-close-line' />
        </StyledDialogTitle>
      )}

      <DialogContent>
        {children}

        {needAgreement && (
          <FormControlLabel
            label={agreementLabel}
            sx={{ mt: 1 }}
            control={
              <Checkbox
                checked={isAgreed}
                onChange={(e) => setAgreed(e.target.checked)}
              />
            }
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button
          fullWidth
          variant='outlined'
          color='cancel'
          disabled={disabled}
          onClick={onCancel}>
          キャンセル
        </Button>

        <Button
          fullWidth
          variant='contained'
          color={color}
          disabled={disabled || !canSubmit}
          onClick={onSubmit}>
          {submitButtonLabel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
  '& > i': {
    cursor: 'pointer',
  },
})

export default FormChangedAlertModal
