import { useCallback, useState } from 'react'
import { createContainer } from 'unstated-next'
import { ticketApi, ticketContentsApi } from '../../lib/api'

export type TicketContent = {
  id: number
  title: string
  body: string
  image_url: string
  created_at: string
  price: number
  usable_count: number
  status: TicketContentStatus
  expiration_month: number | null
  expiration_from: string | null
  expiration_to: string | null
  daily_usable_count: number | null
  monthly_usable_count: number | null
  limit_quantity: number | null
  limited_open_url: string | null
  ticket_amount: number | null
  ticket_feature_id: number | null
  face_price: number | null
  ticket_sales_calc_way: 'by_use'
  enabled_auto_publish_condition: boolean
  enabled_target_user_condition: boolean
  target_user_condition?: TicketContentTargetUserCondition | null
}

type TicketContentTargetUserCondition = {
  age_from: number | null
  age_to: number | null
  birth_month: number | null
  coupon_content_id: number | null
  created_at: string
  gender: 'male' | 'female' | 'other' | null
  id: number
  notice_content_id: number | null
  rank: number | null
  rank_ids: number[]
  updated_at: string
  user_group_collection_id: number
  user_group_ids: number[]
  user_ids: number[]
  users_count: number
}

export type TikcetSnapshot = {
  id: number
  s3_url: string
  snapshot_at: string
}

export const ticketContentStatus = {
  Draft: 'draft',
  Preview: 'preview',
  Open: 'open',
  LimitedOpen: 'limited_open',
  Close: 'close',
  Sold: 'sold',
  Expired: 'expired',
} as const

export type TicketContentStatus =
  (typeof ticketContentStatus)[keyof typeof ticketContentStatus]

const statuses: TicketContentStatus[] = [
  'draft',
  'preview',
  'open',
  'limited_open',
  'close',
  'sold',
  'expired',
]
const statusesToJpObj = {
  draft: '下書き',
  preview: '下書き',
  open: '公開',
  limited_open: '限定公開',
  close: '販売停止',
  sold: '売り切れ',
  expired: '期限切れ',
}

const useTicketContentEntityHook = () => {
  const [ticketContents, setTicketContents] = useState<TicketContent[]>([])
  const [ticketSnapshots, setTicketSnapshots] = useState<TikcetSnapshot[]>([])

  const updateTicketContentsState = useCallback((newTicketContent) => {
    setTicketContents((prevTicketContents) =>
      prevTicketContents.map((ticketContent) =>
        ticketContent.id === newTicketContent.id
          ? newTicketContent
          : ticketContent,
      ),
    )
  }, [])

  const addTicketContentState = useCallback((newTicketContent) => {
    setTicketContents((prevTicketContents) => [
      newTicketContent,
      ...prevTicketContents,
    ])
  }, [])

  const deleteTicketContentState = useCallback((deleteNoticeId) => {
    setTicketContents((prevTicketContents) =>
      prevTicketContents.filter(
        (ticketContent) => ticketContent.id !== deleteNoticeId,
      ),
    )
  }, [])

  return {
    state: { ticketContents, ticketSnapshots },
    constant: { statuses },
    logic: {
      getTicketContents: useCallback(async (): Promise<TicketContent[]> => {
        const newTicketContents: TicketContent[] =
          await ticketContentsApi.getTicketContents()
        setTicketContents(newTicketContents)
        return newTicketContents
      }, []),
      getTicketContent: async (id: number): Promise<TicketContent> => {
        const newTicketContent = await ticketContentsApi.getTicketContent(id)
        return newTicketContent
      },
      createTicketContent: async (params): Promise<TicketContent> => {
        const res = await ticketContentsApi.createTicketContent({
          params: params,
        })
        const ticketContent = res.data
        addTicketContentState(ticketContent)
        return ticketContent
      },

      updateTicketContent: async (id, params): Promise<TicketContent> => {
        const res = await ticketContentsApi.updateTicketContent({
          id: id,
          params: params,
        })
        const ticketContent = res.data
        updateTicketContentsState(ticketContent)
        return ticketContent
      },
      deleteTicketContent: async (id): Promise<TicketContent> => {
        const res = await ticketContentsApi.deleteTicketContent(id)
        const ticketContent = res.data
        deleteTicketContentState(ticketContent)
        return ticketContent
      },
      getTicketSnapshots: useCallback(async (): Promise<TikcetSnapshot[]> => {
        const newSnapshots: TikcetSnapshot[] =
          await ticketApi.getTikcetSnapshots()
        setTicketSnapshots(newSnapshots)
        return newSnapshots
      }, []),
      downloadTicketsCsv: async (): Promise<void> => {
        await ticketApi.downloadTicketsCsv()
      },
      downloadTicketsCsvbyS3: async (url, filename): Promise<void> => {
        await ticketApi.downloadTicketsCsvbyS3(url, filename)
      },
      statusToJp: (status: TicketContentStatus): string =>
        statusesToJpObj[status],
    },
  }
}

export default createContainer(useTicketContentEntityHook)
