import * as firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'

firebase.initializeApp({
  apiKey:
    process.env.REACT_APP_FIREBASE_API_KEY ||
    'AIzaSyBEZ6Poi0Bifbh2LDZvkoDazHa8B_4F1uA',
  authDomain:
    process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ||
    'toypo-store-debug.firebaseapp.com',
  databaseURL:
    process.env.REACT_APP_FIREBASE_DATABASE_URL ||
    'https://toypo-store-debug.firebaseio.com',
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || 'toypo-store-debug',
  storageBucket:
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ||
    'toypo-store-debug.appspot.com',
  messagingSenderId:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '1096472809878',
  appId:
    process.env.REACT_APP_FIREBASE_APP_ID ||
    '1:1096472809878:web:0101d88843d12d41d1cdb7',
  measurementId:
    process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || 'G-GEK3Z4QDWE',
})

export default firebase
