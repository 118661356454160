import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import moment from 'moment'
import * as amplitude from '@amplitude/analytics-browser'
import ScrollToTop from './ScrollToTop'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/animate.min.css'
import './assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0'
import './assets/css/demo.css'
import './assets/css/pe-icon-7-stroke.css'
import ToypoApp from './components/ToypoApp/ToypoApp'
import IntegrationProvider from './IntegrationProvider'
import GoogleAnalytics from './components/GoogleAnalytics'
import { WEEK_OF_DAYS_LABEL } from './lib/general'

amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY)
moment.locale('ja', { weekdays: WEEK_OF_DAYS_LABEL })

ReactDOM.render(
  <IntegrationProvider>
    <BrowserRouter>
      <ScrollToTop>
        <ToypoApp />
      </ScrollToTop>
      <GoogleAnalytics />
    </BrowserRouter>
  </IntegrationProvider>,
  document.getElementById('root'),
)
