import React, { useState } from 'react'
import LoadingButton from '../../../components/CustomButton/LoadingButton'
import StaffRoleModal from './EditStaffRoleModal'
import StoreStaffEntityContainer from '../../../containers/entities/StoreStaffEntityContainer'
import OwnerStaffEntityContainer from '../../../containers/entities/OwnerStaffEntityContainer'
import { useLoginContext } from '../../../providers/LoginContextProvider'
import { useNotification } from '../../../providers/NotificationProvider'
import { StaffBlock } from '../../../types/staff'

type Props = {
  show: boolean
  staff: StaffBlock
  onHide: () => void
  onDeleted: () => void
}
const StaffRoleModalRemoveWrapper = ({
  show,
  staff,
  onHide,
  onDeleted,
}: Props): JSX.Element => {
  const { showSuccessNotification, showErrorNotification } = useNotification()
  const { currentStore, currentStaff } = useLoginContext()
  const storeStaffContainer = StoreStaffEntityContainer.useContainer()
  const { removeAuthFromStaff } = storeStaffContainer.logic
  const ownerStaffContainer = OwnerStaffEntityContainer.useContainer()
  const { removeOwnerFromStaff } = ownerStaffContainer.logic
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const { groups, stores } = staff
  const id = staff.staff.id
  const ownerGroup = groups?.find((g) => g.store_type === 'owner_group')
  const storeIds = stores.map((store) => store.id)
  const nonGrantedOwnerGroup = currentStaff.is_owner ? ownerGroup : null
  const grantedGroups = currentStaff.is_owner
    ? groups.filter((group) => group.store_type !== 'owner_group')
    : groups.filter((group) => group.id === currentStore.id)
  const grantedStores =
    currentStore.store_type !== 'child'
      ? currentStore.child_stores.filter((store) => storeIds.includes(store.id))
      : stores

  const selectStores = (newSelectedId: number) => {
    const newIds = selectedIds.includes(newSelectedId)
      ? selectedIds.filter((selectedId) => selectedId !== newSelectedId)
      : selectedIds.concat(newSelectedId)
    setSelectedIds(newIds)
  }

  const submitButton = (
    <LoadingButton
      type='submit'
      label='削除する'
      loadingLabel='削除中...'
      color='danger'
      fill
      block
      disabled={selectedIds.length === 0}
      onClick={async () => {
        if (!window.confirm('権限を削除しますか？')) {
          return
        }

        const ownerGroupId = ownerGroup?.id ?? 0
        const authStoreIds = selectedIds.filter(
          (selectedId) => selectedId !== ownerGroupId,
        )

        const removeAuth = async () => {
          if (selectedIds.includes(ownerGroupId)) {
            await removeOwnerFromStaff(id)
          }
          if (authStoreIds.length > 0) {
            await removeAuthFromStaff(authStoreIds, id, currentStore.id)
          }
        }

        removeAuth()
          .then(() => {
            showSuccessNotification('権限を削除しました。')
            onHide()
          })
          .catch((error) => showErrorNotification(error.response.data.message))
          .finally(() => {
            onDeleted() // Owner, Store権限削除の一方が成功・失敗してもリロードが必要
            setSelectedIds([])
          })
      }}
    />
  )

  return (
    // grantedStoresがnullのときはOwner
    // Ownerのときはグループを表示する，店舗は紐付いているものをそのまま
    // Groupのときはグループを表示する，店舗はグループ内で所属している店舗(grantedStores)のみ
    <StaffRoleModal
      show={show}
      onHide={onHide}
      stores={grantedStores ?? stores}
      groups={grantedGroups ?? groups}
      ownerGroup={nonGrantedOwnerGroup}
      submitButton={submitButton}
      onClickRow={(newSelectId: number) => selectStores(newSelectId)}
      selectedIds={selectedIds}
    />
  )
}

export default StaffRoleModalRemoveWrapper
