import React, { useEffect, useState, useCallback } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { useLocation, useHistory } from 'react-router-dom'
import { useNotification } from '../../../providers/NotificationProvider'
import Section from '../../../components/Section'
import DisablingMask from '../../../components/DisablingMask'
import ListEmptyContainer from '../../../components/ListEmptyContainer'
import {
  useQuery,
  useApi,
  useMobileFlag,
  useBooleanState,
} from '../../../lib/hooks'
import {
  MenusResponse,
  MenuResource,
  MenuType,
  MenuStatus,
} from '../../../types/menu'
import { MenuStatusLabel, MenuTypeLabels } from '../../../constants/menu'
import MenuStatusChangeModal from './MenuStatusChangeModal'
import LaptopMenuList from './LaptopMenuList'
import MobileMenuList from './MobileMenuList'

type Props = {
  title: string
  menuTypes: MenuType[]
  onClickMenu: (menu: MenuResource) => void
}

const MenuListTable = ({
  title,
  menuTypes,
  onClickMenu,
}: Props): JSX.Element => {
  const isMobile = useMobileFlag()
  const { showSuccessNotification } = useNotification()
  const {
    changeUrlAndFetchMenus,
    loading,
    loaded,
    queryParams,
    menus,
    reloadMenus,
  } = useMenus(menuTypes)
  const updateApi = useApi()
  const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
  const [isStatusDialogOpen, openStatusDialog, closeStatusDialog] =
    useBooleanState(false)
  const [selectedMenu, setSelectedMenu] = useState<MenuResource | null>(null)

  const menuHandler = (fn: () => void) => () => {
    fn()
    setAnchorElement(null)
  }

  const changeMenuStatus = async (
    menuId: number,
    status: MenuStatus,
  ): Promise<void> => {
    if (!window.confirm(`${MenuStatusLabel[status]}に変更しますか？`)) {
      return
    }

    const response = await updateApi.api.put(`/menus/${menuId}`, { status })
    if (response === null) return

    reloadMenus()
    showSuccessNotification('状態を変更しました。')
  }

  const deleteMenu = async (menuId: number) => {
    if (!window.confirm('メニューを削除しますか？')) {
      return
    }

    const response = await updateApi.api.delete(`/menus/${menuId}`)
    if (response === null) return

    reloadMenus()
    showSuccessNotification('メニューを削除しました。')
  }

  return (
    <>
      <Section title={title}>
        {menuTypes.length > 1 && (
          <Tabs
            variant='scrollable'
            scrollButtons='auto'
            value={queryParams.menu_type}
            onChange={(_, tab: MenuType) =>
              changeUrlAndFetchMenus({ menu_type: tab })
            }>
            {menuTypes.map((menuType) => (
              <Tab
                key={menuType}
                value={menuType}
                label={MenuTypeLabels[menuType]}
                disabled={loading}
              />
            ))}
          </Tabs>
        )}

        {loaded && menus.length > 0 && (
          <DisablingMask mask={loading}>
            {!isMobile ? (
              <LaptopMenuList
                menus={menus}
                disabled={loading}
                onClickMenu={onClickMenu}
                onClickMore={(e, menu) => {
                  setAnchorElement(e.currentTarget)
                  setSelectedMenu(menu)
                }}
                onClickStatus={(menu) => {
                  setSelectedMenu(menu)
                  openStatusDialog()
                }}
              />
            ) : (
              <MobileMenuList
                menus={menus}
                disabled={loading}
                onClickMenu={onClickMenu}
                onClickMore={(e, menu) => {
                  setAnchorElement(e.currentTarget)
                  setSelectedMenu(menu)
                }}
              />
            )}
          </DisablingMask>
        )}

        {loaded && menus.length === 0 && (
          <ListEmptyContainer icon={<i className='ri-file-copy-2-line' />}>
            メニューがありません
          </ListEmptyContainer>
        )}
      </Section>

      {selectedMenu && (
        <Menu
          anchorEl={anchorElement}
          open={Boolean(anchorElement)}
          onClose={() => setAnchorElement(null)}>
          <MenuItem onClick={menuHandler(() => onClickMenu(selectedMenu))}>
            詳細を見る
          </MenuItem>
          <MenuItem onClick={menuHandler(() => openStatusDialog())}>
            状態を変更する
          </MenuItem>

          {selectedMenu.status === 'draft' && (
            <MenuItem
              sx={{ color: '#FF4A55' }}
              onClick={menuHandler(() => deleteMenu(selectedMenu.id))}>
              削除する
            </MenuItem>
          )}
        </Menu>
      )}

      {selectedMenu && (
        <MenuStatusChangeModal
          defaultStatus={selectedMenu.status}
          open={isStatusDialogOpen}
          onClose={closeStatusDialog}
          onSubmit={(status) => {
            closeStatusDialog()
            changeMenuStatus(selectedMenu.id, status)
          }}
          disabled={loading}
        />
      )}
    </>
  )
}

export type QueryParams = {
  menu_type: MenuType
}

const useMenus = (menuTypes: MenuType[]) => {
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()
  const tabFromQuery = query.get('menu_type') as MenuType
  const { api, loading, response, loaded } = useApi<MenusResponse>()
  const [queryParams, setQueryParams] = useState<QueryParams>({
    menu_type: menuTypes.includes(tabFromQuery) ? tabFromQuery : menuTypes[0],
  })

  const changeUrl = useCallback(
    (params: QueryParams) => {
      setQueryParams(params)

      const searchParams = new URLSearchParams()
      searchParams.append('menu_type', params.menu_type)

      const newUrl = `${location.pathname}?${searchParams.toString()}`
      history.replace(newUrl)
    },
    [history, location.pathname],
  )

  const fetchMenus = useCallback(() => {
    api.get('/menus', { menu_type: queryParams.menu_type })
  }, [api, queryParams])

  useEffect(() => {
    fetchMenus()
  }, [fetchMenus])

  return {
    changeUrlAndFetchMenus: changeUrl,
    loading,
    loaded,
    queryParams,
    menus: response || [],
    reloadMenus: fetchMenus,
  }
}

export default MenuListTable
