import React, { useEffect } from 'react'
import { styled } from '@mui/system'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import { useApi, useMobileFlag } from '../../../lib/hooks'
import { RestaurantSatisfactionAverageResponse } from '../../../types/questionnaire'
import {
  RatingLabels,
  RestaurantSatisfactionSectionLabel,
} from '../../../constants/questionnaire'
import { RestaurantSatisfactionAnswerQueryParams } from '../type'
import Rating from './Rating'

type Props = {
  questionnaireId: number
  queryParams?: RestaurantSatisfactionAnswerQueryParams
}

const RestaurantSatisfactionAnswerAverage = ({
  questionnaireId,
  queryParams,
}: Props): JSX.Element => {
  const isMobile = useMobileFlag()
  const { api, response } = useApi<RestaurantSatisfactionAverageResponse>()
  const { average_rating, total_answer_count, counts_by_total_quality_label } =
    response || {}
  const averageRating = {
    total_quality: average_rating?.total_quality ?? 0,
    taste_of_food: average_rating?.taste_of_food ?? 0,
    amount_of_food: average_rating?.amount_of_food ?? 0,
    variety_of_food: average_rating?.variety_of_food ?? 0,
    customer_service: average_rating?.customer_service ?? 0,
    speed_of_delivery: average_rating?.speed_of_delivery ?? 0,
    price_of_food: average_rating?.price_of_food ?? 0,
    cleanliness_of_store: average_rating?.cleanliness_of_store ?? 0,
    ambience_of_store: average_rating?.ambience_of_store ?? 0,
  }
  const totalAnswerCount = total_answer_count || 0
  const findCountOf = (label: string) =>
    counts_by_total_quality_label?.find((v) => v.label === label)?.count ?? 0
  const countsByLabel = Object.entries(RatingLabels)
    .sort(([rating1], [rating2]) => (rating1 < rating2 ? 1 : -1))
    .map(([, label]) => ({ label, count: findCountOf(label) }))

  useEffect(() => {
    const sectionsCondition: { item_ids: number[] }[] = []
    if (
      queryParams?.totalQualityIds &&
      queryParams.totalQualityIds.length > 0
    ) {
      sectionsCondition.push({ item_ids: queryParams.totalQualityIds })
    }
    if (queryParams?.orderItemIds && queryParams.orderItemIds.length > 0) {
      sectionsCondition.push({ item_ids: queryParams.orderItemIds })
    }

    const params = {
      answer_store_ids: queryParams?.answerStoreIds,
      date_from: queryParams?.dateFrom,
      date_to: queryParams?.dateTo,
      rank_id: queryParams?.rankId,
      gender: queryParams?.gender,
      age_from: queryParams?.ageFrom,
      age_to: queryParams?.ageTo,
      sections: sectionsCondition,
    }

    api.post(
      `/restaurant_satisfaction_questionnaires/${questionnaireId}/averages`,
      params,
    )
  }, [api, questionnaireId, queryParams])

  return (
    <Box display='flex' flexDirection={isMobile ? 'column' : 'row'} gap={2}>
      <Box
        display='flex'
        flexDirection='column'
        alignItems={isMobile ? 'center' : 'flex-start'}>
        <Box fontSize={16} fontWeight={700}>
          総合満足度 平均
        </Box>

        <Box
          display='flex'
          alignItems={isMobile ? 'center' : 'flex-start'}
          flexDirection={isMobile ? 'row' : 'column'}
          gap={isMobile ? 1 : 0}>
          <Box fontSize={32} fontWeight={700}>
            {averageRating.total_quality > 0
              ? averageRating.total_quality.toFixed(1)
              : '-'}
          </Box>

          <Rating
            size={28}
            value={Number(averageRating.total_quality.toFixed(1))}
            good={Math.trunc(averageRating.total_quality) >= 3}
          />
        </Box>

        <Box fontSize={16}>
          <strong>{totalAnswerCount}</strong> 件の回答
        </Box>
      </Box>

      <Box flex={1} display='flex' flexDirection='column' gap={1}>
        {countsByLabel.map((item) => (
          <Box
            key={item.label}
            display='flex'
            alignItems='center'
            gap={isMobile ? 1 : 2}>
            <Box flexBasis={65} minWidth={0}>
              {item.label}
            </Box>
            <Box color='#FECD00' flex={1}>
              <StyledLinearProgress
                variant='determinate'
                value={
                  totalAnswerCount > 0
                    ? (item.count / totalAnswerCount) * 100
                    : 0
                }
                color='inherit'
              />
            </Box>
            <Box whiteSpace='nowrap'>{item.count || '--'} 件</Box>
          </Box>
        ))}
      </Box>

      <Grid container spacing={isMobile ? 1 : 2} columnSpacing={2} flex={1}>
        {Object.keys(averageRating)
          .filter((section) => section !== 'total_quality')
          .map((section) => (
            <Grid
              key={section}
              item
              xs={6}
              display='flex'
              justifyContent='space-between'>
              <Box fontWeight={700} color='#555'>
                {RestaurantSatisfactionSectionLabel[section]}
              </Box>
              <StyledAverageRating rating={Math.trunc(averageRating[section])}>
                {averageRating[section] > 0
                  ? averageRating[section].toFixed(1)
                  : '--'}
              </StyledAverageRating>
            </Grid>
          ))}
      </Grid>
    </Box>
  )
}

const StyledLinearProgress = styled(LinearProgress)({
  height: 8,
  borderRadius: 5,
})

const StyledAverageRating = styled('div', {
  shouldForwardProp: (prop: string) => 'rating' !== prop,
})<{ rating: number }>(({ rating }) => ({
  fontWeight: 'bold',
  color:
    rating === 0
      ? '#000'
      : rating <= 2
        ? '#E94C4C'
        : rating >= 4
          ? '#CFA909'
          : '#000',
}))

export default RestaurantSatisfactionAnswerAverage
