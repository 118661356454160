import React, { useCallback, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import moment from 'moment'
import { RouteComponentProps, withRouter } from 'react-router'
import {
  TabContainer,
  TabContent,
  TabPane,
  Nav,
  NavItem,
} from 'react-bootstrap'
import {
  UserActionSummaryType,
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from '../../types/common.d'
import { NoticeSummariesResponse } from '../../types/api/summary.d'
import { NoticeContentResponse } from '../../types/api/notice'
import { shouldMaskMeasurementValue } from '../../lib/general'
import { useApi, useGetApiCall, useQuery } from '../../lib/hooks'
import {
  NoticeStatus,
  NoticeStatusType,
  NoticeStatusColors,
  NoticeContentStatusToJpObj,
  AutoNoticeContentStatusToJpObj,
} from '../../containers/entities/NoticeEntityContainer'
import ContentSummaryCard from '../../components/Card/ContentSummaryCard'
import SummariesSection from '../../components/Utils/SummariesSection'
import ImageLoading from '../../components/Loading/ImageLoading'
import UserListSection from './parts/UserListSection'
import EditFormSection from './parts/NoticeContentForm'
import { useLoginContext } from '../../providers/LoginContextProvider'

const StyledStatusLabel = styled.div<{ status: NoticeStatusType }>`
  background-color: ${(props) => NoticeStatusColors[props.status]};
  font-size: 10px;
  display: inline;
  color: white;
  padding: 5px 6px;
  border-width: 0px;
  border-radius: 6px;
  font-weight: bold;
`

const StyledLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
`

const TABS = {
  Data: 'data',
  Users: 'users',
  Form: 'form',
} as const
type TabType = (typeof TABS)[keyof typeof TABS]

type Props = RouteComponentProps & {
  displayErrorNotification: DisplayErrorNotification
  displaySuccessNotification: DisplaySuccessNotification
}

const DEFAULT_SUMMARY_TYPE: UserActionSummaryType = 'read'

const NoticeDetailView = ({
  history,
  displayErrorNotification,
  displaySuccessNotification,
}: Props): JSX.Element | null => {
  const query = useQuery()
  const noticeContentId = Number(query.get('notice_content_id'))

  const [selectedTab, setSelectedTab] = useState<TabType>(TABS.Data)

  const url = `/notice_contents/${noticeContentId}`
  const { response: noticeContent } = useGetApiCall<NoticeContentResponse>(url)
  const { api: summaryApi, response: summary } =
    useApi<NoticeSummariesResponse>()

  const reloadSummary = useCallback(
    (type: UserActionSummaryType) => {
      const url = `/notice_contents/${noticeContentId}/summaries`
      return summaryApi.get(url, { type }, { showErrorNotification: false })
    },
    [summaryApi, noticeContentId],
  )

  const isAuto = Boolean(noticeContent?.enabled_auto_publish_condition)
  const isPublished = noticeContent?.status === NoticeStatus.Publish

  const { currentStore, hasPermission } = useLoginContext()
  const showDeliveredCount = currentStore.show_notice_delivered_count
  const showArrivalCount = currentStore.show_notice_arrival_count
  const showReadCount = currentStore.show_notice_read_count

  useEffect(() => {
    reloadSummary(DEFAULT_SUMMARY_TYPE)
  }, [reloadSummary])

  if (!noticeContentId) {
    history.goBack()
    return null
  }

  if (!noticeContent || !summary) {
    return (
      <StyledLoadingContainer>
        <ImageLoading />
      </StyledLoadingContainer>
    )
  }

  if (
    shouldMaskMeasurementValue(noticeContent.created_at, summary.read_count) ||
    (!isAuto && !isPublished)
  ) {
    return (
      <EditFormSection
        style={{ padding: '24px 12px' }}
        defaultNoticeContent={noticeContent}
        defaultTargetUserCondition={noticeContent.target_user_condition}
        isAuto={isAuto}
        displayErrorNotification={displayErrorNotification}
        displaySuccessNotification={displaySuccessNotification}
      />
    )
  }

  return (
    <div>
      {(showDeliveredCount || showArrivalCount || showReadCount) && (
        <ContentSummaryCard
          style={{ backgroundColor: 'white', padding: 12 }}
          imageUrl={noticeContent.image_urls[0]}
          title={noticeContent.title}
          label={
            <StyledStatusLabel status={noticeContent.status}>
              {isAuto
                ? AutoNoticeContentStatusToJpObj[noticeContent.status]
                : NoticeContentStatusToJpObj[noticeContent.status]}
            </StyledStatusLabel>
          }
          note={
            isAuto
              ? ''
              : `配信日: ${moment(noticeContent.publish_at).format('YYYY/M/D(dddd) HH:mm')}`
          }
          {...(showDeliveredCount && {
            deliveredCount: summary.delivered_count,
          })}
          {...(showArrivalCount && { arrivalCount: summary.arrival_count })}
          {...(showReadCount && { readCount: summary.read_count })}
          {...(showReadCount && { readCountDiff: summary.read_count_diff })}
          createdAt={noticeContent.created_at}
          memo={noticeContent.memo}
          hasAvailableArrivalCount={summary.has_available_arrival_count}
        />
      )}

      <TabContainer
        id='tabs'
        activeKey={selectedTab}
        onSelect={(tab) => setSelectedTab(tab as unknown as TabType)}>
        <>
          <Nav
            bsStyle='tabs'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: 'white',
            }}>
            <NavItem
              eventKey={TABS.Data}
              style={{ flex: 1, textAlign: 'center' }}>
              概要
            </NavItem>
            {hasPermission('END_POINT_LIST_NOTICE_USERS') && (
              <NavItem
                eventKey={TABS.Users}
                style={{ flex: 1, textAlign: 'center' }}>
                ユーザー
              </NavItem>
            )}
            <NavItem
              eventKey={TABS.Form}
              style={{ flex: 1, textAlign: 'center' }}>
              内容編集
            </NavItem>
          </Nav>
          <TabContent animation>
            <TabPane eventKey={TABS.Data}>
              <SummariesSection
                contentName='お知らせ'
                summaries={summary}
                defaultSummaryType={DEFAULT_SUMMARY_TYPE}
                onChangeListItem={(item) => reloadSummary(item)}
              />
            </TabPane>
            {hasPermission('END_POINT_LIST_NOTICE_USERS') && (
              <TabPane eventKey={TABS.Users}>
                <UserListSection
                  noticeContentId={noticeContentId}
                  defaultSummaryType={DEFAULT_SUMMARY_TYPE}
                  onClickCustomer={(customerId) => {
                    history.push(
                      `/admin/customers/detail?user_id=${customerId}`,
                    )
                  }}
                />
              </TabPane>
            )}
            <TabPane eventKey={TABS.Form}>
              <EditFormSection
                style={{ padding: '24px 12px' }}
                defaultNoticeContent={noticeContent}
                defaultTargetUserCondition={noticeContent.target_user_condition}
                isAuto={isAuto}
                isDelivered={summary.delivered_count !== 0}
                displayErrorNotification={displayErrorNotification}
                displaySuccessNotification={displaySuccessNotification}
              />
            </TabPane>
          </TabContent>
        </>
      </TabContainer>
    </div>
  )
}

export default withRouter(NoticeDetailView)
