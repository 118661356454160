import React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { MenuResource } from '../../../types/menu'
import MenuStatusChip from '../../../components/Chip/MenuStatusChip'

type Props = {
  menus: MenuResource[]
  disabled: boolean
  onClickMenu: (menu: MenuResource) => void
  onClickMore: (
    e: React.MouseEvent<HTMLButtonElement>,
    menu: MenuResource,
  ) => void
}

const MobileMenuList = ({
  menus,
  disabled,
  onClickMenu,
  onClickMore,
}: Props): JSX.Element => {
  return (
    <Table size='small'>
      <TableBody>
        {menus.map((menu) => (
          <TableRow
            key={menu.id}
            hover={!disabled}
            onClick={() => {
              if (!disabled) {
                onClickMenu(menu)
              }
            }}>
            <TableCell sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Box flex='1 0 0'>
                <Box display='flex' alignItems='center' gap={1} mb={1}>
                  {menu.image_url ? (
                    <StyledMenuImage alt='メニュー画像' src={menu.image_url} />
                  ) : (
                    <Box
                      width={IMAGE_WIDTH}
                      height={IMAGE_HEIGHT}
                      border='solid 1px #ddd'
                    />
                  )}

                  <Box fontWeight='bold' fontSize={14} flex='1 0 0'>
                    {menu.title}
                  </Box>
                </Box>

                <Box color='#525252'>
                  カテゴリー: {menu.category_label}
                  <br />
                  値段: {menu.price || 0}円
                </Box>
              </Box>

              <Box fontSize={12}>
                <MenuStatusChip status={menu.status} />
              </Box>

              <IconButton
                sx={{ lineHeight: 1 }}
                size='small'
                onClick={(e) => {
                  e.stopPropagation()
                  onClickMore(e, menu)
                }}>
                <i className='ri-more-fill' />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

const IMAGE_WIDTH = 48
const IMAGE_HEIGHT = 30
const StyledMenuImage = styled('img')({
  width: IMAGE_WIDTH,
  height: IMAGE_HEIGHT,
  borderRadius: 2,
  objectFit: 'cover',
})

export default MobileMenuList
