import React from 'react'
import { Table } from 'react-bootstrap'
import { SubscriptionContentBlock } from '../../../types/api/subscription.d'
import {
  SubscriptionContentResource,
  SubscriptionContentStatus,
} from '../../../types/resource/subscriptionContent.d'
import { SortOrderType } from '../../../types/common.d'
import { SubscriptionSortByType } from '../../../types/subscription.d'
import SortIconTh from '../../../components/Table/SortIconTh'
import SubscriptionContentStatusChip from '../../../components/Chip/SubscriptionContentStatusChip'

interface Props {
  subscriptionContents: SubscriptionContentBlock[]
  onClickRow: (subscriptionContent: SubscriptionContentResource) => void
  onClickStatusLabel: (id: number, status: SubscriptionContentStatus) => void
  onSort: (sortBy: SubscriptionSortByType, sortOrder: SortOrderType) => void
  sortBy: SubscriptionSortByType
  sortOrder: SortOrderType
}

const SubscriptionListLargeTable = ({
  subscriptionContents,
  onClickRow,
  onClickStatusLabel,
  onSort,
  sortBy,
  sortOrder,
}: Props): JSX.Element => (
  <Table striped hover>
    <thead>
      <tr>
        <th> </th>
        <th>タイトル</th>
        <SortIconTh
          sortOrder={sortBy === 'price' ? sortOrder : null}
          onChangeSortOrder={(newSortOrder) => onSort('price', newSortOrder)}>
          金額
        </SortIconTh>
        <SortIconTh
          sortOrder={sortBy === 'expiration_month' ? sortOrder : null}
          onChangeSortOrder={(newSortOrder) =>
            onSort('expiration_month', newSortOrder)
          }>
          期間
        </SortIconTh>
        <SortIconTh
          sortOrder={sortBy === 'subscribed_count' ? sortOrder : null}
          onChangeSortOrder={(newSortOrder) =>
            onSort('subscribed_count', newSortOrder)
          }>
          契約数
        </SortIconTh>
        <SortIconTh
          sortOrder={
            sortBy === 'used_count_average_per_term' ? sortOrder : null
          }
          onChangeSortOrder={(newSortOrder) =>
            onSort('used_count_average_per_term', newSortOrder)
          }>
          平均利用回数
        </SortIconTh>
        <SortIconTh
          sortOrder={sortBy === 'retention_rate' ? sortOrder : null}
          onChangeSortOrder={(newSortOrder) =>
            onSort('retention_rate', newSortOrder)
          }>
          継続率
        </SortIconTh>
        <SortIconTh
          sortOrder={sortBy === 'status' ? sortOrder : null}
          onChangeSortOrder={(newSortOrder) => onSort('status', newSortOrder)}>
          状態
        </SortIconTh>
      </tr>
    </thead>
    <tbody>
      {subscriptionContents.map(
        ({
          subscription_content,
          subscribed_count,
          retention_rate,
          used_count_average_per_term,
        }) => (
          <tr
            style={{ cursor: 'pointer' }}
            onClick={() => onClickRow(subscription_content)}>
            <td style={{ width: 48 }}>
              <img
                alt='サブスク画像'
                src={subscription_content.image_url}
                width={48}
                height={30}
                style={{ borderRadius: 2, objectFit: 'cover' }}
              />
            </td>
            <td style={{ wordBreak: 'break-all', fontWeight: 'bold' }}>
              {subscription_content.title}
            </td>
            <td style={{ width: 80 }}>
              {subscription_content.price.toLocaleString()}円
            </td>
            <td
              style={{
                width: 80,
              }}>{`${subscription_content.expiration_month}ヶ月`}</td>
            <td style={{ width: 80 }}>{subscribed_count.toLocaleString()}人</td>
            <td style={{ width: 110 }}>
              {Math.round(used_count_average_per_term * 10) / 10}回
            </td>
            <td style={{ width: 80 }}>
              {retention_rate === null
                ? '---'
                : `${Math.round(retention_rate * 1000) / 10}%`}
            </td>
            <td style={{ width: 100 }}>
              <SubscriptionContentStatusChip
                data-cy={`subscription-list-status-label-${subscription_content.id}`}
                status={subscription_content.status}
                onClick={(e) => {
                  onClickStatusLabel(
                    subscription_content.id,
                    subscription_content.status,
                  )
                  e.stopPropagation()
                }}
              />
            </td>
          </tr>
        ),
      )}
    </tbody>
  </Table>
)

export default SubscriptionListLargeTable
