import React, { CSSProperties, ReactNode } from 'react'
import { styled } from '@mui/material/styles'

type Props = {
  style?: CSSProperties
  footer?: ReactNode
  children: ReactNode
}

const MobileFrame = ({ style = {}, footer, children }: Props): JSX.Element => {
  return (
    <StyledContainer style={style}>
      <StyledHeaderContainer>
        <StyledHeaderMark />

        <StyledHeader>
          <StyledPreviewTitle>プレビュー</StyledPreviewTitle>

          <StyledPreviewNote>
            実際の表示と異なる場合があります
          </StyledPreviewNote>
        </StyledHeader>
      </StyledHeaderContainer>

      <StyledContentContainer>{children}</StyledContentContainer>

      {footer}
    </StyledContainer>
  )
}

// 外側の黒い枠部分を除いたディスプレイ部分の幅
export const FRAME_INNER_WIDTH = 284

// このコンポーネントの幅と高さ
export const FRAME_WIDTH = 300
export const FRAME_HEIGHT = 620

const StyledContainer = styled('div')({
  width: FRAME_WIDTH,
  height: FRAME_HEIGHT,
  border: 'solid 8px #000',
  borderRadius: 18,
  background: '#fff',
  paddingBottom: 8,
  display: 'flex',
  flexDirection: 'column',
})

const StyledHeaderContainer = styled('div')({
  backgroundColor: '#000',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})
const StyledHeaderMark = styled('div')({
  width: 40,
  height: 8,
  backgroundColor: '#444',
  borderRadius: 4,
})
const StyledHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 8,
  width: '100%',
})
const StyledPreviewTitle = styled('div')({
  fontWeight: 700,
  color: '#eee',
  fontSize: 14,
})
const StyledPreviewNote = styled('div')({
  color: '#eee',
  fontSize: 11,
})

const StyledContentContainer = styled('div')(({ theme }) => ({
  flex: 1,
  overflow: 'scroll',
  ...theme.mixins.hiddenScrollbar,
}))

export default MobileFrame
