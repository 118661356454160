import React from 'react'
import { Table } from 'react-bootstrap'
import SmallTableBody from '../../../components/Table/SmallTableBody'
import { SubscriptionContentBlock } from '../../../types/api/subscription.d'
import {
  SubscriptionContentResource,
  SubscriptionContentStatus,
} from '../../../types/resource/subscriptionContent.d'
import SubscriptionContentStatusChip from '../../../components/Chip/SubscriptionContentStatusChip'

interface Props {
  subscriptionContents: SubscriptionContentBlock[]
  onClickRow: (subscriptionContent: SubscriptionContentResource) => void
  onClickStatusLabel: (id: number, status: SubscriptionContentStatus) => void
  loading: boolean
}

const SubscriptionListSmallTable = ({
  subscriptionContents,
  onClickRow,
  onClickStatusLabel,
  loading,
}: Props): JSX.Element => (
  <Table striped hover>
    <tbody>
      {subscriptionContents.map(({ subscription_content }) => (
        <tr key={subscription_content.id}>
          <SmallTableBody
            image={subscription_content.image_url}
            title={subscription_content.title}
            contents={[
              `有効期限: ${subscription_content.expiration_month}ヶ月`,
            ]}
            status={
              <SubscriptionContentStatusChip
                status={subscription_content.status}
              />
            }
            button={
              <button
                type='button'
                className='editStatus'
                onClick={() =>
                  onClickStatusLabel(
                    subscription_content.id,
                    subscription_content.status,
                  )
                }
                disabled={loading || subscription_content.status === 'sold'}>
                <i
                  className='pe-7s-angle-up'
                  style={{ fontSize: 18, margin: 0 }}
                />
              </button>
            }
            goTo={() => onClickRow(subscription_content)}
          />
        </tr>
      ))}
    </tbody>
  </Table>
)

export default SubscriptionListSmallTable
