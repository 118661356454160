import React from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'

type Item = {
  value: string
  label: string
}
type Props = {
  fullWidth?: boolean
  items: Item[]
  selectedValues: string[]
  onChange: (selectedValues: string[]) => void
}

const MultipleSelectForm = ({
  fullWidth,
  items,
  selectedValues,
  onChange,
}: Props): JSX.Element => {
  const findItem = (value: string) => items.find((v) => v.value === value)

  return (
    <Autocomplete
      fullWidth={fullWidth}
      multiple
      disableCloseOnSelect
      size='small'
      value={selectedValues}
      options={items.map((item) => item.value)}
      getOptionLabel={(option) => findItem(option)?.label ?? ''}
      onChange={(_, values) => onChange(values)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={null}
          variant='outlined'
          placeholder='選択してください'
        />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props} style={{ padding: '0 8px' }}>
          <Checkbox checked={selected} />
          {findItem(option)?.label}
        </li>
      )}
    />
  )
}

export default MultipleSelectForm
