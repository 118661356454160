import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Prompt } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormHelperText from '@mui/material/FormHelperText'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Section from '../../../components/Section'
import FormLabel from '../../../components/FormInputs/FormLabel'
import { useBooleanState, useMobileFlag } from '../../../lib/hooks'
import { OrderFeatureResource } from '../../../types/order'
import DeliveryMethodDialog from './DeliveryMethodDialog'
import DeliveryFeeDialog from './DeliveryFeeDialog'

export type DeliverySettingsFormType = {
  delivery_order_duration: number
  delivery_order_duration_on_busy: number
  delivery_duration: number
  delivery_duration_on_busy: number
  delivery_minimum_order_price: number
  delivery_area_radius_m: number
}

type Props = {
  orderFeature: OrderFeatureResource
  disabled: boolean
  onSubmit: (params: Partial<OrderFeatureResource>) => void
}

const DeliverySettingsForm = ({
  orderFeature,
  disabled,
  onSubmit,
}: Props): JSX.Element => {
  const isMobile = useMobileFlag()
  const defaultValues = generateDefaultValues(orderFeature)
  const { control, handleSubmit, errors, formState, reset } =
    useForm<DeliverySettingsFormType>({ defaultValues })
  const { isDirty } = formState
  const [
    isDeliveryMethodDialogOpen,
    openDeliveryMethodDialog,
    closeDeliveryMethodDialog,
  ] = useBooleanState(false)
  const [
    isDeliveryFeeDialogOpen,
    openDeliveryFeeDialog,
    closeDeliveryFeeDialog,
  ] = useBooleanState(false)
  const uberDirectEnabled = orderFeature.uber_direct_status === 'active'

  useEffect(() => {
    reset(generateDefaultValues(orderFeature))
  }, [reset, orderFeature])

  return (
    <>
      {orderFeature.uber_direct_status !== 'unapproved' && (
        <Section title='配達方法'>
          <FormLabel label='配達方法' />
          <Box display='flex' alignItems='center'>
            <Box fontWeight={700} fontSize={15}>
              {uberDirectEnabled
                ? 'UberEats配達パートナーによる配達'
                : '自店舗配達員による配達'}
            </Box>

            <Button
              variant='contained'
              size='small'
              color='submit'
              disabled={disabled}
              sx={{ ml: 2 }}
              onClick={openDeliveryMethodDialog}>
              {isMobile ? '変更する' : '配達方法を変更する'}
            </Button>
          </Box>

          <DeliveryMethodDialog
            open={isDeliveryMethodDialogOpen}
            defaultValues={{
              uber_direct_status: orderFeature.uber_direct_status,
            }}
            disabled={disabled}
            onClose={closeDeliveryMethodDialog}
            onSubmit={(params) => {
              onSubmit(params)
              closeDeliveryMethodDialog()
            }}
          />
        </Section>
      )}

      <Section title='配達料金'>
        {uberDirectEnabled &&
        orderFeature.uber_direct_delivery_fee_type === 'distance' ? (
          <FormLabel label='配達料金のユーザー負担割合' />
        ) : (
          <FormLabel label='配達料金(距離に関わらず固定)' />
        )}
        <Box mb={2} display='flex' alignItems='center'>
          {uberDirectEnabled &&
          orderFeature.uber_direct_delivery_fee_type === 'distance' ? (
            <Box>
              <strong style={{ fontSize: 16 }}>
                {orderFeature.uber_direct_delivery_distance_fee_rate * 100}
              </strong>{' '}
              %
            </Box>
          ) : (
            <Box>
              <strong style={{ fontSize: 16 }}>
                {orderFeature.delivery_price}
              </strong>{' '}
              円
            </Box>
          )}

          <Button
            variant='contained'
            size='small'
            color='submit'
            disabled={disabled}
            sx={{ ml: 2 }}
            onClick={openDeliveryFeeDialog}>
            {isMobile ? '変更する' : '配達料金を変更する'}
          </Button>
        </Box>

        {uberDirectEnabled &&
          orderFeature.uber_direct_delivery_fee_type === 'distance' && (
            <>
              <Box fontSize={12} color='#666' mb={0.5}>
                UberEats配達パートナーによる配達の場合、配達料金は配達距離によって変化するため
                <br />
                その配達料のうち、ユーザーに負担してもらう配達料金の設定ができます。
              </Box>
              <Box fontSize={12} color='#666'>
                例：
                <br />
                UberEats配達料金が690円で、そのうち
                {orderFeature.uber_direct_delivery_distance_fee_rate * 100}
                %をユーザーに負担してもらう場合
                <br />
                ユーザーの負担額:{' '}
                {orderFeature.uber_direct_delivery_distance_fee_rate * 690}円
                <br />
                店舗の負担額:{' '}
                {690 -
                  orderFeature.uber_direct_delivery_distance_fee_rate * 690}
                円
              </Box>
            </>
          )}

        <DeliveryFeeDialog
          open={isDeliveryFeeDialogOpen}
          uberDirectEnabled={uberDirectEnabled}
          defaultValues={{
            uber_direct_delivery_fee_type:
              orderFeature.uber_direct_delivery_fee_type,
            uber_direct_delivery_distance_fee_rate:
              orderFeature.uber_direct_delivery_distance_fee_rate,
            delivery_price: orderFeature.delivery_price,
          }}
          disabled={disabled}
          onClose={closeDeliveryFeeDialog}
          onSubmit={(params) => {
            onSubmit(params)
            closeDeliveryFeeDialog()
          }}
        />
      </Section>

      <Section title='デリバリー基本設定'>
        <Box mb={3}>
          <FormLabel label='仕上がり目安時間(商品の調理や準備にかかる時間)' />
          <Box display='flex' flexWrap='wrap' gap={1}>
            <Box display='flex' alignItems='center'>
              通常時
              <Controller
                control={control}
                name='delivery_order_duration'
                render={({ onChange, value }) => (
                  <Select
                    value={value}
                    disabled={disabled}
                    sx={{ ml: 1, mr: 2, width: 90 }}
                    onChange={({ target }) => onChange(Number(target.value))}>
                    {DURATIONS.map((duration) => (
                      <MenuItem key={duration} value={duration}>
                        {duration}分
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>

            <Box display='flex' alignItems='center'>
              忙しい時
              <Controller
                control={control}
                name='delivery_order_duration_on_busy'
                render={({ onChange, value }) => (
                  <Select
                    value={value}
                    disabled={disabled}
                    sx={{ ml: 1, width: 90 }}
                    onChange={({ target }) => onChange(Number(target.value))}>
                    {DURATIONS.map((duration) => (
                      <MenuItem key={duration} value={duration}>
                        {duration}分
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>
          </Box>
        </Box>

        <Box mb={3} color={uberDirectEnabled ? '#aaa' : undefined}>
          <FormLabel
            label='配達目安時間(商品の配達にかかる時間)'
            style={{ color: uberDirectEnabled ? '#aaa' : undefined }}
          />
          <Box display='flex' flexWrap='wrap' gap={1}>
            <Box display='flex' alignItems='center'>
              通常時
              <Controller
                control={control}
                name='delivery_duration'
                render={({ onChange, value }) => (
                  <Select
                    value={uberDirectEnabled ? '---' : value}
                    disabled={uberDirectEnabled || disabled}
                    sx={{ ml: 1, mr: 2, width: 90 }}
                    onChange={({ target }) => onChange(Number(target.value))}>
                    {uberDirectEnabled && <MenuItem value='---'>---</MenuItem>}
                    {DURATIONS.map((duration) => (
                      <MenuItem key={duration} value={duration}>
                        {duration}分
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>

            <Box display='flex' alignItems='center'>
              忙しい時
              <Controller
                control={control}
                name='delivery_duration_on_busy'
                render={({ onChange, value }) => (
                  <Select
                    value={uberDirectEnabled ? '---' : value}
                    disabled={uberDirectEnabled || disabled}
                    sx={{ ml: 1, width: 90 }}
                    onChange={({ target }) => onChange(Number(target.value))}>
                    {uberDirectEnabled && <MenuItem value='---'>---</MenuItem>}
                    {DURATIONS.map((duration) => (
                      <MenuItem key={duration} value={duration}>
                        {duration}分
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </Box>
          </Box>

          {uberDirectEnabled && (
            <FormHelperText>
              UberEats配達パートナーによる配達の場合、配達時間はUberEatsの配達状況に応じて変動します。
            </FormHelperText>
          )}
        </Box>

        <Box mb={2}>
          <FormLabel label='注文最低金額' />
          <Controller
            control={control}
            name='delivery_minimum_order_price'
            rules={{
              required: '注文最低金額を入力してください。',
              min: {
                value: 50,
                message: '注文最低金額は50円以上にしてください。',
              },
            }}
            render={({ onChange, value }) => (
              <OutlinedInput
                type='number'
                value={value}
                disabled={disabled}
                sx={{ mr: 1 }}
                onChange={({ target }) =>
                  onChange(
                    target.value === '' ? 1 : Math.max(1, Number(target.value)),
                  )
                }
              />
            )}
          />
          円
          {errors.delivery_minimum_order_price?.message && (
            <FormHelperText error>
              {errors.delivery_minimum_order_price?.message}
            </FormHelperText>
          )}
        </Box>

        <Box color={uberDirectEnabled ? '#aaa' : undefined}>
          {uberDirectEnabled ? (
            <FormLabel label='配達可能エリア' style={{ color: '#aaa' }} />
          ) : (
            <FormLabel label='配達可能エリア(店舗を中心とした半径)' />
          )}
          <Controller
            control={control}
            name='delivery_area_radius_m'
            rules={{
              required: '配達可能エリアを入力してください。',
              min: {
                value: 100,
                message: '配達可能エリアは100m未満には設定できません。',
              },
            }}
            render={({ onChange, value }) => (
              <OutlinedInput
                type={uberDirectEnabled ? 'text' : 'number'}
                value={uberDirectEnabled ? '---' : value}
                disabled={uberDirectEnabled || disabled}
                sx={{ mr: 1 }}
                onChange={({ target }) =>
                  onChange(Math.max(0, Number(target.value || 0)))
                }
              />
            )}
          />
          ｍ
          {errors.delivery_area_radius_m?.message && (
            <FormHelperText error>
              {errors.delivery_area_radius_m?.message}
            </FormHelperText>
          )}
          {uberDirectEnabled && (
            <FormHelperText>
              UberEats配達パートナーによる配達の場合、配達可能エリアはUberEatsの配達パートナーアサインの状況に応じて変動するため指定できません。
            </FormHelperText>
          )}
        </Box>
      </Section>

      <Box display='flex' justifyContent='center' mb={5}>
        <Button
          variant='contained'
          color='submit'
          disabled={disabled}
          sx={{ width: 300 }}
          onClick={handleSubmit(onSubmit)}>
          変更を保存する
        </Button>
      </Box>

      <Prompt
        when={isDirty}
        message='行った変更が保存されていない可能性があります。このページを離れますか？'
      />
    </>
  )
}

const generateDefaultValues = (
  orderFeature: OrderFeatureResource,
): DeliverySettingsFormType => ({
  delivery_order_duration: orderFeature.delivery_order_duration,
  delivery_order_duration_on_busy: orderFeature.delivery_order_duration_on_busy,
  delivery_duration: orderFeature.delivery_duration,
  delivery_duration_on_busy: orderFeature.delivery_duration_on_busy,
  delivery_minimum_order_price: orderFeature.delivery_minimum_order_price,
  delivery_area_radius_m: orderFeature.delivery_area_radius_m,
})

const DURATIONS = [...Array(24)].map((_, i) => (i + 1) * 5)

export default DeliverySettingsForm
