import React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import moment from 'moment'
import { RankingResource } from '../../../types/ranking'
import {
  RankingCategoryLabels,
  rankingProgressStatus,
} from '../../../constants/ranking'
import RankingStatusChip from '../../../components/Chip/RankingStatusChip'

type Props = {
  rankings: RankingResource[]
  onClickRanking: (id: number) => void
}

const LaptopRankingListTable = ({
  rankings,
  onClickRanking,
}: Props): JSX.Element => {
  return (
    <Table size='small'>
      <TableHead>
        <StyledHeaderRow>
          <TableCell width={100}>状態</TableCell>
          <TableCell>ランキング名</TableCell>
          <TableCell width={100}>種別</TableCell>
          <TableCell width={200}>ランキング期間</TableCell>
          <TableCell width={100}>ランキング計測対象</TableCell>
          <TableCell width={100}>特典</TableCell>
        </StyledHeaderRow>
      </TableHead>

      <TableBody>
        {rankings.map((ranking) => (
          <StyledBodyRow
            key={ranking.id}
            hover
            onClick={() => onClickRanking(ranking.id)}>
            <TableCell>
              <RankingStatusChip
                status={rankingProgressStatus(
                  ranking.status,
                  ranking.start_datetime,
                  ranking.end_datetime,
                )}
              />
            </TableCell>
            <TableCell>{ranking.name}</TableCell>
            <TableCell>
              {ranking.ranking_content.is_periodic_ranking
                ? '定期'
                : '期間限定'}
            </TableCell>
            <TableCell>
              {moment(ranking.start_datetime).format('YYYY/M/D HH:mm')}〜
              {moment(ranking.end_datetime).format('YYYY/M/D HH:mm')}
            </TableCell>
            <TableCell>{RankingCategoryLabels[ranking.category]}</TableCell>
            <TableCell>{ranking.rewards.length}件</TableCell>
          </StyledBodyRow>
        ))}
      </TableBody>
    </Table>
  )
}

const StyledHeaderRow = styled(TableRow)({
  '& > th': {
    whiteSpace: 'nowrap',
  },
})

const StyledBodyRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '& > td': {
    fontSize: 13,
    fontWeight: 700,
    padding: theme.spacing(3.5, 1),
  },
}))

export default LaptopRankingListTable
