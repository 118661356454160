import React from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

type Props = {
  years: number[]
  year: number | null
  month: number
  enableEmpty?: boolean
  disabled?: boolean
  onChange: (year: number | null, month: number | null) => void
}

const YearMonthForm = ({
  years,
  year,
  month,
  enableEmpty = false,
  disabled = false,
  onChange,
}: Props): JSX.Element => {
  return (
    <div>
      <Select
        sx={{ width: 130 }}
        value={String(year || 0)}
        disabled={disabled}
        onChange={(e) => {
          const newYear = Number(e.target.value)
          if (newYear === 0) {
            onChange(null, null)
          } else {
            onChange(newYear, month)
          }
        }}>
        {enableEmpty && <MenuItem value='0'>指定しない</MenuItem>}
        {years.map((year) => (
          <MenuItem key={year} value={year}>
            {year}年
          </MenuItem>
        ))}
      </Select>

      <Select
        sx={{ width: 90, ml: 1 }}
        value={String(month)}
        disabled={disabled || year === null}
        onChange={(e) => {
          const newMonth = Number(e.target.value)
          onChange(year!, newMonth)
        }}>
        {[...Array(12)].map((_, i) => (
          <MenuItem key={i} value={i + 1}>
            {i + 1}月
          </MenuItem>
        ))}
      </Select>
    </div>
  )
}

export default YearMonthForm
