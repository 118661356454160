import { AxiosResponse } from 'axios'
import apiClient from './apiClient'
import { loadViewObj } from '../viewObj'
import {
  NoticeContent,
  NoticeParamsInterface,
} from '../../containers/entities/NoticeEntityContainer'

interface Message {
  message: string
}

export const getNotice = async (id: number): Promise<NoticeContent> => {
  const GET_NOTICES = `/staff/stores/${loadViewObj()?.id}/notice_contents/${id}`

  const response: AxiosResponse<NoticeContent> = await apiClient({
    method: 'GET',
    uri: GET_NOTICES,
  })
  return response.data
}

export const createNotice = async ({
  params,
}: {
  params: NoticeParamsInterface
}): Promise<NoticeContent> => {
  const CREATE_NOTICE = `/staff/stores/${loadViewObj()?.id}/notice_contents`

  const requestParams = {
    notice_content: params,
  }

  const response: AxiosResponse<NoticeContent> = await apiClient({
    method: 'POST',
    uri: CREATE_NOTICE,
    params: requestParams,
  })
  return response.data
}

export const updateNotice = async ({
  id,
  params,
}: {
  id: number
  params: NoticeParamsInterface
}): Promise<NoticeContent> => {
  const UPDATE_NOTICE = `/staff/stores/${loadViewObj()?.id}/notice_contents/${id}`

  const requestParams = {
    notice_content: params,
  }

  const response: AxiosResponse<NoticeContent> = await apiClient({
    method: 'PATCH',
    uri: UPDATE_NOTICE,
    params: requestParams,
  })
  return response.data
}

export const publishNotice = async (id: number): Promise<NoticeContent> => {
  const PUBLISH_NOTICE = `/staff/stores/${loadViewObj()?.id}/notice_contents/${id}/notices`

  const response: AxiosResponse<NoticeContent> = await apiClient({
    method: 'POST',
    uri: PUBLISH_NOTICE,
    params: { status: 'publish' },
  })
  return response.data
}
