import React from 'react'
import { FormGroup } from 'react-bootstrap'

const CustomFormGroup = ({ children, style = {}, ...props }) => (
  <FormGroup style={{ marginBottom: 20, ...style }} {...props}>
    {children}
  </FormGroup>
)

export default CustomFormGroup
