import React, { useState, useEffect } from 'react'
import {
  Grid,
  Row,
  Col,
  Form,
  HelpBlock,
  FormGroup,
  Radio,
  Image,
} from 'react-bootstrap'
import {
  useForm,
  useWatch,
  FormProvider,
  useFormContext,
  Controller,
  SubmitHandler,
  SubmitErrorHandler,
} from 'react-hook-form'
import { Backdrop, CircularProgress } from '@mui/material'
import { Prompt } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'

import BannerEntityContainer, {
  BannerStatus,
  bannerSize,
  bannerSizes,
  BannerSize,
  bannerPosition,
  BannerPosition,
  bannerPositions,
  imageSize,
  sizeToJpObj,
  positionToJpObj,
  clickTypeToJpObj,
  clickTypes,
  ClickType,
  ResponseBannerData,
} from '../../containers/entities/BannerEntityContainer'

import { Card } from '../../components/Card/Card'
import Button from '../../components/CustomButton/CustomButton'
import LoadingButton from '../../components/CustomButton/LoadingButton'
import FormInputs from '../../components/FormInputs/FormInputs'
import ImageForm from '../../components/FormInputs/ImageForm'
import SelectorForm from '../../components/FormInputs/SelectorForm'
import FormLabel from '../../components/FormInputs/FormLabel'

import { getObjectDiff } from '../../lib/general'
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from '../../types/common.d'

import BannerFormPublishSettingCard from './parts/BannerFormPublishSettingCard'
import PreviewTopBannerImage from '../../assets/img/banners/position_top.png'
import PreviewCenterBannerImage from '../../assets/img/banners/position_center.png'
import PreviewBottomBannerImage from '../../assets/img/banners/position_bottom.png'

const previewBannerImages = {
  [bannerPosition.Top]: PreviewTopBannerImage,
  [bannerPosition.Center]: PreviewCenterBannerImage,
  [bannerPosition.Bottom]: PreviewBottomBannerImage,
} as const

type FormCoreData = {
  status?: BannerStatus
  image?: string
  title: string
  click_url?: string | null
  click_type: ClickType
  priority: number | null
  size: BannerSize
  position: BannerPosition
}

type FormData = {
  banner: FormCoreData
}

interface Props
  extends RouteComponentProps<
    {},
    {},
    { banner: ResponseBannerData; banners: Array<ResponseBannerData> }
  > {
  displaySuccessNotification: DisplaySuccessNotification
  displayErrorNotification: DisplayErrorNotification
}

const BannerFormViewWrapper: React.FC<Props> = ({
  location,
  history,
  displaySuccessNotification,
  displayErrorNotification,
}) => {
  const bannerEntityContainer = BannerEntityContainer.useContainer()

  const editMode = location.pathname.indexOf('edit') !== -1

  const goTo = {
    back: () => history.goBack(),
  }

  const banners = location.state?.banners
  // 変更前のデータを格納
  const originalBanner = location.state?.banner
  const bannerId = originalBanner?.id
  const defaultSize = bannerSize.Small
  const defaultPosition = bannerPosition.Center
  const defaultSizeAndPositionBannersNumber = banners.filter(
    ({ size, position }) =>
      size === defaultSize && position === defaultPosition,
  ).length
  const defaultPriority = defaultSizeAndPositionBannersNumber + 1

  const defaultBannerValues = {
    status: originalBanner?.status || 'close',
    title: originalBanner?.title,
    click_type: originalBanner?.click_type || 'url',
    click_url: originalBanner?.click_url,
    image: originalBanner?.image_url,
    priority: originalBanner?.priority || defaultPriority,
    size: originalBanner?.size || defaultSize,
    position: originalBanner?.position || defaultPosition,
  }

  const methods = useForm<FormData>({
    defaultValues: {
      banner: defaultBannerValues,
    },
  })

  const {
    register,
    unregister,
    setValue,
    formState: { dirtyFields },
    reset,
    trigger,
    getValues,
    control,
  } = methods

  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [isBackdropLoading, setIsBackdropLoading] = useState(false)

  useEffect(() => {
    register({ name: 'banner.size' })
    register({ name: 'banner.position' })

    return () => {
      unregister(['banner.size'])
      unregister(['banner.position'])
    }
  }, [register, unregister])

  const watchStatus = useWatch<BannerStatus>({
    control,
    name: 'banner.status',
    defaultValue: defaultBannerValues.status,
  })
  const watchSize = useWatch<BannerSize>({
    control,
    name: 'banner.size',
    defaultValue: defaultBannerValues.size,
  })
  const watchPosition = useWatch<BannerPosition>({
    control,
    name: 'banner.position',
    defaultValue: defaultBannerValues.position,
  })
  const watchClickType = useWatch<ClickType>({
    control,
    name: 'banner.click_type',
    defaultValue: defaultBannerValues.click_type,
  })

  const onClickDeleteButton = async (): Promise<void> => {
    if (!window.confirm('このバナーを削除しますか？')) {
      return
    }

    await bannerEntityContainer.logic
      .deleteBanner(bannerId)
      .then(() => {
        displaySuccessNotification('バナーを削除しました。')
        reset(getValues())
        goTo.back()
      })
      .catch((error) => {
        displayErrorNotification(error.message)
      })
  }

  const processFormData = (formBanner: FormCoreData) => {
    const processData = formBanner
    // imageを変更してない場合keyを削除
    if (processData.image === originalBanner?.image_url)
      delete processData.image

    // 編集時にstatus変更を削除（公開設定でのみ変更可能にするため）
    if (editMode) {
      delete processData.status
    }

    // 空文字対策 && 値が全て文字列になってる対策
    processData.priority = processData.priority
      ? Number(processData.priority)
      : null

    return formBanner
  }

  const updateBanner = async (data: FormData) => {
    const formData = processFormData(data.banner)
    const params = getObjectDiff(originalBanner, formData)

    // もしも、何も更新するものがない(paramsが空)ならreturn
    if (!Object.keys(params).length) {
      displayErrorNotification('値の変更がありません。')
      return
    }

    await bannerEntityContainer.logic
      .updateBanner(bannerId, params)
      .then((newBanner) => {
        displaySuccessNotification('編集しました。')
        reset({ banner: newBanner })
        goTo.back()
      })
      .catch((error) => {
        displayErrorNotification(error.message)
      })
  }

  const createBanner = async (data: FormData) => {
    const formData = data.banner

    await bannerEntityContainer.logic
      .createBanner(formData)
      .then((newBanner) => {
        reset({ banner: newBanner })
        goTo.back()
        displaySuccessNotification(`${newBanner.title}を作成しました。`)
      })
      .catch((error) => {
        displayErrorNotification(error.message)
      })
  }

  const onClickStatusUpdateButton = async () => {
    const isDirtyExceptStatus =
      Object.keys(dirtyFields.banner || {}).filter((key) => key !== 'status')
        .length > 0

    const isSave = isDirtyExceptStatus
      ? window.confirm(
          '行った変更が保存されていない可能性があります。変更内容を保存しますか？',
        )
      : false
    if (isSave && !(await trigger())) {
      displayErrorNotification('値が正しく入力されていません。')
      return
    }

    const isUpdateStatus = window.confirm(
      `${watchStatus === 'open' ? '公開' : '非公開'}にしますか？`,
    )

    if (!isSave && !isUpdateStatus) return

    const data = getValues()
    const params = isSave
      ? {
          ...getObjectDiff(originalBanner, processFormData(data.banner)),
          status: isUpdateStatus ? watchStatus : undefined,
        }
      : { status: isUpdateStatus ? watchStatus : undefined }

    setIsBackdropLoading((prevIsBackdropLoading) => !prevIsBackdropLoading)
    await bannerEntityContainer.logic
      .updateBanner(bannerId, params)
      .then(async (newBanner) => {
        displaySuccessNotification('更新しました。')
        reset({ banner: newBanner })
        goTo.back()
      })
      .catch((error) => {
        displayErrorNotification(error.message)
      })
      .finally(() => {
        setIsBackdropLoading(false)
      })
  }

  const onSubmit: SubmitHandler<FormData> = (data: FormData) => {
    setIsButtonLoading(true)
    if (editMode) {
      updateBanner(data).finally(() => setIsButtonLoading(false))
    } else {
      createBanner(data).finally(() => setIsButtonLoading(false))
    }
  }

  const onError: SubmitErrorHandler<FormData> = () => {
    displayErrorNotification('値が正しく入力されていません。')
  }

  const sameSizeAndSamePositionBannerTotalCount = banners.filter(
    ({ size, position }) => size === watchSize && position === watchPosition,
  ).length

  // 新規登録またはポジションを変更する際は、表示順+1の数字を「表示順」に表示
  const priorityNumber =
    originalBanner?.size === watchSize &&
    originalBanner?.position === watchPosition
      ? sameSizeAndSamePositionBannerTotalCount
      : sameSizeAndSamePositionBannerTotalCount + 1
  const priorityNumbers = [...Array(priorityNumber)].map(
    (_, index) => index + 1,
  )

  useEffect(() => {
    // 作成・編集（異なるサイズまたは異なるポジションに移動する場合） => 表示順:一番後ろ
    // 編集（同じサイズかつ同じポジションに移動する場合） => 表示順:元の表示順
    if (
      originalBanner?.size === watchSize &&
      originalBanner?.position === watchPosition
    ) {
      setValue('banner.priority', originalBanner?.priority)
    } else {
      setValue('banner.priority', priorityNumber)
    }
  }, [watchSize, watchPosition, priorityNumber, originalBanner, setValue])

  return (
    <FormProvider {...methods}>
      <BannerFormView
        editMode={editMode}
        goTo={goTo}
        isButtonLoading={isButtonLoading}
        isBackdropLoading={isBackdropLoading}
        watchPosition={watchPosition}
        watchClickType={watchClickType}
        watchSize={watchSize}
        priorityNumbers={priorityNumbers}
        onClickDeleteButton={onClickDeleteButton}
        onClickStatusUpdateButton={onClickStatusUpdateButton}
        onSubmit={onSubmit}
        onError={onError}
        disabledChangeStatusButton={originalBanner?.status === watchStatus}
      />
    </FormProvider>
  )
}

type GoToProps = {
  back: () => void
}

type ViewProps = {
  editMode: boolean
  goTo: GoToProps
  onClickDeleteButton: () => void
  isButtonLoading: boolean
  isBackdropLoading: boolean
  watchSize: BannerSize
  watchPosition: BannerPosition
  onSubmit: SubmitHandler<FormData>
  onError: SubmitErrorHandler<FormData>
  watchClickType: ClickType
  onClickStatusUpdateButton: () => Promise<void>
  priorityNumbers: Array<number>
  disabledChangeStatusButton: boolean
}

type PickViewProps = Pick<
  ViewProps,
  'editMode' | 'goTo' | 'onClickDeleteButton' | 'isButtonLoading'
>

type ButtonsProps = PickViewProps & {
  isDirty: boolean
}

const BannerFormView = ({
  editMode,
  goTo,
  onClickDeleteButton,
  isButtonLoading,
  isBackdropLoading,
  onSubmit,
  onError,
  watchClickType,
  watchSize,
  watchPosition,
  onClickStatusUpdateButton,
  priorityNumbers,
  disabledChangeStatusButton,
}: ViewProps): JSX.Element => {
  const {
    handleSubmit,
    register,
    setValue,
    errors,
    formState: { isDirty },
    control,
  } = useFormContext()
  return (
    <div className='content'>
      <Grid fluid>
        <Form onSubmit={handleSubmit(onSubmit, onError)}>
          {editMode && (
            <Row>
              <Col md={10}>
                <BannerFormPublishSettingCard
                  editMode={editMode}
                  onClickStatusUpdateButton={onClickStatusUpdateButton}
                  disabledSubmitButton={disabledChangeStatusButton}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col md={10}>
              <Card
                title={editMode ? 'バナー編集' : 'バナー作成'}
                content={
                  <FormContent
                    editMode={editMode}
                    register={register}
                    setValue={setValue}
                    errors={errors}
                    watchClickType={watchClickType}
                    watchSize={watchSize}
                    watchPosition={watchPosition}
                    control={control}
                    priorityNumbers={priorityNumbers}
                  />
                }
              />

              {!editMode && (
                <Row>
                  <Col md={12}>
                    <BannerFormPublishSettingCard
                      editMode={editMode}
                      onClickStatusUpdateButton={onClickStatusUpdateButton}
                      disabledSubmitButton={disabledChangeStatusButton}
                    />
                  </Col>
                </Row>
              )}

              <EventButtons
                editMode={editMode}
                goTo={goTo}
                onClickDeleteButton={onClickDeleteButton}
                isButtonLoading={isButtonLoading}
                isDirty={isDirty}
              />
            </Col>
          </Row>
        </Form>
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isBackdropLoading}>
          <CircularProgress color='inherit' />
        </Backdrop>
        <Prompt
          when={isDirty}
          message='行った変更が保存されていない可能性があります。このページを離れますか？'
        />
      </Grid>
    </div>
  )
}

const FormContent = ({
  register,
  setValue,
  errors,
  editMode,
  watchClickType,
  watchSize,
  watchPosition,
  control,
  priorityNumbers,
}): JSX.Element => (
  <Row>
    <Col md={12}>
      <FormInputs
        properties={[
          {
            name: 'banner.title',
            label: '管理用バナー名',
            note: 'このバナー名はトイポアプリには表示されません',
            ncol: 'col-md-7',
            type: 'text',
            bsClass: 'form-control',
            inputRef: register({
              required: '管理用バナー名を入力してください。',
            }),
            validationMessage: errors?.banner?.title?.message,
          },
        ]}
      />

      <FormGroup style={{ marginBottom: 20 }}>
        <FormLabel label='サイズ' />
        {editMode ? (
          <div>{sizeToJpObj[watchSize]}</div>
        ) : (
          <Col
            style={{
              display: 'flex',
              flexWrap: 'wrap',
            }}>
            {bannerSizes.map((size) => (
              <Radio
                key={`banner-${size}`}
                checked={watchSize === size}
                onChange={() => {
                  setValue('banner.size', size, {
                    shouldDirty: true,
                  })
                }}
                style={{ margin: 0 }}>
                <p>{sizeToJpObj[size]}</p>
              </Radio>
            ))}
          </Col>
        )}
      </FormGroup>

      <Controller
        control={control}
        name='banner.image'
        rules={{ required: 'バナー画像を登録してください。' }}
        render={({ value, onChange }): JSX.Element => (
          <ImageForm
            label='バナー画像'
            width={320}
            height={imageSize[watchSize].height}
            aspect={
              imageSize[watchSize].aspectHorizontal /
              imageSize[watchSize].aspectVertical
            }
            value={value}
            onChange={(img: string): void => onChange(img)}
            validationMessage={errors?.banner?.image?.message}
          />
        )}
      />
      <HelpBlock className='text-muted'>
        画像は縦横比
        {imageSize[watchSize].aspectHorizontal}:
        {imageSize[watchSize].aspectVertical}
        で表示されます。
      </HelpBlock>

      <SelectorForm
        label='バナーを押したときの遷移先'
        name='banner.click_type'
        inputRef={register}
        width={320}
        options={clickTypes.map((type) => ({
          value: type,
          label: clickTypeToJpObj[type],
        }))}
        validationMessage={errors?.banner?.click_type?.message}
      />

      {watchClickType === 'url' && (
        <FormInputs
          properties={[
            {
              name: 'banner.click_url',
              label: '遷移先URL',
              ncol: 'col-md-7',
              type: 'text',
              bsClass: 'form-control',
              inputRef: register({ required: 'URLを設定してください。' }),
              validationMessage: errors?.banner?.click_url?.message,
            },
          ]}
        />
      )}

      <FormGroup style={{ marginBottom: 20 }}>
        <FormLabel label='配置' />
        <Col
          style={{
            display: 'flex',
            flexWrap: 'wrap',
          }}>
          {bannerPositions.map((position) => (
            <div style={{ marginRight: 16 }}>
              <Radio
                key={`banner-${position}`}
                checked={watchPosition === position}
                onChange={() => {
                  setValue('banner.position', position, {
                    shouldDirty: true,
                  })
                }}
                style={{ marginTop: 0, marginBottom: 12 }}>
                <p>{positionToJpObj[position]}</p>
              </Radio>
              <Image
                style={{ width: 250, height: 250 }}
                src={previewBannerImages[position]}
              />
            </div>
          ))}
        </Col>
      </FormGroup>

      <SelectorForm
        label='表示順'
        name='banner.priority'
        inputRef={register({
          valueAsNumber: true,
          min: { value: 1, message: '1 以上の数値を入力してください。' },
        })}
        width={250}
        options={priorityNumbers.map((number) => ({
          value: number,
          label: `${number}枚目`,
        }))}
        validationMessage={errors?.banner?.priority?.message}
      />
    </Col>
  </Row>
)

const EventButtons = ({
  editMode,
  goTo,
  onClickDeleteButton,
  isButtonLoading,
  isDirty,
}: ButtonsProps): JSX.Element => (
  <>
    {editMode ? (
      <>
        <Row style={{ marginBottom: 30 }}>
          <Col xs={6} md={5} mdOffset={1}>
            <Button fill block onClick={goTo.back}>
              <b>もどる</b>
            </Button>
          </Col>
          <Col xs={6} md={5}>
            <LoadingButton
              type='submit'
              label='更新する'
              loadingLabel='更新中...'
              color='info'
              block
              fill
              disabled={!isDirty}
              loading={isButtonLoading}
            />
          </Col>
        </Row>

        <Row>
          <Col md={3} xs={2} />
          <Col md={6} xs={8}>
            <LoadingButton
              label='削除する'
              loadingLabel='削除中...'
              color='danger'
              block
              fill={false}
              simple
              loadingColor='red'
              onClick={onClickDeleteButton}
            />
          </Col>
          <Col md={3} xs={2} />
        </Row>
      </>
    ) : (
      <Row style={{ marginBottom: 30 }}>
        <Col xs={6} md={5} mdOffset={1}>
          <Button fill block onClick={goTo.back}>
            <b>もどる</b>
          </Button>
        </Col>
        <Col xs={6} md={5}>
          <LoadingButton
            type='submit'
            label='作成する'
            loadingLabel='作成中...'
            color='info'
            block
            fill
            disabled={!isDirty}
            loading={isButtonLoading}
          />
        </Col>
      </Row>
    )}
  </>
)

export default BannerFormViewWrapper
