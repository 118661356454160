import { AxiosResponse } from 'axios'
import apiClient from './apiClient'
import { loadViewObj } from '../viewObj'
import { OptionItemInterface } from '../../containers/entities/OptionItemEntityContainer'

export const getOptionItems = async (): Promise<OptionItemInterface[]> => {
  const GET_OPTION_ITEMS = `/staff/stores/${loadViewObj()?.id}/option_items`

  const response: AxiosResponse<OptionItemInterface[]> = await apiClient({
    method: 'GET',
    uri: GET_OPTION_ITEMS,
  })
  return response.data
}

export const createOptionItem = async ({
  params,
}): Promise<OptionItemInterface> => {
  const CREATE_OPTION_ITEM = `/staff/stores/${loadViewObj()?.id}/option_items`

  const requestParams = {
    option_item: params,
  }

  const response: AxiosResponse<OptionItemInterface> = await apiClient({
    method: 'POST',
    uri: CREATE_OPTION_ITEM,
    params: requestParams,
  })
  return response.data
}

export const updateOptionItem = async ({
  id,
  params,
}): Promise<OptionItemInterface> => {
  const UPDATE_OPTION_ITEM = `/staff/stores/${
    loadViewObj()?.id
  }/option_items/${id}`

  const requestParams = {
    option_item: params,
  }

  const response: AxiosResponse<OptionItemInterface> = await apiClient({
    method: 'PATCH',
    uri: UPDATE_OPTION_ITEM,
    params: requestParams,
  })
  return response.data
}

export const deleteOptionItem = async ({
  id,
}): Promise<{ message: string }> => {
  const DELETE_OPTION_ITEM = `/staff/stores/${
    loadViewObj()?.id
  }/option_items/${id}`

  const response = await apiClient({
    method: 'DELETE',
    uri: DELETE_OPTION_ITEM,
  })
  return response.data
}
