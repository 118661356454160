import React from 'react'
import { useNotification } from '../../providers/NotificationProvider'

export const useRenderer = () => {
  const notification = useNotification()

  return (Component) => (props) => (
    <>
      <Component
        {...props}
        displayNotification={notification.showNotification}
        displayErrorNotification={notification.showErrorNotification}
        displaySuccessNotification={notification.showSuccessNotification}
      />
    </>
  )
}
