import React from 'react'
import Select, { SelectProps } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useGetApiCall } from '../../lib/hooks'
import {
  MembersCardRanksResponse,
  MembersCardRankResource,
} from '../../types/membersCard'

type Props = Omit<SelectProps, 'onChange'> & {
  disableEmpty?: boolean
  emptyLabel?: string
  rankId: number | null
  onChange: (
    rankId: number | null,
    rank: MembersCardRankResource | null,
  ) => void
}

const MembersCardRankSelectForm = ({
  disableEmpty = false,
  emptyLabel = 'すべて',
  rankId,
  onChange,
  ...otherProps
}: Props): JSX.Element => {
  const url = '/members_card_contents/members_card_ranks?status=open'
  const { response } = useGetApiCall<MembersCardRanksResponse>(url)
  const ranks = response?.members_card_ranks || []
  const getRank = (rankId: number) => ranks.find((rank) => rank.id === rankId)

  return (
    <Select
      value={ranks.length === 0 ? 'all' : (rankId ?? 'all')}
      onChange={(e) => {
        const { value } = e.target
        if (value === 'all') {
          onChange(null, null)
        } else {
          const rankId = Number(value)
          onChange(rankId, getRank(rankId) || null)
        }
      }}
      {...otherProps}>
      {!disableEmpty && <MenuItem value='all'>{emptyLabel}</MenuItem>}

      {ranks.map((rank) => (
        <MenuItem key={rank.id} value={rank.id}>
          {rank.name}
        </MenuItem>
      ))}
    </Select>
  )
}

export default MembersCardRankSelectForm
