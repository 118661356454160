import React, { useState, useEffect } from 'react'
import { Modal, Form, Row, Col, HelpBlock } from 'react-bootstrap'
import Flatpickr from 'react-flatpickr'
import { Japanese } from 'flatpickr/dist/l10n/ja'
import { Controller, useForm } from 'react-hook-form'
import { usePcSizeFlag } from '../../../lib/hooks'
import { useNotification } from '../../../providers/NotificationProvider'
import FormLabel from '../../../components/FormInputs/FormLabel'
import Checkbox from '../../../components/CustomCheckbox/CustomCheckbox'
import LoadingButton from '../../../components/CustomButton/LoadingButton'
import FormInputs from '../../../components/FormInputs/FormInputs'
import SelectorForm from '../../../components/FormInputs/SelectorForm'
import TimeSelectBox from '../../../components/FormInputs/TimeSelectBox'

import EventEntityContainer, {
  EventItem,
} from '../../../containers/entities/EventEntityContainer'
import { compareValidateEventDate } from '../../../lib/validation'

type Props = {
  getEventData: (page: number) => void
  eventData: EventItem | null
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  show: boolean
  onHide: () => void
}

type RepeatType = 'none' | 'month'

const repeatTypeToJp: {
  none: '繰り返さない'
  month: '毎月'
} = {
  none: '繰り返さない',
  month: '毎月',
}

const EventModal: React.FC<Props> = ({
  getEventData,
  eventData,
  setCurrentPage,
  show,
  onHide,
}) => {
  const isPcOrTablet = usePcSizeFlag()
  const { showSuccessNotification, showErrorNotification } = useNotification()
  const date = new Date()

  const {
    register,
    unregister,
    setValue,
    watch,
    handleSubmit,
    reset,
    getValues,
    control,
    errors,
  } = useForm<{ event: EventItem }>({
    defaultValues: {
      event: {
        id: null,
        title: '',
        body: '',
        is_all_day: true,
        start_year: date.getFullYear(),
        start_month: date.getMonth() + 1,
        start_date: date.getDate(),
        start_hour: null,
        start_minute: null,
        start_day: null,
        end_year: date.getFullYear(),
        end_month: date.getMonth() + 1,
        end_date: date.getDate(),
        end_hour: null,
        end_minute: null,
        end_day: null,
      },
    },
  })

  const eventContainer = EventEntityContainer.useContainer()
  const { createEvent, updateEvent } = eventContainer.logic

  const [editMode, setEditMode] = useState(false)
  const [saveLoading, setSaveLoading] = useState(false)
  const [repeat, setRepeat] = useState<RepeatType>('none')

  useEffect(() => {
    setEditMode(!!eventData)
  }, [eventData])

  const validateEndTime = (value) => {
    if (!getValues('event.is_all_day') && !value) {
      return '終了時間を設定してください'
    }

    const startHour = getValues('event.start_hour')
    const startMinute = getValues('event.start_minute')
    const endHour = getValues('event.end_hour')
    const endMinute = getValues('event.end_minute')
    const compareValidate = compareValidateEventDate({
      startHour,
      startMinute,
      endHour,
      endMinute,
    })
    if (!getValues('event.is_all_day') && !compareValidate.validation) {
      return compareValidate.message
    }
    return true
  }

  useEffect(() => {
    register('event.id')
    register('event.start_year', { valueAsNumber: true })
    register('event.start_month', { valueAsNumber: true })
    register('event.start_date', { valueAsNumber: true })
    register('event.start_day', { valueAsNumber: true })
    register('event.end_year', { valueAsNumber: true })
    register('event.end_month', { valueAsNumber: true })
    register('event.end_date', { valueAsNumber: true })
    register('event.end_day', { valueAsNumber: true })

    return () => {
      unregister(['event'])
    }
  }, [register, unregister])

  useEffect(() => {
    reset({ event: eventData as EventItem })
  }, [eventData, reset])

  useEffect(() => {
    const nowDate = new Date()

    if (!eventData?.start_month && editMode) {
      setRepeat('month')
    } else {
      setRepeat('none')
    }

    setValue('event.start_year', eventData?.start_year || nowDate.getFullYear())
    setValue(
      'event.start_month',
      eventData?.start_month || nowDate.getMonth() + 1,
    )
    setValue('event.start_date', eventData?.start_date || nowDate.getDate())
    setValue('event.end_year', eventData?.end_year || nowDate.getFullYear())
    setValue('event.end_month', eventData?.end_month || nowDate.getMonth() + 1)
    setValue('event.end_date', eventData?.end_date || nowDate.getDate())
  }, [eventData, editMode, register, setValue])

  const watchIsAllDay = watch('event.is_all_day')
  const watchStartYear: number = watch('event.start_year')
  const watchStartMonth: number = watch('event.start_month')
  const watchStartDate: number = watch('event.start_date')

  const repeatEvent = (repeatType: RepeatType) => {
    setRepeat(repeatType)

    if (repeatType === 'none') {
      setValue('event.start_year', date.getFullYear())
      setValue('event.start_month', date.getMonth() + 1)
      setValue('event.end_year', date.getFullYear())
      setValue('event.end_month', date.getMonth() + 1)
    }
  }

  const processFormData = (event: EventItem) => {
    let processData = event

    // イベント
    if (processData.is_all_day) {
      processData = {
        ...processData,
        is_all_day: true,
        start_hour: null,
        start_minute: null,
        end_hour: null,
        end_minute: null,
      }
    }

    // 繰り返し
    if (repeat === 'none') {
      processData = {
        ...processData,
        start_day: null,
        end_day: null,
      }
    }
    if (repeat === 'month') {
      processData = {
        ...processData,
        start_year: null,
        start_month: null,
        start_day: null,
        end_year: null,
        end_month: null,
        end_day: null,
      }
    }

    return processData
  }

  const onSubmit = async (data: { event: EventItem }) => {
    setSaveLoading(true)
    const processedData = processFormData(data.event)

    if (editMode) {
      await updateEvent(processedData)
        .then(() => {
          showSuccessNotification('更新しました。')
        })
        .catch((error) => {
          showErrorNotification(error.message)
        })
        .finally(() => {
          onHide()
          reset()
          setCurrentPage(1)
          getEventData(1)
          setSaveLoading(false)
        })
    } else {
      await createEvent(processedData)
        .then(() => {
          showSuccessNotification('作成しました。')
        })
        .catch((error) => {
          showErrorNotification(error.message)
        })
        .finally(() => {
          onHide()
          reset()
          setCurrentPage(1)
          getEventData(1)
          setSaveLoading(false)
        })
    }
  }

  const onError = () => {
    showErrorNotification('値が正しく入力されていません')
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        onHide()
        reset()
      }}>
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <b>イベントを{editMode ? '編集' : '追加'}</b>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div style={{ backgroundColor: '#F4F4F5', padding: '16px' }}>
            <FormInputs
              properties={[
                {
                  placeholder: 'イベント名',
                  type: 'text',
                  ncol: 'col-md-7 col-xs-12',
                  name: 'event.title',
                  inputRef: register({
                    required: 'イベント名を入力してください。',
                  }),
                  validationMessage: errors?.event?.title?.message,
                },
                {
                  placeholder: 'イベント詳細（任意）',
                  componentClass: 'textarea',
                  rows: '3',
                  ncol: 'col-md-9 col-xs-12',
                  name: 'event.body',
                  inputRef: register,
                },
              ]}
            />

            <Row>
              <Col md={4} xs={4}>
                <FormLabel label='日程' />
                <Flatpickr
                  options={{
                    altInput: true,
                    allowInput: true,
                    locale: Japanese,
                    altFormat: 'Y年m月d日',
                  }}
                  value={
                    new Date(
                      watchStartYear,
                      watchStartMonth - 1,
                      watchStartDate,
                    )
                  }
                  onChange={(newDate) => {
                    setValue('event.start_year', newDate[0].getFullYear())
                    setValue('event.start_month', newDate[0].getMonth() + 1)
                    setValue('event.start_date', newDate[0].getDate())
                    setValue('event.start_day', newDate[0].getDay())
                    setValue('event.end_year', newDate[0].getFullYear())
                    setValue('event.end_month', newDate[0].getMonth() + 1)
                    setValue('event.end_date', newDate[0].getDate())
                    setValue('event.end_day', newDate[0].getDay())
                  }}
                />
              </Col>
            </Row>

            <Row style={{ marginTop: '10px' }}>
              <Col md={5} xs={12}>
                <SelectorForm
                  onSelect={({ target }) => {
                    repeatEvent(target.value)
                  }}
                  value={repeat}
                  options={['none', 'month'].map((type) => ({
                    label: repeatTypeToJp[type],
                    value: type,
                  }))}
                />
              </Col>
            </Row>

            <FormLabel label='時間' />
            <Row
              style={{
                display: 'flex',
                flexDirection: isPcOrTablet ? 'row' : 'column',
                alignItems: isPcOrTablet ? 'baseline' : 'normal',
                minHeight: '45px',
              }}>
              <Col md={4} xs={8}>
                <Controller
                  control={control}
                  name='event.is_all_day'
                  render={({ value }) => (
                    <Checkbox
                      number='時間'
                      label='終日'
                      checked={value}
                      onChange={() => {
                        setValue('event.is_all_day', !value)
                      }}
                    />
                  )}
                />
              </Col>

              {!watchIsAllDay && (
                <Col md={8} xs={12}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'baseline',
                      justifyContent: 'start',
                      gap: '3px',
                      marginTop: isPcOrTablet ? 0 : '10px',
                    }}>
                    <Controller
                      name='event.start_hour'
                      control={control}
                      rules={{
                        valueAsNumber: true,
                      }}
                      render={({ value }) => (
                        <TimeSelectBox
                          type='hour'
                          maxHour={24}
                          value={String(value).padStart(2, '0')}
                          onChange={(time: string) =>
                            setValue('event.start_hour', time)
                          }
                        />
                      )}
                    />
                    {/* 分 */}
                    <Controller
                      name='event.start_minute'
                      control={control}
                      rules={{
                        valueAsNumber: true,
                      }}
                      render={({ value }) => (
                        <TimeSelectBox
                          type='minute'
                          value={String(value).padStart(2, '0')}
                          onChange={(time: string) =>
                            setValue('event.start_minute', time)
                          }
                          minuteIncrement={5}
                        />
                      )}
                    />
                    <div style={{ margin: '0 5px' }}>〜</div>
                    <Controller
                      name='event.end_hour'
                      control={control}
                      rules={{
                        valueAsNumber: true,
                        validate: (value) => validateEndTime(value),
                      }}
                      render={({ value }) => (
                        <TimeSelectBox
                          type='hour'
                          maxHour={28}
                          value={String(value).padStart(2, '0')}
                          onChange={(time: string) =>
                            setValue('event.end_hour', time)
                          }
                        />
                      )}
                    />
                    {/* 分 */}
                    <Controller
                      name='event.end_minute'
                      control={control}
                      rules={{
                        valueAsNumber: true,
                      }}
                      render={({ value }) => (
                        <TimeSelectBox
                          type='minute'
                          value={String(value).padStart(2, '0')}
                          onChange={(time: string) =>
                            setValue('event.end_minute', time)
                          }
                          minuteIncrement={5}
                        />
                      )}
                    />
                  </div>
                  {!!(
                    errors?.event?.start_hour?.message ||
                    errors?.event?.end_hour?.message
                  ) && (
                    <HelpBlock className='text-danger'>
                      {errors?.event?.start_hour?.message ||
                        errors?.event?.end_hour?.message}
                    </HelpBlock>
                  )}
                </Col>
              )}
            </Row>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Col md={4} xs={6}>
              <LoadingButton
                type='submit'
                label={editMode ? '変更する' : '追加する'}
                loadingLabel='保存中...'
                color='info'
                fill
                block
                loading={saveLoading}
              />
            </Col>
          </Row>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default EventModal
