import React, { FC, ReactNode } from 'react'
import AppContainer from './containers/AppContainer'
import AuthEntityContainer from './containers/entities/AuthEntityContainer'
import BranchEntityContainer from './containers/entities/BranchEntityContainer'
import NoticeEntityContainer from './containers/entities/NoticeEntityContainer'
import NoticeTemplateEntityContainer from './containers/entities/NoticeTemplateEntityContainer'
import StoreEntityContainer from './containers/entities/StoreEntityContainer'

// リファクタリング前（TODO: リファクタリングが終わったら削除）
import StampCardEntityContainer from './containers/entities/StampCardEntityContainer'
// リファクタリング後
import StampCardContentEntityContainer from './containers/entities/StampCardContentEntityContainer'
import CouponContentEntityContainer from './containers/entities/CouponContentEntityContainer'
import AutoCouponContentEntityContainer from './containers/entities/AutoCouponContentEntityContainer'
import PrivilegeCouponContentEntityContainer from './containers/entities/PrivilegeCouponContentEntityContainer'
import AnalyticsEntityContainer from './containers/entities/AnalyticsEntityContainer'
import TicketContentEntityContainer from './containers/entities/TicketContentEntityContainer'
import TenantEntityContainer from './containers/entities/TenantEntityContainer'
import ChargeEntityContainer from './containers/entities/ChargeEntityContainer'

import MenuEntityContainer from './containers/entities/MenuEntityContainer'
import BannerEntityContainer from './containers/entities/BannerEntityContainer'
import OptionItemEntityContainer from './containers/entities/OptionItemEntityContainer'
import UserEntityContainer from './containers/entities/UserEntityContainer'
import OwnerEntityContainer from './containers/entities/OwnerEntityContainer'
import OwnerStaffEntityContainer from './containers/entities/OwnerStaffEntityContainer'
import StoreStaffEntityContainer from './containers/entities/StoreStaffEntityContainer'
import ActivityLogEntityContainer from './containers/entities/ActivityLogEntityContainer'
import MembersCardContentEntityContainer from './containers/entities/MembersCardContentEntityContainer'
import ServiceContentEntityContainer from './containers/entities/ServiceContentEntityContainer'
import SubscriptionContentEntityContainer from './containers/entities/SubscriptionContentEntityContainer'
import EventEntityContainer from './containers/entities/EventEntityContainer'
import OpenTimeEntityContainer from './containers/entities/OpenTimeEntityContainer'
import DiscountEntityContainer from './containers/entities/DiscountEntityContainer'
import EcFeatureEntityContainer from './containers/entities/EcFeatureEntityContainer'
import UserGroupEntityContainer from './containers/entities/UserGroupEntityContainer'
import ConnectionEntityContainer from './containers/entities/ConnectionEntityContainer'

type Props = {
  children: ReactNode
}

const IntegrationProvider: FC<Props> = ({ children }) => (
  <AppContainer.Provider>
    <BranchEntityContainer.Provider>
      <NoticeEntityContainer.Provider>
        <StampCardEntityContainer.Provider>
          <StoreEntityContainer.Provider>
            <AuthEntityContainer.Provider>
              <CouponContentEntityContainer.Provider>
                <AutoCouponContentEntityContainer.Provider>
                  <PrivilegeCouponContentEntityContainer.Provider>
                    <StampCardContentEntityContainer.Provider>
                      <NoticeTemplateEntityContainer.Provider>
                        <AnalyticsEntityContainer.Provider>
                          <TicketContentEntityContainer.Provider>
                            <TenantEntityContainer.Provider>
                              <ChargeEntityContainer.Provider>
                                <MenuEntityContainer.Provider>
                                  <OptionItemEntityContainer.Provider>
                                    <UserEntityContainer.Provider>
                                      <OwnerEntityContainer.Provider>
                                        <OwnerStaffEntityContainer.Provider>
                                          <StoreStaffEntityContainer.Provider>
                                            <ActivityLogEntityContainer.Provider>
                                              <MembersCardContentEntityContainer.Provider>
                                                <ServiceContentEntityContainer.Provider>
                                                  <BannerEntityContainer.Provider>
                                                    <SubscriptionContentEntityContainer.Provider>
                                                      <EventEntityContainer.Provider>
                                                        <OpenTimeEntityContainer.Provider>
                                                          <DiscountEntityContainer.Provider>
                                                            <EcFeatureEntityContainer.Provider>
                                                              <UserGroupEntityContainer.Provider>
                                                                <ConnectionEntityContainer.Provider>
                                                                  {children}
                                                                </ConnectionEntityContainer.Provider>
                                                              </UserGroupEntityContainer.Provider>
                                                            </EcFeatureEntityContainer.Provider>
                                                          </DiscountEntityContainer.Provider>
                                                        </OpenTimeEntityContainer.Provider>
                                                      </EventEntityContainer.Provider>
                                                    </SubscriptionContentEntityContainer.Provider>
                                                  </BannerEntityContainer.Provider>
                                                </ServiceContentEntityContainer.Provider>
                                              </MembersCardContentEntityContainer.Provider>
                                            </ActivityLogEntityContainer.Provider>
                                          </StoreStaffEntityContainer.Provider>
                                        </OwnerStaffEntityContainer.Provider>
                                      </OwnerEntityContainer.Provider>
                                    </UserEntityContainer.Provider>
                                  </OptionItemEntityContainer.Provider>
                                </MenuEntityContainer.Provider>
                              </ChargeEntityContainer.Provider>
                            </TenantEntityContainer.Provider>
                          </TicketContentEntityContainer.Provider>
                        </AnalyticsEntityContainer.Provider>
                      </NoticeTemplateEntityContainer.Provider>
                    </StampCardContentEntityContainer.Provider>
                  </PrivilegeCouponContentEntityContainer.Provider>
                </AutoCouponContentEntityContainer.Provider>
              </CouponContentEntityContainer.Provider>
            </AuthEntityContainer.Provider>
          </StoreEntityContainer.Provider>
        </StampCardEntityContainer.Provider>
      </NoticeEntityContainer.Provider>
    </BranchEntityContainer.Provider>
  </AppContainer.Provider>
)

export default IntegrationProvider
