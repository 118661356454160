import React, { useEffect, useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Pagination from '@mui/material/Pagination'
import { ButtonToolbar, DropdownButton, MenuItem } from 'react-bootstrap'
import { Crown } from 'phosphor-react'
import { useMobileFlag, useApi, useQuery } from '../../lib/hooks'
import LaptopRankingListTable from './parts/LaptopRankingListTable'
import LaptopRankingContentListTable from './parts/LaptopRankingContentListTable'
import { RankingResource } from '../../types/ranking'
import { RankingContentResource } from '../../types/rankingContent'
import NoItemContent from '../../components/Utils/NoItemContent'

const TabList = ['ranking', 'content']
type TabType = (typeof TabList)[number]
type QueryParams = {
  page: number
  tab: TabType
}

const RankingListView = () => {
  const isMobile = useMobileFlag()

  const history = useHistory()
  const location = useLocation()
  const query = useQuery()
  const tabFromQuery = query.get('tab') as TabType

  const [queryParams, setQueryParams] = useState<QueryParams>({
    page: Number(query.get('page') || 1),
    tab: TabList.includes(tabFromQuery) ? tabFromQuery : 'ranking',
  })

  const changeUrl = useCallback(
    (params: QueryParams) => {
      setQueryParams(params)

      const searchParams = new URLSearchParams()
      searchParams.append('tab', params.tab)
      searchParams.append('page', String(params.page))

      const newUrl = `${location.pathname}?${searchParams.toString()}`
      history.replace(newUrl)
    },
    [history, location.pathname],
  )

  const {
    api: rankingApi,
    loading: rankingLoading,
    loaded: rankingLoaded,
    response: rankingResponse,
    totalPages: rankingPages,
  } = useApi<RankingResource[]>()
  const {
    api: contentApi,
    loading: contentLoading,
    loaded: contentLoaded,
    response: contentResponse,
    totalPages: contentPages,
  } = useApi<RankingContentResource[]>()
  const loading =
    queryParams.tab === 'ranking' ? rankingLoading : contentLoading
  const loaded = queryParams.tab === 'ranking' ? rankingLoaded : contentLoaded
  const rankings = rankingResponse || []
  const contents = contentResponse || []
  const dataLength =
    queryParams.tab === 'ranking' ? rankings.length : contents.length
  const totalPages = queryParams.tab === 'ranking' ? rankingPages : contentPages

  const fetchRankings = useCallback(() => {
    rankingApi.get('/rankings')
  }, [rankingApi])

  const fetchContents = useCallback(() => {
    contentApi.get('/ranking_contents')
  }, [contentApi])

  useEffect(() => {
    if (queryParams.tab === 'ranking') {
      fetchRankings()
    } else if (queryParams.tab === 'content') {
      fetchContents()
    }
  }, [fetchRankings, fetchContents, queryParams.tab])

  const listTable = () => {
    if (queryParams.tab === 'ranking') {
      return (
        <LaptopRankingListTable
          rankings={rankings}
          onClickRanking={(id) => history.push(`ranking/detail?id=${id}`)}
        />
      )
    }
    return (
      <LaptopRankingContentListTable
        // 期間限定ランキングは周期0でコンテンツが存在するので
        // 周期0のものを除いて定期ランキングのみ渡す
        contents={contents.filter(
          (content) =>
            content.period_in_days !== 0 ||
            content.period_in_weeks !== 0 ||
            content.period_in_months !== 0,
        )}
        onClickRankingContent={(id) =>
          history.push(`ranking_contents/edit?id=${id}`)
        }
      />
    )
  }

  return (
    <Box p={2} paddingBottom={0}>
      <Box p={2} marginBottom={5}>
        <ButtonToolbar className='pull-right'>
          <DropdownButton
            title={<b>ランキングを作成する</b>}
            className='btn-info btn-fill'
            pullRight
            id='dropdown-menu-list'>
            <MenuItem
              eventKey='1'
              onClick={() =>
                history.push('ranking_contents/new?periodic=true')
              }>
              定期ランキングを作成する
            </MenuItem>
            <MenuItem divider />
            <MenuItem
              eventKey='2'
              onClick={() =>
                history.push('ranking_contents/new?periodic=false')
              }>
              ランキングを作成する
            </MenuItem>
          </DropdownButton>
        </ButtonToolbar>
      </Box>

      <Paper
        variant={isMobile ? 'elevation' : 'outlined'}
        square={isMobile}
        sx={{ m: isMobile ? 0 : 2 }}>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          mb={2}
          p={2}>
          <Typography variant='title'>ランキング</Typography>
        </Box>

        <Tabs
          variant='scrollable'
          scrollButtons='auto'
          value={queryParams.tab}
          onChange={(_, tab: string) => changeUrl({ page: 1, tab })}>
          <Tab value='ranking' label='ランキング' disabled={loading} />
          <Tab value='content' label='定期ランキング設定' disabled={loading} />
        </Tabs>

        {loaded && dataLength === 0 ? (
          <NoItemContent icon={<Crown />} label='ランキングがありません' />
        ) : (
          listTable()
        )}
        {totalPages > 1 && (
          <Pagination
            sx={{ my: 1 }}
            disabled={loading}
            count={totalPages}
            page={queryParams.page}
            onChange={(_, page) => changeUrl({ ...queryParams, page })}
          />
        )}
      </Paper>
    </Box>
  )
}

export default RankingListView
