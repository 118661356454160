import { useState, useCallback, Dispatch, SetStateAction } from 'react'
import { createContainer } from 'unstated-next'
import { subscriptionContentApi } from '../../lib/api'

const SubscriptionStatus = {
  Draft: 'draft',
  Open: 'open',
  Close: 'close',
  Sold: 'sold',
  LimitedOpen: 'limited_open',
  Preview: 'preview',
} as const

export type SubscriptionContentStatus =
  (typeof SubscriptionStatus)[keyof typeof SubscriptionStatus]

export interface SubscriptionContent {
  id: number
  body: string
  created_at: Date
  daily_usable_count: number | null
  expiration_month: number
  image_url: string
  limit_quantity: number | null
  limited_open_url: string
  monthly_usable_count: number | null
  this_term_usable_count: number | null
  price: number
  status: SubscriptionContentStatus
  store_id: number
  title: string
  updated_at: Date
  enabled_auto_publish_condition: boolean
  enabled_target_user_condition: boolean
  target_user_condition?: SubscriptionContentTargetUserCondition | null
}

export interface SubscriptionContentParams {
  id?: number
  body?: string
  created_at?: Date
  daily_usable_count?: number | null
  expiration_month?: number
  image_url?: string
  limit_quantity?: number | null
  limited_open_url?: string
  monthly_usable_count?: number | null
  price?: number
  status?: SubscriptionContentStatus
  store_id?: number
  title?: string
  updated_at?: Date
  enabled_auto_publish_condition: boolean
  enabled_target_user_condition?: boolean
  target_user_condition?: Partial<SubscriptionContentTargetUserCondition> | null
  image?: string
}

interface SubscriptionContentTargetUserCondition {
  age_from: number | null
  age_to: number | null
  birth_month: number | null
  coupon_content_id: number | null
  created_at: string
  gender: 'male' | 'female' | 'other' | null
  id: number
  notice_content_id: number | null
  rank: number | null
  rank_ids: number[]
  updated_at: string
  user_group_collection_id: number
  user_group_ids: number[]
  user_ids: number[]
  users_count: number
}

type SubscriptionContentEntity = {
  state: {
    subscriptionContents: SubscriptionContent[]
  }
  logic: {
    setSubscriptionContents: Dispatch<SetStateAction<SubscriptionContent[]>>
    getSubscriptionContents: () => Promise<SubscriptionContent[]>
    getSubscriptionContent: (id: number) => Promise<SubscriptionContent>
    createSubscriptionContent: ({
      params,
    }: {
      params: SubscriptionContentParams
    }) => Promise<SubscriptionContent>
    updateSubscriptionContent: ({
      params,
      id,
    }: {
      params: Partial<SubscriptionContentParams>
      id: number
    }) => Promise<SubscriptionContent>

    deleteSubscriptionContent: ({
      id,
    }: {
      id: number
    }) => Promise<{ message: string }>
  }
}

const useSubscriptionContentEntityHook = (): SubscriptionContentEntity => {
  const [subscriptionContents, setSubscriptionContents] = useState<
    SubscriptionContent[]
  >([])

  return {
    state: { subscriptionContents },
    logic: {
      setSubscriptionContents,
      getSubscriptionContents: useCallback(async (): Promise<
        SubscriptionContent[]
      > => {
        const response = await subscriptionContentApi.getSubscriptionContents()
        setSubscriptionContents(response)
        return response
      }, []),
      getSubscriptionContent: async (
        id: number,
      ): Promise<SubscriptionContent> => {
        const response = await subscriptionContentApi.getSubscriptionContent(id)
        return response
      },
      createSubscriptionContent: async ({
        params,
      }: {
        params: SubscriptionContentParams
      }): Promise<SubscriptionContent> => {
        const response = await subscriptionContentApi.createSubscriptionContent(
          { params },
        )
        return response
      },
      updateSubscriptionContent: async ({
        params,
        id,
      }: {
        params: Partial<SubscriptionContentParams>
        id: number
      }): Promise<SubscriptionContent> => {
        const response = await subscriptionContentApi.updateSubscriptionContent(
          { params, id },
        )
        return response
      },
      deleteSubscriptionContent: async ({
        id,
      }: {
        id: number
      }): Promise<{ message: string }> => {
        const response =
          await subscriptionContentApi.deleteSubscreiptionContent({ id })
        return response
      },
    },
  }
}

export default createContainer(useSubscriptionContentEntityHook)
