import React from 'react'
import { styled } from '@mui/material/styles'
import { useLoginContext } from '../../../providers/LoginContextProvider'
import SidebarHeader from './SidebarHeader'
import SidebarFooter from './SidebarFooter'
import StoreQrcode from './StoreQrcode'
import MenuGroup from './MenuGroup'
import MenuItem from './MenuItem'

const Sidebar = (): JSX.Element => {
  const { isOwnerRejected } = useLoginContext()

  return (
    <StyledContainer>
      <SidebarHeader />

      <StyledContentContainer>
        {isOwnerRejected ? <RejectedSidebarMenuList /> : <SidebarMenuList />}
      </StyledContentContainer>

      <SidebarFooter />
    </StyledContainer>
  )
}

const SidebarMenuList = (): JSX.Element => {
  const { currentStaff, currentStore, hasFunction, lineChannel } =
    useLoginContext()
  const isOwnerGroup = currentStore.store_type === 'owner_group'
  const isStoreGroup = currentStore.store_type === 'store_group'
  const isChild = currentStore.store_type === 'child'
  const isGroup = isOwnerGroup || isStoreGroup
  const isOwner = currentStaff.is_owner
  const isSingle = currentStaff.stores.length === 1
  const isSingleOwner = isSingle && isOwner
  const storeQrcodeUrl = currentStore.store_qrcodes[0]?.image_url
  const { hasPermission } = useLoginContext()

  return (
    <>
      {currentStore.store_type === 'child' && Boolean(storeQrcodeUrl) && (
        <StoreQrcode
          storeQrcodeUrl={storeQrcodeUrl}
          storeName={currentStore.name}
        />
      )}

      <MenuGroup icon='ri-home-4-line' label='ホーム' path='/admin/home' />

      <MenuGroup icon='ri-megaphone-line' label='販促管理'>
        <MenuItem label='お知らせ' path='/admin/notices' />
        {hasFunction('coupon') && (
          <MenuItem label='クーポン' path='/admin/coupons' />
        )}
        {hasFunction('ticket') && (
          <MenuItem label='チケット' path='/admin/ticket' />
        )}
        {hasFunction('subscription') && (
          <MenuItem label='サブスク' path='/admin/subscription' />
        )}
        {hasFunction('stamp_card') && (
          <MenuItem label='スタンプカード' path='/admin/stampcard' />
        )}
        {hasFunction('members_card') && (
          <MenuItem label='会員カード' path='/admin/memberscard' />
        )}
        {hasFunction('ranking') && (
          <MenuItem label='ランキング' path='/admin/ranking' />
        )}
        {hasFunction('banner') && (
          <MenuItem label='バナー' path='/admin/banner' />
        )}
        {hasFunction('calendar') && (
          <MenuItem label='カレンダー' path='/admin/calendar' />
        )}
        {hasFunction('menu') && (
          <MenuItem label='メニュー' path='/admin/menu' />
        )}
      </MenuGroup>

      {hasFunction('order') && (
        <MenuGroup icon='ri-smartphone-line' label='モバイルオーダー'>
          <MenuItem label='注文管理' path='/admin/order/orders' />
          <MenuItem label='メニュー設定' path='/admin/order/menu' />
          <MenuItem label='モバイルオーダー設定' path='/admin/order/setting' />
        </MenuGroup>
      )}

      {hasFunction('ec') && (
        <MenuGroup icon='ri-shopping-cart-line' label='オンラインショップ'>
          {(isSingleOwner || isOwnerGroup) && (
            <MenuItem label='注文管理' path='/admin/ec/orders' />
          )}
          <MenuItem label='メニュー設定' path='/admin/ec/menu' />
          {(isSingleOwner || isOwnerGroup) && (
            <MenuItem label='オンラインショップ設定' path='/admin/ec/setting' />
          )}
        </MenuGroup>
      )}

      <MenuGroup icon='ri-user-3-line' label='ユーザー管理'>
        {hasPermission('END_POINT_LIST_STORE_USERS') && (
          <MenuItem label='ユーザー一覧' path='/admin/customers' />
        )}
        <MenuItem label='利用履歴' path='/admin/user_log' />
      </MenuGroup>

      <MenuGroup icon='ri-pie-chart-2-line' label='データ分析'>
        <MenuItem label='ダッシュボード' path='/admin/dashboard' />
        {hasFunction('coupon') && (
          <MenuItem label='クーポン分析' path='/admin/coupons_summary' />
        )}
        {hasFunction('analytics_report') && (
          <MenuItem label='自動分析' path='/admin/analytics_report' />
        )}
        {hasFunction('questionnaire') && (
          <MenuItem
            label='顧客満足度'
            path='/admin/satisfaction_questionnaire'
          />
        )}
      </MenuGroup>

      <MenuGroup
        icon='ri-flag-2-line'
        label='目標管理'
        path='/admin/benchmark'
      />
      {hasFunction('sales') && (
        <MenuGroup
          icon='ri-wallet-3-line'
          label='売上管理'
          path='/admin/report'
        />
      )}
      <MenuGroup
        icon='ri-percent-line'
        label='割引管理'
        path='/admin/discount'
      />
      {isChild && (
        <MenuGroup
          icon='ri-qr-code-line'
          label='QRコード・販促物'
          path='/admin/promotional_material'
        />
      )}
      <MenuGroup
        icon='ri-add-box-line'
        label='機能を追加'
        path='/admin/plugin'
      />

      {isGroup && (
        <MenuGroup icon='ri-organization-chart' label='グループ管理'>
          <MenuItem label='基本情報' path='/admin/group/edit' />
          {isOwner && <MenuItem label='グループ一覧' path='/admin/groups' />}
          <MenuItem label='店舗一覧' path='/admin/child_stores' />
          {isOwnerGroup && isOwner && (
            <MenuItem label='店舗追加' path='/admin/stores/new' />
          )}
        </MenuGroup>
      )}

      <MenuGroup icon='ri-settings-2-line' label='設定'>
        {!isGroup && <MenuItem label='店舗情報' path='/admin/store/edit' />}
        {isSingleOwner && (
          <MenuItem label='店舗追加' path='/admin/stores/new' />
        )}
        {(isSingle || isOwnerGroup) && (
          <MenuItem label='事業者情報' path='/admin/owner' />
        )}
        <MenuItem label='スタッフ管理' path='/admin/staff' />
        {lineChannel && (
          <MenuItem label='LINE連携' path='/admin/line_setting' />
        )}
      </MenuGroup>
    </>
  )
}

const RejectedSidebarMenuList = (): JSX.Element => {
  return (
    <MenuGroup
      icon='ri-shield-keyhole-line'
      label='利用停止'
      path='/admin/suspend'
    />
  )
}

const StyledContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  background: '#1D2636',
  height: '100vh',
})

const StyledContentContainer = styled('div')(({ theme }) => ({
  overflowY: 'scroll',
  flexGrow: 1,
  ...theme.mixins.hiddenScrollbar,
}))

export default Sidebar
