import React from 'react'
import { useHistory } from 'react-router-dom'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { Gift } from 'phosphor-react'
import { RankingResource } from '../../../types/ranking'
import { useMobileFlag, useGetApiCall } from '../../../lib/hooks'
import NoItemContent from '../../../components/Utils/NoItemContent'
import CustomButton from '../../../components/CustomButton/CustomButton'
import StatusLabel from '../../Coupon/parts/StatusLabel'
import { AutoCouponContentStatusToJpObj } from '../../../containers/entities/CouponContentEntityContainer'

type Props = {
  ranking: RankingResource
}
const RankingRewardListTable = ({ ranking }: Props): JSX.Element => {
  const isMobile = useMobileFlag()
  const history = useHistory()
  const rankingId = ranking.id
  const rewardCouponsPath = `/coupon_contents?type=ranking&ranking_id=${rankingId}`
  const { response } = useGetApiCall(rewardCouponsPath)
  const rewards = response?.coupon_contents || []

  return (
    <Paper
      square={isMobile}
      variant={isMobile ? 'elevation' : 'outlined'}
      sx={{ m: isMobile ? 0 : 1 }}>
      {ranking.status !== 'finished' && (
        <Box sx={{ m: 2, mb: 10 }}>
          <CustomButton
            bsStyle='info'
            fill
            pullRight
            onClick={() =>
              history.push(`/admin/reward/new?ranking_id=${rankingId}`)
            }>
            <b>特典を作成する</b>
          </CustomButton>
        </Box>
      )}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell width={100}>状態</TableCell>
            <TableCell>タイトル</TableCell>
            <TableCell width={100}>対象者</TableCell>
            <TableCell width={100}>配信数</TableCell>
            <TableCell width={100}>到達数</TableCell>
            <TableCell width={100}>開封数</TableCell>
            <TableCell width={100}>利用数</TableCell>
            <TableCell width={200}>配信効果売上</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rewards.map((reward) => (
            <StyledBodyRow
              key={reward.ranking_reward_id}
              hover
              onClick={() =>
                history.push(`/admin/reward/edit?ranking_id=${rankingId}`, {
                  privilegeCoupon: {
                    ...reward.coupon_content,
                  },
                  rankingRewardId: reward.ranking_reward_id,
                  startRank: reward.start_rank,
                  endRank: reward.end_rank,
                })
              }>
              <TableCell>
                <StatusLabel status={reward.coupon_content.status}>
                  {AutoCouponContentStatusToJpObj[reward.coupon_content.status]}
                </StatusLabel>
              </TableCell>
              <TableCell style={{ padding: 0 }}>
                <img
                  alt='特典画像'
                  src={reward.coupon_content.image_url}
                  width={48}
                  height={30}
                  style={{ marginRight: 16, marginLeft: 16 }}
                />
                {reward.coupon_content.benefit}
              </TableCell>
              <TableCell>
                {reward.start_rank === reward.end_rank
                  ? reward.start_rank
                  : `${reward.start_rank}〜${reward.end_rank}`}
                位
              </TableCell>
              <TableCell width={100}>{reward.delivered_count}</TableCell>
              <TableCell width={100}>{reward.arrival_count}</TableCell>
              <TableCell width={100}>{reward.read_count}</TableCell>
              <TableCell width={100}>{reward.used_count}</TableCell>
              <TableCell width={200}>{reward.sales_effect}</TableCell>
            </StyledBodyRow>
          ))}
        </TableBody>
      </Table>
      {rewards.length === 0 && (
        <NoItemContent icon={<Gift />} label='特典がありません' />
      )}
    </Paper>
  )
}

const StyledBodyRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '& > td': {
    fontSize: 13,
    fontWeight: 700,
    padding: theme.spacing(3.5, 1),
  },
}))

export default RankingRewardListTable
