import { useEffect } from 'react'
import { withRouter } from 'react-router'
import firebase from '../lib/firebase'

const GoogleAnalytics = ({ history }) => {
  useEffect(() => {
    if (process.env.REACT_APP_ENV === 'production') {
      const analytics = firebase.analytics()
      history.listen(({ pathname }) => {
        analytics.logEvent('view_page', { path: pathname })
      })
    }
  }, [history])

  return null
}

export default withRouter(GoogleAnalytics)
