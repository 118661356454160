import React from 'react'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import NoLoginRoute from './NoLoginRoute'

const TERM_URL = 'https://toypo.notion.site/b42fbd7e43ca4588a13661258e30d90e'
const PRIVACY_POLICY_URL =
  'https://toypo.notion.site/71160018a21a41c3b2145b3ad863ecb5'

const NoLoginContent = (): JSX.Element => {
  return (
    <StyledContentContainer className='main-panel'>
      <Box mt={5} />

      <div style={{ flexGrow: 1 }}>
        <NoLoginRoute />
      </div>

      <StyledFooter>
        <Box display='flex'>
          <StyledFooterItem
            href={TERM_URL}
            target='_blank'
            rel='noopener noreferrer'>
            利用規約
          </StyledFooterItem>
          <StyledFooterItem
            href={PRIVACY_POLICY_URL}
            target='_blank'
            rel='noopener noreferrer'>
            プライバシーポリシー
          </StyledFooterItem>
        </Box>

        <StyledCopyright>
          &copy; {new Date().getFullYear()} toypo
        </StyledCopyright>
      </StyledFooter>
    </StyledContentContainer>
  )
}

const StyledContentContainer = styled('div')({
  marginLeft: 0,
  background: 'rgba(203, 203, 210, 0.15)',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  '& .content': {
    width: '100%',
  },
})

const StyledFooter = styled('div')({
  borderTop: '1px solid #e7e7e7',
  backgroundColor: '#fff',
  display: 'flex',
  justifyContent: 'space-between',
})

const StyledFooterItem = styled('a')(({ theme }) => ({
  display: 'block',
  padding: theme.spacing(2),
  color: '#9a9a9a',
}))

const StyledCopyright = styled('div')(({ theme }) => ({
  color: '#777',
  padding: theme.spacing(2, 4),
}))

export default NoLoginContent
