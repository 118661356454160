/* eslint-disable react/require-default-props */
import React, { CSSProperties } from 'react'
import { usePcSizeFlag } from '../../../lib/hooks'

import { EventItem } from '../../../containers/entities/EventEntityContainer'

type Props = {
  events: EventItem[] | []
  onClickEditIcon: (event: EventItem) => void
  onClickDeleteIcon: (id: number) => void
}

const EventList: React.FC<Props> = ({
  events = [],
  onClickEditIcon,
  onClickDeleteIcon,
}) => {
  const isPcOrTablet = usePcSizeFlag()

  return (
    <>
      {events.map((e: EventItem) => (
        <div
          key={`event-${e.id}`}
          style={{
            backgroundColor: '#F4F4F5',
            padding: '16px',
            paddingTop: isPcOrTablet ? '16px' : '34px',
            position: 'relative',
            display: 'grid',
            rowGap: '12px',
          }}>
          <EventListRow title='イベント名' content={e.title} />
          <EventListRow
            title='日程'
            content={
              e.start_year && e.start_month
                ? `${e.start_year}年${e.start_month}月${e.start_date}日`
                : `毎月${e.start_date}日`
            }
          />
          <EventListRow title='イベント詳細' content={e.body} />

          {/* 編集・削除アイコン */}
          <div
            style={{
              position: 'absolute',
              top: 16,
              right: 16,
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              columnGap: '8px',
            }}>
            <i
              className='fa fa-pencil'
              style={{ fontSize: 18, color: '#757575', cursor: 'pointer' }}
              onClick={() => onClickEditIcon(e)}
            />
            <i
              className='fa fa-trash-o'
              style={{ fontSize: 18, color: '#757575', cursor: 'pointer' }}
              onClick={() => onClickDeleteIcon(e.id as number)}
            />
          </div>
        </div>
      ))}
    </>
  )
}

export default EventList

type RowProps = {
  title: string
  content?: string
  style?: CSSProperties
}

const EventListRow = ({ title, content, style }: RowProps): JSX.Element => (
  <div
    style={{
      display: 'grid',
      gridTemplateColumns: '3fr 7fr',
      alignItems: 'center',
    }}>
    <div style={{ color: '#787878', fontWeight: 'bold' }}>{title}</div>
    <div style={style}>{content}</div>
  </div>
)
