import React from 'react'
import { Table } from 'react-bootstrap'
import * as H from 'history'
import { formatDate } from '../../../lib/general'
import SmallTableBody from '../../../components/Table/SmallTableBody'
import OptionButton from '../../../components/CustomButton/OptionButton'
import { DiscountLog } from '../../../containers/entities/DiscountEntityContainer'
import { usePcSizeFlag } from '../../../lib/hooks'
import {
  IllegalLogStatusBackgroundColor,
  IllegalLogStatusFontColor,
  IllegalStatusLabel,
} from '../../../constants/activityLog'
import { ActionType } from '../../../types/activityLog'

const tdPaddingBottom = {
  illegal: 0,
  doubtful: 0,
  not_check: 16,
  legal: 16,
}

type Props = {
  logs?: DiscountLog[]
  actionTypesToJp: (
    actionType: ActionType,
    value?: number,
    amount?: number,
  ) => string
  history: H.History
  onClickDeleteActionLogsButton: (id: number) => void
  onClickIllegalStatusChangeButton: ({ id, illegalStatus, reasonText }) => void
}

const DiscountLogTable: React.FC<Props> = ({
  logs,
  actionTypesToJp,
  history,
  onClickDeleteActionLogsButton,
  onClickIllegalStatusChangeButton,
}) => {
  const isPcOrTablet = usePcSizeFlag()
  const thArray = [
    '日時',
    'アクション',
    'コンテンツ',
    '店舗',
    'ユーザー',
    '割引額',
    'お会計金額',
    '',
  ]

  return (
    <Table striped hover>
      {isPcOrTablet && (
        <thead>
          <tr>
            {thArray.map((prop) => (
              <th key={`column-${prop}`}>{prop}</th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {logs?.map((log) => (
          <React.Fragment key={`discount-log-${log.id}`}>
            <tr
              style={{
                backgroundColor:
                  IllegalLogStatusBackgroundColor[log.illegal_status],
              }}>
              {isPcOrTablet ? (
                <>
                  <td
                    style={{
                      paddingBottom: tdPaddingBottom[log.illegal_status],
                    }}>
                    {formatDate(log.created_at)}
                  </td>
                  <td
                    style={{
                      paddingBottom: tdPaddingBottom[log.illegal_status],
                    }}>
                    {actionTypesToJp(log.action_type, log.value, log.amount) ||
                      ''}
                  </td>
                  <td
                    style={{
                      paddingBottom: tdPaddingBottom[log.illegal_status],
                    }}>
                    {log.content?.title || ''}
                  </td>
                  <td
                    style={{
                      paddingBottom: tdPaddingBottom[log.illegal_status],
                    }}>
                    {log.store_name || ''}
                  </td>
                  <td
                    style={{
                      paddingBottom: tdPaddingBottom[log.illegal_status],
                      cursor: 'pointer',
                      color: '#1dc7ea',
                    }}
                    onClick={() =>
                      history.push(`customers/detail?user_id=${log.user_id}`)
                    }>
                    {log.user_name || ''}
                  </td>
                  <td
                    style={{
                      paddingBottom: tdPaddingBottom[log.illegal_status],
                    }}>
                    {log.discount_amount ? `${log.discount_amount}円` : '---'}
                  </td>
                  <td
                    style={{
                      paddingBottom: tdPaddingBottom[log.illegal_status],
                    }}>
                    {log.amount ? `${log.amount}円` : '---'}
                  </td>
                  <td
                    style={{
                      paddingBottom: tdPaddingBottom[log.illegal_status],
                    }}>
                    <OptionButton
                      options={[
                        {
                          label: '不正ステータスを変更する',
                          onClick: (): void =>
                            onClickIllegalStatusChangeButton({
                              id: log.id,
                              illegalStatus: log.illegal_status,
                              reasonText: log.doubtful_reason_text,
                            }),
                        },
                        {
                          label: '取り消す',
                          labelColor: '#FF4A55',
                          onClick: (): void => {
                            onClickDeleteActionLogsButton(log.id)
                          },
                        },
                      ]}
                    />
                  </td>
                </>
              ) : (
                <SmallTableBody
                  title={[
                    actionTypesToJp(log.action_type, log.value, log.amount) ||
                      '',
                    log.content?.title || '',
                  ]
                    .filter((v) => v)
                    .join(':')}
                  contents={[
                    `日時: ${formatDate(log.created_at)}`,
                    `店舗: ${log.store_name || ''}`,
                    `ユーザー: ${log.user_name || ''}`,
                    `割引額: ${
                      log.discount_amount ? `${log.discount_amount}円` : '---'
                    }`,
                    `お会計金額: ${log.amount ? `${log.amount}円` : '---'}`,
                  ]}
                  button={
                    <OptionButton
                      options={[
                        {
                          label: '不正ステータスを変更する',
                          onClick: (): void =>
                            onClickIllegalStatusChangeButton({
                              id: log.id,
                              illegalStatus: log.illegal_status,
                              reasonText: log.doubtful_reason_text,
                            }),
                        },
                        {
                          label: '取り消す',
                          labelColor: '#FF4A55',
                          onClick: (): void => {
                            onClickDeleteActionLogsButton(log.id)
                          },
                        },
                      ]}
                    />
                  }
                />
              )}
            </tr>

            {/* PCサイズ, スマホサイズ */}
            {(log.illegal_status === 'illegal' ||
              log.illegal_status === 'doubtful') && (
              <tr
                style={{
                  color: IllegalLogStatusFontColor[log.illegal_status],
                  backgroundColor:
                    IllegalLogStatusBackgroundColor[log.illegal_status],
                }}>
                <td
                  colSpan={thArray.length}
                  style={{
                    fontSize: 12,
                    border: 'none',
                    paddingTop: 5,
                    paddingBottom: 5,
                    fontWeight: 'bold',
                    wordBreak: 'break-all',
                  }}>
                  <i className='fas fa-exclamation-triangle' />{' '}
                  {IllegalStatusLabel[log.illegal_status]}
                  {log.doubtful_reason_text && `: ${log.doubtful_reason_text}`}
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  )
}

export default DiscountLogTable
