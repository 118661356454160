import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Grid, Row, Col, FormGroup, Form, HelpBlock } from 'react-bootstrap'
import { Prompt } from 'react-router'
import { useLoginContext } from '../../providers/LoginContextProvider'
import OwnerEntityContainer from '../../containers/entities/OwnerEntityContainer'
import ConfigListView from '../ConfigListView'
import { Card } from '../../components/Card/Card'
import LoadingButton from '../../components/CustomButton/LoadingButton'
import FormInputs from '../../components/FormInputs/FormInputs'
import FormLabel from '../../components/FormInputs/FormLabel'
import CustomCheckbox from '../../components/CustomCheckbox/CustomCheckbox'
import { getObjectDiff } from '../../lib/general'

type OwnerFormViewProps = {
  displayNotification: ({ level, message: string }) => void
}

const OwnerFormView: React.FC<OwnerFormViewProps> = (props) => {
  const { displayNotification } = props
  const [loading, setLoading] = useState(false)
  const ownerEntityContainer = OwnerEntityContainer.useContainer()
  const { owner } = ownerEntityContainer.state
  const { ownerTypes } = ownerEntityContainer.constant
  const { getOwner, createOwner, updateOwner, ownerTypesToJp } =
    ownerEntityContainer.logic
  const { reloadCurrentStaff } = useLoginContext()

  const {
    register,
    handleSubmit,
    errors,
    setValue,
    unregister,
    watch,
    formState,
    reset,
  } = useForm({
    defaultValues: {
      owner: {
        owner_type: '',
        name: '',
        email: '',
        phone_number: '',
        corp_name: '',
        tokushoho_url: '',
      },
    },
  })

  const { isDirty } = formState

  const watchOwnerType = watch('owner.owner_type')

  // custom register
  useEffect(() => {
    register(
      {
        name: 'owner.owner_type',
      },
      { required: '事業タイプを選択してください。' },
    )

    return (): void => {
      unregister(['owner.owner_type'])
    }
  }, [register, unregister])

  useEffect(() => {
    getOwner()
  }, [getOwner])

  useEffect(() => {
    // initialize form
    if (owner) {
      reset({ owner: owner })
    }
  }, [owner, reset])

  const onClickCreateButton = async (data): Promise<void> => {
    await createOwner(data)
      .then((newOwner) => {
        reset({ owner: newOwner })
        displayNotification({
          level: 'success',
          message: '事業者を作成しました。',
        })
      })
      .catch((error) => {
        displayNotification({
          level: 'error',
          message: error.message,
        })
      })
  }

  const onClickUpdateButton = async (data): Promise<void> => {
    const params = getObjectDiff(owner, data)

    await updateOwner(params)
      .then((newOwner) => {
        reset({ owner: newOwner })
        reloadCurrentStaff()
        displayNotification({
          level: 'success',
          message: '編集を保存しました。',
        })
      })
      .catch((error) => {
        displayNotification({
          level: 'error',
          message: error.message,
        })
      })
  }

  const onSubmit = (data): void => {
    setLoading(true)
    if (owner) {
      onClickUpdateButton(data).finally(() => setLoading(false))
    } else {
      onClickCreateButton(data).finally(() => setLoading(false))
    }
  }

  const onError = (): void => {
    displayNotification({
      level: 'error',
      message: '値が正しく入力されていません。',
    })
  }

  return (
    <Grid fluid className='content'>
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <Row>
          <Col md={10}>
            <Card
              title={owner ? '事業者情報編集' : '事業者情報作成'}
              content={
                <Row>
                  <Col md={12}>
                    <FormInputs
                      properties={[
                        {
                          name: 'owner.name',
                          label: '事業者名',
                          placeholder: '事業者名を入力してください。',
                          ncol: 'col-md-9',
                          type: 'text',
                          bsClass: 'form-control',
                          inputRef: register({
                            required: '事業者名を入力してください。',
                          }),
                          validationMessage: errors?.owner?.name?.message,
                        },
                      ]}
                    />

                    <FormGroup>
                      <FormLabel label='事業タイプ' />
                      <Row>
                        <Col xs={12}>
                          {ownerTypes.map((ownerType) => (
                            <CustomCheckbox
                              key={ownerType}
                              checked={watchOwnerType === ownerType}
                              id={ownerType}
                              label={ownerTypesToJp(ownerType)}
                              onChange={(): void =>
                                setValue('owner.owner_type', ownerType, {
                                  shouldDirty: true,
                                })
                              }
                              inline
                            />
                          ))}
                        </Col>
                      </Row>
                      <HelpBlock className='text-danger'>
                        {errors?.owner?.owner_type?.message}
                      </HelpBlock>
                    </FormGroup>

                    <FormInputs
                      properties={[
                        {
                          name: 'owner.corp_name',
                          label: '法人名',
                          placeholder: '法人名を入力してください。',
                          ncol: 'col-md-9',
                          type: 'text',
                          bsClass: 'form-control',
                          inputRef: register({
                            required:
                              watchOwnerType === 'corporation'
                                ? '法人名を入力してください。'
                                : false,
                          }),
                          validationMessage: errors?.owner?.corp_name?.message,
                          disabled: watchOwnerType === 'individual',
                        },
                        {
                          name: 'owner.email',
                          label: 'メールアドレス',
                          placeholder: 'メールアドレスを入力してください。',
                          ncol: 'col-md-9',
                          type: 'text',
                          bsClass: 'form-control',
                          inputRef: register({
                            required: 'メールアドレスを入力してください。',
                          }),
                          validationMessage: errors?.owner?.email?.message,
                        },
                        {
                          name: 'owner.phone_number',
                          label: '電話番号',
                          placeholder: '電話番号を入力してください。',
                          ncol: 'col-md-9',
                          type: 'text',
                          bsClass: 'form-control',
                          inputRef: register({
                            required: '電話番号を入力してください。',
                          }),
                          validationMessage:
                            errors?.owner?.phone_number?.message,
                        },
                        {
                          name: 'owner.tokushoho_url',
                          label: '特定商取引法に関するURL',
                          placeholder:
                            '特定商取引法に関するURLを入力してください。',
                          ncol: 'col-md-9',
                          type: 'text',
                          bsClass: 'form-control',
                          inputRef: register,
                          validationMessage:
                            errors?.owner?.tokushoho_url?.message,
                        },
                      ]}
                    />
                    <LoadingButton
                      type='submit'
                      label={owner ? '更新する' : '作成する'}
                      loadingLabel={owner ? '更新中...' : '作成中...'}
                      color='info'
                      fill
                      pullRight
                      disabled={!isDirty}
                      loading={loading}
                    />
                  </Col>
                </Row>
              }
            />
          </Col>
        </Row>
      </Form>

      <Prompt
        when={isDirty}
        message='行った変更が保存されていない可能性があります。このページを離れますか？'
      />
      <ConfigListView />
    </Grid>
  )
}

export default OwnerFormView
