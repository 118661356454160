import React from 'react'

interface Props {
  label: string
  link: string
}

const GuideButton: React.FC<Props> = ({ label, link }) => (
  <a href={link} target='_blank' rel='noopener noreferrer'>
    <b>
      <i className='far fa-question-circle' />
      <span style={{ marginLeft: 5 }}>{label}利用ガイド</span>
    </b>
  </a>
)

export default GuideButton
