import React, { useState, useEffect } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { styled } from '@mui/material/styles'
import { CalendarOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import DateRangeModal from './DateRangeModal'

// 日付フォーマット
const formatDate = (date: Date): string => {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}/${month}/${day}`
}

// 期間計算
const calculatePeriod = (period: string) => {
  const now = new Date()
  let fromDate, toDate

  switch (period) {
    case '今日':
      fromDate = toDate = formatDate(now)
      break
    case '昨日':
      const yesterday = new Date(now)
      yesterday.setDate(now.getDate() - 1)
      fromDate = toDate = formatDate(yesterday)
      break
    case '今週':
      const weekStart = new Date(now)
      weekStart.setDate(now.getDate() - now.getDay())
      fromDate = formatDate(weekStart)
      toDate = formatDate(now)
      break
    case '先週':
      const lastWeekStart = new Date(now)
      lastWeekStart.setDate(now.getDate() - now.getDay() - 7)
      const lastWeekEnd = new Date(now)
      lastWeekEnd.setDate(now.getDate() - now.getDay() - 1)
      fromDate = formatDate(lastWeekStart)
      toDate = formatDate(lastWeekEnd)
      break
    case '今月':
      const monthStart = new Date(now.getFullYear(), now.getMonth(), 1)
      fromDate = formatDate(monthStart)
      toDate = formatDate(now)
      break
    case '先月':
      const lastMonthStart = new Date(now.getFullYear(), now.getMonth() - 1, 1)
      const lastMonthEnd = new Date(now.getFullYear(), now.getMonth(), 0)
      fromDate = formatDate(lastMonthStart)
      toDate = formatDate(lastMonthEnd)
      break
    case '期間を指定':
      break
    default:
      fromDate = toDate = formatDate(now)
      break
  }
  return { fromDate, toDate }
}

type Props = {
  title: string
  description: string
  onChangePeriod: (...args: any[]) => void
}
const PeriodSelector = ({
  title,
  description,
  onChangePeriod,
}: Props): JSX.Element => {
  const [period, setPeriod] = useState<string>('今月')
  const [fromDate, setFromDate] = useState<string | undefined>()
  const [toDate, setToDate] = useState<string | undefined>()
  const [showDateRangeModal, setShowDateRangeModal] = useState(false)

  useEffect(() => {
    const { fromDate, toDate } = calculatePeriod(period)
    setFromDate(fromDate)
    setToDate(toDate)
    onChangePeriod({ fromDate, toDate })
  }, [period, onChangePeriod])

  const handlePeriodChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPeriod = event.target.value
    if (selectedPeriod === '期間を指定') {
      setShowDateRangeModal(true)
    } else {
      setPeriod(selectedPeriod)
    }
    setPeriod(selectedPeriod)
  }

  const handleApplyDateRange = (
    from: string | undefined,
    to: string | undefined,
  ) => {
    setPeriod('期間を指定')

    if (from || to) {
      setFromDate(from)
      setToDate(to)
      onChangePeriod({ fromDate: from, toDate: to })
    }
  }

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <StyledAggregationPeriod>
          {title}
          <OverlayTrigger
            placement='top'
            overlay={
              <Tooltip
                style={{ fontSize: '0.8em', maxWidth: '400px', width: '400px' }}
                id='unique-tooltip-id'>
                <div style={{ textAlign: 'left' }}>{description}</div>
              </Tooltip>
            }>
            <QuestionCircleOutlined
              style={{
                marginLeft: '5px',
                fontSize: '1.0em',
                color: '#888888',
                cursor: 'pointer',
              }}
            />
          </OverlayTrigger>
        </StyledAggregationPeriod>
        <select
          className='form-control'
          style={{ marginLeft: '10px', height: '38px', fontSize: '0.9em' }}
          value={period}
          onChange={handlePeriodChange}>
          <option value='今日'>今日</option>
          <option value='昨日'>昨日</option>
          <option value='今週'>今週</option>
          <option value='先週'>先週</option>
          <option value='今月'>今月</option>
          <option value='先月'>先月</option>
          <option value='期間を指定'>期間を指定</option>
        </select>
        {period === '期間を指定' && (fromDate || toDate) && (
          <StyledDateRange onClick={() => setShowDateRangeModal(true)}>
            {`${fromDate || ''} 〜 ${toDate || ''}`}
            <CalendarOutlined
              style={{ marginLeft: '5px', fontSize: '1em', color: '#495057' }}
            />
          </StyledDateRange>
        )}
      </div>
      <DateRangeModal
        show={showDateRangeModal}
        handleClose={() => setShowDateRangeModal(false)}
        handleApply={handleApplyDateRange}
      />
    </>
  )
}

const StyledAggregationPeriod = styled('span')`
  color: #888888;
  margin-right: 5px;
  font-size: 0.9em;
  font-weight: 300;
  display: flex;
  align-items: center;
  white-space: nowrap;
`

const StyledDateRange = styled('div')`
  display: flex;
  margin-left: 4px;
  border: 1px solid #e3e3e3;
  border-radius: 0.25rem;
  height: 38px;
  padding: 0.375rem 0.75rem;
  color: #495057;
  background-color: #fff;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  font-size: 0.8em;
`
export default PeriodSelector
