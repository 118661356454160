import React from 'react'
import { FilteringPeriod } from '../../../containers/entities/DiscountEntityContainer'

type Props = {
  filteringPeriod: FilteringPeriod
}

const DiscountPeriodElement: React.FC<Props> = ({ filteringPeriod }) => (
  <span
    style={{
      fontSize: 14,
      fontWeight: 'bold',
      backgroundColor: '#F0F0F0',
      padding: 5,
      borderRadius: 5,
      marginLeft: 5,
      marginBottom: 5,
    }}>
    {filteringPeriod.from && filteringPeriod.to
      ? `${filteringPeriod.from} - ${filteringPeriod.to}`
      : '未設定'}
  </span>
)

export default DiscountPeriodElement
