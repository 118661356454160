import React, { FC, memo } from 'react'
import { Data } from '../PieChart'

type Props = {
  data: Data
}

const PieChartLabel: FC<Props> = ({ data }) => (
  <div
    style={{
      display: 'flex',
      flexWrap: 'wrap',
      flexDirection: 'row',
      margin: '0px auto',
      width: '100%',
      maxWidth: 300,
    }}>
    {data.map((d) => (
      <span key={d.id} style={{ marginRight: 10, fontSize: 12 }}>
        <i
          className='fas fa-circle'
          style={{ color: d.color, marginRight: 5 }}
        />
        {d.label}
      </span>
    ))}
  </div>
)

export default memo(PieChartLabel)
