import React, { Dispatch, SetStateAction, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col, Table } from 'react-bootstrap'
import Box from '@mui/material/Box'
import { Card } from '../../../components/Card/Card'
import Button from '../../../components/CustomButton/CustomButton'
import OptionButton from '../../../components/CustomButton/OptionButton'
import MenuEntityContainer, {
  MenuInterface,
} from '../../../containers/entities/MenuEntityContainer'
import { useNotification } from '../../../providers/NotificationProvider'
import { useApi } from '../../../lib/hooks'
import { OptionInMenuResponse } from '../../../types/menu'

type Props = {
  options: OptionInMenuResponse[]
  menuId: number
  setMenu: Dispatch<SetStateAction<MenuInterface>>
  selectedFunction?: 'ec' | 'order'
}

const OptionListTable = ({
  options,
  menuId,
  setMenu,
  selectedFunction,
}: Props): JSX.Element => {
  const history = useHistory()
  const { showSuccessNotification } = useNotification()
  const menuEntityContainer = MenuEntityContainer.useContainer()
  const { getMenu } = menuEntityContainer.logic
  const { api } = useApi()

  // useMemoしてないとgoTo.newOptionでひっかかる
  const goTo = useMemo(() => {
    if (['ec', 'order'].includes(selectedFunction ?? '')) {
      return {
        newOption: () =>
          history.push(
            `/admin/${selectedFunction}/menu/option/new?menu_id=${menuId}`,
          ),
        editOption: (option) =>
          history.push(
            `/admin/${selectedFunction}/menu/option/edit?menu_id=${menuId}&option_id=${option.id}`,
          ),
      }
    }

    return {
      newOption: () => history.push(`/admin/menu/option/new?menu_id=${menuId}`),
      editOption: (option) =>
        history.push(
          `/admin/menu/option/edit?menu_id=${menuId}&option_id=${option.id}`,
        ),
    }
  }, [history, menuId, selectedFunction])

  return (
    <div style={{ marginTop: 50 }}>
      <Row style={{ marginBottom: 10 }}>
        <Col md={12}>
          <Button pullRight bsStyle='info' fill onClick={goTo.newOption}>
            <b>オプションを作成する</b>
          </Button>
        </Col>
      </Row>
      <Card
        title='オプション一覧'
        ctTableFullWidth
        content={
          <Table striped hover>
            {options?.length ? (
              <>
                <thead>
                  <tr>
                    {[
                      'オプション名',
                      'オプションメニュー',
                      '選択',
                      '複数選択',
                    ].map((prop) => (
                      <th key={`column-${prop}`}>{prop}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {options.map((option) => (
                    <tr
                      key={option.id}
                      style={{ fontWeight: 'bold', cursor: 'pointer' }}>
                      <td onClick={() => goTo.editOption(option)}>
                        {option.title}
                      </td>
                      <td onClick={() => goTo.editOption(option)}>
                        {option.option_items.map((optionItem) => (
                          <Box key={optionItem.id}>
                            {optionItem.title}(
                            {optionItem.price?.toLocaleString() || 0}円)
                          </Box>
                        ))}
                      </td>
                      <td onClick={() => goTo.editOption(option)}>
                        {option.is_required && '選択必須'}
                      </td>
                      <td onClick={() => goTo.editOption(option)}>
                        {option.is_multiple && '複数選択可能'}
                      </td>
                      <td>
                        <OptionButton
                          options={[
                            {
                              label: '詳細を見る',
                              onClick: () => goTo.editOption(option),
                            },
                            {
                              label: '削除する',
                              labelColor: '#FF4A55',
                              onClick: async () => {
                                if (
                                  !window.confirm('オプションを削除しますか？')
                                ) {
                                  return
                                }

                                const response = await api.delete(
                                  `/menus/${menuId}/options/${option.id}`,
                                )
                                if (response === null) return

                                showSuccessNotification(
                                  'オプションを削除しました。',
                                )
                                getMenu(menuId).then((newMenu) =>
                                  setMenu(newMenu),
                                )
                              },
                            },
                          ]}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </>
            ) : (
              <thead style={{ textAlign: 'center' }}>
                <tr>
                  <td>
                    <b>表示できるオプションがありません</b>
                  </td>
                </tr>
              </thead>
            )}
          </Table>
        }
      />
    </div>
  )
}

export default OptionListTable
