import React, { useMemo } from 'react'
import { Modal } from 'react-bootstrap'
import { PlusOutlined, CloseOutlined } from '@ant-design/icons'
import Button from '../../../components/CustomButton/CustomButton'

import FeatureDetail from './FeatureDetail'

type Feature =
  | 'notice'
  | 'coupon'
  | 'stamp_card'
  | 'members_card'
  | 'ticket'
  | 'order'
  | 'banner'
  | 'subscription'
  | 'calendar'
  | 'ec'

type Props = {
  selectActive?: boolean
  active?: boolean
  feature?: Feature | string
  onClickAddFeature: () => void
  onClickRemoveFeature: () => void
  show: boolean
  onHide: () => void
}

const FunctionDetailModal: React.FC<Props> = ({
  selectActive,
  active,
  feature,
  onClickAddFeature,
  onClickRemoveFeature,
  show,
  onHide,
}) => {
  const detailData = useMemo(() => {
    switch (feature) {
      case 'coupon':
        return {
          title: 'クーポン',
          image: require('../../../assets/img/features/coupon.png'),
          description:
            'お客様が商品を購入またはサービスを利用の際に配信したクーポン画面を提示してもらうことで、設定した割引・プレゼントなどの特典を提供できます。',
          what: '・ミニアプリ登録時にクーポン自動配信\n・誕生日のお客様にクーポン自動配信\n・定期的なクーポン配信（毎月1日に配信など）\n・しばらく来店がないお客様に自動でクーポン配信',
        }
      case 'stamp_card':
        return {
          title: 'スタンプカード',
          image: require('../../../assets/img/features/stamp_card.png'),
          description:
            'お客様が来店の際に、チェックインQRコードを読み込んでもらうことでスタンプを付与できます。スタンプの数に応じて設定した特典を提供することもできます。',
          what: '・一定スタンプをためると特典クーポンが自動配信\n・特典クーポンを配信するスタンプ数の登録(複数可)\n・チェックインでのスタンプ付与(一日一回)\n・お会計金額に合わせたスタンプ付与',
        }
      case 'subscription':
        return {
          title: 'サブスク',
          image: require('../../../assets/img/features/subscription.png'),
          description:
            'お客様がサブスクリプション（定額会員）形式のチケットをトイポアプリ上で購入し、来店の際に提示してもらうことで設定した特典を提供できます。',
          what: '・サブスクの契約自動更新\n・日/月での利用できる回数の制限\n・販売枚数の制限',
        }
      case 'ticket':
        return {
          title: 'チケット',
          image: require('../../../assets/img/features/ticket.png'),
          description:
            'お客様が一回券・回数券形式のチケットをトイポアプリ上で購入し、来店の際に提示してもらうことで設定した特典を提供できます。',
          what: '・使える回数の設定\n・日/月での利用できる回数の制限\n・販売枚数の制限\n・有効期限の設定\n・チケットの限定公開',
        }
      case 'members_card':
        return {
          title: '会員カード',
          image: require('../../../assets/img/features/members_card.png'),
          description:
            'お客様が来店の際に、チェックインQRコードを読み込んでもらうことでポイントを付与できます。会員のランクに応じて設定した特典を提供することもできます。',
          what: '・会員コードの配信\n・チェックイン/お会計金額でのポイントの付与\n・ポイントで交換できるクーポンの設定\n・来店/お会計金額での会員ランクの設定\n・会員ランク限定のサービスクーポン配信',
        }
      case 'banner':
        return {
          title: 'バナー',
          image: require('../../../assets/img/features/banner.png'),
          description:
            'ホーム画面にバナーを表示し、お客様がお得な情報や重要な情報を見逃さないようにできます。',
          what: '・バナーを押すとイベントページに遷移\n・バナーを押すとアプリ内のページに遷移',
        }
      case 'calendar':
        return {
          title: 'カレンダー',
          image: require('../../../assets/img/features/calendar.png'),
          description:
            'お客様が店舗情報画面から営業日・イベントなどの情報をカレンダーで確認することができます。',
          what: '・臨時休業日のカレンダー表示\n・周年記念や特別なイベントのカレンダー表示',
        }
      case 'order':
        return {
          title: 'オーダー',
          image: require('../../../assets/img/features/order.png'),
          description:
            'お客様がメニュー画面から注文することで、テイクアウトやデリバリーなどの設定した方法で商品の受け渡しができます。\n※ トイポのデリバリー配達員はいませんので、お店の方が配達する必要があります。',
          what: '・テイクアウト/デリバリーのメニュー公開\n・注文の管理\n・ステータス変更（受付/準備中など）\n・注文のステータス変更でお客様にプッシュ通知の配信\n・注文受付時に店舗アプリにプッシュ通知が届く\n・メニューの割引設定',
        }
      case 'takeout':
        return {
          title: 'テイクアウト',
          image: require('../../../assets/img/features/order.png'),
          description:
            'お客様がアプリでテイクアウトを注文することが出来るようになります。\n※ 新規注文が行われると店舗アプリに通知が届くので店舗アプリの利用を推奨しています。',
          what: '・テイクアウトのメニュー公開\n・注文の管理\n・ステータス変更（受付/準備中など）\n・注文のステータス変更でお客様にプッシュ通知の配信\n・注文受付時に店舗アプリにプッシュ通知が届く\n・メニューの割引設定',
        }
      case 'delivery':
        return {
          title: 'デリバリー',
          image: require('../../../assets/img/features/order.png'),
          description:
            'お客様がアプリでテイクアウトを注文することが出来るようになります。\n※ トイポのデリバリー配達員はいませんので、お店の方が配達する必要があります。',
          what: '・デリバリーのメニュー公開\n・注文の管理\n・ステータス変更（受付/準備中など）\n・注文のステータス変更でお客様にプッシュ通知の配信\n・注文受付時に店舗アプリにプッシュ通知が届く\n・メニューの割引設定',
        }
      case 'preorder':
        return {
          title: 'プレオーダー',
          image: require('../../../assets/img/features/order.png'),
          description:
            'お客様がアプリで事前に注文することが出来るようになります。\n※ 新規注文が行われると店舗アプリに通知が届くので店舗アプリの利用を推奨しています。',
          what: '・プレオーダーのメニュー公開\n・注文の管理\n・ステータス変更（受付/準備中など）\n・注文のステータス変更でお客様にプッシュ通知の配信\n・注文受付時に店舗アプリにプッシュ通知が届く\n・メニューの割引設定',
        }
      case 'notice':
        return {
          title: 'お知らせ',
          image: require('../../../assets/img/features/notice.png'),
          description:
            'お客様がお知らせ画面でお得な情報やお店の情報を見ることができます。\nまた、プッシュ通知と組み合わせることでお客様の端末に通知を届けることができます。',
          what: '・新メニューやお店の最新情報をお知らせ\n・臨時的な営業時間変更のお知らせ\n・お店のブログとしての活用',
        }
      case 'ec':
        return {
          title: 'オンラインショップ',
          image: require('../../../assets/img/features/order.png'),
          description: 'オンラインショップを利用。',
          what: 'オンラインショップを利用',
        }
      case 'questionnaire':
        return {
          title: '顧客満足度の可視化',
          image: require('../../../assets/img/features/questionnaire.png'),
          description:
            'お客様にアンケートを配信することで、顧客満足度を可視化することができます。\nまた、プッシュ通知と組み合わせたお知らせや、アンケートに回答するともらえるクーポンとして配信することで通常より高い回答率を期待することができます。',
          what: '・顧客満足度調査アンケートの作成\n・顧客満足度調査アンケートの配信（お知らせ・アンケート回答クーポン・URL・QRコード）\n・顧客満足度の可視化（ダッシュボード）',
        }
      case 'ranking':
        return {
          title: 'ランキング',
          image: require('../../../assets/img/features/ranking.png'),
          description:
            '期間指定のチェックイン数やポイント数などに応じたランキングを開催でき、お客様のアプリに表示できます。\nまた、順位に応じた特典を設定することでより高い参加率や来店頻度の向上が期待できます。',
          what: '・ランキングの作成\n・特典の設定\n・参加ユーザーの可視化',
        }
      default:
        return {
          title: 'その他',
          image: require('../../../assets/img/features/stamp_card.png'),
          description: '',
          what: '',
        }
    }
  }, [feature])

  return (
    <Modal show={show} aria-labelledby='contained-modal-title' onHide={onHide}>
      <Modal.Header closeButton>
        {/* タイトル */}
        <Modal.Title id='contained-modal-title'>{detailData.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <FeatureDetail
          image={detailData.image}
          description={detailData.description}
          what={detailData.what}
        />
      </Modal.Body>

      <Modal.Footer>
        {/* ボタン */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {active ? (
            <Button
              fill
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'bold',
                border: 'none',
                backgroundColor: '#C2C2C2',
                width: '100%',
                maxWidth: 300,
                padding: 12,
              }}
              onClick={onClickRemoveFeature}
              disabled={!selectActive}>
              {selectActive ? (
                <>
                  <CloseOutlined style={{ marginRight: 6 }} />
                  無効にする
                </>
              ) : (
                'この機能は無効にできません'
              )}
            </Button>
          ) : (
            <Button
              fill
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontWeight: 'bold',
                border: 'none',
                backgroundColor: '#000',
                width: '100%',
                maxWidth: 300,
                padding: 12,
              }}
              onClick={onClickAddFeature}>
              <PlusOutlined style={{ marginRight: 6 }} />
              追加する
            </Button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default FunctionDetailModal
