import apiClient from './apiClient'
import { loadViewObj } from '../viewObj'

type ParamsProps = {
  params: {
    active: boolean
  }
}

// eslint-disable-next-line import/prefer-default-export
export const updateCalendarFeature = async ({ params }: ParamsProps) => {
  const UPDATE_CALENDAR_FEATURE = `/staff/stores/${
    loadViewObj()?.id
  }/calendar_features`

  const requestParams = {
    calendar_feature: params,
  }

  const response = await apiClient({
    method: 'PATCH',
    uri: UPDATE_CALENDAR_FEATURE,
    params: requestParams,
  })

  return response.data
}
