import React, { ReactNode, useState, useEffect, useRef } from 'react'
import { styled } from '@mui/material/styles'
import { CircularProgress } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'

type Props<T> = {
  show: boolean
  loading: boolean
  items: T[]
  placeholder: string
  emptyLabel: string
  renderItem: (item: T) => ReactNode
  onHide: () => void
  onFilter: (text: string) => void
}

const InstanceFilterDialog = <T,>({
  show,
  loading,
  items,
  placeholder,
  emptyLabel,
  renderItem,
  onHide,
  onFilter,
}: Props<T>): JSX.Element => {
  const filterInputRef = useRef<HTMLInputElement>(null)
  const [filterText, setFilterText] = useState<string>('')

  useEffect(() => {
    if (show) {
      setTimeout(() => filterInputRef.current?.focus(), 100)
    }
  }, [show])

  return (
    <Dialog fullWidth maxWidth='sm' open={show} onClose={onHide}>
      <DialogContent sx={{ p: 0 }}>
        <StyledInputContainer>
          <StyledInput
            ref={filterInputRef}
            type='text'
            placeholder={placeholder}
            value={filterText}
            onChange={(e) => {
              setFilterText(e.target.value)
              onFilter(e.target.value)
            }}
          />
        </StyledInputContainer>

        {loading ? (
          <StyledProgressContainer>
            <CircularProgress />
          </StyledProgressContainer>
        ) : (
          <StyledItemContainer>
            {items.length === 0 ? (
              <StyledEmptyContainer>
                <i className='ri-search-line' />

                {emptyLabel}
              </StyledEmptyContainer>
            ) : (
              items.map((item) => renderItem(item))
            )}
          </StyledItemContainer>
        )}
      </DialogContent>
    </Dialog>
  )
}

const StyledInputContainer = styled('div')({
  display: 'flex',
  padding: '12px 8px',
  borderBottom: 'solid 1px #eee',
})
const StyledInput = styled('input')({
  flexGrow: 1,
  borderWidth: 0,
  fontSize: 16,
})

const StyledProgressContainer = styled('div')({
  width: '100%',
  height: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const StyledItemContainer = styled('div')({
  height: 300,
  overflowY: 'scroll',
})
const StyledEmptyContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: 16,
  fontSize: 16,
  color: '#aaa',
  '& > i': { marginRight: 8 },
})

export default InstanceFilterDialog
