/* eslint @typescript-eslint/camelcase: 0 */
/* eslint camelcase: 0 */
import apiClient from './apiClient'
import { StoreStaffInterface } from '../../containers/entities/StoreStaffEntityContainer'
import { isViewObjGroup } from '../viewObj'

export const giveAuthToStaff = async ({
  groupId,
  staffId,
  storeId,
}: {
  groupId: number
  staffId: number
  storeId: number
}): Promise<StoreStaffInterface> => {
  const GIVE_AUTH_TO_STAFF = `/staff/stores/${groupId}/staffs/${staffId}/store_staffs`

  const params = {
    store_staff: {
      store_id: storeId,
      role: 'admin',
    },
  }

  const response = await apiClient({
    method: 'POST',
    params,
    uri: GIVE_AUTH_TO_STAFF,
  })
  return response.data
}

export const removeAuthFromStaff = async ({
  storeId,
  staffId,
  groupId,
}: {
  storeId: number
  staffId: number
  groupId: number
}): Promise<StoreStaffInterface> => {
  const id = isViewObjGroup() ? groupId : storeId
  const REMOVE_AUTH_FROM_STAFF = `/staff/stores/${id}/staffs/${staffId}/store_staffs?delete_store_id=${storeId}`

  const response = await apiClient({
    method: 'DELETE',
    uri: REMOVE_AUTH_FROM_STAFF,
  })
  return response.data
}
