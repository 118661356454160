import { useState } from 'react'
import { createContainer } from 'unstated-next'

const useAppHook = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const state = {
    isLoading,
    disabled,
  }

  const setAppState = {
    isLoading: setIsLoading,
    disabled: setDisabled,
  }

  const logic = {}

  return { state, setAppState, logic }
}

export default createContainer(useAppHook)
