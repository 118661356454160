import React, { memo, FC } from 'react'

type Props = {
  centerX: number
  centerY: number
  label: string
  unit: string
  value?: string | number
}

const PieChartCenterLabel: FC<Props> = ({
  centerX,
  centerY,
  label,
  unit,
  value,
}) => (
  <>
    <text
      x={centerX}
      y={centerY - 15}
      textAnchor='middle'
      dominantBaseline='central'
      style={{
        fontSize: 14,
      }}
      fill='#6F6F6F'>
      {label}
    </text>
    <text
      x={centerX}
      y={label ? centerY + 20 : centerY + 10}
      textAnchor='middle'
      dominantBaseline='auto'
      style={{
        fontSize: 24,
        fontWeight: 600,
      }}
      fill='#4D535E'>
      {value && value.toLocaleString()}
      <tspan
        style={{
          fontSize: 12,
          fontWeight: 400,
        }}>
        {unit}
      </tspan>
    </text>
  </>
)

export default memo(PieChartCenterLabel)
