import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import OutlinedInput from '@mui/material/OutlinedInput'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { UberDirectDeliveryFeeType } from '../../../types/order'
import DialogTitle from '../../../components/DialogTitle'
import FormLabel from '../../../components/FormInputs/FormLabel'

type DeliveryFeeForm = {
  uber_direct_delivery_distance_fee_rate: number
  uber_direct_delivery_fee_type: UberDirectDeliveryFeeType
  delivery_price: number
}

type Props = {
  open: boolean
  uberDirectEnabled: boolean
  defaultValues: DeliveryFeeForm
  disabled: boolean
  onClose: () => void
  onSubmit: (params: DeliveryFeeForm) => void
}

const DeliveryFeeDialog = ({
  open,
  uberDirectEnabled,
  defaultValues,
  disabled,
  onClose,
  onSubmit,
}: Props): JSX.Element => {
  const [feeType, setFeeType] = useState<UberDirectDeliveryFeeType>('fixed')
  const [distanceFeeRate, setDistanceFeeRate] = useState<number>(0)
  const [fixedFee, setFixedFee] = useState<number>(0)

  useEffect(() => {
    if (open) {
      setFeeType(
        uberDirectEnabled
          ? defaultValues.uber_direct_delivery_fee_type
          : 'fixed',
      )
      setDistanceFeeRate(
        defaultValues.uber_direct_delivery_distance_fee_rate * 100,
      )
      setFixedFee(defaultValues.delivery_price)
    }
  }, [
    open,
    uberDirectEnabled,
    defaultValues.delivery_price,
    defaultValues.uber_direct_delivery_fee_type,
    defaultValues.uber_direct_delivery_distance_fee_rate,
  ])

  const handleSubmit = () => {
    onSubmit({
      uber_direct_delivery_distance_fee_rate: distanceFeeRate / 100,
      uber_direct_delivery_fee_type: feeType,
      delivery_price: fixedFee,
    })
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose} maxWidth='sm'>
      <DialogTitle onClose={onClose}>配達料金</DialogTitle>

      <DialogContent>
        <FormLabel label='配達料金' />
        <RadioGroup
          row
          value={feeType}
          sx={{ mb: 2 }}
          onChange={(e) =>
            setFeeType(e.target.value as UberDirectDeliveryFeeType)
          }>
          <FormControlLabel
            value='fixed'
            control={<Radio disabled={disabled} />}
            label='固定値を指定'
          />

          {uberDirectEnabled && (
            <FormControlLabel
              value='distance'
              control={<Radio disabled={disabled} />}
              label='距離に応じて計算'
            />
          )}
        </RadioGroup>

        {feeType === 'fixed' && (
          <>
            <FormLabel label='配達料金(距離に関わらず固定)' />
            <Box display='flex' alignItems='center'>
              <OutlinedInput
                value={fixedFee}
                type='number'
                sx={{ mr: 1 }}
                onChange={(e) => {
                  const valueAsNumber = Number(e.target.value)
                  setFixedFee(valueAsNumber < 0 ? 0 : valueAsNumber)
                }}
              />
              円
            </Box>
          </>
        )}

        {feeType === 'distance' && (
          <>
            <FormLabel label='配達料のユーザー負担割合' />
            <Box display='flex' alignItems='center'>
              <OutlinedInput
                value={distanceFeeRate}
                type='number'
                sx={{ mr: 1 }}
                onChange={(e) => {
                  const valueAsNumber = Number(e.target.value)
                  const feeRate =
                    valueAsNumber < 0
                      ? 0
                      : valueAsNumber > 100
                        ? 100
                        : valueAsNumber
                  setDistanceFeeRate(feeRate)
                }}
              />
              %
            </Box>
          </>
        )}
      </DialogContent>

      <DialogActions sx={{ pt: 2 }}>
        <Button
          fullWidth
          variant='outlined'
          color='cancel'
          disabled={disabled}
          onClick={onClose}>
          閉じる
        </Button>

        <Button
          fullWidth
          variant='contained'
          color='submit'
          disabled={disabled}
          onClick={handleSubmit}>
          更新する
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeliveryFeeDialog
