import React from 'react'
import { useHistory } from 'react-router-dom'
import Button from '../CustomButton/CustomButton'
import { isOwner } from '../../lib/staff'
import { useLoginContext } from '../../providers/LoginContextProvider'

interface Props {
  functionName: string
}

const TenantAlert = ({ functionName }: Props): JSX.Element => {
  const history = useHistory()
  const { currentOwner, cardApplicationStatus } = useLoginContext()

  if (!currentOwner) {
    return <></>
  }

  if (
    !isOwner() &&
    (!currentOwner.has_tenant || cardApplicationStatus !== 'passed')
  ) {
    return (
      <div className='tenantAlertContainer'>
        <div style={{ color: '#01B902', display: 'flex' }}>
          <div style={{ alignSelf: 'center' }}>
            <span>
              {!currentOwner.has_tenant &&
                `${functionName}機能を利用するには口座登録およびクレジット利用申請が必要です。オーナー権限を持つアカウントにて設定を行ってください。`}
              {currentOwner.has_tenant &&
                cardApplicationStatus === 'notApply' &&
                `${functionName}機能を利用するにはクレジット利用申請が必要です。オーナー権限を持つアカウントにてクレジット利用申請を行ってください。`}
              {currentOwner.has_tenant &&
                cardApplicationStatus === 'inReview' &&
                'クレジット利用申請中です。今しばらくお待ち下さい。'}
            </span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {currentOwner.has_tenant && cardApplicationStatus === 'passed' ? null : (
        <div className='tenantAlertContainer'>
          <div style={{ color: '#01B902', display: 'flex' }}>
            <div style={{ alignSelf: 'center' }}>
              <span>
                {!currentOwner.has_tenant &&
                  `${functionName}機能を利用するには口座登録およびクレジット利用申請が必要です。`}
                {currentOwner.has_tenant &&
                  cardApplicationStatus === 'notApply' &&
                  `${functionName}機能を利用するにはクレジット利用申請が必要です。`}
                {currentOwner.has_tenant &&
                  cardApplicationStatus === 'inReview' &&
                  'クレジット利用申請中です。今しばらくお待ち下さい。'}
              </span>
            </div>
            {!currentOwner.has_tenant ||
            cardApplicationStatus !== 'inReview' ? (
              <div style={{ marginLeft: 'auto', display: 'inline' }}>
                <Button
                  style={{
                    display: 'inline',
                    backgroundColor: '#01B902',
                    border: '1px solid #01B902',
                    marginnLeft: 'auto',
                  }}
                  fill
                  onClick={() => history.push('/admin/owner')}>
                  <b>{!currentOwner.has_tenant ? '登録する' : '申請する'}</b>
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </>
  )
}

export default TenantAlert
