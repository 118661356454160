import RedPreviewImage from '../assets/img/members_card_previews/members_card_red.png'
import BluePreviewImage from '../assets/img/members_card_previews/members_card_blue.png'
import TurquoiseBluePreviewImage from '../assets/img/members_card_previews/members_card_turquoise_blue.png'
import YellowPreviewImage from '../assets/img/members_card_previews/members_card_yellow.png'
import GreenPreviewImage from '../assets/img/members_card_previews/members_card_green.png'
import BronzePreviewImage from '../assets/img/members_card_previews/members_card_bronze.png'
import SilverPreviewImage from '../assets/img/members_card_previews/members_card_silver.png'
import GoldPreviewImage from '../assets/img/members_card_previews/members_card_gold.png'
import WhitePreviewImage from '../assets/img/members_card_previews/members_card_white.png'
import BlackPreviewImage from '../assets/img/members_card_previews/members_card_black.png'
import LightGrayPreviewImage from '../assets/img/members_card_previews/members_card_light_gray.png'

export const RankUpMethodLabel = {
  rank_up_by_qrcode: 'QRコード',
  rank_up_by_check_in: 'チェックイン',
  rank_up_by_payment: 'お会計',
  rank_up_by_point: 'ポイント',
} as const

export const PaymentPointsLabel = {
  disable: '無効',
  only_toypo_payment: 'トイポ内決済のみ',
  only_store_payment: '店舗決済のみ',
  everything: 'すべて有効',
}

export const PaymentPoints = [
  'disable',
  'only_toypo_payment',
  'only_store_payment',
  'everything',
] as const

export const MembersCardRankStatusLabel = {
  open: '公開',
  close: '非公開',
}

export const MembersCardStatusLabel = {
  draft: '下書き',
  open: '公開',
  close: '非公開',
}

export const MemberCardRankColors = [
  'blue',
  'turquoise_blue',
  'red',
  'green',
  'yellow',
  'bronze',
  'silver',
  'gold',
  'white',
  'black',
  'light_gray',
] as const

export const MemberCardRankImages = {
  blue: BluePreviewImage,
  turquoise_blue: TurquoiseBluePreviewImage,
  yellow: YellowPreviewImage,
  red: RedPreviewImage,
  green: GreenPreviewImage,
  bronze: BronzePreviewImage,
  silver: SilverPreviewImage,
  gold: GoldPreviewImage,
  white: WhitePreviewImage,
  black: BlackPreviewImage,
  light_gray: LightGrayPreviewImage,
} as const

export const MemberCardRankColorLabels = {
  blue: 'ブルー',
  turquoise_blue: 'ターコイズブルー',
  red: 'レッド',
  green: 'グリーン',
  yellow: 'イエロー',
  bronze: 'ブロンズ',
  silver: 'シルバー',
  light_gray: 'ライトグレー',
  gold: 'ゴールド',
  white: 'ホワイト',
  black: 'ブラック',
} as const
