import { useCallback, useState } from 'react'
import { createContainer } from 'unstated-next'
import { noticeTemplateApi } from '../../lib/api'
import EntityContainerInterface from './_EntityContainerInterface'

export interface NoticeTemplateInterface {
  id: number
  name: string
  store_id: number
  title: string
  body: string
  image_url: string
  do_push: boolean
  created_at: Date
}

export interface NoticeTemplateParamsInterface {
  name: string
  title: string
  body: string
  image: string
  do_push: boolean
}

const useNoticeEntityHook = (): EntityContainerInterface => {
  const [noticeTemplates, setNoticeTemplates] = useState<
    NoticeTemplateInterface[]
  >([])

  const updateNoticeTemplateState = useCallback(
    (newNotice) => {
      const index = noticeTemplates.findIndex(
        (notice) => notice.id === newNotice.id,
      )
      setNoticeTemplates([
        ...noticeTemplates.slice(0, index),
        newNotice,
        ...noticeTemplates.slice(index + 1),
      ])
    },
    [noticeTemplates],
  )

  const addNoticeTemplateState = useCallback(
    (newNotice) => {
      setNoticeTemplates([newNotice, ...noticeTemplates])
    },
    [noticeTemplates],
  )

  return {
    state: { noticeTemplates },
    constant: {},
    logic: {
      setNoticeTemplates: setNoticeTemplates,
      getNoticeTemplates: useCallback(async (): Promise<
        NoticeTemplateInterface[]
      > => {
        const newNoticeTemplates: NoticeTemplateInterface[] =
          await noticeTemplateApi.getNoticeTemplates()
        setNoticeTemplates(newNoticeTemplates)
        return newNoticeTemplates
      }, []),
      createNoticeTemplate: async (
        params: NoticeTemplateParamsInterface,
      ): Promise<NoticeTemplateInterface> => {
        const newNoticeTemplate: NoticeTemplateInterface =
          await noticeTemplateApi.createNoticeTamplate({
            params,
          })
        addNoticeTemplateState(newNoticeTemplate)
        return newNoticeTemplate
      },
      updateNoticeTemplate: async (
        id: number,
        params: NoticeTemplateParamsInterface,
      ): Promise<NoticeTemplateInterface> => {
        const newNoticeTemplate: NoticeTemplateInterface =
          await noticeTemplateApi.updateNoticeTemplate({
            template_notice_id: id,
            params,
          })
        updateNoticeTemplateState(newNoticeTemplate)
        return newNoticeTemplate
      },
      deleteNoticeTemplate: async (
        id: number,
      ): Promise<{ message: string }> => {
        const response = await noticeTemplateApi.deleteNoticeTemplate({
          template_notice_id: id,
        })
        return response
      },
    },
  }
}

export default createContainer(useNoticeEntityHook)
