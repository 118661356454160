import React, { useEffect, useState } from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useBooleanState, useApi } from '../../../../lib/hooks'
import { MenuResource, MenusResponse } from '../../../../types/menu'
import MenuStatusChip from '../../../Chip/MenuStatusChip'
import { Condition, ConditionType } from '../../type'
import ConditionFormItem from './ConditionFormItem'
import InstanceItem from './InstanceItem'
import InstanceFilterDialog from './InstanceFilterDialog'

type Props = {
  condition: Condition
  onSelectMenu: (menu: MenuResource) => void
  style: React.CSSProperties
}

const MenuFormItem = ({
  condition,
  onSelectMenu,
  style,
}: Props): JSX.Element => {
  const [isModalOpen, openModal, closeModal] = useBooleanState(false)
  const { api, loading, response } = useApi<MenusResponse>()
  const [filterText, setFilterText] = useState<string>('')
  const subscriptionContents = (response || []).filter(
    ({ title }) => !filterText || title.indexOf(filterText) > -1,
  )

  useEffect(() => {
    if (isModalOpen) {
      api.get('/menus', {
        status: 'open',
        menu_type: calculateMenuTypeForFilter(condition.condition_type),
      })
    }
  }, [isModalOpen, api, condition.condition_type])

  return (
    <>
      <ConditionFormItem label='メニュー' {...style}>
        <Select
          readOnly
          fullWidth
          value='default'
          style={{ backgroundColor: '#fff' }}
          sx={{ '& > .MuiSelect-select': { width: 370 } }}
          onClick={openModal}>
          <MenuItem value='default'>
            {condition.menu?.title || 'すべてのメニュー'}
          </MenuItem>
        </Select>
      </ConditionFormItem>

      <InstanceFilterDialog
        show={isModalOpen}
        loading={loading}
        items={subscriptionContents}
        placeholder='メニュー名を入力して検索...'
        emptyLabel={
          Boolean(filterText)
            ? `${filterText}を含むメニューが見つかりません。`
            : 'メニューがありません。'
        }
        renderItem={(menu) => (
          <InstanceItem
            key={menu.id}
            title={menu.title}
            imageUrl={menu.image_url}
            status={<MenuStatusChip status={menu.status} />}
            note={menu.category_label}
            onClick={() => {
              onSelectMenu(menu)
              closeModal()
            }}
          />
        )}
        onHide={closeModal}
        onFilter={setFilterText}
      />
    </>
  )
}

const calculateMenuTypeForFilter = (conditionType: ConditionType) => {
  switch (conditionType) {
    case 'mobile_order':
    case 'mobile_order_by_order_date':
      return ''

    case 'mobile_order_takeout':
    case 'mobile_order_takeout_by_order_date':
      return 'takeout'

    case 'mobile_order_delivery':
    case 'mobile_order_delivery_by_order_date':
      return 'delivery'

    case 'mobile_order_preorder':
    case 'mobile_order_preorder_by_order_date':
      return 'preorder'

    case 'mobile_order_ec':
    case 'mobile_order_ec_by_order_date':
      return 'ec'
  }
}

export default MenuFormItem
