import React, { FC } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { Card } from '../../../components/Card/Card'
import LoadingButton from '../../../components/CustomButton/LoadingButton'
import SelectorForm from '../../../components/FormInputs/SelectorForm'

import {
  ServiceContentInterface,
  ServiceContentStatus,
} from '../../../containers/entities/ServiceContentEntityContainer'

const optionArray = (status?: ServiceContentStatus): ServiceContentStatus[] => {
  switch (status) {
    case 'draft':
    case undefined:
      return ['draft', 'open']
    default:
      return ['open', 'close']
  }
}

type Props = {
  editMode: boolean
  watchStatus: ServiceContentStatus
  serviceContent?: ServiceContentInterface
  statusesToJp: (status: ServiceContentStatus) => string
  onClickStatusUpdateButton: (status: ServiceContentStatus) => Promise<void>
}

const ServicePublishSettingCard: FC<Props> = ({
  editMode,
  watchStatus,
  serviceContent,
  statusesToJp,
  onClickStatusUpdateButton,
}) => {
  const { register } = useFormContext<{
    service_content: ServiceContentInterface
  }>()

  return (
    <Card
      title='公開設定'
      content={
        <>
          <SelectorForm
            name='service_content.status'
            inputRef={register}
            width={180}
            style={{ marginBottom: 0 }}
            options={optionArray(serviceContent?.status).map((status) => ({
              label: statusesToJp(status),
              value: status,
            }))}
          />
          {editMode && (
            <ServicePublishSettingCardButtonGroup
              watchStatus={watchStatus}
              onClickStatusUpdateButton={onClickStatusUpdateButton}
              serviceContent={serviceContent}
            />
          )}
        </>
      }
    />
  )
}

export default ServicePublishSettingCard

type ServicePublishSettingCardButtonGroupProps = Pick<
  Props,
  'watchStatus' | 'onClickStatusUpdateButton' | 'serviceContent'
>

const ServicePublishSettingCardButtonGroup: FC<
  ServicePublishSettingCardButtonGroupProps
> = ({ watchStatus, onClickStatusUpdateButton, serviceContent }) => (
  <Row
    style={{
      marginRight: 0,
      display: 'flex',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
    }}>
    <Col
      style={{
        marginLeft: 10,
        marginTop: 5,
        marginBottom: 5,
      }}>
      <LoadingButton
        label='変更する'
        loadingLabel='変更中...'
        color='info'
        fill
        simple
        pullRight
        loadingColor='red'
        disabled={serviceContent?.status === watchStatus}
        onClick={async (): Promise<void> => {
          await onClickStatusUpdateButton(watchStatus)
        }}
      />
    </Col>
  </Row>
)
