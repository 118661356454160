import { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import {
  AxiosResponse,
  AxiosRequestConfig,
  AxiosError,
  ResponseType,
} from 'axios'
import { useMediaQuery } from 'react-responsive'
import { loadViewObj } from './viewObj'
import apiClient from './api/apiClient'
import { useNotification } from '../providers/NotificationProvider'

export function usePcSizeFlag(): boolean {
  const isPcOrTablet = useMediaQuery({ query: '(min-width: 767px)' })
  return useMemo(() => isPcOrTablet, [isPcOrTablet])
}

export function useMobileFlag(): boolean {
  return !usePcSizeFlag()
}

export function useQuery(): URLSearchParams {
  const { search } = useLocation()
  return useMemo<URLSearchParams>(() => new URLSearchParams(search), [search])
}

type UseBooleanStateReturnType = [boolean, () => void, () => void, () => void]
export function useBooleanState(
  defaultState: boolean,
): UseBooleanStateReturnType {
  const [value, setValue] = useState<boolean>(defaultState)

  return [
    value,
    useCallback(() => setValue(true), []),
    useCallback(() => setValue(false), []),
    useCallback(() => setValue((prev) => !prev), []),
  ]
}

export const useOnceEffect = (callback: Function) => {
  const ref = useRef(false)

  useEffect(() => {
    if (!ref.current) {
      callback()
      ref.current = true
    }
  }, [callback])
}

export const usePreviousState = <T>(value: T | null): T | null => {
  const ref = useRef<T | null>(null)

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

type ApiTypeArgs = {
  uri: string
  showErrorNotification?: boolean
  errorMessage?: string
  responseType?: ResponseType
}

type UpdateApiTypeArgs = ApiTypeArgs & {
  params?: any
}

type GetApiTypeArgs = UpdateApiTypeArgs & {
  otherOptions?: Omit<AxiosRequestConfig, 'url' | 'params'>
}

type ApiType<Response> = {
  api: {
    get: (
      path: string | GetApiTypeArgs,
      params?: object,
      options?: Options,
      axiosConfig?: Omit<AxiosRequestConfig, 'url' | 'params'>,
    ) => Promise<AxiosResponse<Response>>
    post: (
      path: string,
      params?: object,
      options?: Options,
      axiosConfig?: Omit<AxiosRequestConfig, 'url' | 'params'>,
    ) => Promise<AxiosResponse<Response>>
    put: (
      path: string,
      params?: object,
      options?: Options,
      axiosConfig?: Omit<AxiosRequestConfig, 'url' | 'params'>,
    ) => Promise<AxiosResponse<Response>>
    patch: (
      path: string,
      params?: object,
      options?: Options,
      axiosConfig?: Omit<AxiosRequestConfig, 'url' | 'params'>,
    ) => Promise<AxiosResponse<Response>>
    delete: (
      path: string | ApiTypeArgs,
      params?: object,
      options?: Options,
      axiosConfig?: Omit<AxiosRequestConfig, 'url' | 'params'>,
    ) => Promise<AxiosResponse<Response>>
    blob: (
      path: string,
      params?: object,
      options?: Omit<Options, 'responseType'>,
      axiosConfig?: Omit<AxiosRequestConfig, 'url' | 'params'>,
    ) => Promise<AxiosResponse<Response>>
  }
  response: Response | null
  status: number | null
  loading: boolean
  loaded: boolean
  headers: any
  totalPages: number
  currentPage: number
  reset: () => void
}

type Options = {
  showErrorNotification?: boolean
  errorMessage?: string
  responseType?: ResponseType
  skipSetAuth?: boolean
  throwsError?: boolean
  timeout?: number
}

export function useApi<Response = any>(baseUri = ''): ApiType<Response> {
  const [response, setResponse] = useState<Response | null>(null)
  const [error, setError] = useState<AxiosError | null>(null)
  const [status, setStatus] = useState<number | null>(null)
  const [headers, setHeaders] = useState<any | null>(null)
  const [loading, startLoading, finishLoading] = useBooleanState(false)
  const { showErrorNotification: _showErrorNotification } = useNotification()

  const call = useCallback(
    async ({
      method,
      uri,
      params = {},
      axiosConfig,
      showErrorNotification,
      errorMessage,
      responseType,
      skipSetAuth,
      throwsError,
      timeout,
    }): Promise<AxiosResponse<Response> | any> => {
      startLoading()

      try {
        const res = await apiClient({
          method,
          uri: (baseUri || `/staff/stores/${loadViewObj()?.id}`) + uri,
          params,
          config: axiosConfig,
          responseType,
          skipSetAuth,
          timeout,
        })

        setStatus(res.status)
        setHeaders(res.headers)
        setResponse(res.data)
        return res
      } catch (error) {
        if (throwsError) {
          throw error
        } else if (showErrorNotification) {
          _showErrorNotification(
            errorMessage ||
              (error as AxiosError).response?.data?.message ||
              (error as AxiosError).message,
          )
        }

        setResponse(null)
        if (error.response) setStatus(error.response.status)
        setError(error)
        return null
      } finally {
        finishLoading()
      }
    },
    [baseUri, startLoading, finishLoading, _showErrorNotification],
  )

  const api = useMemo(
    () => ({
      get: (
        path: string | GetApiTypeArgs,
        params = {},
        options: Options = { showErrorNotification: true },
        axiosConfig = {},
      ) => {
        // 以前は引数をオブジェクトで受け取る形となっていたが、
        // 毎回`get({uri: xxx, params: {}})`という風に
        // 書くのは手間な為、`get(path, params)`という形で
        // 受け取れるように対応した。
        // 現状、引数をオブジェクトで受け取る実装が残っているため、
        // 最初の引数がstring型かobject型かで処理を分けている。
        // 今後の実装は`get(path, params)`の形で統一していく。
        if (typeof path === 'string') {
          return call({
            method: 'GET',
            uri: path,
            params: { params, ...axiosConfig },
            showErrorNotification: options.showErrorNotification,
            errorMessage: options.errorMessage,
            responseType: options.responseType,
            skipSetAuth: options.skipSetAuth,
            throwsError: options.throwsError,
            timeout: options.timeout,
          })
        }

        return call({
          method: 'GET',
          uri: path.uri,
          params: { params: path.params, ...path.otherOptions },
          showErrorNotification: path.showErrorNotification ?? true,
          errorMessage: path.errorMessage,
          responseType: path.responseType,
          throwsError: options.throwsError,
          timeout: options.timeout,
        })
      },
      post: (
        path: string,
        params = {},
        options: Options = { showErrorNotification: true },
        axiosConfig = {},
      ) =>
        call({
          method: 'POST',
          uri: path,
          params,
          axiosConfig,
          showErrorNotification: options.showErrorNotification,
          errorMessage: options.errorMessage,
          responseType: options.responseType,
          skipSetAuth: options.skipSetAuth,
          throwsError: options.throwsError,
          timeout: options.timeout,
        }),
      put: (
        path: string,
        params = {},
        options: Options = { showErrorNotification: true },
        axiosConfig = {},
      ) =>
        call({
          method: 'PUT',
          uri: path,
          params,
          axiosConfig,
          showErrorNotification: options.showErrorNotification,
          errorMessage: options.errorMessage,
          throwsError: options.throwsError,
          timeout: options.timeout,
        }),
      patch: (
        path: string,
        params = {},
        options: Options = { showErrorNotification: true },
        axiosConfig = {},
      ) =>
        call({
          method: 'PATCH',
          uri: path,
          params,
          axiosConfig,
          showErrorNotification: options.showErrorNotification,
          errorMessage: options.errorMessage,
          skipSetAuth: options.skipSetAuth,
          throwsError: options.throwsError,
          timeout: options.timeout,
        }),
      delete: (
        path: string | ApiTypeArgs,
        params = {},
        options: Options = { showErrorNotification: true },
        axiosConfig = {},
      ) => {
        if (typeof path === 'string') {
          return call({
            method: 'DELETE',
            uri: path,
            params: { params, ...axiosConfig },
            showErrorNotification: options.showErrorNotification,
            errorMessage: options.errorMessage,
            skipSetAuth: options.skipSetAuth,
            throwsError: options.throwsError,
            timeout: options.timeout,
          })
        }

        return call({
          method: 'DELETE',
          uri: path.uri,
          showErrorNotification: path.showErrorNotification ?? true,
          errorMessage: path.errorMessage,
          throwsError: options.throwsError,
          timeout: options.timeout,
        })
      },
      blob: (
        path: string,
        params = {},
        options: Omit<Options, 'responseType'> = {
          showErrorNotification: true,
        },
        axiosConfig = {},
      ) =>
        call({
          method: 'GET',
          uri: path,
          params: { params, ...axiosConfig },
          showErrorNotification: options.showErrorNotification,
          errorMessage: options.errorMessage,
          responseType: 'blob',
          throwsError: options.throwsError,
          timeout: options.timeout,
        }),
    }),
    [call],
  )

  const reset = useCallback(() => {
    setResponse(null)
    setStatus(null)
    setHeaders(null)
  }, [])

  return {
    api,
    response,
    status,
    loading,
    loaded: response !== null || error !== null,
    headers,
    totalPages: headers ? Number(headers['total-pages']) || -1 : -1,
    currentPage: headers ? Number(headers['current-page']) || -1 : -1,
    reset,
  }
}

type UseGetApiCallType<Response> = ApiType<Response> & {
  reload: () => Promise<AxiosResponse<Response>>
}
export function useGetApiCall<Response = any>(
  url: string,
  params?: { [key: string]: string },
  baseUri?: string,
): UseGetApiCallType<Response> {
  const api = useApi<Response>(baseUri)
  const { get } = api.api

  const queryString = new URLSearchParams(params || {}).toString()
  const requestPath = queryString.length > 0 ? `${url}?${queryString}` : url

  const reload = useCallback(
    () => get(requestPath, {}, { showErrorNotification: false }),
    [requestPath, get],
  )

  useEffect(() => {
    reload()
  }, [reload])

  return {
    ...api,
    reload,
  }
}

export const useApiClient = (baseUri: string = '') => {
  const { showErrorNotification } = useNotification()

  const call = useCallback(
    async (
      method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE',
      path: string,
      params: object = {},
      options: Options = { showErrorNotification: true },
      config: Omit<AxiosRequestConfig, 'url' | 'params'> = {},
    ) =>
      await apiClient({
        method,
        uri: (baseUri || `/staff/stores/${loadViewObj()?.id}`) + path,
        params,
        config,
      }).catch((error) => {
        if (options.showErrorNotification) {
          showErrorNotification(
            options.errorMessage ||
              (error as AxiosError).response?.data?.message ||
              (error as AxiosError).message,
          )
        }
        return null
      }),
    [baseUri, showErrorNotification],
  )

  return call
}
