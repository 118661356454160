export const Genders = ['male', 'female', 'other'] as const
export const GenderLabel = {
  male: '男性',
  female: '女性',
  other: 'その他',
} as const

export const Generations = [
  'child',
  'teens',
  'twenties',
  'thirties',
  'forties',
  'fifties',
  'sixties',
  'seventies',
  'eighties',
] as const
export const GenerationLabel = {
  child: '10代未満',
  teens: '10代',
  twenties: '20代',
  thirties: '30代',
  forties: '40代',
  fifties: '50代',
  sixties: '60代',
  seventies: '70代',
  eighties: '80代以上',
} as const

export const ThirdPartyServices = ['toypo', 'line'] as const
export const ThirdPartyServiceLabel = {
  toypo: 'toypoミニアプリ',
  line: 'LINEミニアプリ',
} as const
