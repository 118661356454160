import React from 'react'
import styled from '@emotion/styled'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Info, ArrowUpRight } from 'phosphor-react'
import {
  shouldMaskMeasurementValue,
  shouldShowMeasurementValueNote,
  measurementValueNote,
} from '../../lib/general'

type Props = {
  value: number
  diff: number
  createdAt: string
}

const ContentMeasurementValueAndDiff = ({
  value,
  diff,
  createdAt,
}: Props): JSX.Element => {
  const children = (
    <StyledContainer>
      {shouldMaskMeasurementValue(createdAt, value) ? (
        '---'
      ) : (
        <>
          <div>{value.toLocaleString()}</div>

          {diff > 0 && (
            <>
              <ArrowUpRight
                weight='bold'
                color='#26ABA4'
                size={13}
                style={{ margin: '0 2px' }}
              />
              <StyledDiff>{diff.toLocaleString()}</StyledDiff>
            </>
          )}
        </>
      )}
    </StyledContainer>
  )

  return shouldShowMeasurementValueNote(createdAt, value) ? (
    <OverlayTrigger
      placement='left'
      overlay={
        <Tooltip>{measurementValueNote('クーポン', createdAt)}</Tooltip>
      }>
      <StyledContainer>
        <Info style={{ marginRight: 4 }} />
        {children}
      </StyledContainer>
    </OverlayTrigger>
  ) : (
    children
  )
}

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
`

const StyledDiff = styled.div`
  font-weight: bold;
  font-size: 13;
  color: #26aba4;
`

export default ContentMeasurementValueAndDiff
