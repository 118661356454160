import React, { ChangeEvent, Dispatch, FC, SetStateAction } from 'react'
import moment from 'moment'
import SelectorForm from '../../../components/FormInputs/SelectorForm'
import {
  DataPeriod,
  DataPeriodType,
  DataType,
  DataUnit,
} from '../../../containers/entities/AnalyticsEntityContainer'

type Props = {
  dataType: DataType
  selectedUnit: DataUnit
  setDataPeriod: Dispatch<SetStateAction<DataPeriod>>
  dataPeriod: DataPeriod
  onShowDataPeriodModal: () => void
  setSelectedDataType: Dispatch<SetStateAction<DataType | null>>
  dataPeriodTypeToPeriod: {
    [key in DataPeriodType]: { from: string; to: string }
  }
}

type Options = {
  value: DataPeriodType
  label: string
}

const AnalyticsPeriodSelectorForm: FC<Props> = ({
  dataType,
  selectedUnit,
  setDataPeriod,
  dataPeriod,
  onShowDataPeriodModal,
  setSelectedDataType,
  dataPeriodTypeToPeriod,
}) => {
  const MONTHLY_OPTIONS: Options[] = [
    {
      value: 'this_year',
      label: moment().format('YYYY年'),
    },
    {
      value: 'last_year',
      label: moment().add(-1, 'year').format('YYYY年'),
    },
    {
      value: '2_years_ago',
      label: moment().add(-2, 'year').format('YYYY年'),
    },
  ]
  const WEEKLY_OPTIONS: Options[] = [
    {
      value: 'last_8_weeks',
      label: '過去8週間',
    },
    {
      value: 'this_month',
      label: '今月',
    },
    {
      value: 'last_month',
      label: moment().add(-1, 'month').format('YYYY年MM月'),
    },
    {
      value: '2_months_ago',
      label: moment().add(-2, 'month').format('YYYY年MM月'),
    },
    {
      value: '3_months_ago',
      label: moment().add(-3, 'month').format('YYYY年MM月'),
    },
    {
      value: '4_months_ago',
      label: moment().add(-4, 'month').format('YYYY年MM月'),
    },
    {
      value: '5_months_ago',
      label: moment().add(-5, 'month').format('YYYY年MM月'),
    },
    {
      value: '6_months_ago',
      label: moment().add(-6, 'month').format('YYYY年MM月'),
    },
    {
      value: 'custom',
      label: '期間を指定',
    },
  ]
  const DAILY_OPTIONS: Options[] = [
    {
      value: 'last_7_days',
      label: '過去7日間',
    },
    {
      value: 'last_14_days',
      label: '過去14日間',
    },
    {
      value: 'this_month',
      label: '今月',
    },
    {
      value: 'last_month',
      label: moment().add(-1, 'month').format('YYYY年MM月'),
    },
    {
      value: '2_months_ago',
      label: moment().add(-2, 'month').format('YYYY年MM月'),
    },
    {
      value: '3_months_ago',
      label: moment().add(-3, 'month').format('YYYY年MM月'),
    },
    {
      value: '4_months_ago',
      label: moment().add(-4, 'month').format('YYYY年MM月'),
    },
    {
      value: '5_months_ago',
      label: moment().add(-5, 'month').format('YYYY年MM月'),
    },
    {
      value: '6_months_ago',
      label: moment().add(-6, 'month').format('YYYY年MM月'),
    },
    {
      value: 'custom',
      label: '期間を指定',
    },
  ]

  const selectedOptions = {
    monthly: MONTHLY_OPTIONS,
    weekly: WEEKLY_OPTIONS,
    daily: DAILY_OPTIONS,
  }

  const onSelect = (event: ChangeEvent<HTMLSelectElement>): void => {
    const type = event.target.value
    switch (type) {
      case 'this_year':
      case 'last_year':
      case '2_years_ago':
      case 'last_8_weeks':
      case 'this_month':
      case 'last_month':
      case '2_months_ago':
      case '3_months_ago':
      case '4_months_ago':
      case '5_months_ago':
      case '6_months_ago':
      case 'last_7_days':
      case 'last_14_days':
        setDataPeriod({
          type: type,
          from: dataPeriodTypeToPeriod[type].from,
          to: dataPeriodTypeToPeriod[type].to,
        })
        break
      case 'custom':
        onShowDataPeriodModal()
        setSelectedDataType(dataType)
        break
      default:
        setDataPeriod({
          type: null,
          from: null,
          to: null,
        })
        break
    }
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <SelectorForm
        style={{ margin: 0 }}
        onSelect={onSelect}
        width='100%'
        height={36}
        value={dataPeriod.type || undefined}
        options={selectedOptions[selectedUnit]}
      />
      {dataPeriod.type === 'custom' && (
        <p style={{ fontSize: 12, color: '#999', marginBottom: 0 }}>
          {dataPeriod.from} - {dataPeriod.to}
        </p>
      )}
    </div>
  )
}

export default AnalyticsPeriodSelectorForm
