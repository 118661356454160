import apiClient from './apiClient'
import { loadViewObj } from '../viewObj'

export const updatePassword = async ({ password, currentPassword }) => {
  const UPDATE_PASSWORD = '/auth/password'

  const params = {
    current_password: currentPassword,
    password: password,
    password_confirmation: password,
  }

  const response = await apiClient({
    method: 'PATCH',
    uri: UPDATE_PASSWORD,
    params: params,
  })
  return response.data.data
}

export const postPasswordResetLink = async ({ email }) => {
  const POST_PASSWORD_RESET_LINK = '/auth/password'

  const params = {
    email: email,
    redirect_url: `https://${window.location.host}/admin/password/edit`,
  }

  const response = await apiClient({
    method: 'POST',
    uri: POST_PASSWORD_RESET_LINK,
    params: params,
  })

  return response.data
}

export const getUsers = async (newUrlParams?: string) => {
  let GET_USERS = `/staff/stores/${loadViewObj()?.id}/users`

  if (newUrlParams) {
    GET_USERS += newUrlParams
  }

  const response = await apiClient({
    method: 'GET',
    uri: GET_USERS,
  })
  return response
}

export const getUser = async (id) => {
  const GET_USER = `/staff/stores/${loadViewObj()?.id}/users/${id}`

  const response = await apiClient({
    method: 'GET',
    uri: GET_USER,
  })
  return response.data
}
