import React, { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { useHistory } from 'react-router'
import moment from 'moment'
import { UserActionSummaryType } from '../../types/common.d'
import { CouponSummariesResponse } from '../../types/api/summary.d'
import { CouponContentResponse } from '../../types/api/coupon'
import { shouldMaskMeasurementValue } from '../../lib/general'
import { useApi, useGetApiCall, useQuery } from '../../lib/hooks'
import {
  CouponStatus,
  CouponStatusType,
  CouponStatusColors,
  AutoCouponContentStatusToJpObj,
  CouponContentStatusToJpObj,
} from '../../containers/entities/CouponContentEntityContainer'
import ContentSummaryCard from '../../components/Card/ContentSummaryCard'
import ImageLoading from '../../components/Loading/ImageLoading'
import UserListSection from './parts/UserListSection'
import EditFormSection from './parts/CouponContentForm'
import { useLoginContext } from '../../providers/LoginContextProvider'
import QuickSightDashboard from '../../components/QuickSightDashboard'

const TABS = {
  Data: 'data',
  Users: 'users',
  Form: 'form',
} as const
type TabType = (typeof TABS)[keyof typeof TABS]

const DEFAULT_SUMMARY_TYPE = 'read'

const CouponDetailView = (): JSX.Element | null => {
  const history = useHistory()
  const query = useQuery()
  const couponContentId = Number(query.get('coupon_content_id'))
  const type = query.get('type')
  const [selectedTab, setSelectedTab] = useState<TabType>(TABS.Data)
  const path =
    type === 'auto' ? 'auto_coupon_contents' : 'present_coupon_contents'
  const { response: couponContent } = useGetApiCall<CouponContentResponse>(
    `/${path}/${couponContentId}`,
  )
  const { api: summaryAPi, response: summary } =
    useApi<CouponSummariesResponse>()
  const isAuto = Boolean(couponContent?.enabled_auto_publish_condition)
  const isPublished = couponContent?.status === CouponStatus.Publish

  const reloadSummary = useCallback(
    (type: UserActionSummaryType) => {
      const url = `/coupon_contents/${couponContentId}/summaries`
      return summaryAPi.get(url, { type })
    },
    [summaryAPi, couponContentId],
  )

  const { currentStore, hasPermission } = useLoginContext()

  useEffect(() => {
    reloadSummary(DEFAULT_SUMMARY_TYPE)
  }, [reloadSummary])

  if (!couponContentId) {
    return null
  }

  if (!couponContent || !summary) {
    return (
      <StyledLoadingContainer>
        <ImageLoading />
      </StyledLoadingContainer>
    )
  }

  if (
    shouldMaskMeasurementValue(couponContent.created_at, summary.read_count) ||
    (!isAuto && !isPublished)
  ) {
    return (
      <EditFormSection
        style={{ padding: '24px 12px' }}
        defaultCouponContent={couponContent}
        defaultTargetUserCondition={couponContent.target_user_condition}
        editMode
        isAuto={isAuto}
      />
    )
  }

  return (
    <Box flex='1 0 0' display='flex' flexDirection='column'>
      <ContentSummaryCard
        style={{ backgroundColor: 'white', padding: 12 }}
        imageUrl={couponContent.image_url}
        title={couponContent.benefit}
        label={
          <StyledStatusLabel status={couponContent.status}>
            {isAuto
              ? CouponContentStatusToJpObj[couponContent.status]
              : AutoCouponContentStatusToJpObj[couponContent.status]}
          </StyledStatusLabel>
        }
        note={
          isAuto
            ? ''
            : `配信日: ${moment(couponContent.publish_at).format('YYYY/M/D(dddd) HH:mm')}`
        }
        {...(currentStore.show_coupon_delivered_count && {
          deliveredCount: summary.delivered_count,
        })}
        {...(currentStore.show_coupon_arrival_count && {
          arrivalCount: summary.arrival_count,
        })}
        {...(currentStore.show_coupon_read_count && {
          readCount: summary.read_count,
        })}
        {...(currentStore.show_coupon_read_count && {
          readCountDiff: summary.read_count_diff,
        })}
        usedCount={summary.used_count}
        salesEffect={summary.sales_effect}
        createdAt={couponContent.created_at}
        memo={couponContent.memo}
        hasAvailableArrivalCount={summary.has_available_arrival_count}
      />

      <Box bgcolor='#fff'>
        <Tabs
          variant='scrollable'
          scrollButtons='auto'
          value={selectedTab}
          sx={{ '& .MuiTab-root': { flex: '1 0 0' } }}
          onChange={(_, tab: string) => setSelectedTab(tab as TabType)}>
          <Tab value='data' label='概要' />
          {hasPermission('END_POINT_LIST_COUPON_USERS') && (
            <Tab value='users' label='ユーザー' />
          )}
          <Tab value='form' label='内容編集' />
        </Tabs>
      </Box>

      {selectedTab === 'data' && (
        <QuickSightDashboard
          params={{
            CouponContentId: couponContentId,
          }}
          dashboardType='coupon'
        />
      )}

      {selectedTab === 'users' &&
        hasPermission('END_POINT_LIST_COUPON_USERS') && (
          <UserListSection
            couponContentId={couponContentId}
            defaultSummaryType={DEFAULT_SUMMARY_TYPE}
            onClickCustomer={(customerId) => {
              history.push(`/admin/customers/detail?user_id=${customerId}`)
            }}
          />
        )}

      {selectedTab === 'form' && (
        <EditFormSection
          style={{ padding: '24px 12px' }}
          defaultCouponContent={couponContent}
          defaultTargetUserCondition={couponContent.target_user_condition}
          editMode
          isAuto={type === 'auto'}
        />
      )}
    </Box>
  )
}

const StyledStatusLabel = styled('div', {
  shouldForwardProp: (prop) => prop !== 'status',
})<{ status: CouponStatusType }>(({ theme, status }) => ({
  backgroundColor: CouponStatusColors[status],
  fontSize: 10,
  display: 'inline',
  color: 'white',
  padding: theme.spacing(0.5),
  borderWidth: 0,
  borderRadius: 6,
  fontWeight: 700,
}))

const StyledLoadingContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 500,
})

export default CouponDetailView
