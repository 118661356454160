import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import * as amplitude from '@amplitude/analytics-browser'
import { useLoginContext } from '../providers/LoginContextProvider'

export const AmplitudePageTracking = () => {
  const { pathname, hash, search } = useLocation()
  const { currentStore } = useLoginContext()
  const { name, id } = currentStore

  useEffect(() => {
    amplitude.track('VIEW_PAGE', {
      path: pathname + search + hash,
      storeId: id,
      storeName: name,
    })
  }, [pathname, hash, search, name, id])

  return null
}

export default AmplitudePageTracking
