import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FormHelperText from '@mui/material/FormHelperText'
import { padding } from '../../../lib/general'
import FormLabel from '../../../components/FormInputs/FormLabel'
import StaffNotificationPolicySelectorForm, {
  StaffNotificationPolicyForm,
} from '../../../components/StaffNotificationPolicySelectorForm'

export type BenchmarkNotificationSetting = {
  timeOfNotificationOnStarted: string | null
  timeOfNotificationOnMiddle: string | null
  timeOfNotificationOnFinished: string | null
  daysOfNotificationOnFinished: number | null
  notificationPolicies: StaffNotificationPolicyForm[]
}

type Props = {
  storeId: number
  notificationSettings: BenchmarkNotificationSetting
  labelOnStarted: string
  labelOnMiddle: string
  labelOnFinished: string
  disabled: boolean
  onChange: (notificationSettings: BenchmarkNotificationSetting) => void
}

const BenchmarkProgressNotificationSettingsForm = ({
  storeId,
  notificationSettings,
  labelOnStarted,
  labelOnMiddle,
  labelOnFinished,
  disabled,
  onChange,
}: Props): JSX.Element => {
  const [enabledNotificationOnStarted, setEnabledNotificationOnStarted] =
    useState(Boolean(notificationSettings.timeOfNotificationOnStarted))
  const [enabledNotificationOnMiddle, setEnabledNotificationOnMiddle] =
    useState(Boolean(notificationSettings.timeOfNotificationOnMiddle))
  const [enabledNotificationOnFinished, setEnabledNotificationOnFinished] =
    useState(Boolean(notificationSettings.timeOfNotificationOnFinished))
  const times = [...Array(24)].map((_, i) => `${padding(i, 2)}:00`)

  const handleChange = (override: Partial<BenchmarkNotificationSetting>) => {
    onChange({ ...notificationSettings, ...override })
  }

  return (
    <>
      <Box mt={2}>
        <FormLabel label='通知タイミング' />
        <Box display='flex' flexDirection='column'>
          <StyledLabelContainer>
            <FormControlLabel
              label='目標開始の通知'
              disabled={disabled}
              sx={{ mb: 0, width: 180 }}
              control={
                <Checkbox
                  checked={enabledNotificationOnStarted}
                  onChange={(e) => {
                    setEnabledNotificationOnStarted(e.target.checked)

                    const newValue = e.target.checked
                      ? DEFAULT_NOTIFICATION_TIME_ON_STARTED
                      : null
                    handleChange({ timeOfNotificationOnStarted: newValue })
                  }}
                />
              }
            />

            <Box display='flex' alignItems='center'>
              <Box color={!enabledNotificationOnStarted ? '#999' : undefined}>
                {labelOnStarted}
              </Box>

              <Select
                sx={{ ml: 0.5 }}
                disabled={!enabledNotificationOnStarted || disabled}
                value={
                  notificationSettings.timeOfNotificationOnStarted ?? '---'
                }
                onChange={(e) =>
                  handleChange({ timeOfNotificationOnStarted: e.target.value })
                }>
                {enabledNotificationOnStarted ? (
                  times.map((time) => (
                    <MenuItem key={time} value={time}>
                      {time}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value='---'>---</MenuItem>
                )}
              </Select>
            </Box>
          </StyledLabelContainer>

          <StyledLabelContainer mt={1}>
            <FormControlLabel
              label='目標途中経過の通知'
              disabled={disabled}
              sx={{ mb: 0, width: 180 }}
              control={
                <Checkbox
                  checked={enabledNotificationOnMiddle}
                  onChange={(e) => {
                    setEnabledNotificationOnMiddle(e.target.checked)

                    const newValue = e.target.checked
                      ? DEFAULT_NOTIFICATION_TIME_ON_MIDDLE
                      : null
                    handleChange({ timeOfNotificationOnMiddle: newValue })
                  }}
                />
              }
            />

            <Box display='flex' alignItems='center'>
              <Box color={!enabledNotificationOnMiddle ? '#999' : undefined}>
                {labelOnMiddle}
              </Box>

              <Select
                sx={{ ml: 0.5 }}
                disabled={!enabledNotificationOnMiddle || disabled}
                value={notificationSettings.timeOfNotificationOnMiddle ?? '---'}
                onChange={(e) =>
                  handleChange({ timeOfNotificationOnMiddle: e.target.value })
                }>
                {enabledNotificationOnMiddle ? (
                  times.map((time) => (
                    <MenuItem key={time} value={time}>
                      {time}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value='---'>---</MenuItem>
                )}
              </Select>
            </Box>
          </StyledLabelContainer>

          <StyledLabelContainer mt={1}>
            <FormControlLabel
              label='目標終了の通知'
              disabled={disabled}
              sx={{ mb: 0, width: 180 }}
              control={
                <Checkbox
                  checked={enabledNotificationOnFinished}
                  onChange={(e) => {
                    setEnabledNotificationOnFinished(e.target.checked)

                    handleChange({
                      timeOfNotificationOnFinished: e.target.checked
                        ? DEFAULT_NOTIFICATION_TIME_ON_FINISHED
                        : null,
                      daysOfNotificationOnFinished: e.target.checked
                        ? DEFAULT_DAYS_ON_FINISHED
                        : null,
                    })
                  }}
                />
              }
            />

            <Box display='flex' alignItems='center'>
              <Box color={!enabledNotificationOnFinished ? '#999' : undefined}>
                {labelOnFinished}
              </Box>

              <Select
                sx={{ mx: 0.5 }}
                disabled={!enabledNotificationOnFinished || disabled}
                value={
                  notificationSettings.daysOfNotificationOnFinished ?? '---'
                }
                onChange={(e) =>
                  handleChange({
                    daysOfNotificationOnFinished: Number(e.target.value),
                  })
                }>
                {enabledNotificationOnFinished && (
                  <MenuItem value='0'>当日</MenuItem>
                )}
                {enabledNotificationOnFinished && (
                  <MenuItem value='1'>翌日</MenuItem>
                )}
                {!enabledNotificationOnFinished && (
                  <MenuItem value='---'>---</MenuItem>
                )}
              </Select>

              <Box color={!enabledNotificationOnFinished ? '#999' : undefined}>
                の
              </Box>

              <Select
                sx={{ ml: 0.5 }}
                disabled={!enabledNotificationOnFinished || disabled}
                value={
                  notificationSettings.timeOfNotificationOnFinished ?? '---'
                }
                onChange={(e) =>
                  handleChange({ timeOfNotificationOnFinished: e.target.value })
                }>
                {enabledNotificationOnFinished ? (
                  times.map((time) => (
                    <MenuItem key={time} value={time}>
                      {time}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem value='---'>---</MenuItem>
                )}
              </Select>
            </Box>
          </StyledLabelContainer>
        </Box>
      </Box>

      <Box mt={2}>
        <StaffNotificationPolicySelectorForm
          storeId={storeId}
          disabled={disabled}
          policies={notificationSettings.notificationPolicies}
          includeChildStoreStaffs
          onChange={(policies) =>
            handleChange({ notificationPolicies: policies })
          }
          tableProps={{
            sx: {
              width: { mobile: '100vw', tablet: '100%' },
              ml: { mobile: -2, tablet: 0 },
            },
          }}
        />

        {notificationSettings.notificationPolicies.length === 0 && (
          <FormHelperText error>
            通知するスタッフを選択してください。
          </FormHelperText>
        )}
      </Box>
    </>
  )
}

const DEFAULT_NOTIFICATION_TIME_ON_STARTED = '09:00'
const DEFAULT_NOTIFICATION_TIME_ON_MIDDLE = '13:00'
const DEFAULT_NOTIFICATION_TIME_ON_FINISHED = '18:00'
const DEFAULT_DAYS_ON_FINISHED = 0

export const generateDefaultValues = (): BenchmarkNotificationSetting => ({
  timeOfNotificationOnStarted: DEFAULT_NOTIFICATION_TIME_ON_STARTED,
  timeOfNotificationOnMiddle: DEFAULT_NOTIFICATION_TIME_ON_MIDDLE,
  timeOfNotificationOnFinished: DEFAULT_NOTIFICATION_TIME_ON_FINISHED,
  daysOfNotificationOnFinished: DEFAULT_DAYS_ON_FINISHED,
  notificationPolicies: [
    {
      staff_id: null,
      enabled_email: true,
      enabled_push_notification: true,
      include_child_store_staffs: false,
    },
  ],
})

const StyledLabelContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  [theme.breakpoints.down('tablet')]: {
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
}))

export default BenchmarkProgressNotificationSettingsForm
