import React from 'react'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import CircularProgress from '@mui/material/CircularProgress'
import { useNotification } from '../../providers/NotificationProvider'
import { useLoginContext } from '../../providers/LoginContextProvider'
import { useQuery, useGetApiCall, useApi, useMobileFlag } from '../../lib/hooks'
import { open } from '../../lib/general'
import { OrderFeatureResponse } from '../../types/order'
import TenantAlert from '../../components/Modal/TenantAlert'
import TakeoutSettingsForm, {
  TakeoutSettingsFormType,
} from './parts/TakeoutSettingsForm'
import DeliverySettingsForm, {
  DeliverySettingsFormType,
} from './parts/DeliverySettingsForm'
import PreorderSettingsForm, {
  PreorderSettingsFormType,
} from './parts/PreorderSettingsForm'
import CommonSettingsForm, {
  CommonSettingsFormType,
} from './parts/CommonSettingsForm'
import CommonAutoSettingsForm, {
  CommonAutoSettingsFormType,
} from './parts/CommonAutoSettingsForm'

const TabList = ['takeout', 'delivery', 'preorder', 'common', 'autoSettings']
type Tab = (typeof TabList)[number]

type OrderFeatureFormType =
  | TakeoutSettingsFormType
  | DeliverySettingsFormType
  | PreorderSettingsFormType
  | CommonSettingsFormType
  | CommonAutoSettingsFormType

const OrderFeatureView = () => {
  const history = useHistory()
  const query = useQuery()
  const isMobile = useMobileFlag()
  const { showSuccessNotification } = useNotification()
  const { hasFunction } = useLoginContext()
  const tabFromQuery = query.get('tab') as Tab
  const currentTab = TabList.includes(tabFromQuery) ? tabFromQuery : 'takeout'
  const fetchApi = useGetApiCall<OrderFeatureResponse>('/order_features')
  const updateApi = useApi()
  const orderFeature = fetchApi.response
  const loading = fetchApi.loading || updateApi.loading

  const updateOrderFeature = async (
    formValues: Partial<OrderFeatureFormType>,
  ) => {
    const params = { order_feature: formValues }
    const response = await updateApi.api.patch('/order_features', params)
    if (!response) return

    showSuccessNotification('デリバリー設定を更新しました。')
    fetchApi.reload()
  }

  return (
    <div>
      <Box
        bgcolor='#fff'
        pt={1}
        display='flex'
        flexDirection={isMobile ? 'column-reverse' : 'row'}
        justifyContent='space-between'>
        <Tabs
          value={currentTab}
          onChange={(_, tab: string) =>
            history.push(`/admin/order/setting?tab=${tab}`)
          }>
          {hasFunction('takeout') && (
            <Tab value='takeout' label='テイクアウト' />
          )}
          {hasFunction('delivery') && (
            <Tab value='delivery' label='デリバリー' />
          )}
          {hasFunction('preorder') && (
            <Tab value='preorder' label='プレオーダー' />
          )}
          <Tab value='common' label='共通設定' />
          <Tab value='autoSettings' label='注文受付設定' />
        </Tabs>

        <Button
          color='submit'
          size='small'
          startIcon={<i className='far fa-question-circle' />}
          sx={{ mr: 2, alignSelf: isMobile ? 'flex-end' : 'center' }}
          onClick={() =>
            open('https://toypo.notion.site/e796c92139164460a254b1fe3db3a7ea')
          }>
          モバイルオーダー利用ガイド
        </Button>
      </Box>

      <Box m={2}>
        <TenantAlert functionName='オーダー' />
      </Box>

      {!orderFeature && (
        <Box display='flex' justifyContent='center' py={5}>
          <CircularProgress />
        </Box>
      )}

      {orderFeature && currentTab === 'takeout' && hasFunction('takeout') && (
        <TakeoutSettingsForm
          orderFeature={orderFeature}
          disabled={loading}
          onSubmit={updateOrderFeature}
        />
      )}

      {orderFeature && currentTab === 'delivery' && hasFunction('delivery') && (
        <DeliverySettingsForm
          orderFeature={orderFeature}
          disabled={loading}
          onSubmit={updateOrderFeature}
        />
      )}

      {orderFeature && currentTab === 'preorder' && hasFunction('preorder') && (
        <PreorderSettingsForm
          orderFeature={orderFeature}
          disabled={loading}
          onSubmit={updateOrderFeature}
        />
      )}

      {orderFeature && currentTab === 'common' && (
        <CommonSettingsForm
          orderFeature={orderFeature}
          disabled={loading}
          onSubmit={updateOrderFeature}
        />
      )}

      {orderFeature && currentTab === 'autoSettings' && (
        <CommonAutoSettingsForm
          orderFeature={orderFeature}
          disabled={loading}
          onSubmit={updateOrderFeature}
        />
      )}
    </div>
  )
}

export default OrderFeatureView
