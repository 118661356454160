import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Row, Col, Grid } from 'react-bootstrap'
import { Pagination } from '@mui/material'
import useSWR from 'swr'
import { AxiosResponse } from 'axios'
import MediaQuery from 'react-responsive'
import { useNotification } from '../../../providers/NotificationProvider'
import { useLoginContext } from '../../../providers/LoginContextProvider'
import LoadingButton from '../../../components/CustomButton/LoadingButton'
import { Card } from '../../../components/Card/Card'
import Button from '../../../components/CustomButton/CustomButton'
import DiscountEntityContainer, {
  DiscountLog,
} from '../../../containers/entities/DiscountEntityContainer'
import ActivityLogEntityContainer, {
  IllegalStatus,
} from '../../../containers/entities/ActivityLogEntityContainer'
import DiscountLogTable from './DiscountLogTable'
import DiscountLogFilteringModal from './DiscountLogFilteringModal'
import ActivityLogIllegalStatusChangeModal from '../../ActivityLog/parts/ActivityLogIllegalStatusChangeModal'
import DiscountLogFilteringConditionElements from './DiscountLogFilteringConditionElements'

type Props = {
  changeUrl: ({ content_page, log_page, condition, period }) => void
}

const DiscountLogListView = ({ changeUrl }: Props): JSX.Element => {
  const history = useHistory()
  const { showSuccessNotification, showErrorNotification } = useNotification()
  const { currentStore } = useLoginContext()
  const [showFilteringModal, setShowFilteringModal] = useState(false)
  const [showIllegalStatusChangeModal, setShowIllegalStatusChangeModal] =
    useState(false)
  const [selectedActivityLogId, setSelectedActivityLogId] = useState<
    number | null
  >()
  const [selectedIllegalStatus, setSelectedIllegalStatus] =
    useState<IllegalStatus | null>('not_check')
  const [doubtfulReasonText, setDoubtfulReasonText] = useState('')
  const discountEntityContainer = DiscountEntityContainer.useContainer()
  const {
    filteringPeriod,
    urlParams,
    discountLogFilteringCondition,
    contents,
    isDiscountLogFiltering,
    contentPage,
  } = discountEntityContainer.state
  const { illegalStatuses } = discountEntityContainer.constant
  const {
    updateDicountLog,
    deleteDiscountLog,
    downloadDiscountLogsCsv,
    getDiscountLogs,
    actionTypeToContentType,
    setLogPage,
  } = discountEntityContainer.logic
  const activityLogEntityContainer = ActivityLogEntityContainer.useContainer()
  const { actionTypesToJp } = activityLogEntityContainer.logic

  const fetcher = async (): Promise<AxiosResponse<DiscountLog[]>> =>
    getDiscountLogs(urlParams)

  const { data, error, mutate } = useSWR(
    `/discount_logs${urlParams}`,
    urlParams ? fetcher : null,
    {
      dedupingInterval: 30000,
      shouldRetryOnError: false,
    },
  )

  const onClickLogDownloadButton = async () => {
    downloadDiscountLogsCsv(urlParams).catch((err) =>
      showErrorNotification(err.response.data.message || err.message),
    )
  }

  const onClickIllegalStatusChangeButton = ({
    id,
    illegalStatus,
    reasonText,
  }) => {
    setShowIllegalStatusChangeModal(true)
    setSelectedActivityLogId(id)
    setSelectedIllegalStatus(illegalStatus)
    setDoubtfulReasonText(reasonText)
  }

  const onHideIllegalStatusChangeModal = () => {
    setShowIllegalStatusChangeModal(false)
    setSelectedActivityLogId(null)
    setSelectedIllegalStatus(null)
    setDoubtfulReasonText('')
  }

  const onClickUpdateIllegalStatusButton = () => {
    updateDicountLog({
      id: selectedActivityLogId,
      params: {
        illegal_status: selectedIllegalStatus,
        doubtful_reason_text: doubtfulReasonText,
      },
    })
      .then(() => {
        showSuccessNotification('不正ステータスを変更しました。')
        mutate()
        onHideIllegalStatusChangeModal()
      })
      .catch((err) => showErrorNotification(err.message))
  }

  const onClickDeleteActionLogsButton = (id, doCancelDependentLogs = false) => {
    if (
      !window.confirm(
        doCancelDependentLogs
          ? 'チェックイン処理を取り消しますか？'
          : 'このログを取り消しますか？',
      )
    ) {
      return
    }
    deleteDiscountLog(id, doCancelDependentLogs)
      .then(() => {
        showSuccessNotification('取り消しました。')
        mutate()
      })
      .catch((err) =>
        showErrorNotification(err?.response?.data?.message || err.message),
      )
  }

  useEffect(() => {
    if (data) {
      setLogPage(data.headers['current-page'])
    }
  }, [data, setLogPage])

  useEffect(() => {
    if (error) {
      showErrorNotification(error.message || 'ログの取得に失敗しました。')
    }
  }, [error, showErrorNotification])

  return (
    <Grid fluid>
      <Row>
        <Col md={12}>
          <Card
            title='割引ログ一覧'
            ctTableFullWidth
            rightContent={
              <MediaQuery query='(min-width: 450px)'>
                <div style={{ display: 'flex' }}>
                  <Button
                    onClick={(): void => setShowFilteringModal(true)}
                    style={{ marginRight: 10 }}>
                    <b>絞り込み設定</b>
                  </Button>
                  <LoadingButton
                    label='CSV出力'
                    loadingLabel='ダウンロード中...'
                    color='info'
                    block
                    onClick={onClickLogDownloadButton}
                  />
                </div>
              </MediaQuery>
            }
            content={
              <>
                <MediaQuery query='(max-width: 449px)'>
                  <div
                    style={{
                      display: 'flex',
                      marginBottom: 20,

                      justifyContent: 'flex-end',
                    }}>
                    <Button
                      onClick={(): void => setShowFilteringModal(true)}
                      style={{ marginRight: 10 }}>
                      <b>絞り込み設定</b>
                    </Button>
                    <LoadingButton
                      label='CSV出力'
                      loadingLabel='ダウンロード中...'
                      color='info'
                      onClick={onClickLogDownloadButton}
                    />
                  </div>
                </MediaQuery>
                {/* 絞り込み条件 */}
                <DiscountLogFilteringConditionElements
                  store={currentStore}
                  discountLogFilteringCondition={discountLogFilteringCondition}
                  actionTypesToJp={actionTypesToJp}
                  contents={contents}
                  actionTypeToContentType={actionTypeToContentType}
                  isDiscountLogFiltering={isDiscountLogFiltering}
                />
                {/* テーブル */}
                <DiscountLogTable
                  logs={data?.data}
                  actionTypesToJp={actionTypesToJp}
                  history={history}
                  onClickDeleteActionLogsButton={onClickDeleteActionLogsButton}
                  onClickIllegalStatusChangeButton={
                    onClickIllegalStatusChangeButton
                  }
                />
              </>
            }
            stats={
              <Pagination
                count={Number(data?.headers['total-pages']) || 1}
                page={Number(data?.headers['current-page']) || 1}
                onChange={(event, value): void =>
                  changeUrl({
                    period: filteringPeriod,
                    content_page: contentPage,
                    log_page: value,
                    condition: discountLogFilteringCondition,
                  })
                }
                sx={{ fontSize: 24, color: 'black' }}
              />
            }
          />
        </Col>
      </Row>

      <DiscountLogFilteringModal
        show={showFilteringModal}
        onHide={(): void => setShowFilteringModal(false)}
        changeUrl={changeUrl}
      />

      <ActivityLogIllegalStatusChangeModal
        show={showIllegalStatusChangeModal}
        onHide={onHideIllegalStatusChangeModal}
        selectedIllegalStatus={selectedIllegalStatus}
        setSelectedIllegalStatus={setSelectedIllegalStatus}
        illegalStatuses={illegalStatuses}
        onClickUpdateIllegalStatusButton={onClickUpdateIllegalStatusButton}
        doubtfulReasonText={doubtfulReasonText}
        setDoubtfulReasonText={setDoubtfulReasonText}
      />
    </Grid>
  )
}

export default DiscountLogListView
