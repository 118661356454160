import React, { useState, memo } from 'react'
import { Table } from 'react-bootstrap'
import { Pagination } from '@mui/material'
import MediaQuery from 'react-responsive'
import * as H from 'history'

import { Card } from '../../../components/Card/Card'
import LoadingButton from '../../../components/CustomButton/LoadingButton'

import DataTable from './DataTable'
import { useLoginContext } from '../../../providers/LoginContextProvider'

import {
  Charge,
  ChargeProduct,
} from '../../../containers/entities/ChargeEntityContainer'
import { formatDate } from '../../../lib/general'

interface Props {
  downloadChargesCsv: () => Promise<void>
  charges: Charge[]
  history: H.History
}

const ChargesListCard: React.FC<Props> = ({
  downloadChargesCsv,
  charges,
  history,
}) => {
  const [parPage] = useState(10)
  const [page, setPage] = useState(1)

  return (
    <Card
      title='決済一覧'
      ctTableFullWidth
      rightContent={
        <LoadingButton
          label='CSV出力'
          loadingLabel='ダウンロード中...'
          color='info'
          block
          onClick={downloadChargesCsv}
        />
      }
      content={
        <ChargeTable
          charges={charges}
          offSet={parPage * (page - 1)}
          parPage={parPage}
          history={history}
        />
      }
      stats={
        <Pagination
          count={Math.ceil(charges.length / parPage)}
          page={page}
          onChange={(_, newPage): void => setPage(newPage)}
          sx={{ fontSize: 24, color: 'black' }}
        />
      }
    />
  )
}

export default memo(ChargesListCard)

const convertChargeProductsToCellValue = (
  chargeProducts: ChargeProduct[],
): string[] =>
  chargeProducts.map(
    (cp) => `${cp.title}(${cp.price.toLocaleString()}円) x ${cp.quantity}`,
  )

interface TableProps {
  charges: Charge[]
  offSet: number
  parPage: number
  history: H.History
}

const ChargeTable: React.FC<TableProps> = ({
  charges,
  offSet,
  parPage,
  history,
}) => {
  const { hasPermission } = useLoginContext()
  return (
    <Table striped hover>
      {/* PCサイズ */}
      <MediaQuery query='(min-width: 767px)'>
        <DataTable
          data={charges.slice(offSet, offSet + parPage)}
          columns={['決済日', '店舗', 'ユーザー名', '金額', '内容']}
          renderCell={(d): JSX.Element => (
            <DataTableCell
              key={`charge-cell-${d.id}`}
              values={[
                formatDate(d.created_at),
                d.store_name,
                hasPermission('SHOW_USER_NAME') ? d.user_name : d.user_id,
                `${d.amount.toLocaleString()}円`,
                convertChargeProductsToCellValue(d.charge_products),
              ]}
              onClick={() => history.push('report/detail', { charge: d })}
            />
          )}
        />
      </MediaQuery>

      {/* SPサイズ */}
      <MediaQuery query='(max-width: 766px)'>
        <DataTable
          data={charges.slice(offSet, offSet + parPage)}
          columns={['決済日', '店舗', 'ユーザー名', '金額']}
          renderCell={(d): JSX.Element => (
            <DataTableCell
              key={`charge-cell-${d.id}`}
              values={[
                formatDate(d.created_at),
                d.store_name,
                hasPermission('SHOW_USER_NAME') ? d.user_name : d.user_id,
                `${d.amount.toLocaleString()}円`,
              ]}
              onClick={(): void => history.push('report/detail', { charge: d })}
            />
          )}
        />
      </MediaQuery>
    </Table>
  )
}
interface DataTableCellProps {
  values: (number | string | string[])[]
  onClick: () => void
}

const DataTableCell: React.FC<DataTableCellProps> = ({ values, onClick }) => (
  <tr onClick={onClick} style={{ cursor: 'pointer' }}>
    {values.map((value, i) => {
      if (Array.isArray(value)) {
        return (
          <td key={`data-table-cell-${i}`} style={{ fontWeight: 'bold' }}>
            {value.map((v, j) => (
              <div key={`data-values-${j}`}>{v}</div>
            ))}
          </td>
        )
      }
      return (
        <td key={`data-table-cell-${i}`} style={{ fontWeight: 'bold' }}>
          {value}
        </td>
      )
    })}
  </tr>
)
