import React from 'react'
import moment from 'moment'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { displayJpReadableNumber } from '../../../lib/general'
import {
  StoreBenchmarkBlock,
  StoreBenchmarkResource,
} from '../../../types/storeBenchmark'
import BenchmarkStatusChip from './BenchmarkStatusChip'
import BenchmarkListEmpty from './BenchmarkListEmpty'

type Props = {
  benchmarks: StoreBenchmarkBlock[]
  onClick: (benchmark: StoreBenchmarkResource) => void
}

const MobileBenchmarkList = ({ benchmarks, onClick }: Props): JSX.Element => {
  return (
    <>
      <List dense disablePadding>
        {benchmarks.map((benchmarkBlock) => (
          <BenchmarkListItem
            benchmarkBlock={benchmarkBlock}
            onClick={() => onClick(benchmarkBlock.store_benchmark)}
          />
        ))}
      </List>

      {benchmarks.length === 0 && <BenchmarkListEmpty />}
    </>
  )
}

type BenchmarkListItemProps = {
  benchmarkBlock: StoreBenchmarkBlock
  onClick: () => void
}

const BenchmarkListItem = ({
  benchmarkBlock,
  onClick,
}: BenchmarkListItemProps): JSX.Element => {
  const { store, store_benchmark, children } = benchmarkBlock
  const dailyBenchmarksUpToToday = children.filter((child) =>
    moment(child.store_benchmark.start_date).isSameOrBefore(moment(), 'day'),
  )
  const sumOfBenchmark = dailyBenchmarksUpToToday.reduce(
    (sum, child) => sum + child.store_benchmark.benchmark_value,
    0,
  )
  const sumOfPerformance = dailyBenchmarksUpToToday.reduce(
    (sum, child) => sum + (child.store_benchmark.performance_value || 0),
    0,
  )
  const ongoing = store_benchmark.benchmark_progress === 'ongoing'
  const before = store_benchmark.benchmark_progress === 'before'
  const active = store_benchmark.status === 'active'
  const paused = store_benchmark.status === 'paused'

  return (
    <ListItem
      key={store_benchmark.id}
      divider
      onClick={() => onClick()}
      sx={{ p: 2, flexDirection: 'column', alignItems: 'flex-start' }}>
      <Box
        width='100%'
        display='flex'
        alignItems='flex-start'
        justifyContent='space-between'
        gap={1}>
        <Box fontSize={15} fontWeight={700}>
          {store_benchmark.title}
        </Box>
        <BenchmarkStatusChip benchmark={store_benchmark} />
      </Box>

      <Box fontSize={13} color='#555' mb={1}>
        <Box mb={0.5}>
          {store_benchmark.start_date} 〜 {store_benchmark.end_date}
        </Box>
        {store.name}
      </Box>

      <Box
        width='100%'
        p={1.5}
        bgcolor='#F8F9FA'
        borderRadius={1}
        display='flex'>
        <Achievement
          label='月次の目標達成度'
          hidePerformance={before || paused}
          performanceValue={store_benchmark.performance_value || 0}
          benchmarkValue={store_benchmark.benchmark_value}
        />

        <Achievement
          label='今日までの目標達成度'
          hidePerformance={!(active && ongoing && children.length > 0)}
          performanceValue={sumOfPerformance}
          benchmarkValue={sumOfBenchmark}
        />
      </Box>
    </ListItem>
  )
}

type AchievementProps = {
  label: string
  hidePerformance: boolean
  performanceValue: number
  benchmarkValue: number
}

const Achievement = ({
  label,
  hidePerformance,
  performanceValue,
  benchmarkValue,
}: AchievementProps): JSX.Element => {
  return (
    <StyledAchievementContainer>
      <StyledAchievementLabel>{label}</StyledAchievementLabel>

      <Box display='flex' alignItems='flex-end'>
        {hidePerformance ? (
          '--'
        ) : (
          <>
            <StyledPerformanceRatio>
              {Math.floor(((performanceValue || 0) / benchmarkValue) * 100)}%
            </StyledPerformanceRatio>
            {displayJpReadableNumber(performanceValue || 0)}/
            {displayJpReadableNumber(benchmarkValue)}
          </>
        )}
      </Box>
    </StyledAchievementContainer>
  )
}

const StyledAchievementContainer = styled('div')({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  alignItems: 'center',
})

const StyledAchievementLabel = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontSize: 13,
  fontWeight: 700,
  color: '#555',
}))

const StyledPerformanceRatio = styled('div')(({ theme }) => ({
  fontSize: 17,
  fontWeight: 700,
  color: '#313131',
  marginBottom: -1,
  marginRight: theme.spacing(0.5),
}))

export default MobileBenchmarkList
