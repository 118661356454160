import React, { useEffect, useCallback } from 'react'
import { Pagination } from '@mui/material'
import { IdentificationCard } from 'phosphor-react'
import { ServiceBlock } from '../../../types/api/service.d'
import { useApi, useMobileFlag } from '../../../lib/hooks'
import SectionTitle from './SectionTitle'
import NoItemContent from '../../../components/Utils/NoItemContent'
import ContentListLarge from './ContentListLarge'
import ContentListSmall from './ContentListSmall'

type Props = {
  userId: number
  [key: string]: string | number | boolean | {} | null | undefined
}
const CustomerServiceList = ({ userId, ...otherProps }: Props): JSX.Element => {
  const isMobile = useMobileFlag()

  const {
    api: servicesApi,
    response: servicesResponse,
    headers: servicesResHeaders,
  } = useApi()
  const services: Array<ServiceBlock> = servicesResponse?.services || []
  const ServiceList = isMobile ? ContentListSmall : ContentListLarge

  const getServices = useCallback(
    (page: number = 1) => {
      servicesApi.get(`/users/${userId}/services`, {
        page,
        limit: 5,
      })
    },
    [servicesApi, userId],
  )

  useEffect(() => {
    getServices()
  }, [getServices])

  if (services.length === 0) {
    return (
      <div {...otherProps}>
        <SectionTitle
          style={{ marginBottom: 12 }}
          title={<b>獲得済みのサービス</b>}
        />

        <NoItemContent
          label='獲得済みのサービスはありません'
          icon={<IdentificationCard />}
        />
      </div>
    )
  }

  return (
    <div {...otherProps}>
      <SectionTitle
        style={{ marginBottom: 12 }}
        title={<b>獲得済みのサービス</b>}
      />

      <ServiceList services={services} />

      {Number(servicesResHeaders['total-pages']) > 1 && (
        <Pagination
          count={Number(servicesResHeaders['total-pages']) || 1}
          page={Number(servicesResHeaders['current-page']) || 1}
          onChange={(_, page) => getServices(page)}
          style={{ paddingBottom: 8, borderBottom: '1px solid #E8E8E8' }}
          sx={{ fontSize: 24, color: 'black' }}
        />
      )}
    </div>
  )
}

export default CustomerServiceList
