import React from 'react'
import { ImageCard } from '../../../components/Image/ImageInput'
import CustomButton from '../../../components/CustomButton/CustomButton'

interface Props {
  name: string
  href: string
  image: string
  altMessage: string
  width?: number
  height?: number | string
  border?: string
}

const QrImageDisplay: React.FC<Props> = ({
  href,
  image,
  altMessage,
  width = 120,
  height = 120,
  border,
  name,
}) => (
  <div
    style={{
      width: 300,
      backgroundColor: '#f6f6f7',
      padding: 15,
      borderRadius: 5,
      marginRight: 10,
      marginBottom: 10,
    }}>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
      }}>
      <div style={{ width: '80%', fontSize: 18 }}>{name}</div>
    </div>

    <div
      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <div>
        <a href={href} target='_blank' rel='noopener noreferrer'>
          <ImageCard
            src={image}
            altMessage={altMessage}
            width={width}
            height={height}
            border={border}
          />
        </a>
      </div>

      <CustomButton
        bsStyle='info'
        fill
        block
        style={{ padding: 0, marginLeft: 10 }}>
        <a
          href={href}
          download
          style={{
            padding: 4,
            fontWeight: 'bold',
            color: 'white',
            display: 'block',
          }}>
          保存する
        </a>
      </CustomButton>
    </div>
  </div>
)

export default QrImageDisplay
