import apiClient from '../apiClient'

export const getOwner = async () => {
  const GET_OWNER = '/staff/owner'

  const response = await apiClient({ method: 'GET', uri: GET_OWNER })
  return response.data
}

export const createOwner = async ({ params }) => {
  const CREATE_OWNER = '/staff/owner'

  const response = await apiClient({
    method: 'POST',
    uri: CREATE_OWNER,
    params,
  })
  return response.data
}

export const updateOwner = async ({ params }) => {
  const UPDATE_OWNER = '/staff/owner'

  const response = await apiClient({
    method: 'PUT',
    uri: UPDATE_OWNER,
    params,
  })
  return response.data
}

export const deleteOwner = async () => {
  const DELETE_OWNER = '/staff/owner'

  const response = await apiClient({ method: 'DELETE', uri: DELETE_OWNER })
  return response.data
}
