import React, { memo, FC } from 'react'
import { MouseEventHandler, ResponsivePie } from '@nivo/pie'
import { Margin } from '@nivo/core'
import { CircularProgress } from '@mui/material'
import PieChartLabel from './parts/PieChartLabel'
import PieChartCenterLabel from './parts/PieChartCenterLabel'
import PieChartTooltip from './parts/PieChartTooltip'

export type Data = {
  id: number | string
  label: string
  value: number
  color: string
  [key: string]: number | string | boolean | undefined | null
}[]

type Props = {
  data: Data
  enableArcLinkLabels?: boolean
  enableLabel?: boolean
  enableCenterLabel?: boolean
  enableArcLabels?: boolean
  margin?: Partial<Margin>
  innerRadius?: number
  cornerRadius?: number
  padAngle?: number
  height?: string | number
  unit?: string
  centerLabel?: string
  centerUnit?: string
  centerValue?: string | number
  onClick?: MouseEventHandler<
    {
      [key: string]: string | number | boolean | null | undefined
      id: number | string
      label: string
      value: number
      color: string
    },
    SVGPathElement
  >
  onMouseEnter?: MouseEventHandler<
    {
      [key: string]: string | number | boolean | null | undefined
      id: number | string
      label: string
      value: number
      color: string
    },
    SVGPathElement
  >
  onMouseLeave?: MouseEventHandler<
    {
      [key: string]: string | number | boolean | null | undefined
      id: number | string
      label: string
      value: number
      color: string
    },
    SVGPathElement
  >
  tooltip?
}

const PieChart: FC<Props> = ({
  data,
  enableArcLinkLabels = false,
  enableLabel = false,
  enableCenterLabel = false,
  enableArcLabels = false,
  margin = {
    top: 30,
    left: 100,
    bottom: 30,
    right: 100,
  },
  innerRadius = 0.5,
  cornerRadius = 0,
  padAngle = 0,
  height = '100%',
  unit = '',
  centerLabel = '',
  centerUnit = '',
  centerValue = '',
  onClick,
  onMouseEnter,
  onMouseLeave,
  tooltip = (props): JSX.Element => (
    <PieChartTooltip datum={props.datum} unit={unit} />
  ),
}) => {
  if (!data) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 'bold',
        }}>
        <CircularProgress color='inherit' />
      </div>
    )
  }

  return (
    <>
      <div style={{ height: height }}>
        <ResponsivePie
          data={data}
          margin={margin}
          innerRadius={innerRadius}
          activeOuterRadiusOffset={8}
          cornerRadius={cornerRadius}
          padAngle={padAngle}
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          enableArcLinkLabels={enableArcLinkLabels}
          enableArcLabels={enableArcLabels}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor='#333333'
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{ from: 'color', modifiers: [['darker', 2]] }}
          tooltip={tooltip}
          colors={data.map((d) => d.color)}
          onClick={(node, event) => onClick && onClick(node, event)}
          layers={
            enableCenterLabel
              ? [
                  'arcs',
                  'arcLabels',
                  'arcLinkLabels',
                  'legends',
                  ({ centerX, centerY }): JSX.Element => (
                    <PieChartCenterLabel
                      centerX={centerX}
                      centerY={centerY}
                      value={centerValue}
                      label={centerLabel}
                      unit={centerUnit}
                    />
                  ),
                ]
              : ['arcs', 'arcLabels', 'arcLinkLabels', 'legends']
          }
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        />
      </div>

      {enableLabel && <PieChartLabel data={data} />}
    </>
  )
}

export default memo(PieChart)
