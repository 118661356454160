import React, { ComponentPropsWithoutRef } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import {
  ArrowUpRight,
  CaretRight,
  RssSimple,
  Eye,
  HandPointing,
  CurrencyJpy,
  PaperPlaneRight,
} from 'phosphor-react'
import TooltipWithLabel from '../Tooltip/TooltipWithLabel'
import { usePcSizeFlag } from '../../lib/hooks'
import {
  shouldMaskMeasurementValue,
  shouldShowMeasurementValueNote,
  measurementValueNote,
} from '../../lib/general'

type Props = ComponentPropsWithoutRef<'div'> & {
  imageUrl?: string
  title?: string
  label?: JSX.Element | string
  note?: string
  mask?: boolean
  deliveredCount?: number
  readCount?: number
  readCountDiff?: number
  usedCount?: number
  usedCountDiff?: number
  salesEffect?: number
  arrivalCount?: number
  createdAt?: string
  memo?: string
  options?: JSX.Element | string
  withHeader?: boolean
  hasAvailableArrivalCount?: boolean
}

const ContentSummaryCard = ({
  imageUrl,
  title,
  label,
  note = '',
  mask = false,
  deliveredCount,
  readCount,
  readCountDiff,
  usedCount,
  usedCountDiff,
  salesEffect,
  arrivalCount,
  createdAt,
  memo = '',
  options,
  withHeader = true,
  hasAvailableArrivalCount = true,
  ...otherProps
}: Props): JSX.Element => {
  const isPcOrTablet = usePcSizeFlag()

  // ここにはお知らせ、クーポンでそれぞれ以下の要素が表示される。
  // お知らせの場合: 配信数 / 到達数 / 閲覧数 /
  // クーポンの場合: 配信数 / 到達数 / 閲覧数 / 利用数 / 売上効果
  //
  // 基本的にはそれぞれの要素を以下のように横並びにして表示する。
  // +-------------------------------------+
  // | 配信数 > 閲覧数 > (利用数) > 売上効果   |
  // |   10       5         2        200   |
  // +-------------------------------------+
  //
  // ただし、スマホのように画面が小さいデバイスで閲覧している場合
  // 要素が4つある時に横並びで表示してしまうと窮屈な表示になってしまうので
  // 以下のように2つずつ並べて表示する。
  // +-------------------------------------+
  // |      配信           到達             |
  // |      100            80              |
  // |                                     |
  // |      開封           利用             |
  // |       50            25              |
  // |                                     |
  // |            売上効果                  |
  // |             2,500                   |
  // +-------------------------------------+
  //
  // この表示を制御するために要素数がいくつなのかを計算しておきます。
  const numOfItems = usedCount === undefined ? 2 : 4
  const isGrid = !isPcOrTablet && numOfItems === 4
  const showCaret = isPcOrTablet || numOfItems === 2

  return (
    <div {...otherProps}>
      {withHeader && (
        <StyledHeaderContainer>
          {Boolean(imageUrl) && (
            <StyledContentImage alt={title} src={imageUrl} />
          )}

          <div style={{ flex: 1 }}>
            <StyledTitleContainer>
              <StyledContentTitle>{title}</StyledContentTitle>
              {options && <div>{options}</div>}
            </StyledTitleContainer>

            <Box display='flex' alignItems='center' gap={1}>
              {note && <StyledNote>{note}</StyledNote>}
              <div>{label}</div>
            </Box>
            {memo && <StyledMemo>{memo}</StyledMemo>}
          </div>
        </StyledHeaderContainer>
      )}

      <StyledDataContainer grid={isGrid}>
        {deliveredCount !== undefined && (
          <CountItem
            icon={<PaperPlaneRight weight='bold' />}
            label='配信'
            count={deliveredCount}
            mask={mask}
            showCaret={showCaret}
          />
        )}

        {arrivalCount !== undefined && (
          <CountItem
            icon={<RssSimple weight='bold' />}
            label='到達'
            tooltip='通知が届いたか、開封されたらカウントします。'
            count={arrivalCount}
            mask={!hasAvailableArrivalCount}
            showCaret={showCaret}
          />
        )}

        {readCount !== undefined && (
          <CountItem
            icon={<Eye weight='bold' />}
            label='開封'
            tooltip={
              shouldShowMeasurementValueNote(createdAt, readCount)
                ? measurementValueNote('コンテンツ', createdAt)
                : ''
            }
            count={readCount}
            diff={readCountDiff}
            mask={mask || shouldMaskMeasurementValue(createdAt, readCount)}
            showCaret={usedCount !== undefined && showCaret}
          />
        )}

        {usedCount !== undefined && (
          <CountItem
            icon={<HandPointing weight='bold' />}
            label='利用'
            tooltip='ユーザー1人につき1回のみカウントされます。2回目以降の利用はカウントされません。'
            count={usedCount}
            diff={usedCountDiff}
            mask={mask}
            showCaret={showCaret}
          />
        )}

        {salesEffect !== undefined && (
          <CountItem
            icon={<CurrencyJpy weight='bold' />}
            label='売上効果'
            tooltip='クーポン利用による売上 x 利用数'
            count={salesEffect}
            mask={mask}
          />
        )}
      </StyledDataContainer>
    </div>
  )
}

type CountItemProps = {
  icon: JSX.Element
  label: string
  tooltip?: string
  count: number
  diff?: number
  mask?: boolean
  showCaret?: boolean
}

const CountItem = ({
  icon,
  label,
  tooltip,
  count,
  diff = 0,
  mask = false,
  showCaret = false,
}: CountItemProps): JSX.Element => (
  <>
    <StyledItemContainer>
      <StyledLabelContainer>
        {icon}
        <div>
          {tooltip ? (
            <TooltipWithLabel
              label={label}
              tooltip={tooltip}
              iconPosition='right'
            />
          ) : (
            label
          )}
        </div>
      </StyledLabelContainer>

      <div style={{ fontWeight: 700, fontSize: 18 }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>{mask ? '---' : count.toLocaleString()}</div>

          {!mask && diff > 0 && (
            <>
              <ArrowUpRight
                weight='bold'
                color='#26ABA4'
                size={13}
                style={{ margin: '0 2px' }}
              />
              <div style={{ fontWeight: 700, fontSize: 13, color: '#26ABA4' }}>
                {diff.toLocaleString()}
              </div>
            </>
          )}
        </div>
      </div>
    </StyledItemContainer>

    {showCaret && <CaretRight weight='bold' color='#959595' />}
  </>
)

const StyledHeaderContainer = styled('div')({
  display: 'flex',
  marginBottom: 8,
})
const StyledTitleContainer = styled('div')({
  display: 'flex',
  gap: 4,
  justifyContent: 'space-between',
  marginBottom: 4,
})

const CONTENT_IMAGE_WIDTH = 48
const StyledContentImage = styled('img')({
  borderRadius: 2,
  objectFit: 'cover',
  marginRight: 8,
  flexBasis: CONTENT_IMAGE_WIDTH,
  width: CONTENT_IMAGE_WIDTH,
  height: 32,
})

const StyledContentTitle = styled('div')({
  fontWeight: 700,
  fontSize: 15,
  flex: 1,
})

const StyledNote = styled('div')({
  fontSize: 13,
  color: '#636464',
})

const StyledMemo = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  fontSize: 13,
  color: '#636464',
}))

const StyledDataContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'grid',
})<{ grid: boolean }>(({ grid }) => ({
  display: 'flex',
  backgroundColor: '#f8f9fa',
  borderRadius: 4,
  padding: 8,
  alignItems: 'center',
  justifyContent: 'space-around',
  flexWrap: 'wrap',
  '& > div': grid ? { width: '50%' } : {},
}))

const StyledItemContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const StyledLabelContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: 4,
  color: '#636464',
  fontWeight: 700,
})

export default ContentSummaryCard
