import React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {
  MembersCardResource,
  MembersCardRanksResponse,
  MembersCardRankResource,
} from '../../../types/membersCard'
import { useGetApiCall, useBooleanState } from '../../../lib/hooks'
import AdminShieldedButton from '../../../components/AdminShieldedButton'
import SectionTitle from './SectionTitle'
import ModalForMembersRankChange from './ModalForMembersRankChange'
import { useLoginContext } from '../../../providers/LoginContextProvider'

type Props = {
  membersCard: MembersCardResource
  membersCardRank: MembersCardRankResource | null
  membersCardPointEnabled: boolean | null
  membersCardCurrentRanking: number | null
  onClickUpdateMemberCode: () => void
  onClickChangePoint: () => void
  onUpdateMembersCardRank: (rankId: number) => Promise<void>
}

const MembersCard = ({
  membersCard,
  membersCardRank,
  membersCardPointEnabled,
  membersCardCurrentRanking,
  onClickUpdateMemberCode,
  onClickChangePoint,
  onUpdateMembersCardRank,
}: Props): JSX.Element => {
  const [isChangeRankModal, openChangeRankModal, closeChangeRankModal] =
    useBooleanState(false)
  const { currentStaff } = useLoginContext()
  const path = `/members_card_contents/members_card_ranks?status=open`
  const { response } = useGetApiCall<MembersCardRanksResponse>(path)
  const membersCardRanks = response?.members_card_ranks || []

  return (
    <div>
      <SectionTitle
        style={{ marginBottom: 12 }}
        title={<b>会員カード情報</b>}
      />

      <StyledMembersCardItemsContainer>
        <MembersCardItem
          title='会員コード'
          value={membersCard?.member_code}
          button={
            <Box display='flex' alignItems='center'>
              {membersCard?.is_code_updated && (
                <Typography color='#999' fontSize={13} ml={0.5}>
                  (変更済み)
                </Typography>
              )}

              {(currentStaff.is_toypo_member ||
                !membersCard?.is_code_updated) && (
                <AdminShieldedButton
                  style={{ padding: '0 8px', marginLeft: 8 }}
                  color={
                    currentStaff.is_toypo_member && membersCard?.is_code_updated
                      ? 'toypoAdmin'
                      : 'submit'
                  }
                  onClick={onClickUpdateMemberCode}>
                  更新
                </AdminShieldedButton>
              )}
            </Box>
          }
        />

        {membersCardRank && (
          <div>
            <MembersCardItem
              title='ランク'
              value={membersCardRank.name}
              button={
                membersCardRanks.length > 1 ? (
                  <AdminShieldedButton
                    style={{ padding: '0 8px', marginLeft: 8 }}
                    onClick={openChangeRankModal}>
                    変更
                  </AdminShieldedButton>
                ) : null
              }
            />

            <ModalForMembersRankChange
              show={isChangeRankModal}
              onHide={closeChangeRankModal}
              currentMembersCardRankId={membersCardRank.id}
              membersCardRanks={membersCardRanks}
              onChangeMembersCardRank={(id) => {
                onUpdateMembersCardRank(id)
                closeChangeRankModal()
              }}
            />
          </div>
        )}

        {membersCardCurrentRanking && (
          <MembersCardItem
            title='ランキング'
            value={`${membersCardCurrentRanking.toLocaleString()}位`}
          />
        )}

        {membersCardPointEnabled && (
          <MembersCardItem
            title='ポイント'
            value={`${membersCard?.point?.toLocaleString() || 0}pt`}
            button={
              <AdminShieldedButton
                style={{ padding: '0 8px', marginLeft: 8 }}
                onClick={onClickChangePoint}>
                ポイント付与/削除
              </AdminShieldedButton>
            }
          />
        )}

        {membersCardRank?.rank_up_method === 'rank_up_by_check_in' && (
          <MembersCardItem
            title='累計チェックイン数'
            value={`${membersCard?.rank_value.toLocaleString()}チェックイン`}
          />
        )}

        {membersCardRank?.rank_up_method === 'rank_up_by_payment' && (
          <MembersCardItem
            title='累計お会計金額'
            value={`${membersCard?.rank_value.toLocaleString()}円`}
          />
        )}

        {membersCardRank?.rank_up_method === 'rank_up_by_point' && (
          <MembersCardItem
            title='累計獲得ポイント'
            value={`${membersCard?.rank_value.toLocaleString()}pt`}
          />
        )}
      </StyledMembersCardItemsContainer>
    </div>
  )
}

type MembersCardItemProps = {
  title: string
  value: string | number
  button?: JSX.Element | null
}

const MembersCardItem = ({
  title,
  value,
  button,
}: MembersCardItemProps): JSX.Element => (
  <div style={{ display: 'flex', alignItems: 'center', padding: '6px 0' }}>
    <div style={{ color: '#9A9A9A', fontWeight: 'bold', width: 150 }}>
      {title}
    </div>

    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div>{value}</div>
      <div>{button}</div>
    </div>
  </div>
)

const StyledMembersCardItemsContainer = styled('div')(({ theme }) => ({
  borderRadius: 4,
  background: '#f5f6f9',
  padding: theme.spacing(1, 2),
}))

export default MembersCard
