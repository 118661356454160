import React, { FC } from 'react'
import { Button } from '@mui/material'
import { formatDateExceptTimeToJp } from '../../../lib/general'
import { useAdminApi } from '../../../lib/api/admin'
import { UserResourceForAdminStaff } from '../../../types/user'

type Props = {
  user: UserResourceForAdminStaff
  onUpdated: () => void
}

export const UserWithdrawal: FC<Props> = ({ user, onUpdated }) => {
  const { id: userId, left_at: leftAt } = user
  const { revertWithdrawal, scheduleWithdrawal } = useAdminApi({
    userId,
    onUpdated,
  })

  const Revert: FC = () => (
    <Button
      size='small'
      color='toypoAdmin'
      variant='contained'
      style={{
        whiteSpace: 'nowrap',
      }}
      onClick={revertWithdrawal}
      sx={{ ml: 1, fontSize: 12 }}>
      {formatDateExceptTimeToJp(leftAt)} 予約取消
    </Button>
  )

  const Withdrawal: FC = () => (
    <Button
      size='small'
      color='toypoAdmin'
      variant='contained'
      onClick={scheduleWithdrawal}
      sx={{ ml: 1, fontSize: 12 }}>
      退会を予約する
    </Button>
  )

  return leftAt ? <Revert /> : <Withdrawal />
}
