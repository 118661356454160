import React from 'react'
import { Prompt } from 'react-router'
import Flatpickr from 'react-flatpickr'
import { Japanese } from 'flatpickr/dist/l10n/ja'
import 'flatpickr/dist/themes/material_blue.css'
import {
  Grid,
  Row,
  Col,
  FormGroup,
  FormControl,
  Tooltip,
  HelpBlock,
  OverlayTrigger,
  Form,
} from 'react-bootstrap'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useHistory, useLocation } from 'react-router-dom'
import { TicketContentStatusLabels } from '../../constants/ticket'
import CustomButton from '../../components/CustomButton/CustomButton'
import LoadingButton from '../../components/CustomButton/LoadingButton'
import { Card } from '../../components/Card/Card'
import FormInputs from '../../components/FormInputs/FormInputs'
import SelectorForm from '../../components/FormInputs/SelectorForm'
import ChangeIndicator from '../../components/ChangeIndicator'
import ImageForm from '../../components/FormInputs/ImageForm'
import FormLabel from '../../components/FormInputs/FormLabel'
import OwnerEntityContainer from '../../containers/entities/OwnerEntityContainer'
import {
  TicketContent,
  TicketContentStatus,
} from '../../containers/entities/TicketContentEntityContainer'
import { UserGroup } from '../../containers/entities/UserGroupEntityContainer'
import {
  copyText,
  formatDateExceptTime,
  getObjectDiff,
} from '../../lib/general'
import { compareValidateDate, validateDate } from '../../lib/validation'
import { isViewObjGroup } from '../../lib/viewObj'
import TicketPublishSettingCard from './parts/TicketPublishSettingCard'
import { useNotification } from '../../providers/NotificationProvider'
import { useApi, useBooleanState } from '../../lib/hooks'
import {
  FormChangedAlertModal,
  ModalContentFormChangedList,
} from '../../components/Modal/FormChangedAlertModal'

type TicketType = 'one' | 'infinity' | 'multiple'
type TicketExpirationType = 'relative' | 'absolute'
type TicketFormLocation = { ticket?: TicketContent; userGroup?: UserGroup }
type TicketForm = {
  ticket_type: TicketType
  expiration_type: TicketExpirationType
  status: TicketContentStatus
  usable_count: number | null
  image: string | null
  title: string
  body: string
  price: number | null
  face_price: string | null
  monthly_usable_count: number | null
  daily_usable_count: number | null
  limit_quantity: string | null
  expiration_month: number | null
  expiration_from: string | null
  expiration_to: string | null
}
type TicketFormParams = Partial<TicketForm>

const FieldLabels = {
  ticket_type: 'チケットの種類',
  title: 'タイトル',
  body: 'サービス内容',
  price: '販売価格',
  usable_count: '利用可能回数',
  monthly_usable_count: '月間での利用回数制限',
  daily_usable_count: '日間での利用回数制限',
  limit_quantity: '販売上限枚数',
  image: '画像',
  face_price: '額面価格',
  expiration_type: '有効期限種別',
  expiration_from: '有効期限(開始)',
  expiration_to: '有効期限(終了)',
  expiration_month: '有効期限月数',
}

const LIMITED_OPEN_URL_ELEMENT_ID = 'TicketLimitedOpenUrl'

const calculateTicketType = (
  usableCount: number | null | undefined,
): TicketType => {
  if (usableCount === null) return 'infinity'
  if (usableCount === 1 || usableCount === undefined) return 'one'
  return 'multiple'
}
const calculateExpirationType = (
  expirationMonth: number | null | undefined,
): TicketExpirationType => {
  return Boolean(expirationMonth) || expirationMonth === undefined
    ? 'relative'
    : 'absolute'
}

const TicketFormView = (): JSX.Element => {
  const { showSuccessNotification, showErrorNotification } = useNotification()
  const location = useLocation<TicketFormLocation>()
  const history = useHistory()
  const ticket = location.state?.ticket
  const editMode = location.pathname.indexOf('edit') !== -1
  const createMode = !editMode
  const ownerEntityContainer = OwnerEntityContainer.useContainer()
  const { owner, cardApplicationStatus } = ownerEntityContainer.state
  const canPublishTicket =
    owner?.has_tenant && cardApplicationStatus === 'passed'
  const { api, loading } = useApi()
  const [isOpenSubmitModal, openSubmitModal, closeSubmitModal] =
    useBooleanState(false)
  const [isOpenDeleteModal, openDeleteModal, closeDeleteModal] =
    useBooleanState(false)
  const editable = createMode || ticket?.status === 'draft'

  const defaultValues = {
    title: ticket?.title || '',
    body: ticket?.body || '',
    price: ticket?.price || null,
    face_price: String(ticket?.face_price || ''),
    limit_quantity: String(ticket?.limit_quantity || ''),
    monthly_usable_count: ticket?.monthly_usable_count || 0,
    daily_usable_count: ticket?.daily_usable_count || 0,
    image: ticket?.image_url || null,
    expiration_from: ticket?.expiration_from
      ? new Date(ticket.expiration_from).toLocaleDateString()
      : '',
    expiration_to: ticket?.expiration_to
      ? new Date(ticket.expiration_to).toLocaleDateString()
      : '',
    expiration_month: ticket?.expiration_month || 1,
    usable_count: ticket?.usable_count ?? 1,
    ticket_type: calculateTicketType(ticket?.usable_count),
    expiration_type: calculateExpirationType(ticket?.expiration_month),
    status: ticket?.status || 'draft',
  }
  const methods = useForm<TicketForm>({
    defaultValues,
    shouldUnregister: false,
  })
  const { register, handleSubmit, errors, getValues, control, reset } = methods
  const { dirtyFields } = methods.formState
  const watchTicket = methods.watch()

  const changedProperties = Object.entries(FieldLabels)
    .map(([key, label]) => (dirtyFields?.[key] ? label : ''))
    .filter((v) => v)
    .filter(
      (v) =>
        !(
          watchTicket.expiration_type === 'relative' &&
          v === FieldLabels.expiration_to
        ),
    )
    .filter(
      (v) =>
        !(
          watchTicket.expiration_type === 'relative' &&
          v === FieldLabels.expiration_from
        ),
    )
    .filter(
      (v) =>
        !(
          watchTicket.expiration_type === 'absolute' &&
          v === FieldLabels.expiration_month
        ),
    )
    .filter(
      (v) =>
        !(
          watchTicket.ticket_type !== 'multiple' &&
          v === FieldLabels.usable_count
        ),
    )
    .filter(
      (v) =>
        !(
          watchTicket.ticket_type === 'infinity' && v === FieldLabels.face_price
        ),
    )
  const isDirty = changedProperties.length > 0

  const validateExpirationTo = () => {
    const from = getValues('expiration_from')
    const to = getValues('expiration_to')
    if (!to) {
      return '期間を入力してください'
    }

    const validationResult = validateDate(to)
    if (!validationResult.validation) {
      return validationResult.message
    }

    const validate = compareValidateDate(from, to)
    if (from && to && !validate.validation) {
      return '開始日時より過去の終了日時を設定することはできません'
    }

    return true
  }

  const validateFacePrice = (value: string) => {
    if (!value) {
      return true
    }

    const valueAsNumber = Number(value)
    if (valueAsNumber <= Number(getValues('price'))) {
      return '販売価格より大きい値にしてください。'
    }

    const v = Number(value)
    return (
      (1 <= v && v <= 1000000) || '1以上1000000以内の数字を入力してください。'
    )
  }

  const validateLimitQuantity = (value: string) => {
    if (!value) {
      return true
    }

    const v = Number(value)
    return (
      (1 <= v && v <= 1000000) || '1以上1000000以内の数字を入力してください。'
    )
  }

  const copyStoreText = (id: string) => {
    copyText(id)
    showSuccessNotification('コピーしました。')
  }

  const processFormData = (data: TicketForm) => {
    const processedData = data

    if (editMode) {
      delete processedData.status
    }

    if (ticket && ticket.image_url === processedData.image) {
      delete processedData.image
    }

    if (
      processedData.ticket_type === 'infinity' ||
      processedData.face_price === ''
    ) {
      processedData.face_price = null
    }

    if (processedData.monthly_usable_count === 0) {
      processedData.monthly_usable_count = null
    }

    if (processedData.daily_usable_count === 0) {
      processedData.daily_usable_count = null
    }

    if (processedData.limit_quantity === '') {
      processedData.limit_quantity = null
    }

    if (processedData.ticket_type === 'one') {
      processedData.usable_count = 1
    } else if (processedData.ticket_type === 'infinity') {
      processedData.usable_count = null
    }

    if (processedData.expiration_type === 'relative') {
      processedData.expiration_from = null
      processedData.expiration_to = null
    } else {
      processedData.expiration_month = null
    }

    return processedData
  }

  const updateTicket = async (params: TicketFormParams) => {
    if (
      !canPublishTicket &&
      ['open', 'limited_open'].includes(params?.status || '')
    ) {
      showErrorNotification(
        'チケットを公開するには、口座登録とクレジット利用申請が必要です。',
      )
      return
    }

    if (!ticket) return
    const res = await api.patch(`/ticket_contents/${ticket.id}`, {
      ticket_content: params,
    })
    if (res !== null) {
      showSuccessNotification('更新しました。')
      reset()
      history.goBack()
    }
  }

  const handleUpdateButton = async () => {
    const data = processFormData(getValues())
    const params = getObjectDiff(ticket, data)
    await updateTicket(params)
  }

  const createTicket = async () => {
    const data = processFormData(getValues())

    if (
      !canPublishTicket &&
      ['open', 'limited_open'].includes(data?.status || '')
    ) {
      showErrorNotification(
        'チケットを公開するには、口座登録とクレジット利用申請が必要です。',
      )
      return
    }

    const res = await api.post('/ticket_contents', { ticket_content: data })
    if (res !== null) {
      showSuccessNotification('チケットを作成しました。')
      reset()
      history.goBack()
    }
  }

  const deleteTicket = async () => {
    if (!editMode) return
    const res = await api.delete(`/ticket_contents/${ticket?.id}`)
    if (res !== null) {
      showSuccessNotification(`${ticket?.title}を削除しました。`)
      reset()
      history.goBack()
    }
  }

  const onError = () => showErrorNotification('値が正しく入力されていません。')

  return (
    <>
      <FormProvider {...methods}>
        <Grid fluid className='content'>
          <Form
            onSubmit={handleSubmit(
              editMode ? openSubmitModal : createTicket,
              onError,
            )}>
            {editMode && ticket && (
              <Row>
                <Col md={10}>
                  <TicketPublishSettingCard
                    onClickStatusUpdateButton={(status) =>
                      updateTicket({ status })
                    }
                    defaultStatus={ticket.status}
                  />
                </Col>
              </Row>
            )}
            <Row>
              <Col md={10}>
                <Card
                  title={editMode ? 'チケット編集' : 'チケット作成'}
                  content={
                    <>
                      <Row>
                        <Col md={3} sm={3} xs={6}>
                          <FormGroup bsSize='large'>
                            <SelectorForm
                              name='ticket_type'
                              inputRef={register}
                              label={`${FieldLabels.ticket_type}※`}
                              style={{ width: 200 }}
                              disabled={!editable}
                              options={[
                                { label: '一回券', value: 'one' },
                                { label: '回数券', value: 'multiple' },
                                { label: '定期券', value: 'infinity' },
                              ]}
                              showChangeIndicator={
                                editMode && Boolean(dirtyFields.ticket_type)
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <FormGroup bsSize='large'>
                        <Controller
                          control={control}
                          name='image'
                          render={({ value, onChange }) => (
                            <ImageForm
                              doTrimming={false}
                              label={FieldLabels.image}
                              attachment='任意'
                              value={value}
                              onChange={onChange}
                              validationMessage={errors?.image?.message}
                              showChangeIndicator={
                                editMode && Boolean(dirtyFields.image)
                              }
                            />
                          )}
                        />
                        <HelpBlock className='text-muted'>
                          画像は縦横比16:9で表示されます。
                        </HelpBlock>
                      </FormGroup>

                      <FormInputs
                        properties={[
                          {
                            name: 'title',
                            label: FieldLabels.title,
                            ncol: 'col-md-9',
                            type: 'text',
                            bsClass: 'form-control',
                            inputRef: register({
                              required: 'タイトルを入力してください。',
                            }),
                            validationMessage: errors?.title?.message,
                            showChangeIndicator:
                              editMode && Boolean(dirtyFields.title),
                          },
                          {
                            name: 'body',
                            label: FieldLabels.body,
                            ncol: 'col-md-12',
                            rows: '6',
                            componentClass: 'textarea',
                            bsClass: 'form-control',
                            inputRef: register({
                              required: 'サービス内容を入力してください。',
                            }),
                            validationMessage: errors?.body?.message,
                            showChangeIndicator:
                              editMode && Boolean(dirtyFields.body),
                          },
                          {
                            name: 'price',
                            label: `${FieldLabels.price}※`,
                            ncol: 'col-sm-3 col-xs-5',
                            type: 'number',
                            disabled: !editable,
                            placeholder: 1000,
                            unit: '円',
                            inputRef: register({
                              valueAsNumber: true,
                              min: {
                                value: 1,
                                message: '1以上の数字を入力してください。',
                              },
                              max: {
                                value: 1000000,
                                message:
                                  '1000000以下の数字を入力してください。',
                              },
                              required: '販売価格を入力してください。',
                            }),
                            validationMessage: errors?.price?.message,
                            showChangeIndicator:
                              editMode && Boolean(dirtyFields.price),
                          },
                          {
                            name: 'face_price',
                            label: `${FieldLabels.face_price}※`,
                            ncol: 'col-sm-3 col-xs-5',
                            type: 'number',
                            disabled: !editable,
                            placeholder: 10000,
                            unit: '円',
                            attachment: '任意',
                            inVisible: watchTicket.ticket_type === 'infinity',
                            inputRef: register({ validate: validateFacePrice }),
                            validationMessage: errors?.face_price?.message,
                            showChangeIndicator:
                              editMode && Boolean(dirtyFields.face_price),
                          },
                        ]}
                      />

                      <FormGroup>
                        {watchTicket.ticket_type === 'infinity' && (
                          <>
                            <FormLabel label={`${FieldLabels.usable_count}※`} />
                            <FormControl.Static>
                              有効期限内、何回でも利用可能
                            </FormControl.Static>
                          </>
                        )}
                        {watchTicket.ticket_type === 'one' && (
                          <>
                            <FormLabel label={`${FieldLabels.usable_count}※`} />
                            <FormControl.Static>1回</FormControl.Static>
                          </>
                        )}
                        <SelectorForm
                          name='usable_count'
                          label={`${FieldLabels.usable_count}※`}
                          disabled={!editable}
                          inputRef={register({ valueAsNumber: true })}
                          options={[...Array(100)].map((_, i) => ({
                            value: i + 1,
                            label: `${i + 1}回`,
                          }))}
                          style={{
                            width: 200,
                            display:
                              watchTicket.ticket_type === 'multiple'
                                ? 'block'
                                : 'none',
                          }}
                          showChangeIndicator={
                            editMode && Boolean(dirtyFields.usable_count)
                          }
                        />
                      </FormGroup>

                      <Row>
                        <Col md={3} sm={3} xs={6}>
                          <FormGroup bsSize='large'>
                            <SelectorForm
                              name='monthly_usable_count'
                              inputRef={register({ valueAsNumber: true })}
                              label={FieldLabels.monthly_usable_count}
                              disabled={
                                watchTicket.ticket_type === 'one' ||
                                (watchTicket.ticket_type === 'multiple' &&
                                  watchTicket.usable_count === 1)
                              }
                              style={{ width: 200 }}
                              options={[...Array(100)].map((_, i) => ({
                                label: i === 0 ? '設定しない' : `${i}回`,
                                value: i,
                              }))}
                              showChangeIndicator={
                                editMode &&
                                Boolean(dirtyFields.monthly_usable_count)
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={3} sm={3} xs={6}>
                          <FormGroup bsSize='large'>
                            <SelectorForm
                              name='daily_usable_count'
                              inputRef={register({ valueAsNumber: true })}
                              label={FieldLabels.daily_usable_count}
                              disabled={
                                watchTicket.ticket_type === 'one' ||
                                (watchTicket.ticket_type === 'multiple' &&
                                  watchTicket.usable_count === 1)
                              }
                              style={{ width: 200 }}
                              options={[...Array(100)].map((_, i) => ({
                                label: i === 0 ? '設定しない' : `${i}回`,
                                value: i,
                              }))}
                              showChangeIndicator={
                                editMode &&
                                Boolean(dirtyFields.daily_usable_count)
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <FormInputs
                        properties={[
                          {
                            name: 'limit_quantity',
                            label: FieldLabels.limit_quantity,
                            ncol: 'col-md-3 col-sm-5 col-xs-5',
                            type: 'text',
                            attachment: '任意',
                            placeholder: 100,
                            unit: '枚',
                            inputRef: register({
                              validate: validateLimitQuantity,
                            }),
                            validationMessage: errors?.limit_quantity?.message,
                            showChangeIndicator:
                              editMode && Boolean(dirtyFields.limit_quantity),
                          },
                        ]}
                      />

                      <Row>
                        <Col md={3} sm={3} xs={6}>
                          <FormGroup bsSize='large'>
                            <SelectorForm
                              name='expiration_type'
                              label='有効期限※'
                              style={{ width: 240 }}
                              inputRef={register}
                              disabled={!editable}
                              options={[
                                {
                                  label: '購入からの月数で指定',
                                  value: 'relative',
                                },
                                { label: '日時で指定', value: 'absolute' },
                              ]}
                              showChangeIndicator={
                                editMode && Boolean(dirtyFields.expiration_type)
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      {watchTicket.expiration_type === 'relative' && (
                        <Row>
                          <Col md={3} sm={3} xs={6}>
                            <FormGroup bsSize='large'>
                              <SelectorForm
                                name='expiration_month'
                                label='購入後※'
                                style={{ width: 120 }}
                                disabled={!editable}
                                inputRef={register({
                                  valueAsNumber: true,
                                  required: '選択してください。',
                                })}
                                options={[1, 2, 3, 4, 5, 6].map((month) => ({
                                  label: `${month}ヶ月`,
                                  value: month,
                                }))}
                                validationMessage={
                                  errors?.expiration_month?.message
                                }
                                showChangeIndicator={
                                  editMode &&
                                  Boolean(dirtyFields.expiration_month)
                                }
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      )}

                      {watchTicket.expiration_type === 'absolute' && (
                        <>
                          <Row>
                            <Col sm={12} style={{ display: 'flex' }}>
                              <FormLabel label='開始※' />
                              {editMode &&
                                Boolean(dirtyFields.expiration_from) && (
                                  <ChangeIndicator />
                                )}
                            </Col>
                            <Col
                              sm={12}
                              style={{ marginBottom: 10, width: 220 }}>
                              <Controller
                                control={control}
                                name='expiration_from'
                                rules={{
                                  validate: (v) =>
                                    Boolean(v) || '期間を入力してください',
                                }}
                                render={({ value, onChange }) => (
                                  <Flatpickr
                                    value={value}
                                    disabled={!editable}
                                    options={{
                                      altInput: true,
                                      allowInput: true,
                                      locale: Japanese,
                                      altFormat: 'Y-m-d',
                                    }}
                                    onChange={(date: Date[]) =>
                                      onChange(formatDateExceptTime(date[0]))
                                    }
                                  />
                                )}
                              />
                            </Col>
                            <Col sm={12}>
                              <HelpBlock className='text-danger'>
                                {errors?.expiration_from?.message}
                              </HelpBlock>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12} style={{ display: 'flex' }}>
                              <FormLabel label='終了※' />
                              {editMode &&
                                Boolean(dirtyFields.expiration_to) && (
                                  <ChangeIndicator />
                                )}
                            </Col>
                            <Col
                              sm={12}
                              style={{ marginBottom: 10, width: 220 }}>
                              <Controller
                                control={control}
                                name='expiration_to'
                                rules={{ validate: validateExpirationTo }}
                                render={({ value, onChange }): JSX.Element => (
                                  <Flatpickr
                                    value={value}
                                    disabled={!editable}
                                    options={{
                                      altInput: true,
                                      allowInput: true,
                                      locale: Japanese,
                                      altFormat: 'Y-m-d',
                                    }}
                                    onChange={(date: Date[]): void => {
                                      onChange(formatDateExceptTime(date[0]))
                                    }}
                                  />
                                )}
                              />
                            </Col>
                            <Col sm={12}>
                              <HelpBlock className='text-danger'>
                                {errors?.expiration_to?.message}
                              </HelpBlock>
                            </Col>
                          </Row>
                        </>
                      )}

                      {editMode && ticket?.status === 'limited_open' && (
                        <FormGroup bsSize='large'>
                          <FormLabel label='限定公開リンク' />
                          {isViewObjGroup() ? (
                            <p>
                              オーナーおよびグループは限定公開機能に対応しておりません。対応をお待ち下さい。
                            </p>
                          ) : (
                            <div>
                              <span id={LIMITED_OPEN_URL_ELEMENT_ID}>
                                {ticket?.limited_open_url}
                              </span>
                              <OverlayTrigger
                                placement='bottom'
                                overlay={
                                  <Tooltip id='tooltip'>
                                    <strong>コピーする</strong>
                                  </Tooltip>
                                }>
                                <i
                                  className='far fa-copy'
                                  aria-hidden='true'
                                  onClick={() =>
                                    copyStoreText(LIMITED_OPEN_URL_ELEMENT_ID)
                                  }
                                />
                              </OverlayTrigger>
                            </div>
                          )}
                        </FormGroup>
                      )}
                      <HelpBlock>
                        ※のついた項目は公開後編集不可となります。
                      </HelpBlock>
                    </>
                  }
                />
              </Col>
            </Row>

            {!editMode && (
              <Row>
                <Col md={10}>
                  <Card
                    title='公開設定'
                    content={
                      <SelectorForm
                        name='status'
                        inputRef={register}
                        width={180}
                        style={{ marginBottom: 0 }}
                        options={(isViewObjGroup()
                          ? ['draft', 'open']
                          : ['draft', 'open', 'limited_open']
                        ).map((status) => ({
                          label: TicketContentStatusLabels[status],
                          value: status,
                        }))}
                      />
                    }
                  />
                </Col>
              </Row>
            )}

            <Row>
              <Col md={10}>
                <Row style={{ marginBottom: 30 }}>
                  <Col xs={6} md={5} mdOffset={1}>
                    <CustomButton block fill onClick={history.goBack}>
                      <b>もどる</b>
                    </CustomButton>
                  </Col>
                  <Col xs={6} md={5}>
                    <LoadingButton
                      type='submit'
                      color='info'
                      label={editMode ? '編集する' : '作成する'}
                      loadingLabel={editMode ? '編集中...' : '作成中...'}
                      block
                      fill
                      style={{ fontWeight: 'bold' }}
                      disabled={!isDirty}
                      loading={loading}
                    />
                  </Col>
                </Row>

                {editMode && (
                  <Row>
                    <Col xs={8} xsOffset={2} md={6} mdOffset={3}>
                      <CustomButton
                        bsStyle='danger'
                        block
                        simple
                        onClick={openDeleteModal}
                        style={{ fontWeight: 'bold' }}
                        disabled={loading || watchTicket.status !== 'draft'}>
                        削除する
                      </CustomButton>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </Form>

          <Prompt
            when={isDirty}
            message='行った変更が保存されていない可能性があります。このページを離れますか？'
          />
        </Grid>
      </FormProvider>

      <FormChangedAlertModal
        title='チケットの更新'
        show={isOpenSubmitModal}
        onSubmit={handleUpdateButton}
        onCancel={closeSubmitModal}>
        チケットを更新してよろしいですか？
        <ModalContentFormChangedList changedProperties={changedProperties} />
      </FormChangedAlertModal>

      <FormChangedAlertModal
        title='チケットの削除'
        show={isOpenDeleteModal}
        onSubmit={deleteTicket}
        onCancel={closeDeleteModal}
        submitButtonLabel='チケットを削除する'
        displayStatus='danger'>
        チケットを削除してよろしいですか？
      </FormChangedAlertModal>
    </>
  )
}

export default TicketFormView
