import React, { useEffect, useMemo, useCallback } from 'react'
import { Prompt, withRouter } from 'react-router'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import { Grid, Row, Col } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { isOwner } from '../../lib/staff'
import { useApi, useBooleanState } from '../../lib/hooks'
import {
  MembersCardContentInterface,
  MembersCardStatusType,
} from '../../containers/entities/MembersCardContentEntityContainer'
import GuideButton from '../../components/Link/GuideLink'
import PointCouponListTable from './parts/PointCouponListTable'
import RankSettingCard from './parts/RankSettingCard'
import PointSettingCard from './parts/PointSettingCard'
import MembersCardSettingCard from './parts/MembersCardSettingCard'
import MembersCardRankTable from './parts/MembersCardRankTable'
import PointOverrideSettingList from './parts/PointOverrideSettingList'
import { MembersCardForm } from './type.d'
import MembersCardPublishSettingCard from './parts/MembersCardPublishSettingCard'
import StatusAlert from './parts/StatusAlert'
import {
  Alert,
  FormChangedAlertModal,
  ModalContentFormChangedList,
} from '../../components/Modal/FormChangedAlertModal'
import { useNotification } from '../../providers/NotificationProvider'
import { useLoginContext } from '../../providers/LoginContextProvider'

const FieldLabels = {
  display_store_name: 'カード表示用店舗名',
  how_to_get: 'ため方',
  usage_text: '会員カードの詳しい説明',
  member_code_prefix: '会員カード接頭辞',
  rank_enabled: 'ランク機能',
  point_enabled: 'ポイント機能',

  default_rank_up_method: 'ランクアップ方法',
  rank_value_expiration_month: '対象とする期間',

  check_in_point_enabled: 'チェックインポイント設定',
  check_in_point: 'チェックインポイント数',
  payment_point_type: 'お会計ポイント設定',
  payment_point_amount: 'お会計ポイント数',
  point_rate: 'ポイント還元率',
  point_expiration_month: 'ポイントの有効期限',
} as const

type MembersCardParam = Partial<MembersCardForm>

const MembersCardView = (): JSX.Element => {
  const { showSuccessNotification } = useNotification()
  const { currentStaff } = useLoginContext()
  const history = useHistory()
  const membersCardContentApi = useApi<MembersCardContentInterface>()

  const methods = useForm<MembersCardForm>()

  const { formState, reset, watch, getValues, setValue } = methods
  const { isDirty, dirtyFields } = formState

  const formValuesBy = (
    membersCardContent: MembersCardContentInterface,
  ): MembersCardForm => ({
    display_store_name: membersCardContent.display_store_name ?? '',
    how_to_get: membersCardContent.how_to_get ?? '',
    usage_text: membersCardContent.usage_text ?? '',
    member_code_prefix: membersCardContent.member_code_prefix ?? '',
    rank_value_expiration_month:
      membersCardContent.rank_value_expiration_month ?? 0,
    check_in_point_enabled: membersCardContent.check_in_point_enabled
      ? 'enabled'
      : 'disabled',
    point_expiration_month: membersCardContent.point_expiration_month ?? 0,
    rank_enabled: membersCardContent.rank_enabled,
    point_enabled: membersCardContent.point_enabled,
    default_rank_up_method: membersCardContent.default_rank_up_method,
    payment_point_type: membersCardContent.payment_point_type,
    check_in_point: membersCardContent.check_in_point,
    payment_point_amount: membersCardContent.payment_point_amount,
    point_rate: (membersCardContent.point_rate ?? 0) * 100,
    point_qrcodes: membersCardContent.point_qrcodes,
  })

  const [isOpenModal, openModal, closeModal] = useBooleanState(false)
  const defaultValues = useMemo(
    () =>
      membersCardContentApi.response
        ? formValuesBy(membersCardContentApi.response)
        : null,
    [membersCardContentApi.response],
  )
  const membersCardContent = membersCardContentApi.response
  const membersCardContentStatus = membersCardContentApi.response?.status
  const membersCardRanks =
    membersCardContentApi.response?.members_card_ranks || []
  const { api, loading } = useApi()

  const watchRankEnabled = watch('rank_enabled')
  const watchPointEnabled = watch('point_enabled')
  const watchDefaultRankUpMethod = watch('default_rank_up_method')
  const watchCheckInPointIsEnabled =
    watch('check_in_point_enabled') === 'enabled'
  const watchPaymentPointType = watch('payment_point_type')

  const reloadMembersCardContents = useCallback(
    async () => membersCardContentApi.api.get('/members_card_contents'),
    [membersCardContentApi.api],
  )

  const resetLatestFormValues = useCallback(async () => {
    const { data } = await reloadMembersCardContents()
    reset(formValuesBy(data))
  }, [reloadMembersCardContents, reset])

  useEffect(() => {
    resetLatestFormValues()
  }, [resetLatestFormValues])

  const goToRankPage = (rankId: number | undefined): void => {
    if (rankId) {
      return history.push('memberscard/memberscard_rank/edit', {
        rankId: rankId,
      })
    }

    return history.push('memberscard/memberscard_rank/new')
  }

  const updateMembersCardContent = async (params) => {
    await api.patch('/members_card_contents', { members_card_content: params })
    showSuccessNotification('更新しました。')
  }

  const handleChangeStatus = async (status: MembersCardStatusType) => {
    await updateMembersCardContent({ status })
    resetLatestFormValues()
  }

  const handleSubmit = async () => {
    const values = getValues()
    const updatedValues: MembersCardParam = Object.keys(dirtyFields).reduce(
      (result, key) => {
        const value = values[key]
        switch (key) {
          case 'rank_value_expiration_month':
          case 'point_expiration_month':
            return { ...result, [key]: value === 0 ? null : value }
          case 'check_in_point_enabled':
            return { ...result, [key]: value === 'enabled' }
          case 'check_in_point': {
            const checkInPointEnabled =
              values.point_enabled &&
              values.check_in_point_enabled === 'enabled'
            return { ...result, [key]: checkInPointEnabled ? value : null }
          }
          case 'payment_point_amount': {
            const paymentPointEnabled =
              values.point_enabled && values.payment_point_type !== 'disable'
            return { ...result, [key]: paymentPointEnabled ? value : null }
          }
          case 'point_rate': {
            const pointRateEnabled =
              values.point_enabled && values.payment_point_type !== 'disable'
            return { ...result, [key]: pointRateEnabled ? value / 100 : null }
          }
          default:
            return { ...result, [key]: values[key] }
        }
      },
      {},
    )

    await updateMembersCardContent(updatedValues)
    resetLatestFormValues()
    closeModal()
  }

  const warnings: string[] = []
  if (dirtyFields.rank_enabled && !watchRankEnabled) {
    warnings.push(
      'ランク機能を無効に設定すると、本日に限り初期ランク以外のユーザーに会員カードが表示されなくなります。また、初期ランク以外のユーザーは翌日以降に初期ランクに更新されます。',
    )
  }
  if (dirtyFields.point_enabled && !watchPointEnabled) {
    warnings.push(
      'ポイント機能を無効に設定すると、ユーザーがポイント獲得できなくなり、またポイントの利用もできなくなります。',
    )
  }
  if (
    dirtyFields.point_enabled &&
    !watchPointEnabled &&
    watchDefaultRankUpMethod === 'rank_up_by_point' &&
    watchRankEnabled
  ) {
    warnings.push(
      'ランクアップ方法が獲得ポイントの場合、ポイント機能を無効に設定するとポイント獲得ができなくなるのでランクアップもできなくなります。',
    )
  }
  if (
    dirtyFields.default_rank_up_method &&
    watchDefaultRankUpMethod !== 'rank_up_by_qrcode' &&
    watchRankEnabled
  ) {
    warnings.push(
      'ランクアップ方法を変更すると、ユーザーのランクアップする条件が変化します。',
    )
  }
  if (
    dirtyFields.check_in_point_enabled &&
    !watchCheckInPointIsEnabled &&
    watchPointEnabled
  ) {
    warnings.push(
      'チェックインポイントを無効に設定すると、チェックイン時にユーザーがポイント獲得できなくなります。',
    )
  }
  if (
    dirtyFields.payment_point_type &&
    watchPaymentPointType === 'disable' &&
    watchPointEnabled
  ) {
    warnings.push(
      'お会計によるポイント付与を無効に設定すると、お会計によるポイント付与ができなくなります。',
    )
  }

  return (
    <>
      <FormProvider {...methods}>
        <div className='content'>
          <Grid fluid>
            <Row>
              <Col xs={12} lg={10}>
                <div className='tenantAlertContainer'>
                  <div style={{ color: '#01B902', display: 'flex' }}>
                    <div style={{ alignSelf: 'center' }}>
                      {isOwner() ? (
                        <span>
                          会員カードの設定は全店舗共通です。変更の際はご注意ください。
                        </span>
                      ) : (
                        <span>
                          会員カードの設定はオーナーに所属する全店舗共通です。確認/変更を行う場合は管理者に連絡してオーナー権限をリクエストしてください。
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            {membersCardContentStatus && (
              <Row>
                <Col xs={12} lg={10}>
                  <StatusAlert status={membersCardContentStatus} />
                </Col>
              </Row>
            )}

            {isOwner() ? (
              <div>
                <Row style={{ marginBottom: 10 }}>
                  <Col
                    xs={12}
                    lg={10}
                    style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <GuideButton
                      label='会員カード'
                      link='https://toypo.notion.site/71843d7888d4412aa51ea374295f4f27'
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    {membersCardContentStatus && (
                      <MembersCardSettingCard
                        membersCardContentStatus={membersCardContentStatus}
                        disabled={loading}
                        onSubmit={openModal}
                      />
                    )}

                    {membersCardContent && (
                      <RankSettingCard
                        canEdit={
                          membersCardContent.default_rank_up_method !==
                          'rank_up_by_qrcode'
                        }
                        disabled={loading}
                        onSubmit={openModal}
                        style={{
                          display: !watchRankEnabled ? 'none' : 'block',
                        }}
                      />
                    )}

                    {membersCardContent && membersCardRanks && (
                      <MembersCardRankTable
                        ranks={membersCardRanks || []}
                        default_rank_up_method={membersCardContent.default_rank_up_method}
                        rank_enabled={membersCardContent.rank_enabled && watchRankEnabled}
                        onClick={(rankId) => goToRankPage(rankId)}
                      />
                    )}

                    {membersCardContent && defaultValues && (
                      <PointSettingCard
                        membersCardContent={membersCardContent}
                        onAddedMembersCardQrCode={resetLatestFormValues}
                        onDeletedMembersCardQrCode={resetLatestFormValues}
                        disabled={loading}
                        onSubmit={openModal}
                        onDisableCheckInPoint={() =>
                          setValue(
                            'check_in_point',
                            defaultValues.check_in_point,
                          )
                        }
                        onDisablePaymentPoint={() => {
                          // お会計ポイントを無効にする場合、お会計ポイント数やポイント還元率が
                          // 変更されていたとしてもユーザーにとっては不要な情報なので、
                          // サブミット時にアラート表示されないようにデフォルト値を再設定。
                          setValue('point_rate', defaultValues.point_rate)
                          setValue(
                            'payment_point_amount',
                            defaultValues.payment_point_amount,
                          )
                        }}
                        style={{
                          display: !watchPointEnabled ? 'none' : 'block',
                        }}
                      />
                    )}

                    {currentStaff.is_toypo_member && membersCardContent && (
                      <Box mb={4} width='83%'>
                        <PointOverrideSettingList
                          membersCardContent={membersCardContent}
                        />
                      </Box>
                    )}

                    {watchPointEnabled && (
                      <PointCouponListTable history={history} />
                    )}

                    {membersCardContentStatus && (
                      <MembersCardPublishSettingCard
                        status={membersCardContentStatus}
                        onChangeStatus={handleChangeStatus}
                        disabled={loading}
                      />
                    )}

                    <Prompt
                      when={isDirty}
                      message='行った変更が保存されていない可能性があります。このページを離れますか？'
                    />
                  </Col>
                </Row>
              </div>
            ) : null}
          </Grid>
        </div>
      </FormProvider>

      <FormChangedAlertModal
        show={isOpenModal}
        title='会員カード設定を更新'
        onSubmit={handleSubmit}
        onCancel={closeModal}
        disabled={loading}
        submitButtonLabel='更新する'>
        会員カードランク設定の内容を更新してよろしいですか？
        <ModalContentFormChangedList
          changedProperties={Object.entries(FieldLabels)
            .map(([key, label]) => (dirtyFields[key] ? label : ''))
            .filter((v) => v)}
        />
        {warnings.length > 0 && (
          <Alert severity='warning'>
            <ul>
              {warnings.map((warning) => (
                <li>{warning}</li>
              ))}
            </ul>
          </Alert>
        )}
      </FormChangedAlertModal>
    </>
  )
}

export default withRouter(MembersCardView)
