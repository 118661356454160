import React from 'react'
import moment from 'moment'
import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import LinearProgress from '@mui/material/LinearProgress'
import { displayJpReadableNumber } from '../../../lib/general'
import { StoreBenchmarkIndicatorLabels } from '../../../constants/storeBenchmark'
import {
  StoreBenchmarkResource,
  StoreBenchmarkBlock,
} from '../../../types/storeBenchmark'
import { progressColor } from '../utils'
import BenchmarkStatusChip from './BenchmarkStatusChip'
import BenchmarkListEmpty from './BenchmarkListEmpty'

type Props = {
  benchmarks: StoreBenchmarkBlock[]
  onClick: (benchmark: StoreBenchmarkResource) => void
}

const LaptopBenchmarkList = ({ benchmarks, onClick }: Props): JSX.Element => {
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ステータス</TableCell>
            <TableCell>目標タイトル</TableCell>
            <TableCell>指標</TableCell>
            <TableCell>店舗</TableCell>
            <TableCell>月次の目標達成度</TableCell>
            <TableCell>今日までの目標達成度</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {benchmarks.map((benchmarkBlock) => (
            <LaptopBenchmarkRow
              key={benchmarkBlock.store_benchmark.id}
              benchmarkBlock={benchmarkBlock}
              onClick={() => onClick(benchmarkBlock.store_benchmark)}
            />
          ))}
        </TableBody>
      </Table>

      {benchmarks.length === 0 && <BenchmarkListEmpty />}
    </>
  )
}

type LaptopBenchmarkRowProps = {
  benchmarkBlock: StoreBenchmarkBlock
  onClick: () => void
}

const LaptopBenchmarkRow = ({
  benchmarkBlock,
  onClick,
}: LaptopBenchmarkRowProps): JSX.Element => {
  const { store, store_benchmark, children } = benchmarkBlock
  const dailyBenchmarksUpToToday = children.filter((child) =>
    moment(child.store_benchmark.start_date).isSameOrBefore(moment(), 'day'),
  )
  const sumOfBenchmark = dailyBenchmarksUpToToday.reduce(
    (sum, child) => sum + child.store_benchmark.benchmark_value,
    0,
  )
  const sumOfPerformance = dailyBenchmarksUpToToday.reduce(
    (sum, child) => sum + (child.store_benchmark.performance_value || 0),
    0,
  )
  const progress =
    (store_benchmark.performance_value || 0) / store_benchmark.benchmark_value
  const roundedProgress = Math.floor(progress * 100)
  const ongoing = store_benchmark.benchmark_progress === 'ongoing'
  const before = store_benchmark.benchmark_progress === 'before'
  const active = store_benchmark.status === 'active'
  const paused = store_benchmark.status === 'paused'
  const done = ['achieved', 'unachieved'].includes(
    store_benchmark.benchmark_progress,
  )

  return (
    <TableRow hover style={{ cursor: 'pointer' }} onClick={onClick}>
      <TableCell>
        <BenchmarkStatusChip benchmark={store_benchmark} />
      </TableCell>
      <TableCell>
        <Box fontWeight={700} fontSize={15}>
          {store_benchmark.title}
        </Box>
        <Box fontSize={12} color='#555'>
          {store_benchmark.start_date} 〜 {store_benchmark.end_date}
        </Box>
      </TableCell>
      <TableCell>
        {StoreBenchmarkIndicatorLabels[store_benchmark.benchmark_indicator]}
      </TableCell>
      <TableCell>{store.name}</TableCell>
      <TableCell>
        <Box display='flex' alignItems='center'>
          <Box
            flexBasis={40}
            color={done ? progressColor(roundedProgress) : 'inherit'}
            fontWeight={700}
            textAlign='right'>
            {before || paused ? '--' : `${roundedProgress}%`}
          </Box>

          <Box
            mx={1}
            width={100}
            color={done ? progressColor(roundedProgress) : 'inherit'}>
            <LinearProgress
              sx={{ height: 10, borderRadius: 4 }}
              variant='determinate'
              value={Math.min(100, roundedProgress)}
              color='inherit'
            />
          </Box>

          <Box fontSize={13} display='flex' gap={0.5}>
            {before || paused
              ? '--'
              : displayJpReadableNumber(store_benchmark.performance_value || 0)}
            /{displayJpReadableNumber(store_benchmark.benchmark_value)}
          </Box>
        </Box>
      </TableCell>
      <TableCell>
        {active && ongoing && children.length > 0 ? (
          <Box display='flex'>
            {displayJpReadableNumber(sumOfPerformance)} /{' '}
            {displayJpReadableNumber(sumOfBenchmark)}
            {sumOfBenchmark > 0 && (
              <Box
                ml={2}
                fontWeight={700}
                color={progressColor(roundedProgress)}>
                {Math.floor((sumOfPerformance / sumOfBenchmark) * 100)}%
              </Box>
            )}
          </Box>
        ) : (
          '--'
        )}
      </TableCell>
    </TableRow>
  )
}

export default LaptopBenchmarkList
