import React from 'react'
import { Table } from 'react-bootstrap'
import { Smartphone } from '@mui/icons-material'
import { SortOrderType } from '../../../types/common.d'
import { UserSortByType, UserTableType } from '../../../types/user'
import { formatDateExceptTime, formatRelativeDate } from '../../../lib/general'
import { DEFAULT_AVATAR } from '../../../lib/const'
import { ColorType } from '../../../containers/entities/MembersCardContentEntityContainer'
import UserIcon from './UserIcon'
import SortIconTh from '../../../components/Table/SortIconTh'

type Props = {
  onClickCustomer: (customerId: number) => void
  users: UserTableType
  sortBy: UserSortByType
  sortOrder: SortOrderType
  onSort: (sortBy: UserSortByType, sortOrder: SortOrderType) => void
}

const sortableHeader = [
  { title: '来店数', value: 'visit_count' },
  { title: '最終来店日', value: 'last_visit_at' },
  { title: '登録日', value: 'connected_at' },
] as const

const CustomerListLarge = ({
  onClickCustomer,
  users,
  sortBy,
  sortOrder,
  onSort,
}: Props): JSX.Element => (
  <Table striped hover>
    <thead>
      <tr>
        {['氏名', '電話番号'].map((prop) => (
          <th key={`column-${prop}`}>{prop}</th>
        ))}
        {sortableHeader.map((prop) => (
          <SortIconTh
            key={`column-${prop.value}`}
            sortOrder={sortBy === prop.value ? sortOrder : null}
            onChangeSortOrder={(newSortOrder) =>
              onSort(prop.value, newSortOrder)
            }>
            {prop.title}
          </SortIconTh>
        ))}
      </tr>
    </thead>
    <tbody>
      {users.map((customer) => (
        <tr
          key={`customer-management${customer.id}`}
          onClick={() => onClickCustomer(customer.id)}
          style={{ cursor: 'pointer' }}>
          <td>
            <UserNameSection
              name={customer.name}
              nickName={customer.nick_name}
              rankColor={customer.rank_color}
              memberCode={customer.member_code}
              rankName={customer.rank_name}
              avatar={customer.avatar_url || DEFAULT_AVATAR[customer.sex]}
              id={customer.id}
            />
          </td>
          <td>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}>
              <Smartphone
                style={{
                  color: '#D8D8D8',
                  marginRight: 4,
                }}
              />
              {customer.phone_number
                ? customer.phone_number.substring(
                    customer.phone_number.length - 4,
                  )
                : '---'}
            </div>
          </td>
          <td>{customer.visit_count}</td>
          <td>
            {customer.last_visit_at
              ? formatRelativeDate(customer.last_visit_at)
              : '---'}
          </td>
          <td>{formatDateExceptTime(customer.connected_at)}</td>
        </tr>
      ))}
    </tbody>
  </Table>
)

type UserNameSectionProps = {
  name: string
  nickName: string | null
  memberCode: string | null
  rankColor: ColorType | null
  rankName: string | null
  avatar: string
  id: number
}

const UserNameSection = ({
  name,
  nickName,
  memberCode,
  rankColor,
  rankName,
  avatar,
  id,
}: UserNameSectionProps): JSX.Element => (
  <div style={{ display: 'flex', alignItems: 'center', gap: 16 }}>
    <UserIcon avatar={avatar} rankColor={rankColor} />

    <div>
      <div style={{ fontWeight: 'bold' }}>
        {(name || id) + (nickName ? `（${nickName}）` : '')}
      </div>
      <div style={{ color: '#9a9a9a', fontSize: 12, display: 'flex' }}>
        {Boolean(rankName) && <div>{rankName}・</div>}
        {Boolean(memberCode) && <div>{memberCode}</div>}
      </div>
    </div>
  </div>
)

export default CustomerListLarge
