import React from 'react'
import moment from 'moment'
import Box from '@mui/material/Box'

type Props = {
  value: string
}

const OrderScheduledDateTimeLabel = ({ value }: Props): JSX.Element => {
  const momented = moment(value)
  const today = moment()

  return (
    <>
      {momented.isSame(today, 'day') ? (
        <Box display='flex' alignItems='baseline'>
          <Box fontSize={15}>本日</Box>
          <Box fontSize={24} fontWeight={700}>
            {momented.format('HH:mm')}
          </Box>
        </Box>
      ) : (
        <Box fontSize={15}>{momented.format('YYYY/M/D(dddd) HH:mm')}</Box>
      )}
    </>
  )
}

export default OrderScheduledDateTimeLabel
