import React, { useState } from 'react'
import { createContainer } from 'unstated-next'
import {
  SmileOutlined,
  BellOutlined,
  SyncOutlined,
  GiftOutlined,
  BookOutlined,
  IdcardOutlined,
  FileDoneOutlined,
  PictureOutlined,
  TransactionOutlined,
  CalendarOutlined,
  CoffeeOutlined,
  CrownOutlined,
} from '@ant-design/icons'
import {
  stampCardApi,
  ticketFeatureApi,
  membersCardContentApi,
  orderFeatureApi,
  bannerFeatureApi,
  subscriptionContentApi,
  calendarFeatureApi,
  ecFeatureApi,
  rankingFeatureApi,
} from '../../lib/api'

const useFeatureChoiceViewHook = () => {
  const [activeFeature, setActiveFeature] = useState([])
  const [nonActiveFeature, setNonActiveFeature] = useState([])
  const state = {
    activeFeature,
    nonActiveFeature,
  }

  const setViewState = {
    activeFeature: setActiveFeature,
    nonActiveFeature: setNonActiveFeature,
  }

  const api = {
    updateTicketFeature: async (bool, showDialog = true) => {
      if (showDialog) {
        if (
          !window.confirm(`チケット機能を${bool ? '有効' : '無効'}にしますか？`)
        ) {
          return
        }
      }
      await ticketFeatureApi.updateTicketFeatureContent({
        params: { active: bool },
      })
      window.location.reload(true)
    },
    updateStampCardContent: async (bool, showDialog = true) => {
      if (showDialog) {
        if (
          !window.confirm(
            `スタンプカード機能を${bool ? '有効' : '無効'}にしますか？`,
          )
        ) {
          return
        }
      }
      await stampCardApi.updateStampCardContent({
        params: { active: bool },
      })
      window.location.reload(true)
    },
    updateMembersCardContent: async (bool, showDialog = true) => {
      if (showDialog) {
        if (
          !window.confirm(
            `会員カード機能を${bool ? '有効' : '無効'}にしますか？`,
          )
        ) {
          return
        }
      }
      await membersCardContentApi.updateMembersCardContent({
        params: { active: bool },
      })
      window.location.reload(true)
    },
    updateDeliveryFeature: async (bool, showDialog = true) => {
      if (showDialog) {
        if (
          !window.confirm(
            `デリバリー機能を${bool ? '有効' : '無効'}にしますか？`,
          )
        ) {
          return
        }
      }
      await orderFeatureApi.updateOrderFeature({
        params: { active: true, delivery_enabled: bool },
      })
      window.location.reload(true)
    },
    updateTakeoutFeature: async (bool, showDialog = true) => {
      if (showDialog) {
        if (
          !window.confirm(
            `テイクアウト機能を${bool ? '有効' : '無効'}にしますか？`,
          )
        ) {
          return
        }
      }
      await orderFeatureApi.updateOrderFeature({
        params: { active: true, takeout_enabled: bool },
      })
      window.location.reload(true)
    },
    updateBannerFeature: async (bool, showDialog = true) => {
      if (showDialog) {
        if (
          !window.confirm(`バナー機能を${bool ? '有効' : '無効'}にしますか？`)
        ) {
          return
        }
      }
      await bannerFeatureApi.updateBannerFeature({
        params: { active: bool },
      })
      window.location.reload()
    },
    updateSubscriptionFeature: async (bool, showDialog = true) => {
      if (showDialog) {
        if (
          !window.confirm(`サブスク機能を${bool ? '有効' : '無効'}にしますか？`)
        ) {
          return
        }
      }
      await subscriptionContentApi.updateSubscriptionFeature({ active: bool })
      window.location.reload()
    },
    updateCalendarFeature: async (bool, showDialog = true) => {
      if (showDialog) {
        if (
          !window.confirm(
            `カレンダー機能を${bool ? '有効' : '無効'}にしますか？`,
          )
        ) {
          return
        }
      }
      await calendarFeatureApi.updateCalendarFeature({
        params: { active: bool },
      })
      window.location.reload()
    },
    updateEcFeature: async (bool, showDialog = true) => {
      if (showDialog) {
        if (
          !window.confirm(
            `オンラインショップ機能を${bool ? '有効' : '無効'}にしますか？`,
          )
        ) {
          return
        }
      }
      await ecFeatureApi.updateEcFeature({ params: { active: bool } })
      window.location.reload()
    },
    updateRankingFeature: async (bool, showDialog = true) => {
      if (showDialog) {
        if (
          !window.confirm(
            `ランキング機能を${bool ? '有効' : '無効'}にしますか？`,
          )
        ) {
          return
        }
      }
      await rankingFeatureApi.updateRankingFeature({
        params: { active: bool },
      })
      window.location.reload()
    },
  }

  const logic = {
    createFeature: () => [
      {
        id: 1,
        title: 'お知らせ',
        description: '情報を配信、プッシュ通知も可能',
        icon: {
          icon: <BellOutlined />,
          color: '#00B2F4',
        },
        showMoreInfoLink: true,
        type: ['default', 'distribution'],
        function: 'notice',
        selectActive: false,
        active: false,
        externalLink: null,
        note: '※この機能は無効にできません',
      },
      {
        id: 2,
        title: 'カレンダー',
        description: 'イベント等をカレンダーで表示',
        icon: {
          icon: <CalendarOutlined />,
          color: '#40A837',
        },
        showMoreInfoLink: true,
        type: ['distribution'],
        function: 'calendar',
        onChange: api.updateCalendarFeature,
        selectActive: true,
        active: false,
      },
      {
        id: 3,
        title: 'メニュー',
        description: '店舗のメニューを掲載',
        icon: {
          icon: <CoffeeOutlined />,
          color: '#F6AA50',
        },
        showMoreInfoLink: false,
        type: ['default', 'distribution'],
        function: 'menu',
        selectActive: false,
        active: false,
        externalLink: null,
        note: '※この機能は無効にできません',
        excludeType: 'group',
      },
      {
        id: 4,
        title: 'バナー',
        description: 'ホーム画面にバナーを表示',
        icon: {
          icon: <PictureOutlined />,
          color: '#6383FC',
        },
        showMoreInfoLink: true,
        type: ['distribution'],
        function: 'banner',
        onChange: api.updateBannerFeature,
        selectActive: true,
        active: false,
      },
      {
        id: 5,
        title: 'クーポン',
        description: '割引などのお得なクーポンを配信',
        icon: {
          icon: <GiftOutlined />,
          color: '#EE5F64',
        },
        showMoreInfoLink: true,
        type: ['default', 'visit'],
        function: 'coupon',
        selectActive: false,
        active: false,
        externalLink: null,
        note: '※この機能は無効にできません',
      },
      {
        id: 6,
        title: 'クーポン自動配信',
        description: 'クーポンを自動で配信',
        icon: {
          icon: <SyncOutlined />,
          color: '#00B2F4',
        },
        showMoreInfoLink: true,
        type: ['default', 'visit'],
        function: 'coupon',
        selectActive: false,
        active: false,
        note: '※この機能は無効にできません',
      },
      {
        id: 7,
        title: 'チケット・回数券',
        description: 'オンライン上で電子チケットを販売',
        fontAwesome: 'fas fa-ticket-alt',
        icon: {
          icon: <BookOutlined />,
          color: '#47B98A',
        },
        showMoreInfoLink: true,
        function: 'ticket',
        type: ['visit'],
        onChange: api.updateTicketFeature,
        selectActive: true,
        active: false,
      },
      {
        id: 8,
        title: 'サブスク',
        description: 'サブスク形式のチケットを配信',
        icon: {
          icon: <TransactionOutlined />,
          color: '#7064F4',
        },
        showMoreInfoLink: true,
        type: ['visit'],
        function: 'subscription',
        onChange: api.updateSubscriptionFeature,
        selectActive: true,
        active: false,
      },
      {
        id: 9,
        title: '会員カード',
        description: 'QRコード読み取りでポイントを付与',
        icon: {
          icon: <IdcardOutlined />,
          color: '#ECC700',
        },
        showMoreInfoLink: true,
        function: 'members_card',
        type: ['visit'],
        onChange: api.updateMembersCardContent,
        selectActive: true,
        active: false,
      },
      {
        id: 10,
        title: 'スタンプカード',
        description: 'QRコード読み取りでスタンプを付与',
        icon: {
          icon: <SmileOutlined />,
          color: '#F6AA50',
        },
        showMoreInfoLink: true,
        function: 'stamp_card',
        type: ['visit'],
        onChange: api.updateStampCardContent,
        selectActive: true,
        active: false,
      },
      {
        id: 12,
        title: 'テイクアウト',
        description: 'アプリでテイクアウト',
        icon: {
          icon: <FileDoneOutlined />,
          color: '#718096',
        },
        showMoreInfoLink: true,
        type: ['optimization'],
        function: 'takeout',
        onChange: api.updateTakeoutFeature,
        selectActive: true,
        active: false,
        excludeType: 'group',
      },
      {
        id: 13,
        title: 'デリバリー',
        description: 'アプリでデリバリー',
        icon: {
          icon: <FileDoneOutlined />,
          color: '#718096',
        },
        showMoreInfoLink: true,
        type: ['optimization'],
        function: 'delivery',
        onChange: api.updateDeliveryFeature,
        selectActive: true,
        active: false,
        excludeType: 'group',
      },
      {
        id: 14,
        title: 'ランキング',
        description:
          '指定期間のチェックイン数やポイント数などに応じたランキングを開催',
        icon: {
          icon: <CrownOutlined />,
          color: '#EECD22',
        },
        showMoreInfoLink: true,
        type: ['visit'],
        function: 'ranking',
        onChange: api.updateRankingFeature,
        selectActive: true,
        active: false,
      },
    ],
  }
  return { state, setViewState, logic }
}

export default createContainer(useFeatureChoiceViewHook)
