import { storage } from './general'
import { StoreObject } from '../types/staff'

type StaffType = {
  isOwner: boolean
  stores: StoreObject[]
}

export const loadStaff = (): StaffType | null => {
  const staff = storage.getItem('staff')
  return staff ? JSON.parse(staff) : null
}

export const setStaff = ({ isOwner, stores }: StaffType): void => {
  storage.setItem('staff', JSON.stringify({ isOwner, stores }))
}

export const removeStaff = (): boolean => {
  try {
    storage.removeItem('staff')
    return true
  } catch (error) {
    return false
  }
}

export const isOwner = (): boolean => loadStaff()?.isOwner ?? false

export const hasMultipleStores = (): boolean =>
  (loadStaff()?.stores?.length ?? 0) > 1
