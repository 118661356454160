import { loadViewObj } from '../viewObj'
import apiClient from './apiClient'

export const getStaffs = async () => {
  const GET_STAFFS = `/staff/stores/${loadViewObj()?.id}/staffs`

  const response = await apiClient({ method: 'GET', uri: GET_STAFFS })
  return response.data
}

export const getAllStaffs = async () => {
  const GET_ALL_STAFFS = `/staff/stores/${loadViewObj()?.id}/staffs?type=all`

  const response = await apiClient({ method: 'GET', uri: GET_ALL_STAFFS })
  return response.data
}

export const getStaff = async ({ id }) => {
  const GET_STAFF = `/staff/stores/${loadViewObj()?.id}/staffs/${id}`

  const response = await apiClient({ method: 'GET', uri: GET_STAFF })
  return response.data
}

export const createStaff = async ({ staff, storeIds }) => {
  const POST_STAFF = `/staff/stores/${loadViewObj()?.id}/staffs`

  const requestParams = {
    staff: staff,
    store_ids: storeIds,
  }

  const response = await apiClient({
    method: 'POST',
    uri: POST_STAFF,
    params: requestParams,
  })

  return response.data.data
}

export const deleteStaff = async ({ id }) => {
  const DELETE_STAFF = `/staff/stores/${loadViewObj()?.id}/staffs/${id}`

  const response = await apiClient({
    method: 'DELETE',
    uri: DELETE_STAFF,
  })
  return response.data
}

export const updateStaff = async ({ id, params }) => {
  const UPDATE_STAFF_URL = `/staff/stores/${loadViewObj()?.id}/staffs/${id}`

  const BASE_URL =
    process.env.REACT_APP_BASE_URL || 'https://toypo4store-web.vercel.app/'

  // redirectさせたいURL
  const CONFIRM_SUCCESS_URL = `${BASE_URL}admin/login`
  // error時にredirectさせたいURL
  const ERROR_REDIRECT_URL = `${BASE_URL}admin/account`

  const requestParams = {
    staff: params,
    confirm_success_url: CONFIRM_SUCCESS_URL,
    error_redirect_url: ERROR_REDIRECT_URL,
  }

  const response = await apiClient({
    method: 'PATCH',
    uri: UPDATE_STAFF_URL,
    params: requestParams,
  })
  return response
}

export const updatePassword = async ({ password, currentPassword }) => {
  const UPDATE_PASSWORD = '/staff/auth'

  const params = {
    current_password: currentPassword,
    password: password,
    password_confirmation: password,
  }

  const response = await apiClient({
    method: 'PATCH',
    uri: UPDATE_PASSWORD,
    params: params,
  })
  return response.data.data
}

export const postPasswordResetLink = async ({ email }) => {
  const POST_PASSWORD_RESET_LINK = '/staff/auth/password'

  const params = {
    email: email,
    redirect_url: `https://${window.location.host}/admin/staff/password/edit`,
  }

  const response = await apiClient({
    method: 'POST',
    uri: POST_PASSWORD_RESET_LINK,
    params: params,
  })
  return response.data
}
