import React, { useEffect, useState, useCallback } from 'react'
import { Modal, Form, Row, Col, FormGroup } from 'react-bootstrap'
import { useForm, Controller } from 'react-hook-form'
import { useLoginContext } from '../../../providers/LoginContextProvider'
import LoadingButton from '../../../components/CustomButton/LoadingButton'
import FormLabel from '../../../components/FormInputs/FormLabel'
import SelectorForm from '../../../components/FormInputs/SelectorForm'
import MultipleSelectorForm from '../../../components/FormInputs/MultipleSelectorForm'
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox'
import CustomButton from '../../../components/CustomButton/CustomButton'
import DiscountEntityContainer, {
  DiscountLogFilteringCondition,
} from '../../../containers/entities/DiscountEntityContainer'
import ActivityLogEntityContainer, {
  IllegalStatus,
} from '../../../containers/entities/ActivityLogEntityContainer'
import { ActionType } from '../../../types/activityLog'

type Props = {
  show: boolean
  onHide: () => void
  changeUrl: ({ content_page, log_page, condition, period }) => void
}

type DisplayContent = {
  id: number
  store_id: number
  title: string
  store_name: string
}

const DiscountLogFilteringModal = ({
  show,
  onHide,
  changeUrl,
}: Props): JSX.Element => {
  const { currentStore } = useLoginContext()
  const [displayContents, setDisplayContents] = useState<DisplayContent[]>([])
  const discountEntityContainer = DiscountEntityContainer.useContainer()
  const {
    actionTypes,
    contents,
    discountLogFilteringCondition,
    filteringPeriod,
    logPage,
  } = discountEntityContainer.state
  const { actionTypeToContentType, getContents } = discountEntityContainer.logic
  const activityLogEntityContainer = ActivityLogEntityContainer.useContainer()
  const { actionTypesToJp } = activityLogEntityContainer.logic

  const { register, handleSubmit, formState, watch, setValue, control, reset } =
    useForm<{ discountLogFiltering: DiscountLogFilteringCondition }>({
      defaultValues: {
        discountLogFiltering: {
          action_types: [],
          content_id: null,
          store_ids: [],
          status: 'active',
          illegal_statuses: [],
        },
      },
    })
  const { isDirty } = formState

  const watchActionTypes: ActionType[] = watch(
    'discountLogFiltering.action_types',
  )
  const watchStoreIds: number[] = watch('discountLogFiltering.store_ids')
  const watchStatus = watch('discountLogFiltering.status')
  const watchIllegalStatuses: IllegalStatus[] = watch(
    'discountLogFiltering.illegal_statuses',
  )

  const processFormData = useCallback((data) => {
    const processedData = data

    if (!processedData.content_id) {
      processedData.content_id = null
    }

    return processedData
  }, [])

  const onSubmit = (data) => {
    const formData = processFormData(data.discountLogFiltering)
    changeUrl({
      condition: formData,
      period: filteringPeriod,
      content_page: 1,
      log_page: logPage,
    })
    onHide()
  }

  const onClickResetButton = () => {
    setValue('discountLogFiltering.store_ids', [], {
      shouldDirty: true,
    })
    setValue('discountLogFiltering.action_types', [], {
      shouldDirty: true,
    })
    setValue('discountLogFiltering.content_id', null, {
      shouldDirty: true,
    })
    setValue('discountLogFiltering.status', 'active', {
      shouldDirty: true,
    })
    setValue('discountLogFiltering.illegal_statuses', [], {
      shouldDirty: true,
    })
  }

  const updateContents = async (newItems) => {
    const functionItems = newItems
      .map((item) => actionTypeToContentType(item))
      .filter((x, i, self) => self.indexOf(x) === i)

    // functionItems の 大きさが 1 以外の場合は contents を空配列へ
    if (functionItems.length !== 1) {
      setDisplayContents([])
      return
    }

    if (functionItems[0] === 'coupon') {
      setDisplayContents(contents.coupons)
    } else if (functionItems[0] === 'ticket') {
      setDisplayContents(contents.tickets)
    } else if (functionItems[0] === 'subscription') {
      setDisplayContents(contents.subscriptions)
    } else if (functionItems[0] === 'stamp') {
      setDisplayContents(contents.stamp_cards)
    } else if (functionItems[0] === 'service') {
      setDisplayContents(contents.services)
    }
  }

  const addActionType = async ({ target }) => {
    const newItems = [...watchActionTypes]
    const { value } = target

    if (!value) {
      return
    }

    newItems.push(value)
    // 重複を削除
    const uniqItems = newItems.filter((x, i, self) => self.indexOf(x) === i)
    setValue('discountLogFiltering.action_types', uniqItems, {
      shouldDirty: true,
    })

    updateContents(uniqItems)
  }

  const deleteActionType = async (value) => {
    const index = watchActionTypes.findIndex((id) => id === value)
    const newItems = [...watchActionTypes]
    newItems.splice(index, 1)
    setValue('discountLogFiltering.action_types', newItems, {
      shouldDirty: true,
    })

    updateContents(newItems)
  }

  const addStoreId = ({ target }) => {
    const newItems = [...watchStoreIds]
    const { value } = target
    if (!value) {
      return
    }
    newItems.push(Number(value))
    // 重複を削除
    const uniqItems = newItems.filter((x, i, self) => self.indexOf(x) === i)
    setValue('discountLogFiltering.store_ids', uniqItems, {
      shouldDirty: true,
    })
  }

  const deleteStoreId = (value) => {
    const index = watchStoreIds.findIndex((id) => id === value)
    const newItems = [...watchStoreIds]
    newItems.splice(index, 1)
    setValue('discountLogFiltering.store_ids', newItems, {
      shouldDirty: true,
    })
  }

  useEffect(() => {
    getContents()
  }, [getContents])

  useEffect(() => {
    reset({ discountLogFiltering: discountLogFilteringCondition })
  }, [discountLogFilteringCondition, reset])

  return (
    <Modal show={show} onHide={onHide}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton>
          <Modal.Title
            style={{ display: 'flex', justifyContent: 'space-between' }}>
            <b>絞り込み設定</b>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md={12} sm={12} xs={12}>
              <FormGroup bsSize='large'>
                <Controller
                  control={control}
                  name='discountLogFiltering.store_ids'
                  render={(): JSX.Element => (
                    <MultipleSelectorForm
                      label='店舗'
                      labelAttachment='複数選択可'
                      addItem={addStoreId}
                      deleteItem={deleteStoreId}
                      options={[
                        {
                          value: '',
                          label: '選択する',
                        },
                        ...[
                          currentStore,
                          ...(currentStore.child_stores || []),
                        ].map((s) => ({
                          value: s.id,
                          label: s.name,
                        })),
                      ]}
                      selectedItems={watchStoreIds}
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12} sm={12} xs={12}>
              <FormGroup bsSize='large'>
                <Controller
                  control={control}
                  name='discountLogFiltering.action_types'
                  render={(): JSX.Element => (
                    <MultipleSelectorForm
                      label='アクション'
                      addItem={addActionType}
                      deleteItem={deleteActionType}
                      labelAttachment='複数選択可'
                      options={
                        actionTypes
                          ? [
                              {
                                value: '',
                                label: '選択する',
                              },
                              ...actionTypes?.data?.map((c) => ({
                                value: c,
                                label: actionTypesToJp(c),
                              })),
                            ]
                          : []
                      }
                      selectedItems={watchActionTypes}
                    />
                  )}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12} sm={12} xs={12}>
              <FormGroup bsSize='large'>
                <SelectorForm
                  label='コンテンツ'
                  name='discountLogFiltering.content_id'
                  disabled={
                    watchActionTypes
                      .map((item) => actionTypeToContentType(item))
                      .filter((x, i, self) => self.indexOf(x) === i).length !==
                    1
                  }
                  inputRef={register({ valueAsNumber: true })}
                  tooltipText='アクションを二つ以上選択するとコンテンツは選択できません'
                  options={[
                    {
                      value: '',
                      label: '選択する',
                    },
                    ...displayContents?.map((c) => ({
                      value: c?.id,
                      label: c?.title,
                    })),
                  ]}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12} sm={12} xs={12}>
              <FormGroup bsSize='large'>
                <FormLabel label='その他の条件' />
                <Row>
                  <Col md={12} style={{ marginBottom: 10 }}>
                    <Controller
                      control={control}
                      name='discountLogFiltering.status'
                      render={(): JSX.Element => (
                        <CustomCheckbox
                          number={1}
                          checked={watchStatus === 'cancel'}
                          label='取り消したログを表示'
                          onChange={(e): void => {
                            const status = e.target.checked
                              ? 'cancel'
                              : 'active'
                            setValue('discountLogFiltering.status', status, {
                              shouldDirty: true,
                            })
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} style={{ marginBottom: 10 }}>
                    <Controller
                      control={control}
                      name='discountLogFiltering.illegal_statuses'
                      render={(): JSX.Element => (
                        <CustomCheckbox
                          number={2}
                          checked={!!watchIllegalStatuses?.length}
                          label='不正なログを表示'
                          onChange={(e): void => {
                            const { checked } = e.target
                            setValue(
                              'discountLogFiltering.illegal_statuses',
                              checked ? ['illegal', 'doubtful'] : [],
                              {
                                shouldDirty: true,
                              },
                            )
                          }}
                        />
                      )}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>

          <LoadingButton
            type='submit'
            label='この条件で絞り込む'
            loadingLabel='絞り込み中...'
            color='info'
            fill
            block
            disabled={!isDirty}
          />

          <CustomButton
            block
            simple
            style={{ marginTop: 10 }}
            onClick={onClickResetButton}>
            <b style={{ fontSize: 14 }}>条件をリセットする</b>
          </CustomButton>
        </Modal.Body>
      </Form>
    </Modal>
  )
}

export default DiscountLogFilteringModal
