import React, { FC } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { Card } from '../../../components/Card/Card'
import LoadingButton from '../../../components/CustomButton/LoadingButton'
import SelectorForm from '../../../components/FormInputs/SelectorForm'

type Status = 'open' | 'close'

type Props = {
  editMode: boolean
  onClickStatusUpdateButton: (status: Status) => Promise<void>
  store?
  watchStatus: Status
}

const optionArray = (): Status[] => ['open', 'close']

const StoresFormPublishSettingCard: FC<Props> = ({
  editMode,
  onClickStatusUpdateButton,
  store,
  watchStatus,
}) => {
  const { register } = useFormContext()

  return (
    <Card
      title='公開設定'
      content={
        <>
          <Row>
            <Col sm={12}>
              <SelectorForm
                name='store.status'
                inputRef={register}
                width={180}
                style={{ marginBottom: 0 }}
                options={optionArray().map((status) => ({
                  label: status === 'open' ? '公開' : '非公開',
                  value: status,
                }))}
              />
            </Col>
          </Row>

          {editMode && (
            <StoresFormPublishSettingCardButtonGroup
              watchStatus={watchStatus}
              onClickStatusUpdateButton={onClickStatusUpdateButton}
              store={store}
            />
          )}
        </>
      }
    />
  )
}

export default StoresFormPublishSettingCard

type StoresFormPublishSettingCardProps = Pick<
  Props,
  'watchStatus' | 'onClickStatusUpdateButton' | 'store'
>

const StoresFormPublishSettingCardButtonGroup: FC<
  StoresFormPublishSettingCardProps
> = ({ watchStatus, onClickStatusUpdateButton, store }) => (
  <Row
    style={{
      marginRight: 0,
      display: 'flex',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
    }}>
    <Col
      style={{
        marginLeft: 10,
        marginTop: 5,
        marginBottom: 5,
      }}>
      <LoadingButton
        label='変更する'
        loadingLabel='変更中...'
        color='info'
        fill
        simple
        pullRight
        loadingColor='red'
        disabled={store?.status === watchStatus}
        onClick={async (): Promise<void> => {
          await onClickStatusUpdateButton(watchStatus)
        }}
      />
    </Col>
  </Row>
)
