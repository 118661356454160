import React, { useState, useReducer } from 'react'
import { styled } from '@mui/system'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import CircularProgress from '@mui/material/CircularProgress'
import PersonSearch from '@mui/icons-material/PersonSearch'
import { useLoginContext } from '../../providers/LoginContextProvider'
import { useNotification } from '../../providers/NotificationProvider'
import { UserResponse } from '../../types/user'
import { ToshibaTecCheckInResponse } from '../../types/toshibaTec'
import {
  useApi,
  useGetApiCall,
  useBooleanState,
  useQuery,
  usePcSizeFlag,
} from '../../lib/hooks'
import MemberCodeModal from './parts/MemberCodeModal'
import CheckInModal from './parts/CheckInModal'
import PointModal from './parts/PointModal'
import CustomerInfoCard from './parts/CustomerInfoCard'
import CustomerSummary from './parts/CustomerSummary'
import ActivityLog from './parts/ActivityLog'
import MembersCard from './parts/MembersCard'
import CustomerServiceList from './parts/CustomerServiceList'
import StampCard from './parts/StampCard'
import Notice from './parts/Notice'
import Coupon from './parts/Coupon'
import Ticket from './parts/Ticket'
import Subscription from './parts/Subscription'
import Order from './parts/Order'
import ToshibaTecPosUserComplementConfirmDialog from './parts/ToshibaTecPosUserComplementConfirmDialog'

const contentType = {
  Summary: 'summary',
  MembersCard: 'members_card',
  StampCard: 'stamp_card',
  Notice: 'notice',
  Coupon: 'coupon',
  Ticket: 'ticket',
  Subscription: 'subscription',
  Order: 'order',
} as const
type ContentType = (typeof contentType)[keyof typeof contentType]

const CustomerDetailView = (): JSX.Element => {
  const history = useHistory()
  const { currentStore } = useLoginContext()
  const { showSuccessNotification, showErrorNotification } = useNotification()
  const activeFunction = currentStore.active_function
  const isPcOrTablet = usePcSizeFlag()
  const query = useQuery()
  const userId = Number(query.get('user_id'))
  const [summaryActivityLogReRenderingKey, reRenderSummaryActivityLog] =
    useReducer((state) => state + 1, 1)
  const [selectedTab, setSelectedTab] = useState<ContentType>(
    contentType.Summary,
  )
  const [userComplement, setUserComplement] =
    useState<ToshibaTecCheckInResponse | null>(null)
  const [isMemberCodeModalOpen, openMemberCodeModal, closeMemberCodeModal] =
    useBooleanState(false)
  const [isPointModalOpen, openPointModal, closePointModal] =
    useBooleanState(false)
  const [isCheckInModal, openCheckInModal, closeCheckInModal] =
    useBooleanState(false)
  const [isConfirmModalOpen, openConfirmModal, closeConfirmModal] =
    useBooleanState(false)
  const { api: membersCardUpdateApi, loading: membersCardUpdateApiLoading } =
    useApi()
  const { api: membersCardRankUpdateApi } = useApi()
  const { response: userInfoResponse, reload: reloadUserInfo } =
    useGetApiCall<UserResponse>(`/web/users/${userId}`)
  const userInfo = userInfoResponse || null

  const addMembersCardPoint = async (point: number) => {
    if (point <= 0 || 100000 <= point) {
      showErrorNotification('1回で付与できるポイントは1〜99,999までです。')
      return
    }

    const path = `/members_cards/${userInfo?.members_card?.id}`
    const res = await membersCardUpdateApi.put(path, {
      members_card: { point },
    })
    if (!res) return

    reloadUserInfo()
    reRenderSummaryActivityLog()
    showSuccessNotification('ポイントを付与しました。')
    closePointModal()
  }

  const removeMembersCardPoint = async (point: number) => {
    const path = `/members_cards/${userInfo?.members_card?.id}`
    const res = await membersCardUpdateApi.put(path, {
      members_card: { point: -point },
    })
    if (!res) return

    reloadUserInfo()
    reRenderSummaryActivityLog()
    showSuccessNotification('ポイントを削除しました。')
    closePointModal()
  }

  const updateMemberCode = async (code: string) => {
    const path = `/members_cards/${userInfo?.members_card?.id}`
    const res = await membersCardUpdateApi.put(path, {
      members_card: { member_code: code },
    })
    if (!res) return

    reloadUserInfo()
    showSuccessNotification('会員コードを更新しました。')
    closeMemberCodeModal()
  }

  const updateMembersCardRank = async (rankId: number): Promise<void> => {
    if (!userInfo?.members_card) return

    const res = await membersCardRankUpdateApi.patch(
      `/members_cards/${userInfo.members_card.id}`,
      {
        members_card: { members_card_rank_id: rankId },
      },
    )

    if (!res) return

    reloadUserInfo()
    showSuccessNotification('会員ランクを変更しました。')
  }

  if (!userId) {
    return (
      <StyledContainer>
        <StyledUndefinedUserContainer>
          <PersonSearch style={{ fontSize: 40, color: '#757575' }} />
          ユーザーが見つかりませんでした
        </StyledUndefinedUserContainer>
      </StyledContainer>
    )
  }

  return (
    <StyledContainer style={{ flexDirection: isPcOrTablet ? 'row' : 'column' }}>
      <CustomerInfoCard userInfo={userInfo} onUpdated={reloadUserInfo} />

      <StyledCustomerDetailContainer>
        <StyledContentContainer>
          <Tabs
            value={selectedTab}
            variant='scrollable'
            scrollButtons='auto'
            sx={{ mb: 2 }}
            onChange={(_, newTab: ContentType) => setSelectedTab(newTab)}>
            <Tab label='概要' value={contentType.Summary} />
            {activeFunction.members_card && (
              <Tab
                disabled={!userInfo}
                label='会員カード'
                value={contentType.MembersCard}
              />
            )}
            {activeFunction.stamp_card && (
              <Tab label='スタンプカード' value={contentType.StampCard} />
            )}
            <Tab label='お知らせ' value={contentType.Notice} />
            {activeFunction.coupon && (
              <Tab label='クーポン' value={contentType.Coupon} />
            )}
            {activeFunction.ticket && (
              <Tab label='チケット' value={contentType.Ticket} />
            )}
            {activeFunction.subscription && (
              <Tab label='サブスク' value={contentType.Subscription} />
            )}
            {(activeFunction.ec || activeFunction.order) && (
              <Tab label='オーダー' value={contentType.Order} />
            )}
          </Tabs>

          {selectedTab === contentType.Summary &&
            (userInfo ? (
              <CustomerSummary
                totalSales={userInfo.total_sales}
                totalVisitCount={userInfo.total_visit_count}
                visitFrequency={userInfo.visit_frequency}
                lastVisitAt={userInfo.last_visit_at}
                onClickOpenCheckInModal={openCheckInModal}
              />
            ) : (
              <Box display='flex' justifyContent='center' py={2}>
                <CircularProgress />
              </Box>
            ))}

          {userInfo?.members_card &&
            selectedTab === contentType.MembersCard && (
              <>
                <MembersCard
                  membersCard={userInfo.members_card}
                  membersCardRank={userInfo.members_card_rank}
                  membersCardPointEnabled={userInfo.members_card_point_enabled}
                  membersCardCurrentRanking={
                    userInfo.members_card_current_ranking
                  }
                  onClickUpdateMemberCode={openMemberCodeModal}
                  onClickChangePoint={openPointModal}
                  onUpdateMembersCardRank={updateMembersCardRank}
                />

                <CustomerServiceList
                  key={userInfo?.members_card_rank?.id}
                  style={{ marginTop: 32 }}
                  userId={userId}
                />
              </>
            )}

          {selectedTab === contentType.StampCard && (
            <StampCard userId={userId} />
          )}

          {selectedTab === contentType.Notice && (
            <Notice
              userId={userId}
              onClickCreateNotice={() =>
                history.push(
                  `/admin/notices/new?type=normal&user_ids=${userId}`,
                )
              }
            />
          )}

          {selectedTab === contentType.Coupon && (
            <Coupon
              userId={userId}
              onClickCreateCoupon={() => {
                history.push(
                  `/admin/coupons/new?type=normal&user_ids=${userId}`,
                )
              }}
            />
          )}

          {selectedTab === contentType.Ticket && <Ticket userId={userId} />}

          {selectedTab === contentType.Subscription && (
            <Subscription userId={userId} />
          )}

          {selectedTab === contentType.Order && <Order userId={userId} />}
        </StyledContentContainer>

        <StyledContentContainer>
          <ActivityLog
            key={summaryActivityLogReRenderingKey}
            userId={userId}
            onDeleteActivityLog={reloadUserInfo}
          />
        </StyledContentContainer>
      </StyledCustomerDetailContainer>

      {userInfo?.members_card && (
        <PointModal
          currentPoint={userInfo.members_card.point ?? 0}
          open={isPointModalOpen}
          disabled={membersCardUpdateApiLoading}
          onClose={closePointModal}
          onAddPoint={addMembersCardPoint}
          onRemovePoint={removeMembersCardPoint}
        />
      )}

      {userInfo?.members_card && (
        <MemberCodeModal
          open={isMemberCodeModalOpen}
          disabled={membersCardUpdateApiLoading}
          defaultValue={userInfo.members_card.member_code}
          onClose={closeMemberCodeModal}
          onSubmit={updateMemberCode}
        />
      )}

      <CheckInModal
        show={isCheckInModal}
        onHide={closeCheckInModal}
        onCheckedInByManual={() => {
          reloadUserInfo()
          reRenderSummaryActivityLog()
          closeCheckInModal()
        }}
        onCheckedInByPos={(newComplement) => {
          if (
            newComplement.toshiba_tec_pos_transaction_user_complement.status ===
            'processed'
          ) {
            closeCheckInModal()
            reloadUserInfo()
            reRenderSummaryActivityLog()
          } else {
            closeCheckInModal()
            setUserComplement(newComplement)
            openConfirmModal()
          }
        }}
        userId={userId}
        isNeedAmount={Boolean(userInfo?.is_need_amount)}
        membersCardPointEnabled={Boolean(userInfo?.members_card_point_enabled)}
      />

      {userComplement && (
        <ToshibaTecPosUserComplementConfirmDialog
          open={isConfirmModalOpen}
          storeName={userComplement.store.name}
          businessDay={
            userComplement.toshiba_tec_pos_transaction_user_complement
              .target_date
          }
          receiptNo={
            userComplement.toshiba_tec_pos_transaction_user_complement
              .receipt_no
          }
          onClose={closeConfirmModal}
        />
      )}
    </StyledContainer>
  )
}

const StyledCustomerDetailContainer = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
})
const StyledContentContainer = styled('div')({
  padding: 16,
  backgroundColor: 'white',
  border: '1px solid #E7E7E8',
  borderRadius: 4,
})

const StyledContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'start',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
}))

const StyledUndefinedUserContainer = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  border: '1px solid #E7E7E8',
  borderRadius: 4,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: '96px 0',
}))

export default CustomerDetailView
