/* eslint-disable @typescript-eslint/camelcase */
import apiClient from './apiClient'
import { loadViewObj } from '../viewObj'

export const getRankingFeature = async () => {
  const GET_RANKING_FEATURE = `/staff/stores/${loadViewObj()?.id}/ranking_features`

  const response = await apiClient({
    method: 'GET',
    uri: GET_RANKING_FEATURE,
  })
  return response.data
}

export const updateRankingFeature = async ({ params }) => {
  const UPDATE_RANKING_FEATURE = `/staff/stores/${
    loadViewObj()?.id
  }/ranking_features`

  const requestParams = {
    ranking_feature: params,
  }

  const response = await apiClient({
    method: 'PATCH',
    uri: UPDATE_RANKING_FEATURE,
    params: requestParams,
  })
  return response.data
}
