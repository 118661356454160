import React, { useState, useRef, useEffect } from 'react'
import moment from 'moment'
import { styled } from '@mui/material/styles'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { Radio } from 'react-bootstrap'
import { useBooleanState, useApi } from '../../../../lib/hooks'
import { NoticeContentsResponse } from '../../../../types/api/notice.d'
import { NoticeContentResource } from '../../../../types/resource/noticeContent.d'
import ConditionFormItem from '../parts/ConditionFormItem'
import DateOptionFormItem from '../parts/DateOptionFormItem'
import InstanceItem from '../parts/InstanceItem'
import InstanceFilterDialog from '../parts/InstanceFilterDialog'
import { Condition, ConditionDiff } from '../../type'

type Props = {
  condition: Condition
  onChange: (diff: ConditionDiff) => void
}

const NoticeReadCondition = ({ condition, onChange }: Props): JSX.Element => {
  const filterInputRef = useRef<HTMLInputElement>(null)
  const [filterText, setFilterText] = useState<string>('')
  const [isModalOpen, openModal, closeModal] = useBooleanState(false)
  const { api, response, loading } = useApi<NoticeContentsResponse>()
  const noticeContents = (response?.notice_contents || []).filter(
    ({ notice_content }) =>
      !Boolean(filterText) || notice_content.title.indexOf(filterText) > -1,
  )

  useEffect(() => {
    if (isModalOpen) {
      setTimeout(() => filterInputRef.current?.focus(), 100)
    }
  }, [isModalOpen])

  const handleOpenModal = () => {
    openModal()

    if (!loading && !response) {
      api.get('/notice_contents', {
        status: 'publish',
        sort: 'publish_at',
        order: 'DESC',
        with_aggregation: false,
        with_details: false,
        limit: LIMIT,
      })
    }
  }

  const handleClickNotice = (noticeContent: NoticeContentResource) => {
    closeModal()
    onChange({ instance_id: noticeContent.id, noticeContent })
  }

  return (
    <>
      <ConditionFormItem label='お知らせ'>
        <Select
          readOnly
          fullWidth
          value='default'
          style={{ backgroundColor: '#fff' }}
          sx={{ '& > .MuiSelect-select': { width: 370 } }}
          onClick={handleOpenModal}>
          <MenuItem value='default'>
            {condition.noticeContent?.title || 'すべてのお知らせ'}
          </MenuItem>
        </Select>
      </ConditionFormItem>

      <ConditionFormItem label='閲覧' style={{ marginTop: 8 }}>
        <StyledRadioContainer>
          <Radio
            name={'notice-radioGroup-' + condition.key}
            checked={condition.value_from === '1'}
            onChange={() => onChange({ value_from: '1' })}>
            閲覧したことがある
          </Radio>

          <Radio
            name={'notice-radioGroup-' + condition.key}
            checked={condition.value_from === '0'}
            onChange={() =>
              onChange({
                value_from: '0',
                scope_from: null,
                scope_to: null,
                relative_beginning_scope: null,
                relative_end_scope: null,
              })
            }>
            閲覧したことがない
          </Radio>
        </StyledRadioContainer>
      </ConditionFormItem>

      {condition.value_from !== '0' && (
        <DateOptionFormItem
          style={{ marginTop: 8 }}
          condition={condition}
          label='閲覧日'
          noneLabel='すべての期間'
          onChange={(
            scope_from,
            scope_to,
            relative_beginning_scope,
            relative_end_scope,
            time_from,
            time_to,
          ) => {
            onChange({
              scope_from,
              scope_to,
              relative_beginning_scope,
              relative_end_scope,
              time_from,
              time_to,
            })
          }}
        />
      )}

      <InstanceFilterDialog
        show={isModalOpen}
        loading={loading}
        items={noticeContents}
        placeholder='お知らせ名を入力して検索...'
        emptyLabel={
          Boolean(filterText)
            ? `${filterText}を含むお知らせが見つかりません。`
            : '配信済みのお知らせがありません。'
        }
        renderItem={({ notice_content }) => (
          <InstanceItem
            key={notice_content.id}
            title={notice_content.title}
            imageUrl={notice_content.image_urls[0]}
            note={`配信日: ${moment(notice_content.publish_at).format('YYYY/MM/DD HH:mm')}`}
            onClick={() => handleClickNotice(notice_content)}
          />
        )}
        onHide={closeModal}
        onFilter={setFilterText}
      />
    </>
  )
}

const LIMIT = 100

const StyledRadioContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  paddingBottom: 8,
  '& .radio': {
    margin: '0 8px 0 0',
    padding: 0,
  },
  '& .radio label': {
    paddingLeft: 24,
  },
  '& .radio label input': {
    marginTop: 6,
  },
})

export default NoticeReadCondition
