import React from 'react'
import { Modal } from 'react-bootstrap'
import QRCode from 'qrcode.react'
import Button from '../../../components/CustomButton/CustomButton'

const QrcodeModal = ({ show, onHide, title, link }) => (
  <Modal
    show={show}
    onHide={onHide}
    bsSize='small'
    aria-labelledby='contained-modal-title-medium'>
    <Modal.Header closeButton>
      <Modal.Title id='contained-modal-title-' componentClass='h5'>
        {title}
      </Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        {link && <QRCode value={link} size={150} />}
      </div>
      <p style={{ fontSize: '13px', marginTop: '10px' }}>
        ご利用のスマートフォンでQRコードを読み取ってトイポアプリとつながりましょう
      </p>
      <Button style={{ width: '100%', marginTop: '15px' }} onClick={onHide}>
        <b>閉じる</b>
      </Button>
    </Modal.Body>
  </Modal>
)

export default QrcodeModal
