import apiClient from './apiClient'
import { loadViewObj } from '../viewObj'

export const createPrivilegeCoupon = async ({ params }) => {
  const CREATE_PRIVILEGE_COUPON_CONTENT = `/staff/stores/${
    loadViewObj()?.id
  }/privilege_coupon_contents`

  const requestParams = {
    privilege_coupon_content: params,
  }

  const response = await apiClient({
    method: 'POST',
    uri: CREATE_PRIVILEGE_COUPON_CONTENT,
    params: requestParams,
  })
  return response
}

export const updatePrivilegeCoupon = async ({ id, params }) => {
  const UPDATE_PRIVILEGE_COUPON_CONTENT = `/staff/stores/${
    loadViewObj()?.id
  }/privilege_coupon_contents/${id}`

  const requestParams = {
    privilege_coupon_content: params,
  }

  const response = await apiClient({
    method: 'PATCH',
    uri: UPDATE_PRIVILEGE_COUPON_CONTENT,
    params: requestParams,
  })
  return response
}
