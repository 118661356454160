export const ServiceStatuses = {
  Inactive: 'inactive',
  Active: 'active',
  Used: 'used',
  Expired: 'expired',
  DailyUsed: 'daily_used',
  MonthlyUsed: 'monthly_used',
} as const

export const ServiceStatusColors = {
  [ServiceStatuses.Inactive]: '#ff9933',
  [ServiceStatuses.Active]: '#888',
  [ServiceStatuses.Used]: '#00ddb4',
  [ServiceStatuses.DailyUsed]: '#00ddb4',
  [ServiceStatuses.MonthlyUsed]: '#00ddb4',
} as const

export const ServiceStatusLabels = {
  [ServiceStatuses.Inactive]: '利用不可',
  [ServiceStatuses.Active]: '利用可能',
  [ServiceStatuses.Used]: '利用済み',
  [ServiceStatuses.Expired]: '期限切れ',
  [ServiceStatuses.DailyUsed]: '本日利用済み',
  [ServiceStatuses.MonthlyUsed]: '本月利用済み',
} as const

export const ServiceStatusLabel = {
  open: '公開',
  close: '非公開',
  draft: '下書き',
} as const
