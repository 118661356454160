import React, { useEffect, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { styled } from '@mui/system'
import * as amplitude from '@amplitude/analytics-browser'
import { AxiosResponse } from 'axios'
import CircularProgress from '@mui/material/CircularProgress'
import { removeAuth } from '../lib/auth'
import {
  setViewObj,
  loadViewObj,
  isViewObjGroup,
  isViewObjStore,
} from '../lib/viewObj'
import { removeStaff, setStaff } from '../lib/staff'
import { setOwner, removeOwner } from '../lib/owner'
import { useApi } from '../lib/hooks'
import {
  StoreResponse,
  StoreFeature,
  ActiveFunctionResource,
  LineChannelResponse,
  LineChannelResource,
} from '../types/store'
import { StaffResponse, Permission } from '../types/staff'
import { OwnerResponse, CardApplicationStatusType } from '../types/owner'
import StoreEntityContainer from '../containers/entities/StoreEntityContainer'
import OwnerEntityContainer from '../containers/entities/OwnerEntityContainer'
import { ChildStoresResponse } from '../types/api/childStore.d'
import { createContext } from './utils'

const LoginContextProvider = ({ children }) => {
  const history = useHistory()
  const { api: logoutApi } = useApi('/staff')
  const storeApi = useApi<StoreResponse>('/staff')
  const ownerApi = useApi<OwnerResponse>('/staff')
  const childStoresApi = useApi<ChildStoresResponse>()
  const staffApi = useApi<StaffResponse>('/staff')
  const lineChannelApi = useApi<LineChannelResponse>('/staff')
  const currentStore = storeApi.response
  const currentStaff = staffApi.response
  const currentOwner = ownerApi.response
  const storeEntityContainer = StoreEntityContainer.useContainer()
  const ownerEntityContainer = OwnerEntityContainer.useContainer()
  const { setEntityState } = storeEntityContainer
  const { getStore } = storeEntityContainer.api
  const { setAll } = ownerEntityContainer.logic

  const isAdminRole = useMemo(() => {
    const stores = [
      ...(currentStaff?.stores || []),
      ...(currentStaff?.groups || []),
    ]
    const store = stores.find(({ id }) => id === currentStore?.id)
    return store?.role === 'admin'
  }, [currentStaff, currentStore])

  const denyPermissions = useMemo(
    () =>
      Array.from(
        new Set(
          currentStaff?.staff_roles?.flatMap((role) => role.deny_permissions),
        ),
      ),
    [currentStaff],
  )

  const cardApplicationStatus: CardApplicationStatusType = useMemo(() => {
    if (currentOwner?.reviewed_brands?.length) {
      const passedStatus = currentOwner.reviewed_brands.some(
        (brand) => brand.status === 'passed',
      )
      return passedStatus ? 'passed' : 'inReview'
    }

    return 'notApply'
  }, [currentOwner])

  const reloadCurrentStore = useCallback(() => {
    const currentStoreId = loadViewObj()?.id
    if (currentStoreId) {
      storeApi.api.get(`/stores/${currentStoreId}`)
      lineChannelApi.api.get(
        `/stores/${currentStoreId}/line_channel`,
        {},
        { showErrorNotification: false },
      )
    }
  }, [storeApi.api, lineChannelApi.api])

  const reloadCurrentOwner = useCallback(async () => {
    if (!currentStaff || !currentStore) {
      return
    }

    const url = currentStaff.is_owner
      ? '/owner'
      : `/stores/${currentStore.id}/owner`
    const response = await ownerApi.api.get(url)
    const owner = response.data
    setAll(owner)
    setOwner({ status: owner.status })

    if (isRejected(owner)) {
      history.push('/admin/suspend')
    }
  }, [ownerApi.api, currentStaff, currentStore, setAll, history])

  const reloadCurrentStaff = useCallback(
    async () =>
      staffApi.api.get('/staffs/me').then((response) => {
        const staff = response.data

        amplitude.setUserId(staff.email)
        setStaff({ isOwner: staff.is_owner, stores: staff.stores })

        // - はじめてログインする場合：
        //   スタッフが所属する店舗の最初の店舗でログイン状態にします。
        // - 1度ログアウトして再度ログインしようとする場合：
        //   前回ログアウト時と同じ店舗でログインした状態にするため
        //   viewObjの中身に保存されている店舗IDをスタッフが所属している店舗のリストから探します。
        //   見つからなかった場合はスタッフが所属する店舗の最初の店舗でログイン状態にします。
        const viewObj = loadViewObj()
        const defaultObj =
          viewObj === null
            ? staff.stores[0] || staff.groups[0]
            : isViewObjStore(viewObj)
              ? staff.stores.find(({ id }) => id === viewObj.id)
              : isViewObjGroup(viewObj)
                ? staff.groups.find(({ id }) => id === viewObj.id)
                : null
        const newObj = defaultObj || staff.stores[0] || staff.groups[0]
        if (newObj) {
          setViewObj({
            type: newObj.store_type === 'child' ? 'store' : 'group',
            id: newObj.id,
            name: newObj.name,
            status: newObj.status,
            role: newObj.role,
            isSetup: newObj.is_setup,
            isOwnerGroup: newObj.store_type === 'owner_group',
          })
        }
        return response
      }),
    [staffApi.api],
  )

  const logout = useCallback(
    async () =>
      logoutApi.delete('/auth/sign_out').then(() => {
        removeAuth()
        removeStaff()
        removeOwner()
        window.location.reload(true)
      }),
    [logoutApi],
  )

  const hasPermission = useCallback(
    (permission: Permission | Permission[]) => {
      if (Array.isArray(permission)) {
        return permission.every((p) => !denyPermissions.includes(p))
      } else {
        return !denyPermissions.includes(permission)
      }
    },
    [denyPermissions],
  )

  // TODO: storeEntityContainer.stateを使う場所を消して
  // 代わりにcurrentStoreを使うようにできたらここの処理は消せます
  useEffect(() => {
    getStore().then(setEntityState.store)
  }, [getStore, setEntityState])

  useEffect(() => {
    reloadCurrentOwner()
  }, [reloadCurrentOwner])

  useEffect(() => {
    reloadCurrentStaff().then(() => {
      reloadCurrentStore()
      childStoresApi.api.get('/child_stores')
    })
  }, [reloadCurrentStaff, reloadCurrentStore, childStoresApi.api])

  const activeFunctions = useMemo(() => {
    return generateActiveFunctionArray(storeApi.response?.active_function || {})
  }, [storeApi.response])

  const hasFunction = useCallback(
    (func) => {
      return activeFunctions.includes(func)
    },
    [activeFunctions],
  )

  if (
    !currentStore ||
    !childStoresApi.response ||
    !currentStaff ||
    !currentOwner ||
    !lineChannelApi.loaded
  ) {
    return (
      <StyledProgressContainer>
        <CircularProgress />
      </StyledProgressContainer>
    )
  }

  const activeFunctionsByCurrentStoreAndChildren =
    childStoresApi.response.child_stores.reduce(
      (acc, { active_function }) => ({
        banner: acc.banner || active_function.banner,
        calendar: acc.calendar || active_function.calendar,
        coupon: acc.coupon || active_function.coupon,
        delivery: acc.delivery || active_function.delivery,
        ec: acc.ec || active_function.ec,
        members_card: acc.members_card || active_function.members_card,
        menu: acc.menu || active_function.menu,
        notice: acc.notice || active_function.notice,
        order: acc.order || active_function.order,
        preorder: acc.preorder || active_function.preorder,
        sales: acc.sales || active_function.sales,
        stamp_card: acc.stamp_card || active_function.stamp_card,
        subscription: acc.subscription || active_function.subscription,
        takeout: acc.takeout || active_function.takeout,
        ticket: acc.ticket || active_function.ticket,
        questionnaire: acc.questionnaire || active_function.questionnaire,
        ranking: acc.ranking || active_function.ranking,
      }),
      currentStore.active_function,
    )

  const value = {
    currentStore,
    currentStaff,
    currentOwner,
    lineChannel: lineChannelApi.response?.line_channel ?? null,
    isAdminRole,
    childStores: childStoresApi.response,
    reloadCurrentStore,
    reloadCurrentStaff,
    hasFunction,
    hasPermission,
    activeFunctions,
    activeFunctionsByCurrentStoreAndChildren: generateActiveFunctionArray(
      activeFunctionsByCurrentStoreAndChildren,
    ),
    isOwnerRejected: isRejected(currentOwner),
    cardApplicationStatus: cardApplicationStatus,
    logout,
    fromWebView: navigator.userAgent === 'webView',
  }

  return <LoginContext.Provider value={value}>{children}</LoginContext.Provider>
}

const isRejected = (owner: OwnerResponse) =>
  ['rejected', 'rejected_by_terms'].includes(owner.status)

const generateActiveFunctionArray = (
  activeFunctionResource: Partial<ActiveFunctionResource>,
) =>
  Object.entries(activeFunctionResource)
    .filter(([, active]) => active)
    .map(([feature]) => feature as StoreFeature)

type LoginContextType = {
  currentStore: StoreResponse
  currentStaff: StaffResponse
  currentOwner: OwnerResponse
  lineChannel: LineChannelResource | null
  childStores: ChildStoresResponse
  reloadCurrentStore: () => void
  reloadCurrentStaff: () => Promise<AxiosResponse<StaffResponse>>
  hasFunction: (func: string) => boolean
  activeFunctions: StoreFeature[]
  activeFunctionsByCurrentStoreAndChildren: StoreFeature[]
  isOwnerRejected: boolean
  isAdminRole: boolean
  cardApplicationStatus: CardApplicationStatusType
  logout: () => void
  fromWebView: boolean
  hasPermission: (permission: Permission | Permission[]) => boolean
}

export const [useLoginContext, LoginContext] = createContext<LoginContextType>()

const StyledProgressContainer = styled('div')({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

export default LoginContextProvider
