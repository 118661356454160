import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import FormInputs from '../FormInputs/FormInputs'
import CustomButton from '../CustomButton/CustomButton'

interface Props {
  id: string
  qrName: string
  show: boolean
  onHide: () => void
  updateQrcodeName: ({ id, name }: { id: string; name: string }) => Promise<any>
  displayNotification: ({
    level,
    message,
  }: {
    level: 'success' | 'error' | 'warning'
    message: string
  }) => void
  getStore: () => Promise<void>
}

const QrNameChangeModal: React.FC<Props> = ({
  id,
  qrName,
  show,
  onHide,
  updateQrcodeName,
  displayNotification,
  getStore,
}) => {
  const [name, setName] = useState(qrName)

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>名称を変更</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormInputs
          properties={[
            {
              name: 'formName',
              label: '名称',
              ncol: 'col-md-7',
              type: 'text',
              bsClass: 'form-control',
              value: name,
              onChange: ({ target }): void => setName(target.value),
            },
          ]}
        />
      </Modal.Body>
      <Modal.Footer>
        <CustomButton onClick={onHide}>キャンセル</CustomButton>
        <CustomButton
          bsStyle='info'
          fill
          onClick={(): Promise<void> =>
            updateQrcodeName({ id, name })
              .then(() => {
                displayNotification({
                  level: 'success',
                  message: '更新しました',
                })
                getStore()
                onHide()
              })
              .catch(() =>
                displayNotification({
                  level: 'error',
                  message: '更新に失敗しました',
                }),
              )
          }>
          <b>保存する</b>
        </CustomButton>
      </Modal.Footer>
    </Modal>
  )
}

export default QrNameChangeModal
