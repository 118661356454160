import React from 'react'
import { Table } from 'react-bootstrap'
import moment from 'moment'
import {
  ServiceStatusColors,
  ServiceStatusLabels,
} from '../../../constants/service'
import { ServiceStatusType } from '../../../types/service'

type ServiceType = {
  id: number
  user_id: number
  used_count: number
  daily_used_count: number
  expiration_at: string | null
  monthly_used_count: number
  service_content_id: number
  status: ServiceStatusType
}
type ServiceContentType = {
  id: number
  store_id: number
  members_card_rank_id: number
  title: string
  body: string
  status: 'draft' | 'open' | 'close'
  image_url: string | null
  service_type: 'discount' | 'free' | 'other' | null
  service_value: number | null
  expiration_month: number | null
  usable_count: number | null
  daily_usable_count: number | null
  monthly_usable_count: number | null
  created_at: string
}

type ServiceResponseType = {
  service: ServiceType
  service_content: ServiceContentType
}

type Props = {
  services: Array<ServiceResponseType>
}

const ContentListLarge = ({ services }: Props): JSX.Element => (
  <Table striped hover>
    <thead>
      <tr>
        {['タイトル', '利用状況', '配信日時'].map((label) => (
          <th key={label}>{label}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {services.map(({ service, service_content }) => (
        <tr key={service.id}>
          <td>
            <div
              style={{
                fontWeight: 700,
                display: 'flex',
                alignItems: 'center',
              }}>
              {service_content.image_url && (
                <img
                  key={service_content.id}
                  alt='サービス画像'
                  src={service_content.image_url}
                  width={48}
                  height={30}
                  style={{
                    borderRadius: 2,
                    objectFit: 'cover',
                    marginRight: 8,
                  }}
                />
              )}

              <div>{service_content.title}</div>
            </div>
          </td>
          <td style={{ fontWeight: 700 }}>
            <div style={{ color: ServiceStatusColors[service.status] }}>
              {ServiceStatusLabels[service.status]}
            </div>
          </td>
          <td>
            {moment(service_content.created_at).format('YYYY/M/D(dddd) HH:mm')}
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
)

export default ContentListLarge
