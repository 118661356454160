import React from 'react'

interface Props<T> {
  data: T[]
  columns?: string[]
  renderCell: (d: T) => JSX.Element
}

const DataTable: <T>(props: Props<T>) => JSX.Element = ({
  data,
  columns,
  renderCell,
}) => (
  <>
    {!!columns?.length && (
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={`header-${column}`}>{column}</th>
          ))}
        </tr>
      </thead>
    )}
    <tbody>{data.map((d) => renderCell(d))}</tbody>
  </>
)

export default DataTable
