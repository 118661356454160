import React, { useState } from 'react'
import Cropper from 'react-easy-crop'
import { Modal } from 'react-bootstrap'
import Button from '../CustomButton/CustomButton'
import './ImageTrimmingModal.css'

// props のデフォルト値を設定
const ImageTrimmingModal = ({
  image,
  doneTrimmingImage,
  open,
  aspect = 16 / 9,
  closeModal,
  id,
  startLoading,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const onCropComplete = (_, newCroppedAreaPixels) =>
    setCroppedAreaPixels(newCroppedAreaPixels)

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const imageElement = new Image()
      imageElement.addEventListener('load', () => resolve(imageElement))
      imageElement.addEventListener('error', (error) => reject(error))
      imageElement.src = url
    })

  async function getCroppedImg(imageSrc, pixelCrop) {
    const imageElement = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    const maxSize = Math.max(imageElement.width, imageElement.height)
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea
    canvas.height = safeArea

    // draw rotated image and store data.
    ctx.drawImage(
      imageElement,
      safeArea / 2 - imageElement.width * 0.5,
      safeArea / 2 - imageElement.height * 0.5,
    )
    const data = ctx.getImageData(0, 0, safeArea, safeArea)

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + imageElement.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + imageElement.height * 0.5 - pixelCrop.y),
    )

    // As Base64 string
    return canvas.toDataURL('image/jpeg')
  }

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels)
      doneTrimmingImage(croppedImage)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Modal show={open}>
      <div className='cropper-modal-container'>
        <div className='cropper-content'>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </div>

        <div className='buttons'>
          <Button block onClick={closeModal}>
            キャンセル
          </Button>

          <Button
            block
            bsStyle='info'
            fill
            onClick={async () => {
              if (id !== undefined) {
                startLoading()
              }
              await showCroppedImage()
            }}>
            切り取る
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default ImageTrimmingModal
