import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Grid, Row, Col, Form } from 'react-bootstrap'
import { Card } from '../../components/Card/Card'
import FormInputs from '../../components/FormInputs/FormInputs'
import ImageForm from '../../components/FormInputs/ImageForm'
import SwitchForm from '../../components/FormInputs/SwitchForm'
import LoadingButton from '../../components/CustomButton/LoadingButton'
import { useLoginContext } from '../../providers/LoginContextProvider'
import { useNotification } from '../../providers/NotificationProvider'
import { StoreStatus } from '../../types/store'
import { useApi } from '../../lib/hooks'

type GroupFormType = {
  name: string
  status: StoreStatus
  app_icon: string | null
  is_open_child_stores: boolean
}

const GroupFormView = (): JSX.Element => {
  const { showSuccessNotification, showErrorNotification } = useNotification()
  const { api, loading } = useApi('/staff')
  const { reloadCurrentStore, reloadCurrentStaff, currentStore, currentStaff } =
    useLoginContext()

  const defaultValues = {
    name: currentStore.name,
    status: currentStore.status,
    app_icon: currentStore.app_icon_url,
    is_open_child_stores: currentStore.is_open_child_stores,
  }
  const { register, handleSubmit, errors, formState, control } =
    useForm<GroupFormType>({ defaultValues })
  const { isDirty } = formState

  const submit = async (data: GroupFormType) => {
    const params = { ...data }
    if (params.app_icon === currentStore.app_icon_url) {
      delete params.app_icon
    }

    const path = currentStaff.is_owner
      ? `/owner/stores/${currentStore.id}`
      : `/stores/${currentStore.id}`
    const response = await api.patch(path, params)
    if (!response) return

    reloadCurrentStaff()
    reloadCurrentStore()
    showSuccessNotification('編集を保存しました。')
  }

  const error = () => showErrorNotification('値が正しく入力されていません。')

  return (
    <Grid fluid className='content'>
      <Form onSubmit={handleSubmit(submit, error)}>
        <Row>
          <Col md={12}>
            <Card
              title='グループ情報'
              content={
                <Row>
                  <Col md={12}>
                    <FormInputs
                      properties={[
                        {
                          name: 'name',
                          label: 'グループ名',
                          ncol: 'col-md-7',
                          type: 'text',
                          bsClass: 'form-control',
                          inputRef: register({
                            required: 'グループ名を入力してください。',
                          }),
                          validationMessage: errors?.name?.message,
                        },
                      ]}
                    />

                    <Controller
                      control={control}
                      name='app_icon'
                      render={({ value, onChange }) => (
                        <ImageForm
                          label='ロゴ画像'
                          aspect={1}
                          height={180}
                          width={180}
                          value={value}
                          canDelete
                          onChange={(img) => onChange(img)}
                          deleteImage={() => onChange(null)}
                          validationMessage={errors?.app_icon?.message}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name='is_open_child_stores'
                      render={({ value, onChange }) => (
                        <SwitchForm
                          label='店舗情報ページにその他の店舗を表示する'
                          checked={value}
                          onChange={(isChecked) => onChange(isChecked)}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name='status'
                      render={({ value, onChange }) => (
                        <SwitchForm
                          label='公開する'
                          checked={value === 'open'}
                          onChange={(isChecked) =>
                            onChange(isChecked ? 'open' : 'close')
                          }
                        />
                      )}
                    />

                    <LoadingButton
                      type='submit'
                      label='保存する'
                      loadingLabel='保存中...'
                      color='info'
                      fill
                      pullRight
                      disabled={!isDirty}
                      loading={loading}
                    />
                  </Col>
                </Row>
              }
            />
          </Col>
        </Row>
      </Form>
    </Grid>
  )
}

export default GroupFormView
