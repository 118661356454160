import React, { useEffect, useState, useCallback } from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Pagination from '@mui/material/Pagination'
import { useApi, useBooleanState } from '../../../lib/hooks'
import { useLoginContext } from '../../../providers/LoginContextProvider'
import { useNotification } from '../../../providers/NotificationProvider'
import {
  MembersCardContentPointOverrideSettingsResponse,
  MembersCardContentPointOverrideSettingResponse,
  TimeRangeConditionPolicyResource,
} from '../../../types/membersCardContentPointOverrideSetting'
import { MembersCardContentInterface } from '../../../containers/entities/MembersCardContentEntityContainer'
import PointOverrideSettingDialog from './PointOverrideSettingDialog'

type Props = {
  membersCardContent: MembersCardContentInterface
}

const DayOfWeekLabel = {
  sunday: '日曜日',
  monday: '月曜日',
  tuesday: '火曜日',
  wednesday: '水曜日',
  thursday: '木曜日',
  friday: '金曜日',
  saturday: '土曜日',
} as const

const calculateRepeatLabel = (policy: TimeRangeConditionPolicyResource) => {
  const { year, month, date, day_of_week } = policy

  return year && month && date
    ? `${year}年${month}月${date}日`
    : month && date
      ? `毎年${month}月${date}日`
      : date
        ? `毎月${date}日`
        : day_of_week === 'holiday'
          ? '祝日'
          : day_of_week
            ? `毎週${DayOfWeekLabel[day_of_week]}`
            : '毎日'
}

const calculateTimeRangeLabel = (policy: TimeRangeConditionPolicyResource) =>
  policy.from_time === '00:00' && policy.to_time === '00:00'
    ? '終日'
    : `${policy.from_time} ~ ${policy.to_time}`

const PointOverrideSettingList = ({ membersCardContent }: Props) => {
  const { currentStore } = useLoginContext()
  const { showSuccessNotification } = useNotification()
  const {
    api: overridesGetApi,
    response: overridesResponse,
    totalPages,
  } = useApi<MembersCardContentPointOverrideSettingsResponse>()
  const { api: overrideDeleteApi } = useApi()
  const [isModalOpen, openModal, closeModal] = useBooleanState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [selectedOverride, setSelectedOverride] =
    useState<MembersCardContentPointOverrideSettingResponse | null>(null)

  const reload = useCallback(
    (page = 1) => {
      setCurrentPage(page)
      overridesGetApi.get('/members_card_content_point_override_settings', {
        limit: 5,
        page,
      })
    },
    [overridesGetApi],
  )

  useEffect(() => {
    reload()
  }, [reload])

  const deleteOverride = async (overrideId: number) => {
    if (!window.confirm('特別ポイント設定を削除しますか？')) return

    const res = await overrideDeleteApi.delete(
      `/members_card_content_point_override_settings/${overrideId}`,
    )
    if (!res) return

    reload()
    showSuccessNotification('特別ポイント設定を削除しました。')
  }

  return (
    <Paper variant='outlined' style={{ padding: 16 }}>
      <Typography variant='title' mb={2}>
        特別ポイント設定
      </Typography>
      <div>
        <Button
          color='submit'
          variant='text'
          onClick={() => {
            setSelectedOverride(null)
            openModal()
          }}>
          ＋ 特別ポイント設定を追加
        </Button>
        <Box display='flex' flexDirection='column' gap={1}>
          {(overridesResponse?.point_override_settings || []).map(
            (override) => {
              const {
                members_card_content_point_override_setting: override_setting,
                time_range_condition_policy: policy,
              } = override

              return (
                <StyledSection key={policy.id} variant='outlined'>
                  <Box
                    color={policy.status === 'inactive' ? '#bbb' : 'initial'}>
                    {policy.title && (
                      <Box fontWeight={600} mb={1}>
                        {policy.title}
                      </Box>
                    )}
                    <Box display='flex' alignItems='center' gap={1}>
                      {policy.status === 'inactive' && (
                        <StyledInactiveLabel>無効</StyledInactiveLabel>
                      )}
                      <Box>
                        {[
                          calculateRepeatLabel(policy),
                          calculateTimeRangeLabel(policy),
                          membersCardContent.members_card_ranks.find(
                            (rank) =>
                              rank.id === override_setting.members_card_rank_id,
                          )?.name || 'すべてのランク',
                          [currentStore, ...currentStore.child_stores].find(
                            (store) => store.id === policy.store_id,
                          )?.name,
                          override_setting.check_in_point !== null &&
                            `チェックイン ${override_setting.check_in_point}ポイント`,
                          override_setting.point_rate !== null &&
                            `ポイント還元率 ${override_setting.point_rate * 100}%`,
                          override_setting.payment_point_amount !== null &&
                            `${override_setting.payment_point_amount}円で1ポイント`,
                        ]
                          .filter((v) => v)
                          .join(' / ')}
                      </Box>
                    </Box>
                  </Box>

                  <Box display='flex' gap={1}>
                    <i
                      className='ri-edit-line'
                      style={{ fontSize: 18, cursor: 'pointer' }}
                      onClick={() => {
                        setSelectedOverride(override)
                        openModal()
                      }}
                    />
                    <i
                      className='ri-delete-bin-line'
                      style={{ fontSize: 18, cursor: 'pointer' }}
                      onClick={() => deleteOverride(override_setting.id)}
                    />
                  </Box>
                </StyledSection>
              )
            },
          )}
        </Box>

        {totalPages > 1 && (
          <Pagination
            sx={{ mt: 1 }}
            count={totalPages}
            page={currentPage}
            onChange={(_, page) => reload(page)}
          />
        )}
      </div>

      <PointOverrideSettingDialog
        selectedOverride={selectedOverride}
        membersCardContent={membersCardContent}
        open={isModalOpen}
        onClose={closeModal}
        onSubmit={reload}
      />
    </Paper>
  )
}

export default PointOverrideSettingList

const StyledSection = styled(Card)(({ theme }) => ({
  backgroundColor: '#F4F4F5',
  padding: theme.spacing(1, 2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
}))

const StyledInactiveLabel = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.cancel.main,
  color: 'white',
  padding: theme.spacing(0.5, 1),
  borderRadius: 6,
  fontWeight: 700,
}))
