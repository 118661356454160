import React from 'react'
import styled from '@emotion/styled'
import {
  LogStatusLabel,
  IllegalStatusLabel,
  ActionTypeLabels,
  ContentTypeMappings,
} from '../../../constants/activityLog'
import { Typography } from '@mui/material'
import {
  ActivityLogContentResponse,
  ActivityLogFilteringCondition,
} from '../../../types/activityLog'
import { StoreResponse } from '../../../types/store'

type Props = {
  store: StoreResponse
  contents: ActivityLogContentResponse
  condition: ActivityLogFilteringCondition
}

const ActivityLogFilteringConditionElements = ({
  store,
  contents,
  condition,
}: Props): JSX.Element => {
  const contentTitle = calculateContentTitle(condition, contents)
  const hasFilteringConditions =
    condition.from ||
    condition.to ||
    condition.store_ids.length ||
    condition.action_types.length ||
    contentTitle ||
    condition.statuses.length ||
    condition.illegal_statuses.length

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        gap: 8,
      }}>
      <span
        style={{
          fontSize: 14,
          fontWeight: 'bold',
        }}>
        絞り込み条件:
      </span>

      {!hasFilteringConditions && (
        <Typography fontWeight='bold' fontSize={14} lineHeight={0}>
          なし
        </Typography>
      )}

      {condition.from && condition.to && (
        <StyledLabel>{`${condition.from} - ${condition.to}`}</StyledLabel>
      )}

      {!!condition.store_ids.length &&
        Object.keys(store).length &&
        condition.store_ids.map((id) => (
          <StyledLabel key={id}>
            {
              (Object.keys(store).length
                ? [store, ...store?.child_stores]
                : []
              ).find((s) => s.id === id)?.name
            }
          </StyledLabel>
        ))}

      {condition.action_types.map((action_type) => (
        <StyledLabel key={action_type}>
          {ActionTypeLabels[action_type]}
        </StyledLabel>
      ))}

      {contentTitle && <StyledLabel>{contentTitle}</StyledLabel>}

      {condition.statuses.map((status) => (
        <StyledLabel key={status}>{LogStatusLabel[status]}</StyledLabel>
      ))}

      {condition.illegal_statuses.map((status) => (
        <StyledLabel key={status}>{IllegalStatusLabel[status]}</StyledLabel>
      ))}
    </div>
  )
}

const calculateContentTitle = (
  condition: ActivityLogFilteringCondition,
  contents: ActivityLogContentResponse,
) => {
  switch (ContentTypeMappings[condition.action_types[0]]) {
    case 'coupon':
      return (
        contents.coupons.find((c) => c.id === condition.content_id)?.title ?? ''
      )
    case 'ticket':
      return (
        contents.tickets.find((c) => c.id === condition.content_id)?.title ?? ''
      )
    case 'subscription':
      return (
        contents.subscriptions.find((c) => c.id === condition.content_id)
          ?.title ?? ''
      )
    case 'stamp':
      return (
        contents.stamp_cards.find((c) => c.id === condition.content_id)
          ?.title ?? ''
      )
    case 'service':
      return (
        contents.services.find((c) => c.id === condition.content_id)?.title ??
        ''
      )
    default:
      return ''
  }
}

const StyledLabel = styled('span')({
  fontSize: 14,
  fontWeight: 'bold',
  backgroundColor: '#F0F0F0',
  padding: 5,
  borderRadius: 5,
})

export default ActivityLogFilteringConditionElements
