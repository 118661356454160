import React from 'react'
import { FormControl } from 'react-bootstrap'
import { SubscriptionContentResource } from '../../../../types/resource/subscriptionContent'
import { Condition, ConditionDiff } from '../../type'
import ConditionFormItem from '../parts/ConditionFormItem'
import SubscriptionContentFormItem from '../parts/SubscriptionContentFormItem'

type Props = {
  condition: Condition
  onChange: (diff: ConditionDiff) => void
}

const SubscriptionCondition = ({ condition, onChange }: Props): JSX.Element => {
  return (
    <>
      <SubscriptionContentFormItem
        condition={condition}
        onSelectSubscriptionContent={(
          subscriptionContent: SubscriptionContentResource,
        ) => {
          onChange({ instance_id: subscriptionContent.id, subscriptionContent })
        }}
      />

      <ConditionFormItem label='契約状況' style={{ marginTop: 8 }}>
        <FormControl
          style={{ backgroundColor: '#fff', color: '#565656', cursor: 'auto' }}
          componentClass='select'
          value={condition.condition_type}
          onChange={(e: any) => onChange({ condition_type: e.target.value })}>
          <option value='subscription_no_contracted'>未契約</option>
          <option value='subscription_contracted'>契約中</option>
          <option value='subscription_canceled'>解約済み</option>
          <option value='subscription_paused'>一時停止中</option>
        </FormControl>
      </ConditionFormItem>
    </>
  )
}

export default SubscriptionCondition
