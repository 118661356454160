import React, { useEffect } from 'react'
import { Grid, Row, Col, ButtonToolbar, Table } from 'react-bootstrap'
import MediaQuery, { useMediaQuery } from 'react-responsive'

// components
import SmallTableBody from '../../components/Table/SmallTableBody'
import Button from '../../components/CustomButton/CustomButton'
import GuideButton from '../../components/Link/GuideLink'
import { Card } from '../../components/Card/Card'

// container
import BannerEntityContainer, {
  ResponseBannerData,
  clickTypeToJpObj,
  positionToJpObj,
  statusToJpObj,
  sizeToJpObj,
} from '../../containers/entities/BannerEntityContainer'

const thBannerArray = [
  'バナー画像',
  'タイトル',
  '遷移先',
  'サイズ',
  '配置',
  '表示順',
  '状態',
]

const statusesStyle = {
  close: 'closed',
  open: 'active',
} as const

const BannerFeatureViewWrapper = ({ history }) => {
  const bannerEntityContainer = BannerEntityContainer.useContainer()
  const { banners } = bannerEntityContainer.state
  const { getBanners } = bannerEntityContainer.logic

  const minWidth600px: boolean = useMediaQuery({
    query: '(min-width: 600px)',
  })

  useEffect(() => {
    getBanners()
  }, [getBanners])

  const goTo = {
    bannerNew: (): void => history.push('banner/new', { banners }),
    bannerEdit: (banner: ResponseBannerData): void =>
      history.push('banner/edit', { banner, banners }),
  }

  return (
    <BannerFeatureView
      goTo={goTo}
      banners={banners}
      minWidth600px={minWidth600px}
    />
  )
}

type GoToProps = {
  bannerNew: () => void
  bannerEdit: (banner: ResponseBannerData) => void
}

type ViewProps = {
  goTo: GoToProps
  banners: ResponseBannerData[]
  minWidth600px: boolean
}

const BannerFeatureView: React.FC<ViewProps> = ({
  goTo,
  banners,
  minWidth600px,
}) => (
  <div className='content'>
    <Grid fluid>
      <Row style={{ marginBottom: 10 }}>
        <Col
          md={12}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 10,
            alignItems: minWidth600px ? 'center' : 'flex-end',
            flexDirection: minWidth600px ? 'row' : 'column',
          }}>
          <GuideButton
            label='バナー'
            link='https://toypo.notion.site/0eb331e33f6e4599a4f5c8d7575bae0f'
          />

          <ButtonToolbar className='pull-right'>
            <Button bsStyle='info' fill pullRight onClick={goTo.bannerNew}>
              <b>バナーを作成する</b>
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Card
            title='バナー一覧'
            category=''
            ctTableFullWidth
            content={
              <Table striped hover>
                <MediaQuery query='(min-width: 767px)'>
                  <thead>
                    <tr>
                      {thBannerArray.map((tableHeaderTitle) => (
                        <th key={tableHeaderTitle}>{tableHeaderTitle}</th>
                      ))}
                    </tr>
                  </thead>
                </MediaQuery>

                <tbody>
                  {banners.map((banner) => (
                    <tr
                      key={banner.id}
                      style={{ cursor: 'pointer' }}
                      onClick={() => goTo.bannerEdit(banner)}>
                      <MediaQuery query='(min-width: 767px)'>
                        <td style={{ width: 48 }}>
                          {/* バナー画像 */}
                          {banner.image_url && (
                            <img
                              key={banner.id}
                              alt='バナー画像'
                              src={banner.image_url}
                              width={210}
                              height={90}
                              style={{
                                borderRadius: 2,
                                objectFit: 'cover',
                              }}
                            />
                          )}
                        </td>
                        {/* タイトル */}
                        <td style={{ fontWeight: 'bold' }}>{banner.title}</td>
                        {/* 遷移先URL */}
                        <td style={{ wordBreak: 'break-word' }}>
                          {clickTypeToJpObj[banner.click_type]}
                          {banner.click_type === 'url' &&
                            `(${banner.click_url})`}
                        </td>
                        {/* サイズ */}
                        <td>{sizeToJpObj[banner.size] || '--'}</td>
                        {/* 配置 */}
                        <td>{positionToJpObj[banner.position] || '--'}</td>
                        {/* 表示順 */}
                        <td>{`${banner.priority}枚目` || '--'}</td>
                        {/* 状態 */}
                        <td
                          style={{
                            fontWeight: 'bold',
                            fontSize: 14,
                            color: 'white',
                            cursor: 'pointer',
                            width: '10%',
                            padding: 10,
                          }}>
                          <div className='statusWrapper'>
                            <div className={statusesStyle[banner.status]}>
                              {statusToJpObj[banner.status]}
                            </div>
                          </div>
                        </td>
                      </MediaQuery>

                      {/* スマホサイズ */}
                      <MediaQuery query='(max-width: 766px)'>
                        <SmallTableBody
                          image={banner.image_url}
                          title={banner.title}
                          contents={[]}
                          status={
                            <div className={statusesStyle[banner.status]}>
                              {statusToJpObj[banner.status]}
                            </div>
                          }
                        />
                      </MediaQuery>
                    </tr>
                  ))}
                </tbody>
              </Table>
            }
          />
        </Col>
      </Row>
    </Grid>
  </div>
)

export default BannerFeatureViewWrapper
