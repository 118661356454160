import { TargetUserCondition } from './type.d'

export const validateTargetUserCondition = (
  targetUserCondition: TargetUserCondition,
) => {
  switch (targetUserCondition.targetGroup) {
    case 'conditions':
      return targetUserCondition.conditionGroups.length === 0
        ? '絞り込み条件を指定してください。'
        : true

    case 'collections':
      return targetUserCondition.userGroupCollectionId === null
        ? 'ユーザーグループを選択してください。'
        : true

    case 'specific_user':
      return targetUserCondition.userIds.length === 0
        ? '対象ユーザーを指定してください。'
        : true

    default:
      return true
  }
}
