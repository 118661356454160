export const TargetGroup = {
  all: 'すべてのユーザー',
  conditions: '対象者の条件を設定',
  collections: 'ユーザーグループを選択',
  specific_user: '指定のユーザー',
} as const
type TargetGroupType = keyof typeof TargetGroup

export type TargetUserCondition = {
  targetGroup: TargetGroupType
  conditionGroups: ConditionGroup[]
  userIds: number[]
  userGroupIds: number[]
  userGroupCollectionId: number | null
}

export type TargetUserConditionParams = {
  conditionGroups?: ConditionGroup[]
  userIds?: number[]
  userGroupCollectionId?: number | null
}
