import React from 'react'
import { TicketContent } from '../../../containers/entities/TicketContentEntityContainer'
import { formatDateExceptTime } from '../../../lib/general'

type Props = {
  ticketContent: TicketContent
  openStatusModal: () => void
  label: string
  labelStyle: string
  ticketType: string
  goToTicketEdit: () => void
}

const TicketContentTableRow = ({
  ticketContent,
  openStatusModal,
  label,
  labelStyle,
  ticketType,
  goToTicketEdit,
}: Props): JSX.Element => (
  <>
    <td
      style={{
        width: 48,
        cursor: 'pointer',
      }}>
      {ticketContent.image_url && (
        <img
          alt='チケット画像'
          src={ticketContent.image_url}
          width={48}
          height={30}
          style={{ borderRadius: 2, objectFit: 'cover' }}
        />
      )}
    </td>
    <td
      style={{
        fontWeight: 'bold',
        cursor: 'pointer',
      }}
      aria-hidden='true'
      onClick={goToTicketEdit}>
      {ticketContent.title}
    </td>
    <td
      style={{
        fontWeight: 'bold',
        cursor: 'pointer',
      }}
      aria-hidden='true'
      onClick={goToTicketEdit}>
      {ticketType}
    </td>
    <td
      style={{
        color: 'white',
        fontWeight: 'bold',
        cursor: 'pointer',
        whiteSpace: 'nowrap',
      }}>
      <div
        className={labelStyle}
        aria-hidden='true'
        onClick={openStatusModal}
        data-cy={`ticket-list-status-label-${ticketContent.id}`}>
        {label}
      </div>
    </td>
    <td
      style={{
        fontWeight: 'bold',
        cursor: 'pointer',
      }}
      aria-hidden='true'
      onClick={goToTicketEdit}>
      {ticketContent.expiration_month
        ? `購入から${ticketContent.expiration_month}ヶ月`
        : `${formatDateExceptTime(
            ticketContent.expiration_from?.toLocaleString().slice(0, -6),
          )}
                                  ~ ${formatDateExceptTime(
                                    ticketContent.expiration_to
                                      ?.toLocaleString()
                                      .slice(0, -6),
                                  )}`}
    </td>
  </>
)

export default TicketContentTableRow
