import React, { useState } from 'react'
import styled from '@emotion/styled'
import { Trash, Plus } from 'phosphor-react'
import ChangeIndicator from '../../../components/ChangeIndicator'
import FormInput from '../../../components/FormInputs/FormInput'
import { QuestionnaireSectionItemFormType } from '../type'
import { generateSectionItem } from '../defaultValues'

type Props = {
  defaultItems: QuestionnaireSectionItemFormType[]
  onChange: (items: QuestionnaireSectionItemFormType[]) => void
  disabled?: boolean
}

const MultiSelectSectionItem = ({
  defaultItems,
  onChange,
  disabled = false,
}: Props): JSX.Element => {
  const [items, setItems] =
    useState<QuestionnaireSectionItemFormType[]>(defaultItems)

  const addItem = () => {
    const newItems = [...items, generateSectionItem()]
    setItems(newItems)
    onChange(newItems)
  }

  const updateSection1Item = (
    key: string,
    params: Partial<QuestionnaireSectionItemFormType>,
  ) => {
    const index = items.findIndex((item) => item.key === key)
    const targetItem = items[index]
    if (targetItem) {
      const newItems = [...items]
      newItems[index] = { ...targetItem, ...params }
      setItems(newItems)
      onChange(newItems)
    }
  }

  const removeSection1Item = (key: string) => {
    updateSection1Item(key, { status: 'close' })
  }

  const isSection1ItemChanged = (key: string) => {
    const original = defaultItems.find((item) => item.key === key)
    const item = items.find((item) => item.key === key)

    return original?.label !== item?.label || original?.status !== item?.status
  }

  const isChanged = defaultItems.reduce(
    (changed, item) => changed || isSection1ItemChanged(item.key),
    false,
  )
  const isAdded = items.some((item) => item.unpersisted)

  return (
    <>
      <div style={{ display: 'flex' }}>
        <StyledCaption>選択肢</StyledCaption>
        {(isChanged || isAdded) && (
          <ChangeIndicator style={{ marginLeft: 8 }} />
        )}
      </div>

      {items
        .filter((item) => item.status === 'open')
        .map((item) => (
          <StyledAnswerContainer key={item.key}>
            <FormInput
              value={item.label}
              onChange={({ target }) =>
                updateSection1Item(item.key, { label: target.value })
              }
              formGroupStyle={{ marginBottom: 0, flex: 1, marginRight: 8 }}
              showChangeBorderIndicator={isSection1ItemChanged(item.key)}
              disabled={disabled}
            />
            {!disabled && (
              <Trash
                size={20}
                color='#999'
                style={{ cursor: 'pointer' }}
                onClick={() => removeSection1Item(item.key)}
              />
            )}
          </StyledAnswerContainer>
        ))}

      {!disabled && (
        <StyledAddAnswerButtonContainer onClick={addItem}>
          <Plus size={20} />
          選択肢を追加
        </StyledAddAnswerButtonContainer>
      )}
    </>
  )
}

const StyledAnswerContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 8,
})

const StyledCaption = styled('div')({
  fontSize: 14,
  color: '#787878',
  fontWeight: 700,
  marginBottom: 8,
})

const StyledAddAnswerButtonContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  color: '#1dc7ea',
  cursor: 'pointer',
  marginTop: 16,
  '& > svg': { marginTop: -3 },
})

export default MultiSelectSectionItem
