import React, { useEffect, useReducer } from 'react'
import { styled } from '@mui/system'
import MuiAlert from '@mui/material/Alert'
import { Prompt } from 'react-router-dom'
import { FormProvider, Controller, useForm } from 'react-hook-form'
import { Row, Form } from 'react-bootstrap'
import { useLoginContext } from '../../../providers/LoginContextProvider'
import CustomButton from '../../../components/CustomButton/CustomButton'
import FormInput from '../../../components/FormInputs/FormInput'
import {
  FormChangedAlertModal,
  ModalContentFormChangedList,
  Alert,
} from '../../../components/Modal/FormChangedAlertModal'
import SwitchForm from '../../../components/FormInputs/SwitchForm'
import { useBooleanState, usePcSizeFlag } from '../../../lib/hooks'
import { QuestionnaireStatus } from '../../../types/questionnaire'
import { QuestionnaireFormType } from '../type'
import { QuestionnaireSectionCount } from '../defaultValues'
import QuestionnairePreview from './QuestionnairePreview'
import QuestionnaireAccordion from './QuestionnaireAccordion'
import MultiSelectSectionItem from './MultiSelectSectionItem'
import QuestionnairePublishSettingCard from './QuestionnairePublishSettingCard'

const SatisfactionQuestionnaireItemLabels = [
  'お店の総合満足度',
  '味の満足度',
  '量の満足度',
  '品揃えの満足度',
  '接客の満足度',
  '提供スピードの満足度',
  '価格の満足度',
  'お店の清潔感に対する満足度',
  'お店の雰囲気に対する満足度',
]

const FieldLabels = {
  title: 'アンケート名',
  description: '説明文',
  thanks_message: 'お礼メッセージ',
  google_map_review_url: 'GoogleMapの口コミURL',
  section1: '来店のきっかけ',
  section2: 'これまでの来店回数',
  section3: '注文したメニュー',
  section4: '商品やサービスに対する信頼・愛着',
  section5: SatisfactionQuestionnaireItemLabels[0],
  section6: SatisfactionQuestionnaireItemLabels[1],
  section7: SatisfactionQuestionnaireItemLabels[2],
  section8: SatisfactionQuestionnaireItemLabels[3],
  section9: SatisfactionQuestionnaireItemLabels[4],
  section10: SatisfactionQuestionnaireItemLabels[5],
  section11: SatisfactionQuestionnaireItemLabels[6],
  section12: SatisfactionQuestionnaireItemLabels[7],
  section13: SatisfactionQuestionnaireItemLabels[8],
  section14: 'お店の良かった点・改善してほしい点',
} as const

// "属性の調査"に属する質問項目数
const UserAttributeQuestionnaireCount = 3
// "NPSの調査"に属する質問項目数
const NPSQuestionnaireCount = 1

type Props = {
  status: QuestionnaireStatus
  editable: boolean
  defaultValues: QuestionnaireFormType
  disabled: boolean
  onSubmit: (formValues: QuestionnaireFormType) => void
  onChangeStatus: (status: QuestionnaireStatus) => void
}

const QuestionnaireForm = ({
  status,
  editable,
  defaultValues,
  disabled,
  onSubmit,
  onChangeStatus,
}: Props): JSX.Element => {
  const { currentStore } = useLoginContext()
  const isPcOrTablet = usePcSizeFlag()
  const [isModalOpen, openModal, closeModal] = useBooleanState(false)
  const methods = useForm<QuestionnaireFormType>({ defaultValues })
  const {
    register,
    errors,
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
  } = methods
  const { dirtyFields } = methods.formState
  const watchValues = methods.watch()
  const isFormChanged = Object.keys(dirtyFields).length > 0
  const [section1ItemsKey, resetSection1Items] = useReducer((s) => s + 1, 1)
  const [section3ItemsKey, resetSection3Items] = useReducer((s) => s + 1, 1)

  useEffect(() => {
    reset(defaultValues)
    resetSection1Items()
    resetSection3Items()
  }, [reset, defaultValues])

  useEffect(() => {
    ;[...Array(QuestionnaireSectionCount)].forEach((_, i) => {
      register(`section${i + 1}.id`)
      register(`section${i + 1}.enabled`)
      register(`section${i + 1}.section_type`)
      register(`section${i + 1}.items`)
      register(`section${i + 1}.required`)
    })
  }, [register])

  const submit = async () => {
    onSubmit(getValues())
    closeModal()
  }

  const warnings = []

  return (
    <FormProvider {...methods}>
      {!editable && (
        <StyledAlert severity='info'>
          このアンケートは編集できません。編集したい場合は作成元の店舗から行ってください。
        </StyledAlert>
      )}

      <StyledContainer isPcOrTablet={isPcOrTablet}>
        <Form onSubmit={handleSubmit(openModal)} style={{ flex: 1 }}>
          <div className='card'>
            <div className='header'>
              <h4 className='title'>基本設定</h4>
            </div>
            <div className='content'>
              <Row>
                <FormInput
                  name='title'
                  label={FieldLabels.title}
                  ncol='col-lg-9 col-md-12'
                  inputRef={register({
                    required: 'アンケート名を入力してください',
                  })}
                  validationMessage={errors.title?.message}
                  showChangeIndicator={Boolean(dirtyFields.title)}
                  disabled={!editable}
                />
              </Row>

              <Row>
                <FormInput
                  name='description'
                  label={FieldLabels.description}
                  rows={6}
                  ncol='col-lg-9 col-md-12'
                  componentClass='textarea'
                  inputRef={register({ required: '説明文を入力してください' })}
                  validationMessage={errors.description?.message}
                  showChangeIndicator={Boolean(dirtyFields.description)}
                  disabled={!editable}
                />
              </Row>
            </div>

            <div className='header'>
              <h4 className='title'>質問項目</h4>
            </div>
            <div className='content'>
              <StyledNote>表示したい質問項目を選択してください</StyledNote>

              <StyledSectionContainer>
                <StyledSectionTitle>属性の調査</StyledSectionTitle>

                <QuestionnaireAccordion
                  label={FieldLabels.section1}
                  name='section1.enabled'
                  required={watchValues.section1?.required}
                  disabled={!editable}
                >
                  <FormInput
                    name='section1.title'
                    label='質問'
                    inputRef={register({
                      required: '質問の文言を入力してください',
                    })}
                    validationMessage={errors.section1?.title?.message}
                    showChangeIndicator={Boolean(dirtyFields.section1?.title)}
                    disabled={!editable}
                  />

                  <Controller
                    type='checkbox'
                    name='section1.required'
                    control={control}
                    render={(props) => (
                      <SwitchForm
                        onChange={(checked) => props.onChange(checked)}
                        checked={props.value}
                        label='回答を必須にする'
                        uncheckedText='任意'
                        checkedText='必須'
                        showChangeIndicator={Boolean(
                          dirtyFields.section1?.required,
                        )}
                        disabled={!editable}
                      />
                    )}
                  />

                  <MultiSelectSectionItem
                    key={section1ItemsKey}
                    defaultItems={defaultValues.section1.items}
                    onChange={(items) =>
                      setValue('section1.items', items, { shouldDirty: true })
                    }
                    disabled={!editable}
                  />
                </QuestionnaireAccordion>

                <QuestionnaireAccordion
                  label={FieldLabels.section2}
                  name='section2.enabled'
                  required={watchValues.section2?.required}
                  disabled={!editable}
                >
                  <FormInput
                    name='section2.title'
                    label='質問'
                    inputRef={register({
                      required: '質問のタイトルを入力してください',
                    })}
                    validationMessage={errors.section2?.title?.message}
                    showChangeIndicator={Boolean(dirtyFields.section2?.title)}
                    disabled={!editable}
                  />

                  <Controller
                    type='checkbox'
                    name='section2.required'
                    control={control}
                    render={(props) => (
                      <SwitchForm
                        onChange={(checked) => props.onChange(checked)}
                        checked={props.value}
                        label='回答を必須にする'
                        uncheckedText='任意'
                        checkedText='必須'
                        showChangeIndicator={Boolean(
                          dirtyFields.section2?.required,
                        )}
                        disabled={!editable}
                      />
                    )}
                  />
                </QuestionnaireAccordion>

                <QuestionnaireAccordion
                  label={FieldLabels.section3}
                  name='section3.enabled'
                  required={watchValues.section3?.required}
                  disabled={!editable}
                >
                  <FormInput
                    name='section3.title'
                    label='質問'
                    inputRef={register({
                      required: '質問の文言を入力してください',
                    })}
                    validationMessage={errors.section3?.title?.message}
                    showChangeIndicator={Boolean(dirtyFields.section3?.title)}
                    disabled={!editable}
                  />

                  <Controller
                    type='checkbox'
                    name='section3.required'
                    control={control}
                    render={(props) => (
                      <SwitchForm
                        onChange={(checked) => props.onChange(checked)}
                        checked={props.value}
                        label='回答を必須にする'
                        uncheckedText='任意'
                        checkedText='必須'
                        showChangeIndicator={Boolean(
                          dirtyFields.section3?.required,
                        )}
                        disabled={!editable}
                      />
                    )}
                  />

                  <MultiSelectSectionItem
                    key={section3ItemsKey}
                    defaultItems={defaultValues.section3.items}
                    onChange={(items) =>
                      setValue('section3.items', items, { shouldDirty: true })
                    }
                  />
                </QuestionnaireAccordion>
              </StyledSectionContainer>

              <StyledSectionContainer>
                <StyledSectionTitle>NPSの調査</StyledSectionTitle>

                <QuestionnaireAccordion
                  label={FieldLabels.section4}
                  name='section4.enabled'
                  required={watchValues.section4?.required}
                  disabled={!editable}
                >
                  <FormInput
                    name='section4.title'
                    label='質問'
                    inputRef={register({
                      required: '質問のタイトルを入力してください',
                    })}
                    validationMessage={errors.section4?.title?.message}
                    showChangeIndicator={Boolean(dirtyFields.section4?.title)}
                    disabled={!editable}
                  />
                  <FormInput
                    name='section4.description_before'
                    label='スコア0の説明'
                    inputRef={register({
                      required: 'スコア0の説明を入力してください',
                    })}
                    validationMessage={
                      errors.section4?.description_before?.message
                    }
                    showChangeIndicator={Boolean(
                      dirtyFields.section4?.description_before,
                    )}
                    disabled={!editable}
                  />
                  <FormInput
                    name='section4.description_after'
                    label='スコア10の説明'
                    inputRef={register({
                      required: 'スコア10の説明を入力してください',
                    })}
                    validationMessage={
                      errors.section4?.description_after?.message
                    }
                    showChangeIndicator={Boolean(
                      dirtyFields.section4?.description_after,
                    )}
                    disabled={!editable}
                  />

                  <Controller
                    type='checkbox'
                    name='section4.required'
                    control={control}
                    render={(props) => (
                      <SwitchForm
                        onChange={(checked) => props.onChange(checked)}
                        checked={props.value}
                        label='回答を必須にする'
                        uncheckedText='任意'
                        checkedText='必須'
                        showChangeIndicator={Boolean(
                          dirtyFields.section4?.required,
                        )}
                        disabled={!editable}
                      />
                    )}
                  />
                </QuestionnaireAccordion>
              </StyledSectionContainer>

              <StyledSectionContainer>
                <StyledSectionTitle>満足度の調査</StyledSectionTitle>

                {SatisfactionQuestionnaireItemLabels.map((label, index) => {
                  const sectionName = `section${
                    index +
                    UserAttributeQuestionnaireCount +
                    NPSQuestionnaireCount +
                    1
                  }`
                  return (
                    <QuestionnaireAccordion
                      key={sectionName}
                      label={label}
                      name={`${sectionName}.enabled`}
                      required={watchValues[sectionName]?.required}
                      fixed={sectionName === 'section5'}
                      disabled={!editable}
                    >
                      <FormInput
                        name={`${sectionName}.title`}
                        label='質問'
                        inputRef={register({
                          required: '質問のタイトルを入力してください',
                        })}
                        validationMessage={errors[sectionName]?.title?.message}
                        showChangeIndicator={Boolean(
                          dirtyFields[sectionName]?.title,
                        )}
                        disabled={!editable}
                      />

                      <Controller
                        type='checkbox'
                        name={`${sectionName}.required`}
                        control={control}
                        render={(props) => (
                          <SwitchForm
                            onChange={(checked) => props.onChange(checked)}
                            checked={props.value}
                            disabled={sectionName === 'section5' || !editable}
                            label='回答を必須にする'
                            uncheckedText='任意'
                            checkedText='必須'
                            showChangeIndicator={Boolean(
                              dirtyFields[sectionName]?.required,
                            )}
                          />
                        )}
                      />
                    </QuestionnaireAccordion>
                  )
                })}
              </StyledSectionContainer>

              <StyledSectionContainer>
                <StyledSectionTitle>レビュー</StyledSectionTitle>

                <QuestionnaireAccordion
                  label={`${FieldLabels.section14}（自由入力）`}
                  name='section14.enabled'
                  required={watchValues.section14?.required}
                  disabled={!editable}
                >
                  <FormInput
                    name='section14.title'
                    label='質問'
                    inputRef={register({
                      required: '質問のタイトルを入力してください',
                    })}
                    validationMessage={errors.section14?.title?.message}
                    showChangeIndicator={Boolean(dirtyFields.section14?.title)}
                    disabled={!editable}
                  />

                  <Controller
                    type='checkbox'
                    name='section14.required'
                    control={control}
                    render={(props) => (
                      <SwitchForm
                        onChange={(checked) => props.onChange(checked)}
                        checked={props.value}
                        label='回答を必須にする'
                        uncheckedText='任意'
                        checkedText='必須'
                        showChangeIndicator={Boolean(
                          dirtyFields.section14?.required,
                        )}
                        disabled={!editable}
                      />
                    )}
                  />
                </QuestionnaireAccordion>
              </StyledSectionContainer>
            </div>

            <div className='header'>
              <h4 className='title'>完了画面</h4>
            </div>
            <div className='content'>
              <Row>
                <FormInput
                  name='thanks_message'
                  label={FieldLabels.thanks_message}
                  rows={6}
                  ncol='col-lg-9 col-md-12'
                  componentClass='textarea'
                  inputRef={register({
                    required: 'お礼メッセージを入力してください',
                  })}
                  validationMessage={errors.thanks_message?.message}
                  showChangeIndicator={Boolean(dirtyFields.thanks_message)}
                  disabled={!editable}
                />
              </Row>

              <Row>
                <FormInput
                  name='google_map_review_url'
                  label={FieldLabels.google_map_review_url}
                  ncol='col-lg-9 col-md-12'
                  inputRef={register}
                  validationMessage={errors.google_map_review_url?.message}
                  showChangeIndicator={Boolean(
                    dirtyFields.google_map_review_url,
                  )}
                  disabled={!editable}
                />
              </Row>
            </div>

            <StyledSubmitButtonContainer>
              <CustomButton
                type='submit'
                bsStyle='info'
                fill
                disabled={disabled || !isFormChanged || !editable}
              >
                <strong>更新する</strong>
              </CustomButton>
            </StyledSubmitButtonContainer>
          </div>

          <QuestionnairePublishSettingCard
            status={status}
            disabled={disabled || !editable}
            onChangeStatus={onChangeStatus}
          />
        </Form>

        {isPcOrTablet && (
          <div style={{ marginLeft: 16 }}>
            <QuestionnairePreview
              formValues={watchValues}
              storeIconUrl={currentStore.app_icon_url}
            />
          </div>
        )}
      </StyledContainer>

      <FormChangedAlertModal
        show={isModalOpen}
        title='顧客満足度調査アンケートを更新'
        onSubmit={submit}
        onCancel={closeModal}
        disabled={disabled}
      >
        顧客満足度調査アンケートの内容を更新してよろしいですか？
        <ModalContentFormChangedList
          changedProperties={Object.entries(FieldLabels)
            .map(([key, label]) => (dirtyFields[key] ? label : ''))
            .filter((v) => v)}
        />
        {warnings.length > 0 && (
          <Alert severity='warning'>
            <ul>
              {warnings.map((warning) => (
                <li>{warning}</li>
              ))}
            </ul>
          </Alert>
        )}
      </FormChangedAlertModal>

      <Prompt
        when={isFormChanged}
        message='行った変更が保存されていない可能性があります。このページを離れますか？'
      />
    </FormProvider>
  )
}

const StyledContainer = styled('div', {
  shouldForwardProp: (prop: string) => 'isPcOrTablet' !== prop,
})<{ isPcOrTablet: boolean }>(({ theme, isPcOrTablet }) => ({
  display: 'flex',
  ...(isPcOrTablet
    ? { margin: theme.spacing(2) }
    : { marginTop: theme.spacing(2) }),
}))

const StyledAlert = styled(MuiAlert)(({ theme }) => ({
  [theme.breakpoints.up('mobile')]: {
    margin: theme.spacing(2, 0, 0, 0),
  },
  [theme.breakpoints.up('laptop')]: {
    margin: theme.spacing(2),
  },
}))

const StyledNote = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: '#555',
}))

const StyledSectionContainer = styled('div')(({ theme }) => ({
  backgroundColor: '#F7F7F8',
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
}))

const StyledSectionTitle = styled('div')(({ theme }) => ({
  fontSize: 15,
  color: '#555',
  fontWeight: 700,
  marginBottom: theme.spacing(2),
}))

const StyledSubmitButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(2),
}))

export default QuestionnaireForm
