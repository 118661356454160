export const MenuStatusLabel = {
  draft: '下書き',
  open: '公開',
  close: '非公開',
  preview: '下書き',
  sold: '売り切れ',
} as const

export const MenuTypeLabels = {
  takeout: 'テイクアウト',
  delivery: 'デリバリー',
  preorder: 'プレオーダー',
  ec: 'オンラインショップ',
  display: '店内メニュー',
} as const
