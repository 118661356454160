import React, { useState } from 'react'
import styled from '@emotion/styled'
import { FormControl } from 'react-bootstrap'
import { useLoginContext } from '../../../providers/LoginContextProvider'
import MembersCardRankCondition from './conditions/MembersCardRankCondition'
import GenderCondition from './conditions/GenderCondition'
import GenerationCondition from './conditions/GenerationCondition'
import ThirdPartyServiceCondition from './conditions/ThirdPartyServiceCondition'
import StoreConnectionCondition from './conditions/StoreConnectionCondition'
import LastVisitCondition from './conditions/LastVisitCondition'
import VisitCountCondition from './conditions/VisitCountCondition'
import VisitFrequencyCondition from './conditions/VisitFrequencyCondition'
import CouponReceivedCondition from './conditions/CouponReceivedCondition'
import CouponUsedCondition from './conditions/CouponUsedCondition'
import { Condition, ConditionDiff } from '../type'
import { generateDescription, generateDefaultCondition } from '../utils'
import NoticeReadCondition from './conditions/NoticeReadCondition'
import MobileOrderCondition from './conditions/MobileOrderCondition'
import MobileOrderByOrderDateCondition from './conditions/MobileOrderByOrderDateCondition'
import SubscriptionStatusCondition from './conditions/SubscriptionStatusCondition'
import SubscriptionPurchasedCountCondition from './conditions/SubscriptionPurchasedCountCondition'
import SubscriptionUsedCountCondition from './conditions/SubscriptionUsedCountCondition'
import SubscriptionUpdatedCountCondition from './conditions/SubscriptionUpdatedCountCondition'

type Props = {
  conditionLabel: string
  condition: Condition
  errorMessage?: string
  onUpdateCondition: (condition: Condition) => void
  onDelete: () => void
}

const ConditionForm = ({
  conditionLabel,
  condition,
  errorMessage,
  onUpdateCondition,
  onDelete,
}: Props): JSX.Element => {
  const [conditionFormType, setConditionFormType] = useState<ConditionFormType>(
    ConditionMap[condition.condition_type],
  )
  const updateCondition = (diff: ConditionDiff) =>
    onUpdateCondition({ ...condition, ...diff })
  const conditionTypeOption = ConditionFormTypeOption[conditionFormType]
  const availableConditionTypeOptionGroups =
    useAvailableConditionTypeOptionGroups()

  const handleChangeConditionFormType = (e: any) => {
    const newConditionFormType = e.target.value as ConditionFormType
    setConditionFormType(newConditionFormType)

    const newConditionType = DefaultConditionTypeOf[newConditionFormType]
    const newCondition = generateDefaultCondition(newConditionType)
    if (
      ConditionFormTypesHavingDefaultValueFrom.includes(newConditionFormType)
    ) {
      newCondition.value_from = '1'
    }
    onUpdateCondition(newCondition)
  }

  const description = generateDescription(condition)

  return (
    <StyledContainer error={Boolean(errorMessage)}>
      <StyledHeader>
        <StyledHeaderTitle>
          <StyledConditionLabel>{conditionLabel}</StyledConditionLabel>

          <StyledDescription error={Boolean(errorMessage)}>
            {errorMessage || description}
          </StyledDescription>

          <StyledIcon
            style={{ margin: '0 8px' }}
            className='fa fa-trash-o'
            onClick={onDelete}
          />
        </StyledHeaderTitle>
      </StyledHeader>

      <div
        style={{
          marginTop: 8,
          display: conditionTypeOption?.short ? 'flex' : 'block',
        }}>
        <StyledFlex>
          <FormControl
            componentClass='select'
            value={conditionFormType}
            onChange={handleChangeConditionFormType}>
            <option value=''>選択してください</option>

            {availableConditionTypeOptionGroups.map(
              ({ label, conditionFormTypes }) => (
                <optgroup key={label} label={label}>
                  {conditionFormTypes.map((conditionFormType) => (
                    <option key={conditionFormType} value={conditionFormType}>
                      {ConditionFormTypeOption[conditionFormType].label}
                    </option>
                  ))}
                </optgroup>
              ),
            )}
          </FormControl>
        </StyledFlex>

        {Boolean(conditionTypeOption) && (
          <>
            <StyledFlexSpacer />

            <StyledFlex>
              <conditionTypeOption.component
                condition={condition}
                onChange={updateCondition}
              />
            </StyledFlex>
          </>
        )}
      </div>
    </StyledContainer>
  )
}

const ConditionFormTypesHavingDefaultValueFrom = [
  'visit_count',
  'coupon_received_count',
  'coupon_used_count',
  'mobile_order_takeout',
  'mobile_order_takeout_by_order_date',
  'mobile_order_delivery',
  'mobile_order_delivery_by_order_date',
  'mobile_order_preorder',
  'mobile_order_preorder_by_order_date',
  'mobile_order_ec',
  'mobile_order_ec_by_order_date',
  'notice_read',
  'subscription_purchased_count',
  'subscription_used_count',
  'subscription_updated_count',
]

const ConditionMap = {
  store_connection: 'store_connection',
  visit_count: 'visit_count',
  last_visit: 'last_visit',
  visit_frequency: 'visit_frequency',
  members_card_rank: 'members_card_rank',
  coupon_received_count: 'coupon_received_count',
  coupon_used_count: 'coupon_used_count',
  notice_read: 'notice_read',
  user_gender: 'user_gender',
  user_generation: 'user_generation',
  third_party_service: 'third_party_service',
  mobile_order_takeout: 'mobile_order_takeout',
  mobile_order_delivery: 'mobile_order_delivery',
  mobile_order_preorder: 'mobile_order_preorder',
  mobile_order_ec: 'mobile_order_ec',
  mobile_order_takeout_by_order_date: 'mobile_order_takeout_by_order_date',
  mobile_order_delivery_by_order_date: 'mobile_order_delivery_by_order_date',
  mobile_order_preorder_by_order_date: 'mobile_order_preorder_by_order_date',
  mobile_order_ec_by_order_date: 'mobile_order_ec_by_order_date',
  subscription_purchased_count: 'subscription_purchased_count',
  subscription_used_count: 'subscription_used_count',
  subscription_updated_count: 'subscription_updated_count',
  subscription_contracted: 'subscription_status',
  subscription_paused: 'subscription_status',
  subscription_canceled: 'subscription_status',
  subscription_no_contracted: 'subscription_status',
} as const

const DefaultConditionTypeOf = {
  user_gender: 'user_gender',
  user_generation: 'user_generation',
  third_party_service: 'third_party_service',
  store_connection: 'store_connection',
  visit_count: 'visit_count',
  last_visit: 'last_visit',
  visit_frequency: 'visit_frequency',
  members_card_rank: 'members_card_rank',
  coupon_received_count: 'coupon_received_count',
  coupon_used_count: 'coupon_used_count',
  notice_read: 'notice_read',
  mobile_order_takeout: 'mobile_order_takeout',
  mobile_order_takeout_by_order_date: 'mobile_order_takeout_by_order_date',
  mobile_order_delivery: 'mobile_order_delivery',
  mobile_order_delivery_by_order_date: 'mobile_order_delivery_by_order_date',
  mobile_order_preorder: 'mobile_order_preorder',
  mobile_order_preorder_by_order_date: 'mobile_order_preorder_by_order_date',
  mobile_order_ec: 'mobile_order_ec',
  mobile_order_ec_by_order_date: 'mobile_order_ec_by_order_date',
  subscription_status: 'subscription_no_contracted',
  subscription_purchased_count: 'subscription_purchased_count',
  subscription_used_count: 'subscription_used_count',
  subscription_updated_count: 'subscription_updated_count',
} as const

const ConditionFormTypeOption = {
  user_gender: { label: '性別', component: GenderCondition, short: true },
  user_generation: {
    label: '年代',
    component: GenerationCondition,
    short: true,
  },
  third_party_service: {
    label: '利用サービス',
    component: ThirdPartyServiceCondition,
    short: true,
  },
  store_connection: {
    label: 'つながった日',
    component: StoreConnectionCondition,
    short: false,
  },
  visit_count: {
    label: '来店回数',
    component: VisitCountCondition,
    short: false,
  },
  last_visit: {
    label: '最終来店日',
    component: LastVisitCondition,
    short: false,
  },
  visit_frequency: {
    label: '来店頻度',
    component: VisitFrequencyCondition,
    short: false,
  },
  members_card_rank: {
    label: '会員ランク',
    component: MembersCardRankCondition,
    short: true,
  },
  coupon_received_count: {
    label: 'クーポン獲得',
    component: CouponReceivedCondition,
    short: false,
  },
  coupon_used_count: {
    label: 'クーポン利用',
    component: CouponUsedCondition,
    short: false,
  },
  notice_read: {
    label: 'お知らせ閲覧',
    component: NoticeReadCondition,
    short: false,
  },
  mobile_order_takeout: {
    label: 'テイクアウト受け取り',
    component: MobileOrderCondition,
    short: false,
  },
  mobile_order_takeout_by_order_date: {
    label: 'テイクアウト注文',
    component: MobileOrderByOrderDateCondition,
    short: false,
  },
  mobile_order_delivery: {
    label: 'デリバリー受け取り',
    component: MobileOrderCondition,
    short: false,
  },
  mobile_order_delivery_by_order_date: {
    label: 'デリバリー注文',
    component: MobileOrderByOrderDateCondition,
    short: false,
  },
  mobile_order_preorder: {
    label: 'プレオーダー受け取り',
    component: MobileOrderCondition,
    short: false,
  },
  mobile_order_preorder_by_order_date: {
    label: 'プレオーダー注文',
    component: MobileOrderByOrderDateCondition,
    short: false,
  },
  mobile_order_ec: {
    label: 'オンラインショップ受け取り',
    component: MobileOrderCondition,
    short: false,
  },
  mobile_order_ec_by_order_date: {
    label: 'オンラインショップ注文',
    component: MobileOrderByOrderDateCondition,
    short: false,
  },
  subscription_status: {
    label: 'サブスク契約状況',
    component: SubscriptionStatusCondition,
    short: false,
  },
  subscription_purchased_count: {
    label: 'サブスク購入',
    component: SubscriptionPurchasedCountCondition,
    short: false,
  },
  subscription_used_count: {
    label: 'サブスク利用',
    component: SubscriptionUsedCountCondition,
    short: false,
  },
  subscription_updated_count: {
    label: 'サブスク更新',
    component: SubscriptionUpdatedCountCondition,
    short: false,
  },
} as const
type ConditionFormType = keyof typeof ConditionFormTypeOption

const useAvailableConditionTypeOptionGroups = (): {
  label: string
  conditionFormTypes: string[]
}[] => {
  const { activeFunctionsByCurrentStoreAndChildren, lineChannel } =
    useLoginContext()
  return ConditionTypeOptionGroups.map((group) => {
    const availableTypes = group.conditionFormTypes.filter((type) => {
      switch (type) {
        case 'user_gender':
        case 'user_generation':
        case 'store_connection':
        case 'visit_count':
        case 'last_visit':
        case 'visit_frequency':
          return true

        case 'members_card_rank':
          return activeFunctionsByCurrentStoreAndChildren.includes(
            'members_card',
          )

        case 'coupon_received_count':
        case 'coupon_used_count':
          return activeFunctionsByCurrentStoreAndChildren.includes('coupon')

        case 'notice_read':
          return activeFunctionsByCurrentStoreAndChildren.includes('notice')

        case 'mobile_order_takeout':
        case 'mobile_order_takeout_by_order_date':
          return activeFunctionsByCurrentStoreAndChildren.includes('takeout')

        case 'mobile_order_delivery':
        case 'mobile_order_delivery_by_order_date':
          return activeFunctionsByCurrentStoreAndChildren.includes('delivery')

        case 'mobile_order_preorder':
        case 'mobile_order_preorder_by_order_date':
          return activeFunctionsByCurrentStoreAndChildren.includes('preorder')

        case 'mobile_order_ec':
        case 'mobile_order_ec_by_order_date':
          return activeFunctionsByCurrentStoreAndChildren.includes('ec')

        case 'subscription_status':
        case 'subscription_purchased_count':
        case 'subscription_used_count':
        case 'subscription_updated_count':
          return activeFunctionsByCurrentStoreAndChildren.includes(
            'subscription',
          )

        case 'third_party_service':
          return lineChannel !== null

        default:
          return false
      }
    })

    return { label: group.label, conditionFormTypes: availableTypes }
  }).filter((group) => group.conditionFormTypes.length > 0)
}

const ConditionTypeOptionGroups = [
  {
    label: '顧客情報',
    conditionFormTypes: [
      'user_gender',
      'user_generation',
      'third_party_service',
    ],
  },
  {
    label: 'つながり',
    conditionFormTypes: ['store_connection'],
  },
  {
    label: '来店',
    conditionFormTypes: ['visit_count', 'last_visit', 'visit_frequency'],
  },
  {
    label: '会員カード',
    conditionFormTypes: ['members_card_rank'],
  },
  {
    label: 'クーポン',
    conditionFormTypes: ['coupon_received_count', 'coupon_used_count'],
  },
  {
    label: 'お知らせ',
    conditionFormTypes: ['notice_read'],
  },
  {
    label: 'テイクアウト',
    conditionFormTypes: [
      'mobile_order_takeout',
      'mobile_order_takeout_by_order_date',
    ],
  },
  {
    label: 'デリバリー',
    conditionFormTypes: [
      'mobile_order_delivery',
      'mobile_order_delivery_by_order_date',
    ],
  },
  {
    label: 'プレオーダー',
    conditionFormTypes: [
      'mobile_order_preorder',
      'mobile_order_preorder_by_order_date',
    ],
  },
  {
    label: 'オンラインショップ',
    conditionFormTypes: ['mobile_order_ec', 'mobile_order_ec_by_order_date'],
  },
  {
    label: 'サブスク',
    conditionFormTypes: [
      'subscription_status',
      'subscription_purchased_count',
      'subscription_used_count',
      'subscription_updated_count',
    ],
  },
]

const StyledContainer = styled.div<{ error: boolean }>(({ error }) => ({
  padding: 16,
  backgroundColor: '#F4F4F4',
  borderRadius: 4,
  border: error ? 'solid 1px red' : '',
}))

const StyledHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})
const StyledHeaderTitle = styled.div({
  display: 'flex',
  alignItems: 'center',
  fontSize: 18,
  color: '#787878',
  fontWeight: 700,
})
const StyledConditionLabel = styled.div({
  whiteSpace: 'nowrap',
  marginRight: 8,
})
const StyledDescription = styled.div<{ error: boolean }>(({ error }) => ({
  fontSize: 14,
  fontWeight: 500,
  color: error ? 'red' : '#787878',
}))
const StyledIcon = styled.i({
  cursor: 'pointer',
})

const StyledFlex = styled.div({
  flex: 1,
})
const StyledFlexSpacer = styled.div({
  width: 16,
  height: 8,
  flexBasis: 16,
})

export default ConditionForm
