import apiClient from './apiClient'
import { loadViewObj } from '../viewObj'
import { RequestBannerData } from '../../containers/entities/BannerEntityContainer'

export const getBanners = async () => {
  const GET_BANNERS = `staff/stores/${loadViewObj()?.id}/banners`

  const response = await apiClient({
    method: 'GET',
    uri: GET_BANNERS,
  })
  return response.data
}

export const deleteBanner = async (bannerId: number): Promise<void> => {
  const DELETE_BANNER = `staff/stores/${loadViewObj()?.id}/banners/${bannerId}`

  await apiClient({
    method: 'DELETE',
    uri: DELETE_BANNER,
  })
}

export const updateBanner = async (
  bannerId: number,
  bannerData: Partial<RequestBannerData>,
) => {
  const UPDATE_BANNER = `staff/stores/${loadViewObj()?.id}/banners/${bannerId}`

  const requestParams = {
    banner: bannerData,
  }

  const response = await apiClient({
    method: 'PUT',
    uri: UPDATE_BANNER,
    params: requestParams,
  })

  return response.data
}

export const createBanner = async (bannerData: RequestBannerData) => {
  const POST_BANNER = `staff/stores/${loadViewObj()?.id}/banners`

  const requestParams = {
    banner: bannerData,
  }

  const response = await apiClient({
    method: 'POST',
    uri: POST_BANNER,
    params: requestParams,
  })

  return response.data
}
