import React, { useMemo } from "react";
import { Row, Col, Table } from "react-bootstrap";

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Card } from "../../../components/Card/Card";

import { MembersCardRankInterface } from '../../../containers/entities/MembersCardContentEntityContainer'
import {
  MembersCardRankStatusLabel,
  MemberCardRankImages,
} from '../../../constants/membersCard'

interface Props {
  ranks: MembersCardRankInterface[]
  default_rank_up_method: string
  rank_enabled: boolean
  onClick: (id?: number) => void
}

const MembersCardRankTable: React.FC<Props> = ({
  ranks,
  default_rank_up_method,
  rank_enabled,
  onClick,
}) => {
  const needValueObj = useMemo(() => {
    switch (default_rank_up_method) {
      case 'rank_up_by_check_in':
        return {
          label: 'チェックイン数',
          unit: '回',
        }
      case 'rank_up_by_payment':
        return {
          label: 'お会計金額',
          unit: '円',
        }
      case 'rank_up_by_point':
        return {
          label: '獲得ポイント',
          unit: 'pt',
        }
      default:
        return {
          label: '',
          unit: '',
        }
    }
  }, [default_rank_up_method])

  const trArray = useMemo(
    () =>
      needValueObj.label
        ? [
            { id: 'rank_image', label: '' },
            { id: 'rank_name', label: 'ランク名' },
            {
              id: 'require_number',
              label: `必要な${needValueObj?.label}`,
            },
            { id: 'rank_status', label: '状態' },
          ]
        : [
            { id: 'rank_image', label: '' },
            { id: 'rank_name', label: 'ランク名' },

            { id: 'rank_status', label: '状態' },
          ],
    [needValueObj],
  )

  return (
    <Row>
      <Col xs={12} lg={10}>
        <Box mb={1.2} display="flex" justifyContent="flex-end">
          <Button
            disabled={!rank_enabled}
            variant="contained"
            color={rank_enabled ? "submit" : "cancel"}
            onClick={() => onClick()}
          >
            ランクを作成する
          </Button>
        </Box>
        <Card
          title='会員ランク一覧'
          content={
            <Table striped hover>
              <thead>
                <tr>
                  {trArray.map((prop) => (
                    <th key={prop.id}>{prop.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rank_enabled
                  ? ranks.map((rank) => (
                      <MembersCardRankRow
                        key={rank.id}
                        rank={rank}
                        unit={needValueObj.unit}
                        needValueVisible={Boolean(needValueObj.label)}
                        onClick={() => onClick(rank.id)}
                      />
                    ))
                  : ranks
                      .filter((rank) => rank.is_default_rank)
                      .map((rank) => (
                        <MembersCardRankRow
                          key={rank.id}
                          rank={rank}
                          unit={needValueObj.unit}
                          needValueVisible={Boolean(needValueObj.label)}
                          onClick={() => onClick(rank.id)}
                        />
                      ))}
              </tbody>
            </Table>
          }
        />
      </Col>
    </Row>
  )
}

export default MembersCardRankTable

const statusesStyle = {
  open: 'active',
  close: 'closed',
}

interface RowProps {
  rank: MembersCardRankInterface
  unit: string
  needValueVisible: boolean
  onClick: () => void
}

const MembersCardRankRow: React.FC<RowProps> = ({
  rank,
  unit,
  needValueVisible,
  onClick,
}) => (
  <tr
    key={rank.id}
    style={{ fontWeight: 'bold', cursor: 'pointer' }}
    onClick={onClick}>
    <td>
      <img
        alt='会員カードプレビュー画像'
        src={MemberCardRankImages[rank.color]}
        width={60}
        height={30}
        style={{
          borderRadius: 2,
          objectFit: 'contain',
        }}
      />
    </td>
    <td>
      {rank.name}（{rank.display_name}）
      {rank.is_default_rank && (
        <>
          <br />
          <span
            style={{
              background: 'gray',
              color: 'white',
              fontWeight: 'bold',
              padding: '3px 5px',
              fontSize: 11,
              borderRadius: '0.25em',
            }}>
            初期ランク
          </span>
        </>
      )}
    </td>
    {needValueVisible && (
      <td>{rank.need_value ? String(rank.need_value) + unit : '---'}</td>
    )}
    <td
      style={{
        fontWeight: 'bold',
        fontSize: 12,
        color: 'white',
        padding: 5,
      }}>
      <div className='statusWrapper'>
        <div className={statusesStyle[rank.status]}>
          {MembersCardRankStatusLabel[rank.status]}
        </div>
      </div>
    </td>
  </tr>
)
