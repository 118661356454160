import React from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { TodoItem, TodoButton } from '../../../types/todo'
import { useBooleanState } from '../../../lib/hooks'

type Props = {
  todoItem: TodoItem
  isDesktop: boolean
  onClickButton: (button: TodoButton) => void
}

const TodoListItem: React.FC<Props> = ({
  todoItem,
  onClickButton,
  isDesktop,
}) => {
  const [expanded, , , toggleExpanded] = useBooleanState(false)

  return (
    <StyledAccordion
      expanded={expanded}
      onChange={toggleExpanded}
      disableGutters>
      <AccordionSummary
        expandIcon={<Chevron className='ri-arrow-right-s-line' />}>
        <StyledCheckIcon
          className='ri-checkbox-circle-fill'
          done={todoItem.done}
        />
        <Box fontWeight={700} ml={0.5}>
          {todoItem.title}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <Box width={isDesktop ? '50%' : '100%'} margin='0 auto'>
            <img
              src={todoItem.image_url}
              alt={todoItem.title}
              style={{ width: '100%' }}
            />
          </Box>

          <Box mt={2} fontSize={16}>
            {todoItem.description}
          </Box>

          {todoItem.notes && (
            <Box color='#757575' mt={2}>
              {todoItem.notes}
            </Box>
          )}

          <Box
            mt={2}
            display='flex'
            flexDirection='column'
            alignItems='center'
            gap={1}>
            {todoItem.buttons?.map((button) => (
              <Button
                key={button.value}
                variant='contained'
                color='submit'
                onClick={() => onClickButton(button)}>
                {button.text}
              </Button>
            ))}
          </Box>

          {todoItem.counter && Object.keys(todoItem.counter).length !== 0 && (
            <StyledCounterContainer>
              <div>{todoItem.counter?.label}</div>
              <StyledCounter>
                <span style={{ color: '#487ED9' }}>
                  {todoItem.counter?.currentCount}
                </span>
                /{todoItem.counter?.totalCount}
              </StyledCounter>
            </StyledCounterContainer>
          )}
        </div>
      </AccordionDetails>
    </StyledAccordion>
  )
}

const StyledAccordion = styled(Accordion)({
  '&:before': {
    backgroundColor: 'transparent',
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    alignItems: 'center',
  },
})

const Chevron = styled('i')({
  fontSize: 26,
})
const StyledCheckIcon = styled('i', {
  shouldForwardProp: (props) => props !== 'done',
})<{ done: boolean }>(({ done }) => ({
  fontSize: 34,
  lineHeight: 1,
  color: done ? '#487ED9' : '#DFDFDF',
}))

const StyledCounterContainer = styled('div')(({ theme }) => ({
  backgroundColor: '#F4F7FA',
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(1.5),
}))
const StyledCounter = styled('div')({
  display: 'flex',
  color: '#999999',
  fontWeight: 'bold',
  fontSize: 26,
})

export default TodoListItem
