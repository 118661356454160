import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Grid, Row, Col, Form } from 'react-bootstrap'
import FormInputs from '../../components/FormInputs/FormInputs'
import SelectorForm from '../../components/FormInputs/SelectorForm'
import SwitchForm from '../../components/FormInputs/SwitchForm'
import LoadingButton from '../../components/CustomButton/LoadingButton'
import { Card } from '../../components/Card/Card'
import TenantAlert from '../../components/Modal/TenantAlert'
import GuideButton from '../../components/Link/GuideLink'
import EcFeatureEntityContainer, {
  EcFeatureInterface,
} from '../../containers/entities/EcFeatureEntityContainer'
import { regexpEmail } from '../../lib/validation'
import { useNotification } from '../../providers/NotificationProvider'

const statusOptions = [
  { label: '公開', value: 'open' },
  { label: '非公開', value: 'close' },
  { label: '一時停止', value: 'pause' },
]

const EcFeatureView = (): JSX.Element => {
  const { showSuccessNotification, showErrorNotification } = useNotification()
  const ecFeatureEntityContainer = EcFeatureEntityContainer.useContainer()
  const { ecFeature } = ecFeatureEntityContainer.state
  const { updateEcFeature, getEcFeature } = ecFeatureEntityContainer.logic

  const { register, control, setValue, handleSubmit, errors, formState } =
    useForm<{
      ecFeature: EcFeatureInterface
    }>({
      defaultValues: {
        ecFeature: {
          appeal_sentence: '',
          cash_on_delivery_enabled: false,
          contact_phone_number: '',
          contact_mail_address: '',
          contact_link: '',
          earliest_shipped_day: 0,
          longest_shipped_day: 0,
          minimum_purchase_price: 50,
          shipped_business_day: 0,
          shipping_price: 0,
        },
      },
    })
  const { isDirty } = formState

  useEffect(() => {
    getEcFeature().then((feature) => {
      setValue('ecFeature', feature)
    })
  }, [getEcFeature, setValue])

  const onSubmit = (data) => {
    updateEcFeature(data.ecFeature)
      .then(() => showSuccessNotification('更新しました。'))
      .catch((error) => showErrorNotification(error.message))
  }

  const onError = () => {
    showErrorNotification('値が正しく入力されていません')
  }

  const changeStatus = (status) => {
    updateEcFeature({ status })
      .then(() => showSuccessNotification('状態を変更しました。'))
      .catch((error) => showErrorNotification(error.message))
  }

  return (
    <div className='content'>
      <Grid fluid>
        <TenantAlert functionName='オンラインショップ' />

        <Row style={{ marginBottom: 10 }}>
          <Col
            xs={12}
            lg={10}
            style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <GuideButton
              label='オンラインショップ'
              link='https://toypo.notion.site/EC-9b29998f29bf4f4e9b065cd4d333175d'
            />
          </Col>
        </Row>

        <Form onSubmit={handleSubmit(onSubmit, onError)}>
          <Row>
            <Col xs={12} lg={10}>
              <Card
                title='基本設定'
                rightContent={
                  <SelectorForm
                    value={ecFeature?.status}
                    onSelect={({ target }) => changeStatus(target.value)}
                    width={180}
                    options={statusOptions}
                  />
                }
                content={
                  <>
                    <FormInputs
                      properties={[
                        {
                          name: 'ecFeature.appeal_sentence',
                          label: 'アピール文',
                          ncol: 'col-md-12',
                          rows: '3',
                          attachment: '任意',
                          componentClass: 'textarea',
                          bsClass: 'form-control',
                          placeholder:
                            '例: 今なら全品20%割引キャンペーン中です！',
                          tooltipText:
                            'アプリのメニューページに表示されます。キャンペーンの情報などを書いてみましょう！',
                          inputRef: register,
                          validationMessage:
                            errors.ecFeature?.appeal_sentence?.message,
                        },
                      ]}
                    />

                    <Controller
                      control={control}
                      name='ecFeature.cash_on_delivery_enabled'
                      render={({ value, onChange }): JSX.Element => (
                        <SwitchForm
                          label='代引き'
                          checked={value}
                          onChange={(v: boolean): void => onChange(v)}
                        />
                      )}
                    />

                    <FormInputs
                      properties={[
                        {
                          name: 'ecFeature.minimum_purchase_price',
                          label: '最低購入金額',
                          ncol: 'col-xs-5',
                          type: 'number',
                          unit: '円',
                          bsClass: 'form-control',
                          inputRef: register({
                            required: '金額を設定してください。',
                            valueAsNumber: true,
                            min: {
                              value: 50,
                              message: '50 円以上の値を設定してください。',
                            },
                          }),
                          validationMessage:
                            errors.ecFeature?.minimum_purchase_price?.message,
                        },
                        {
                          name: 'ecFeature.shipping_price',
                          label: '送料',
                          ncol: 'col-xs-5',
                          type: 'number',
                          unit: '円',
                          bsClass: 'form-control',
                          inputRef: register({
                            required: '金額を設定してください。',
                            valueAsNumber: true,
                            min: {
                              value: 0,
                              message: '0 円以上の値を設定してください。',
                            },
                          }),
                          validationMessage:
                            errors.ecFeature?.shipping_price?.message,
                        },
                        {
                          name: 'ecFeature.shipped_business_day',
                          label: '出荷までの営業日',
                          ncol: 'col-xs-5',
                          type: 'number',
                          unit: '日',
                          bsClass: 'form-control',
                          inputRef: register({
                            required: '日数を指定してください',
                            valueAsNumber: true,
                            min: {
                              value: 0,
                              message: '0 日以上の日数を設定してください。',
                            },
                            max: {
                              value: 99,
                              message: '100 日未満の日数を指定してください。',
                            },
                          }),
                          validationMessage:
                            errors.ecFeature?.shipped_business_day?.message,
                        },
                        {
                          name: 'ecFeature.earliest_shipped_day',
                          label: '出荷から到着までの最短所要日数',
                          ncol: 'col-xs-5',
                          type: 'number',
                          unit: '日',
                          bsClass: 'form-control',
                          inputRef: register({
                            required: '日数を指定してください',
                            valueAsNumber: true,
                            min: {
                              value: 0,
                              message: '0 日以上の日数を設定してください。',
                            },
                            max: {
                              value: 99,
                              message: '100 日未満の日数を指定してください。',
                            },
                          }),
                          validationMessage:
                            errors.ecFeature?.earliest_shipped_day?.message,
                        },
                        {
                          name: 'ecFeature.longest_shipped_day',
                          label: '出荷から到着までの最長所要日数',
                          ncol: 'col-xs-5',
                          type: 'number',
                          unit: '日',
                          bsClass: 'form-control',
                          inputRef: register({
                            required: '日数を指定してください',
                            valueAsNumber: true,
                            min: {
                              value: 0,
                              message: '0 日以上の日数を設定してください。',
                            },
                            max: {
                              value: 99,
                              message: '100 日未満の日数を指定してください。',
                            },
                          }),
                          validationMessage:
                            errors.ecFeature?.longest_shipped_day?.message,
                        },
                      ]}
                    />

                    <FormInputs
                      properties={[
                        {
                          name: 'ecFeature.contact_phone_number',
                          label: 'お問い合わせ先（電話）',
                          attachment: '任意',
                          ncol: 'col-md-8',
                          bsClass: 'form-control',
                          inputRef: register,
                          validationMessage:
                            errors.ecFeature?.contact_phone_number?.message,
                        },
                        {
                          name: 'ecFeature.contact_mail_address',
                          label: 'お問い合わせ先（メールアドレス）',
                          attachment: '任意',
                          ncol: 'col-md-8',
                          bsClass: 'form-control',
                          inputRef: register({
                            pattern: {
                              value: regexpEmail,
                              message:
                                '正しい形式のメールアドレスを入力してください。',
                            },
                          }),
                          validationMessage:
                            errors.ecFeature?.contact_mail_address?.message,
                        },
                        {
                          name: 'ecFeature.contact_link',
                          label: 'お問い合わせ先（リンク）',
                          attachment: '任意',
                          ncol: 'col-md-8',
                          bsClass: 'form-control',
                          inputRef: register,
                          validationMessage:
                            errors.ecFeature?.contact_link?.message,
                        },
                      ]}
                    />
                  </>
                }
              />
            </Col>
          </Row>

          <Row style={{ marginBottom: 80 }}>
            <Col xs={6} lg={6} xsOffset={3} lgOffset={2}>
              <LoadingButton
                type='submit'
                label='更新する'
                loadingLabel='更新中...'
                color='info'
                fill
                block
                disabled={!isDirty}
              />
            </Col>
          </Row>
        </Form>
      </Grid>
    </div>
  )
}

export default EcFeatureView
