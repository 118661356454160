/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect } from 'react'
import { Pagination } from '@mui/material'
import { Row, Col, Table } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { Card } from '../../../components/Card/Card'
import Button from '../../../components/CustomButton/CustomButton'
import OptionButton from '../../../components/CustomButton/OptionButton'
import { useApi, usePcSizeFlag } from '../../../lib/hooks'
import { ServiceContentsResponse } from '../../../types/api/service.d'
import { ServiceStatusLabel } from '../../../constants/service'
import { ServiceContentResource } from '../../../types/resource/serviceContent.d'
import { useNotification } from '../../../providers/NotificationProvider'

const StyledServiceContentStatus = styled.td`
  font-weight: bold;
  font-size: 12px;
  color: white;
  cursor: pointer;
  width: 10%;
  padding: 5px;
`

type PropsType = { rankId: number }

const statusesStyle = {
  draft: 'draft',
  open: 'active',
  close: 'closed',
}

type ServiceContentsProps = {
  page?: number
}

const ServiceListTable: React.FC<PropsType> = ({ rankId }) => {
  const history = useHistory()
  const isPcOrTablet = usePcSizeFlag()
  const PAGE_LIMIT = isPcOrTablet ? 20 : 4
  const { showSuccessNotification, showErrorNotification } = useNotification()

  const {
    api: serviceContentsGetApi,
    response: serviceContentsResponse,
    headers: serviceContentsResHeaders,
  } = useApi<ServiceContentsResponse>()

  const serviceContents = serviceContentsResponse?.service_contents || []

  const getServiceContents = useCallback(
    async (params: ServiceContentsProps = {}) => {
      const page = params.page || 1

      await serviceContentsGetApi.get(
        `/members_card_contents/members_card_ranks/${rankId}/service_contents`,
        {
          page: page,
          limit: PAGE_LIMIT,
        },
      )
    },
    [serviceContentsGetApi, rankId, PAGE_LIMIT],
  )

  useEffect(() => {
    getServiceContents()
  }, [getServiceContents])

  const { api: serviceContentDeleteApi } = useApi()

  const deleteServiceContent = async (id): Promise<void> => {
    if (!window.confirm('サービスを削除しますか？')) {
      return
    }

    serviceContentDeleteApi
      .delete(`/service_contents/${id}`)
      .then(() => {
        showSuccessNotification('サービスを削除しました。')
        getServiceContents({ page: serviceContentsResHeaders['current-page'] })
      })
      .catch((error) => {
        showErrorNotification(error.message)
      })
  }

  const buttonOptions = (service: ServiceContentResource) => [
    {
      label: '詳細を見る',
      onClick: (): void =>
        history.push('/admin/memberscard/service/edit', {
          rankId: rankId,
          service: service,
        }),
    },
    {
      label: '削除する',
      labelColor: '#FF4A55',
      itemStyle: {
        display: [service.status === 'draft' ? 'block' : 'none'],
      },
      onClick: () => {
        deleteServiceContent(service.id)
      },
    },
  ]

  return (
    <div>
      <Row style={{ marginBottom: 10 }}>
        <Col xs={12} lg={10}>
          <Button
            pullRight
            bsStyle='info'
            fill
            onClick={(): void => {
              history.push('/admin/memberscard/service/new', { rankId: rankId })
            }}>
            <b>サービスを作成する</b>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={10}>
          <Card
            title='サービス一覧'
            content={
              <>
                <Table striped hover>
                  {serviceContents.length ? (
                    <>
                      {isPcOrTablet && (
                        <thead>
                          <tr>
                            <th> </th>
                            <th>サービス名</th>
                            <th>配信</th>
                            <th>利用</th>
                            <th>状態</th>
                          </tr>
                        </thead>
                      )}

                      <tbody>
                        {serviceContents.map((item) => (
                          <tr
                            key={item.service_content.id}
                            style={{ fontWeight: 'bold', cursor: 'pointer' }}
                            onClick={(): void =>
                              history.push('/admin/memberscard/service/edit', {
                                rankId: rankId,
                                service: item.service_content,
                              })
                            }>
                            {isPcOrTablet ? (
                              <>
                                <td style={{ width: 80 }}>
                                  {item.service_content.image_url && (
                                    <img
                                      key={item.service_content.id}
                                      alt='サービス画像'
                                      src={item.service_content.image_url}
                                      width={48}
                                      height={30}
                                      style={{
                                        borderRadius: 2,
                                        objectFit: 'cover',
                                      }}
                                    />
                                  )}
                                </td>
                                <td>{item.service_content.title}</td>
                                <td>
                                  {item.service_content.status === 'draft'
                                    ? '---'
                                    : item.delivered_count}
                                </td>
                                <td>
                                  {item.service_content.status === 'draft'
                                    ? '---'
                                    : item.total_used_count}
                                </td>
                                <StyledServiceContentStatus>
                                  <div className='statusWrapper'>
                                    <div
                                      className={
                                        statusesStyle[
                                          item.service_content.status
                                        ]
                                      }>
                                      {
                                        ServiceStatusLabel[
                                          item.service_content.status
                                        ]
                                      }
                                    </div>
                                  </div>
                                </StyledServiceContentStatus>
                                <td
                                  onClick={(e) => {
                                    e.stopPropagation()
                                  }}>
                                  <OptionButton
                                    options={buttonOptions(
                                      item.service_content,
                                    )}
                                  />
                                </td>
                              </>
                            ) : (
                              <>
                                <td style={{ width: 80 }}>
                                  {item.service_content.image_url && (
                                    <img
                                      key={item.service_content.id}
                                      alt='サービス画像'
                                      src={item.service_content.image_url}
                                      width={48}
                                      height={30}
                                      style={{
                                        borderRadius: 2,
                                        objectFit: 'cover',
                                      }}
                                    />
                                  )}
                                </td>
                                <td>{item.service_content.title}</td>
                                <td
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                    color: 'white',
                                    cursor: 'pointer',
                                    width: '20%',
                                    padding: 5,
                                  }}>
                                  <div className='statusWrapper'>
                                    <div
                                      className={
                                        statusesStyle[
                                          item.service_content.status
                                        ]
                                      }>
                                      {
                                        ServiceStatusLabel[
                                          item.service_content.status
                                        ]
                                      }
                                    </div>
                                  </div>
                                </td>
                              </>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </>
                  ) : (
                    <thead style={{ textAlign: 'center' }}>
                      <tr>
                        <td>
                          <b>表示できるサービスがありません</b>
                        </td>
                      </tr>
                    </thead>
                  )}
                </Table>
              </>
            }
            stats={
              serviceContentsResHeaders &&
              Number(serviceContentsResHeaders['total-pages']) > 1 ? (
                <Pagination
                  count={Number(serviceContentsResHeaders['total-pages'])}
                  page={Number(serviceContentsResHeaders['current-page'])}
                  onChange={(_, value) => getServiceContents({ page: value })}
                  sx={{ fontSize: 24, color: 'black' }}
                />
              ) : null
            }
          />
        </Col>
      </Row>
    </div>
  )
}

export default ServiceListTable
