import React, { useState, useEffect, useCallback } from 'react'
import { Pagination } from '@mui/material'
import { usePcSizeFlag, useBooleanState } from '../../../lib/hooks'
import { Card } from '../../../components/Card/Card'
import SpecialOpenTimeList from './SpecialOpenTimeList'
import OpenTimeModal from './OpenTimeModal'
import OpenTimeEntityContainer, {
  SpecialOpenTimeItem,
} from '../../../containers/entities/OpenTimeEntityContainer'
import { useNotification } from '../../../providers/NotificationProvider'

const SpecialOpenTimeView = () => {
  const isPcOrTablet = usePcSizeFlag()
  const { showSuccessNotification, showErrorNotification } = useNotification()
  const openTImeContainer = OpenTimeEntityContainer.useContainer()
  const { specialOpenTimes } = openTImeContainer.state
  const { getSpecialOpenTime, deleteSpecialOpenTime } = openTImeContainer.logic

  const [currentPage, setCurrentPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)

  const [isOpenTimeModalOpen, openOpenTimeModalOpen, closeOpenTimeModalOpen] =
    useBooleanState(false)
  const [updateOpenTime, setUpdateOpenTime] = useState<
    SpecialOpenTimeItem | {}
  >({})

  const getSpecialOpenTimeData = useCallback(
    (page = currentPage) => {
      getSpecialOpenTime({ page })
        .then((response) => {
          setTotalPage(Number(response.headers['total-pages']))
        })
        .catch((error) => {
          showErrorNotification(error.message)
        })
    },
    [currentPage, showErrorNotification, getSpecialOpenTime],
  )

  useEffect(() => {
    getSpecialOpenTimeData()
  }, [getSpecialOpenTimeData])

  const onClickDeleteIcon = (id: number) => {
    if (!window.confirm('この特別営業時間を削除します。よろしいですか？')) {
      return
    }
    deleteSpecialOpenTime(id)
      .then(() => {
        setCurrentPage(1)
        getSpecialOpenTimeData()
        showSuccessNotification('特別営業時間を削除しました。')
      })
      .catch((error) => {
        showErrorNotification(error.message)
      })
  }

  const onClickEditIcon = (specialOpenTime: SpecialOpenTimeItem) => {
    setUpdateOpenTime(specialOpenTime)
    openOpenTimeModalOpen()
  }

  return (
    <>
      <Card
        title='特別営業時間'
        content={
          <div>
            <p style={{ fontSize: isPcOrTablet ? '16px' : '14px' }}>
              通常営業時間と異なる日がある場合に設定できます。カレンダーの表示はこちらが優先されます。
            </p>

            <div style={{ marginBottom: '10px' }}>
              <button
                type='button'
                style={{
                  color: '#1DC7EA',
                  fontWeight: 'bold',
                  border: 'none',
                  backgroundColor: 'initial',
                }}
                onClick={openOpenTimeModalOpen}>
                ＋ 特別営業時間を追加
              </button>
            </div>

            <div
              style={{
                width: isPcOrTablet ? '70%' : '100%',
                display: 'grid',
                rowGap: '10px',
              }}>
              <SpecialOpenTimeList
                specialOpenTimes={specialOpenTimes}
                onClickEditIcon={onClickEditIcon}
                onClickDeleteIcon={onClickDeleteIcon}
              />

              <Pagination
                count={totalPage}
                page={currentPage}
                onChange={(_, value) => setCurrentPage(value)}
                sx={{ fontSize: 24, color: 'black', margin: '0 auto' }}
              />
            </div>
          </div>
        }
      />

      <OpenTimeModal
        show={isOpenTimeModalOpen}
        updateOpenTime={updateOpenTime}
        onCreateOpenTime={() => {
          getSpecialOpenTimeData(1)
          setCurrentPage(1)
        }}
        onHide={() => {
          closeOpenTimeModalOpen()
          setUpdateOpenTime({})
        }}
      />
    </>
  )
}

export default SpecialOpenTimeView
