import React from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import EventView from './_part/EventView'
import DefaultOpenTimeView from '../Store/_parts/DefaultOpenTimeView'
import SpecialOpenTimeView from '../Store/_parts/SpecialOpenTimeView'
import GuideButton from '../../components/Link/GuideLink'
import { isViewObjStore } from '../../lib/viewObj'

const CalendarListView = () => {
  return (
    <div className='content'>
      <Grid fluid>
        <Row style={{ marginBottom: 10 }}>
          <Col md={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <GuideButton
              label='カレンダー'
              link='https://toypo.notion.site/d61e6117689d47bc8cf8898bf6d0aab1'
            />
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <EventView />
            {isViewObjStore() && (
              <>
                <DefaultOpenTimeView />
                <SpecialOpenTimeView />
              </>
            )}
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export default CalendarListView
