import React, {
  useRef,
  useReducer,
  useState,
  useCallback,
  useEffect,
} from 'react'
import moment from 'moment'
import { useLocation, useHistory } from 'react-router-dom'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Pagination from '@mui/material/Pagination'
import CircularProgress from '@mui/material/CircularProgress'
import { useApi, useQuery } from '../../lib/hooks'
import {
  AnalyticsReportsResponse,
  AnalyticsReportCategory,
} from '../../types/analyticsReport'
import {
  CategoryColorMap,
  CategoryLabel,
} from '../../constants/analyticsReport'
import AnalyticsReportCategoryIcon from '../../components/AnalyticsReportCategoryIcon'

const AnalyticsReportView = () => {
  const reportApi = useAnalyticsReportApi()
  const iframeHeightMapRef = useRef({})
  const [, rerender] = useReducer((s) => s + 1, 0)

  useEffect(() => {
    // 画面ロードが完了した時点でコンテンツの高さを取得して
    // それをWindowオブジェクトのメッセージとして送信するような処理を
    // Super.so側に仕込んでいます。
    // このイベントハンドラはそのメッセージを受信するもので
    // Super.so側からメッセージ送信元のURLと画面の高さが送られてきます。
    const handleMessage = (e: MessageEvent) => {
      if (e.origin !== 'https://csreporting.super.site') {
        return
      }

      iframeHeightMapRef.current = {
        ...iframeHeightMapRef.current,
        [e.data.url]: e.data.height,
      }
      rerender()
    }
    window.addEventListener('message', handleMessage)

    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [rerender])

  return reportApi.loaded ? (
    <Container maxWidth='lg' style={{ padding: 0 }}>
      {reportApi.reports.length === 0 && (
        <StyledEmptyContainer>
          <i className='ri-file-list-line' style={{ fontSize: 46 }} />
          まだレポートがありません
        </StyledEmptyContainer>
      )}

      {reportApi.reports.map(({ report }) => (
        <StyledReportContainer key={report.id}>
          <StyledReportHeader category={report.category}>
            <AnalyticsReportCategoryIcon category={report.category} />
            {CategoryLabel[report.category]}
          </StyledReportHeader>

          <StyledReportTitle>{report.title}</StyledReportTitle>

          <Box color='#555' fontSize={14}>
            {moment(report.published_at).format('YYYY/MM/DD(dddd)')}
          </Box>

          <iframe
            title='自動分析結果'
            style={{
              width: '100%',
              height:
                (iframeHeightMapRef.current[report.report_url] || 300) + 10,
              border: 'solid 1px #eee',
              flexGrow: 1,
              marginTop: 8,
            }}
            src={report.report_url}
          />
        </StyledReportContainer>
      ))}

      {reportApi.totalPages > 1 && (
        <Pagination
          count={reportApi.totalPages}
          page={reportApi.queryParams.page}
          onChange={(_, page: number) => {
            reportApi.changeUrlAndFetchAnalyticsReports({ page })
          }}
        />
      )}
    </Container>
  ) : (
    <Container maxWidth='lg'>
      <CircularProgress />
    </Container>
  )
}

type QueryParams = {
  page: number
}

const useAnalyticsReportApi = () => {
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()
  const { api, response, loaded, totalPages, currentPage } =
    useApi<AnalyticsReportsResponse>()
  const [queryParams, setQueryParams] = useState<QueryParams>({
    page: Number(query.get('page')) || 1,
  })

  const changeUrl = useCallback(
    (params: QueryParams) => {
      setQueryParams(params)

      const newUrl = `${location.pathname}?page=${params.page}`
      history.replace(newUrl)
    },
    [history, location.pathname],
  )

  useEffect(() => {
    api.get('/analytics_reports', { ...queryParams, limit: 5 })
  }, [api, queryParams])

  return {
    changeUrlAndFetchAnalyticsReports: changeUrl,
    reports: response?.reports || [],
    loaded,
    totalPages,
    currentPage,
    queryParams,
  }
}

const StyledEmptyContainer = styled('div')(({ theme }) => ({
  fontSize: 18,
  color: '#666',
  textAlign: 'center',
  marginTop: theme.spacing(10),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledReportContainer = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  [theme.breakpoints.down('laptop')]: {
    margin: theme.spacing(2, 0),
    borderRadius: 0,
    borderWidth: 0,
  },
}))

const StyledReportHeader = styled('div')<{ category: AnalyticsReportCategory }>(
  ({ theme, category }) => ({
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    gap: theme.spacing(1),
    color: CategoryColorMap[category],
  }),
)

const StyledReportTitle = styled('div')({
  fontSize: 20,
  fontWeight: 700,
})

export default AnalyticsReportView
