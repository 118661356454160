import React, { useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

const OptionButton = ({ options, ...otherProps }) => {
  const [anchorEl, setAnchorEl] = useState(null)

  return (
    <>
      <div
        className='editStatus'
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
          e.stopPropagation()
        }}
        {...otherProps}>
        <i
          className='fas fa-ellipsis-h'
          style={{
            padding: 5,
            color: '#474747',
          }}
        />
      </div>
      <OptionPopUp
        anchorEl={anchorEl}
        options={options}
        close={() => setAnchorEl(null)}
      />
    </>
  )
}

const OptionPopUp = ({ anchorEl, options, close }) => (
  <Menu
    id='simple-menu'
    anchorEl={anchorEl}
    keepMounted
    open={Boolean(anchorEl)}
    onClose={close}>
    {options.map(
      ({ itemStyle, onClick, labelColor, label, ...otherProps }, i) => (
        <MenuItem
          style={itemStyle}
          key={`option-popup-menu-${i}`}
          onClick={(e) => {
            onClick()
            close()
            e.stopPropagation()
          }}
          {...otherProps}>
          <b style={{ fontSize: 12, color: labelColor || '#000' }}>{label}</b>
        </MenuItem>
      ),
    )}
  </Menu>
)

export default OptionButton
