import React from 'react'

import { Grid, Row, Col } from 'react-bootstrap'
import { withRouter } from 'react-router'
import Button from '../components/CustomButton/CustomButton'
import { Card } from '../components/Card/Card'

import { formatDate } from '../lib/general'
import { PaymentMethodLabels } from '../constants/charge'

const convertChargeProductsToCellValue = (chargeProducts) =>
  chargeProducts.map(
    (cp) => `${cp.title}(${cp.price.toLocaleString()}円) x ${cp.quantity}`,
  )

const ChargeDetailView = ({ location, history }) => {
  const { charge } = location.state

  const dataList = [
    { lable: '決済ID', value: charge.id },
    { lable: 'ユーザー名', value: charge.user_name },
    { lable: '決済額', value: `${charge.amount.toLocaleString()}円` },
    {
      lable: '決済方法',
      value: PaymentMethodLabels[charge.payment_method],
    },
    {
      lable: '内容',
      values: convertChargeProductsToCellValue(charge.charge_products),
    },
    { lable: '備考', value: charge.description || 'なし' },
    { lable: '決済日', value: formatDate(charge.created_at) },
  ]

  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <Col md={10}>
            <Card
              title='決済詳細'
              content={
                <div style={{ fontWeight: 'bold', fontSize: 17 }}>
                  {dataList.map((data, i) => (
                    <Row key={`charge-value-${i}`}>
                      <Col xs={4}>
                        <p>{data.lable}</p>
                      </Col>
                      {data.value ? (
                        <Col xs={8}>{data.value}</Col>
                      ) : (
                        <Col xs={8}>
                          {data.values?.map((v, j) => (
                            <div
                              key={`charge-product-${j}`}
                              style={{ marginBottom: 5 }}>
                              {v}
                            </div>
                          ))}
                        </Col>
                      )}
                    </Row>
                  ))}
                </div>
              }
            />
          </Col>
        </Row>
      </Grid>

      <Row style={{ marginBottom: 20 }}>
        <Col xs={6} lg={6} xsOffset={3} lgOffset={2}>
          <Button fill block onClick={history.goBack}>
            <b>戻る</b>
          </Button>
        </Col>
      </Row>
    </div>
  )
}

export default withRouter(ChargeDetailView)
