import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { CircularProgress, Pagination } from '@mui/material'
import moment from 'moment'
import { User } from 'phosphor-react'
import { RankingResource } from '../../../types/ranking'
import { useGetApiCall, useMobileFlag } from '../../../lib/hooks'
import { DEFAULT_AVATAR } from '../../../lib/const'
import UserIcon from '../../Customer/parts/UserIcon'
import { RankingCategoryLabels } from '../../../constants/ranking'
import NoItemContent from '../../../components/Utils/NoItemContent'
import { formatRelativeDate } from '../../../lib/general'

type Props = {
  ranking: RankingResource
}

const RankingUserListView = ({ ranking }: Props): JSX.Element => {
  const isMobile = useMobileFlag()
  const history = useHistory()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const perPage = ranking.per_page || 100
  const url = `/rankings/${ranking.id}/users?page=${currentPage}&per_page=${perPage}`
  const { response, loading } = useGetApiCall(url)
  const users = response?.users || []
  const countUsers = response?.count || 0
  const totalPages = Math.ceil(countUsers / perPage)

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Paper
      square={isMobile}
      variant={isMobile ? 'elevation' : 'outlined'}
      sx={{ m: isMobile ? 0 : 1 }}>
      {isMobile ? (
        <Table>
          {users.map((user) => (
            <TableRow
              key={user.user_id}
              hover
              onClick={() =>
                history.push(`/admin/customers/detail?user_id=${user.user_id}`)
              }>
              <TableCell
                style={{ display: 'flex', gap: 16, alignItems: 'flex-start' }}>
                <UserIcon
                  avatar={user.avatar_url || DEFAULT_AVATAR[user.sex]}
                  rankColor={user.rank_color}
                />
                <div>
                  <div style={{ fontWeight: 'bold' }}>{user.display_name}</div>
                  <div
                    style={{
                      fontWeight: 'normal',
                      color: '#9a9a9a',
                      fontSize: 12,
                      display: 'flex',
                    }}>
                    {Boolean(user.members_card_rank) && (
                      <div>{user.members_card_rank}・</div>
                    )}
                    {Boolean(user.code) && <div>{user.code}</div>}
                  </div>
                  <div style={{ color: '#7d7d7d' }}>
                    ランキング {user.rank}位
                  </div>
                  <div style={{ color: '#7d7d7d' }}>
                    {RankingCategoryLabels[ranking.category]} {user.score}
                  </div>
                  <div style={{ color: '#7d7d7d' }}>
                    最終来店日{' '}
                    {user.last_visit
                      ? formatRelativeDate(user.last_visit)
                      : '---'}
                  </div>
                  <div style={{ color: '#7d7d7d' }}>
                    登録日 {moment(user.created_at).format('YYYY/M/D')}
                  </div>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>氏名</TableCell>
              <TableCell width={100}>ランキング</TableCell>
              <TableCell width={200}>計測対象</TableCell>
              <TableCell width={200}>
                {RankingCategoryLabels[ranking.category]}
              </TableCell>
              <TableCell width={100}>最終来店日</TableCell>
              <TableCell width={100}>登録日</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <StyledBodyRow
                key={user.user_id}
                hover
                onClick={() =>
                  history.push(
                    `/admin/customers/detail?user_id=${user.user_id}`,
                  )
                }>
                <TableCell>
                  <StyledUserContainer>
                    <Box display='flex' alignItems='center' gap={1}>
                      <UserIcon
                        iconSize={35}
                        badgeSize={10}
                        avatar={user.avatar_url || DEFAULT_AVATAR[user.sex]}
                        rankColor={user.rank_color}
                      />
                      <div>
                        <StyledUser>{user.display_name}</StyledUser>
                        {user.members_card_rank && (
                          <StyledRankName>
                            {user.members_card_rank}・{user.code}
                          </StyledRankName>
                        )}
                      </div>
                    </Box>
                  </StyledUserContainer>
                </TableCell>
                <TableCell>{user.rank}位</TableCell>
                <TableCell>{RankingCategoryLabels[ranking.category]}</TableCell>
                <TableCell>{user.score}</TableCell>
                <TableCell>
                  {user.last_visit
                    ? formatRelativeDate(user.last_visit)
                    : '---'}
                </TableCell>
                <TableCell>
                  {moment(user.created_at).format('YYYY/M/D')}
                </TableCell>
              </StyledBodyRow>
            ))}
          </TableBody>
        </Table>
      )}
      {users.length === 0 && (
        <NoItemContent icon={<User />} label='参加者がいません' />
      )}
      {totalPages > 1 && (
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={(_, page) => setCurrentPage(page)}
          sx={{ fontSize: 24, color: 'black' }}
        />
      )}
    </Paper>
  )
}

const StyledUserContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
})

const StyledUser = styled('div')({
  fontWeight: 'bold',
})

const StyledRankName = styled('div')(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.cancel.main,
}))

const StyledBodyRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '& > td': {
    fontSize: 13,
    fontWeight: 700,
    padding: theme.spacing(3.5, 1),
  },
}))

export default RankingUserListView
