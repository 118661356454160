import { AxiosResponse } from 'axios'
import apiClient from './apiClient'
import { loadViewObj } from '../viewObj'
import {
  DefaultOpenTimeItem,
  SpecialOpenTimeItem,
  UpdateSpecialOpenTimeItem,
} from '../../containers/entities/OpenTimeEntityContainer'

export const getDefaultOpenTime = async (): Promise<DefaultOpenTimeItem[]> => {
  const GET_DEFAULT_OPEN_TIME = `/staff/stores/${
    loadViewObj()?.id
  }/open_times?type=default`

  const response: AxiosResponse<DefaultOpenTimeItem[]> = await apiClient({
    method: 'GET',
    uri: GET_DEFAULT_OPEN_TIME,
  })
  return response.data
}

export const getSpecialOpenTime = async ({
  page = 1,
}): Promise<AxiosResponse<SpecialOpenTimeItem[]>> => {
  const GET_SPECIAL_OPEN_TIME = `/staff/stores/${
    loadViewObj()?.id
  }/open_times?type=special&page=${page}`

  const response: AxiosResponse<SpecialOpenTimeItem[]> = await apiClient({
    method: 'GET',
    uri: GET_SPECIAL_OPEN_TIME,
  })
  return response
}

export const updateDefaultOpenTime = async (
  data: DefaultOpenTimeItem[],
): Promise<DefaultOpenTimeItem[]> => {
  const UPDATE_DEFAULT_OPEN_TIME = `/staff/stores/${
    loadViewObj()?.id
  }/open_times`

  const requestParams = {
    open_time: data,
  }

  const response: AxiosResponse<DefaultOpenTimeItem[]> = await apiClient({
    method: 'PATCH',
    uri: UPDATE_DEFAULT_OPEN_TIME,
    params: requestParams,
  })
  return response.data
}

export const createSpecialOpenTime = async (
  data: UpdateSpecialOpenTimeItem,
): Promise<SpecialOpenTimeItem[]> => {
  const CREATE_SPECIAL_OPEN_TIME = `/staff/stores/${
    loadViewObj()?.id
  }/open_times`

  const requestParams = {
    open_time: data,
  }

  const response: AxiosResponse<SpecialOpenTimeItem[]> = await apiClient({
    method: 'POST',
    uri: CREATE_SPECIAL_OPEN_TIME,
    params: requestParams,
  })
  return response.data
}

export const updateSpecialOpenTime = async (
  data: UpdateSpecialOpenTimeItem,
): Promise<SpecialOpenTimeItem[]> => {
  const UPDATE_SPECIAL_OPEN_TIME = `/staff/stores/${
    loadViewObj()?.id
  }/open_times?id=${data.id}`

  const requestParams = {
    open_time: data,
  }

  const response: AxiosResponse<SpecialOpenTimeItem[]> = await apiClient({
    method: 'PATCH',
    uri: UPDATE_SPECIAL_OPEN_TIME,
    params: requestParams,
  })
  return response.data
}

export const deleteDefaultOpenTime = async ({
  openTimeId,
}): Promise<DefaultOpenTimeItem[]> => {
  const DELETE_DEFAULT_OPEN_TIME = `/staff/stores/${
    loadViewObj()?.id
  }/open_times?id=${openTimeId}`

  const response: AxiosResponse<DefaultOpenTimeItem[]> = await apiClient({
    method: 'DELETE',
    uri: DELETE_DEFAULT_OPEN_TIME,
  })
  return response.data
}

export const deleteSpecialOpenTime = async ({
  openTimeId,
}): Promise<SpecialOpenTimeItem[]> => {
  const DELETE_SPECIAL_OPEN_TIME = `/staff/stores/${
    loadViewObj()?.id
  }/open_times?id=${openTimeId}`

  const response: AxiosResponse<SpecialOpenTimeItem[]> = await apiClient({
    method: 'DELETE',
    uri: DELETE_SPECIAL_OPEN_TIME,
  })
  return response.data
}
