import React from 'react'
import { HelpBlock } from 'react-bootstrap'
import ImageTrimmingInput from '../ImageInput/ImageTrimmingInput'
import ChangeIndicator from '../ChangeIndicator'
import FormLabel from './FormLabel'
import FormGroup from './FormGroup'

const ImageForm = ({
  height = 175,
  maxHeight = null,
  width = 280,
  maxWidth = null,
  aspect = 16 / 9,
  doCompress = true,
  doTrimming = true,
  label = '',
  attachment = '',
  tooltipText = '',
  value,
  onChange,
  canDelete = false,
  deleteImage = () => {},
  validationMessage,
  showChangeIndicator = false,
}) => (
  <FormGroup>
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <FormLabel
        label={label}
        tooltipText={tooltipText}
        attachment={attachment}
      />

      {showChangeIndicator && (
        <ChangeIndicator style={{ margin: '0 0 8px 3px' }} />
      )}
    </div>

    <ImageTrimmingInput
      height={height}
      maxHeight={maxHeight}
      width={width}
      maxWidth={maxWidth}
      aspect={aspect}
      doCompress={doCompress}
      doTrimming={doTrimming}
      altMessage={label}
      image={value}
      doDisplayImage={!!value}
      updateImage={(img) => {
        onChange(img)
      }}
      canDelete={canDelete}
      deleteImage={deleteImage}
    />
    <HelpBlock className='text-danger'>{validationMessage}</HelpBlock>
  </FormGroup>
)

export default ImageForm
