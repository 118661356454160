import React, { useState } from 'react'
import { ArrayField, Controller, useFormContext } from 'react-hook-form'

import { HelpBlock } from 'react-bootstrap'
import { usePcSizeFlag } from '../../../lib/hooks'
import Checkbox from '../../../components/CustomCheckbox/CustomCheckbox'
import TimeSelectBox from '../../../components/FormInputs/TimeSelectBox'
import FormInput from '../../../components/FormInputs/FormInput'
import FormGroup from '../../../components/FormInputs/FormGroup'
import SwitchForm from '../../../components/FormInputs/SwitchForm'
import { DefaultOpenTimeItem } from '../../../containers/entities/OpenTimeEntityContainer'

const dayToJp = {
  0: '日曜日',
  1: '月曜日',
  2: '火曜日',
  3: '水曜日',
  4: '木曜日',
  5: '金曜日',
  6: '土曜日',
  7: '祝日',
}

type Props = {
  field: Partial<ArrayField<DefaultOpenTimeItem, 'fieldId'>>
  openTimes: Partial<ArrayField<DefaultOpenTimeItem, 'fieldId'>>[]
  index: number
  onClickAddButton: ({ day, index }: { day: number; index: number }) => void
  onClickDeleteButton: (index: number) => void
  validateEndTime: (value: number, index: number) => string | true
}

const OpenTimeRow: React.FC<Props> = ({
  field,
  openTimes,
  index,
  onClickAddButton,
  onClickDeleteButton,
  validateEndTime,
}) => {
  const { watch, control, setValue, errors } = useFormContext()

  const oneItem =
    openTimes.filter((item) => item.start_day === field.start_day).length === 1
  const isFirstItemOfDay =
    oneItem ||
    openTimes.findIndex((item) => item.start_day === field.start_day) === index

  const isPcOrTablet = usePcSizeFlag()

  const watchIsHoliday = watch(`openTime[${index}].is_holiday`)
  const watchIsAllDay = watch(`openTime[${index}].is_all_day`)

  // 開始の時刻を変更時にその時刻を保持して終了時刻の最低値として渡すためのstate
  const [minHourForEndTime, setMinHourForEndTime] = useState(0)

  return (
    <div
      key={field.fieldId}
      style={{
        display: 'grid',
        gridTemplateColumns: isPcOrTablet ? '1fr 15fr' : '1fr',
        columnGap: '13px',
        rowGap: '10px',
        alignItems: 'baseline',
        minHeight: '40px',
      }}>
      {isFirstItemOfDay ? (
        <div style={{ fontWeight: 'bold' }}>
          {dayToJp[field.start_day as number]}
        </div>
      ) : (
        <div />
      )}
      {/* 登録するためだけのもの */}
      <Controller
        name={`openTime[${index}].id`}
        defaultValue={field.id}
        control={control}
        as={<div style={{ display: 'none' }} />}
      />
      <Controller
        name={`openTime[${index}].start_day`}
        defaultValue={field.start_day}
        control={control}
        as={<div style={{ display: 'none' }} />}
      />
      <Controller
        name={`openTime[${index}].end_day`}
        defaultValue={field.end_day}
        control={control}
        as={<div style={{ display: 'none' }} />}
      />

      <div
        style={{
          display: isPcOrTablet ? 'flex' : 'grid',
          gridTemplateColumns: isPcOrTablet ? 0 : '1fr',
          flexWrap: 'wrap',
          columnGap: '13px',
          rowGap: '9px',
        }}>
        <Controller
          name={`openTime[${index}].is_holiday`}
          defaultValue={field.is_holiday}
          control={control}
          render={({ value }): JSX.Element => (
            <SwitchForm
              formMarginBottom={0}
              checkedText='営業日'
              uncheckedText='定休日'
              checked={!value}
              onChange={(isChecked: boolean): void => {
                // isChecked:trueは営業日
                setValue(`openTime[${index}].is_all_day`, !isChecked, {
                  shouldDirty: true,
                })
                setValue(`openTime[${index}].is_holiday`, !isChecked, {
                  shouldDirty: true,
                })
              }}
            />
          )}
        />

        <Controller
          name={`openTime[${index}].title`}
          defaultValue={field.title}
          control={control}
          render={({ value, onChange }): JSX.Element => (
            <FormInput
              type='text'
              placeholder='営業時間名(任意)'
              formGroupStyle={{ marginBottom: 0 }}
              value={value}
              onChange={(v): void => onChange(v)}
            />
          )}
        />

        {!watchIsHoliday && (
          <>
            {!watchIsAllDay && (
              <FormGroup style={{ marginBottom: 0 }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                  }}>
                  {/* 時間 */}
                  <Controller
                    name={`openTime[${index}].start_hour`}
                    defaultValue={field.start_hour}
                    control={control}
                    rules={{
                      valueAsNumber: true,
                    }}
                    render={({ value, onChange }): JSX.Element => (
                      <TimeSelectBox
                        type='hour'
                        maxHour={24}
                        value={String(value).padStart(2, '0')}
                        onChange={(time: string): void => {
                          onChange(time)
                          setMinHourForEndTime(Number(time))
                        }}
                      />
                    )}
                  />
                  {/* 分 */}
                  <Controller
                    name={`openTime[${index}].start_minute`}
                    defaultValue={field.start_minute}
                    control={control}
                    rules={{
                      valueAsNumber: true,
                    }}
                    render={({ value, onChange }): JSX.Element => (
                      <TimeSelectBox
                        type='minute'
                        value={String(value).padStart(2, '0')}
                        onChange={(time: string): void => onChange(time)}
                        minuteIncrement={5}
                      />
                    )}
                  />
                  <div style={{ margin: '0 5px' }}>〜</div>
                  <Controller
                    name={`openTime[${index}].end_hour`}
                    defaultValue={field.end_hour}
                    control={control}
                    rules={{
                      valueAsNumber: true,
                      validate: (value): string | true =>
                        validateEndTime(value, index),
                    }}
                    render={({ value, onChange }): JSX.Element => (
                      <TimeSelectBox
                        type='hour'
                        minHour={minHourForEndTime}
                        maxHour={31}
                        value={String(value).padStart(2, '0')}
                        onChange={(time: string): void => onChange(time)}
                      />
                    )}
                  />
                  {/* 分 */}
                  <Controller
                    name={`openTime[${index}].end_minute`}
                    defaultValue={field.end_minute}
                    control={control}
                    rules={{
                      valueAsNumber: true,
                    }}
                    render={({ value, onChange }): JSX.Element => (
                      <TimeSelectBox
                        type='minute'
                        value={String(value).padStart(2, '0')}
                        onChange={(time: string): void => onChange(time)}
                        minuteIncrement={5}
                      />
                    )}
                  />
                </div>
                {errors?.openTime &&
                  errors?.openTime[index] &&
                  errors?.openTime[index].end_hour.message && (
                    <HelpBlock className='text-danger'>
                      {errors?.openTime[index].end_hour.message}
                    </HelpBlock>
                  )}
              </FormGroup>
            )}
            <FormGroup
              style={{
                marginBottom: 0,
                display: 'flex',
                alignItems: isPcOrTablet ? 'center' : 'normal',
                flexDirection: isPcOrTablet ? 'row' : 'column',
              }}>
              {oneItem && (
                <Controller
                  name={`openTime[${index}].is_all_day`}
                  defaultValue={field.is_all_day}
                  control={control}
                  render={({ value, onChange }): JSX.Element => (
                    <Checkbox
                      number={`all-day-${field.fieldId}`}
                      label='終日'
                      checked={value}
                      onChange={(): void => onChange(!value)}
                    />
                  )}
                />
              )}

              {isFirstItemOfDay && !watchIsAllDay && oneItem ? (
                <button
                  type='button'
                  style={{
                    color: '#1DC7EA',
                    fontWeight: 'bold',
                    border: 'none',
                    backgroundColor: 'initial',
                  }}
                  onClick={(): void =>
                    onClickAddButton({ day: field.start_day as number, index })
                  }>
                  ＋ 時間を追加
                </button>
              ) : (
                !isFirstItemOfDay && (
                  <button
                    type='button'
                    style={{
                      color: '#787878',
                      fontWeight: 'bold',
                      border: 'none',
                      backgroundColor: 'initial',
                    }}
                    onClick={(): void => onClickDeleteButton(index)}>
                    ×
                  </button>
                )
              )}
            </FormGroup>
          </>
        )}
      </div>
    </div>
  )
}

export default OpenTimeRow
