import React, { useEffect, useCallback, FC } from 'react'
import { Row, Col, FormGroup } from 'react-bootstrap'
import { Controller } from 'react-hook-form'

// components
import Box from '@mui/material/Box'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import SelectorForm from '../FormInputs/SelectorForm'
import { Card } from './Card'
import FormInput from '../FormInputs/FormInput'
import FormLabel from '../FormInputs/FormLabel'
import Checkbox from '../CustomCheckbox/CustomCheckbox'
import MultipleSelectorForm from '../FormInputs/MultipleSelectorForm'

// lib
import { WEEK_OF_DAYS_LABEL } from '../../lib/general'
import { DAYS, HOUR } from '../../lib/const'

const contentCategory = {
  Register: 'register',
  NoCheckIn: 'no_check_in',
  Repeat: 'repeat',
  Birthday: 'birthday',
} as const

const contentName = {
  Coupon: 'coupon',
  Notice: 'notice',
  Service: 'service',
  Ticket: 'ticket',
  Subscription: 'subscription',
} as const

const nameToJpObj = {
  [contentName.Coupon]: 'クーポン',
  [contentName.Notice]: 'お知らせ',
  [contentName.Service]: 'サービス',
  [contentName.Ticket]: 'チケット',
  [contentName.Subscription]: 'サブスク',
} as const

type Names = typeof contentName[keyof typeof contentName]

type Props = {
  name: Names
  editMode: boolean
  errors: any
  register: any
  setValue: any
  setError: any
  clearErrors: any
  categories: any
  categoryToJp: any
  category: any
  publishDates: number[]
  publishWeeks: number[]
  daysLater: number
  repeatType: 'week' | 'date'
  setRepeatType: React.Dispatch<React.SetStateAction<'date' | 'week'>>
  control: any
}

const AutoPublishFormCard: FC<Props> = ({
  name,
  editMode,
  errors,
  register,
  setValue,
  setError,
  clearErrors,
  categories,
  categoryToJp,
  category,
  publishDates,
  publishWeeks,
  daysLater,
  repeatType,
  setRepeatType,
  control,
}) => {
  const oneMonth = [...Array(30)].map((_, i) => i + 1)
  const noCheckInDays = [
    ...oneMonth,
    45,
    60,
    75,
    90,
    120,
    150,
    180,
    210,
    240,
    270,
    300,
    330,
    360,
  ]
  // 初回登録は配信タイミングに「すぐに」が存在するため、0を追加
  const registerDays = [0, ...noCheckInDays]

  const addPublishDate = ({ target }) => {
    const newPublishDates = publishDates ? [...publishDates] : []
    const date = Number(target.value)
    newPublishDates.push(date)
    // ソート
    newPublishDates.sort((a, b) => a - b)
    // 重複を削除
    const uniqItems = newPublishDates.filter(
      (x, i, self) => self.indexOf(x) === i,
    )
    setValue(`${name}.publish_dates`, uniqItems, { shouldDirty: true })
  }

  const deletePublishDate = (id) => {
    const index = publishDates.findIndex((i) => id === i)
    const newPublishDates = [...publishDates]
    newPublishDates.splice(index, 1)
    setValue(`${name}.publish_dates`, newPublishDates, { shouldDirty: true })
  }

  const addPublishWeek = ({ target }) => {
    const newPublishWeeks = publishWeeks ? [...publishWeeks] : []
    const date = Number(target.value)
    newPublishWeeks.push(date)
    // ソート
    newPublishWeeks.sort((a, b) => a - b)
    // 重複を削除
    const uniqItems = newPublishWeeks.filter(
      (x, i, self) => self.indexOf(x) === i,
    )
    setValue(`${name}.publish_weeks`, uniqItems, { shouldDirty: true })
  }

  const deletePublishWeek = (id) => {
    const index = publishWeeks.findIndex((i) => id === i)
    const newPublishWeeks = [...publishWeeks]
    newPublishWeeks.splice(index, 1)
    setValue(`${name}.publish_weeks`, newPublishWeeks, { shouldDirty: true })
  }

  // 日付のバリデーション
  const validatePublishDates = useCallback(() => {
    if (
      category === contentCategory.Repeat &&
      repeatType === 'date' &&
      !publishDates?.length
    ) {
      setError(`${name}.publish_dates`, {
        type: 'manual',
        message: '日付を選択してください',
      })
    } else {
      clearErrors(`${name}.publish_dates`)
    }
  }, [category, repeatType, publishDates, setError, name, clearErrors])

  // 曜日のバリデーション
  const validatePublishWeeks = useCallback(() => {
    if (
      category === contentCategory.Repeat &&
      repeatType === 'week' &&
      !publishWeeks?.length
    ) {
      setError(`${name}.publish_weeks`, {
        type: 'manual',
        message: '曜日を選択してください',
      })
    } else {
      clearErrors(`${name}.publish_weeks`)
    }
  }, [category, repeatType, publishWeeks, setError, name, clearErrors])

  useEffect(() => {
    validatePublishDates()
    validatePublishWeeks()
  }, [validatePublishDates, validatePublishWeeks])

  return (
    <Card
      title='自動配信設定'
      content={
        <FormGroup bsSize='large'>
          <SelectorForm
            name={`${name}.category`}
            label='自動配信の種類'
            width={240}
            disabled={editMode}
            inputRef={register({
              required: '自動配信の種類を入力してください',
            })}
            validationMessage={errors[name]?.category?.message}
            options={[
              { value: '', label: '選択する' },
              ...categories.map((c) => ({
                value: c,
                label: categoryToJp(c),
              })),
            ]}
          />

          {category === contentCategory.Repeat && (
            <>
              <Row>
                <Col sm={2} md={2}>
                  <Checkbox
                    number={200}
                    label='日付'
                    checked={repeatType === 'date'}
                    onChange={() => {
                      setRepeatType('date')
                    }}
                  />
                </Col>
                <Col md={4}>
                  <Controller
                    control={control}
                    name={`${name}.publish_dates`}
                    render={() => (
                      <MultipleSelectorForm
                        addItem={addPublishDate}
                        deleteItem={deletePublishDate}
                        disabled={repeatType !== 'date'}
                        options={[
                          {
                            value: '',
                            label: '日付を選択する',
                          },
                          ...DAYS.map((day) => ({
                            value: day,
                            label: `${day}日`,
                          })),
                        ]}
                        selectedItems={publishDates || []}
                        validationMessage={errors[name]?.publish_dates?.message}
                      />
                    )}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={2} md={2}>
                  <Checkbox
                    number={201}
                    label='曜日'
                    checked={repeatType === 'week'}
                    onChange={() => {
                      setRepeatType('week')
                    }}
                  />
                </Col>
                <Col md={4}>
                  <Controller
                    control={control}
                    name={`${name}.publish_weeks`}
                    render={() => (
                      <MultipleSelectorForm
                        addItem={addPublishWeek}
                        deleteItem={deletePublishWeek}
                        disabled={repeatType !== 'week'}
                        options={[
                          {
                            value: '',
                            label: '曜日を選択する',
                          },
                          ...WEEK_OF_DAYS_LABEL.map((week, i) => ({
                            value: i,
                            label: `${week}曜日`,
                          })),
                        ]}
                        selectedItems={publishWeeks || []}
                        validationMessage={errors[name]?.publish_weeks?.message}
                      />
                    )}
                  />
                </Col>
              </Row>
            </>
          )}

          {category === contentCategory.NoCheckIn && (
            <SelectorForm
              name={`${name}.days_later`}
              label='最終来店からの経過日数'
              inputRef={register({
                valueAsNumber: true,
                required: '最終来店からの経過日数を入力してください',
              })}
              options={[...noCheckInDays].map((day) => ({
                label: `${day}日`,
                value: day,
              }))}
              onSelect={(e) => setValue(`${name}.days_later`, e.target.value)}
              tooltipText={`最終来店から指定の日数、来店がない場合に${nameToJpObj[name]}が配信されます`}
              validationMessage={errors[name]?.days_later?.message}
            />
          )}

          {category === contentCategory.Register && (
            <SelectorForm
              name={`${name}.days_later`}
              label='初回登録後からのタイミング'
              inputRef={register({
                valueAsNumber: true,
                required: '初回登録後からのタイミングを入力してください',
              })}
              options={registerDays.map((day) => ({
                label: day === 0 ? 'すぐに' : `${day}日後`,
                value: day,
              }))}
              onSelect={(e) => setValue(`${name}.days_later`, e.target.value)}
              tooltipText={`初回登録から指定の日数後に${nameToJpObj[name]}が配信されます`}
              validationMessage={errors[name]?.days_later?.message}
            />
          )}

          {category === contentCategory.Birthday && (
            <>
              <Row>
                <FormInput
                  name={`${name}.days_ago`}
                  label='誕生日前の配信'
                  ncol='col-xs-4 col-sm-6 col-md-4'
                  type='number'
                  bsClass='form-control'
                  unit='日前'
                  inputRef={register()}
                  attachment='任意'
                  tooltipText='誕生日の指定日数前に配信できます。未設定の場合、誕生月の1日に配信されます'
                  validationMessage={errors[name]?.days_ago?.message}
                />
              </Row>
            </>
          )}

          {daysLater !== 0 && (
            <>
              <FormLabel label='配信時間' />
              <Box display='flex' alignItems='center' mb={2}>
                <Controller
                  name={`${name}.publish_hour`}
                  control={control}
                  rules={{ required: '配信時間を入力してください' }}
                  render={({ onChange, value }) => (
                    <Select
                      value={value}
                      onChange={(e) => onChange(e)}
                      sx={{ width: 100 }}
                      displayEmpty
                    >
                      {HOUR.map((hour) => (
                        <MenuItem key={hour} value={hour}>
                          {hour}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                />
                <span style={{ margin: '0 8px' }}>時</span>
                <Controller
                  name={`${name}.publish_min`}
                  control={control}
                  rules={{ required: '配信時間を入力してください' }}
                  render={({ onChange, value }) => (
                    <Select
                      value={value}
                      onChange={(e) => onChange(e)}
                      sx={{ width: 100 }}
                      displayEmpty
                    >
                      {Array.from({ length: 12 }, (_, i) => i * 5).map(
                        (minutes) => (
                          <MenuItem key={minutes} value={minutes}>
                            {minutes}
                          </MenuItem>
                        ),
                      )}
                    </Select>
                  )}
                />
                <span style={{ margin: '0 8px' }}>分</span>
              </Box>
              {(errors[name]?.publish_hour?.message ||
                errors[name]?.publish_min?.message) && (
                <FormHelperText error>
                  {errors[name]?.publish_hour?.message ||
                    errors[name]?.publish_min?.message}
                </FormHelperText>
              )}
            </>
          )}
        </FormGroup>
      }
    />
  )
}

export default AutoPublishFormCard
