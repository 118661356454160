import React, { useEffect } from 'react'
import { useApi } from '../../lib/hooks'

const TradeAreaDashboardView = () => {
  const { api, response } = useApi('/staff')
  const token = response?.token

  const dashboardParams = JSON.stringify({
    'connections.token': token,
    'stores.token': token,
  })
  const dashboardUrl =
    'https://lookerstudio.google.com/embed/reporting/264000d1-002e-465c-be35-02801e4478cc/page/AGM4C'
  const dashboardUrlWithToken = `${dashboardUrl}?params=${encodeURIComponent(dashboardParams)}`

  useEffect(() => {
    api.put('/looker_studio_tokens')
  }, [api])

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <iframe
        width='100%'
        height='900'
        title='商圏ダッシュボード'
        src={dashboardUrlWithToken}
        style={{ border: 0, maxWidth: 1200, margin: 16 }}
      />
    </div>
  )
}

export default TradeAreaDashboardView
