import React from 'react'
import styled from '@emotion/styled'
import moment from 'moment'
import { Table } from 'react-bootstrap'
import { CouponContentBlock } from '../../../types/api/coupon.d'
import { SortOrderType } from '../../../types/common.d'
import { CouponSortByType, CouponContentResource } from '../../../types/coupon'
import { CouponContentStatusToJpObj } from '../../../containers/entities/CouponContentEntityContainer'
import OptionButton from '../../../components/CustomButton/OptionButton'
import ContentMeasurementValueAndDiff from '../../../components/Utils/ContentMeasurementValueAndDiff'
import SortIconTh from '../../../components/Table/SortIconTh'
import StatusLabel from './StatusLabel'
import ContentSalesEffect from './ContentSalesEffect'
import { useLoginContext } from '../../../providers/LoginContextProvider'

type Props = {
  couponContents: CouponContentBlock[]
  sortBy: CouponSortByType
  sortOrder: SortOrderType
  onClickCouponContent: (id: number) => void
  onClickCopyButton: (couponContent: CouponContentResource) => void
  onDeleteCouponContent: (id: number) => void
  onSort: (sortBy: CouponSortByType, sortOrder: SortOrderType) => void
}

const PresentCouponTable = ({
  couponContents,
  sortBy,
  sortOrder,
  onClickCouponContent,
  onClickCopyButton,
  onDeleteCouponContent,
  onSort,
}: Props): JSX.Element => {
  const { currentStore } = useLoginContext()
  const showDeliveredCount = currentStore.show_coupon_delivered_count
  const showArrivalCount = currentStore.show_coupon_arrival_count
  const showReadCount = currentStore.show_coupon_read_count

  return (
    <Table data-cy='present-coupon-table'>
      <thead>
        <tr>
          <th> </th>
          <th>タイトル</th>

          {showDeliveredCount && <StyledNoWrapTh>配信</StyledNoWrapTh>}
          {showArrivalCount && <StyledNoWrapTh>到達</StyledNoWrapTh>}

          {showReadCount && (
            <StyledNoWrapSortIconTh
              data-cy='present-coupon-table-header-read-count'
              sortOrder={sortBy === 'read_count' ? sortOrder : null}
              onChangeSortOrder={(newSortOrder) =>
                onSort('read_count', newSortOrder)
              }>
              開封
            </StyledNoWrapSortIconTh>
          )}

          <StyledNoWrapSortIconTh
            data-cy='present-coupon-table-header-used-count'
            sortOrder={sortBy === 'used_count' ? sortOrder : null}
            onChangeSortOrder={(newSortOrder) =>
              onSort('used_count', newSortOrder)
            }>
            利用
          </StyledNoWrapSortIconTh>
          <StyledNoWrapTh>売上効果</StyledNoWrapTh>
          <StyledNoWrapTh>配信状態</StyledNoWrapTh>
          <SortIconTh
            data-cy='present-coupon-table-header-publish-at'
            sortOrder={sortBy === 'publish_at' ? sortOrder : null}
            onChangeSortOrder={(newSortOrder) =>
              onSort('publish_at', newSortOrder)
            }>
            配信日時/配信予定日時
          </SortIconTh>
          <th> </th>
        </tr>
      </thead>
      <tbody>
        {couponContents.map(
          ({
            coupon_content,
            delivered_count,
            arrival_count,
            used_count,
            used_count_diff,
            read_count,
            read_count_diff,
            sales_effect,
            has_available_arrival_count,
          }) => (
            <tr key={coupon_content.id} style={{ cursor: 'pointer' }}>
              <td onClick={() => onClickCouponContent(coupon_content.id)}>
                <StyledCouponImage
                  alt='クーポン画像'
                  src={coupon_content.image_url}
                  width={48}
                  height={30}
                />
              </td>
              <td onClick={() => onClickCouponContent(coupon_content.id)}>
                <div>
                  <b>{coupon_content.benefit}</b>
                </div>
                {coupon_content.memo && (
                  <StyledMemo>{coupon_content.memo}</StyledMemo>
                )}
              </td>

              {showDeliveredCount && (
                <StyledNoWrapTd
                  onClick={() => onClickCouponContent(coupon_content.id)}>
                  {coupon_content.status === 'publish'
                    ? delivered_count
                    : '---'}
                </StyledNoWrapTd>
              )}

              {showArrivalCount && (
                <StyledNoWrapTd
                  onClick={() => onClickCouponContent(coupon_content.id)}>
                  {coupon_content.status === 'publish' &&
                  has_available_arrival_count
                    ? arrival_count
                    : '---'}
                </StyledNoWrapTd>
              )}

              {showReadCount && (
                <StyledNoWrapTd
                  onClick={() => onClickCouponContent(coupon_content.id)}>
                  {coupon_content.status === 'publish' ? (
                    <ContentMeasurementValueAndDiff
                      value={read_count}
                      diff={read_count_diff}
                      createdAt={coupon_content.created_at}
                    />
                  ) : (
                    '---'
                  )}
                </StyledNoWrapTd>
              )}

              <StyledNoWrapTd
                onClick={() => onClickCouponContent(coupon_content.id)}>
                {coupon_content.status === 'publish' ? (
                  <ContentMeasurementValueAndDiff
                    value={used_count}
                    diff={used_count_diff}
                    createdAt={coupon_content.created_at}
                  />
                ) : (
                  '---'
                )}
              </StyledNoWrapTd>
              <StyledNoWrapTdSalesEffect
                onClick={() => onClickCouponContent(coupon_content.id)}>
                {coupon_content.status === 'publish' ? (
                  <ContentSalesEffect value={sales_effect} />
                ) : (
                  '---'
                )}
              </StyledNoWrapTdSalesEffect>
              <StyledNoWrapTd
                onClick={() => onClickCouponContent(coupon_content.id)}>
                <StatusLabel
                  data-cy={`present-coupon-list-status-label-${coupon_content.id}`}
                  status={coupon_content.status}>
                  {CouponContentStatusToJpObj[coupon_content.status]}
                </StatusLabel>
              </StyledNoWrapTd>
              <StyledNoWrapTd
                onClick={() => onClickCouponContent(coupon_content.id)}>
                {coupon_content.publish_at
                  ? moment(coupon_content.publish_at).format(
                      'YYYY/M/D(dddd) HH:mm',
                    )
                  : '---'}
              </StyledNoWrapTd>
              <td>
                <OptionButton
                  data-cy={`present-coupon-list-option-button-${coupon_content.id}`}
                  options={[
                    {
                      label: '複製',
                      onClick: () => onClickCopyButton(coupon_content),
                    },
                    coupon_content.published_number === 0 && {
                      'data-cy': `present-coupon-list-delete-button-${coupon_content.id}`,
                      label: '削除',
                      labelColor: '#FF4A55',
                      onClick: () => onDeleteCouponContent(coupon_content.id),
                    },
                  ].filter((v) => v)}
                />
              </td>
            </tr>
          ),
        )}
      </tbody>
    </Table>
  )
}

const StyledNoWrapTh = styled('th')({ whiteSpace: 'nowrap' })
const StyledNoWrapTd = styled('td')({ whiteSpace: 'nowrap' })
const StyledNoWrapTdSalesEffect = styled('td')({
  whiteSpace: 'nowrap',
  backgroundColor: '#EFF8F7',
})
const StyledNoWrapSortIconTh = styled(SortIconTh)({ whiteSpace: 'nowrap' })

const StyledCouponImage = styled('img')({
  borderRadius: 2,
  objectFit: 'cover',
})

const StyledMemo = styled('div')({
  fontSize: 12,
  color: '#787878',
})

export default PresentCouponTable
