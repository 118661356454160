import React, { FC } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Card } from '../../../components/Card/Card'
import LoadingButton from '../../../components/CustomButton/LoadingButton'
import SelectorForm from '../../../components/FormInputs/SelectorForm'

type Status = 'open' | 'close'

type Props = {
  onClickStatusUpdateButton: (status: Status) => Promise<void>
  store?
  formStatus: Status
  onSelect: (status: Status) => void
}

const optionArray = (): Status[] => ['open', 'close']

const StoreViewPublishSettingCard: FC<Props> = ({
  onClickStatusUpdateButton,
  store,
  formStatus,
  onSelect,
}) => (
  <Card
    title='公開設定'
    content={
      <>
        <Row>
          <Col sm={12}>
            <SelectorForm
              name='formStatus'
              width={180}
              style={{ marginBottom: 0 }}
              options={optionArray().map((status) => ({
                label: status === 'open' ? '公開' : '非公開',
                value: status,
              }))}
              onSelect={onSelect}
              value={formStatus}
            />
          </Col>
        </Row>

        <StoreViewPublishSettingCardButtonGroup
          formStatus={formStatus}
          onClickStatusUpdateButton={onClickStatusUpdateButton}
          store={store}
        />
      </>
    }
  />
)
export default StoreViewPublishSettingCard

type StoreViewPublishSettingCardButtonGroupProps = Pick<
  Props,
  'formStatus' | 'onClickStatusUpdateButton' | 'store'
>

const StoreViewPublishSettingCardButtonGroup: FC<
  StoreViewPublishSettingCardButtonGroupProps
> = ({ formStatus, onClickStatusUpdateButton, store }) => (
  <Row
    style={{
      marginRight: 0,
      display: 'flex',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
    }}>
    <Col
      style={{
        marginLeft: 10,
        marginTop: 5,
        marginBottom: 5,
      }}>
      <LoadingButton
        label='変更する'
        loadingLabel='変更中...'
        color='info'
        fill
        simple
        pullRight
        loadingColor='red'
        disabled={store?.status === formStatus}
        onClick={async (): Promise<void> => {
          await onClickStatusUpdateButton(formStatus)
        }}
      />
    </Col>
  </Row>
)
