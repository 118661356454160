import React, { ComponentPropsWithoutRef } from 'react'
import moment, { Moment } from 'moment'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import { range, WEEK_OF_DAYS_LABEL } from '../../../lib/general'

type Props = ComponentPropsWithoutRef<'div'> & {
  startDate: string
  endDate: string
  cellHeight?: number
  renderHeader?: (dayOfWeek: number, dateIndice: number[]) => React.ReactNode
  renderCell?: (dateIndex: number, date: Moment) => React.ReactNode
}

const CALENDAR_FIRST_DAY_OF_WEEK = 1
const NUM_OF_DAYS_IN_WEEK = 7
const CELL_WIDTH = `${100 / NUM_OF_DAYS_IN_WEEK}%`

const Calendar = ({
  startDate,
  endDate,
  cellHeight = 80,
  renderCell,
  renderHeader,
  ...otherProps
}: Props): JSX.Element => {
  const momentedDateFrom = moment(startDate)
  const momentedDateTo = moment(endDate)
  const dayOfDateFrom = momentedDateFrom.day()
  const numOfExtraDatesBeforeDateFrom =
    (dayOfDateFrom - CALENDAR_FIRST_DAY_OF_WEEK + NUM_OF_DAYS_IN_WEEK) %
    NUM_OF_DAYS_IN_WEEK
  const numOfDates = momentedDateTo.diff(momentedDateFrom, 'days') + 1
  const numOfRows = Math.ceil(
    (numOfDates + numOfExtraDatesBeforeDateFrom) / NUM_OF_DAYS_IN_WEEK,
  )
  const calendarDates = range(0, numOfDates).map((i) => ({
    index: i,
    date: momentedDateFrom.clone().add(i, 'days'),
  }))

  return (
    <StyledCalendarContainer {...otherProps}>
      <Box display='flex' bgcolor='#F9F9F9'>
        {[...Array(NUM_OF_DAYS_IN_WEEK)].map((_, i) => {
          const dayOfWeek =
            (CALENDAR_FIRST_DAY_OF_WEEK + i) % NUM_OF_DAYS_IN_WEEK
          const dateIndice = calendarDates
            .filter(({ date }) => date.day() === dayOfWeek)
            .map(({ index }) => index)

          return (
            <Box key={dayOfWeek} width={CELL_WIDTH}>
              <StyledDayOfWeekCell dayOfWeek={dayOfWeek}>
                {WEEK_OF_DAYS_LABEL[dayOfWeek]}
              </StyledDayOfWeekCell>
              {renderHeader && renderHeader(dayOfWeek, dateIndice)}
            </Box>
          )
        })}
      </Box>

      {[...Array(numOfRows)].map((_, row) => (
        <Box key={row} display='flex'>
          {[...Array(NUM_OF_DAYS_IN_WEEK)].map((_, cell) => {
            const index =
              row * NUM_OF_DAYS_IN_WEEK + cell - numOfExtraDatesBeforeDateFrom
            const date = calendarDates[index]
            return (
              <StyledCalendarCell key={cell} style={{ height: cellHeight }}>
                {date && (
                  <>
                    <StyledDate>{date.date.date()}</StyledDate>
                    {renderCell && renderCell(date.index, date.date)}
                  </>
                )}
              </StyledCalendarCell>
            )
          })}
        </Box>
      ))}
    </StyledCalendarContainer>
  )
}

const StyledCalendarContainer = styled('div')({
  width: '100%',
  maxWidth: 1000,
  borderWidth: '1px 0 0 1px',
  borderStyle: 'solid',
  borderColor: '#eee',
})

const StyledDayOfWeekCell = styled('div', {
  shouldForwardProp: (props) => props !== 'dayOfWeek',
})<{ dayOfWeek: number }>(({ dayOfWeek, theme }) => ({
  color: dayOfWeek === 0 ? '#E15F4D' : dayOfWeek === 6 ? '#1DC7EA' : '#9A9A9A',
  fontSize: 13,
  fontWeight: 700,
  padding: theme.spacing(1),
  display: 'flex',
  justifyContent: 'center',
  borderRight: 'solid 1px #eee',
}))

const StyledCalendarCell = styled('div')({
  width: CELL_WIDTH,
  position: 'relative',
  borderWidth: '0 1px 1px 0',
  borderStyle: 'solid',
  borderColor: '#eee',
})

const StyledDate = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  left: theme.spacing(1),
  color: '#9A9A9A',
  fontSize: 14,
  fontWeight: 700,
}))

export default Calendar
