import React, { useEffect, useState, useCallback } from 'react'
import { v4 as uuid } from 'uuid'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import { useNotification } from '../../../providers/NotificationProvider'
import DialogTitle from '../../../components/DialogTitle'
import { OptionItemResource } from '../../../types/menu'
import { useApi, useApiClient, useBooleanState } from '../../../lib/hooks'

type OptionItemForm = {
  key: string
  id: number | null
  title: string
  price: number | null
}

type Props = {
  open: boolean
  onClose: () => void
}

const OptionItemDialog = ({ open, onClose }: Props): JSX.Element => {
  const { showSuccessNotification } = useNotification()
  const [optionItems, setOptionItems] = useState<OptionItemForm[]>([])
  const { api, response, loaded, loading } = useApi<OptionItemResource[]>()
  const callApi = useApiClient()
  const defaultOptionItems = response ?? []
  const [requesting, startRequest, finishRequest] = useBooleanState(false)
  const dirtyOptionItems = optionItems.filter(
    (optionItem, index) =>
      optionItem.id === null ||
      optionItem.title !== defaultOptionItems[index]?.title ||
      optionItem.price !== defaultOptionItems[index]?.price,
  )
  const cantSubmit =
    dirtyOptionItems.length === 0 ||
    dirtyOptionItems.some((optionItem) => !Boolean(optionItem.title))

  const addOptionItem = () =>
    setOptionItems([...optionItems, generateEmptyOptionItem()])
  const removeOptionItem = (key: string) => {
    const newOptionItems = optionItems.filter(
      (optionItem) => optionItem.key !== key,
    )
    setOptionItems(newOptionItems)
  }
  const updateOptionItem = (
    key: string,
    optionItem: Partial<OptionItemForm>,
  ) => {
    const index = optionItems.findIndex((optionItem) => optionItem.key === key)
    if (index > -1) {
      optionItems[index] = { ...optionItems[index], ...optionItem }
      setOptionItems([...optionItems])
    }
  }

  const fetchOptionItems = useCallback(async () => {
    const response = await api.get('/option_items')
    const newOptionItems = response.data.map((v) => ({
      ...v,
      key: String(v.id),
    }))
    setOptionItems(newOptionItems)
  }, [api])

  useEffect(() => {
    if (open) {
      fetchOptionItems()
    }
  }, [open, fetchOptionItems])

  const handleSubmit = async () => {
    startRequest()

    const promises = dirtyOptionItems.map((optionItem) => {
      const params = {
        option_item: {
          title: optionItem.title,
          price: optionItem.price,
          status: 'open',
        },
      }

      return optionItem.id === null
        ? callApi('POST', '/option_items', params)
        : callApi('PATCH', `/option_items/${optionItem.id}`, params)
    })

    await Promise.all(promises).finally(finishRequest)

    showSuccessNotification('オプションメニューを更新しました。')
    onClose()
  }

  const handleRemove = async (optionItem: OptionItemForm) => {
    if (optionItem.id) {
      if (!window.confirm('このオプションメニューを削除しますか？')) return

      startRequest()
      const res = await callApi(
        'DELETE',
        `/option_items/${optionItem.id}`,
      ).finally(finishRequest)
      if (!res) return

      await fetchOptionItems()
      showSuccessNotification('オプションメニューを削除しました')
    } else {
      removeOptionItem(optionItem.key)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle onClose={onClose}>
        オプションメニューのマスタを編集
      </DialogTitle>

      <DialogContent>
        {!loaded && (
          <Box display='flex' justifyContent='center' py={2}>
            <CircularProgress />
          </Box>
        )}

        {optionItems.map((optionItem) => (
          <Box
            key={optionItem.id}
            display='flex'
            alignItems='center'
            gap={1}
            mb={1}>
            <OutlinedInput
              value={optionItem.title}
              disabled={loading || requesting}
              error={!optionItem.title}
              sx={{ flex: '1 0 0' }}
              onChange={(e) =>
                updateOptionItem(optionItem.key, { title: e.target.value })
              }
            />

            <OutlinedInput
              type='number'
              endAdornment='円'
              value={optionItem.price}
              disabled={loading || requesting}
              sx={{ flexBasis: 130 }}
              onChange={(e) =>
                updateOptionItem(optionItem.key, {
                  price: Number(e.target.value),
                })
              }
            />

            <IconButton size='small' onClick={() => handleRemove(optionItem)}>
              <i className='ri-delete-bin-6-line' style={{ lineHeight: 1 }} />
            </IconButton>
          </Box>
        ))}

        {loaded && (
          <Button
            color='submit'
            disabled={loading || requesting}
            onClick={addOptionItem}>
            新しいオプションメニューを追加する
          </Button>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          fullWidth
          variant='outlined'
          color='cancel'
          disabled={loading || requesting}
          onClick={onClose}>
          閉じる
        </Button>

        <Button
          fullWidth
          color='submit'
          variant='contained'
          disabled={cantSubmit || loading || requesting}
          onClick={handleSubmit}>
          更新する
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const generateEmptyOptionItem = (): OptionItemForm => ({
  key: uuid(),
  id: null,
  title: '',
  price: 0,
})

export default OptionItemDialog
