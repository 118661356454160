import React, { useEffect } from 'react'
import { styled } from '@mui/system'
import { useLocation } from 'react-router-dom'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import { isOwner } from '../../../lib/staff'
import { useBooleanState, usePcSizeFlag } from '../../../lib/hooks'
import LoginContextProvider, {
  useLoginContext,
} from '../../../providers/LoginContextProvider'
import TenantEntityContainer from '../../../containers/entities/TenantEntityContainer'
import AmplitudePageTracking from '../../AmplitudePageTracking'
import StoreAppWebViewPostHandler from '../../StoreAppWebViewPostHandler'
import Sidebar from '../sidebar/Sidebar'
import LoginRoute, { getPageTitle } from './LoginRoute'
import RejectedRoute from './RejectedRoute'

const LoginContentWrapper = () => {
  return (
    <LoginContextProvider>
      <LoginContent />
    </LoginContextProvider>
  )
}

const LoginContent = (): JSX.Element => {
  const [isSidebarOpen, openSidebar, closeSidebar] = useBooleanState(false)
  const { isOwnerRejected, currentOwner } = useLoginContext()
  const { pathname } = useLocation()
  const isPcOrTablet = usePcSizeFlag()
  const fromWebview = navigator.userAgent === 'webView'
  const showSidebarAsAlways =
    isPcOrTablet && !fromWebview && !pathname.includes('welcome')
  const showHeader = !fromWebview
  const tenantEntityContainer = TenantEntityContainer.useContainer()
  const { getTenant } = tenantEntityContainer.api

  useEffect(() => {
    if (isOwner()) {
      getTenant()
    }
  }, [getTenant])

  return (
    <>
      <AmplitudePageTracking />
      <StoreAppWebViewPostHandler />

      {isPcOrTablet ? (
        showSidebarAsAlways && (
          <Box position='fixed' width={SIDEBAR_WIDTH} zIndex={100}>
            <Sidebar />
          </Box>
        )
      ) : (
        <Drawer anchor='left' open={isSidebarOpen} onClose={closeSidebar}>
          <Box width={SIDEBAR_WIDTH}>
            <Sidebar />
          </Box>
        </Drawer>
      )}

      <StyledContentContainer
        showSidebar={showSidebarAsAlways}
        className='main-panel'>
        {!fromWebview && currentOwner.plan === 'test' && (
          <StyledTestAccountLabel>テストアカウント</StyledTestAccountLabel>
        )}

        {showHeader && (
          <StyledHeaderContainer>
            <Box fontSize={20}>{getPageTitle(pathname)}</Box>

            {!isPcOrTablet && (
              <i
                className='ri-menu-line'
                style={{ fontSize: 24 }}
                onClick={openSidebar}
              />
            )}
          </StyledHeaderContainer>
        )}

        {isOwnerRejected ? <RejectedRoute /> : <LoginRoute />}
      </StyledContentContainer>
    </>
  )
}

const SIDEBAR_WIDTH = 260

const StyledContentContainer = styled('div', {
  shouldForwardProp: (name: string) => name !== 'showSidebar',
})<{ showSidebar: boolean }>(({ showSidebar }) => ({
  marginLeft: showSidebar ? SIDEBAR_WIDTH : 0,
  background: '#F3F5F9',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  float: 'none',
  width: showSidebar ? `calc(100% - ${SIDEBAR_WIDTH}px)` : '100%',
  '& .content': {
    width: '100%',
  },
}))

const StyledTestAccountLabel = styled('div')(({ theme }) => ({
  backgroundColor: '#f6aa50',
  textAlign: 'left',
  color: '#fff',
  fontWeight: 700,
  padding: theme.spacing(0.5, 2),
}))

const StyledHeaderContainer = styled('div')(({ theme }) => ({
  backgroundColor: '#fff',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2),
}))

export default LoginContentWrapper
