import React from 'react'
import Box from '@mui/material/Box'
import { useLoginContext } from '../providers/LoginContextProvider'
import QuickSightDashboard from '../components/QuickSightDashboard'

const CouponsSummaryView = () => {
  const { currentStore } = useLoginContext()

  return (
    <Box flex={1}>
      <QuickSightDashboard
        params={{
          StoreId: currentStore.store_type === 'child' ? currentStore.id : null,
        }}
        dashboardType='coupons_summary'
      />
    </Box>
  )
}

export default CouponsSummaryView
