import React, { useState } from 'react'
import SwitchForm from '../../../components/FormInputs/SwitchForm'
import SelectorForm from '../../../components/FormInputs/SelectorForm'
import { useGetApiCall } from '../../../lib/hooks'
import { RestaurantSatisfactionQuestionnairesResponse } from '../../../types/questionnaire'

type Props = {
  defaultQuestionnaireId?: number
  questionnaireId: number | null
  onChange: (questionnaireId: number | null) => void
}

const CouponQuestionnaireForm = ({
  defaultQuestionnaireId,
  questionnaireId,
  onChange,
}: Props): JSX.Element => {
  const [checked, setChecked] = useState(Boolean(questionnaireId))
  const path = '/restaurant_satisfaction_questionnaires'
  const params = { status: 'open' }
  const { loading, response } =
    useGetApiCall<RestaurantSatisfactionQuestionnairesResponse>(path, params)
  const questionnaires = response?.questionnaires || []
  const options =
    questionnaires.length === 0
      ? [{ label: 'クーポンに添付できるアンケートがありません', value: 0 }]
      : questionnaires.map(({ questionnaire, store }) => ({
          label: `${questionnaire.title} (${store.name})`,
          value: questionnaire.id,
        }))

  const handleChange = async (newValue: boolean) => {
    setChecked(newValue)
    if (newValue) {
      if (response) {
        onChange(
          defaultQuestionnaireId || response.questionnaires[0].questionnaire.id,
        )
      }
    } else {
      onChange(null)
    }
  }

  return (
    <div>
      <SwitchForm
        disabled={loading}
        onChange={handleChange}
        checked={checked}
        label='アンケート回答後にクーポンを利用可能にする'
        uncheckedText=''
        checkedText=''
        width={60}
      />

      {checked && (
        <SelectorForm
          disabled={loading || questionnaires.length === 0}
          label='アンケート'
          value={questionnaireId || 0}
          options={loading ? [] : options}
          width='90%'
          onSelect={({ target }) => onChange(target.value)}
        />
      )}
    </div>
  )
}

export default CouponQuestionnaireForm
