import React from 'react'
import ImageLoading from '../Loading/ImageLoading'

const ImageInput = (props) => {
  const {
    deleteImage,
    image,
    canDelete,
    width = 280,
    maxWidth,
    height = 175,
    maxHeight,
    aspect,
    doDisplayImage,
    uploadImage,
    isLoading,
    altMessage,
    inline,
  } = props

  // TODO: 削除ボタンの位置がおかしい。
  return doDisplayImage ? (
    <div
      style={{
        display: inline ? 'inline-block' : 'block',
        position: 'relative',
        width: width,
        height: height,
        marginRight: 5,
      }}>
      {canDelete && (
        <div
          style={{
            position: 'absolute',
            padding: '8px 12px',
            top: 0,
            right: 0,
            zIndex: 0,
            cursor: 'pointer',
          }}
          onClick={(e) => {
            deleteImage()
            e.stopPropagation()
          }}>
          <i className='far fa-trash-alt' style={{ color: 'red' }} />
        </div>
      )}
      <label style={{ cursor: 'pointer' }}>
        <input
          onChange={uploadImage}
          type='file'
          accept='image/png,image/jpeg'
          style={{ display: 'none' }}
        />
        <ImageCard
          src={image}
          altMessage={`${altMessage}`}
          width={width}
          maxWidth={maxWidth}
          height={height}
          maxHeight={maxHeight}
          opacity={isLoading ? 0.5 : 1}
        />
        {isLoading ? <ImageLoading /> : null}
      </label>
    </div>
  ) : (
    <label
      style={{
        display: inline ? 'inline-block' : 'block',
        width: width,
        maxWidth: maxWidth,
        height: height,
        maxHeight: maxHeight,
        aspectRatio: String(aspect), // トリミングで使用する値がNumber型の為、ここでString型に変換している
        boxSizing: 'border-box',
        border: 'dashed 2px #ccc',
        backgroundColor: '#fff',
        cursor: 'pointer',
        fontWeight: 400,
        position: 'relative',
      }}>
      <input
        onChange={uploadImage}
        type='file'
        accept='image/png,image/jpeg'
        style={{ display: 'none' }}
      />
      <div
        style={{
          textAlign: 'center',
          position: 'relative',
          top: '50%',
          transform: 'translateY(-50%)',
        }}>
        <i className='far fa-image' />
        <p
          style={{
            marginTop: 8,
            marginBottom: 0,
            textAlign: 'center',
            fontSize: 13,
            color: '#888',
            maxWidth: '100%',
          }}>
          画像を追加
        </p>
      </div>
      {isLoading ? <ImageLoading /> : null}
    </label>
  )
}

export default ImageInput

export const ImageCard = ({
  src,
  altMessage,
  width,
  maxWidth = null,
  height,
  maxHeight = null,
  opacity = 1,
  border = 'none',
}) => (
  <img
    src={src}
    alt={altMessage}
    style={{
      width: width,
      maxWidth: maxWidth,
      height: height,
      maxHeight: maxHeight,
      objectFit: 'contain',
      border: border,
      borderRadius: 5,
      opacity: opacity,
      backgroundColor: '#ebebeb',
    }}
  />
)
