import { parsePhoneNumberFromString } from 'libphonenumber-js'
import { EMAIL_REGEX } from './general'

export const validateTextForm = (value) => {
  if (!value) {
    return { validation: false, message: '値を入力してください。' }
  }

  return { validation: true, message: '' }
}

export const validateDigit = (value, digit) => {
  if (value.length !== digit) {
    return { validation: false, message: `${digit}桁で入力してください。` }
  }

  return { validation: true, message: '' }
}

export const validateCount = (value, max) => {
  if (!value || value < 1 || value > max) {
    return {
      validation: false,
      message: `1以上${max}以下の数字を入力してください`,
    }
  }

  return { validation: true, message: '' }
}

export const validateOptionForm = (value) => {
  if (!value || value === '0') {
    return { validation: false, message: '値を選択してください。' }
  }

  return { validation: true, message: '' }
}

export const validatePassword = (values) => {
  if (values.newPassword !== values.confirmPassword) {
    return {
      validation: false,
      message: '新しいパスワードと確認用パスワードが一致していません',
    }
  }
  return { validation: true, message: '' }
}

export const validateDate = (date) => {
  if (!date) {
    return { validation: false, message: '値を入力してください。' }
  }
  if (new Date(date) < new Date()) {
    return {
      validation: false,
      message: '現在時刻よりも過去の時刻を設定することはできません。',
    }
  }

  return { validation: true, message: '' }
}

export const validateMinDate = (date) => {
  if (!date) {
    return { validation: false, message: '値を入力してください。' }
  }
  if (new Date(date) < new Date()) {
    return {
      validation: false,
      message: '現在時刻よりも過去の時刻を設定することはできません。',
    }
  }
  if (new Date(date) < new Date('2020/6/1')) {
    return {
      validation: false,
      message: '2020/06/01以降を選択して下さい。',
    }
  }

  return { validation: true, message: '' }
}

export const validateArray = (array) => {
  if (!array.length) {
    return { validation: false, message: '値を選択してください。' }
  }
  return { validation: true, message: '' }
}

export const compareValidateDate = (
  publishDate,
  expirationDate,
  acceptSameDay = false,
) => {
  if (!expirationDate) {
    return { validation: false, message: '値を入力してください。' }
  }

  const isInvalid = acceptSameDay
    ? new Date(publishDate) >= new Date(expirationDate)
    : new Date(publishDate) > new Date(expirationDate)

  if (isInvalid) {
    return {
      validation: false,
      message: '配信日よりも過去の時刻を設定することはできません。',
    }
  }

  return { validation: true, message: '' }
}

export const compareValidateEventDate = ({
  startHour,
  startMinute,
  endHour,
  endMinute,
}) => {
  if (startHour > endHour) {
    return {
      validation: false,
      message: '開始時間よりも過去の終了時間を設定することはできません。',
    }
  }

  if (startHour === endHour) {
    if (startMinute > endMinute) {
      return {
        validation: false,
        message: '開始時間よりも過去の終了時間を設定することはできません。',
      }
    }
    if (startMinute === endMinute) {
      return {
        validation: false,
        message: '開始時間と同じ時間を終了時間に設定することはできません。',
      }
    }
  }

  return { validation: true, message: '' }
}

export const compareCounts = (lowNumber, highNumber) => {
  const regexp = /^[0-9]+$/

  if (!lowNumber || !highNumber) {
    return { validation: false, message: '値を入力してください。' }
  }

  if (!regexp.test(lowNumber)) {
    return {
      validation: false,
      message: '半角数字で入力してください',
    }
  }

  if (!regexp.test(highNumber)) {
    return {
      validation: false,
      message: '半角数字で入力してください',
    }
  }

  if (lowNumber >= highNumber) {
    return {
      validation: false,
      message: '日の使用可能回数より月の使用可能回数を多く設定してください',
    }
  }

  return { validation: true, message: '' }
}

export const compareNumber = (lowNumber, highNumber) => {
  const regexp = /^[0-9]+$/

  if (!lowNumber || !highNumber) {
    return { validation: false, message: '値を入力してください。' }
  }

  if (!regexp.test(lowNumber)) {
    return {
      validation: false,
      message: '半角数字で入力してください',
    }
  }

  if (!regexp.test(highNumber)) {
    return {
      validation: false,
      message: '半角数字で入力してください',
    }
  }

  if (lowNumber >= highNumber) {
    return {
      validation: false,
      message: '正しい年齢の範囲を入力してください',
    }
  }

  return { validation: true, message: '' }
}

export const compareExpirationDate = (expirationFrom, expirationTo) => {
  if (!expirationTo) {
    return { validation: false, message: '値を入力してください。' }
  }
  if (new Date(expirationFrom) > new Date(expirationTo)) {
    return {
      validation: false,
      message: '開始日時よりも過去の時刻を設定することはできません。',
    }
  }

  return { validation: true, message: '' }
}

export const validatePhoneNumberForm = (number) => {
  if (!number) {
    return { validation: false, message: '電話番号を入力してください。' }
  }

  const phoneNumber = parsePhoneNumberFromString(number, 'JP')
  if (!phoneNumber || !phoneNumber.isValid()) {
    return { validation: false, message: '正しい電話番号を入力してください。' }
  }

  return { validation: true, message: '' }
}

export const validateFirstMessage = (firstMessage) => {
  const MAX_LIMIT = 150

  if (firstMessage?.length > MAX_LIMIT) {
    return {
      validation: false,
      message: '150文字以内で入力してください。',
    }
  }
  return { validation: true, message: '' }
}
export const validateFirstMessageStaffName = (firstMessageStaffName) => {
  const MAX_LIMIT = 10

  if (firstMessageStaffName?.length > MAX_LIMIT) {
    return {
      validation: false,
      message: '10文字以内で入力してください。',
    }
  }
  return { validation: true, message: '' }
}

export const validatePositiveNumber = (number) => {
  const regexp = /^[0-9]+$/
  if (!number && number !== 0) {
    return {
      validation: false,
      message: '数字を入力してください',
    }
  }
  if (number < 0) {
    return {
      validation: false,
      message: '正の値を入力してください',
    }
  }
  if (!regexp.test(number)) {
    return {
      validation: false,
      message: '半角数字で入力してください',
    }
  }
  return { validation: true, message: '' }
}

export const validateNumber = (number) => {
  const regexp = /^[0-9]+$/
  if (!number && number !== 0) {
    return {
      validation: false,
      message: '数字を入力してください',
    }
  }
  if (number <= 0) {
    return {
      validation: false,
      message: '1以上の数字を入力してください',
    }
  }
  if (!regexp.test(number)) {
    return {
      validation: false,
      message: '半角数字で入力してください',
    }
  }
  return { validation: true, message: '' }
}

// 最低入金額のバリデーションに使用
export const validatePrice = (price, minimamPrice) => {
  const regexp = /^[0-9]+$/
  if (!price && price !== 0) {
    return {
      validation: false,
      message: '最低入金額を入力してください',
    }
  }
  if (price < minimamPrice) {
    return {
      validation: false,
      message: `最低入金額は${minimamPrice}円以上に設定してください`,
    }
  }
  if (!regexp.test(price)) {
    return {
      validation: false,
      message: '半角数字で入力してください',
    }
  }
  return { validation: true, message: '' }
}

export const validateMaxStamp = (stamp) => {
  const MAX_LIMIT = 60
  const result = validatePositiveNumber(stamp)

  if (!result.validation) {
    return result
  }
  if (stamp > MAX_LIMIT) {
    return {
      validation: false,
      message: '60以上のスタンプ数は設定できません',
    }
  }

  return { validation: true, message: '' }
}

export const regexpEmail = EMAIL_REGEX
export const validateEmail = (email) => {
  const result = email.match(regexpEmail)
  if (!result) {
    return {
      validation: false,
      message: '正しい形式のメールアドレスを入力してください。',
    }
  }
  return { validation: true, message: '' }
}

export const validateTime = (hour) => {
  const regexp = /^[0-9]+$/

  if (!hour && hour !== 0) {
    return {
      validation: false,
      message: '適切な値を入力してください',
    }
  }
  if (hour < 0 || hour > 23) {
    return {
      validation: false,
      message: '適切な値を入力してください',
    }
  }
  if (!regexp.test(hour)) {
    return {
      validation: false,
      message: '半角数字で入力してください',
    }
  }
  return { validation: true, message: '' }
}

export const validateDay = (day) => {
  const regexp = /^[0-9]+$/
  if (!day && day !== 0) {
    return {
      validation: false,
      message: '適切な値を入力してください',
    }
  }
  if (day < 0) {
    return {
      validation: false,
      message: '適切な値を入力してください',
    }
  }
  if (!regexp.test(day)) {
    return {
      validation: false,
      message: '半角数字で入力してください',
    }
  }
  return { validation: true, message: '' }
}

export const validateBranchArray = (array) => {
  if (!array.length) {
    return {
      validation: false,
      message: '店舗を選択してください',
    }
  }
  return { validation: true, message: '' }
}

export const validateOptionArray = (array) => {
  if (!array.length) {
    return {
      validation: false,
      message: 'オプションを選択してください',
    }
  }
  return { validation: true, message: '' }
}

export const validateBankAccountName = (text) => {
  const regexp = /^([ァ-ン0-9a-zA-Z()（）「」./ 　]|ー)+$/
  if (!text) {
    return {
      validation: false,
      message: '値を入力してください',
    }
  }
  if (!regexp.test(text)) {
    return {
      validation: false,
      message: '口座名義はカタカナや英数字で入力してください',
    }
  }
  return { validation: true, message: '' }
}

export const validateBlankSpace = (value) => {
  // 半角・全角のスペースがあるか
  if (value?.indexOf(' ') > -1 || value?.indexOf('　') > -1) {
    return {
      validation: false,
      message: '空白を入力することはできません。',
    }
  }
  return { validation: true, message: '' }
}
