import React, { useEffect } from 'react'
import {
  Grid,
  Row,
  Col,
  HelpBlock,
  FormGroup,
  ControlLabel,
  Image,
} from 'react-bootstrap'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import * as H from 'history'
import { HashLink } from 'react-router-hash-link'
import { useLoginContext } from '../../providers/LoginContextProvider'
import { useNotification } from '../../providers/NotificationProvider'
import FormLabel from '../../components/FormInputs/FormLabel'
import StoreUrlSection from './_parts/StoreUrlSection'
import PostSupportGuide from './_parts/PostSupportGuide'
import { Card } from '../../components/Card/Card'
import QrImageDisplay from '../../components/Image/QrImageDisplay'
import PostDisplay from './_parts/PostDisplay'
import OpenStatusMessage from '../../components/Modal/OpenStatusMessage'
import StampCardEntityContainer from '../../containers/entities/StampCardEntityContainer'
import {
  deleteStoreQrcodes,
  deleteCheckInQrcodes,
  updateStoreQrcodeName,
  updateCheckInQrcodeName,
} from '../../lib/api/qrcodes'
import { formatDateExceptTime } from '../../lib/general'
import { useApi } from '../../lib/hooks'
import SpreadImg from '../../assets/img/spread_header.png'
import NewConnectionMessageSampleImg from '../../assets/img/new_conection_message_sample.png'

interface PropsType {
  displayNotification: ({
    level,
    message,
  }: {
    level: 'success' | 'error' | 'warning'
    message: string
  }) => void
  history: H.History
}

const posters = [
  {
    title: 'ポスター ブルー',
    image_url: require('../../assets/img/posts/poster_blue.png'),
    download_url: require('../../assets/img/posts/poster_blue.pdf'),
  },
  {
    title: 'ポスター ネイビー',
    image_url: require('../../assets/img/posts/poster_navy.png'),
    download_url: require('../../assets/img/posts/poster_navy.pdf'),
  },
]

const pops = [
  {
    title: 'POP ブルー',
    image_url: require('../../assets/img/posts/pop_blue.png'),
    download_url: require('../../assets/img/posts/pop_blue.pdf'),
  },
  {
    title: 'POP ネイビー',
    image_url: require('../../assets/img/posts/pop_navy.png'),
    download_url: require('../../assets/img/posts/pop_navy.pdf'),
  },
]

const PromotionalMaterialView: React.FC<PropsType> = ({
  displayNotification,
  history,
}) => {
  const stampCardEntityContainer = StampCardEntityContainer.useContainer()
  const { showSuccessNotification, showErrorNotification } = useNotification()
  const { currentStore, reloadCurrentStore } = useLoginContext()
  const { getStampCardContent } = stampCardEntityContainer.api
  const { api } = useApi()

  useEffect(() => {
    getStampCardContent().then((res) => {
      stampCardEntityContainer.setEntityState.stampCard(res)
    })
  }, [getStampCardContent, stampCardEntityContainer.setEntityState])

  const handleClickCreateStoreQrcodes = async (type: 'toypo' | 'liff') => {
    const message =
      type === 'toypo'
        ? '店舗登録用QRコードを追加しますか？'
        : 'LINEミニアプリ用の店舗登録用QRコードを追加しますか？'
    if (!window.confirm(message)) {
      return
    }

    const response = await api.post('/store_qrcodes', { qr_type: type })
    if (response) {
      reloadCurrentStore()
      showSuccessNotification('追加しました。')
    }
  }

  const handleClickDeleteStoreQrcodes = async (e, id) => {
    e.preventDefault()
    if (!window.confirm('店舗登録用QRコードを削除しますか？')) {
      return
    }

    try {
      await deleteStoreQrcodes(id)
      reloadCurrentStore()
      showSuccessNotification('削除しました。')
    } catch (error) {
      showErrorNotification('削除できませんでした')
    }
  }

  const handleClickCreateCheckInQrcodes = async (type: 'toypo' | 'liff') => {
    const message =
      type === 'toypo'
        ? 'チェックインQRコードを追加しますか？'
        : 'LINEミニアプリ用のチェックインQRコードを追加しますか？'
    if (!window.confirm(message)) {
      return
    }

    const response = await api.post('/check_in_qrcodes', { qr_type: type })
    if (response) {
      reloadCurrentStore()
      showSuccessNotification('追加しました。')
    }
  }

  const handleClickDeleteCheckInQrcodes = async (e, id) => {
    e.preventDefault()
    if (!window.confirm('チェックインQRコードを削除しますか？')) {
      return
    }

    try {
      await deleteCheckInQrcodes(id)
      reloadCurrentStore()
      showSuccessNotification('削除しました。')
    } catch (error) {
      showErrorNotification('削除できませんでした')
    }
  }

  const handleClickOpenStatusMessage = () => {
    if (currentStore.store_type === 'child') {
      history.push('/admin/store/edit')
    } else {
      history.push('/admin/group/edit')
    }
  }

  return (
    <Grid fluid className='content'>
      {currentStore.status === 'close' && (
        <Row>
          <Col md={12}>
            <div style={{ marginBottom: 16 }}>
              <OpenStatusMessage
                name={currentStore.name}
                onClick={handleClickOpenStatusMessage}
              />
            </div>
          </Col>
        </Row>
      )}

      <Row>
        <Col md={12}>
          <Card
            title='SNSやWEBサイトで宣伝する'
            category='SNSやWEBサイト、ブログなどにダウンロード用のURLを貼ってアプリを宣伝しましょう。'
            content={
              <StoreUrlSection
                storeLink={currentStore.store_link || ''}
                displayNotification={displayNotification}
              />
            }
          />
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <Card
            title='店頭POPや掲示物で宣伝する'
            category='掲示物・QRコードをダウンロードしてアプリの宣伝に利用しましょう。'
            content={
              <QrcodeSection
                onClickCreateStoreQrcodes={handleClickCreateStoreQrcodes}
                onClickCreateCheckInQrcodes={handleClickCreateCheckInQrcodes}
                onClickDeleteStoreQrcodes={handleClickDeleteStoreQrcodes}
                onClickDeleteCheckInQrcodes={handleClickDeleteCheckInQrcodes}
                onClickUpdateStoreQrcodeName={updateStoreQrcodeName}
                onClickUpdateCheckInQrcodeName={updateCheckInQrcodeName}
                displayNotification={displayNotification}
                onUpdateQrcode={reloadCurrentStore}
              />
            }
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Card
            title='初回登録したユーザーへメッセージを表示する'
            category={`お店やアプリにさらに興味を持ってもらえるように、はじめて登録したユーザーへメッセージを伝えましょう。\n簡単なお店の紹介や、アプリで配信する内容を伝えると効果が期待できます。`}
            content={
              <FormGroup>
                <HelpBlock style={{ marginBottom: 25 }}>
                  <HashLink to='/admin/store/edit#firstMessage'>
                    <p>メッセージを設定する</p>
                  </HashLink>
                </HelpBlock>

                <FormGroup style={{ marginBottom: 20 }}>
                  <FormLabel label='メッセージ例' />
                  <Col>
                    <Image src={NewConnectionMessageSampleImg} />
                  </Col>
                </FormGroup>
              </FormGroup>
            }
          />
        </Col>
      </Row>
    </Grid>
  )
}

const QrcodeSection = ({
  onClickCreateStoreQrcodes,
  onClickCreateCheckInQrcodes,
  onClickDeleteStoreQrcodes,
  onClickDeleteCheckInQrcodes,
  onClickUpdateStoreQrcodeName,
  onClickUpdateCheckInQrcodeName,
  displayNotification,
  onUpdateQrcode,
}) => {
  const { currentStore, lineChannel } = useLoginContext()

  return (
    <FormGroup bsSize='large' style={{ marginBottom: 30 }}>
      <div style={{ marginBottom: 24 }}>
        <img
          style={{ width: '100%', maxWidth: 370 }}
          src={SpreadImg}
          alt='店頭POPや掲示物で宣伝'
        />
      </div>

      <div style={{ marginBottom: 24, width: '100%', maxWidth: 600 }}>
        <PostSupportGuide
          onClick={() =>
            window.open(
              'https://toypo.notion.site/64e02c7b2ebf49a6bf9373eae87074a1',
              '_blank',
            )
          }
        />
      </div>
      {/* ポスター */}
      <div style={{ marginBottom: 15 }}>
        <ControlLabel
          style={{ display: 'block', fontSize: 18, fontWeight: 700 }}>
          ポスター
        </ControlLabel>
        <HelpBlock
          className='text-muted'
          style={{ fontSize: 11, marginBottom: 7 }}>
          このポスターを印刷後、QRコードを別途印刷して赤枠の部分へ貼り付け、アプリダウンロード特典を記入して利用しましょう。
        </HelpBlock>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}>
          {posters?.map((poster) => (
            <PostDisplay
              key={poster.title}
              href={poster.download_url}
              altMessage={poster.title}
              image={poster.image_url}
              name={poster.title}
              height='auto'
              border='1px solid #000'
            />
          ))}
        </div>
      </div>
      {/* POP */}
      <div style={{ marginBottom: 15 }}>
        <ControlLabel
          style={{ display: 'block', fontSize: 18, fontWeight: 700 }}>
          POP
        </ControlLabel>
        <HelpBlock
          className='text-muted'
          style={{ fontSize: 11, marginBottom: 7 }}>
          このPOPを印刷後、QRコードを別途印刷して赤枠の部分へ貼り付け、アプリダウンロード特典を記入して利用しましょう。
        </HelpBlock>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}>
          {pops?.map((pop) => (
            <PostDisplay
              key={pop.title}
              href={pop.download_url}
              altMessage={pop.title}
              image={pop.image_url}
              name={pop.title}
              height='auto'
              border='1px solid #000'
            />
          ))}
        </div>
      </div>

      <Box mb={2}>
        <Box fontSize={18} fontWeight={700}>
          店舗登録用QRコード
        </Box>
        <HelpBlock
          className='text-muted'
          style={{ fontSize: 11, marginBottom: 7 }}>
          お客様がQRコードを読み込むと、アプリのダウンロードと店舗の登録ができます。
        </HelpBlock>
        <Box display='flex' flexWrap='wrap'>
          {currentStore.store_qrcodes
            .filter((qrCode) => qrCode.data.indexOf('liff') === -1)
            .map((qrCode) => (
              <QrImageDisplay
                key={qrCode.id}
                label={
                  <>
                    作成日：{formatDateExceptTime(qrCode.created_at)}
                    <br />
                    読み取り数：{qrCode.scanned_count}
                  </>
                }
                href={qrCode.image_url}
                altMessage='店舗登録用QRコード'
                image={qrCode.image_url}
                filename={`${qrCode.name}.png`}
                onClickDeleteButton={(e) =>
                  onClickDeleteStoreQrcodes(e, qrCode.id)
                }
                id={String(qrCode.id)}
                name={qrCode.name}
                updateQrcodeName={onClickUpdateStoreQrcodeName}
                displayNotification={displayNotification}
                getStore={onUpdateQrcode}
              />
            ))}
        </Box>

        <Button
          size='small'
          variant='outlined'
          color='cancel'
          startIcon={<i className='ri-add-line' />}
          onClick={() => onClickCreateStoreQrcodes('toypo')}>
          QRコードを追加
        </Button>
      </Box>

      <Box mb={2}>
        <Box fontSize={18} fontWeight={700}>
          チェックインQRコード
        </Box>
        <HelpBlock
          className='text-muted'
          style={{ fontSize: 11, marginBottom: 7 }}>
          ダウンロードとチェックイン機能（スタンプカードや会員カード）の設定に応じたアクションができます。
          <a
            style={{ color: '#0076D7' }}
            href='https://toypo.notion.site/QR-708ed3233fba47bdb6f52d236820137f#aad9d7bf9ed047c3b765de60607a7f77'
            target='_blank'
            rel='noopener noreferrer'>
            詳しくはこちら
          </a>
        </HelpBlock>

        <Box display='flex' flexWrap='wrap'>
          {currentStore.check_in_qrcodes
            .filter((qrCode) => qrCode.data.indexOf('liff') === -1)
            .map((qrCode) => (
              <QrImageDisplay
                key={qrCode.id}
                label={
                  <>
                    作成日：{formatDateExceptTime(qrCode.created_at)}
                    <br />
                    読み取り数：{qrCode.scanned_count}
                  </>
                }
                href={qrCode.image_url}
                altMessage='チェックインQRコード'
                image={qrCode.image_url}
                filename={`${qrCode.name}.png`}
                onClickDeleteButton={(e) =>
                  onClickDeleteCheckInQrcodes(e, qrCode.id)
                }
                id={String(qrCode.id)}
                name={qrCode.name}
                updateQrcodeName={onClickUpdateCheckInQrcodeName}
                displayNotification={displayNotification}
                getStore={onUpdateQrcode}
              />
            ))}
        </Box>

        <Button
          size='small'
          variant='outlined'
          color='cancel'
          startIcon={<i className='ri-add-line' />}
          onClick={() => onClickCreateCheckInQrcodes('toypo')}>
          QRコードを追加
        </Button>
      </Box>

      {lineChannel && (
        <Box mb={2}>
          <Box fontSize={18} fontWeight={700} mb={1}>
            LINEミニアプリ：店舗登録用QRコード
          </Box>

          <Box display='flex' flexWrap='wrap'>
            {currentStore.store_qrcodes
              .filter((qrCode) => qrCode.data.indexOf('liff') > -1)
              .map((qrCode) => (
                <QrImageDisplay
                  key={qrCode.id}
                  label={
                    <>
                      作成日：{formatDateExceptTime(qrCode.created_at)}
                      <br />
                      読み取り数：{qrCode.scanned_count}
                    </>
                  }
                  href={qrCode.image_url}
                  altMessage='店舗登録用QRコード'
                  image={qrCode.image_url}
                  filename={`${qrCode.name}.png`}
                  onClickDeleteButton={(e) =>
                    onClickDeleteStoreQrcodes(e, qrCode.id)
                  }
                  id={String(qrCode.id)}
                  name={qrCode.name}
                  updateQrcodeName={onClickUpdateStoreQrcodeName}
                  displayNotification={displayNotification}
                  getStore={onUpdateQrcode}
                />
              ))}
          </Box>

          <Button
            size='small'
            variant='outlined'
            color='cancel'
            startIcon={<i className='ri-add-line' />}
            onClick={() => onClickCreateStoreQrcodes('liff')}>
            QRコードを追加
          </Button>
        </Box>
      )}

      {lineChannel && (
        <Box mb={2}>
          <Box fontSize={18} fontWeight={700} mb={1}>
            LINEミニアプリ：チェックインQRコード
          </Box>

          <Box display='flex' flexWrap='wrap'>
            {currentStore.check_in_qrcodes
              .filter((qrCode) => qrCode.data.indexOf('liff') > -1)
              .map((qrCode) => (
                <QrImageDisplay
                  key={qrCode.id}
                  label={
                    <>
                      作成日：{formatDateExceptTime(qrCode.created_at)}
                      <br />
                      読み取り数：{qrCode.scanned_count}
                    </>
                  }
                  href={qrCode.image_url}
                  altMessage='チェックインQRコード'
                  image={qrCode.image_url}
                  filename={`${qrCode.name}.png`}
                  onClickDeleteButton={(e) =>
                    onClickDeleteCheckInQrcodes(e, qrCode.id)
                  }
                  id={String(qrCode.id)}
                  name={qrCode.name}
                  updateQrcodeName={onClickUpdateCheckInQrcodeName}
                  displayNotification={displayNotification}
                  getStore={onUpdateQrcode}
                />
              ))}
          </Box>

          <Button
            size='small'
            variant='outlined'
            color='cancel'
            startIcon={<i className='ri-add-line' />}
            onClick={() => onClickCreateCheckInQrcodes('liff')}>
            QRコードを追加
          </Button>
        </Box>
      )}
    </FormGroup>
  )
}

export default PromotionalMaterialView
