import React, { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { FormProvider, useForm, Controller } from 'react-hook-form'
import { Prompt, useHistory } from 'react-router'
import { Grid, Row, Col, FormGroup, FormControl, Form } from 'react-bootstrap'
import { Backdrop, CircularProgress } from '@mui/material'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormHelperText from '@mui/material/FormHelperText'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { cloneDeep } from 'lodash'
import { AxiosError } from 'axios'
import NoticeEntityContainer, {
  NoticeStatus,
  Categories,
  NoticeStatusType,
  AutoPublishNoticeStatusToJpObj,
} from '../../../containers/entities/NoticeEntityContainer'
import NoticeTemplateEntityContainer from '../../../containers/entities/NoticeTemplateEntityContainer'
import { useLoginContext } from '../../../providers/LoginContextProvider'
import { Card } from '../../../components/Card/Card'
import LoadingButton from '../../../components/CustomButton/LoadingButton'
import Checkbox from '../../../components/CustomCheckbox/CustomCheckbox'
import FormLabel from '../../../components/FormInputs/FormLabel'
import CustomFormGroup from '../../../components/FormInputs/FormGroup'
import ImageTrimmingInput from '../../../components/ImageInput/ImageTrimmingInput'
import ChangeIndicator from '../../../components/ChangeIndicator'
import TargetUserForm from '../../../components/TargetUser'
import {
  useTargetUserConditionResourceConverter,
  useTargetUserConditionEffect,
  DefaultTargetUserCondition,
} from '../../../components/TargetUser/hooks'
import { validateTargetUserCondition } from '../../../components/TargetUser/utils'
import { getObjectDiff } from '../../../lib/general'
import { validateDate } from '../../../lib/validation'
import { usePcSizeFlag, useApi, useBooleanState } from '../../../lib/hooks'
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from '../../../types/common.d'
import { TargetUserConditionResource } from '../../../types/resource/targetUserCondition.d'
import AutoPublishFormCard from '../../../components/Card/AutoPublishFormCard'
import PublishNoticeCard from './PublishNoticeCard'
import AutoPublishNoticeActiveSettingCard from './AutoPublishNoticeActiveSettingCard'
import NoticePreview from './NoticePreview'
import FormInput from '../../../components/FormInputs/FormInput'
import {
  FormChangedAlertModal,
  Alert,
} from '../../../components/Modal/FormChangedAlertModal'
import NoticeContentBodyAIAsistantDialog from './NoticeContentBodyAIAsistantDialog'

const DefaultNoticeContent = {
  id: null,
  store_id: null,
  body: '',
  status: NoticeStatus.Unpublished,
  do_push: true,
  send_line_message: false,
  image_urls: [],
  created_at: '',
  publish_at: '',
  template_id: null,
  memo: '',
  line_message_type: 'normal_message',
}

const validateUrl = (value) => {
  const regExp =
    /https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#\u3000-\u30FE\u4E00-\u9FA0\uFF01-\uFFE3]+/g
  if (value && value.match(regExp)) {
    return true
  }
  return 'URLを入力してください'
}

const validatePhoneNumber = (value) => {
  const regExp = /^[0-9]{10,11}$/
  if (value && value.match(regExp)) {
    return true
  }
  return '電話番号を入力してください'
}

type Props = {
  defaultNoticeContent?: any
  defaultTargetUserCondition?: TargetUserConditionResource
  defaultQuestionnaireUrl?: string
  isAuto: boolean
  isDelivered?: boolean
  displayErrorNotification: DisplayErrorNotification
  displaySuccessNotification: DisplaySuccessNotification
  style: React.CSSProperties
}

type ButtonActionType = 'open_url' | 'telephone' | ''

const NoticeContentForm = ({
  defaultNoticeContent,
  defaultTargetUserCondition,
  defaultQuestionnaireUrl,
  isAuto,
  isDelivered = false,
  displayErrorNotification,
  displaySuccessNotification,
  style,
}: Props): JSX.Element => {
  const notice = defaultNoticeContent
  const isEditMode = Boolean(defaultNoticeContent?.id)
  const defaultActionType = notice?.button_url
    ? 'open_url'
    : notice?.button_phone_number
    ? 'telephone'
    : ''
  const { lineChannel } = useLoginContext()
  const isPcOrTablet = usePcSizeFlag()
  const api = useApi()
  const history = useHistory()
  const [selectedActionType, setSelectedActionType] =
    useState<ButtonActionType>(defaultActionType)
  const isOpenUrl = selectedActionType === 'open_url'
  const [isBackdropLoading, setIsBackdropLoading] = useState(false)
  const [
    isNoticeDeleteModalOpen,
    openNoticeDeleteModal,
    closeNoticeDeleteModal,
  ] = useBooleanState(false)
  const [isAiAsistantDialogOpen, openAiAsistantDialog, closeAiAsistantDialog] =
    useBooleanState(false)
  const convertToResource = useTargetUserConditionResourceConverter()
  const noticeEntityContainer = NoticeEntityContainer.useContainer()
  const noticeTemplateEntityContainer =
    NoticeTemplateEntityContainer.useContainer()
  const { noticeTemplates } = noticeTemplateEntityContainer.state
  const {
    updateNotice,
    createNotice,
    publishNotice,
    statusLabelBackgroundColor,
    categoryToJp,
  } = noticeEntityContainer.logic
  const { getNoticeTemplates } = noticeTemplateEntityContainer.logic

  const [loading, setLoading] = useState(false)
  const [repeatType, setRepeatType] = useState<'week' | 'date'>(
    notice?.publish_weeks?.length ? 'week' : 'date',
  )
  const isPublished = notice?.status === NoticeStatus.Publish
  const title = isAuto ? '自動配信お知らせ' : '通常配信お知らせ'
  const defaultNoticeContentValues = {
    notice: {
      title: Boolean(defaultQuestionnaireUrl)
        ? 'アンケート回答にご協力をお願いします'
        : '',
      button_url: defaultQuestionnaireUrl || '',
      button_label: Boolean(defaultQuestionnaireUrl) ? 'アンケートを回答' : '',
      ...(notice || DefaultNoticeContent),
    },
    target_user_condition: DefaultTargetUserCondition,
  }
  const methods = useForm({ defaultValues: defaultNoticeContentValues })

  const {
    register,
    unregister,
    handleSubmit,
    trigger,
    errors,
    watch,
    setValue,
    formState: { isDirty, dirtyFields },
    reset,
    getValues,
    control,
    setError,
    clearErrors,
  } = methods

  const [defaultTargetGroup, setDefaultTargetGroup] = useState<
    'all' | 'conditions' | 'collections' | 'specific_user'
  >('all')
  useTargetUserConditionEffect(
    defaultTargetUserCondition || null,
    (targetUserCondition) => {
      setValue('target_user_condition', targetUserCondition)
      setDefaultTargetGroup(targetUserCondition.targetGroup)
    },
  )

  const watchNoticeContent = watch('notice')
  const watchImages: Array<string> = watch('notice.image_urls', [])
  const watchCategory = watch('notice.category')
  const watchPublishDates: number[] = watch('notice.publish_dates')
  const watchPublishWeeks: number[] = watch('notice.publish_weeks')
  const watchDaysLater: number = watch('notice.days_later')
  const watchTargetUserCondition = watch('target_user_condition')

  const processFormData = async (data) => {
    const processedData = cloneDeep(data.notice)

    // imageがattachされていないがimage_urlが存在する状態のお知らせ(ActiveRecordの中間テーブルがない)が編集できないため、
    // 他の項目のみを編集する場合にimageをrequestに含めないように修正している。
    // templateから作成の場合に分岐に入るとエラーが出るために条件として記載。
    // TODO: imageがattachされていないがimage_urlが存在する状態のお知らせの編集時に画像がattachされてデータ不整合が発生してる状態を解消するように修正し以下のコードを削除する。
    const isImageChanged =
      JSON.stringify(processedData.image_urls) !==
      JSON.stringify(notice?.image_urls)
    if (isImageChanged || processedData.template_id) {
      processedData.images = processedData.image_urls
    }

    // 編集時に配信設定カード内のみで変更可
    if (isEditMode) {
      delete processedData.publish_at
      delete processedData.status
    }

    if (isAuto && watchDaysLater !== 0) {
      processedData.publish_time = `${String(
        processedData.publish_hour,
      ).padStart(2, '0')}:${String(processedData.publish_min).padStart(2, '0')}`
    }

    processedData.target_user_condition = await convertToResource(
      data.target_user_condition,
    )

    return processedData
  }

  const onClickCreateButton = async (data) => {
    const response = await createNotice(data).catch((error) => {
      displayErrorNotification(error.message)
    })
    if (!response) {
      return
    }

    displaySuccessNotification('お知らせを作成しました。')
    reset()
    history.goBack()
  }

  const onClickUpdateButton = async (data) => {
    await updateNotice(notice.id, data)
      .then(() => {
        displaySuccessNotification('編集を保存しました。')
        reset()
        history.goBack()
      })
      .catch((error) => {
        displayErrorNotification(error.message)
      })
  }

  const handleClickDeleteButton = () => {
    if (isDelivered) {
      openNoticeDeleteModal()
    } else {
      if (!window.confirm('このお知らせを削除しますか？')) return
      deleteNoticeContent()
    }
  }

  const deleteNoticeContent = async () => {
    const res = await api.api.delete(`/notice_contents/${notice.id}`)
    if (!res) return

    displaySuccessNotification('お知らせを削除しました。')
    reset()
    history.goBack()
  }

  const unpublishNotice = async () => {
    if (!window.confirm('下書きにしてよろしいですか？')) {
      return
    }

    try {
      await updateNotice(notice.id, { status: 'unpublished', publish_at: null })
      displaySuccessNotification('編集を保存しました。')
      reset()
      history.goBack()
    } catch (error) {
      displayErrorNotification((error as AxiosError).message)
    }
  }

  const publishNoticeByNow = async () => {
    if (!window.confirm('配信しますか？')) {
      return
    }

    try {
      await publishNotice(notice.id)
      reset()
      history.goBack()
      displaySuccessNotification('お知らせを配信しました。')
    } catch (error) {
      displayErrorNotification((error as AxiosError)?.message)
    }
  }

  const publishNoticeByReservation = async () => {
    const publishAt: Date = getValues('notice.publish_at')
    const validationResult = validateDate(publishAt)
    if (!validationResult.validation) {
      displayErrorNotification(validationResult.message)
      return
    }

    if (!window.confirm('配信予約しますか？')) {
      return
    }

    try {
      await updateNotice(notice.id, {
        publish_at: publishAt,
        status: 'reservation',
      })
      displaySuccessNotification('配信予約しました。')
      reset()
      history.goBack()
    } catch (error) {
      displayErrorNotification((error as AxiosError).message)
    }
  }

  const onClickPublishNoticeButton = async (status: NoticeStatusType) => {
    try {
      setIsBackdropLoading((prevIsBackdropLoading) => !prevIsBackdropLoading)

      switch (status) {
        case 'unpublished':
          await unpublishNotice()
          break
        case 'publish':
          await publishNoticeByNow()
          break
        case 'reservation':
          await publishNoticeByReservation()
          break
        default:
          break
      }
    } finally {
      setIsBackdropLoading(false)
    }
  }

  // 自動配信お知らせメソッド
  const onClickPublishAutoPublishNoticeButton = async (status) => {
    const isDirtyExceptStatus = !!Object.keys(dirtyFields.notice || {}).filter(
      (key) => key !== 'status',
    ).length
    const isSave = isDirtyExceptStatus
      ? window.confirm(
          '行った変更が保存されていない可能性があります。変更内容を保存しますか？',
        )
      : false
    if (isSave && !(await trigger())) {
      displayErrorNotification('値が正しく入力されていません。')
      return
    }
    const isPublish = window.confirm(
      `${AutoPublishNoticeStatusToJpObj[status]}にしますか？`,
    )
    if (!isSave && !isPublish) {
      return
    }

    const data = getValues()
    const params = isSave
      ? {
          ...getObjectDiff(notice, await processFormData(data)),
          status,
        }
      : { status }

    try {
      setIsBackdropLoading((prevIsBackdropLoading) => !prevIsBackdropLoading)
      await updateNotice(notice.id, params)
      displaySuccessNotification('変更しました。')
      reset()
      history.goBack()
    } catch (error) {
      displayErrorNotification((error as AxiosError).message)
    } finally {
      setIsBackdropLoading(false)
    }
  }

  const onSubmit = async (data) => {
    const formData = await processFormData(data)

    setLoading(true)
    if (isEditMode) {
      onClickUpdateButton(formData).finally(() => setLoading(false))
    } else {
      onClickCreateButton(formData).finally(() => setLoading(false))
    }
  }

  const onError = () =>
    displayErrorNotification('値が正しく入力されていません。')

  const ValidatePublishAt = useCallback(
    (publishAt) => {
      if (isEditMode) {
        return true
      }

      const status = getValues('notice.status')
      if (status !== NoticeStatus.Reservation) {
        return true
      }

      const validationResult = validateDate(publishAt)
      if (validationResult.validation) {
        return true
      }

      return validationResult.message
    },
    [isEditMode, getValues],
  )

  // custom register
  useEffect(() => {
    register('notice.type')
    register('notice.status')
    register('notice.image_urls')
    register('notice.publish_at', { validate: ValidatePublishAt })
    register('target_user_condition', { validate: validateTargetUserCondition })

    return () => {
      unregister('notice.type')
      unregister('notice.status')
      unregister('notice.image_urls')
      unregister('notice.publish_at')
      unregister('target_user_condition')
    }
  }, [register, unregister, ValidatePublishAt])

  useEffect(() => {
    if (!noticeTemplates.length) {
      getNoticeTemplates()
    }
  }, [getNoticeTemplates, noticeTemplates.length])

  const createImage = async (img) => {
    const newImages = [...watchImages, img]
    setValue('notice.image_urls', newImages, { shouldDirty: true })
  }

  const updateImage = async (newImage, id) => {
    const newImages = [...watchImages]
    newImages.splice(id, 1, newImage)

    setValue('notice.image_urls', newImages, { shouldDirty: true })
  }

  const deleteImage = async (index) => {
    if (!window.confirm('この画像を削除しますか？')) {
      return
    }

    const newImages = [...watchImages]
    newImages.splice(index, 1)
    setValue('notice.image_urls', newImages, { shouldDirty: true })
  }

  return (
    <FormProvider {...methods}>
      <Grid
        data-cy='notice-form-view-container'
        fluid
        className='content'
        style={style}
      >
        <div style={{ display: 'flex' }}>
          <Form
            data-cy='notice-form-view-form'
            onSubmit={handleSubmit(onSubmit, onError)}
            style={{ flex: 1 }}
          >
            {isEditMode && !isAuto && (
              <PublishNoticeCard
                data-cy='notice-form-view-publish-setting-card-top'
                editMode={isEditMode}
                notice={notice}
                onClickPublishNoticeButton={onClickPublishNoticeButton}
                statusLabelBackgroundColor={statusLabelBackgroundColor}
              />
            )}

            {isEditMode && isAuto && (
              <AutoPublishNoticeActiveSettingCard
                data-cy='auto-notice-form-view-publish-setting-card-top'
                editMode={isEditMode}
                notice={notice}
                onClickPublishAutoPublishNoticeButton={
                  onClickPublishAutoPublishNoticeButton
                }
              />
            )}

            <Row>
              <Col md={12}>
                <Card
                  title={isEditMode ? `${title}編集` : `${title}作成`}
                  content={
                    <>
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <StyledCheckBoxContainer>
                              <Checkbox
                                containerProps={{
                                  'data-cy': 'notice-form-view-do-push',
                                }}
                                number={1}
                                inline
                                name='notice.do_push'
                                inputRef={register}
                                label='プッシュ通知で配信'
                                ncol='col-md-3'
                                disabled={isPublished}
                              />
                              <Checkbox
                                style={{
                                  display: lineChannel ? 'block' : 'none',
                                }}
                                number={2}
                                inline
                                name='notice.send_line_message'
                                inputRef={register}
                                label='LINE公式アカウント'
                                ncol='col-md-3'
                                disabled={isPublished}
                              />

                              {isEditMode &&
                                Boolean(dirtyFields.notice?.do_push) && (
                                  <ChangeIndicator />
                                )}
                            </StyledCheckBoxContainer>
                          </FormGroup>
                        </Col>
                      </Row>

                      {!isEditMode && (
                        <Row>
                          <Col md={10}>
                            <FormGroup bsSize='large'>
                              <FormLabel label='テンプレート選択' />
                              <FormControl
                                componentClass='select'
                                name='notice.template_id'
                                inputRef={register}
                                onChange={({ target }: { target: any }) => {
                                  const targetId = Number(target.value)
                                  const selectedTemplate = noticeTemplates.find(
                                    ({ id }) => id === targetId,
                                  )
                                  if (selectedTemplate) {
                                    const templateSelectedActionType =
                                      selectedTemplate.button_url
                                        ? 'open_url'
                                        : selectedTemplate.button_phone_number
                                        ? 'telephone'
                                        : ''
                                    setSelectedActionType(
                                      templateSelectedActionType,
                                    )
                                    reset({
                                      notice: {
                                        ...watchNoticeContent,
                                        title: selectedTemplate.title,
                                        body: selectedTemplate.body,
                                        image_urls: selectedTemplate.image_urls,
                                        do_push: selectedTemplate.do_push,
                                        button_label:
                                          selectedTemplate.button_label,
                                        button_phone_number:
                                          selectedTemplate.button_phone_number,
                                        button_url: selectedTemplate.button_url,
                                        template_id: selectedTemplate.id,
                                      },
                                      target_user_condition:
                                        watchTargetUserCondition,
                                    })
                                  } else {
                                    // "テンプレートを使用しない"を選択したらフォームをクリアする
                                    setSelectedActionType(defaultActionType)
                                    reset(defaultNoticeContentValues)
                                  }
                                }}
                              >
                                <option value=''>
                                  テンプレートを使用しない
                                </option>
                                {noticeTemplates.map(({ id, name }) => (
                                  <option key={id} value={id}>
                                    {name}
                                  </option>
                                ))}
                              </FormControl>
                            </FormGroup>
                          </Col>
                        </Row>
                      )}

                      <Box mb={2}>
                        <FormLabel label='タイトル' />
                        <Box
                          display='flex'
                          gap={1}
                          alignItems={isPcOrTablet ? 'center' : 'flex-end'}
                          flexDirection={isPcOrTablet ? 'row' : 'column'}
                        >
                          <Controller
                            control={control}
                            name='notice.title'
                            rules={{ required: 'タイトルを入力してください。' }}
                            render={({ onChange, value }) => (
                              <OutlinedInput
                                data-cy='notice-form-view-notice-title'
                                fullWidth
                                placeholder='例: 今ならキャンペーン中！'
                                sx={{ flex: '1 0 0' }}
                                value={value}
                                onChange={(e) => onChange(e.target.value)}
                              />
                            )}
                          />

                          <Button
                            size='small'
                            variant='contained'
                            color='submit'
                            disabled={loading || !watchNoticeContent.title}
                            sx={isPcOrTablet ? { width: 200, height: 40 } : {}}
                            onClick={openAiAsistantDialog}
                          >
                            AIに本文を提案してもらう
                          </Button>
                        </Box>

                        {errors?.notice?.title?.message && (
                          <FormHelperText error>
                            {errors?.notice?.title?.message}
                          </FormHelperText>
                        )}

                        <NoticeContentBodyAIAsistantDialog
                          defaultTitle={watchNoticeContent.title}
                          open={isAiAsistantDialogOpen}
                          onClose={closeAiAsistantDialog}
                          onSubmit={(title, body) => {
                            closeAiAsistantDialog()
                            setValue('notice.title', title)
                            const newBody = watchNoticeContent.body
                              ? watchNoticeContent.body + '\n' + body
                              : body
                            setValue('notice.body', newBody)
                          }}
                        />
                      </Box>

                      <Box mb={2}>
                        <FormLabel label='本文' />
                        <Controller
                          control={control}
                          name='notice.body'
                          rules={{ required: '本文を入力してください。' }}
                          render={({ onChange, value }) => (
                            <OutlinedInput
                              data-cy='notice-form-view-notice-body'
                              multiline
                              rows={12}
                              value={value}
                              onChange={(e) => onChange(e.target.value)}
                              fullWidth
                            />
                          )}
                        />
                        {errors?.notice?.body?.message && (
                          <FormHelperText error>
                            {errors?.notice?.body?.message}
                          </FormHelperText>
                        )}
                      </Box>

                      <Row>
                        <Col md={12}>
                          <CustomFormGroup>
                            <FormLabel label='画像' attachment='任意' />
                            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                              {watchImages?.map((image, i) => (
                                <ImageTrimmingInput
                                  doTrimming={false}
                                  key={image}
                                  id={i}
                                  canDelete
                                  doCompress
                                  doDisplayImage
                                  width={200}
                                  height={130}
                                  altMessage='画像'
                                  image={image}
                                  updateImage={updateImage}
                                  deleteImage={deleteImage}
                                  maxWidth={undefined}
                                  maxHeight={undefined}
                                  inline={undefined}
                                  aspect={undefined}
                                />
                              ))}
                              {watchImages.flat().length !== 3 ? (
                                <ImageTrimmingInput
                                  doCompress
                                  doTrimming={false}
                                  width={200}
                                  height={130}
                                  id={undefined}
                                  doDisplayImage={false}
                                  updateImage={createImage}
                                  canDelete={undefined}
                                  image={undefined}
                                  maxWidth={undefined}
                                  maxHeight={undefined}
                                  altMessage={undefined}
                                  deleteImage={undefined}
                                  inline={undefined}
                                  aspect={undefined}
                                />
                              ) : null}
                            </div>
                          </CustomFormGroup>
                        </Col>
                      </Row>
                    </>
                  }
                />

                <Card
                  title='ボタン設置機能'
                  category='お知らせページの下部に指定したアクションを行うボタンを設置することができます。'
                  content={
                    <Box>
                      <Box mb={1}>
                        <FormLabel label='ボタン押下時のアクション' />
                        <RadioGroup
                          row
                          value={selectedActionType}
                          onChange={(e) => {
                            setSelectedActionType(
                              e.target.value as ButtonActionType,
                            )
                            setValue('notice.button_label', '')
                            setValue('notice.button_url', '')
                            setValue('notice.button_phone_number', '')
                          }}
                        >
                          <FormControlLabel
                            value=''
                            control={<Radio />}
                            label='設定しない'
                          />
                          <FormControlLabel
                            value='open_url'
                            control={<Radio />}
                            label='URLを開く'
                          />
                          <FormControlLabel
                            value='telephone'
                            control={<Radio />}
                            label='電話をかける'
                          />
                        </RadioGroup>
                      </Box>

                      {Boolean(selectedActionType) && (
                        <>
                          <Box mb={2}>
                            <FormLabel
                              label='ボタンのラベル'
                              attachment='任意'
                            />
                            <Controller
                              control={control}
                              name='notice.button_label'
                              render={({ onChange, value }) => (
                                <OutlinedInput
                                  placeholder={
                                    isOpenUrl ? '詳細を確認する' : '電話する'
                                  }
                                  value={value}
                                  onChange={(e) => onChange(e.target.value)}
                                  sx={{ maxWidth: isPcOrTablet ? 250 : null }}
                                  fullWidth
                                />
                              )}
                            />
                          </Box>

                          <Box mb={2}>
                            <FormLabel
                              label={
                                isOpenUrl
                                  ? 'ボタン押下時の遷移先URL'
                                  : 'ボタン押下時にかける電話番号'
                              }
                            />
                            <Controller
                              control={control}
                              name={
                                isOpenUrl
                                  ? 'notice.button_url'
                                  : 'notice.button_phone_number'
                              }
                              rules={{
                                validate: isOpenUrl
                                  ? validateUrl
                                  : validatePhoneNumber,
                              }}
                              render={({ onChange, value }) => (
                                <OutlinedInput
                                  type={isOpenUrl ? 'text' : 'tel'}
                                  placeholder={
                                    isOpenUrl
                                      ? 'https://example.com'
                                      : '09012345678'
                                  }
                                  value={value}
                                  onChange={(e) => onChange(e.target.value)}
                                  sx={{ maxWidth: 600 }}
                                  fullWidth
                                />
                              )}
                            />
                            {errors?.notice?.button_url?.message && (
                              <FormHelperText error>
                                {errors?.notice?.button_url?.message}
                              </FormHelperText>
                            )}
                            {errors?.notice?.button_phone_number?.message && (
                              <FormHelperText error>
                                {errors?.notice?.button_phone_number?.message}
                              </FormHelperText>
                            )}
                          </Box>
                        </>
                      )}
                    </Box>
                  }
                />

                <Card
                  title='配信対象者'
                  content={
                    <TargetUserForm
                      allLabel={
                        watchNoticeContent.send_line_message
                          ? 'すべてのユーザー・LINE友だち'
                          : null
                      }
                      disabled={!isAuto && isPublished}
                      targetUserCondition={watchTargetUserCondition}
                      errorMessage={errors?.target_user_condition?.['message']}
                      onChangeTargetUserCondition={(targetUserCondition) => {
                        clearErrors('target_user_condition')
                        setValue('target_user_condition', targetUserCondition, {
                          shouldDirty: true,
                        })
                      }}
                      showChangeIndicator={
                        isEditMode &&
                        watchTargetUserCondition?.targetGroup !==
                          defaultTargetGroup
                      }
                    />
                  }
                />

                {isAuto && (
                  <AutoPublishFormCard
                    name='notice'
                    control={control}
                    register={register}
                    editMode={!!isEditMode}
                    categories={Categories}
                    setValue={setValue}
                    errors={errors}
                    categoryToJp={categoryToJp}
                    category={watchCategory}
                    publishWeeks={watchPublishWeeks}
                    publishDates={watchPublishDates}
                    daysLater={watchDaysLater}
                    repeatType={repeatType}
                    setRepeatType={setRepeatType}
                    setError={setError}
                    clearErrors={clearErrors}
                  />
                )}
              </Col>
            </Row>

            {lineChannel && watchNoticeContent.send_line_message && (
              <Card
                title='LINEメッセージ'
                content={
                  <Controller
                    control={control}
                    name='notice.line_message_type'
                    render={({ onChange, value }) => (
                      <RadioGroup
                        row
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                      >
                        <FormControlLabel
                          value='normal_message'
                          control={<Radio />}
                          label='通常メッセージ'
                          disabled={isPublished}
                        />
                        <FormControlLabel
                          value='rich_message'
                          control={<Radio />}
                          label='リッチメッセージ'
                          disabled={isPublished}
                        />
                      </RadioGroup>
                    )}
                  />
                }
              />
            )}

            {!isEditMode &&
              (isAuto ? (
                <AutoPublishNoticeActiveSettingCard
                  data-cy='auto-notice-form-view-publish-setting-card-bottom'
                  editMode={isEditMode}
                  onClickPublishAutoPublishNoticeButton={
                    onClickPublishAutoPublishNoticeButton
                  }
                />
              ) : (
                <PublishNoticeCard
                  data-cy='notice-form-view-publish-setting-card-bottom'
                  editMode={isEditMode}
                  notice={notice}
                  onClickPublishNoticeButton={onClickPublishNoticeButton}
                  statusLabelBackgroundColor={statusLabelBackgroundColor}
                />
              ))}

            <Card
              title='目的・振り返り'
              category='施策の振り返りや評価のためのメモを残すことができます。このメモはユーザーからは見えません。'
              content={
                <Row>
                  <Row>
                    <Col md={12}>
                      <FormInput
                        data-cy='notice-form-view-notice-memo'
                        name='notice.memo'
                        label='メモ'
                        attachment='任意'
                        placeholder='例：女性向けに配信、雨の日に配信など'
                        ncol='col-md-12'
                        rows='5'
                        componentClass='textarea'
                        bsClass='form-control'
                        inputRef={register}
                        validationMessage={errors?.notice?.memo?.message}
                        showChangeIndicator={
                          isEditMode && Boolean(dirtyFields.notice?.memo)
                        }
                      />
                    </Col>
                  </Row>
                </Row>
              }
            />

            <Row style={{ marginBottom: 16 }}>
              <Col md={5} xs={6} mdOffset={1}>
                <Button
                  color='cancel'
                  variant='contained'
                  fullWidth
                  disabled={api.loading}
                  onClick={history.goBack}
                >
                  もどる
                </Button>
              </Col>
              <Col md={5} xs={6}>
                <LoadingButton
                  type='submit'
                  label={isEditMode ? '編集する' : '作成する'}
                  loadingLabel={isEditMode ? '編集中...' : '作成中...'}
                  color='info'
                  block
                  disabled={!isDirty}
                  fill
                  loading={loading}
                />
              </Col>
            </Row>

            {isEditMode && (
              <Row>
                <Col
                  md={6}
                  xs={8}
                  mdOffset={3}
                  xsOffset={2}
                  style={{ textAlign: 'center' }}
                >
                  <Button
                    color='danger'
                    onClick={handleClickDeleteButton}
                    disabled={api.loading}
                  >
                    このお知らせを削除する
                  </Button>
                </Col>
              </Row>
            )}
          </Form>

          {isPcOrTablet && (
            <div style={{ marginLeft: 16 }}>
              <NoticePreview noticeContent={watchNoticeContent} />
            </div>
          )}
        </div>

        <Prompt
          when={isDirty}
          message='行った変更が保存されていない可能性があります。このページを離れますか？'
        />
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isBackdropLoading}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      </Grid>

      <FormChangedAlertModal
        show={isNoticeDeleteModalOpen}
        title='お知らせを削除する'
        submitButtonLabel='削除する'
        danger
        disabled={api.loading}
        onSubmit={deleteNoticeContent}
        needAgreement
        onCancel={closeNoticeDeleteModal}
      >
        お知らせを削除してよろしいですか？
        <Alert severity='danger'>
          <ul>
            <li>
              ユーザーに配信されたお知らせはトイポアプリから表示されなくなります。
            </li>
          </ul>
        </Alert>
      </FormChangedAlertModal>
    </FormProvider>
  )
}

const StyledCheckBoxContainer = styled('div')(({ theme }) => ({
  backgroundColor: '#f0f1f4',
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
}))

export default NoticeContentForm
