import React, { useEffect, useState } from 'react'
import { useLoginContext } from '../../../providers/LoginContextProvider'
import { useNotification } from '../../../providers/NotificationProvider'
import LoadingButton from '../../../components/CustomButton/LoadingButton'
import OwnerStaffEntityContainer from '../../../containers/entities/OwnerStaffEntityContainer'
import StoreStaffEntityContainer from '../../../containers/entities/StoreStaffEntityContainer'
import StaffRoleModal from './EditStaffRoleModal'
import { useApi } from '../../../lib/hooks'
import { StaffBlock } from '../../../types/staff'
import { OwnerStoresResponse, StoreType } from '../../../types/store'

type Store = { id: number; name: string }
type Props = {
  show: boolean
  staff: StaffBlock
  onHide: () => void
  onAdded: () => void
}
const StaffRoleModalAddWrapper = ({
  show,
  staff,
  onHide,
  onAdded,
}: Props): JSX.Element => {
  const storeStaffContainer = StoreStaffEntityContainer.useContainer()
  const ownerStaffContainer = OwnerStaffEntityContainer.useContainer()
  const { giveAuthToStaff } = storeStaffContainer.logic
  const { giveOwnerToStaff } = ownerStaffContainer.logic
  const { currentStaff, currentStore } = useLoginContext()
  const { showSuccessNotification, showErrorNotification } = useNotification()
  const storesApi = useApi<OwnerStoresResponse>('/staff')
  const groupsApi = useApi<OwnerStoresResponse>('/staff')
  const stores = storesApi.response ?? []
  const groups = groupsApi.response ?? []
  const ownerGroup = groups.find((g) => g.store_type === 'owner_group')
  const [nonGrantedStores, setNonGrantedStores] = useState<Store[]>([])
  const [nonGrantedGroups, setNonGrantedGroups] = useState<Store[]>([])
  const [nonGrantedOwnerGroup, setNonGrantedOwnerGroup] =
    useState<Store | null>(null)
  const [selectedId, setSelectedId] = useState<number | null>(null)

  useEffect(() => {
    if (show && currentStaff.is_owner) {
      storesApi.api.get('/owner/stores')
      groupsApi.api.get('/owner/stores?type=group')
    }
  }, [show, currentStaff, storesApi.api, groupsApi.api])

  useEffect(() => {
    if (currentStaff.is_owner) {
      // オーナーのときは配下のグループ・全店舗の中から所属していないもののみ
      // 所属していない店舗を生成(追加時のみ)
      const grantedStoreIds = staff.stores?.map((s) => s.id)
      setNonGrantedStores(
        stores.filter((s) => !grantedStoreIds?.includes(s.id)),
      )

      // 所属していないオーナーグループを生成
      const isOwnerGroup = (store: { store_type: StoreType }) =>
        store.store_type === 'owner_group'

      const grantedOwnerGroupId = staff.groups.find(isOwnerGroup)?.id
      if (grantedOwnerGroupId) {
        setNonGrantedOwnerGroup(null)
      } else {
        setNonGrantedOwnerGroup(groups.find(isOwnerGroup) ?? null)
      }

      // 所属していないグループを生成(オーナーグループ以外)
      const grantedGroupIds = staff.groups?.map((group) => group.id)
      const newNonGrantedGroups = groups.filter(
        (group) => !isOwnerGroup(group) && !grantedGroupIds?.includes(group.id),
      )
      setNonGrantedGroups(newNonGrantedGroups)
    } else {
      // グループのときは配下のグループの中で所属していない店舗のみ
      const grantedStoreIds = staff.stores?.map((s) => s.id)
      const nonGrantedStores = currentStore.child_stores.filter(
        (s) => !grantedStoreIds?.includes(s.id),
      )
      setNonGrantedStores(nonGrantedStores)
      // グループは表示しないのでリセット
      setNonGrantedOwnerGroup(null)
      setNonGrantedGroups([])
    }
  }, [staff, stores, groups, currentStore, currentStaff.is_owner])

  const submitButton = (
    <LoadingButton
      type='submit'
      label='追加する'
      loadingLabel='追加中...'
      color='info'
      fill
      block
      disabled={!selectedId}
      onClick={async () => {
        const ownerGroupId = ownerGroup?.id
        const giveAuth = () => {
          if (ownerGroupId === selectedId) {
            return giveOwnerToStaff(staff.staff.id)
          }
          return giveAuthToStaff(staff.staff.id, selectedId!, currentStore.id)
        }

        giveAuth()
          .then(() => {
            showSuccessNotification('権限を追加しました。')
            onAdded()
            onHide()
          })
          .catch((error) => showErrorNotification(error.response.data.message))
          .finally(() => setSelectedId(null))
      }}
    />
  )

  return (
    <StaffRoleModal
      show={show}
      onHide={onHide}
      stores={[...(nonGrantedStores || [])]}
      groups={[...(nonGrantedGroups || [])]}
      ownerGroup={nonGrantedOwnerGroup}
      submitButton={submitButton}
      onClickRow={(newSelectId: number) => setSelectedId(newSelectId)}
      selectedIds={[selectedId]}
    />
  )
}

export default StaffRoleModalAddWrapper
