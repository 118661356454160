import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useLoginContext } from '../../../providers/LoginContextProvider'
import { open } from '../../../lib/general'

const TERMS_URL = 'https://toypo.notion.site/b42fbd7e43ca4588a13661258e30d90e'
const PRIVACY_POLICY_URL =
  'https://toypo.notion.site/71160018a21a41c3b2145b3ad863ecb5'

const SidebarFooter = (): JSX.Element => {
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { currentStaff, logout } = useLoginContext()
  const close = () => setAnchorEl(null)

  const handleClick = (handler: () => void) => () => {
    handler()
    close()
  }

  const handleLogout = () => {
    if (!window.confirm('本当にログアウトしますか？')) {
      return
    }
    logout()
  }

  return (
    <>
      <StyledAccountNameButton
        fullWidth
        onClick={(e) => setAnchorEl(e.currentTarget)}>
        {currentStaff.name}
      </StyledAccountNameButton>

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={close}>
        <MenuItem onClick={handleClick(() => history.push('/admin/account'))}>
          アカウント情報
        </MenuItem>
        <StyledLogoutMenu onClick={handleClick(handleLogout)}>
          ログアウト
        </StyledLogoutMenu>

        <StyledLinkMenu onClick={handleClick(() => open(TERMS_URL))}>
          利用規約
        </StyledLinkMenu>
        <StyledLinkMenu onClick={handleClick(() => open(PRIVACY_POLICY_URL))}>
          プライバシーポリシー
        </StyledLinkMenu>
      </Menu>
    </>
  )
}

const StyledAccountNameButton = styled(Button)(({ theme }) => ({
  color: '#fff',
  fontSize: 14,
  padding: theme.spacing(2, 2.5),
  borderTop: '1px solid #454B5A',
  borderRadius: 0,
  textAlign: 'left',
  justifyContent: 'flex-start',
  '&:hover': {
    backgroundColor: '#262F3E',
  },
}))

const StyledLogoutMenu = styled(MenuItem)({
  color: 'red',
})

const StyledLinkMenu = styled(MenuItem)({
  fontSize: 12,
})

export default SidebarFooter
