import { useState, useCallback, useMemo } from 'react'
import { createContainer } from 'unstated-next'
import { stampCardApi } from '../../lib/api'
import { formatDate } from '../../lib/general'

const useStampCardEntityHook = () => {
  const [stampCardContent, setStampCardContent] = useState({})

  const state = {
    stampCardContent,
  }

  const setEntityState = useMemo(
    () => ({
      stampCardContentInitialize: () => {
        setStampCardContent({})
      },
      stampCard: (nextStampCard) => {
        setStampCardContent(nextStampCard)
      },
    }),
    [],
  )

  const toStampCradParams = useCallback((data) => {
    const {
      maxStamp,
      connectionStamp,
      howToUses,
      checkInEnabled,
      specialCheckInEnabled,
      specialCheckInStamp,
      paymentBenefit,
      stampImage,
      stampValue,
    } = data
    return {
      max_stamp: maxStamp,
      connection_stamp: connectionStamp,
      how_to_uses: howToUses,
      check_in_enabled: checkInEnabled,
      special_check_in_enabled: specialCheckInEnabled,
      special_check_in_stamp: specialCheckInStamp,
      payment_benefit: paymentBenefit,
      stamp_image: stampImage,
      stamp_value: stampValue,
    }
  }, [])

  const toStampCardContentEntity = useCallback((data) => {
    const {
      id,
      store_id,
      max_stamp,
      connection_stamp,
      stamp_value,
      how_to_uses,
      active,
      welcome_qrcode_url,
      stamp_qrcodes,
      created_at,
      check_in_enabled,
      payment_benefit,
      stamp_image_url,
      special_check_in_enabled,
      special_check_in_stamp,
    } = data
    return {
      id,
      storeId: store_id,
      maxStamp: max_stamp,
      connectionStamp: connection_stamp,
      stampValue: stamp_value,
      howToUses: how_to_uses,
      active: active,
      checkInEnabled: check_in_enabled,
      paymentBenefit: payment_benefit,
      stampImageUrl: stamp_image_url,
      specialCheckInEnabled: special_check_in_enabled,
      specialCheckInStamp: special_check_in_stamp,
      welcomeQrcodeUrl: welcome_qrcode_url,
      stampQrcodes: stamp_qrcodes,
      createdAt: formatDate(created_at),
    }
  }, [])

  const bind = { toStampCradParams, toStampCardContentEntity }

  const api = {
    getStampCardContent: useCallback(async () => {
      const stampCard = await stampCardApi.getStampCardContent()
      return toStampCardContentEntity(stampCard.data)
    }, [toStampCardContentEntity]),
  }

  return { state, setEntityState, api, bind }
}

export default createContainer(useStampCardEntityHook)
