import React from 'react'
import Typography from '@mui/material/Typography'
import { ActivityLogResource, ActivityLogTag } from '../../../types/activityLog'

type ActivityLogBodyProps = {
  activityLog: ActivityLogResource
}
const ActivityLogBody = ({
  activityLog,
}: ActivityLogBodyProps): JSX.Element => {
  const canceled = activityLog.tags.some((tag: ActivityLogTag) =>
    ['cancel', 'refunded'].includes(tag.type),
  )

  return (
    <Typography
      color='#555555'
      style={{ textDecoration: canceled ? 'line-through' : 'none' }}>
      {calculateBody(activityLog)}
    </Typography>
  )
}

const calculateBody = (activityLog: ActivityLogResource): string => {
  if (['check_in', 'get_order'].includes(activityLog.action_type)) {
    return `お会計${(activityLog.amount || activityLog.value || 0).toLocaleString()}円`
  } else if (
    [
      'get_point',
      'get_check_in_point',
      'get_payment_point',
      'use_point',
      'expire_point',
      'remove_point',
    ].includes(activityLog.action_type)
  ) {
    return `${(activityLog.amount || activityLog.value || 0).toLocaleString()}ポイント`
  } else if (
    ['get_stamp', 'get_check_in_stamp', 'get_payment_stamp'].includes(
      activityLog.action_type,
    )
  ) {
    return `${(activityLog.amount || activityLog.value).toLocaleString()}スタンプ`
  } else if (
    [
      'get_ticket,',
      'get_subscription',
      'update_subscription',
      'use_house_money',
    ].includes(activityLog.action_type)
  ) {
    return `決済${(activityLog.amount || activityLog.value).toLocaleString()}円`
  } else if (
    ['charge_house_money_by_credit', 'charge_house_money_by_cash'].includes(
      activityLog.action_type,
    )
  ) {
    return `チャージ${(activityLog.amount || activityLog.value).toLocaleString()}円`
  }
  return ''
}

export default ActivityLogBody
