import moment from 'moment'
import { useState, useCallback } from 'react'
import { createContainer } from 'unstated-next'
import { userGroupApi } from '../../lib/api'
import {
  Genders,
  GenderLabel,
  Generations,
  GenerationLabel,
} from '../../constants/user'
import { UserGender, UserGeneration } from '../../types/user'

export type UserGroup = {
  id: number
  title: string | null
  description: string | null
  status: 'open' | 'close'
  is_history: boolean
  users_count: number
  all_users_count: number
  created_at: string
  user_group_conditions: UserGroupCondition[]
}

export type UserGroupCondition = {
  id?: number
  data_type: UserGroupType
  action_type?: 'count' | 'volume' | 'frequency' | 'last_action_days'
  user_group_id?: number
  value_from?: string
  value_to?: string
  scope_from?: string
  scope_to?: string
  title?: string
  segment_id?: number
}

export type UserGroupForm = {
  age?: UserGeneration
  fan_id?: number
  gender?: UserGender
  members_card_rank_id?: number
  registration_date_elapsed_days_from?: number
  registration_date_elapsed_days_to?: number
  registration_date_status?: RegistrationDateStatus
  registration_date_from?: string
  registration_date_to?: string
  visit_elapsed_days_from?: number
  visit_elapsed_days_to?: number
  visit_interval_from?: number
  visit_interval_to?: number
  visit_period_from?: string
  visit_period_to?: string
  visit_times_from?: number
  visit_times_to?: number
}

export type UserGroupType =
  // action系
  | 'visit'
  | 'connect_store'
  | 'reconnect_store'
  | 'delete_connection'
  | 'check_in'
  | 'use'
  | 'get'
  | 'buy'
  | 'order'
  // attribute系
  | 'gender'
  | 'age'
  | 'address'
  | 'distance'
  // other
  | 'rank'
  | 'user_group'
  | 'fan'

export type RegistrationDateStatus =
  | 'today'
  | 'yesterday'
  | 'this_week'
  | 'last_week'
  | 'this_month'
  | 'last_month'
  | 'specified_period'
  | 'specified_elapsed_days_from_registration'
export type RegistrationDateStatusJp =
  | '今日'
  | '昨日'
  | '今週'
  | '先週'
  | '今月'
  | '先月'
  | '期間を指定'
  | '登録からの経過日数を指定'

const registrationDates: RegistrationDateStatus[] = [
  'today',
  'yesterday',
  'this_week',
  'last_week',
  'this_month',
  'last_month',
  'specified_period',
  'specified_elapsed_days_from_registration',
]

const registrationDateStatusToJpObj = {
  today: '今日',
  yesterday: '昨日',
  this_week: '今週',
  last_week: '先週',
  this_month: '今月',
  last_month: '先月',
  specified_period: '期間を指定',
  specified_elapsed_days_from_registration: '登録からの経過日数を指定',
} as const
const ageToValue = {
  child: { value_from: '0', value_to: '9' },
  teens: { value_from: '10', value_to: '19' },
  twenties: { value_from: '20', value_to: '29' },
  thirties: { value_from: '30', value_to: '39' },
  forties: { value_from: '40', value_to: '49' },
  fifties: { value_from: '50', value_to: '59' },
  sixties: { value_from: '60' },
} as const

const formatStatusToRegistrationDate = (
  status: RegistrationDateStatus,
): { from?: string | null; to?: string | null } => {
  const format = 'YYYY/MM/DD HH:mm'
  const TODAY_START = moment().startOf('day').format(format)
  const TODAY_END = moment().endOf('day').format(format)
  const YESTERDAY_START = moment().add(-1, 'day').startOf('day').format(format)
  const YESTERDAY_END = moment().add(-1, 'day').endOf('day').format(format)
  const THIS_WEEK_START = moment().startOf('week').format(format)
  const THIS_WEEK_END = moment().endOf('week').format(format)
  const LAST_WEEK_START = moment()
    .add(-1, 'week')
    .startOf('week')
    .format(format)
  const LAST_WEEK_END = moment().add(-1, 'week').endOf('week').format(format)
  const THIS_MONTH_START = moment().startOf('month').format(format)
  const THIS_MONTH_END = moment().endOf('month').format(format)
  const LAST_MONTH_START = moment()
    .add(-1, 'month')
    .startOf('month')
    .format(format)
  const LAST_MONTH_END = moment().add(-1, 'month').endOf('month').format(format)

  switch (status) {
    case 'today':
      return {
        from: TODAY_START,
        to: TODAY_END,
      }
    case 'yesterday':
      return {
        from: YESTERDAY_START,
        to: YESTERDAY_END,
      }
    case 'this_week':
      return {
        from: THIS_WEEK_START,
        to: THIS_WEEK_END,
      }
    case 'last_week':
      return {
        from: LAST_WEEK_START,
        to: LAST_WEEK_END,
      }
    case 'this_month':
      return {
        from: THIS_MONTH_START,
        to: THIS_MONTH_END,
      }
    case 'last_month':
      return {
        from: LAST_MONTH_START,
        to: LAST_MONTH_END,
      }
    case 'specified_period':
      return {
        from: null,
        to: null,
      }

    default:
      return {}
  }
}

const formatUserGroupConditionsToUserGroupForm = (
  userGroupConditions: UserGroupCondition[],
): UserGroupForm => {
  const userGroupForm: UserGroupForm = {}
  const format = 'YYYY/MM/DD HH:mm'

  userGroupConditions.forEach((userGroupCondition) => {
    switch (userGroupCondition.data_type) {
      // 今のところuser_groupはファンのみ想定
      case 'user_group':
        userGroupForm.fan_id = userGroupCondition.segment_id
        break
      case 'rank':
        userGroupForm.members_card_rank_id = userGroupCondition.segment_id
        break
      case 'gender':
        userGroupForm.gender = userGroupCondition.value_from as UserGender
        break
      case 'age':
        if (
          userGroupCondition.value_from === '0' &&
          userGroupCondition.value_to === '9'
        ) {
          userGroupForm.age = 'child'
        } else if (
          userGroupCondition.value_from === '10' &&
          userGroupCondition.value_to === '19'
        ) {
          userGroupForm.age = 'teens'
        } else if (
          userGroupCondition.value_from === '20' &&
          userGroupCondition.value_to === '29'
        ) {
          userGroupForm.age = 'twenties'
        } else if (
          userGroupCondition.value_from === '30' &&
          userGroupCondition.value_to === '39'
        ) {
          userGroupForm.age = 'thirties'
        } else if (
          userGroupCondition.value_from === '40' &&
          userGroupCondition.value_to === '49'
        ) {
          userGroupForm.age = 'forties'
        } else if (
          userGroupCondition.value_from === '50' &&
          userGroupCondition.value_to === '59'
        ) {
          userGroupForm.age = 'fifties'
        } else if (userGroupCondition.value_from === '60') {
          userGroupForm.age = 'sixties'
        }
        break
      case 'connect_store':
        switch (userGroupCondition.action_type) {
          case 'count':
            userGroupForm.registration_date_status = 'specified_period'
            userGroupForm.registration_date_from = moment(
              userGroupCondition.scope_from,
            ).format(format)
            userGroupForm.registration_date_to = moment(
              userGroupCondition.scope_to,
            ).format(format)
            break
          case 'last_action_days':
            userGroupForm.registration_date_status =
              'specified_elapsed_days_from_registration'
            userGroupForm.registration_date_elapsed_days_from = Number(
              userGroupCondition.value_from,
            )
            userGroupForm.registration_date_elapsed_days_to = Number(
              userGroupCondition.value_to,
            )
            break
          default:
            break
        }
        break
      case 'visit':
        switch (userGroupCondition.action_type) {
          case 'count':
            userGroupForm.visit_times_from = Number(
              userGroupCondition.value_from,
            )
            userGroupForm.visit_times_to = Number(userGroupCondition.value_to)
            userGroupForm.visit_period_from = moment(
              userGroupCondition.scope_from,
            ).format(format)
            userGroupForm.visit_period_to = moment(
              userGroupCondition.scope_to,
            ).format(format)
            break
          case 'last_action_days':
            userGroupForm.visit_elapsed_days_from = Number(
              userGroupCondition.value_from,
            )
            userGroupForm.visit_elapsed_days_to = Number(
              userGroupCondition.value_to,
            )
            break
          case 'frequency':
            userGroupForm.visit_interval_from = Number(
              userGroupCondition.value_from,
            )
            userGroupForm.visit_interval_to = Number(
              userGroupCondition.value_to,
            )
            break
          default:
            break
        }
        break
      default:
        break
    }
  })

  return userGroupForm
}

const formatFormToUserGroupConditions = (
  userGroupForm: UserGroupForm,
): UserGroupCondition[] => {
  const userGroupConditions: UserGroupCondition[] = []

  if (userGroupForm.fan_id) {
    userGroupConditions.push({
      data_type: 'user_group',
      segment_id: userGroupForm.fan_id,
    })
  }

  if (userGroupForm.members_card_rank_id) {
    userGroupConditions.push({
      data_type: 'rank',
      segment_id: userGroupForm.members_card_rank_id,
    })
  }

  if (userGroupForm.gender) {
    userGroupConditions.push({
      data_type: 'gender',
      value_from: userGroupForm.gender,
    })
  }

  if (userGroupForm.age) {
    userGroupConditions.push({
      data_type: 'age',
      ...ageToValue[userGroupForm.age],
    })
  }

  if (
    userGroupForm.registration_date_from ||
    userGroupForm.registration_date_to
  ) {
    userGroupConditions.push({
      data_type: 'connect_store',
      action_type: 'count',
      value_from: '1',
      scope_from: userGroupForm.registration_date_from,
      scope_to: userGroupForm.registration_date_to,
    })
  }

  if (
    userGroupForm.registration_date_elapsed_days_from ||
    userGroupForm.registration_date_elapsed_days_to
  ) {
    userGroupConditions.push({
      data_type: 'connect_store',
      action_type: 'last_action_days',
      value_from: String(userGroupForm.registration_date_elapsed_days_from),
      value_to: String(userGroupForm.registration_date_elapsed_days_to),
    })
  }

  if (
    userGroupForm.visit_times_from ||
    userGroupForm.visit_times_to ||
    userGroupForm.visit_period_from ||
    userGroupForm.visit_period_to
  ) {
    userGroupConditions.push({
      data_type: 'visit',
      action_type: 'count',
      value_from: userGroupForm.visit_times_from
        ? String(userGroupForm.visit_times_from)
        : undefined,
      value_to: userGroupForm.visit_times_to
        ? String(userGroupForm.visit_times_to)
        : undefined,
      scope_from: userGroupForm.visit_period_from,
      scope_to: userGroupForm.visit_period_to,
    })
  }

  if (
    userGroupForm.visit_elapsed_days_from ||
    userGroupForm.visit_elapsed_days_to
  ) {
    userGroupConditions.push({
      data_type: 'visit',
      action_type: 'last_action_days',
      value_from: userGroupForm.visit_elapsed_days_from
        ? String(userGroupForm.visit_elapsed_days_from)
        : undefined,
      value_to: userGroupForm.visit_elapsed_days_to
        ? String(userGroupForm.visit_elapsed_days_to)
        : undefined,
    })
  }

  if (userGroupForm.visit_interval_from || userGroupForm.visit_interval_to) {
    userGroupConditions.push({
      data_type: 'visit',
      action_type: 'frequency',
      value_from: userGroupForm.visit_interval_from
        ? String(userGroupForm.visit_interval_from)
        : undefined,
      value_to: userGroupForm.visit_interval_to
        ? String(userGroupForm.visit_interval_to)
        : undefined,
    })
  }

  return userGroupConditions
}

const useUserGroupEntityHook = () => {
  const [fanUserGroups, setFanUserGroups] = useState<UserGroup[]>([])

  return {
    state: {
      fanUserGroups,
    },
    constant: { genders: Genders, ages: Generations, registrationDates },
    logic: {
      formatStatusToRegistrationDate,
      formatFormToUserGroupConditions,
      formatUserGroupConditionsToUserGroupForm,
      getUserGroups: async (type: UserGroupType): Promise<UserGroup[]> => {
        const res = await userGroupApi.getUserGroups(type)
        return res
      },
      getUserGroup: useCallback(async (id: number): Promise<UserGroup> => {
        const res = await userGroupApi.getUserGroup(id)
        return res
      }, []),
      getFanUserGroups: useCallback(async (): Promise<UserGroup[]> => {
        const res = await userGroupApi.getUserGroups('fan')
        setFanUserGroups(res)
        return res
      }, []),
      createUserGroup: async (params): Promise<UserGroup> => {
        const res = await userGroupApi.createUserGroup(params)
        return res
      },
      updateUserGroup: async ({ id, params }): Promise<UserGroup> => {
        const res = await userGroupApi.updateUserGroup({ id, params })
        return res
      },
      deleteUserGroupCondition: async (
        condition: UserGroupCondition,
        userGroup: UserGroup | undefined,
      ): Promise<UserGroup> => {
        const paramsUserGroup = {
          ...userGroup,
          user_group_conditions: userGroup?.user_group_conditions.filter(
            (c) => c.id !== condition.id,
          ),
        }
        const res = await userGroupApi.createUserGroup(paramsUserGroup)
        return res
      },
      genderToJp: (key: UserGender) => GenderLabel[key],
      ageToJp: (key: UserGeneration) => GenerationLabel[key],
      registrationDateStatusToJp: (
        key: RegistrationDateStatus,
      ): RegistrationDateStatusJp => registrationDateStatusToJpObj[key],
    },
  }
}

export default createContainer(useUserGroupEntityHook)
