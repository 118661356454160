import React from 'react'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import { useBooleanState } from '../../../lib/hooks'

type Props = {
  storeQrcodeUrl: string
  storeName: string
}

const StoreQrcode = ({ storeQrcodeUrl, storeName }: Props): JSX.Element => {
  const [active, , hide, toggle] = useBooleanState(false)

  return (
    <Box my={1} mx={2}>
      <StyledLabel active={active} onClick={toggle}>
        ミニアプリを確認する
      </StyledLabel>

      {active && (
        <StyledQrcodeContainer onClick={hide}>
          <Box color='#333' fontSize={14} mb={0.5}>
            {storeName}のQRコード
          </Box>
          <Box color='gray' fontSize={11} mb={0.5}>
            スマートフォンで読み取ってください
          </Box>

          <img
            src={storeQrcodeUrl}
            width='120'
            height='120'
            alt='店舗アプリQRコード'
          />
        </StyledQrcodeContainer>
      )}
    </Box>
  )
}

const StyledLabel = styled('div', {
  shouldForwardProp: (name: string) => name !== 'active',
})<{ active: boolean }>(({ active }) => ({
  display: 'flex',
  justifyContent: 'center',
  color: '#fff',
  backgroundColor: active ? '#2F3746' : 'transparent',
  padding: '8px 15px',
  fontSize: 12,
  alignItems: 'center',
  border: '1px solid #454B5A',
  borderRadius: 4,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#262F3E',
  },
}))

const StyledQrcodeContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(1),
  padding: '8px 15px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'white',
  borderRadius: 4,
}))

export default StoreQrcode
