import Axios from 'axios'
import apiClient from './apiClient'
import { loadViewObj } from '../viewObj'
import { downloadCSVFile, downloadFile } from '../general'

export const getTickets = async () => {
  const GET_TICKETS = `/staff/stores/${loadViewObj()?.id}/tickets`

  const response = await apiClient({
    method: 'GET',
    uri: GET_TICKETS,
  })
  return response.data
}

export const getTikcetSnapshots = async () => {
  const GET_TIKCET_SNAPSHOTS = `/staff/stores/${
    loadViewObj()?.id
  }/ticket_snapshots`

  const response = await apiClient({
    method: 'GET',
    uri: GET_TIKCET_SNAPSHOTS,
  })
  return response.data
}

export const downloadTicketsCsv = async () => {
  const viewObj = loadViewObj()
  const GET_TICKETS_CSV = `/staff/stores/${viewObj?.id}/tickets?format=csv`

  await apiClient({
    method: 'GET',
    uri: GET_TICKETS_CSV,
    responseType: 'blob',
  }).then((response) => downloadCSVFile(response))
}

export const downloadTicketsCsvbyS3 = async (url, filename) => {
  await Axios({
    method: 'get',
    url: url,
    responseType: 'blob',
    headers: {
      'Content-Disposition': 'attachment',
      'Content-Type': 'application/force-download',
    },
  }).then((response) => {
    const blob = new Blob([response.data], {
      type: 'text/csv',
    })
    downloadFile(blob, `${filename}.csv`)
  })
}
