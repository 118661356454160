import { useState } from 'react'
import { createContainer } from 'unstated-next'

const useConfigListViewHook = () => {
  const [cardData, setCardData] = useState([])
  const [creditCardStatus, setCreditCardStatus] = useState({})
  const [show, setShow] = useState(false)

  const state = {
    cardData,
    creditCardStatus,
    show,
  }

  const setViewState = {
    cardData: setCardData,
    creditCardStatus: setCreditCardStatus,
    show: setShow,
  }

  const logic = {
    convertCardStatusJp: (status) => {
      const statusJp = {
        passed: '審査通過',
        in_review: '審査中',
        declined: '審査否決',
      }
      return statusJp[status]
    },
    checkCard: (reviewedBrands) => {
      // tenantを渡す
      // 審査中かそうでないかのチェック
      if (reviewedBrands.length) {
        // reviewedBrandsのなかにpassedが一つでも入ってるかどうか
        const cardStatus = reviewedBrands.some(
          (value) => value.status === 'passed',
        )
        setCreditCardStatus(
          cardStatus
            ? { status: 'passed', statusJp: '申請済' }
            : {
                status: 'inReview',
                statusJp: 'ただいまクレジット利用申請中です。',
              },
        )
      } else {
        setCreditCardStatus({
          status: 'notReview',
          statusJp:
            'オーダー機能を使用するには、クレジット利用申請が必要です。',
        })
      }
    },
  }

  return { state, setViewState, logic }
}

export default createContainer(useConfigListViewHook)
