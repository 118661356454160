import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { getPageTitle } from './ToypoApp/parts/LoginRoute'

declare global {
  interface Window {
    ReactNativeWebView: {
      postMessage: (msg: string) => void
    }
  }
}

export const StoreAppWebViewPostHandler = () => {
  const { pathname, search, hash } = useLocation()

  useEffect(() => {
    if (window?.ReactNativeWebView) {
      const title = getPageTitle(pathname)
      const data = { eventType: 'navigation', pathname, search, hash, title }
      window?.ReactNativeWebView.postMessage(JSON.stringify(data))
    }
  }, [pathname, search, hash])

  return null
}

export default StoreAppWebViewPostHandler
