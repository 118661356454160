import React, { useState, useEffect, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { styled } from '@mui/system'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Pagination from '@mui/material/Pagination'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { useLoginContext } from '../../providers/LoginContextProvider'
import { useNotification } from '../../providers/NotificationProvider'
import {
  useApi,
  useGetApiCall,
  useMobileFlag,
  useQuery,
  useBooleanState,
} from '../../lib/hooks'
import {
  QuestionnaireFeatureResponse,
  RestaurantSatisfactionQuestionnairesResponse,
  RestaurantSatisfactionQuestionnaireBlock,
} from '../../types/questionnaire'
import { generateDefaultQuestionnaireRequestParams } from './defaultValues'
import StartPage from './parts/StartPage'
import LaptopQuestionnaireList from './parts/LaptopQuestionnaireList'
import MobileQuestionnaireList from './parts/MobileQuestionnaireList'

const SatisfactionQuestionnaireListView = () => {
  const history = useHistory()
  const isMobile = useMobileFlag()
  const { showSuccessNotification } = useNotification()
  const [isModalOpen, openModal, closeModal] = useBooleanState(false)
  const { currentStaff } = useLoginContext()
  const {
    questionnaires,
    totalPages,
    currentPage,
    changeUrlAndFetchQuestionnaires,
    loaded,
    reload,
  } = useQuestionnairesApi()
  const featureApi = useGetApiCall<QuestionnaireFeatureResponse>(
    '/questionnaire_features',
  )
  const questionnaireCreateApi = useApi()
  const questionnaireFeatureUpdateApi = useApi()

  const [selectedQuestionnaire, setSelectedQuestionnaire] =
    useState<RestaurantSatisfactionQuestionnaireBlock>()
  const featureResponse = featureApi.response?.questionnaire_feature
  const isCreatedQuestionnaire = Boolean(
    featureResponse?.restaurant_satisfaction_questionnaire_id,
  )

  const QuestionnaireList = isMobile
    ? MobileQuestionnaireList
    : LaptopQuestionnaireList

  const createQuestionnaire = async () => {
    const params = generateDefaultQuestionnaireRequestParams(
      currentStaff.id,
      Boolean(currentStaff.push_token),
    )
    const questionnaireResponse = await questionnaireCreateApi.api.post(
      '/questionnaires',
      params,
    )
    if (questionnaireResponse === null) return

    const questionnaireId = questionnaireResponse.data.questionnaire.id
    const questionnaireFeatureReponse =
      await questionnaireFeatureUpdateApi.api.put('/questionnaire_features', {
        restaurant_satisfaction_questionnaire_id: questionnaireId,
        default_restaurant_satisfaction_questionnaire_id: questionnaireId,
      })

    if (questionnaireFeatureReponse !== null) {
      showSuccessNotification('アンケートが作成されました')
      history.push(`/admin/satisfaction_questionnaire/${questionnaireId}`)
    }
  }

  const changeDefaultQuestionnaire = async (id) => {
    const params = { default_restaurant_satisfaction_questionnaire_id: id }
    await questionnaireFeatureUpdateApi.api.put(
      '/questionnaire_features',
      params,
    )
    showSuccessNotification('ホーム画面に表示するアンケートを変更しました')
    reload()
    closeModal()
  }

  return !loaded || !featureApi.loaded ? (
    <Box display='flex' justifyContent='center' my={5}>
      <CircularProgress />
    </Box>
  ) : !isCreatedQuestionnaire ? (
    <StartPage
      loading={
        questionnaireCreateApi.loading || questionnaireFeatureUpdateApi.loading
      }
      onCreateQuestionnaire={createQuestionnaire}
    />
  ) : (
    <>
      <StyledPaper
        variant={isMobile ? 'elevation' : 'outlined'}
        square={isMobile}
        isMobile={isMobile}>
        <Box mt={isMobile ? 0 : 2} p={isMobile ? 0 : 1}>
          <QuestionnaireList
            questionnaires={questionnaires}
            onClickRow={(id) =>
              history.push(`/admin/satisfaction_questionnaire/${id}`)
            }
            onClickPin={(questionnaire) => {
              setSelectedQuestionnaire(questionnaire)
              openModal()
            }}
          />

          {totalPages > 1 && (
            <Pagination
              sx={{ mt: 1 }}
              count={totalPages}
              page={currentPage}
              onChange={(_, page: number) =>
                changeUrlAndFetchQuestionnaires({ page })
              }
            />
          )}
        </Box>
      </StyledPaper>

      <Dialog maxWidth='xs' open={isModalOpen} onClose={closeModal}>
        <DialogContent>
          {`「${selectedQuestionnaire?.store.name}」の「${selectedQuestionnaire?.questionnaire.title}」をホーム画面に表示してよろしいですか？`}
        </DialogContent>
        <DialogActions>
          <Button
            color='cancel'
            variant='contained'
            fullWidth
            onClick={closeModal}>
            キャンセル
          </Button>

          <Button
            color='submit'
            variant='contained'
            fullWidth
            onClick={() =>
              changeDefaultQuestionnaire(
                selectedQuestionnaire?.questionnaire.id,
              )
            }>
            表示する
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

type QueryParams = {
  page: number
}

const useQuestionnairesApi = () => {
  const query = useQuery()
  const location = useLocation()
  const history = useHistory()
  const [queryParams, setQueryParams] = useState<QueryParams>({
    page: Number(query.get('page') || 1),
  })
  const { api, response, totalPages, currentPage, loading, loaded } =
    useApi<RestaurantSatisfactionQuestionnairesResponse>()
  const questionnaires = response?.questionnaires || []

  const changeUrl = useCallback(
    ({ page }: QueryParams) => {
      setQueryParams({ page })

      const searchParams = new URLSearchParams()
      searchParams.append('page', String(page))
      const newUrl = `${location.pathname}?${searchParams.toString()}`
      history.replace(newUrl)
    },
    [history, location.pathname],
  )

  const reloadQuestionnaires = useCallback(() => {
    api.get('/restaurant_satisfaction_questionnaires', {
      limit: 20,
      ...queryParams,
    })
  }, [api, queryParams])

  useEffect(() => {
    reloadQuestionnaires()
  }, [reloadQuestionnaires])

  return {
    questionnaires,
    totalPages,
    currentPage,
    reload: reloadQuestionnaires,
    loaded: loaded,
    loading: loading,
    changeUrlAndFetchQuestionnaires: changeUrl,
  }
}

const StyledPaper = styled(Paper, {
  shouldForwardProp: (prop: string) => 'isMobile' !== prop,
})<{ isMobile: boolean }>(({ theme, isMobile }) => ({
  ...(isMobile
    ? { marginTop: theme.spacing(2) }
    : { margin: theme.spacing(2) }),
}))

export default SatisfactionQuestionnaireListView
