import React, { ReactNode } from 'react'
import { styled } from '@mui/system'
import MuiAlert from '@mui/material/Alert'

type Props = {
  title?: string
  severity?: 'warning' | 'danger'
  children: ReactNode
}

const Alert = ({
  title = '注意事項',
  severity = 'warning',
  children,
}: Props): JSX.Element => {
  const muiSeverity = severity === 'danger' ? 'error' : severity

  return (
    <StyledAlert icon={<i className='ri-alert-line' />} severity={muiSeverity}>
      {Boolean(title) && <StyledTitle>{title}</StyledTitle>}

      {children}
    </StyledAlert>
  )
}

const StyledAlert = styled(MuiAlert)(({ theme }) => ({
  marginTop: theme.spacing(2),
  fontSize: 13,
  '& ul': {
    paddingLeft: theme.spacing(2),
  },
  '& li': {
    marginBottom: theme.spacing(0.5),
  },
}))

const StyledTitle = styled('div')(({ theme }) => ({
  fontSize: 15,
  fontWeight: 'bold',
  marginBottom: theme.spacing(0.5),
}))

export default Alert
