import React, { useState, FC, Dispatch, SetStateAction } from 'react'
import { BarLegendProps } from '@nivo/bar'

import { Col } from 'react-bootstrap'
import AnalyticsCardTooltip from './AnalyticsCardTooltip'
import AnalyticsBarGraphRightContent from './AnalyticsBarGraphRightContent'
import AnalyticsPeriodFilteringModal from './AnalyticsPeriodFilteringModal'

import BarGraph from '../../../components/Chart/BarGraph'
import { Card } from '../../../components/Card/Card'

import AnalyticsEntityContainer, {
  DataPeriod,
  DataUnit,
  BarGraphData,
  GraphSummary,
  dataPeriodTypeToPeriod,
  DataType,
} from '../../../containers/entities/AnalyticsEntityContainer'
import { DisplayNotification } from '../../../types/common.d'

type Props = {
  dataType: DataType
  data: BarGraphData | undefined
  summaries?: GraphSummary[]
  dataKeys: string[]
  dataColor: object
  dataPeriod: DataPeriod
  unit: string
  dataUnit: DataUnit
  maxValue?: number
  totalKey?: string
  setDataPeriod: Dispatch<SetStateAction<DataPeriod>>
  setDataUnit: Dispatch<SetStateAction<DataUnit>>
  convertDataKeyToJp
  displayNotification: DisplayNotification
  lg?: number
  style?: object
}

const axisLeftInteger = {
  tickSize: 0,
  format: (value): string =>
    Number.isInteger(value) ? value.toLocaleString() : '',
}

const legends = (keys: string[], color, dataKeyToJp): BarLegendProps[] => [
  {
    dataFrom: 'keys',
    anchor: 'top-right',
    direction: 'row',
    data: keys.map((key) => ({
      id: key,
      color: color[key],
      label: dataKeyToJp(key),
    })),
    justify: false,
    translateX: 0,
    translateY: -30,
    itemsSpacing: 2,
    itemWidth: 100,
    itemHeight: 20,
    itemDirection: 'left-to-right',
    itemOpacity: 0.85,
    symbolSize: 20,
    effects: [
      {
        on: 'hover',
        style: {
          itemOpacity: 1,
        },
      },
    ],
  },
]

const BarGraphCard: FC<Props> = ({
  dataType,
  data,
  summaries,
  dataKeys,
  dataColor,
  dataPeriod,
  dataUnit,
  unit,
  maxValue,
  totalKey,
  convertDataKeyToJp,
  setDataPeriod,
  setDataUnit,
  displayNotification,
  lg = 6,
  style,
}) => {
  const analyticsEntityContainer = AnalyticsEntityContainer.useContainer()
  const { setSelectedDataType } = analyticsEntityContainer.logic

  const [showDataPeriodModal, setShowDataPeriodModal] = useState(false)

  const onShowDataPeriodModal = () => setShowDataPeriodModal(true)
  const onHideDataPeriodModal = () => setShowDataPeriodModal(false)

  const setCustomDataPeriod = (formData: DataPeriod): void => {
    setDataPeriod({
      ...formData,
      type: 'custom',
    })
  }

  return (
    <>
      <Col lg={lg} style={style}>
        <Card
          title={data?.label}
          titleFontSize={20}
          titleRightContent={
            !!data?.description && (
              <AnalyticsCardTooltip description={data.description} />
            )
          }
          rightContent={
            <AnalyticsBarGraphRightContent
              dataType={dataType}
              dataUnit={dataUnit}
              dataPeriod={dataPeriod}
              setSelectedDataType={setSelectedDataType}
              setDataUnit={setDataUnit}
              setDataPeriod={setDataPeriod}
              onShowDataPeriodModal={onShowDataPeriodModal}
              dataPeriodTypeToPeriod={dataPeriodTypeToPeriod}
            />
          }
          content={
            <div style={{ height: 430 }}>
              <BarGraph
                data={data?.data}
                layout='vertical'
                indexBy='date'
                enableGridY
                maxValue={maxValue}
                keys={dataKeys}
                color={dataColor}
                axisLeft={axisLeftInteger}
                height={300}
                unit={unit}
                dataKeyToJp={convertDataKeyToJp}
                legends={legends(dataKeys, dataColor, convertDataKeyToJp)}
                totalKey={totalKey}
                dataUnit={dataUnit}
                summaries={summaries}
              />
            </div>
          }
        />
      </Col>

      <AnalyticsPeriodFilteringModal
        dataPeriod={dataPeriod}
        show={showDataPeriodModal}
        setDataPeriod={setCustomDataPeriod}
        onHide={onHideDataPeriodModal}
        displayNotification={displayNotification}
      />
    </>
  )
}

export default BarGraphCard
