import { storage } from './general'

export type ViewObjType = {
  type: 'group' | 'store'
  id: number
  name: string
  status: string
  isOwnerGroup: boolean
  role: 'admin' | 'member'
  isSetup?: boolean
}

export const loadViewObj = (): ViewObjType | null => {
  const viewObj = storage.getItem('selectedViewObj')
  if (viewObj) {
    return JSON.parse(viewObj)
  }
  return null
}

export const setViewObj = ({
  type,
  id,
  name,
  status,
  isOwnerGroup,
  role,
  isSetup,
}: ViewObjType): void => {
  const viewObj = {
    type,
    id,
    name,
    status,
    isOwnerGroup,
    role,
    isSetup,
  }

  storage.setItem('selectedViewObj', JSON.stringify(viewObj))
}

export const removeViewObj = (): boolean => {
  try {
    storage.removeItem('selectedViewObj')
    return true
  } catch (error) {
    return false
  }
}

export const isViewObjAdmin = () => loadViewObj()?.role === 'admin'

export const isViewObjStore = (obj: ViewObjType | null = null) => {
  const viewObj = obj || loadViewObj()
  return viewObj?.type === 'store'
}

export const isViewObjGroup = (obj: ViewObjType | null = null) => {
  const viewObj = obj || loadViewObj()
  return viewObj?.type === 'group'
}

export const isViewObjOwnerGroup = () => !!loadViewObj()?.isOwnerGroup
