import React from 'react'
import styled from '@emotion/styled'

type Props = {
  value: number
  prefix?: string
}

const ContentSalesEffect = ({ value, prefix = '¥' }: Props): JSX.Element => {
  return (
    <StyledContainer>
      <div>{prefix + value.toLocaleString()}</div>
    </StyledContainer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  color: #26aba4;
  font-weight: bold;
`

export default ContentSalesEffect
