import React, { ComponentPropsWithoutRef } from 'react'

type Props = ComponentPropsWithoutRef<'input'> & {
  inputRef?: React.RefObject<HTMLInputElement>
  disabled?: boolean
}

const TimeForm = ({
  inputRef,
  disabled = false,
  style = {},
  ...otherProps
}: Props): JSX.Element => (
  <input
    type='time'
    ref={inputRef}
    disabled={disabled}
    style={{
      width: 120,
      padding: '8px 12px',
      borderColor: '#E3E3E3',
      borderWidth: 1,
      borderRadius: 4,
      fontSize: 18,
      color: '#565656',
      backgroundColor: disabled ? '#F3F3F3' : 'white',
      borderStyle: 'solid',
      ...style,
    }}
    {...otherProps}
  />
)
export default TimeForm
