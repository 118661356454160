import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { Controller, useFormContext } from 'react-hook-form'

import { Card } from '../../../components/Card/Card'
import LoadingButton from '../../../components/CustomButton/LoadingButton'
import FormInputs from '../../../components/FormInputs/FormInputs'
import SwitchForm from '../../../components/FormInputs/SwitchForm'

import { MembersCardStatusType } from '../../../containers/entities/MembersCardContentEntityContainer'
import { useNotification } from '../../../providers/NotificationProvider'
import { MembersCardForm } from '../type.d'

interface Props {
  membersCardContentStatus: MembersCardStatusType
  disabled: boolean
  onSubmit: () => void
}

const MembersCardSettingCard: React.FC<Props> = ({
  membersCardContentStatus,
  disabled,
  onSubmit,
}) => {
  const { showErrorNotification } = useNotification()
  const { register, control, errors, setValue, formState, handleSubmit } =
    useFormContext<MembersCardForm>()
  const { dirtyFields } = formState

  const onError = () => {
    showErrorNotification('値が正しく入力されていません。')
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)}>
      <Row>
        <Col xs={12} lg={10}>
          <Card
            title='基本設定'
            content={
              <>
                <FormInputs
                  properties={[
                    {
                      disabled: disabled,
                      name: 'display_store_name',
                      label: 'カード表示用店舗名',
                      type: 'text',
                      bsClass: 'form-control',
                      ncol: 'col-md-6',
                      tooltipText: '会員カードに表示する店舗名を指定できます',
                      inputRef: register({
                        required: 'カード表示用店舗名を入力してください。',
                      }),
                      validationMessage: errors?.display_store_name?.message,
                      showChangeIndicator: dirtyFields.display_store_name,
                    },
                    {
                      disabled: disabled,
                      name: 'how_to_get',
                      label: 'ため方',
                      type: 'text',
                      bsClass: 'form-control',
                      ncol: 'col-md-6',
                      inputRef: register,
                      attachment: '任意',
                      tooltipText: 'この説明は会員カード上に表示されます。',
                      validationMessage: errors?.how_to_get?.message,
                      showChangeIndicator: dirtyFields.how_to_get,
                    },
                    {
                      disabled: disabled,
                      name: 'usage_text',
                      label: '会員カードの詳しい説明',
                      ncol: 'col-md-12',
                      rows: '6',
                      componentClass: 'textarea',
                      bsClass: 'form-control',
                      inputRef: register,
                      attachment: '任意',
                      tooltipText:
                        'この説明は会員カードページの下部に表示されます。',
                      placeholder:
                        '例: ランクによって色々な特典がもらえるお得な会員カードです!',
                      validationMessage: errors?.usage_text?.message,
                      showChangeIndicator: dirtyFields.usage_text,
                    },
                    {
                      // 会員コード接頭辞
                      disabled:
                        membersCardContentStatus !== 'draft' || disabled,
                      name: 'member_code_prefix',
                      label: '会員コード接頭辞',
                      type: 'text',
                      bsClass: 'form-control',
                      ncol: 'col-md-6',
                      attachment: '任意',
                      tooltipText:
                        '会員コードの先頭文字を指定できます。（5文字まで）',
                      inputRef: register({
                        maxLength: {
                          value: 5,
                          message: '接頭辞の入力は5文字までです。',
                        },
                      }),
                      validationMessage: errors?.member_code_prefix?.message,
                      showChangeIndicator: dirtyFields.member_code_prefix,
                    },
                  ]}
                />

                <Controller
                  control={control}
                  name='rank_enabled'
                  render={({ value }) => (
                    <SwitchForm
                      disabled={disabled}
                      label='ランク機能'
                      checked={value}
                      onChange={(isChecked): void => {
                        setValue('rank_enabled', isChecked, {
                          shouldDirty: true,
                        })
                      }}
                      showChangeIndicator={dirtyFields.rank_enabled}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name='point_enabled'
                  render={({ value }) => (
                    <SwitchForm
                      label='ポイント機能'
                      checked={value}
                      onChange={(isChecked): void => {
                        setValue('point_enabled', isChecked, {
                          shouldDirty: true,
                        })
                      }}
                      showChangeIndicator={dirtyFields.point_enabled}
                    />
                  )}
                />

                <Row>
                  <Col xs={12}>
                    <LoadingButton
                      type='submit'
                      label='更新する'
                      loadingLabel='更新中...'
                      color='info'
                      fill
                      pullRight
                      disabled={
                        Object.keys(dirtyFields).length === 0 || disabled
                      }
                    />
                  </Col>
                </Row>
              </>
            }
          />
        </Col>
      </Row>
    </Form>
  )
}

export default MembersCardSettingCard
