import React from 'react'
import Select, { SelectProps } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { calculateGeneration } from '../../lib/general'
import { Generations, GenerationLabel } from '../../constants/user'
import { UserGeneration } from '../../types/user'

type Props = Omit<SelectProps, 'onChange'> & {
  disableEmpty?: boolean
  emptyLabel?: string
  age: number | null
  onChange: (
    generation: UserGeneration | null,
    range: [number, number] | null,
  ) => void
}

const GenerationSelectForm = ({
  disableEmpty = false,
  emptyLabel = 'すべて',
  age,
  onChange,
  ...otherProps
}: Props): JSX.Element => {
  const generation = age === null ? null : calculateGeneration(age)

  return (
    <Select
      value={generation ?? 'all'}
      onChange={(e) => {
        const { value } = e.target
        if (value === 'all') {
          onChange(null, null)
        } else {
          const generation = value as UserGeneration
          onChange(generation, calculateRange(generation))
        }
      }}
      {...otherProps}>
      {!disableEmpty && <MenuItem value='all'>{emptyLabel}</MenuItem>}
      {Generations.map((generation) => (
        <MenuItem key={generation} value={generation}>
          {GenerationLabel[generation]}
        </MenuItem>
      ))}
    </Select>
  )
}

const calculateRange = (generation: UserGeneration): [number, number] => {
  switch (generation) {
    case 'child':
      return [0, 9]
    case 'teens':
      return [10, 19]
    case 'twenties':
      return [20, 29]
    case 'thirties':
      return [30, 39]
    case 'forties':
      return [40, 49]
    case 'fifties':
      return [50, 59]
    case 'sixties':
      return [60, 69]
    case 'seventies':
      return [70, 79]
    default:
      return [80, 200]
  }
}

export default GenerationSelectForm
