import React from 'react'
import { Row } from 'react-bootstrap'
import FormInput from './FormInput'

const FormInputs = ({ properties, ...otherProps }) =>
  properties.map((property, i) => {
    if (property.hide) return null
    if (property.inline)
      return <FormInput key={`form-inputs-${i}`} {...property} />
    return (
      <Row key={`form-inputs-${i}`} {...otherProps}>
        <FormInput {...property} />
      </Row>
    )
  })

export default FormInputs
