import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import {
  OptionResponse,
  OptionTemplatesResponse,
  OptionItemsResponse,
} from '../../types/menu'
import { useNotification } from '../../providers/NotificationProvider'
import FormLabel from '../../components/FormInputs/FormLabel'
import Section from '../../components/Section'
import { useApi, useGetApiCall, useQuery } from '../../lib/hooks'

type OptionFormType = {
  title: string
  option_template_id: number
  option_item_ids: number[]
  is_required: boolean
  is_multiple: boolean
}

const OptionFormView = () => {
  const history = useHistory()
  const { showSuccessNotification } = useNotification()
  const query = useQuery()
  const menuId = Number(query.get('menu_id'))
  const optionId = Number(query.get('option_id'))
  const updateApi = useApi()
  const optionApi = useApi<OptionResponse>()
  const optionItemsApi = useGetApiCall<OptionItemsResponse>('/option_items')
  const optionTemplatesApi =
    useGetApiCall<OptionTemplatesResponse>('/option_templates')
  const optionLoaded = !optionId || optionApi.loaded
  const optionItemsLoaded = optionItemsApi.loaded
  const optionTemplatesLoaded = optionTemplatesApi.loaded
  const optionTemplates = optionTemplatesApi.response?.option_templates ?? []
  const editMode = Boolean(optionId)
  const defaultValues = generateDefaultValues()
  const { handleSubmit, reset, errors, control, setValue, watch } =
    useForm<OptionFormType>({ defaultValues })
  const watchValues = watch()
  const findTemplate = (id: number) =>
    optionTemplates.find((v) => v.option_template.id === id)
  const selectedTemplate = findTemplate(watchValues.option_template_id)
  const currentOptionItems = selectedTemplate
    ? selectedTemplate.option_items
    : (optionItemsApi.response ?? [])
  const canSubmit = watchValues.option_template_id > -1

  useEffect(() => {
    if (!menuId) {
      history.push('/')
    }
  }, [menuId, history])

  useEffect(() => {
    if (menuId && optionId) {
      optionApi.api
        .get(`/menus/${menuId}/options/${optionId}`)
        .then((response) => {
          const resetValues = generateDefaultValues(response.data)
          reset(resetValues)
        })
    }
  }, [menuId, optionId, optionApi.api, reset])

  const submit = async (formValues: OptionFormType) => {
    const templateId =
      formValues.option_template_id === 0 ? null : formValues.option_template_id
    const option = { ...formValues, option_template_id: templateId }
    const params = { option }
    const response = editMode
      ? await updateApi.api.put(`/menus/${menuId}/options/${optionId}`, params)
      : await updateApi.api.post(`/menus/${menuId}/options`, params)
    if (response === null) return

    showSuccessNotification(
      editMode ? '編集を保存しました。' : 'オプションを作成しました。',
    )
    history.goBack()
  }

  const deleteOption = async () => {
    if (!editMode || !window.confirm('このオプションを削除しますか？')) {
      return
    }

    const response = await updateApi.api.delete(
      `/menus/${menuId}/options/${optionId}`,
    )
    if (response === null) return

    showSuccessNotification('オプションを削除しました。')
    history.goBack()
  }

  return !optionLoaded || !optionItemsLoaded || !optionTemplatesLoaded ? (
    <Section title='オプション設定'>
      <CircularProgress />
    </Section>
  ) : (
    <>
      <Section title='オプション設定'>
        <Box mb={3}>
          <FormLabel label='オプション' />
          <Controller
            control={control}
            name='option_template_id'
            render={({ onChange, value }) => (
              <Select
                displayEmpty
                value={value}
                sx={{ width: 350 }}
                onChange={(e) => {
                  const templateId = e.target.value
                  const template = findTemplate(templateId)

                  if (templateId === 0) {
                    setValue('option_item_ids', [])
                    setValue('title', '')
                    setValue('is_required', false)
                    setValue('is_multiple', false)
                  } else if (template) {
                    setValue(
                      'option_item_ids',
                      template.option_items.map(({ id }) => id),
                    )
                    setValue('title', template.option_template.title)
                    setValue(
                      'is_required',
                      template.option_template.is_required,
                    )
                    setValue(
                      'is_multiple',
                      template.option_template.is_multiple,
                    )
                  }

                  onChange(templateId)
                }}>
                <MenuItem disabled value={-1}>
                  オプションを選択してください
                </MenuItem>

                {optionTemplates.map(({ option_template }) => (
                  <MenuItem key={option_template.id} value={option_template.id}>
                    {option_template.title}
                  </MenuItem>
                ))}

                <MenuItem value={0}>
                  自分でオプションメニューを選択する
                </MenuItem>
              </Select>
            )}
          />
          {errors.option_template_id?.message && (
            <FormHelperText error>
              {errors.option_template_id.message}
            </FormHelperText>
          )}
        </Box>

        <Box
          mb={2}
          display={watchValues.option_template_id === 0 ? 'block' : 'none'}>
          <FormLabel label='オプション名' />
          <Controller
            name='title'
            control={control}
            rules={{ required: 'オプション名を入力してください。' }}
            render={({ onChange, value }) => (
              <OutlinedInput
                error={Boolean(errors.title)}
                placeholder='例: 小鉢、サイズなど'
                fullWidth
                sx={{ maxWidth: 400 }}
                value={value}
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
          {errors.title?.message && (
            <FormHelperText error>{errors.title?.message}</FormHelperText>
          )}
        </Box>

        <Box display={watchValues.option_template_id > -1 ? 'block' : 'none'}>
          <Box mb={2}>
            <FormLabel label='オプションの選択について' />

            <Controller
              name='is_required'
              control={control}
              render={({ onChange, value }) => (
                <FormControlLabel
                  label='選択を必須にする'
                  control={
                    <Checkbox
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                />
              )}
            />

            <Controller
              name='is_multiple'
              control={control}
              render={({ onChange, value }) => (
                <FormControlLabel
                  label='複数選択を可能にする'
                  control={
                    <Checkbox
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                    />
                  }
                />
              )}
            />
          </Box>

          <Box mb={3}>
            <FormLabel label='オプションメニュー' />
            <Controller
              name='option_item_ids'
              control={control}
              render={({ onChange, value }) => (
                <>
                  <Box display='flex' flexWrap='wrap'>
                    {currentOptionItems.map((optionItem) => (
                      <StyledFormControlLabel
                        key={optionItem.id}
                        label={`${optionItem.title} (${optionItem.price?.toLocaleString() || 0}円)`}
                        control={
                          <Checkbox
                            checked={value.some(
                              (id: number) => id === optionItem.id,
                            )}
                            onChange={(e) => {
                              const newValue = e.target.checked
                                ? [...value, optionItem.id]
                                : value.filter(
                                    (id: number) => id !== optionItem.id,
                                  )
                              onChange(newValue)
                            }}
                          />
                        }
                      />
                    ))}
                  </Box>
                </>
              )}
            />
          </Box>
        </Box>
      </Section>

      <StyledActionContainer>
        <Button
          variant='outlined'
          color='cancel'
          disabled={updateApi.loading}
          onClick={() => history.goBack()}>
          もどる
        </Button>

        <Button
          variant='contained'
          color='submit'
          disabled={!canSubmit || updateApi.loading}
          onClick={handleSubmit(submit)}>
          {editMode ? '変更を保存する' : 'オプションを作成する'}
        </Button>
      </StyledActionContainer>

      {editMode && (
        <Box display='flex' justifyContent='center'>
          <Button
            color='danger'
            disabled={updateApi.loading}
            onClick={deleteOption}>
            このオプションを削除する
          </Button>
        </Box>
      )}
    </>
  )
}

const generateDefaultValues = (
  option: OptionResponse | null = null,
): OptionFormType => ({
  title: option?.option.title ?? '',
  option_item_ids: option?.option_items.map(({ id }) => id) ?? [],
  is_required: option?.option.is_required ?? false,
  is_multiple: option?.option.is_multiple ?? false,
  // 新規作成の際は "オプションを選択してください" を選ばせておく
  // 編集の際、テンプレートIDが指定されていればそれを選択状態にしておき
  // テンプレートIDが指定されていなければ "自分でオプションメニューを選択する" を選ばせておく
  option_template_id:
    option === null ? -1 : (option.option.option_template_id ?? 0),
})

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  height: 'auto',
  width: `calc(90%/3)`,
  [theme.breakpoints.down('laptop')]: {
    width: '100%',
  },
  display: 'flex',
}))

const StyledActionContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  '& > button': {
    width: 300,
  },
}))

export default OptionFormView
