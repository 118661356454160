import React from 'react'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { OrderDateWeek } from '../../../types/order'
import TimeForm from '../../../components/FormInputs/TimeForm'
import FormLabel from '../../../components/FormInputs/FormLabel'

const WeekdayLabels = {
  mon: '月曜',
  tue: '火曜',
  wed: '水曜',
  thu: '木曜',
  fri: '金曜',
  sat: '土曜',
  sun: '日曜',
  hol: '祝日',
} as const

export type OrderDateFormType = {
  week: OrderDateWeek
  active: boolean
  from_time: string
  to_time: string
  receive_from_time: string
  receive_to_time: string
}

type Props = {
  orderDate: OrderDateFormType
  openingTimeDisabled: boolean
  receivedTimeDisabled: boolean
  onChange: (params: Partial<OrderDateFormType>) => void
}

const OrderDateForm = ({
  orderDate,
  openingTimeDisabled,
  receivedTimeDisabled,
  onChange,
}: Props): JSX.Element => {
  return (
    <>
      <FormControlLabel
        sx={{ mb: 0 }}
        control={
          <Checkbox
            checked={orderDate.active}
            onChange={(e) => onChange({ active: e.target.checked })}
          />
        }
        label={WeekdayLabels[orderDate.week]}
      />

      <Box display='flex' flexWrap='wrap' gap={1}>
        <Box mr={3}>
          <FormLabel
            label='受付時間'
            tooltipText={
              'ユーザーが注文可能な時間帯を設定します。この時間帯以外はモバイルオーダーで注文できなくなります。'
            }
            style={{ marginBottom: 0, fontWeight: 400 }}
          />

          <Box display='flex' alignItems='center' gap={1}>
            <TimeForm
              value={orderDate.active ? orderDate.from_time : ''}
              disabled={!orderDate.active || openingTimeDisabled}
              onChange={(e) => onChange({ from_time: e.target.value })}
            />
            〜
            <TimeForm
              value={orderDate.active ? orderDate.to_time : ''}
              disabled={!orderDate.active || openingTimeDisabled}
              onChange={(e) => onChange({ to_time: e.target.value })}
            />
          </Box>
        </Box>

        <Box>
          <FormLabel
            label='受取時間'
            tooltipText={`商品を注文する際、ユーザーはこの時間帯の範囲内で自分で商品の受取時間を指定することができます。`}
            style={{ marginBottom: 0, fontWeight: 400 }}
          />

          <Box display='flex' alignItems='center' gap={1}>
            <TimeForm
              value={orderDate.active ? orderDate.receive_from_time : ''}
              disabled={!orderDate.active || receivedTimeDisabled}
              onChange={(e) => onChange({ receive_from_time: e.target.value })}
            />
            〜
            <TimeForm
              value={orderDate.active ? orderDate.receive_to_time : ''}
              disabled={!orderDate.active || receivedTimeDisabled}
              onChange={(e) => onChange({ receive_to_time: e.target.value })}
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default OrderDateForm
