import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Card } from '../../../components/Card/Card'
import FormInput from '../../../components/FormInputs/FormInput'

const QuestionnaireFormCard = () => {
  const { register, errors } = useFormContext()

  return (
    <Card
      title='アンケート設定'
      category='※googleフォームのURLのみ有効です。複数ページには対応していません。'
      content={
        <FormInput
          style={{ width: 700 }}
          name='coupon.questionnaire_url'
          inputRef={register({ required: 'URLを設定してください。' })}
          validationMessage={errors?.coupon?.questionnaire_url?.message}
        />
      }
    />
  )
}

export default QuestionnaireFormCard
