import React, { useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import Flatpickr from 'react-flatpickr'
import { Japanese } from 'flatpickr/dist/l10n/ja'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FormHelperText from '@mui/material/FormHelperText'
import { useApi } from '../../../lib/hooks'
import { useNotification } from '../../../providers/NotificationProvider'
import { useLoginContext } from '../../../providers/LoginContextProvider'
import { MembersCardContentInterface } from '../../../containers/entities/MembersCardContentEntityContainer'
import { MembersCardContentPointOverrideSettingResponse } from '../../../types/membersCardContentPointOverrideSetting'
import FormLabel from '../../../components/FormInputs/FormLabel'
import SwitchForm from '../../../components/FormInputs/SwitchForm'

type RepeatType =
  | 'daily'
  | 'weekly'
  | 'monthly'
  | 'yearly'
  | 'specificDate'
  | 'holiday'

type OverrideForm = {
  repeatType: RepeatType
  status: string
  title: string | null
  year: number | null
  month: number | null
  date: number | null
  dayOfWeek: string | null
  fromTime: string | null
  toTime: string | null
  checkInPoint: number | null
  pointRate: number | null
  paymentPointAmount: number | null
  rankId: number | null
  storeId: number
}

type Props = {
  open: boolean
  onClose: () => void
  onSubmit: () => void
  membersCardContent: MembersCardContentInterface
  selectedOverride: MembersCardContentPointOverrideSettingResponse | null
}

const generateDefaultValues = (
  selectedOverride: MembersCardContentPointOverrideSettingResponse,
): OverrideForm => {
  const policy = selectedOverride.time_range_condition_policy
  const override = selectedOverride.members_card_content_point_override_setting

  const repeatType: RepeatType =
    policy.year && policy.month && policy.date
      ? 'specificDate'
      : policy.month && policy.date
        ? 'yearly'
        : policy.date
          ? 'monthly'
          : policy.day_of_week === 'holiday'
            ? 'holiday'
            : policy.day_of_week
              ? 'weekly'
              : 'daily'

  return {
    repeatType,
    status: policy.status,
    title: policy.title,
    year: policy.year,
    month: policy.month,
    date: policy.date,
    dayOfWeek: policy.day_of_week,
    fromTime: policy.from_time,
    toTime: policy.to_time,
    checkInPoint: override.check_in_point,
    pointRate: override.point_rate !== null ? override.point_rate * 100 : null,
    paymentPointAmount: override.payment_point_amount,
    rankId: override.members_card_rank_id,
    storeId: policy.store_id,
  }
}

const DefaultValues = {
  repeatType: 'daily',
  status: 'active',
  title: '',
  year: null,
  month: null,
  date: null,
  dayOfWeek: '',
  fromTime: '',
  toTime: '',
  checkInPoint: null,
  pointRate: null,
  paymentPointAmount: null,
  rankId: null,
} as const

const PointOverrideSettingDialog = ({
  open,
  onClose,
  onSubmit,
  membersCardContent,
  selectedOverride,
}: Props) => {
  const { showSuccessNotification } = useNotification()
  const { currentStore } = useLoginContext()
  const { control, handleSubmit, formState, reset, setValue, watch } =
    useForm<OverrideForm>()
  const { errors } = formState
  const { api } = useApi()
  const watchValues = watch()
  const isAllDay =
    watchValues.fromTime === '00:00' && watchValues.toTime === '00:00'

  useEffect(() => {
    if (open) {
      const values =
        selectedOverride !== null
          ? generateDefaultValues(selectedOverride)
          : { ...DefaultValues, storeId: currentStore.id }
      reset(values)
    }
  }, [selectedOverride, reset, open, currentStore.id])

  const selectRepeatType = (repeatType: RepeatType) => {
    setValue('repeatType', repeatType)
    setValue('dayOfWeek', '')
    setValue('year', null)
    setValue('month', null)
    setValue('date', null)
  }

  const createOverride = async (formValue: OverrideForm) => {
    const res = await api.post(
      '/members_card_content_point_override_settings',
      {
        status: formValue.status,
        title: formValue.title,
        year: formValue.year || null,
        month: formValue.month || null,
        date: formValue.date || null,
        day_of_week:
          formValue.repeatType === 'holiday' ? 'holiday' : formValue.dayOfWeek,
        from_time: formValue.fromTime,
        to_time: formValue.toTime,
        check_in_point: formValue.checkInPoint,
        point_rate:
          formValue.pointRate !== null ? formValue.pointRate / 100 : null,
        payment_point_amount: formValue.paymentPointAmount,
        members_card_rank_id: formValue?.rankId ? formValue.rankId : null,
        target_store_id: formValue.storeId,
      },
    )

    if (!res) return

    showSuccessNotification('特別ポイント設定を作成しました。')
    onSubmit()
    onClose()
  }

  const updateOverride = async (formValue: OverrideForm) => {
    const res = await api.patch(
      `/members_card_content_point_override_settings/${selectedOverride?.members_card_content_point_override_setting.id}`,
      {
        status: formValue.status,
        title: formValue.title,
        year: formValue.year || null,
        month: formValue.month || null,
        date: formValue.date || null,
        day_of_week:
          formValue.repeatType === 'holiday' ? 'holiday' : formValue.dayOfWeek,
        from_time: formValue.fromTime,
        to_time: formValue.toTime,
        check_in_point: formValue.checkInPoint,
        point_rate:
          formValue.pointRate !== null ? formValue.pointRate / 100 : null,
        payment_point_amount: formValue.paymentPointAmount,
        members_card_rank_id: formValue?.rankId ? formValue.rankId : null,
        target_store_id: formValue.storeId,
      },
    )

    if (!res) return

    showSuccessNotification('特別ポイント設定を更新しました。')
    onSubmit()
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth>
      <StyledDialogTitle>
        特別ポイント設定
        <i
          onClick={onClose}
          className='ri-close-line'
          style={{ cursor: 'pointer' }}
        />
      </StyledDialogTitle>

      <DialogContent>
        <StyledSectionContainer>
          <FormLabel label='有効設定' />
          <Controller
            control={control}
            name='status'
            render={({ value, onChange }) => (
              <SwitchForm
                checked={value === 'active'}
                onChange={(isChecked) =>
                  onChange(isChecked ? 'active' : 'inactive')
                }
              />
            )}
          />
        </StyledSectionContainer>

        <StyledSectionContainer>
          <FormLabel label='タイトル' />
          <StyledFieldContainer>
            <Controller
              control={control}
              name='title'
              render={({ onChange, value }) => (
                <TextField
                  fullWidth
                  size='small'
                  value={value}
                  onChange={onChange}
                  placeholder='例)ポイント2倍デー'
                />
              )}
            />
          </StyledFieldContainer>
        </StyledSectionContainer>

        <StyledSectionContainer>
          <FormLabel label='繰り返し設定' />
          <StyledFieldContainer>
            <FormControl>
              <Controller
                control={control}
                name='repeatType'
                render={({ value }) => (
                  <Select
                    value={value}
                    onChange={(e) =>
                      selectRepeatType(e.target.value as RepeatType)
                    }>
                    <MenuItem value='daily'>毎日</MenuItem>
                    <MenuItem value='weekly'>毎週</MenuItem>
                    <MenuItem value='monthly'>毎月</MenuItem>
                    <MenuItem value='yearly'>毎年</MenuItem>
                    <MenuItem value='specificDate'>特定の日付</MenuItem>
                    <MenuItem value='holiday'>祝日</MenuItem>
                  </Select>
                )}
              />
            </FormControl>

            <Box
              display={watchValues.repeatType === 'weekly' ? 'block' : 'none'}>
              <Controller
                control={control}
                name='dayOfWeek'
                rules={{
                  required:
                    watchValues.repeatType === 'weekly' &&
                    '曜日を選択してください。',
                }}
                render={({ onChange, value }) => (
                  <Select
                    value={value || ''}
                    onChange={({ target }) => onChange(target.value)}
                    displayEmpty>
                    <MenuItem value='' disabled>
                      曜日を選択
                    </MenuItem>
                    <MenuItem value='monday'>月曜日</MenuItem>
                    <MenuItem value='tuesday'>火曜日</MenuItem>
                    <MenuItem value='wednesday'>水曜日</MenuItem>
                    <MenuItem value='thursday'>木曜日</MenuItem>
                    <MenuItem value='friday'>金曜日</MenuItem>
                    <MenuItem value='saturday'>土曜日</MenuItem>
                    <MenuItem value='sunday'>日曜日</MenuItem>
                  </Select>
                )}
              />
              {errors.dayOfWeek?.message && (
                <FormHelperText error>
                  {errors.dayOfWeek?.message}
                </FormHelperText>
              )}
            </Box>

            <Box
              display={
                watchValues.repeatType === 'specificDate' ? 'block' : 'none'
              }>
              <Controller
                control={control}
                name='year'
                rules={{
                  required:
                    watchValues.repeatType === 'specificDate' &&
                    '日付を選択してください。',
                }}
                render={({ onChange }) => (
                  <Flatpickr
                    value={
                      watchValues.year && watchValues.month && watchValues.date
                        ? new Date(
                            watchValues.year,
                            watchValues.month - 1,
                            watchValues.date,
                          )
                        : null
                    }
                    options={{
                      altInput: true,
                      allowInput: true,
                      altFormat: 'Y年m月d日',
                      locale: Japanese,
                      minDate: 'today',
                    }}
                    onChange={(date: Date[]) => {
                      const selectedDate = date[0]
                      if (selectedDate) {
                        onChange(selectedDate.getFullYear())
                        setValue('month', selectedDate.getMonth() + 1)
                        setValue('date', selectedDate.getDate())
                      }
                    }}
                  />
                )}
              />
              {errors.year?.message && (
                <FormHelperText error>{errors.year?.message}</FormHelperText>
              )}
            </Box>

            <Box
              display={watchValues.repeatType === 'yearly' ? 'block' : 'none'}>
              <Controller
                control={control}
                name='month'
                rules={{
                  required:
                    watchValues.repeatType === 'yearly' &&
                    '月を選択してください。',
                }}
                render={({ onChange, value }) => (
                  <Select
                    value={value || 0}
                    onChange={({ target }) => onChange(target.value)}
                    displayEmpty>
                    <MenuItem value={0} disabled>
                      月を選択
                    </MenuItem>
                    {[...Array(12)].map((_, i) => (
                      <MenuItem key={i} value={i + 1}>
                        {i + 1}月
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.month?.message && (
                <FormHelperText error>{errors.month?.message}</FormHelperText>
              )}
            </Box>

            <Box
              display={
                ['monthly', 'yearly'].includes(watchValues.repeatType)
                  ? 'block'
                  : 'none'
              }>
              <Controller
                control={control}
                name='date'
                rules={{
                  required:
                    ['monthly', 'yearly'].includes(watchValues.repeatType) &&
                    '日を選択してください。',
                }}
                render={({ onChange, value }) => (
                  <Select
                    value={value || 0}
                    onChange={({ target }) => onChange(target.value)}
                    displayEmpty>
                    <MenuItem value={0} disabled>
                      日を選択
                    </MenuItem>
                    {[...Array(31)].map((_, i) => (
                      <MenuItem key={i} value={i + 1}>
                        {i + 1}日
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.date?.message && (
                <FormHelperText error>{errors.date?.message}</FormHelperText>
              )}
            </Box>
          </StyledFieldContainer>
        </StyledSectionContainer>

        <StyledSectionContainer>
          <FormLabel label='時間' />
          <StyledFieldContainer>
            <Controller
              control={control}
              name='fromTime'
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <TextField
                  type='time'
                  value={value}
                  onChange={onChange}
                  disabled={isAllDay}
                />
              )}
            />
            ~
            <Controller
              control={control}
              name='toTime'
              rules={{ required: true }}
              render={({ onChange, value }) => (
                <TextField
                  type='time'
                  value={value}
                  onChange={onChange}
                  disabled={isAllDay}
                />
              )}
            />
          </StyledFieldContainer>
          <FormControlLabel
            label='終日'
            control={
              <Checkbox
                checked={isAllDay}
                onChange={(e) => {
                  if (e.target.checked) {
                    setValue('fromTime', '00:00')
                    setValue('toTime', '00:00')
                  } else {
                    setValue('fromTime', '')
                    setValue('toTime', '')
                  }
                }}
              />
            }
          />
          {(errors.fromTime || errors.toTime) && (
            <FormHelperText error>時間を設定してください。</FormHelperText>
          )}
        </StyledSectionContainer>

        <StyledSectionContainer>
          <FormLabel label='適用先' />
          <StyledFieldContainer>
            <Controller
              control={control}
              name='storeId'
              render={({ onChange, value }) => (
                <Select
                  disabled={currentStore.store_type === 'child'}
                  value={value}
                  onChange={({ target }) => onChange(target.value)}>
                  <MenuItem value={currentStore.id}>
                    {currentStore.store_type === 'owner_group'
                      ? 'すべての店舗'
                      : currentStore.store_type === 'store_group'
                        ? `${currentStore.name}配下のすべての店舗`
                        : currentStore.name}
                  </MenuItem>
                  {currentStore.child_stores.map((store) => (
                    <MenuItem key={store.id} value={store.id}>
                      {store.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />

            <Controller
              control={control}
              name='rankId'
              render={({ onChange, value }) => (
                <Select
                  value={value || 0}
                  onChange={({ target }) => onChange(target.value)}>
                  <MenuItem value={0}>すべてのランク</MenuItem>
                  {membersCardContent.members_card_ranks.map((rank) => (
                    <MenuItem key={rank.id} value={rank.id}>
                      {rank.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </StyledFieldContainer>
        </StyledSectionContainer>

        <StyledSectionContainer
          display={
            membersCardContent.check_in_point_enabled ? 'block' : 'none'
          }>
          <FormLabel label='チェックインポイント' />
          <StyledFieldContainer>
            <Controller
              control={control}
              name='checkInPoint'
              rules={{
                min: {
                  value: 0,
                  message: '0以上の数字を設定してください。',
                },
                validate: {
                  pointEmpty: (value) =>
                    [
                      value,
                      watchValues.pointRate,
                      watchValues.paymentPointAmount,
                    ].some((v) => v !== null),
                },
              }}
              render={({ onChange, value }) => (
                <TextField
                  type='number'
                  value={value}
                  onChange={({ target }) => {
                    onChange(target.value !== '' ? Number(target.value) : null)
                  }}
                />
              )}
            />
            <Box ml={0.5}>ポイント</Box>
          </StyledFieldContainer>
          {errors.checkInPoint?.message && (
            <FormHelperText error>
              {errors.checkInPoint?.message}
            </FormHelperText>
          )}
        </StyledSectionContainer>

        <StyledSectionContainer
          display={
            membersCardContent.payment_point_type !== 'disable' &&
            membersCardContent.point_rate
              ? 'block'
              : 'none'
          }>
          <FormLabel label='ポイント還元率' />
          <StyledFieldContainer>
            <Controller
              control={control}
              name='pointRate'
              rules={{
                min: {
                  value: 0,
                  message: '0以上の数字を設定してください。',
                },
                validate: {
                  pointEmpty: (value) =>
                    [
                      value,
                      watchValues.checkInPoint,
                      watchValues.paymentPointAmount,
                    ].some((v) => v !== null),
                },
              }}
              render={({ onChange, value }) => (
                <TextField
                  type='number'
                  value={value}
                  onChange={({ target }) => {
                    onChange(target.value !== '' ? Number(target.value) : null)
                  }}
                />
              )}
            />
            <Box ml={0.5}>%</Box>
          </StyledFieldContainer>
          {errors.pointRate?.message && (
            <FormHelperText error>{errors.pointRate?.message}</FormHelperText>
          )}
        </StyledSectionContainer>

        <StyledSectionContainer
          display={
            membersCardContent.payment_point_type !== 'disable' &&
            membersCardContent.payment_point_amount
              ? 'block'
              : 'none'
          }>
          <FormLabel label='お会計ポイント' />
          <StyledFieldContainer>
            <Controller
              control={control}
              name='paymentPointAmount'
              rules={{
                min: {
                  value: 0,
                  message: '0以上の数字を設定してください。',
                },
                validate: {
                  pointEmpty: (value) =>
                    [
                      value,
                      watchValues.checkInPoint,
                      watchValues.pointRate,
                    ].some((v) => v !== null),
                },
              }}
              render={({ onChange, value }) => (
                <TextField
                  type='number'
                  value={value}
                  onChange={({ target }) => {
                    onChange(target.value !== '' ? Number(target.value) : null)
                  }}
                />
              )}
            />
            <Box ml={0.5}>円で1ポイント</Box>
          </StyledFieldContainer>
          {errors.paymentPointAmount?.message && (
            <FormHelperText error>
              {errors.paymentPointAmount?.message}
            </FormHelperText>
          )}
        </StyledSectionContainer>

        {[
          errors.checkInPoint?.type,
          errors.pointRate?.type,
          errors.paymentPointAmount?.type,
        ].every((v) => v === 'pointEmpty') && (
          <FormHelperText error>
            ポイント設定いずれか１つ以上を入力してください。
          </FormHelperText>
        )}
      </DialogContent>

      <DialogActions sx={{ pt: 2 }}>
        <Button variant='outlined' color='cancel' onClick={onClose}>
          キャンセル
        </Button>
        <Button
          variant='contained'
          color='submit'
          onClick={handleSubmit((fromValue) =>
            selectedOverride
              ? updateOverride(fromValue)
              : createOverride(fromValue),
          )}>
          {selectedOverride ? '更新する' : '追加する'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PointOverrideSettingDialog

const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
})

const StyledSectionContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}))

const StyledFieldContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
}))
