import React, { useState } from 'react'

import {
  Row,
  Col,
  FormControl,
  FormGroup,
  Modal,
  Button,
  Radio,
} from 'react-bootstrap'

const SpecifyNumOfStampsQrSettingModal = (props: {
  show: boolean
  onHide: () => void
  onClickCreateStampCardContentQrcode: (stamps: number | null) => Promise<void>
}): JSX.Element => {
  const { show, onHide, onClickCreateStampCardContentQrcode } = props
  const [value, setValue] = useState(0)
  const [status, setStatus] = useState('specify')
  const handleChange = ({ target }): void => {
    setValue(target.value)
  }

  return (
    <Modal show={show} onHide={onHide} bsSize='small'>
      <Modal.Header closeButton>スタンプ付与QRコード</Modal.Header>
      <Modal.Body>
        <Row style={{ marginTop: 10 }}>
          <Col xs={10} md={10}>
            <FormGroup>
              <Col md={12} xs={8} style={{ padding: 0 }}>
                <Radio
                  type='radio'
                  id='with-point'
                  label='スタンプ数を指定する'
                  onChange={() => setStatus('specify')}
                  checked={status === 'specify'}>
                  <div style={{ marginLeft: 10 }}>スタンプ数を指定する</div>
                </Radio>
                <FormControl
                  type='number'
                  style={{ marginLeft: 40, marginBottom: 20 }}
                  placeholder='スタンプ数を入力'
                  value={value}
                  onChange={handleChange}
                  disabled={status !== 'specify'}
                />
                <Radio
                  type='radio'
                  id='with-point'
                  label='付与時に入力する'
                  onChange={() => setStatus('not specify')}
                  checked={status === 'not specify'}>
                  <div style={{ marginLeft: 10 }}>付与時に入力する</div>
                </Radio>
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button
          bsStyle='info'
          onClick={() => {
            onClickCreateStampCardContentQrcode(
              status === 'specify' ? value : null,
            )
            onHide()
          }}>
          作成する
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SpecifyNumOfStampsQrSettingModal
