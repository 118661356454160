import React, { useEffect, useState } from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '../../../components/DialogTitle'
import { useLoginContext } from '../../../providers/LoginContextProvider'

type Props = {
  open: boolean
  disabled: boolean
  defaultValue: string
  onClose: () => void
  onSubmit: (value: string) => void
}
const MemberCodeModal = ({
  open,
  disabled,
  defaultValue,
  onClose,
  onSubmit,
}: Props): JSX.Element => {
  const { currentStaff } = useLoginContext()
  const [memberCode, setMemberCode] = useState('')
  const canSubmit = Boolean(memberCode)

  useEffect(() => {
    if (open) {
      setMemberCode(defaultValue)
    }
  }, [defaultValue, open])

  const handleSubmit = () => {
    if (canSubmit) {
      onSubmit(memberCode)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth='xs'
      fullWidth
      disableEscapeKeyDown>
      <DialogTitle>会員コード更新</DialogTitle>

      <DialogContent>
        {!currentStaff.is_toypo_member && (
          <Typography m={1} color='gray'>
            会員コードは1度しか変更できません。
          </Typography>
        )}

        {currentStaff.is_toypo_member && (
          <Typography m={1} color='gray'>
            会員コードは通常1度しか変更できませんが、現在ログイン中のアカウントがトイポスタッフであるため何度でも変更できます。
          </Typography>
        )}

        <OutlinedInput
          fullWidth
          disabled={disabled}
          value={memberCode}
          onChange={(e) => setMemberCode(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant='outlined'
          color='cancel'
          disabled={disabled}
          onClick={onClose}>
          キャンセル
        </Button>

        <Button
          variant='contained'
          color='submit'
          disabled={!canSubmit || disabled}
          onClick={handleSubmit}>
          変更を保存
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default MemberCodeModal
