import React, { useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import OutlinedInput from '@mui/material/OutlinedInput'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '../../components/DialogTitle'
import FormLabel from '../../components/FormInputs/FormLabel'
import Section from '../../components/Section'
import { useLoginContext } from '../../providers/LoginContextProvider'
import { useBooleanState, useApi } from '../../lib/hooks'

const ToypoAccountView = () => {
  const { currentStaff } = useLoginContext()
  const [isModalOpen, openModal, closeModal] = useBooleanState(false)
  const { api, loading } = useApi('/staff')

  const handleSubmit = async (password: string) => {
    const params = { password, activated: !currentStaff.is_toypo_member }
    const response = await api.put('/toypo_staff', params)
    if (response) {
      window.location.reload()
    }
  }

  return (
    <Section title='スタッフ'>
      <Box mb={2}>
        <FormLabel label='スタッフ名' />

        <Box display='flex' alignItems='baseline' gap={1}>
          <Typography fontSize={16}>{currentStaff.name}</Typography>

          {currentStaff.is_toypo_member && (
            <Typography
              fontWeight={700}
              sx={{ color: (theme) => theme.palette.toypoAdmin.main }}>
              (トイポスタッフ)
            </Typography>
          )}
        </Box>
      </Box>

      <Box mb={2}>
        <FormLabel label='メールアドレス' />
        <Typography fontSize={16}>{currentStaff.email}</Typography>
      </Box>

      <Button variant='contained' color='submit' onClick={openModal}>
        {currentStaff.is_toypo_member
          ? 'トイポスタッフを解除する'
          : 'トイポスタッフとして認証する'}
      </Button>

      <ConfirmModal
        open={isModalOpen}
        disabled={loading}
        onClose={closeModal}
        onSubmit={handleSubmit}
      />
    </Section>
  )
}

type ConfirmModalProps = {
  open: boolean
  disabled: boolean
  onClose: () => void
  onSubmit: (password: string) => void
}
const ConfirmModal = ({
  open,
  disabled,
  onClose,
  onSubmit,
}: ConfirmModalProps): JSX.Element => {
  const { currentStaff } = useLoginContext()
  const [password, setPassword] = useState('')

  return (
    <Dialog fullWidth open={open} onClose={onClose} maxWidth='sm'>
      <DialogTitle onClose={onClose}>トイポスタッフ</DialogTitle>
      <DialogContent>
        <FormLabel label='更新用パスワード' />
        <OutlinedInput
          placeholder='更新用パスワードを入力してください。'
          fullWidth
          type='password'
          value={password}
          onChange={({ target }) => setPassword(target.value)}
        />
      </DialogContent>

      <DialogActions sx={{ pt: 2 }}>
        <Button
          fullWidth
          variant='outlined'
          color='cancel'
          disabled={disabled}
          onClick={onClose}>
          キャンセル
        </Button>

        <Button
          fullWidth
          variant='contained'
          color='submit'
          disabled={!password || disabled}
          onClick={() => onSubmit(password)}>
          {currentStaff.is_toypo_member ? '解除する' : '認証する'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ToypoAccountView
