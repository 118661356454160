import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router'
import Box from '@mui/material/Box'
import { useApi, useMobileFlag } from '../../lib/hooks'
import { useLoginContext } from '../../providers/LoginContextProvider'
import OpenStatusMessage from '../../components/Modal/OpenStatusMessage'
import TodoList from '../TodoList/parts/TodoList'
import SupportList from './parts/SupportList'
import MemoList from './parts/MemoList'
import RecentPublishedContents from './parts/RecentPublishedContents'
import DailySummary from './parts/DailySummary'
import AnalyticsReports from './parts/AnalyticsReports'
import BenchmarkProgressList from './parts/BenchmarkProgressList'
import QuestionnaireAnswerList from './parts/QuestionnaireAnswerList'
import { TodoListContentsResponse, TodoItem } from '../../types/todo'

const HomeView = (): JSX.Element => {
  const isMobile = useMobileFlag()
  const history = useHistory()
  const { currentStore } = useLoginContext()
  const isStore = currentStore.store_type === 'child'
  const fromWebview = navigator.userAgent === 'webView'
  const { active, todoItems, updateTodoListStatus, updating } = useTodoList()

  return (
    <Box
      p={isMobile ? 1 : 2}
      gap={2}
      display='flex'
      flexDirection={isMobile ? 'column' : 'row'}
      alignItems='flex-start'>
      <Box
        display='flex'
        flexDirection='column'
        flexGrow={1}
        minWidth={0}
        gap={2}
        width={isMobile ? '100%' : undefined}>
        {currentStore.status === 'close' && (
          <OpenStatusMessage
            name={currentStore.name}
            onClick={() =>
              history.push(`/admin/${isStore ? 'store' : 'group'}/edit`)
            }
          />
        )}

        <AnalyticsReports />

        {isStore && active && todoItems.length > 0 && (
          <Box mt={1}>
            <TodoList todoItems={todoItems} />
          </Box>
        )}

        <BenchmarkProgressList />

        <DailySummary />

        <QuestionnaireAnswerList />

        <RecentPublishedContents />
      </Box>

      <Box
        display='flex'
        gap={2}
        flexDirection={isMobile ? 'column-reverse' : 'column'}
        flexBasis={isMobile ? undefined : COLUMN_WIDTH}>
        {!fromWebview && (
          <Box minWidth={isMobile ? undefined : COLUMN_WIDTH}>
            <SupportList
              isTodoListActive={active}
              disableTodoListSwitch={updating}
              onChangeTodoListStatus={updateTodoListStatus}
            />
          </Box>
        )}

        <MemoList minWidth={isMobile ? undefined : COLUMN_WIDTH} pinned />
        <MemoList minWidth={isMobile ? undefined : COLUMN_WIDTH} />
      </Box>
    </Box>
  )
}

// ホーム画面へアクセスした際にTODOリストが完了しているかどうかを毎回チェックします。
// /api/v2/stores/:id/todo_listsのエンドポイントに対してPATHでアクセスをすると
// API側で各TODOの項目が完了したかどうかをチェックして自動的にステータスを更新してくれるので
// ステータス更新のAPIが完了した時点で改めて同じエンドポイントに対してGETでアクセスして
// 最新の状態を取得しています。
//
// なお、PATCHでのアクセスに対するレスポンスはTodoListContentsResponseの型であり
// その中にはTODOリストの状態及びTODOリストの項目が含まれているため
// このレスポンスを使って状態のチェックができればPATCH後のGETアクセスは不要なのですが
// すべてのTODOが完了済みの場合、あるいはTODOリストがactiveではない場合(ホームで非表示の状態)
// PATCHのAPIからは空配列が返るようになっていて、その場合はレスポンスから状態が取得できません。
// という理由で、状態の取得にはGETでアクセスした方のレスポンスを利用します。
const useTodoList = () => {
  const todoListApi = useApi()
  const [todoItems, setTodoItems] = useState<TodoItem[]>([])
  const checkDoneApi = useApi<TodoListContentsResponse | []>()
  const updateStatusApi = useApi<TodoListContentsResponse>()
  const { currentStore } = useLoginContext()
  const isStore = currentStore.store_type === 'child'
  const todoList = todoListApi.response

  const fetchTodoList = useCallback(() => {
    // すべてのTODOリストが完了している場合
    // TODOリストが存在していても404 NOT FOUNDが返ってくるが
    // このAPIへアクセスする前にすべてのTODOリストが完了しているかどうかを
    // 把握する術がないためエラーがあっても表示しないようにしている。
    todoListApi.api.get('/todo_lists', {}, { showErrorNotification: false })
  }, [todoListApi.api])

  const updateTodoListStatus = async (active: boolean) => {
    if (!todoList) return

    const params = { todo_list: { active } }
    const path = `/todo_lists/${todoList.id}`
    const response = await updateStatusApi.api.patch(path, params)
    if (response) {
      // TODOリストをactive = trueに更新した時はtodo_itemsに
      // 現在のTODOリストの項目が返ってきます。
      // active = falseに更新した時はtodo_itemsがnullになっているため
      // その場合は空配列をセットしてあげます。
      setTodoItems(response.data.todo_items || [])
      fetchTodoList()
    }
  }

  useEffect(() => {
    if (isStore) {
      checkDoneApi.api.patch('/todo_lists').then(({ data }) => {
        // すべてのTODOが完了済み、あるいはTODOリストがactiveではない場合に
        // レスポンスが空配列になりますが、その場合はTODOリストの項目を
        // 表示する必要がないのでtodoItemsには空配列をセットします。
        // 空配列ではない場合は、TODOリストの項目がレスポンスとして返ってくるので
        // それをtodoItemsにセットして画面に表示します。
        if (!Array.isArray(data)) {
          setTodoItems((data as TodoListContentsResponse)?.todo_items || [])
        } else {
          setTodoItems([])
        }

        fetchTodoList()
      })
    }
  }, [isStore, checkDoneApi.api, fetchTodoList])

  return {
    active: todoList?.active ?? false,
    todoItems,
    updateTodoListStatus,
    updating: updateStatusApi.loading,
  }
}

const COLUMN_WIDTH = 400

export default HomeView
