import React from 'react'
import Box from '@mui/material/Box'

type Props = {
  icon: React.ReactNode
  children: React.ReactNode
}

const ListEmptyContainer = ({ icon, children }: Props): JSX.Element => (
  <Box
    display='flex'
    flexDirection='column'
    alignItems='center'
    justifyContent='center'
    gap={2}
    py={6}>
    <Box
      bgcolor='#F3F5F9'
      borderRadius={10}
      width={40}
      height={40}
      display='flex'
      alignItems='center'
      justifyContent='center'
      color='#555'
      fontSize={20}>
      {icon}
    </Box>

    {children}
  </Box>
)

export default ListEmptyContainer
