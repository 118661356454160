import React, { useRef, useCallback } from 'react'
import NotificationSystem from 'react-notification-system'
import { createContext } from './utils'
import { style } from '../variables/Variables'

const NotificationProvider = ({ children }) => {
  const notificationSystemRef = useRef<NotificationSystem>()

  const showNotification = useCallback(
    ({ level, message, icon = '', autoDismiss = 2 }: ShowNotificationParam) => {
      if (!notificationSystemRef.current) return

      notificationSystemRef.current.addNotification({
        title: icon ? <span data-notify='icon' className={icon} /> : null,
        message: <div data-cy='notification-message'>{message}</div>,
        level,
        position: 'tr',
        autoDismiss: autoDismiss,
      })
    },
    [],
  )

  const showSuccessNotification = useCallback(
    (message: string, icon: string = '') => {
      showNotification({ level: 'success', message, icon })
    },
    [showNotification],
  )
  const showErrorNotification = useCallback(
    (message: string, icon: string = '') => {
      showNotification({ level: 'error', message, icon })
    },
    [showNotification],
  )

  const value = {
    showNotification,
    showSuccessNotification,
    showErrorNotification,
  }

  return (
    <NotificationContext.Provider value={value}>
      <NotificationSystem ref={notificationSystemRef} style={style} />
      {children}
    </NotificationContext.Provider>
  )
}

type ShowNotificationParam = {
  level: 'success' | 'error' | 'warning'
  message: string
  icon?: string
  autoDismiss?: number
}
type ShowNotification = (params: ShowNotificationParam) => void
type ShowSuccessNotification = (message: string, icon?: string) => void
type ShowErrorNotification = (message: string, icon?: string) => void
type NotificationContextType = {
  showNotification: ShowNotification
  showSuccessNotification: ShowSuccessNotification
  showErrorNotification: ShowErrorNotification
}

export const [useNotification, NotificationContext] =
  createContext<NotificationContextType>()

export default NotificationProvider
