import React, { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { withRouter, Prompt } from 'react-router'
import { Grid, Row, Col, Form, FormGroup, HelpBlock } from 'react-bootstrap'
import { Backdrop, CircularProgress } from '@mui/material'

import { Card } from '../../components/Card/Card'
import FormInputs from '../../components/FormInputs/FormInputs'
import ImageForm from '../../components/FormInputs/ImageForm'
import FormLabel from '../../components/FormInputs/FormLabel'
import Checkbox from '../../components/CustomCheckbox/CustomCheckbox'

import DiscountFormCard from '../Coupon/parts/DiscountFormCard'
import ServicePublishSettingCard from './parts/ServicePublishSettingCard'
import ServiceFormButtonGroup from './parts/ServiceFormButtonGroup'

import ServiceContentEntityContainer, {
  ServiceContentInterface,
  ServiceContentStatus,
} from '../../containers/entities/ServiceContentEntityContainer'

import { getObjectDiff } from '../../lib/general'

const ServiceFormView = (props): JSX.Element => {
  const { location, displayNotification, history } = props

  const editMode = !!location.state?.service
  const { rankId } = location.state

  const [serviceContent, setServiceContent] = useState<ServiceContentInterface>(
    location.state?.service,
  )
  const [isShowDiscountFormCard, setIsShowDiscountFormCard] = useState(
    !!serviceContent?.service_type,
  )
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [isBackdropLoading, setIsBackdropLoading] = useState(false)

  const serviceContentEntityContainer =
    ServiceContentEntityContainer.useContainer()

  const { serviceTypes } = serviceContentEntityContainer.constant
  const {
    createServiceContent,
    updateServiceContent,
    deleteServiceContent,
    statusesToJp,
    serviceTypeToJp,
  } = serviceContentEntityContainer.logic

  const methods = useForm<{
    service_content: ServiceContentInterface & { image: string }
  }>({
    defaultValues: {
      service_content: {
        status: 'draft',
        title: '',
        body: '',
        usable_count: null,
        monthly_usable_count: null,
        daily_usable_count: null,
        image: '',
        image_url: '',
        service_type: null,
        service_value: null,
      },
    },
  })

  const {
    register,
    unregister,
    handleSubmit,
    setValue,
    errors,
    formState,
    watch,
    getValues,
    reset,
    trigger,
  } = methods

  const { isDirty, dirtyFields } = formState

  const watchImage = watch('service_content.image', null)
  const watchStatus = watch('service_content.status') as ServiceContentStatus
  const watchServiceType = watch('service_content.service_type')

  const processFormData = (data): ServiceContentStatus => {
    const processedData = data

    if (serviceContent && processedData.image === serviceContent.image_url) {
      delete processedData.image
    }

    // 割引のチェックがついてない場合
    if (serviceContent && !isShowDiscountFormCard) {
      processedData.service_type = null
      processedData.service_value = null
    }

    if (editMode) {
      delete processedData.status
    }

    return processedData
  }

  const onClickCreateButton = async (data): Promise<void> => {
    await createServiceContent({ rankId, params: data })
      .then((newServiceContent) => {
        displayNotification({
          level: 'success',
          message: 'サービスを作成しました。',
        })
        reset({ service_content: newServiceContent })
        history.goBack()
      })
      .catch((error) => {
        displayNotification({
          level: 'error',
          message: error.message,
        })
      })
  }

  const onClickUpdateButton = async (data): Promise<void> => {
    const params = getObjectDiff(serviceContent, data)

    await updateServiceContent({ id: serviceContent.id, params })
      .then((newServiceContent) => {
        displayNotification({
          level: 'success',
          message: '編集を保存しました。',
        })
        reset({ service_content: newServiceContent })
        history.goBack()
      })
      .catch((error) => {
        displayNotification({
          level: 'error',
          message: error.message,
        })
      })
  }

  const onClickDeleteButton = async (): Promise<void> => {
    if (!window.confirm('このサービスを削除しますか？')) {
      return
    }
    await deleteServiceContent(serviceContent.id)
      .then(() => {
        displayNotification({
          level: 'success',
          message: 'サービスを削除しました。',
        })
        reset()
        history.goBack()
      })
      .catch((error) => {
        displayNotification({
          level: 'error',
          message: error.message,
        })
      })
  }

  const onClickStatusUpdateButton = async (
    status: ServiceContentStatus,
  ): Promise<void> => {
    const isDirtyExceptStatus =
      Object.keys(dirtyFields.service_content || {}).filter(
        (key) => key !== 'status',
      ).length > 0

    const isSave =
      isDirtyExceptStatus &&
      window.confirm(
        '行った変更が保存されていない可能性があります。変更内容を保存しますか？',
      )

    if (isSave && !(await trigger())) {
      displayNotification({
        level: 'error',
        message: '値が正しく入力されていません。',
      })
      return
    }

    const isUpdateStatus = window.confirm(
      `${statusesToJp(status)}に変更しますか？`,
    )

    if (!isSave && !isUpdateStatus) {
      return
    }

    const data = getValues()
    const statusParams = isUpdateStatus ? status : undefined
    const params = isSave
      ? {
          ...getObjectDiff(
            serviceContent,
            processFormData(data.service_content),
          ),
          status: statusParams,
        }
      : { status: statusParams }

    setIsBackdropLoading((prevIsBackdropLoading) => !prevIsBackdropLoading)
    await updateServiceContent({ id: serviceContent.id, params })
      .then((newServiceContent) => {
        setServiceContent(newServiceContent)
        displayNotification({
          level: 'success',
          message: '更新しました。',
        })
        history.goBack()
      })
      .catch((error) => {
        displayNotification({
          level: 'error',
          message: error.message,
        })
      })
      .finally(() => {
        setIsBackdropLoading(false)
      })
  }

  const onSubmit = (data): void => {
    const formData = processFormData(data.service_content)

    setIsButtonLoading(true)
    if (editMode) {
      onClickUpdateButton(formData).finally(() => setIsButtonLoading(false))
    } else {
      onClickCreateButton(formData).finally(() => setIsButtonLoading(false))
    }
  }

  const onError = (): void => {
    displayNotification({
      level: 'error',
      message: '値が正しく入力されていません。',
    })
  }

  // custom register
  useEffect(() => {
    register({ name: 'service_content.image' })

    return () => {
      unregister(['service_content.image'])
    }
  }, [register, unregister])

  useEffect(() => {
    // initialize form
    if (serviceContent) {
      reset({
        service_content: { ...serviceContent, image: serviceContent.image_url },
      })
    }
  }, [serviceContent, reset])

  return (
    <FormProvider {...methods}>
      <Grid fluid className='content'>
        <Form onSubmit={handleSubmit(onSubmit, onError)}>
          {editMode && (
            <Row>
              <Col md={10}>
                <ServicePublishSettingCard
                  editMode={editMode}
                  watchStatus={watchStatus}
                  serviceContent={serviceContent}
                  statusesToJp={statusesToJp}
                  onClickStatusUpdateButton={onClickStatusUpdateButton}
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col md={10}>
              <Card
                title={editMode ? 'サービス編集' : 'サービス作成'}
                content={
                  <>
                    <Row>
                      <Col md={12}>
                        <FormInputs
                          properties={[
                            {
                              name: 'service_content.title',
                              label: 'タイトル',
                              ncol: 'col-md-9',
                              type: 'text',
                              bsClass: 'form-control',
                              placeholder: 'タイトル',
                              inputRef: register({
                                required: 'タイトルを入力してください。',
                              }),
                              validationMessage:
                                errors?.service_content?.title?.message,
                            },
                          ]}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <FormGroup bsSize='large'>
                          <ImageForm
                            doCompress
                            doTrimming
                            label='画像'
                            attachment='任意'
                            value={watchImage}
                            onChange={(img) => {
                              setValue('service_content.image', img, {
                                shouldDirty: true,
                              })
                            }}
                            validationMessage={
                              errors?.service_content?.image?.message
                            }
                          />
                        </FormGroup>
                        <HelpBlock className='text-muted'>
                          画像は縦横比16:9で表示されます。
                        </HelpBlock>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <FormInputs
                          properties={[
                            {
                              name: 'service_content.body',
                              label: '本文',
                              ncol: 'col-md-12',
                              rows: '6',
                              componentClass: 'textarea',
                              bsClass: 'form-control',
                              placeholder: '本文',
                              attachment: '任意',
                              inputRef: register,
                              validationMessage:
                                errors?.service_content?.body?.message,
                            },
                          ]}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <FormInputs
                          properties={[
                            {
                              name: 'service_content.usable_count',
                              label: '利用可能回数',
                              ncol: 'col-md-3',
                              type: 'number',
                              bsClass: 'form-control',
                              placeholder: '100',
                              unit: '回',
                              attachment: '任意',
                              inputRef: register,
                              validationMessage:
                                errors?.service_content?.usable_count?.message,
                            },
                            {
                              name: 'service_content.monthly_usable_count',
                              label: '月間での利用回数制限',
                              ncol: 'col-md-3',
                              type: 'number',
                              bsClass: 'form-control',
                              placeholder: '20',
                              unit: '回',
                              attachment: '任意',
                              inputRef: register,
                              validationMessage:
                                errors?.service_content?.monthly_usable_count
                                  ?.message,
                            },
                            {
                              name: 'service_content.daily_usable_count',
                              label: '日間での利用回数制限',
                              ncol: 'col-md-3',
                              type: 'number',
                              bsClass: 'form-control',
                              placeholder: '5',
                              unit: '回',
                              attachment: '任意',
                              inputRef: register,
                              validationMessage:
                                errors?.service_content?.daily_usable_count
                                  ?.message,
                            },
                          ]}
                        />
                      </Col>
                      {/* オプション */}
                      <Col md={12}>
                        <FormGroup>
                          <FormLabel label='オプション' attachment='任意' />
                          <Row>
                            <Col md={4}>
                              <Checkbox
                                number={1}
                                inline
                                name='discount'
                                label='割引設定'
                                ncol='col-md-3'
                                disabled={editMode && watchStatus === 'open'}
                                checked={isShowDiscountFormCard}
                                onChange={(e): void => {
                                  setIsShowDiscountFormCard(e.target.checked)
                                }}
                              />
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                    </Row>
                  </>
                }
              />

              {isShowDiscountFormCard && (
                <DiscountFormCard
                  resourceName='service_content'
                  serviceTypes={serviceTypes}
                  serviceTypeToJp={serviceTypeToJp}
                  register={register}
                  serviceType={watchServiceType}
                  errors={errors}
                  active={serviceContent?.status === 'open'}
                />
              )}

              {!editMode && (
                <Row>
                  <Col md={12}>
                    <ServicePublishSettingCard
                      editMode={editMode}
                      watchStatus={watchStatus}
                      serviceContent={serviceContent}
                      statusesToJp={statusesToJp}
                      onClickStatusUpdateButton={onClickStatusUpdateButton}
                    />
                  </Col>
                </Row>
              )}

              <ServiceFormButtonGroup
                editMode={editMode}
                disabled={!isDirty}
                watchStatus={watchStatus}
                onClickDeleteButton={onClickDeleteButton}
                onClickBackButton={history.goBack}
                loading={isButtonLoading}
              />
            </Col>
          </Row>
        </Form>
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isBackdropLoading}>
          <CircularProgress color='inherit' />
        </Backdrop>
        <Prompt
          when={isDirty}
          message='行った変更が保存されていない可能性があります。このページを離れますか？'
        />
      </Grid>
    </FormProvider>
  )
}

export default withRouter(ServiceFormView)
