import React from 'react'
import { StarOutlined } from '@ant-design/icons'
import { useApi } from '../../lib/hooks'
import { useLoginContext } from '../../providers/LoginContextProvider'

export const useSatisfactionFeatures = () => {
  const { hasFunction } = useLoginContext()
  const { api } = useApi()

  return [
    {
      title: '顧客満足度の可視化',
      description: 'アンケートを配信して顧客満足度を可視化',
      icon: {
        icon: <StarOutlined />,
        color: '#ECC700',
      },
      showMoreInfoLink: true,
      type: ['satisfaction'],
      function: 'questionnaire',
      onChange: async (active: boolean, showDialog: boolean) => {
        if (showDialog) {
          const message = `顧客満足度の可視化機能を${active ? '有効' : '無効'}にしますか？`
          if (!window.confirm(message)) {
            return
          }
        }

        if (active) {
          await api.put('/questionnaire_features/activated')
        } else {
          await api.delete('/questionnaire_features/activated')
        }
        window.location.reload()
      },
      selectActive: true,
      active: hasFunction('questionnaire'),
    },
  ]
}
