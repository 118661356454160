import { useCallback } from 'react'
import { ConditionGroup } from '../../components/UserGroup/type'
import { useApi } from '../../lib/hooks'

export const useContentInstanceFetcher = () => {
  const { api, loading } = useApi()

  const fetchAndSetInstance = useCallback(
    async (conditionGroups: ConditionGroup[]) => {
      for (const conditionGroup of conditionGroups) {
        for (const condition of conditionGroup.conditions) {
          switch (condition.condition_type) {
            case 'members_card_rank':
              const url = `/members_card_contents/members_card_ranks/${condition.value_from}`
              const response = await api.get(url)
              condition.rank = response?.data
              break

            case 'coupon_received_count':
            case 'coupon_used_count':
              if (condition.instance_id) {
                const url = `/coupon_contents/${condition.instance_id}`
                const response = await api.get(url)
                condition.couponContent = response?.data.coupon_content
              }
              break

            case 'notice_read':
              if (condition.instance_id) {
                const url = `/notice_contents/${condition.instance_id}`
                const response = await api.get(url)
                condition.noticeContent = response?.data
              }
              break

            case 'mobile_order':
            case 'mobile_order_takeout':
            case 'mobile_order_delivery':
            case 'mobile_order_preorder':
            case 'mobile_order_ec':
            case 'mobile_order_by_order_date':
            case 'mobile_order_takeout_by_order_date':
            case 'mobile_order_delivery_by_order_date':
            case 'mobile_order_preorder_by_order_date':
            case 'mobile_order_ec_by_order_date':
              if (condition.instance_id) {
                const url = `/menus/${condition.instance_id}`
                const response = await api.get(url)
                condition.menu = response?.data
              }
              break

            case 'subscription_purchased_count':
            case 'subscription_used_count':
            case 'subscription_updated_count':
            case 'subscription_contracted':
            case 'subscription_no_contracted':
            case 'subscription_paused':
            case 'subscription_canceled':
              if (condition.instance_id) {
                const url = `/subscription_contents/${condition.instance_id}`
                const response = await api.get(url)
                condition.subscriptionContent = response?.data
              }
              break

            default:
              break
          }
        }
      }
    },
    [api],
  )

  return { fetchAndSetInstance, loading }
}
