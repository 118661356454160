import { AxiosResponse } from 'axios'
import apiClient from './apiClient'
import { loadViewObj } from '../viewObj'
import {
  SubscriptionContent,
  SubscriptionContentParams,
} from '../../containers/entities/SubscriptionContentEntityContainer'

export const getSubscriptionContents = async (): Promise<
  SubscriptionContent[]
> => {
  const GET_SUBSCRIPTION_CONTENTS = `/staff/stores/${
    loadViewObj()?.id
  }/subscription_contents`

  const response: AxiosResponse<SubscriptionContent[]> = await apiClient({
    method: 'GET',
    uri: GET_SUBSCRIPTION_CONTENTS,
  })
  return response.data
}

export const getSubscriptionContent = async (
  id: number,
): Promise<SubscriptionContent> => {
  const GET_SUBSCRIPTION_CONTENT = `/staff/stores/${
    loadViewObj()?.id
  }/subscription_contents/${id}`

  const response: AxiosResponse<SubscriptionContent> = await apiClient({
    method: 'GET',
    uri: GET_SUBSCRIPTION_CONTENT,
  })
  return response.data
}

export const createSubscriptionContent = async ({
  params,
}: {
  params: SubscriptionContentParams
}): Promise<SubscriptionContent> => {
  const CREATE_SUBSCRIPTION_CONTENT = `/staff/stores/${
    loadViewObj()?.id
  }/subscription_contents`

  const requestParams = {
    subscription_content: params,
  }

  const response: AxiosResponse<SubscriptionContent> = await apiClient({
    method: 'POST',
    uri: CREATE_SUBSCRIPTION_CONTENT,
    params: requestParams,
  })
  return response.data
}

export const updateSubscriptionContent = async ({
  params,
  id,
}: {
  params: Partial<SubscriptionContentParams>
  id: number
}): Promise<SubscriptionContent> => {
  const UPDATE_SUBSCRIPTION_CONTENT = `/staff/stores/${
    loadViewObj()?.id
  }/subscription_contents/${id}`

  const requestParams = {
    subscription_content: params,
  }

  const response: AxiosResponse<SubscriptionContent> = await apiClient({
    method: 'PATCH',
    uri: UPDATE_SUBSCRIPTION_CONTENT,
    params: requestParams,
  })
  return response.data
}

export const deleteSubscreiptionContent = async ({
  id,
}: {
  id: number
}): Promise<{ message: string }> => {
  const DELETE_SUBSCRIPTION_FEATURE = `staff/stores/${
    loadViewObj()?.id
  }/subscription_contents/${id}`

  const response = await apiClient({
    method: 'DELETE',
    uri: DELETE_SUBSCRIPTION_FEATURE,
  })
  return response.data
}

export const updateSubscriptionFeature = async ({
  active,
}: {
  active: boolean
}): Promise<{ active: boolean }> => {
  const UPDATE_SUBSCRIPTION_FEATURE = `/staff/stores/${
    loadViewObj()?.id
  }/subscription_features`

  const requestParams = {
    subscription_feature: {
      active,
    },
  }

  const response = await apiClient({
    method: 'PATCH',
    uri: UPDATE_SUBSCRIPTION_FEATURE,
    params: requestParams,
  })
  return response.data
}
