import React, { useState } from 'react'
import { Plus, Minus } from 'phosphor-react'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { UserResponse, UserGender } from '../../../types/user'
import { calcAge, formatDateExceptTimeToJp } from '../../../lib/general'
import { usePcSizeFlag, useBooleanState, useApi } from '../../../lib/hooks'
import { loadViewObj } from '../../../lib/viewObj'
import { DEFAULT_AVATAR } from '../../../lib/const'
import { useLoginContext } from '../../../providers/LoginContextProvider'
import { useNotification } from '../../../providers/NotificationProvider'
import { userSexToJp } from '../../../containers/entities/UserEntityContainer'
import { UserDataRow } from './UserDataRow'
import UserIcon from './UserIcon'
import BirthdayUpdateModal from './BirthdayUpdateModal'
import GenderUpdateModal from './GenderUpdateModal'
import CustomerInfoEditMemoModal from './CustomerInfoEditMemoModal'
import { UserWithdrawal } from './UserWithdrawal'
import { useAdminApi } from '../../../lib/api/admin'

type Props = {
  userInfo: UserResponse | null
  onUpdated: () => void
}

const CustomerInfoCardWrapper = ({
  userInfo,
  onUpdated,
}: Props): JSX.Element => (
  <StyledContainer>
    {userInfo ? (
      <CustomerInfoCard userInfo={userInfo} onUpdated={onUpdated} />
    ) : (
      <Box display='flex' justifyContent='center' py={2}>
        <CircularProgress />
      </Box>
    )}
  </StyledContainer>
)

type CustomerInfoCardProps = {
  userInfo: UserResponse
  onUpdated: () => void
}

const CustomerInfoCard = ({
  userInfo,
  onUpdated,
}: CustomerInfoCardProps): JSX.Element => {
  const { currentStaff, currentStore, lineChannel, hasPermission } =
    useLoginContext()
  const { showSuccessNotification } = useNotification()
  const { user, members_card_rank, user_address, connections } = userInfo
  const [isBirthdayModalOpen, openBirthdayModal, closeBirthdayModal] =
    useBooleanState(false)
  const [isGenderModalOpen, openGenderModal, closeGenderModal] =
    useBooleanState(false)
  const [editMemoModalOpen, openEditMemoModal, closeEditMemoModal] =
    useBooleanState(false)
  const staffApi = useApi()
  const address = user_address
    ? `〒${user_address.zipcode || ''} ${user_address.address} ` +
      `${user_address.house_number || ''} ${user_address.building_name || ''}`
    : ''
  const connection = connections.find(
    (item) => item.store_id === currentStore.id,
  )
  const isPcOrTablet = usePcSizeFlag()
  const [activeSeeMore, setActiveSeeMore] = useState(!!isPcOrTablet)
  const { setGender, setBirthday, loading } = useAdminApi({
    userId: userInfo.user.id,
    onUpdated,
  })

  const handleBirthdayUpdate = async (birthday: string) => {
    const success = await setBirthday(birthday)
    if (success) {
      closeBirthdayModal()
    }
  }

  const handleGenderUpdate = async (sex: UserGender) => {
    const success = await setGender(sex)
    if (success) {
      closeGenderModal()
    }
  }

  const handleMemoUpdate = async (memo: string) => {
    const response = await staffApi.api.put(
      `/connections/${connection?.id}/memo`,
      { memo },
    )
    if (response) {
      showSuccessNotification('メモを更新しました。')
      onUpdated()
      closeEditMemoModal()
    }
  }

  return (
    <>
      <Box display='flex' alignItems='center'>
        <div>
          <UserIcon
            avatar={user.avatar_url || DEFAULT_AVATAR[user.sex]}
            rankColor={members_card_rank?.color}
            iconSize={64}
            badgeSize={12}
            badgePosition={4}
          />
        </div>

        <Box ml={1.5}>
          {Boolean(user.kana_name) && hasPermission('SHOW_USER_NAME_KANA') && (
            <StyledKanaName>{user.kana_name}</StyledKanaName>
          )}

          <Box display='flex' alignItems='baseline'>
            <Box fontWeight='bold' fontSize={20}>
              {hasPermission('SHOW_USER_NAME') ? user.name : user.id}
            </Box>
            {Boolean(user.birthday) && (
              <Box ml={0.5}>({calcAge(user.birthday)})</Box>
            )}
          </Box>

          <Box color='#9A9A9A' fontSize={10}>
            {members_card_rank?.name}
          </Box>
        </Box>
      </Box>

      {connection && !connection.memo && (
        <Button
          style={{ marginTop: 20, fontSize: 15 }}
          size='small'
          color='submit'
          onClick={openEditMemoModal}
          startIcon={<i className='ri-add-line' />}>
          メモを追加する
        </Button>
      )}
      {connection && connection.memo && (
        <StyledMemoContainer onClick={openEditMemoModal}>
          <StyledMemo>{connection.memo}</StyledMemo>
          <StyledEditMemoButton onClick={openEditMemoModal}>
            <i className='ri-pencil-line' />
          </StyledEditMemoButton>
        </StyledMemoContainer>
      )}
      <CustomerInfoEditMemoModal
        open={editMemoModalOpen}
        memo={connection?.memo ?? ''}
        onClose={closeEditMemoModal}
        onSubmit={(updatedMemo) => handleMemoUpdate(updatedMemo)}
      />

      <Box mt={2.5}>
        <UserDataRow label='利用店舗'>
          {userInfo.connected_group_stores
            ?.map((collection) => collection.name)
            .join(', ')}
        </UserDataRow>

        {activeSeeMore && (
          <>
            <Divider sx={{ my: 1.5 }} />

            <UserDataRow label='ユーザーID'>{user.id}</UserDataRow>

            {loadViewObj()?.role === 'admin' && (
              <UserDataRow label='電話番号'>
                {hasPermission('SHOW_USER_PHONE_NUMBER')
                  ? user.phone_number
                  : '---'}
              </UserDataRow>
            )}

            <UserDataRow label='メールアドレス'>
              {user.email?.includes('@example.com')
                ? ''
                : hasPermission('SHOW_USER_EMAIL')
                  ? user.email
                  : '---'}
            </UserDataRow>

            <UserDataRow label='性別'>
              {hasPermission('SHOW_USER_SEX')
                ? user.sex
                  ? userSexToJp(user.sex)
                  : ''
                : '---'}
              {currentStaff.is_toypo_member && (
                <>
                  <Button
                    size='small'
                    color='toypoAdmin'
                    variant='contained'
                    onClick={openGenderModal}
                    sx={{ ml: 1, fontSize: 12 }}>
                    性別変更
                  </Button>

                  <GenderUpdateModal
                    open={isGenderModalOpen}
                    defaultGender={user.sex || null}
                    disabled={loading}
                    onClose={closeGenderModal}
                    onSubmit={handleGenderUpdate}
                  />
                </>
              )}
            </UserDataRow>

            <UserDataRow label='誕生日'>
              {hasPermission('SHOW_USER_BIRTHDAY')
                ? user.birthday
                  ? formatDateExceptTimeToJp(user.birthday)
                  : ''
                : '---'}
              {currentStaff.is_toypo_member && (
                <>
                  <Button
                    size='small'
                    color='toypoAdmin'
                    variant='contained'
                    onClick={openBirthdayModal}
                    sx={{ ml: 1, fontSize: 12 }}>
                    誕生日変更
                  </Button>

                  <BirthdayUpdateModal
                    open={isBirthdayModalOpen}
                    defaultBirthday={user.birthday ?? null}
                    disabled={loading}
                    onClose={closeBirthdayModal}
                    onSubmit={handleBirthdayUpdate}
                  />
                </>
              )}
            </UserDataRow>

            <UserDataRow label='住所'>
              {hasPermission('SHOW_USER_ADDRESS') ? address : '---'}
            </UserDataRow>

            <Divider sx={{ my: 1.5 }} />

            {lineChannel && (
              <UserDataRow label='利用サービス'>
                {userInfo.user.app_version && <div>toypoミニアプリ</div>}
                {userInfo.is_integrated_with_line && <div>LINEミニアプリ</div>}
              </UserDataRow>
            )}

            <UserDataRow label='登録日'>
              {formatDateExceptTimeToJp(userInfo.connected_at)}
            </UserDataRow>

            <UserDataRow label='プッシュ通知'>
              {userInfo.available_push_notification ? 'ON' : 'OFF'}
            </UserDataRow>

            {currentStaff.is_toypo_member && (
              <>
                <Divider sx={{ my: 1.5 }} />
                <UserDataRow label='退会'>
                  <UserWithdrawal user={userInfo.user} onUpdated={onUpdated} />
                </UserDataRow>
              </>
            )}
          </>
        )}

        {!isPcOrTablet && (
          <StyledSeeMoreButton
            type='button'
            onClick={() => setActiveSeeMore((prev) => !prev)}>
            {activeSeeMore ? (
              <>
                <StyledSeeMoreButtonText>閉じる</StyledSeeMoreButtonText>
                <Minus size={12} />
              </>
            ) : (
              <>
                <StyledSeeMoreButtonText>もっと見る</StyledSeeMoreButtonText>
                <Plus size={12} />
              </>
            )}
          </StyledSeeMoreButton>
        )}
      </Box>
    </>
  )
}

export const CARD_WIDTH = 308

const StyledContainer = styled('div')(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 4,
  border: '1px solid #E7E7E8',
  padding: theme.spacing(2),
  [theme.breakpoints.up('tablet')]: {
    width: CARD_WIDTH,
    minWidth: CARD_WIDTH,
  },
  [theme.breakpoints.down('tablet')]: {
    width: '100%',
  },
}))

const StyledKanaName = styled('div')({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})

const StyledSeeMoreButton = styled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(0.5),
  marginTop: theme.spacing(2),
  padding: theme.spacing(1),
  width: '100%',
  border: '1px solid #E1E1E1',
  color: '#A0A0A0',
  backgroundColor: 'white',
  borderRadius: 2,
}))
const StyledSeeMoreButtonText = styled('span')({
  fontWeight: 'bold',
  fontSize: 13,
})
const StyledEditMemoButton = styled('button')(({ theme }) => ({
  position: 'absolute',
  right: -8,
  top: -18,
  fontSize: 24,
  background: theme.palette.submit.main,
  width: 36,
  height: 36,
  borderRadius: 20,
  color: '#fff',
  textAlign: 'center',
  border: 'none',
}))

const StyledMemoContainer = styled(Box)(({ theme }) => ({
  borderRadius: 8,
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#F7F7F8',
  cursor: 'pointer',
}))

const StyledMemo = styled(Typography)({
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  WebkitLineClamp: 10,
  lineHeight: '1.5',
  flex: 1,
  wordBreak: 'break-all',
  whiteSpace: 'pre-wrap',
})

export default CustomerInfoCardWrapper
