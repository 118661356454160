import React, { FC } from 'react'
import Flatpickr from 'react-flatpickr'
import { Japanese } from 'flatpickr/dist/l10n/ja'
import 'flatpickr/dist/themes/material_blue.css'
import { Row, Col, FormGroup, HelpBlock, Radio } from 'react-bootstrap'
import { Controller, useFormContext } from 'react-hook-form'
import { Card } from '../../../components/Card/Card'
import FormLabel from '../../../components/FormInputs/FormLabel'
import LoadingButton from '../../../components/CustomButton/LoadingButton'
import {
  CouponContent,
  CouponStatus,
  CouponStatusType,
} from '../../../containers/entities/CouponContentEntityContainer'
import { altFormat, formatDate } from '../../../lib/general'
import ChangeIndicator from '../../../components/ChangeIndicator'
import { validateDate } from '../../../lib/validation'

type Props = {
  editMode: boolean
  coupon: CouponContent
  onClickPresentCouponContentPublishButton: (
    status: CouponStatusType,
  ) => Promise<void>
  statusToJp: (coupon: CouponContent) => '配信済み' | '配信予定' | '下書き'
  statusLabelBackgroundColor: (coupon: CouponContent) => string
}

const PresentCouponDistributionSettingsCard: FC<Props> = ({
  editMode,
  coupon,
  onClickPresentCouponContentPublishButton,
  statusToJp,
  statusLabelBackgroundColor,
}) => {
  const { getValues, watch, register, setValue, errors, control } =
    useFormContext<{ coupon: CouponContent & { image: string } }>()

  const watchStatus = watch('coupon.status') as CouponStatusType
  const watchPublishAt: string = watch('coupon.publish_at')
  const watchExpiration: string = watch('coupon.expiration')

  return (
    <Row>
      <Col md={12}>
        <Card
          title='配信設定'
          rightContent={
            !!coupon && (
              <p
                style={{
                  backgroundColor: statusLabelBackgroundColor(coupon),
                  color: 'white',
                  padding: '4px 15px',
                  border: 0,
                  borderRadius: 20,
                  fontWeight: 'bold',
                  fontSize: 12,
                }}
              >
                {statusToJp(coupon)}
              </p>
            )
          }
          content={
            coupon?.status === CouponStatus.Publish ? (
              <>
                <p>
                  配信日：
                  {formatDate(coupon.publish_at)}
                </p>
                <p>
                  有効期限：
                  {coupon.expiration ? formatDate(coupon.expiration) : '---'}
                </p>
              </>
            ) : (
              <>
                <Row style={{ marginBottom: 10 }}>
                  <Col
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      marginBottom: 10,
                      alignItems: 'center',
                    }}
                  >
                    <Radio
                      key='coupon-form-active-now'
                      checked={watchStatus === CouponStatus.Publish}
                      onChange={(): void => {
                        setValue('coupon.status', CouponStatus.Publish, {
                          shouldDirty: true,
                        })
                      }}
                      inputRef={register}
                      style={{ margin: 0 }}
                    >
                      <p>今すぐ配信</p>
                    </Radio>
                    <Radio
                      key='coupon-form-active-reservation'
                      checked={watchStatus === CouponStatus.Reservation}
                      onChange={(): void => {
                        setValue('coupon.status', CouponStatus.Reservation, {
                          shouldDirty: true,
                        })
                      }}
                      style={{ margin: 0 }}
                    >
                      <p>配信予約</p>
                    </Radio>
                    <Radio
                      key='coupon-form-active-none'
                      checked={
                        watchStatus === CouponStatus.Unpublished ||
                        watchStatus === CouponStatus.Preview
                      }
                      onChange={(): void => {
                        setValue('coupon.status', CouponStatus.Unpublished, {
                          shouldDirty: true,
                        })
                      }}
                      style={{ margin: 0 }}
                    >
                      <p>まだ配信しない</p>
                    </Radio>
                    {editMode && watchStatus !== coupon?.status && (
                      <ChangeIndicator />
                    )}
                  </Col>
                </Row>
                {watchStatus === CouponStatus.Reservation && (
                  <Row>
                    <Col md={6} sm={9} xs={12}>
                      <FormGroup bsSize='large'>
                        <FormLabel label='配信予定日' />
                        <Controller
                          control={control}
                          name='coupon.publish_at'
                          rules={
                            !editMode
                              ? {
                                  validate: (): boolean | string => {
                                    const publishAt =
                                      getValues('coupon.publish_at')
                                    const publishAtValidationResult =
                                      validateDate(publishAt)
                                    if (!publishAtValidationResult.validation) {
                                      return publishAtValidationResult.message
                                    }
                                    return true
                                  },
                                  required: '配信予定日を入力してください。',
                                }
                              : undefined
                          }
                          render={({ value, onChange }): JSX.Element => (
                            <Flatpickr
                              data-enable-time
                              value={value}
                              options={{
                                altInput: true,
                                allowInput: true,
                                locale: Japanese,
                                altFormat: altFormat,
                                minuteIncrement: 0,
                              }}
                              onChange={(date: Date[]): void => {
                                onChange(
                                  date.length > 0
                                    ? date.toLocaleString().slice(0, -3)
                                    : null,
                                )
                              }}
                            />
                          )}
                        />
                        <HelpBlock className='text-danger'>
                          {errors?.coupon?.publish_at?.message}
                        </HelpBlock>
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                {editMode && (
                  <Row style={{ marginRight: 0 }}>
                    <Col
                      style={{
                        marginLeft: 10,
                        marginTop: 10,
                      }}
                    >
                      <LoadingButton
                        label='変更する'
                        loadingLabel='変更中...'
                        color='info'
                        fill
                        simple
                        pullRight
                        loadingColor='red'
                        disabled={
                          coupon?.status === watchStatus &&
                          coupon?.publish_at === watchPublishAt &&
                          coupon?.expiration === watchExpiration
                        }
                        onClick={() =>
                          onClickPresentCouponContentPublishButton(watchStatus)
                        }
                      />
                    </Col>
                  </Row>
                )}
              </>
            )
          }
        />
      </Col>
    </Row>
  )
}

export default PresentCouponDistributionSettingsCard
