import React, { useState, useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import Flatpickr from 'react-flatpickr'
import { Japanese } from 'flatpickr/dist/l10n/ja'
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { altFormat } from '../../../lib/general'
import { compareValidateDate, validateDate } from '../../../lib/validation'
import { CouponContent } from '../../../containers/entities/CouponContentEntityContainer'
import FormLabel from '../../../components/FormInputs/FormLabel'

type Props = {
  name: string
  editMode: boolean
  isAuto: boolean
  category?: string
}

const CouponExpirationForm: React.FC<Props> = ({
  name,
  editMode,
  isAuto,
  category,
}) => {
  const {
    getValues,
    control,
    watch,
    formState: { errors },
  } = useFormContext<{ coupon: CouponContent & { image: string } }>()
  const [flatpickrKey, setFlatpickrKey] = useState(0)
  const watchIsUnlimited = watch('coupon.is_unlimited')
  const defaultIsUnlimited = isAuto
    ? editMode &&
      watch('coupon.expiration_day') === null &&
      watch('coupon.expiration_time') === null
    : editMode && watch('coupon.expiration') === null

  useEffect(() => {
    setFlatpickrKey((key) => key + 1)
  }, [watchIsUnlimited])

  return (
    <Box>
      <FormLabel label='有効期限' />

      {/* 自動配信クーポンの場合 */}
      {isAuto ? (
        <RelativeDateExpirationForm
          name={name}
          category={category}
          isUnlimited={watchIsUnlimited}
          control={control}
          errors={errors}
        />
      ) : (
        <AbsoluteDateExpirationForm
          name={name}
          isUnlimited={watchIsUnlimited}
          control={control}
          errors={errors}
          getValues={getValues}
          flatpickrKey={flatpickrKey}
        />
      )}

      <Controller
        name={`${name}.is_unlimited`}
        control={control}
        defaultValue={defaultIsUnlimited}
        render={({ onChange, value }) => (
          <FormControlLabel
            control={
              <Checkbox
                checked={value}
                onChange={(e) => onChange(e.target.checked)}
              />
            }
            label='有効期限を設定しない'
          />
        )}
      />
    </Box>
  )
}

export default CouponExpirationForm

const AbsoluteDateExpirationForm = ({
  name,
  isUnlimited,
  control,
  errors,
  getValues,
  flatpickrKey,
}) => (
  <>
    <Controller
      control={control}
      name={`${name}.expiration`}
      rules={{
        validate: (): boolean | string => {
          if (isUnlimited) return true
          const publishAt = getValues(`${name}.publish_at`)
          const expiration = getValues(`${name}.expiration`)
          const expirationValidationResult = validateDate(expiration)
          const compareValidationResult = compareValidateDate(
            publishAt,
            expiration,
          )

          if (!expirationValidationResult.validation) {
            return expirationValidationResult.message
          }
          if (!compareValidationResult.validation) {
            return compareValidationResult.message
          }
          return true
        },
        required: !isUnlimited ? '有効期限を入力してください。' : false,
      }}
      render={({ value, onChange }) => (
        <Box sx={{ maxWidth: 400 }}>
          <Flatpickr
            key={flatpickrKey}
            data-enable-time
            value={value}
            options={{
              altInput: true,
              allowInput: true,
              locale: Japanese,
              altFormat,
              minuteIncrement: 0,
            }}
            onChange={(date: Date[]): void => {
              onChange(
                date.length > 0 ? date.toLocaleString().slice(0, -3) : null,
              )
            }}
            disabled={isUnlimited}
          />
        </Box>
      )}
    />

    {errors?.[name]?.expiration && (
      <Typography color='error' variant='body2'>
        {errors?.[name]?.expiration.message}
      </Typography>
    )}
  </>
)

const RelativeDateExpirationForm = ({
  name,
  category,
  isUnlimited,
  control,
  errors,
}) => (
  <Box>
    <Box display='flex' alignItems='center'>
      <span style={{ color: '#a9a9a9' }}>
        {category === 'birthday' ? '誕生日から' : '配信されてから'}
      </span>
      <Controller
        name={`${name}.expiration_day`}
        control={control}
        rules={{
          validate: (value) => {
            if (isUnlimited) return true
            if (value === '' || value === null)
              return '有効期限を入力してください。'
            if (Number(value) < 0) return '0以上の数字を入力してください。'
            if (Number(value) > 1000)
              return '1000以下の数字を入力してください。'
            return true
          },
        }}
        render={({ value, onChange }) => (
          <TextField
            type='number'
            size='small'
            variant='outlined'
            sx={{ width: 100 }}
            value={value}
            disabled={isUnlimited}
            onChange={(e) => onChange(e.target.value)}
          />
        )}
      />
      <Typography sx={{ color: '#a9a9a9' }}>日後の</Typography>

      <Controller
        name={`${name}.expiration_time`}
        control={control}
        rules={{
          required: isUnlimited ? false : '選択してください',
        }}
        render={({ value, onChange }) => (
          <Select
            onChange={(e) => onChange(e.target.value)}
            value={value}
            size='small'
            variant='outlined'
            sx={{ width: 100, mr: 1 }}
            disabled={isUnlimited}
          >
            {Array.from({ length: 24 }, (_, i) => (
              <MenuItem key={i} value={i}>{`${i}時`}</MenuItem>
            ))}
          </Select>
        )}
      />
      <Typography sx={{ color: '#a9a9a9' }}>まで有効</Typography>
    </Box>{' '}
    {errors?.[name]?.['expiration_day']?.message && (
      <FormHelperText sx={{ color: 'error.main' }}>
        {errors[name]?.['expiration_day'].message}
      </FormHelperText>
    )}
    {category === 'birthday' && (
      <Box mt={2}>
        <Typography variant='body2' color='textSecondary'>
          誕生日クーポンは、誕生日前の配信日から有効期限までの間に登録したユーザーも獲得できます。
        </Typography>
        <Typography variant='body2' color='textSecondary'>
          配信日前の指定がない場合は、誕生月に登録したユーザーが獲得できます。
        </Typography>
      </Box>
    )}
  </Box>
)
