import React from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import CircularProgress from '@mui/material/CircularProgress'
import {
  Users,
  Repeat,
  Storefront,
  CurrencyCircleDollar,
  ArrowUpRight,
  ArrowRight,
  ArrowDownRight,
} from 'phosphor-react'
import { useGetApiCall, useMobileFlag } from '../../../lib/hooks'
import HomeSection from './HomeSection'
import { DailySummaryResponse } from '../../../types/dailySummary'

const DailySummary = (): JSX.Element => {
  const history = useHistory()
  const yesterday = moment().subtract(1, 'days')
  const oneMonthAgo = moment().subtract(31, 'days')
  const baseApi = useGetApiCall<DailySummaryResponse>(
    `/daily_summaries/${yesterday.format('YYYY-MM-DD')}`,
  )
  const comparisonApi = useGetApiCall<DailySummaryResponse>(
    `/daily_summaries/${oneMonthAgo.format('YYYY-MM-DD')}`,
  )
  const baseSummary = baseApi.response ?? null
  const comparisonSummary = comparisonApi.response ?? null

  const totalConnectedUserCountDiff =
    baseSummary && comparisonSummary
      ? baseSummary.total_connected_user_count -
        comparisonSummary.total_connected_user_count
      : 0
  const repeaterRateDiff =
    baseSummary && comparisonSummary
      ? Math.round(
          (baseSummary.repeater_rate - comparisonSummary.repeater_rate) * 1000,
        ) / 10
      : 0
  const revisitCountDiff =
    baseSummary && comparisonSummary
      ? baseSummary.revisit_count - comparisonSummary.revisit_count
      : 0
  const totalSalesDiff =
    baseSummary && comparisonSummary
      ? baseSummary.total_sales - comparisonSummary.total_sales
      : 0

  return (
    <HomeSection
      title={
        <>
          概要
          <Box component='span' fontSize={14} fontWeight={400} ml={1}>
            {yesterday.format('YYYY年M月D日')}時点
          </Box>
        </>
      }
      onMoveToDetail={() => history.push('/admin/dashboard')}>
      {baseSummary && comparisonSummary ? (
        <Box display='flex' p={1.5} flexWrap='wrap'>
          <SummaryItem
            iconBackgroundColor='#7EC1BE'
            icon={<Users size={16} weight='bold' color='#E5F3F2' />}
            label='ユーザー数'
            tooltip={`現時点でお店のミニアプリを登録しているユーザーの数です。比較の対象は30日前時点でのユーザー数です。${tooltip(totalConnectedUserCountDiff, '人')}`}>
            <StyledCount>
              {baseSummary.total_connected_user_count.toLocaleString()}
            </StyledCount>
            人
            {totalConnectedUserCountDiff !== 0 && (
              <CompareCount diff={totalConnectedUserCountDiff} />
            )}
          </SummaryItem>

          <SummaryItem
            iconBackgroundColor='#F5BD6F'
            icon={<Repeat size={16} weight='bold' color='#FDF2E2' />}
            label='リピーター率'
            tooltip={`ユーザー数のうち、2回以上アプリを利用したことのあるユーザー数の割合です。比較の対象は30日前時点でのリピーター率です。${tooltip(repeaterRateDiff, '%')}`}>
            <StyledCount>
              {Math.round(baseSummary.repeater_rate * 1000) / 10}
            </StyledCount>
            %
            {repeaterRateDiff !== 0 && <CompareCount diff={repeaterRateDiff} />}
          </SummaryItem>

          <SummaryItem
            iconBackgroundColor='#E78B9A'
            icon={<Storefront size={16} weight='bold' color='#FAE8EB' />}
            label='再来店数(30日間)'
            tooltip={`30日前〜昨日までに2回以上アプリが利用された数です。比較の対象は60日前〜30日前までの集計です。${tooltip(revisitCountDiff, '人')}`}>
            <StyledCount>
              {baseSummary.revisit_count.toLocaleString()}
            </StyledCount>
            回
            {revisitCountDiff !== 0 && <CompareCount diff={revisitCountDiff} />}
          </SummaryItem>

          <SummaryItem
            iconBackgroundColor='#8163F0'
            icon={
              <CurrencyCircleDollar size={16} weight='bold' color='#E6E0FC' />
            }
            label='アプリ内売上(30日間)'
            tooltip={`30日前〜昨日までにアプリ上で行われた注文や決済の合計です。比較の対象は60日前〜30日前までの合計です。${tooltip(totalSalesDiff, '円')}`}>
            ¥
            <StyledCount>
              {baseSummary.total_sales.toLocaleString()}
            </StyledCount>
            {totalSalesDiff !== 0 && <CompareCount diff={totalSalesDiff} />}
          </SummaryItem>
        </Box>
      ) : (
        <Box display='flex' justifyContent='center' p={3}>
          <CircularProgress />
        </Box>
      )}
    </HomeSection>
  )
}

const tooltip = (diff: number, unit: string): string => {
  if (diff === 0) return '30日前と比べて変化がありません'
  if (diff > 0)
    return `30日前と比べて${diff.toLocaleString()}${unit}増えています🎉`
  return `30日前と比べて${Math.abs(diff).toLocaleString()}${unit}減っています`
}

type SummaryItemProps = {
  iconBackgroundColor: string
  icon: React.ReactNode
  label: string
  tooltip: string
  children: React.ReactNode
}

const SummaryItem = ({
  iconBackgroundColor,
  icon,
  label,
  tooltip,
  children,
}: SummaryItemProps): JSX.Element => {
  const isMobile = useMobileFlag()

  return (
    <Box
      mb={isMobile ? 2 : 0}
      flex={isMobile ? undefined : 1}
      width={isMobile ? '50%' : undefined}>
      <StyledIcon bgcolor={iconBackgroundColor}>{icon}</StyledIcon>

      <StyledLabelContainer>
        {label}
        <Tooltip title={tooltip}>
          <i className='ri-question-fill' style={{ fontSize: 18 }} />
        </Tooltip>
      </StyledLabelContainer>

      <StyledValueContainer>{children}</StyledValueContainer>
    </Box>
  )
}

type CompareCountProps = {
  diff: number
}

const CompareCount = ({ diff }: CompareCountProps): JSX.Element => {
  if (diff === 0) {
    return <ArrowRight weight='bold' color='#67C3BC' />
  }

  if (diff > 0) {
    return (
      <StyledCompareCountContainer positive>
        <ArrowUpRight weight='bold' />
        {Math.abs(diff).toLocaleString()}
      </StyledCompareCountContainer>
    )
  }

  return (
    <StyledCompareCountContainer positive={false}>
      <ArrowDownRight weight='bold' />
      {Math.abs(diff).toLocaleString()}
    </StyledCompareCountContainer>
  )
}

const StyledIcon = styled('div', {
  shouldForwardProp: (prop) => prop !== 'bgcolor',
})<{ bgcolor: string }>(({ bgcolor, theme }) => ({
  backgroundColor: bgcolor,
  padding: theme.spacing(1),
  borderRadius: 8,
  display: 'inline-flex',
}))
const StyledLabelContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: '#858585',
  gap: theme.spacing(0.5),
}))
const StyledValueContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  flexWrap: 'wrap',
  gap: theme.spacing(0.5),
}))
const StyledCount = styled('span')({
  fontWeight: 'bold',
  fontSize: 24,
})

const StyledCompareCountContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'positive',
})<{ positive: boolean }>(({ positive }) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold',
  color: positive ? '#67C3BC' : '#636464',
}))

export default DailySummary
