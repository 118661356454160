import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useApi } from '../../../lib/hooks'
import { open } from '../../../lib/general'
import { TodoItem, TodoButton } from '../../../types/todo'
import ProgressBar from '../../../components/ProgressBar/ProgressBar'
import TodoListItem from './TodoListItem'
import QrcodeModal from './QrcodeModal'

const ua = navigator.userAgent
const isDesktop = !(
  ua.indexOf('iPhone') >= 0 ||
  ua.indexOf('Android') >= 0 ||
  ua.indexOf('webView') >= 0
)

type WrapperProps = {
  todoItems: TodoItem[]
}

const TodoList = ({ todoItems }: WrapperProps): JSX.Element => {
  const history = useHistory()
  const { api } = useApi()
  const totalCount = todoItems.length
  const doneCount = todoItems.filter((todoItem) => todoItem.done).length
  const [showModal, setShowModal] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [modalLink, setModalLink] = useState('')

  const onClickButton = (item: TodoItem, button: TodoButton) => {
    if (item.check_type === 'manual') {
      api.patch(`/todo_items/${item.id}`, { todo_item: { done: true } })
    }

    if (button.button_type === 'transition') {
      history.push(button.value)
    } else if (button.button_type === 'link') {
      if (button.app_only && isDesktop) {
        setShowModal(true)
        setModalTitle(button.text)
        setModalLink(button.value)
        return
      }

      open(button.value)
    }
  }

  return (
    <div>
      <Typography variant='title'>運用までのやること</Typography>

      <ProgressBar totalCount={totalCount} doneCount={doneCount} />

      <Box mt={1} display='grid' gap={1}>
        {todoItems.map((item) => (
          <TodoListItem
            key={item.id}
            todoItem={item}
            onClickButton={(button) => onClickButton(item, button)}
            isDesktop={isDesktop}
          />
        ))}
      </Box>

      <QrcodeModal
        show={showModal}
        title={modalTitle}
        link={modalLink}
        onHide={(): void => setShowModal(false)}
      />
    </div>
  )
}

export default TodoList
