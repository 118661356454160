/* eslint-disable @typescript-eslint/camelcase */
import apiClient from './apiClient'

export const signIn = async ({ email, password }) => {
  const SIGN_IN_URL = '/staff/auth/sign_in'

  const params = {
    email: email,
    password: password,
  }

  const response = await apiClient({
    method: 'POST',
    uri: SIGN_IN_URL,
    params: params,
  })
  return response
}

export const signUp = async ({ email, password, name, registered }) => {
  const SIGN_UP_URL = '/staff/auth'

  const params = {
    email,
    password,
    password_confirmation: password,
    name,
    registered,
  }

  const response = await apiClient({
    method: 'POST',
    uri: SIGN_UP_URL,
    params: params,
  })
  return response
}
