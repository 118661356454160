import React from 'react'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import SwitchForm from '../../../components/FormInputs/SwitchForm'
import { open } from '../../../lib/general'
import { useBooleanState } from '../../../lib/hooks'
import { useLoginContext } from '../../../providers/LoginContextProvider'

const GUIDE_URL =
  'https://toypo.notion.site/174b5411a2574559a3a83424593ef66c?pvs=4'
const FAQ_URL =
  'https://toypo-guide.zendesk.com/hc/ja/categories/4403515984791-%E3%82%88%E3%81%8F%E3%81%82%E3%82%8B%E8%B3%AA%E5%95%8F'
const CONTACT_URL =
  'https://toypo-guide.zendesk.com/hc/ja/requests/new?ticket_form_id=1500001024222'
const CONTACT_LINE_URL = 'https://lin.ee/TXg9m83'

type Props = {
  isTodoListActive: boolean
  disableTodoListSwitch: boolean
  onChangeTodoListStatus: (active: boolean) => void
}

const SupportList = ({
  isTodoListActive,
  disableTodoListSwitch,
  onChangeTodoListStatus,
}: Props): JSX.Element => {
  const { currentStore } = useLoginContext()
  const [show, , , toggleShow] = useBooleanState(false)

  return (
    <Box bgcolor='#EAEAEE' borderRadius={2} p={2}>
      <Box color='#6A6A6A' fontSize={18} fontWeight={700}>
        困ったときは
      </Box>

      <Box display='grid' gap={1.5} mt={1.5}>
        <ListItem label='トイポ利用ガイド' onClick={() => open(GUIDE_URL)} />

        {currentStore.store_type === 'child' && (
          <>
            <ListItem
              label='やることリストの表示'
              icon={show ? 'ri-subtract-line' : 'ri-add-line'}
              onClick={toggleShow}
            />

            {show && (
              <>
                やることリストをもとに設定を進めるとスムーズに運用を開始できます。
                <SwitchForm
                  formMarginBottom={10}
                  disabled={disableTodoListSwitch}
                  checkedText='表示'
                  uncheckedText='非表示'
                  checked={isTodoListActive}
                  onChange={() => onChangeTodoListStatus(!isTodoListActive)}
                />
              </>
            )}
          </>
        )}

        <ListItem label='よくある質問' onClick={() => open(FAQ_URL)} />
        <ListItem
          label='フォームでお問い合わせ'
          onClick={() => open(CONTACT_URL)}
        />
        <ListItem
          label='LINEでお問い合わせ'
          onClick={() => open(CONTACT_LINE_URL)}
        />
      </Box>
    </Box>
  )
}

type ListItemProps = {
  label: string
  icon?: string
  onClick: () => void
}
const ListItem = ({
  label,
  icon = 'ri-arrow-right-s-line',
  onClick,
}: ListItemProps): JSX.Element => (
  <StyledListItem onClick={onClick}>
    {label}
    <i className={icon} />
  </StyledListItem>
)

const StyledListItem = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontWeight: 'bold',
  color: '#333',
  cursor: 'pointer',
})

export default SupportList
