import React from 'react'
import CustomSwitch from '../Switch/CustomSwitch'
import FormLabel from './FormLabel'
import FormGroup from './FormGroup'
import ChangeIndicator from '../ChangeIndicator'

interface Props {
  label?: string
  attachment?: string
  tooltipText?: string
  checked: boolean
  onChange: (s: any) => void
  width?: number
  height?: number
  offColor?: string
  onColor?: string
  uncheckedText?: string
  checkedText?: string
  inputRef?: any
  horizontal?: boolean
  formMarginBottom?: number
  disabled?: boolean
  showChangeIndicator?: boolean
}

const SwitchForm: React.FC<Props> = ({
  label,
  attachment,
  tooltipText,
  checked,
  onChange = () => {},
  width = 96,
  height = 32,
  offColor = '#DDDDDD',
  onColor = '#0076d7',
  uncheckedText = '無効',
  checkedText = '有効',
  inputRef = null,
  horizontal = false,
  formMarginBottom = 20,
  disabled = false,
  showChangeIndicator,
  ...otherProps
}) => (
  <FormGroup
    style={{
      display: horizontal ? 'flex' : 'block',
      marginBottom: formMarginBottom,
    }}>
    {!!label && (
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
        <FormLabel
          label={label}
          tooltipText={tooltipText}
          attachment={attachment}
          style={{ marginBottom: 0 }}
        />

        {showChangeIndicator && <ChangeIndicator />}
      </div>
    )}

    <CustomSwitch
      checked={checked}
      onChange={onChange}
      offColor={offColor}
      onColor={onColor}
      width={width}
      height={height}
      uncheckedText={uncheckedText}
      checkedText={checkedText}
      inputRef={inputRef}
      disabled={disabled}
      {...otherProps}
    />
  </FormGroup>
)

export default SwitchForm
