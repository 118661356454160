import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '../../../components/DialogTitle'
import { UberDirectStatus } from '../../../types/order'

export type DeliveryMethodForm = {
  uber_direct_status: UberDirectStatus
}

type Props = {
  open: boolean
  defaultValues: DeliveryMethodForm
  disabled: boolean
  onClose: () => void
  onSubmit: (params: DeliveryMethodForm) => void
}

type DeliveryMethod = 'self' | 'uber_direct'

const DeliveryMethodDialog = ({
  open,
  defaultValues,
  disabled,
  onClose,
  onSubmit,
}: Props): JSX.Element => {
  const [deliveryMethod, setDeliveryMethod] = useState<DeliveryMethod>('self')

  useEffect(() => {
    if (open) {
      setDeliveryMethod(
        defaultValues.uber_direct_status === 'active' ? 'uber_direct' : 'self',
      )
    }
  }, [open, defaultValues.uber_direct_status])

  const handleSubmit = () => {
    onSubmit({
      uber_direct_status:
        deliveryMethod === 'uber_direct' ? 'active' : 'inactive',
    })
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose} maxWidth='sm'>
      <DialogTitle onClose={onClose}>配達方法</DialogTitle>

      <DialogContent>
        <RadioGroup
          value={deliveryMethod}
          onChange={(e) => setDeliveryMethod(e.target.value as DeliveryMethod)}>
          <FormControlLabel
            value='self'
            control={<Radio disabled={disabled} />}
            label='自店舗配達員による配達'
          />

          <FormControlLabel
            value='uber_direct'
            control={<Radio disabled={disabled} />}
            label='UberEats配達パートナーによる配達'
          />
        </RadioGroup>
      </DialogContent>

      <DialogActions sx={{ pt: 2 }}>
        <Button
          fullWidth
          variant='outlined'
          color='cancel'
          disabled={disabled}
          onClick={onClose}>
          閉じる
        </Button>

        <Button
          fullWidth
          variant='contained'
          color='submit'
          disabled={disabled}
          onClick={handleSubmit}>
          更新する
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeliveryMethodDialog
