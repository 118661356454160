import React from 'react'
import { Modal, Table } from 'react-bootstrap'

import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox'

const EditStaffRoleModal = ({
  show,
  onHide,
  stores = [],
  groups = [],
  ownerGroup,
  submitButton,
  onClickRow,
  selectedIds = [],
}) => (
  <Modal show={show} onHide={onHide}>
    <Modal.Header closeButton>
      <Modal.Title>店舗を選択</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      {!!ownerGroup && (
        <>
          <div style={{ marginLeft: 10, fontWeight: 'bold', fontSize: 16 }}>
            オーナー
          </div>
          <Table hover>
            <tbody>
              <tr
                key={ownerGroup.id}
                style={{ cursor: 'pointer' }}
                onClick={() => onClickRow(ownerGroup.id)}>
                <td
                  style={{
                    width: 40,
                    border: '0px none',
                    borderRadius: '5px 0 0 5px',
                  }}>
                  <CustomCheckbox
                    readOnly
                    checked={selectedIds.includes(ownerGroup.id)}
                  />
                </td>
                <td
                  style={{
                    border: '0px none',
                    borderRadius: '0 5px 5px 0',
                  }}>
                  {ownerGroup.name}
                </td>
              </tr>
            </tbody>
          </Table>
        </>
      )}
      {!!groups.length && (
        <>
          <div style={{ marginLeft: 10, fontWeight: 'bold', fontSize: 16 }}>
            グループ
          </div>
          <Table hover>
            <tbody>
              {groups?.map((group) => (
                <tr
                  key={group.id}
                  style={{ cursor: 'pointer' }}
                  onClick={() => onClickRow(group.id)}>
                  <td
                    style={{
                      width: 40,
                      border: '0px none',
                      borderRadius: '5px 0 0 5px',
                    }}>
                    <CustomCheckbox
                      readOnly
                      checked={selectedIds.includes(group.id)}
                    />
                  </td>
                  <td
                    style={{
                      border: '0px none',
                      borderRadius: '0 5px 5px 0',
                    }}>
                    {group.name}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
      {!!stores.length && (
        <>
          <div style={{ marginLeft: 10, fontWeight: 'bold', fontSize: 16 }}>
            店舗
          </div>
          <Table hover>
            <tbody>
              {stores?.map((store) => (
                <tr
                  key={store.id}
                  style={{ cursor: 'pointer' }}
                  onClick={() => onClickRow(store.id)}>
                  <td
                    style={{
                      width: 40,
                      border: '0px none',
                      borderRadius: '5px 0 0 5px',
                    }}>
                    <CustomCheckbox
                      readOnly
                      checked={selectedIds.includes(store.id)}
                    />
                  </td>
                  <td
                    style={{
                      border: '0px none',
                      borderRadius: '0 5px 5px 0',
                    }}>
                    {store.name}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}

      {submitButton}
    </Modal.Body>
  </Modal>
)

export default EditStaffRoleModal
