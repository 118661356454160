import React, { useState } from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormHelperText from '@mui/material/FormHelperText'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { useApi, useMobileFlag, useBooleanState } from '../../../lib/hooks'
import {
  StoreBenchmarkResource,
  StoreBenchmarkStatus,
} from '../../../types/storeBenchmark'
import {
  StoreBenchmarkTypeLabels,
  StoreBenchmarkIndicatorLabels,
} from '../../../constants/storeBenchmark'
import { useNotification } from '../../../providers/NotificationProvider'
import FormLabel from '../../../components/FormInputs/FormLabel'
import {
  FormChangedAlertModal,
  Alert,
} from '../../../components/Modal/FormChangedAlertModal'
import Section from '../../../components/Section'
import DailyBenchmarkCalendarForm, {
  DailyBenchmarkMap,
} from './DailyBenchmarkCalendarForm'

type BenchmarkEditForm = {
  title: string
  description: string
  benchmark_value: number
  track_daily_performance: boolean
}

type Props = {
  benchmark: StoreBenchmarkResource
  dailyBenchmarks: StoreBenchmarkResource[]
  onUpdatedBenchmark: () => void
}

const BenchmarkEditForm = ({
  benchmark,
  dailyBenchmarks,
  onUpdatedBenchmark,
}: Props): JSX.Element => {
  const isMobile = useMobileFlag()
  const { showSuccessNotification } = useNotification()
  const history = useHistory()
  const { api, loading } = useApi()
  const [isPauseModalOpen, openPauseModal, closePauseModal] =
    useBooleanState(false)
  const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] =
    useBooleanState(false)
  const [isStartModalOpen, openStartModal, closeStartModal] =
    useBooleanState(false)
  const ongoing = benchmark.benchmark_progress === 'ongoing'
  const defaultDailyBenchmarkMap = dailyBenchmarks.reduce(
    (results, dailyBenchmark) => {
      const index = moment(dailyBenchmark.start_date).diff(
        moment(benchmark.start_date),
        'days',
      )
      return { ...results, [index]: dailyBenchmark.benchmark_value }
    },
    {},
  )
  const [dailyBenchmarkMap, setDailyBenchmarkMap] = useState<DailyBenchmarkMap>(
    defaultDailyBenchmarkMap,
  )
  const [isDailyBenchmarkDirty, setDailyBenchmarkDirty] = useState(false)
  const defaultValues = generateDefaultValues(benchmark, dailyBenchmarks)
  const { control, errors, handleSubmit, watch, formState, reset } =
    useForm<BenchmarkEditForm>({ defaultValues })
  const { isDirty } = formState
  const watchValues = watch()

  const editBenchmark = async (formValue: BenchmarkEditForm) => {
    const params = {
      title: formValue.title,
      description: formValue.description,
      benchmark_value: formValue.benchmark_value,
      daily_benchmarks: formValue.track_daily_performance
        ? Object.entries(dailyBenchmarkMap).map(([index, value]) => ({
            index: Number(index),
            benchmark_value: value,
          }))
        : [
            ...Array(
              moment(benchmark.end_date).diff(
                moment(benchmark.start_date),
                'days',
              ) + 1,
            ),
          ].map((_, i) => ({ index: i, benchmark_value: 0 })),
    }

    const response = await api.patch(
      `/store_benchmarks/${benchmark.id}`,
      params,
    )
    if (response) {
      reset(formValue)
      setDailyBenchmarkDirty(false)

      showSuccessNotification('目標を更新しました。')
      onUpdatedBenchmark()
    }
  }

  const updateBenchmarkStatus = async (status: StoreBenchmarkStatus) => {
    const response = await api.patch(`/store_benchmarks/${benchmark.id}`, {
      status,
    })
    if (response) {
      const message =
        status === 'active' ? '目標を再開しました。' : '目標を停止しました。'
      showSuccessNotification(message)
      closePauseModal()
      closeStartModal()
      onUpdatedBenchmark()
    }
  }

  const deleteBenchmark = async () => {
    const response = await api.delete(`/store_benchmarks/${benchmark.id}`)
    if (response) {
      showSuccessNotification('目標を削除しました。')
      history.push('/admin/benchmark')
    }
  }

  return (
    <>
      <Section title='基本情報'>
        <Box mb={2}>
          <FormLabel label='目標タイトル' />
          <Controller
            control={control}
            name='title'
            render={(props) => (
              <OutlinedInput fullWidth disabled={loading} {...props} />
            )}
          />
        </Box>

        <Box>
          <FormLabel label='メモ' attachment='任意' />
          <Controller
            control={control}
            name='description'
            render={(props) => (
              <OutlinedInput
                fullWidth
                multiline
                disabled={loading}
                rows={5}
                placeholder='目標に関するメモを入力しましょう'
                {...props}
              />
            )}
          />
        </Box>
      </Section>

      <Section title='目標設定'>
        <Box mb={3}>
          <FormLabel label='タイプ' />
          {StoreBenchmarkTypeLabels[benchmark.benchmark_type]}
        </Box>

        <Box mb={3}>
          <FormLabel label='指標' />
          {StoreBenchmarkIndicatorLabels[benchmark.benchmark_indicator]}
        </Box>

        <Box mb={2}>
          <FormLabel label='目標値' />
          <Controller
            control={control}
            name='benchmark_value'
            valueAsNumber
            rules={{ required: '目標値を入力してください' }}
            render={({ value, onChange }) => (
              <OutlinedInput
                error={Boolean(errors.benchmark_value)}
                disabled={loading}
                type='number'
                value={value}
                onChange={({ target }) =>
                  onChange(
                    target.value === '' ? 0 : Math.max(0, Number(target.value)),
                  )
                }
              />
            )}
          />
          {errors.benchmark_value?.message && (
            <FormHelperText error>
              {errors.benchmark_value?.message}
            </FormHelperText>
          )}
        </Box>

        <Box>
          <Controller
            control={control}
            name='track_daily_performance'
            render={({ onChange, value }) => (
              <FormControlLabel
                label='日次の目標も設定する'
                disabled={isMobile || loading}
                control={
                  <Checkbox
                    checked={value}
                    onChange={(e) => onChange(e.target.checked)}
                  />
                }
              />
            )}
          />
        </Box>
        {isMobile && (
          <FormHelperText>※日次目標の設定はPC版から行なえます。</FormHelperText>
        )}

        {!isMobile && watchValues.track_daily_performance && (
          <DailyBenchmarkCalendarForm
            startDate={benchmark.start_date}
            endDate={benchmark.end_date}
            baseBenchmarkValue={watchValues.benchmark_value}
            dailyBenchmarkMap={dailyBenchmarkMap}
            fillDayOffsByDailyBenchmark={dailyBenchmarks.length > 0}
            onChange={(newDailyBenchmarkMap) => {
              setDailyBenchmarkMap(newDailyBenchmarkMap)
              setDailyBenchmarkDirty(true)
            }}
          />
        )}
      </Section>

      <StyledActionContainer>
        <Button
          variant='outlined'
          color='cancel'
          disabled={loading}
          onClick={() => history.push('/admin/benchmark')}>
          もどる
        </Button>

        <Button
          variant='contained'
          color='submit'
          disabled={(!isDirty && !isDailyBenchmarkDirty) || loading}
          onClick={handleSubmit(editBenchmark)}>
          変更を保存する
        </Button>
      </StyledActionContainer>

      <StyledSpecialActionContainer>
        {ongoing && benchmark.status === 'active' && (
          <Button color='danger' disabled={loading} onClick={openPauseModal}>
            目標を停止する
          </Button>
        )}

        {ongoing && benchmark.status === 'paused' && (
          <Button color='submit' disabled={loading} onClick={openStartModal}>
            目標を再開する
          </Button>
        )}

        {(!ongoing || (ongoing && benchmark.status === 'paused')) && (
          <Button color='danger' disabled={loading} onClick={openDeleteModal}>
            目標を削除する
          </Button>
        )}
      </StyledSpecialActionContainer>

      <FormChangedAlertModal
        show={isPauseModalOpen}
        title='目標を停止する'
        submitButtonLabel='停止する'
        danger
        disabled={loading}
        onSubmit={() => updateBenchmarkStatus('paused')}
        onCancel={closePauseModal}>
        目標を停止してよろしいですか？
        <Alert severity='warning'>
          <ul>
            <li>
              停止した目標はホーム画面へ表示されなくなりますが、いつでも再開することができます。
            </li>
            <li>目標を停止しても実績は計測されます。</li>
          </ul>
        </Alert>
      </FormChangedAlertModal>

      <FormChangedAlertModal
        show={isDeleteModalOpen}
        title='目標を削除する'
        submitButtonLabel='削除する'
        needAgreement
        danger
        disabled={loading}
        onSubmit={deleteBenchmark}
        onCancel={closeDeleteModal}>
        目標を削除してよろしいですか？
        <Alert severity='danger'>
          <ul>
            <li>削除した目標を復活させることはできません。</li>
          </ul>
        </Alert>
      </FormChangedAlertModal>

      <FormChangedAlertModal
        show={isStartModalOpen}
        title='目標を再開する'
        submitButtonLabel='再開する'
        disabled={loading}
        onSubmit={() => updateBenchmarkStatus('active')}
        onCancel={closeStartModal}>
        目標を再開してよろしいですか？
        <br />
        目標を再開すると実績と進捗がホーム画面へ表示されます。
      </FormChangedAlertModal>
    </>
  )
}

const generateDefaultValues = (
  benchmark: StoreBenchmarkResource,
  dailyBenchmarks: StoreBenchmarkResource[],
) => ({
  title: benchmark.title,
  description: benchmark.description || '',
  benchmark_value: benchmark.benchmark_value,
  track_daily_performance: dailyBenchmarks.length > 0,
})

const StyledActionContainer = styled('div')(({ theme }) => ({
  margin: theme.spacing(2),
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  '& > button': {
    width: 300,
  },
}))

const StyledSpecialActionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  gap: theme.spacing(1),
  '& button': {
    width: 300,
  },
}))

export default BenchmarkEditForm
