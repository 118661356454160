import React, { FC, useEffect, useState } from 'react'
import { Col, FormGroup, Modal, Radio, Row } from 'react-bootstrap'
import Button from '../../../components/CustomButton/CustomButton'
import FormInput from '../../../components/FormInputs/FormInput'
import { IllegalStatus } from '../../../types/activityLog'
import { IllegalStatusLabel } from '../../../constants/activityLog'

const IllegalStatuses = ['legal', 'illegal', 'doubtful'] as const

type Props = {
  show: boolean
  onHide: () => void
  title: string | JSX.Element
  buttonText: string | JSX.Element
  defaultIllegalStatus: IllegalStatus
  defaultDoubtfulReasonText: string
  selectedActivityLogId: number
  onUpdateIllegalStatus: ({
    id,
    illegalStatus,
    doubtfulReasonText,
  }: {
    id: number
    illegalStatus: IllegalStatus
    doubtfulReasonText: string
  }) => void
}

const ActivityLogIllegalStatusChangeModal: FC<Props> = ({
  show,
  onHide,
  title,
  buttonText,
  defaultIllegalStatus,
  defaultDoubtfulReasonText,
  selectedActivityLogId,
  onUpdateIllegalStatus,
}) => {
  const [illegalStatus, setIllegalStatus] = useState<IllegalStatus>('legal')
  const [doubtfulReasonText, setDoubtfulReasonText] = useState<string>('')

  useEffect(() => {
    setIllegalStatus(defaultIllegalStatus)
    setDoubtfulReasonText(defaultDoubtfulReasonText)
  }, [defaultIllegalStatus, defaultDoubtfulReasonText])

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title componentClass='h6'>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row style={{ marginTop: 10 }}>
          <Col md={12}>
            {IllegalStatuses.map((status) => (
              <Radio
                key={`subscription-status-change-modal-${status}`}
                name='radioGroup'
                checked={status === illegalStatus}
                onChange={() => setIllegalStatus(status)}>
                <b>{IllegalStatusLabel[status]}</b>
              </Radio>
            ))}
          </Col>
        </Row>
        {(illegalStatus === 'doubtful' || illegalStatus === 'illegal') && (
          <Row style={{ marginTop: 10 }}>
            <Col md={12}>
              <FormGroup bsSize='large'>
                <FormInput
                  label='疑わしい理由'
                  ncol='col-md-12'
                  type='text'
                  rows='2'
                  componentClass='textarea'
                  bsClass='form-control'
                  value={doubtfulReasonText}
                  onChange={(e) => setDoubtfulReasonText(e.target.value)}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          bsStyle='info'
          bsSize='small'
          fill
          onClick={() =>
            onUpdateIllegalStatus({
              id: selectedActivityLogId,
              illegalStatus,
              doubtfulReasonText,
            })
          }>
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ActivityLogIllegalStatusChangeModal
