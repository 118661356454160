import React from 'react'
import { withRouter } from 'react-router'
import { Grid, Row, Col, Form, FormGroup, FormControl } from 'react-bootstrap'
import { Card } from '../components/Card/Card'
import { combineContainer } from '../lib/general'
import Button from '../components/CustomButton/CustomButton'
import TenantEntityContainer from '../containers/entities/TenantEntityContainer'
import BankAccountFormViewContainer from '../containers/views/BankAccountFormViewContainer'

const BankSelectViewWrapper = (props) => (
  <BankAccountFormViewContainer.Provider>
    <BankSelectView {...props} />
  </BankAccountFormViewContainer.Provider>
)

export default withRouter(BankSelectViewWrapper)

const BankSelectView = (props) => {
  const container = combineContainer([
    TenantEntityContainer.useContainer(),
    BankAccountFormViewContainer.useContainer(),
  ])

  const { api, logic, state, setViewState } = container

  const onClick = {
    getBankAccount: async () => {
      try {
        await api.getBankAccounts(state.keyword)
      } catch (error) {
        props.displayNotification({ level: 'error', message: error.message })
      }
    },
    getBankBranch: async () => {
      try {
        await api.getBankBranch(state.branchKeyword, state.bankCode)
      } catch (error) {
        props.displayNotification({ level: 'error', message: error.message })
      }
    },
  }

  const goTo = {
    locationBack: (branch) =>
      props.history.push('/admin/config/bank_account', {
        bank_name: state.bankName,
        bank_code: state.bankCode,
        bank_branch_name: branch.name,
        bank_branch_code: branch.code,
        bank_account_type: state.tenant ? state.tenant.bank_account_type : '',
        bank_account_number: state.tenant
          ? state.tenant.bank_account_number
          : '',
        bank_account_holder_name: state.tenant
          ? state.tenant.bank_account_holder_name
          : '',
      }),
    back: () => props.history.goBack(),
  }

  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <Col md={10}>
            <Card
              title='銀行選択'
              content={
                <form>
                  {state.viewMode ? (
                    <b style={{ fontSize: 18 }}>{state.bankName}</b>
                  ) : (
                    <>
                      <Row>
                        <Col xs={10} md={6}>
                          <FormGroup>
                            <Form style={{ display: 'flex' }}>
                              <Col xs={8} style={{ padding: 0 }}>
                                <FormControl
                                  type='text'
                                  name='keyword'
                                  placeholder='キーワードを入力'
                                  value={state.keyword}
                                  onChange={({ target }) => {
                                    logic.handleFormChange(
                                      target.name,
                                      target.value,
                                    )
                                  }}
                                />
                              </Col>
                              <Col
                                xs={4}
                                style={{ padding: 0, marginLeft: 10 }}>
                                <Button
                                  type='button'
                                  onClick={onClick.getBankAccount}>
                                  <b>検索</b>
                                </Button>
                              </Col>
                            </Form>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          md={12}
                          style={{ display: 'flex', flexWrap: 'wrap' }}>
                          {state.bankAccount.map((bankInfo) => {
                            return (
                              <div
                                key={bankInfo.code}
                                style={{
                                  cursor: 'pointer',
                                  verticalAlign: 'middle',
                                  border: 'solid 1px #ccc',
                                  margin: '0 10px 10px 0',
                                  borderRadius: 3,
                                  padding: '6px 10px',
                                  backgroundColor: '#fff',
                                  textAlign: 'center',
                                  fontSize: 14,
                                  color: '#0076d7',
                                }}
                                onClick={() => {
                                  setViewState.viewMode(true)
                                  setViewState.bankCode(bankInfo.code)
                                  setViewState.bankName(bankInfo.name)
                                  setViewState.doSelectBranch(true)
                                }}>
                                {bankInfo.name}
                              </div>
                            )
                          })}
                        </Col>
                      </Row>
                    </>
                  )}
                </form>
              }
            />
            {state.doSelectBranch ? (
              <Card
                title='支店選択'
                content={
                  <form>
                    <Row>
                      <Col xs={10} md={6}>
                        <FormGroup>
                          <Form style={{ display: 'flex' }}>
                            <Col xs={8} style={{ padding: 0 }}>
                              <FormControl
                                type='text'
                                name='branchKeyword'
                                placeholder='キーワードを入力'
                                value={state.branchKeyword}
                                onChange={({ target }) => {
                                  logic.handleFormChange(
                                    target.name,
                                    target.value,
                                  )
                                }}
                              />
                            </Col>
                            <Col xs={4} style={{ padding: 0, marginLeft: 10 }}>
                              <Button
                                type='button'
                                onClick={onClick.getBankBranch}>
                                <b>検索</b>
                              </Button>
                            </Col>
                          </Form>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        md={12}
                        style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {state.bankBranch.map((branchInfo) => (
                          <div
                            key={branchInfo.code}
                            style={{
                              cursor: 'pointer',
                              verticalAlign: 'middle',
                              border: 'solid 1px #ccc',
                              margin: '0 10px 10px 0',
                              borderRadius: 3,
                              padding: '6px 10px',
                              backgroundColor: '#fff',
                              textAlign: 'center',
                              fontSize: 14,
                              color: '#0076d7',
                            }}
                            onClick={() => {
                              goTo.locationBack(branchInfo)
                            }}>
                            {branchInfo.name}
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </form>
                }
              />
            ) : null}
            <Button fill onClick={goTo.back}>
              <b>戻る</b>
            </Button>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}
