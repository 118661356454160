import React from 'react'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import { useHistory, useLocation } from 'react-router-dom'

type Props = {
  label: string
  path: string
}

const MenuItem = ({ label, path }: Props): JSX.Element => {
  const history = useHistory()
  const { pathname } = useLocation()
  const active = pathname.indexOf(path) > -1

  return (
    <StyledButton fullWidth active={active} onClick={() => history.push(path)}>
      {label}
    </StyledButton>
  )
}

const StyledButton = styled(Button, {
  shouldForwardProp: (name: string) => name !== 'active',
})<{ active: boolean }>(({ active }) => ({
  ...(active
    ? {
        color: '#fff',
        fontWeight: 700,
        backgroundColor: '#2F3746',
      }
    : {
        color: '#D4D9E1',
        fontWeight: 400,
        backgroundColor: 'transparent',
      }),
  padding: '8px 20px 8px 52px',
  fontSize: 14,
  justifyContent: 'flex-start',
  '&:hover': {
    backgroundColor: '#262F3E',
  },
}))

export default MenuItem
