import React from 'react'

import { useQuery } from '../../lib/hooks'
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from '../../types/common.d'
import RankingContentForm from './parts/RankingContentForm'

type Props = {
  displayErrorNotification: DisplayErrorNotification
  displaySuccessNotification: DisplaySuccessNotification
}

const NewRankingContentFormView = ({
  displayErrorNotification,
  displaySuccessNotification,
}: Props): JSX.Element => {
  const query = useQuery()
  const periodic = query.get('periodic')
  return (
    <RankingContentForm
      target='rankingContent'
      periodic={periodic === 'true'}
      displayErrorNotification={displayErrorNotification}
      displaySuccessNotification={displaySuccessNotification}
      rankingStarted={false}
    />
  )
}

export default NewRankingContentFormView
