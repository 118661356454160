import React from 'react'
import { open } from '../../../lib/general'
import { useGetApiCall } from '../../../lib/hooks'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Book from '../../../assets/img/book.png'
import Hint from '../../../assets/img/hint.png'
import Flag from '../../../assets/img/flag.png'
import Doc from '../../../assets/img/doc.png'
import Dashboard from '../../../assets/img/dashboard.png'

type MemoItemType = {
  id: string
  title?: string
  body?: string
  url?: string
  category?: 'ダッシュボード' | '資料' | '目標' | 'テキスト'
}

type Props = {
  minWidth?: number
  pinned?: boolean
}

const MemoList = ({ minWidth, pinned = false }: Props): JSX.Element | null => {
  const { response } = useGetApiCall<MemoItemType[]>(`/memos?pinned=${pinned}`)
  const memos = response || []

  return memos.length === 0 ? null : (
    <Box display='grid' gap={2} minWidth={minWidth}>
      {memos.map((memo) => (
        <StyledItemContainer
          key={memo.id}
          style={{ cursor: memo.url ? 'pointer' : 'default' }}
          onClick={() => {
            if (memo.url) open(memo.url)
          }}>
          <StyledIcon
            src={memo.category ? CategoryIcons[memo.category] : Book}
            alt={memo.title}
          />

          <Box flexGrow={1}>
            <StyledTitle>{memo.title}</StyledTitle>
            {Boolean(memo.body) &&
              memo
                .body!.split('\n')
                .map((line) => <StyledBody key={line}>{line}</StyledBody>)}
          </Box>

          {Boolean(memo.url) && <StyledButtonLike>確認する</StyledButtonLike>}
        </StyledItemContainer>
      ))}
    </Box>
  )
}

const CategoryIcons = {
  ダッシュボード: Dashboard,
  資料: Doc,
  目標: Flag,
  テキスト: Hint,
} as const

const StyledItemContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  borderRadius: 8,
  padding: theme.spacing(2),
  backgroundColor: '#fff',
  gap: theme.spacing(1),
}))

const ICON_SIZE = 25
const StyledIcon = styled('img')({
  width: ICON_SIZE,
  height: ICON_SIZE,
  objectFit: 'contain',
})

const StyledTitle = styled('div')({
  fontWeight: 700,
  fontSize: 16,
  margin: 0,
  padding: 0,
})

const StyledBody = styled('p')({
  color: '#282828',
  fontSize: 14,
  margin: 0,
  padding: 0,
  marginTop: 4,
})

const StyledButtonLike = styled('div')(({ theme }) => ({
  backgroundColor: 'rgba(36, 77, 138, 0.1)',
  color: '#282828',
  borderRadius: 32,
  fontSize: 16,
  fontWeight: 'bold',
  padding: theme.spacing(1, 2),
  textAlign: 'center',
  alignSelf: 'center',
  whiteSpace: 'nowrap',
}))

export default MemoList
