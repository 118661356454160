import React, { CSSProperties } from 'react'
import { FormControl, Col, HelpBlock } from 'react-bootstrap'
import FormLabel from './FormLabel'
import FormGroup from './FormGroup'
import ChangeIndicator from '../ChangeIndicator'

type Props = {
  options: any[]
  label?: string
  value?: string | number
  onSelect?: (s: any) => void
  name?: string
  tooltipText?: string
  labelAttachment?: string
  width?: number | string
  height?: number | string
  disabled?: boolean
  style?: CSSProperties
  inputRef?: any
  validationMessage?: string
  selectStyle?: CSSProperties
  showChangeIndicator?: boolean
}

const SelectorForm = ({
  options,
  label,
  value,
  onSelect,
  name,
  tooltipText,
  labelAttachment,
  width = 300,
  height,
  disabled = false,
  style,
  inputRef = null,
  validationMessage,
  selectStyle = {},
  showChangeIndicator = false,
  ...otherProps
}: Props): JSX.Element => (
  <FormGroup style={style} {...otherProps}>
    <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
      {!!label && (
        <FormLabel
          label={label}
          tooltipText={tooltipText}
          attachment={labelAttachment}
        />
      )}

      {showChangeIndicator && <ChangeIndicator />}
    </div>

    <Col style={{ display: 'flex', alignItems: 'center' }}>
      <FormControl
        name={name}
        componentClass='select'
        style={{
          width,
          height,
          ...(showChangeIndicator ? { borderColor: '#1dc7ea' } : {}),
        }}
        disabled={disabled}
        value={value}
        onChange={onSelect}
        inputRef={inputRef}>
        {options.map((option) => (
          <option key={`${name}-option-${option.value}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </FormControl>
    </Col>
    {!!validationMessage && (
      <HelpBlock className='text-danger'>{validationMessage}</HelpBlock>
    )}
  </FormGroup>
)

export default SelectorForm
