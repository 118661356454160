import React, { useEffect, useState, useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Pagination from '@mui/material/Pagination'
import Divider from '@mui/material/Divider'
import CircularProgress from '@mui/material/CircularProgress'
import {
  useApi,
  useBooleanState,
  useQuery,
  useMobileFlag,
} from '../../../lib/hooks'
import { UserGender } from '../../../types/user'
import { Genders } from '../../../constants/user'
import {
  QuestionnaireAnswersResponse,
  QuestionnaireAnswerBlock,
  QuestionnaireSectionResponse,
} from '../../../types/questionnaire'
import DisablingMask from '../../../components/DisablingMask'
import { RestaurantSatisfactionAnswerQueryParams } from '../type'
import RestaurantSatisfactionAnswerAverage from './RestaurantSatisfactionAnswerAverage'
import RestaurantSatisfactionAnswerItem from './RestaurantSatisfactionAnswerItem'
import RestaurantSatisfactionFilterConditionModal from './RestaurantSatisfactionFilterConditionModal'
import RestaurantSatisfactionAnswerModal from './RestaurantSatisfactionAnswerModal'
import RestaurantSatisfactionFilterCondition, {
  isNoCondition,
} from './RestaurantSatisfactionFilterCondition'

type Props = {
  sections: QuestionnaireSectionResponse[]
  questionnaireId: number
}

const RestaurantSatisfactionAnswers = ({
  sections,
  questionnaireId,
}: Props): JSX.Element => {
  const isMobile = useMobileFlag()
  const query = useQuery()
  const [isFilterModalOpen, openFilterModal, closeFilterModal] =
    useBooleanState(false)
  const userId = Number(query.get('user_id'))
  const numberOfTimes = Number(query.get('number_of_times'))

  const {
    changeUrlAndFetchQuestionnaireAnswers,
    answers,
    loading,
    loaded,
    totalPages,
    queryParams,
  } = useQuestionnaireAnswersApi(questionnaireId, sections)

  return !loaded ? (
    <StyledLoadingPaper>
      <CircularProgress />
    </StyledLoadingPaper>
  ) : (
    <>
      <Paper
        square={isMobile}
        variant={isMobile ? 'elevation' : 'outlined'}
        sx={{ m: isMobile ? 0 : 1 }}>
        <StyledConditionContainer
          breaks={isMobile && !isNoCondition(queryParams)}>
          <RestaurantSatisfactionFilterCondition
            sections={sections}
            queryParams={queryParams}
          />

          <Button
            variant='outlined'
            color='cancel'
            disabled={loading}
            onClick={openFilterModal}>
            絞り込み設定
          </Button>
        </StyledConditionContainer>

        <RestaurantSatisfactionFilterConditionModal
          sections={sections}
          open={isFilterModalOpen}
          defaultCondition={{
            totalQualityIds: queryParams.totalQualityIds,
            dateFrom: queryParams.dateFrom,
            dateTo: queryParams.dateTo,
            answerStoreIds: queryParams.answerStoreIds,
            orderItemIds: queryParams.orderItemIds,
            rankId: queryParams.rankId,
            gender: queryParams.gender,
            ageFrom: queryParams.ageFrom,
            ageTo: queryParams.ageTo,
          }}
          onClose={closeFilterModal}
          onSubmit={(conditions) => {
            changeUrlAndFetchQuestionnaireAnswers({
              ...queryParams,
              ...conditions,
            })
          }}
        />

        <Divider />

        <Box p={2}>
          <RestaurantSatisfactionAnswerAverage
            questionnaireId={questionnaireId}
            queryParams={queryParams}
          />
        </Box>

        <Divider />

        {answers.length === 0 ? (
          <StyledAnswerEmpty>
            <StyledAnswerEmptyIconContainer>
              <i className='ri-star-line' />
            </StyledAnswerEmptyIconContainer>
            {isNoCondition(queryParams) ? (
              <>
                顧客満足度調査の
                <br />
                回答結果がここに表示されます。
              </>
            ) : (
              <>指定した条件にマッチする回答結果はありません。</>
            )}
          </StyledAnswerEmpty>
        ) : (
          <div>
            <DisablingMask mask={loading}>
              {answers.map(
                (questionnaireAnswer: QuestionnaireAnswerBlock, i) => (
                  <React.Fragment
                    key={`${questionnaireAnswer.user.id}-${questionnaireAnswer.number_of_times}`}>
                    <Box p={2}>
                      <RestaurantSatisfactionAnswerItem
                        answer={questionnaireAnswer}
                        questionnaireId={questionnaireId}
                      />
                    </Box>
                    {answers.length !== i + 1 && <Divider />}
                  </React.Fragment>
                ),
              )}
            </DisablingMask>

            {totalPages > 1 && (
              <>
                <Divider />

                <Pagination
                  count={totalPages}
                  page={queryParams.page}
                  disabled={loading}
                  sx={{ py: 1 }}
                  onChange={(_, page) =>
                    changeUrlAndFetchQuestionnaireAnswers({
                      ...queryParams,
                      page,
                    })
                  }
                />
              </>
            )}
          </div>
        )}
      </Paper>

      {Boolean(userId) && Boolean(numberOfTimes) && (
        <RestaurantSatisfactionAnswerModal
          questionnaireId={questionnaireId}
          userId={userId}
          numberOfTimes={numberOfTimes}
        />
      )}
    </>
  )
}

const useQuestionnaireAnswersApi = (
  questionnaireId: number,
  sections: QuestionnaireSectionResponse[],
) => {
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()
  const gender = query.get('gender') as UserGender
  const { api, response, loading, loaded, totalPages } =
    useApi<QuestionnaireAnswersResponse>()
  const [queryParams, setQueryParams] =
    useState<RestaurantSatisfactionAnswerQueryParams>({
      page: Number(query.get('page') || 1),
      totalQualityIds: query.getAll('total_quality_ids[]').map(Number) || [],
      dateFrom: query.get('date_from') || null,
      dateTo: query.get('date_to') || null,
      answerStoreIds: query.getAll('answer_store_ids[]').map(Number) || [],
      orderItemIds: query.getAll('order_item_ids[]').map(Number) || [],
      rankId: query.has('rank_id') ? Number(query.get('rank_id')) : null,
      gender: Genders.includes(gender) ? gender : null,
      ageFrom: query.has('age_from') ? Number(query.get('age_from')) : null,
      ageTo: query.has('age_to') ? Number(query.get('age_to')) : null,
    })

  const changeUrl = useCallback(
    (params: RestaurantSatisfactionAnswerQueryParams) => {
      setQueryParams(params)

      const searchParams = new URLSearchParams()
      searchParams.append('tab', 'answers')
      searchParams.append('page', String(params.page))
      if (params.dateFrom) searchParams.append('date_from', params.dateFrom)
      if (params.dateTo) searchParams.append('date_to', params.dateTo)
      if (params.rankId) searchParams.append('rank_id', String(params.rankId))
      if (params.gender) searchParams.append('gender', params.gender)
      if (params.ageFrom !== null)
        searchParams.append('age_from', String(params.ageFrom))
      if (params.ageTo !== null)
        searchParams.append('age_to', String(params.ageTo))

      const answerStoreIds =
        params.answerStoreIds.length > 0
          ? '&' +
            params.answerStoreIds
              .map((id) => `answer_store_ids[]=${id}`)
              .join('&')
          : ''
      const totalQualityIds =
        params.totalQualityIds.length > 0
          ? '&' +
            params.totalQualityIds
              .map((id) => `total_quality_ids[]=${id}`)
              .join('&')
          : ''
      const orderItemIds =
        params.orderItemIds.length > 0
          ? '&' +
            params.orderItemIds.map((id) => `order_item_ids[]=${id}`).join('&')
          : ''

      const newUrl =
        `${location.pathname}?${searchParams.toString()}` +
        `${totalQualityIds}${answerStoreIds}${orderItemIds}`
      history.replace(newUrl)
    },
    [history, location.pathname],
  )

  useEffect(() => {
    const sectionsCondition: { item_ids: number[] }[] = []
    if (queryParams.totalQualityIds.length > 0) {
      sectionsCondition.push({ item_ids: queryParams.totalQualityIds })
    }
    if (queryParams.orderItemIds.length > 0) {
      sectionsCondition.push({ item_ids: queryParams.orderItemIds })
    }

    const params = {
      page: queryParams.page,
      answer_store_ids: queryParams.answerStoreIds,
      date_from: queryParams.dateFrom,
      date_to: queryParams.dateTo,
      rank_id: queryParams.rankId,
      gender: queryParams.gender,
      age_from: queryParams.ageFrom,
      age_to: queryParams.ageTo,
      sections: sectionsCondition,
      limit: 10,
    }

    api.post(`/questionnaires/${questionnaireId}/answers`, params)
  }, [api, questionnaireId, queryParams, sections])

  return {
    changeUrlAndFetchQuestionnaireAnswers: changeUrl,
    answers: response?.questionnaire_answers || [],
    loading,
    loaded,
    totalPages,
    queryParams,
  }
}

const StyledLoadingPaper = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(10),
  textAlign: 'center',
}))

const StyledAnswerEmpty = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
  fontSize: 16,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  gap: theme.spacing(2),
}))
const StyledAnswerEmptyIconContainer = styled('div')({
  backgroundColor: '#F3F5F9',
  borderRadius: 40,
  width: 40,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#555',
  fontSize: 20,
})

const StyledConditionContainer = styled('div', {
  shouldForwardProp: (props) => props !== 'breaks',
})<{ breaks: boolean }>(({ breaks, theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: breaks ? 'column' : 'row',
  justifyContent: 'space-between',
  alignItems: breaks ? 'flex-end' : 'center',
  gap: theme.spacing(1),
}))

export default RestaurantSatisfactionAnswers
