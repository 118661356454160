export const StoreBenchmarkTypes = ['monthly', 'temporary']
export const StoreBenchmarkTypeLabels = {
  monthly: '月次目標',
  temporary: 'キャンペーン・施策目標',
} as const

export const StoreBenchmarkIndicatorLabels = {
  connected_unique_user_count: '新規ユーザー数',
  revisit_count: '再来店数',
} as const
export const StoreBenchmarkIndicators = Object.keys(
  StoreBenchmarkIndicatorLabels,
)
