import React, { FC, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import Button from '../../../components/CustomButton/CustomButton'
import { MembersCardStatusType } from '../../../containers/entities/MembersCardContentEntityContainer'
import { useBooleanState } from '../../../lib/hooks'
import {
  Alert,
  FormChangedAlertModal,
} from '../../../components/Modal/FormChangedAlertModal'
import { Card } from '../../../components/Card/Card'

type Props = {
  status: MembersCardStatusType
  disabled: boolean
  onChangeStatus: (status: MembersCardStatusType) => void
}

const modalTitle = (status: MembersCardStatusType) => {
  switch (status) {
    case 'close':
      return '会員カードの公開を停止'
    case 'open':
      return '会員カードを公開'
    default:
      return ''
  }
}

const modalSubmitButtonLabel = (status: MembersCardStatusType) => {
  switch (status) {
    case 'close':
      return '公開を停止する'
    case 'open':
      return '公開する'
    default:
      return ''
  }
}

const toStatus = (
  currentStatus: MembersCardStatusType,
): MembersCardStatusType => {
  if (currentStatus === 'draft') return 'open'
  return currentStatus === 'open' ? 'close' : 'open'
}

const modalBody = (status: MembersCardStatusType) => {
  switch (status) {
    case 'close':
      return '会員カードの公開を停止してよろしいですか？'
    case 'open':
      return '会員カードを公開してよろしいですか？'
    default:
      return ''
  }
}

const MembersCardPublishSettingCard: FC<Props> = ({
  status,
  disabled,
  onChangeStatus,
}) => {
  const [isModalOpen, openModal, closeModal] = useBooleanState(false)
  const [newStatus, setNewStatus] = useState<MembersCardStatusType>('close')

  const handleSubmit = () => {
    onChangeStatus(newStatus)
    closeModal()
  }

  return (
    <>
      <Row>
        <Col xs={12} lg={10}>
          <Card
            title='公開設定'
            content={
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  bsStyle={status === 'open' ? 'danger' : 'info'}
                  fill={status === 'close'}
                  disabled={disabled}
                  onClick={() => {
                    setNewStatus(toStatus(status))
                    openModal()
                  }}>
                  <b>
                    {status === 'open'
                      ? 'すべての会員カードの公開を停止'
                      : 'すべての会員カードを公開'}
                  </b>
                </Button>
              </div>
            }
          />
        </Col>
      </Row>

      <FormChangedAlertModal
        show={isModalOpen}
        title={modalTitle(newStatus)}
        onSubmit={handleSubmit}
        onCancel={closeModal}
        disabled={disabled}
        submitButtonLabel={modalSubmitButtonLabel(newStatus)}
        needAgreement={newStatus === 'close' || status === 'draft'}
        displayStatus={newStatus === 'close' ? 'danger' : 'info'}>
        {modalBody(newStatus)}

        {newStatus === 'close' && (
          <Alert severity='danger'>
            現在会員カードを利用しているユーザーが
            <strong>会員カードを利用できない状態</strong>になります
          </Alert>
        )}

        {status === 'draft' && (
          <Alert severity='warning'>
            公開すると会員カードが全ユーザーに公開され、「会員コード接頭辞」「電話番号を会員コードに設定する」の変更ができなくなります。
          </Alert>
        )}
      </FormChangedAlertModal>
    </>
  )
}

export default MembersCardPublishSettingCard
