import React, { useState } from 'react'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { ButtonToolbar, DropdownButton, Row, Col } from 'react-bootstrap'
import { ChartBar } from 'phosphor-react'
import { useMobileFlag } from '../../lib/hooks'
import { UserActionSummaryType } from '../../types/common.d'
import { SummariesResponse } from '../../types/api/summary.d'
import { Card } from '../Card/Card'
import LineGraph from '../Chart/LineGraph'
import PieChart from '../Chart/PieChart'
import DataFilteringSidebar from '../Sidebar/DataFilteringSidebar'
import AnalyticsCardTooltip from '../../views/Analytics/parts/AnalyticsCardTooltip'

type Props = {
  contentName: string
  summaries: SummariesResponse
  defaultSummaryType: UserActionSummaryType
  onChangeListItem: (item: UserActionSummaryType) => void
}

const actionTypeToJp = {
  delivered: '配信',
  read: '開封',
} as const

const VISIT_COUNT_DATA = [
  { threshold: 0, color: '#72C9E0', label: '0回' },
  { threshold: 1, color: '#26ABA5', label: '1回' },
  { threshold: 2, color: '#AFB8F0', label: '2回' },
  { threshold: 3, color: '#787CF7', label: '3回' },
  { threshold: 4, color: '#60DEAA', label: '4回' },
  { threshold: 5, color: '#A2E9E0', label: '5回' },
  { threshold: 6, color: '#65789B', label: '6 ~ 10回' },
  { threshold: 11, color: '#85789B', label: '11 ~ 50回' },
  { threshold: 51, color: '#42E9E0', label: '51 ~ 100回' },
  { threshold: 101, color: '#22E922', label: '101回以上' },
] as const

const VISIT_FREQUENCY_DATA = [
  { threshold: -1, color: '#A2E9E0', label: '再来店なし' },
  { threshold: 0, color: '#72C9E0', label: '7日以内' },
  { threshold: 7, color: '#26ABA5', label: '14日以内' },
  { threshold: 14, color: '#AFB8F0', label: '30日以内' },
  { threshold: 30, color: '#787CF7', label: '90日以内' },
  { threshold: 91, color: '#60DEAA', label: '91日以降' },
] as const

const SEX_DATA = [
  { value: 'male', color: '#72C9E0', label: '男性' },
  { value: 'female', color: '#787CF7', label: '女性' },
  { value: 'other', color: '#65789B', label: 'その他' },
  { value: null, color: '#60DEAA', label: '未設定' },
] as const

const AGE_DATA = [
  { threshold: -1, color: '#65789B', label: '未設定' },
  { threshold: 0, color: '#72C9E0', label: '9歳以下' },
  { threshold: 10, color: '#26ABA5', label: '10代' },
  { threshold: 20, color: '#AFB8F0', label: '20代' },
  { threshold: 30, color: '#787CF7', label: '30代' },
  { threshold: 40, color: '#5B8FF9', label: '40代' },
  { threshold: 50, color: '#60DEAA', label: '50代' },
  { threshold: 60, color: '#A2E9E0', label: '60代以上' },
]

const HOURS_OF_DAY = [...Array(24)].map((_, i) => i)
const READ_COUNT_GRAPH_LABEL = '開封数'
const READ_COUNT_GRAPH_COLOR = '#54ADFF'

const SummariesSection = ({
  contentName,
  summaries,
  defaultSummaryType,
  onChangeListItem,
}: Props): JSX.Element => {
  const isMobile = useMobileFlag()
  const [selectedItem, setSelectedItem] =
    useState<UserActionSummaryType>(defaultSummaryType)

  const readCountByHoursData = [
    {
      id: READ_COUNT_GRAPH_LABEL,
      color: READ_COUNT_GRAPH_COLOR,
      data: HOURS_OF_DAY.map((hour) => ({
        x: `${hour}:00`,
        y:
          summaries.read_count_by_hours.find((v) => v.hour === hour)
            ?.read_count || 0,
      })),
    },
  ]

  const visitCountData = VISIT_COUNT_DATA.map((data, i) => ({
    id: data.label,
    label: data.label,
    color: data.color,
    value: summaries.user_count_by_visit_count
      .filter((item) => {
        const nextThreshold =
          VISIT_COUNT_DATA[i + 1]?.threshold || Number.MAX_SAFE_INTEGER
        return (
          data.threshold <= item.visit_count && item.visit_count < nextThreshold
        )
      })
      .reduce((sum, item) => sum + item.user_count, 0),
  }))

  const visitFrequencyData = VISIT_FREQUENCY_DATA.map((data, i) => ({
    id: data.label,
    label: data.label,
    color: data.color,
    value: summaries.user_count_by_visit_frequency
      .filter((item) => {
        const nextThreshold =
          VISIT_FREQUENCY_DATA[i + 1]?.threshold ?? Number.MAX_SAFE_INTEGER

        const visitFrequency = Number(item.visit_frequency)

        return (
          data.threshold < visitFrequency && visitFrequency <= nextThreshold
        )
      })
      .reduce((sum, item) => sum + item.user_count, 0),
  }))

  const userCountBySexData = SEX_DATA.map((data) => ({
    id: data.label,
    label: data.label,
    color: data.color,
    value: summaries.user_count_by_sex
      .filter((item) => data.value === item.sex)
      .reduce((sum, item) => sum + item.user_count, 0),
  }))

  const userCountByAgeData = AGE_DATA.map((data, i) => ({
    id: data.label,
    label: data.label,
    color: data.color,
    value: summaries.user_count_by_age
      .filter((item) => {
        const nextThreshold =
          AGE_DATA[i + 1]?.threshold ?? Number.MAX_SAFE_INTEGER

        const age = item.age ?? -1

        return data.threshold <= age && age < nextThreshold
      })
      .reduce((sum, item) => sum + item.user_count, 0),
  }))

  return (
    <StyledContainer>
      {isMobile ? (
        <ButtonToolbar style={{ marginBottom: 16 }}>
          <StyledDropdownButton
            title={actionTypeToJp[selectedItem]}
            id='dropdown-action-type-list'>
            <DataFilteringSidebar
              list={[
                {
                  label: '配信',
                  value: 'delivered',
                },
                {
                  label: '開封',
                  value: 'read',
                },
              ]}
              defaultSelectedValue={defaultSummaryType}
              onClick={(item: UserActionSummaryType) => {
                setSelectedItem(item)
                onChangeListItem(item)
              }}
            />
          </StyledDropdownButton>
        </ButtonToolbar>
      ) : (
        <DataFilteringSidebar
          list={[
            {
              label: '配信',
              value: 'delivered',
            },
            {
              label: '開封',
              value: 'read',
            },
          ]}
          defaultSelectedValue={defaultSummaryType}
          onClick={(item: UserActionSummaryType) => {
            setSelectedItem(item)
            onChangeListItem(item)
          }}
          style={{
            backgroundColor: 'white',
            padding: '20px 12px',
            minHeight: 400,
          }}
        />
      )}

      <div>
        {/* read(開封)が選択された時、選択されたデータのカウントが0だった場合のUI表示 */}
        {selectedItem === 'read' && summaries.read_count === 0 ? (
          <NoItemContent
            message={
              selectedItem === 'read'
                ? `${contentName}が開封されるとユーザーのデータがここに表示されます`
                : `${contentName}を見て来店があるとユーザーのデータがここに表示されます`
            }
          />
        ) : (
          <>
            <Row>
              <Col lg={12} sm={12}>
                <Card
                  title='時間帯別開封数'
                  titleFontSize={20}
                  titleRightContent={
                    <AnalyticsCardTooltip
                      description={`時間帯別の${contentName}を開いた数です。`}
                    />
                  }
                  content={
                    <LineGraph
                      data={readCountByHoursData}
                      height={290}
                      enableGridY
                      unit='人'
                      axisLeft={{
                        tickSize: 0,
                        tickPadding: 10,
                        format: (value): string =>
                          Number.isInteger(value) ? value.toLocaleString() : '',
                      }}
                      axisBottom={{
                        tickSize: 0,
                        tickPadding: 10,
                        format: (value: string): string =>
                          Number(value.substring(0, value.indexOf(':'))) % 2 ===
                          0
                            ? value
                            : '',
                      }}
                    />
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col lg={6} sm={6}>
                <Card
                  title='来店回数'
                  titleFontSize={20}
                  titleRightContent={
                    <AnalyticsCardTooltip
                      description={`${contentName}を開いた時点でのユーザーがアプリを利用した回数です。`}
                    />
                  }
                  content={
                    <Box height={280}>
                      <PieChart
                        data={visitCountData}
                        margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
                        height='80%'
                        enableLabel
                        unit='人'
                        innerRadius={0.75}
                        centerLabel='平均'
                        centerUnit='回'
                        centerValue={
                          Math.round(summaries.user_visit_count_average * 10) /
                          10
                        }
                        enableCenterLabel
                      />
                    </Box>
                  }
                />
              </Col>

              <Col lg={6} sm={6}>
                <Card
                  title='来店間隔'
                  titleFontSize={20}
                  titleRightContent={
                    <AnalyticsCardTooltip
                      description={`${contentName}を開いた時点でのユーザーの来店頻度です。`}
                    />
                  }
                  content={
                    <Box height={280}>
                      <PieChart
                        data={visitFrequencyData}
                        margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
                        height='80%'
                        enableLabel
                        unit='人'
                        innerRadius={0.75}
                        centerLabel='平均'
                        centerUnit='日'
                        centerValue={
                          Math.round(
                            summaries.user_visit_frequency_average * 10,
                          ) / 10
                        }
                        enableCenterLabel
                      />
                    </Box>
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6} sm={6}>
                <Card
                  title='性別'
                  titleFontSize={20}
                  content={
                    <Box height={280}>
                      <PieChart
                        data={userCountBySexData}
                        margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
                        height='80%'
                        enableLabel
                        unit='人'
                        enableCenterLabel
                      />
                    </Box>
                  }
                />
              </Col>

              <Col lg={6} sm={6}>
                <Card
                  title='年齢層'
                  titleFontSize={20}
                  content={
                    <Box height={280}>
                      <PieChart
                        data={userCountByAgeData}
                        margin={{ top: 30, right: 30, bottom: 30, left: 30 }}
                        height='80%'
                        enableLabel
                        unit='人'
                        innerRadius={0.75}
                        centerLabel='平均'
                        centerUnit='歳'
                        centerValue={
                          Math.round(summaries.user_age_average * 10) / 10
                        }
                        enableCenterLabel
                      />
                    </Box>
                  }
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    </StyledContainer>
  )
}

const NoItemContent = ({ message }: { message: string }): JSX.Element => (
  <Row>
    <Col lg={12} sm={12}>
      <Card
        headerStyle={{ display: 'none' }}
        content={
          <StyledNoDataContainer>
            <ChartBar size={24} color='#757575' />
            <div style={{ color: '#757575' }}>{message}</div>
          </StyledNoDataContainer>
        }
      />
    </Col>
  </Row>
)

const StyledDropdownButton = styled(DropdownButton)({
  fontWeight: 700,
  color: '#000',
  fontSize: 24,
  borderWidth: 0,
  padding: 0,
})

const StyledContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 2),
  display: 'grid',
  gridTemplateColumns: '220px auto',
  gap: 40,
  alignItems: 'flex-start',
  [theme.breakpoints.down('laptop')]: {
    gridTemplateColumns: '1fr',
    gap: 0,
    alignItems: 'normal',
  },
}))

const StyledNoDataContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: theme.spacing(1),
  height: 400,
}))

export default SummariesSection
