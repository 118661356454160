import React from 'react'
import { CircularProgress } from '@mui/material'

import { useQuery, useGetApiCall } from '../../lib/hooks'
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from '../../types/common.d'
import RankingContentForm from './parts/RankingContentForm'
import { RankingContentResource } from '../../types/rankingContent'

type Props = {
  displayErrorNotification: DisplayErrorNotification
  displaySuccessNotification: DisplaySuccessNotification
}

const EditRankingContentFormView = ({
  displayErrorNotification,
  displaySuccessNotification,
}: Props): JSX.Element => {
  const query = useQuery()
  const rankingContentId = query.get('id')

  const url = `/ranking_contents/${rankingContentId}`
  const { response: rankingContent } =
    useGetApiCall<RankingContentResource>(url)

  const periodic = rankingContent?.is_periodic_ranking

  if (!rankingContent) {
    return <CircularProgress />
  }

  return (
    <RankingContentForm
      target='rankingContent'
      defaultRankingContent={rankingContent}
      periodic={!!periodic}
      displayErrorNotification={displayErrorNotification}
      displaySuccessNotification={displaySuccessNotification}
      rankingStarted={false}
    />
  )
}

export default EditRankingContentFormView
