import { createContainer } from 'unstated-next'
import { autoCouponContentApi } from '../../lib/api'

export type AutoCouponContentType = {
  id: number
  status: AutoCouponStatusType
  age_from: number
  age_to: number
  benefit: string
  birth_month
  body: string
  created_at: string
  do_push: boolean
  expiration_day: number
  expiration_time: number
  gender: 'male' | 'female' | 'other' | null
  has_usage_limit: boolean
  image_url: string
  category: AutoCouponCategory
  days_ago: number
  days_later: number
  publish_hour: number
  publish_min: number
  publish_time: string
  publish_dates: number[]
  publish_weeks: number[]
  rank_ids: number[]
  terms: string
  important_notes: string
  user_ids: number[]
  enabled_auto_publish_condition: boolean
  enabled_target_user_condition: boolean
  target_user_condition?: AutoCouponContentTargetUserCondition | null
  memo: string
}

type AutoCouponContentTargetUserCondition = {
  age_from: number | null
  age_to: number | null
  birth_month: number | null
  coupon_content_id: number | null
  created_at: string
  gender: 'male' | 'female' | 'other' | null
  id: number
  notice_content_id: number | null
  rank: number | null
  rank_ids: number[]
  updated_at: string
  user_group_collection_id: number
  user_group_ids: number[]
  user_ids: number[]
  users_count: number
}

export const CouponStatus = {
  Unpublished: 'unpublished',
  Publish: 'publish',
  Reservation: 'reservation',
  Preview: 'preview',
} as const
export type AutoCouponStatusType =
  (typeof CouponStatus)[keyof typeof CouponStatus]

export const category = {
  Register: 'register',
  NoCheckIn: 'no_check_in',
  Repeat: 'repeat',
  Birthday: 'birthday',
} as const

export type AutoCouponCategory = (typeof category)[keyof typeof category]

export type AutoCouponCategoryToJp =
  | '初回登録'
  | '再来店'
  | '定期配信'
  | '誕生日'

const categories = Object.values(category)

export const categoryToJpObj = {
  [category.Register]: '初回登録',
  [category.NoCheckIn]: '再来店',
  [category.Repeat]: '定期配信',
  [category.Birthday]: '誕生日',
} as const

const useAutoCouponContentEntityHook = () => {
  return {
    constant: { categories },
    logic: {
      getAutoCouponContent: async (
        id: number,
      ): Promise<AutoCouponContentType> => {
        const autoCouponContent =
          await autoCouponContentApi.getAutoCouponContent(id)
        return autoCouponContent
      },
      createAutoCouponContent: async (
        params,
      ): Promise<AutoCouponContentType> => {
        const acc = await autoCouponContentApi.createAutoCouponContent({
          params: params,
        })
        return acc
      },
      updateAutoCouponContent: async ({
        id,
        params,
      }): Promise<AutoCouponContentType> => {
        const acc = await autoCouponContentApi.updateAutoCouponContent({
          auto_coupon_content_id: id,
          params: params,
        })
        return acc
      },
      categoryToJp: (
        autoCouponCategory: AutoCouponCategory,
      ): AutoCouponCategoryToJp => categoryToJpObj[autoCouponCategory],
    },
  }
}

export default createContainer(useAutoCouponContentEntityHook)
