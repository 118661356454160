import { AxiosResponse } from 'axios'
import apiClient from './apiClient'
import { loadViewObj } from '../viewObj'
import { ServiceContentInterface } from '../../containers/entities/ServiceContentEntityContainer'

export const getServiceContents = async ({
  rankId,
}): Promise<ServiceContentInterface[]> => {
  const GET_SERVICE_CONTENTS = `/staff/stores/${
    loadViewObj()?.id
  }/members_card_contents/members_card_ranks/${rankId}/service_contents`

  const response: AxiosResponse<ServiceContentInterface[]> = await apiClient({
    method: 'GET',
    uri: GET_SERVICE_CONTENTS,
  })
  return response.data
}

export const getAllServiceContents = async (): Promise<
  ServiceContentInterface[]
> => {
  const GET_ALL_SERVICE_CONTENTS = `/staff/stores/${
    loadViewObj()?.id
  }/service_contents/`

  const response: AxiosResponse<ServiceContentInterface[]> = await apiClient({
    method: 'GET',
    uri: GET_ALL_SERVICE_CONTENTS,
  })
  return response.data
}

export const createServiceContent = async ({
  params,
  rankId,
}): Promise<ServiceContentInterface> => {
  const CREATE_SERVICE_CONTENT = `/staff/stores/${
    loadViewObj()?.id
  }/members_card_contents/members_card_ranks/${rankId}/service_contents`

  const requestParams = {
    service_content: params,
  }

  const response: AxiosResponse<ServiceContentInterface> = await apiClient({
    method: 'POST',
    uri: CREATE_SERVICE_CONTENT,
    params: requestParams,
  })

  return response.data
}

export const updateServiceContent = async ({
  params,
  id,
}): Promise<ServiceContentInterface> => {
  const UPDATE_SERVICE_CONTENT = `/staff/stores/${
    loadViewObj()?.id
  }/service_contents/${id}`

  const requestParams = {
    service_content: params,
  }

  const response: AxiosResponse<ServiceContentInterface> = await apiClient({
    method: 'PUT',
    uri: UPDATE_SERVICE_CONTENT,
    params: requestParams,
  })

  return response.data
}

export const deleteServiceContent = async (
  id,
): Promise<{ message: string }> => {
  const DELETE_SERVICE_CONTENT = `/staff/stores/${
    loadViewObj()?.id
  }/service_contents/${id}`

  const response = await apiClient({
    method: 'DELETE',
    uri: DELETE_SERVICE_CONTENT,
  })

  return response.data
}
