import React, { memo, FC } from 'react'

type Props = {
  datum
  unit: string
}

const PieChartTooltip: FC<Props> = ({ datum, unit }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#fff',
      borderRadius: 2,
      boxShadow: 'rgb(0 0 0 / 25%) 0px 1px 2px',
      padding: '5px 9px',
    }}>
    <span
      style={{
        display: 'block',
        width: 12,
        height: 12,
        background: datum.data.color,
        marginRight: 7,
      }}
    />
    <span>
      {datum.data.label} :{' '}
      {datum.data.value && datum.data.value.toLocaleString()}
      <span style={{ fontSize: 8 }}>{unit}</span>
    </span>
  </div>
)

export default memo(PieChartTooltip)
