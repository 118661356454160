import React from 'react'
import ValueRangeFormItem from '../parts/ValueRangeFormItem'
import { Condition, ConditionDiff } from '../../type'

type Props = {
  condition: Condition
  onChange: (diff: ConditionDiff) => void
}

const VisitFrequencyCondition = ({
  condition,
  onChange,
}: Props): JSX.Element => {
  return (
    <ValueRangeFormItem
      label='来店頻度'
      unit='日'
      from={condition.value_from}
      to={condition.value_to}
      onChange={(value_from, value_to) => onChange({ value_from, value_to })}
    />
  )
}

export default VisitFrequencyCondition
