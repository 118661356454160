// DONE: refact

import { useCallback, useState } from 'react'
import { createContainer } from 'unstated-next'
import { branchApi } from '../../lib/api'
import EntityContainerInterface from './_EntityContainerInterface'

// interface
export interface BranchInterface {
  id: number
  name: string
  address: string
  phone: string
  active: boolean
  open_times: string[]
  latitude: number
  longitude: number
  store_id: number
}

interface BranchParamsInterface {
  name: string
  address: string
  phone: string
  active: boolean
  open_times: Date
}

const useBranchEntityHook = (): EntityContainerInterface => {
  const [branches, setBranches] = useState<BranchInterface[]>([])

  const updateBranchState = useCallback(
    (newBranch) => {
      const index = branches.findIndex((branch) => branch.id === newBranch.id)
      setBranches([
        ...branches.slice(0, index),
        newBranch,
        ...branches.slice(index + 1),
      ])
    },
    [branches],
  )

  const addBranchState = useCallback(
    (newBranch) => {
      setBranches([newBranch, ...branches])
    },
    [branches],
  )

  const deleteBranchState = useCallback(
    (deleteBranchId) => {
      const index = branches.findIndex((branch) => branch.id === deleteBranchId)
      setBranches([...branches.slice(0, index), ...branches.slice(index + 1)])
    },
    [branches],
  )
  return {
    state: { branches },
    constant: {},
    logic: {
      setBranches: setBranches,
      getBranches: async (): Promise<BranchInterface[]> => {
        const newBranches: BranchInterface[] = await branchApi.getBranches()
        setBranches(newBranches)
        return newBranches
      },
      createBranch: async (
        params: BranchParamsInterface,
      ): Promise<BranchInterface> => {
        const newBranch: BranchInterface = await branchApi.createBranch({
          params,
        })
        addBranchState(newBranch)
        return newBranch
      },
      updateBranch: async (
        id: number,
        params: BranchParamsInterface,
      ): Promise<BranchInterface> => {
        const newBranch: BranchInterface = await branchApi.updateBranch({
          id,
          params,
        })
        updateBranchState(newBranch)
        return newBranch
      },
      deleteBranch: async (id: number): Promise<void> => {
        await branchApi.deleteBranch({ id: id })
        deleteBranchState(id)
      },
    },
  }
}

export default createContainer(useBranchEntityHook)
