import React, { useState } from 'react'
import {
  LogStatusLabel,
  IllegalStatusLabel,
  ContentTypeMappings,
  ActionTypes,
  ActionTypeLabels,
} from '../../../constants/activityLog'
import {
  ActivityLogContentResponse,
  ActivityLogFilteringCondition,
  ActivityLogStatus,
  ContentType,
  IllegalStatus,
  ActionType,
  ActivityLogContent,
} from '../../../types/activityLog'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Flatpickr from 'react-flatpickr'
import { Japanese } from 'flatpickr/dist/l10n/ja'
import { X } from 'phosphor-react'
import { styled } from '@mui/material/styles'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import FormLabel from '../../../components/FormInputs/FormLabel'
import { useMobileFlag } from '../../../lib/hooks'
import DialogTitle from '../../../components/DialogTitle'
import { formatDate } from '../../../lib/general'
import MultipleStoreSelectForm from '../../../components/FormInputs/MultipleStoreSelectForm'
import MultipleSelectForm from '../../../components/FormInputs/MultipleSelectForm'

interface Props {
  open: boolean
  defaultCondition: ActivityLogFilteringCondition
  contents: ActivityLogContentResponse
  onClose: () => void
  onSubmit: (conditions: ActivityLogFilteringCondition) => void
}

const ActivityLogFilteringModal = ({
  open,
  contents,
  defaultCondition,
  onSubmit,
  onClose,
}: Props): JSX.Element => {
  const [displayContents, setDisplayContents] = useState<ActivityLogContent[]>(
    [],
  )
  const isMobile = useMobileFlag()
  const [storeIds, setStoreIds] = useState<number[]>(defaultCondition.store_ids)
  const [dateFrom, setDateFrom] = useState<string | null>(defaultCondition.from)
  const [dateTo, setDateTo] = useState<string | null>(defaultCondition.to)
  const [actionType, setActionType] = useState<ActionType[]>(
    defaultCondition.action_types,
  )
  const [contentId, setContentId] = useState<number | null>(
    defaultCondition.content_id,
  )
  const [selectedStatuses, setSelectedStatuses] = useState<ActivityLogStatus[]>(
    defaultCondition.statuses,
  )
  const [selectedIllegalStatus, setSelectedIllegalStatus] = useState<
    IllegalStatus[]
  >(defaultCondition.illegal_statuses)

  const handleClickSubmit = () => {
    const filteredData = {
      from: dateFrom,
      to: dateTo,
      store_ids: storeIds,
      action_types: actionType,
      content_id: contentId,
      statuses: selectedStatuses,
      illegal_statuses: selectedIllegalStatus,
    }
    onSubmit(filteredData)
    onClose()
  }

  const handleClickReset = () => {
    setStoreIds([])
    setDateFrom(null)
    setDateTo(null)
    setActionType([])
    setContentId(null)
    setSelectedStatuses([])
    setSelectedIllegalStatus([])
  }

  const convertUniqueContentType = (
    actionTypeList: ActionType[],
  ): ContentType[] => {
    const contentTypes = actionTypeList.map((v) => ContentTypeMappings[v])
    return Array.from(new Set(contentTypes))
  }

  const handleChangeActionType = (actions: ActionType[]) => {
    setActionType(actions)
    const contentTypes = convertUniqueContentType(actions)
    if (contentTypes.length !== 1) {
      setDisplayContents([])
      setContentId(null)
      return
    }

    if (contentTypes[0] === 'coupon') {
      setDisplayContents(contents.coupons)
    } else if (contentTypes[0] === 'ticket') {
      setDisplayContents(contents.tickets)
    } else if (contentTypes[0] === 'subscription') {
      setDisplayContents(contents.subscriptions)
    } else if (contentTypes[0] === 'stamp') {
      setDisplayContents(contents.stamp_cards)
    } else if (contentTypes[0] === 'service') {
      setDisplayContents(contents.services)
    } else {
      setDisplayContents([])
    }
  }

  const isDisabledContents =
    convertUniqueContentType(actionType).length !== 1 ||
    displayContents.length === 0

  return (
    <Dialog fullScreen={isMobile} fullWidth open={open} onClose={onClose}>
      <DialogTitle onClose={onClose}>絞り込み設定</DialogTitle>

      <DialogContent sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box>
          <FormLabel label='期間' />
          <Box
            display='flex'
            alignItems='center'
            gap={1}
            flexDirection={isMobile ? 'column' : 'row'}>
            <Box flex={1} position='relative' width='100%'>
              <Flatpickr
                data-enable-time
                value={dateFrom}
                options={{
                  altInput: true,
                  allowInput: true,
                  locale: Japanese,
                  altFormat: 'Y/m/d H:i',
                  maxDate: dateTo ? new Date(dateTo) : null,
                  minuteIncrement: 1,
                  disableMobile: true,
                }}
                onChange={(dates: Date[]) => {
                  setDateFrom(formatDate(new Date(dates[0])))
                }}
                placeholder='----/--/--'
              />
              {dateFrom && (
                <StyledClearIcon onClick={() => setDateFrom(null)} />
              )}
            </Box>
            <Box flex={1} position='relative' width='100%'>
              <Flatpickr
                data-enable-time
                value={dateTo}
                options={{
                  altInput: true,
                  allowInput: true,
                  locale: Japanese,
                  altFormat: 'Y/m/d H:i',
                  minDate: dateFrom ? new Date(dateFrom) : null,
                  minuteIncrement: 1,
                  disableMobile: true,
                }}
                onChange={(dates: Date[]) => {
                  setDateTo(formatDate(new Date(dates[0])))
                }}
                placeholder='----/--/--'
              />
              {dateTo && <StyledClearIcon onClick={() => setDateTo(null)} />}
            </Box>
          </Box>
        </Box>

        <Box>
          <FormLabel label='店舗' attachment='複数選択可' />
          <MultipleStoreSelectForm
            fullWidth
            storeIds={storeIds}
            onChange={setStoreIds}
          />
        </Box>

        <Box>
          <FormLabel label='アクション' attachment='複数選択可' />
          <MultipleSelectForm
            fullWidth
            items={
              ActionTypes.map((actionType) => ({
                value: actionType,
                label: ActionTypeLabels[actionType],
              })) || []
            }
            selectedValues={actionType}
            onChange={(values) =>
              handleChangeActionType(values as ActionType[])
            }
          />
        </Box>

        <Box>
          <FormLabel
            label='コンテンツ'
            tooltipText='アクションを二つ以上選択するとコンテンツは選択できません'
          />
          <Autocomplete
            fullWidth
            disabled={isDisabledContents}
            sx={{
              color: isDisabledContents ? '#E3E3E3' : 'none',
              background: isDisabledContents ? '#F5F5F5' : 'none',
            }}
            size='small'
            value={contentId !== null ? String(contentId) : ''}
            options={displayContents.map((content) => String(content.id))}
            getOptionLabel={(option) =>
              calculateContentLabel(
                displayContents.find((v) => v.id === Number(option)) ?? null,
              )
            }
            onChange={(_, value) => setContentId(value ? Number(value) : null)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={null}
                variant='outlined'
                placeholder='選択してください'
              />
            )}
          />
        </Box>

        <Box>
          <FormLabel label='不正状態' />
          {IllegalStatuses.map((status) => (
            <FormControlLabel
              key={status}
              control={
                <Checkbox
                  checked={selectedIllegalStatus.includes(status)}
                  onChange={(event) => {
                    const newStatuses = event.target.checked
                      ? [...selectedIllegalStatus, status]
                      : selectedIllegalStatus.filter(
                          (checkedIllegalStatus) =>
                            checkedIllegalStatus !== status,
                        )
                    setSelectedIllegalStatus(newStatuses)
                  }}
                />
              }
              label={IllegalStatusLabel[status]}
            />
          ))}
        </Box>

        <Box>
          <FormLabel label='有効状態' />
          {ActivityLogStatuses.map((status) => (
            <FormControlLabel
              key={status}
              control={
                <Checkbox
                  checked={selectedStatuses.includes(status)}
                  onChange={(event) => {
                    const newStatuses = event.target.checked
                      ? [...selectedStatuses, status]
                      : selectedStatuses.filter(
                          (checkedStatus) => checkedStatus !== status,
                        )
                    setSelectedStatuses(newStatuses)
                  }}
                />
              }
              label={LogStatusLabel[status]}
            />
          ))}
        </Box>
      </DialogContent>

      <DialogActions sx={{ pt: 2 }}>
        <Button
          fullWidth
          variant='outlined'
          color='cancel'
          onClick={handleClickReset}>
          条件をリセットする
        </Button>
        <Button
          fullWidth
          variant='contained'
          color='submit'
          type='submit'
          onClick={handleClickSubmit}>
          この条件で絞り込む
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const calculateContentLabel = (content: ActivityLogContent | null) =>
  content ? `【${content.store_name}】${content.title}` : ''
const IllegalStatuses = ['legal', 'illegal', 'doubtful'] as const
const ActivityLogStatuses = ['active', 'cancel'] as const

const StyledClearIcon = styled(X)({
  fontSize: 18,
  right: 8,
  top: 11,
  position: 'absolute',
  color: '#aaa',
  cursor: 'pointer',
})

export default ActivityLogFilteringModal
