import React, { useCallback, useEffect } from 'react'
import { Row, Col, Table } from 'react-bootstrap'
import { Pagination } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Card } from '../../../components/Card/Card'
import Button from '../../../components/CustomButton/CustomButton'
import SmallTableBody from '../../../components/Table/SmallTableBody'
import { useApi, usePcSizeFlag } from '../../../lib/hooks'
import { CouponContentsResponse } from '../../../types/api/coupon.d'
import {
  AutoCouponContentStatusToJpObj,
  CouponStatusColors,
  CouponStatusType,
} from '../../../containers/entities/CouponContentEntityContainer'
import ContentSalesEffect from '../../Coupon/parts/ContentSalesEffect'

type PointCouponContentsProps = {
  page?: number
}

type PropsType = {
  history
}

const PointCouponListTable: React.FC<PropsType> = ({ history }) => {
  const isPcOrTablet = usePcSizeFlag()
  const PAGE_LIMIT = isPcOrTablet ? 20 : 4

  const {
    api: pointCouponContentsGetApi,
    response: pointCouponContentsResponse,
    headers: pointCouponContentsResHeaders,
  } = useApi()

  const getPointCouponContents = useCallback(
    async (params: PointCouponContentsProps = {}) => {
      const page = params.page || 1

      await pointCouponContentsGetApi.get('/coupon_contents', {
        type: 'all_point',
        page: page,
        sort: 'created_at',
        order: 'desc',
        limit: PAGE_LIMIT,
      })
    },
    [pointCouponContentsGetApi, PAGE_LIMIT],
  )

  useEffect(() => {
    getPointCouponContents()
  }, [getPointCouponContents])

  const pointCouponContents: CouponContentsResponse['coupon_contents'] =
    pointCouponContentsResponse?.coupon_contents || []

  return (
    <div>
      <Row style={{ marginBottom: 10 }}>
        <Col xs={12} lg={10}>
          <Button
            pullRight
            bsStyle='info'
            fill
            onClick={() => history.push('privilege_point/new')}>
            <b>ポイント交換クーポンを作成する</b>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={10}>
          <Card
            title='ポイント交換クーポン一覧'
            content={
              <>
                <Table striped hover>
                  {pointCouponContents?.length ? (
                    <>
                      {isPcOrTablet && (
                        <thead>
                          <tr>
                            <th />
                            <th>タイトル</th>
                            <StyledNoWrapTh>必要ポイント</StyledNoWrapTh>
                            <StyledNoWrapTh>発行</StyledNoWrapTh>
                            <StyledNoWrapTh>閲覧</StyledNoWrapTh>
                            <StyledNoWrapTh>利用</StyledNoWrapTh>
                            <StyledNoWrapTh>売上効果</StyledNoWrapTh>
                            <StyledNoWrapTh>状態</StyledNoWrapTh>
                          </tr>
                        </thead>
                      )}
                      <tbody>
                        {pointCouponContents.map((item) => (
                          <tr
                            key={item.coupon_content.id}
                            style={{
                              fontWeight: 'bold',
                              cursor: 'pointer',
                            }}
                            onClick={(): void =>
                              history.push('privilege_point/edit', {
                                privilegeCoupon: {
                                  ...item.qrcode,
                                  ...item.obtain_condition,
                                  ...item.coupon_content,
                                },
                              })
                            }>
                            {isPcOrTablet ? (
                              <>
                                <td style={{ width: 80 }}>
                                  {item.coupon_content.image_url && (
                                    <img
                                      key={item.coupon_content.id}
                                      alt='画像'
                                      src={item.coupon_content.image_url}
                                      width={48}
                                      height={30}
                                      style={{
                                        borderRadius: 2,
                                        objectFit: 'cover',
                                      }}
                                    />
                                  )}
                                </td>
                                <td>
                                  {item.coupon_content.benefit}
                                  {item.coupon_content.memo && (
                                    <StyledMemo>
                                      {item.coupon_content.memo}
                                    </StyledMemo>
                                  )}
                                  {item.obtain_condition?.category ===
                                    'auto_point' && (
                                    <>
                                      <br />
                                      <span
                                        style={{
                                          background: 'gray',
                                          color: 'white',
                                          fontWeight: 'bold',
                                          padding: '3px 5px',
                                          fontSize: 11,
                                          borderRadius: '0.25em',
                                        }}>
                                        自動交換
                                      </span>
                                    </>
                                  )}
                                </td>
                                <StyledNoWrapTd>
                                  {item.obtain_condition?.threshold_point}
                                </StyledNoWrapTd>
                                <StyledNoWrapTd>
                                  {item.delivered_count}
                                </StyledNoWrapTd>
                                <StyledNoWrapTd>
                                  {item.read_count}
                                </StyledNoWrapTd>
                                <StyledNoWrapTd>
                                  {item.used_count}
                                </StyledNoWrapTd>
                                <StyledNoWrapTdSalesEffect>
                                  <ContentSalesEffect
                                    value={item.sales_effect}
                                  />
                                </StyledNoWrapTdSalesEffect>
                                <StyledNoWrapTd>
                                  <StyledStatusLabel
                                    status={item.coupon_content.status}>
                                    {
                                      AutoCouponContentStatusToJpObj[
                                        item.coupon_content.status
                                      ]
                                    }
                                  </StyledStatusLabel>
                                </StyledNoWrapTd>
                              </>
                            ) : (
                              <SmallTableBody
                                image={item.coupon_content.image_url}
                                title={item.coupon_content.benefit}
                                contents={[
                                  `必要ポイント:  ${item.obtain_condition?.threshold_point}`,
                                ]}
                                status={
                                  <StyledStatusLabel
                                    status={item.coupon_content.status}>
                                    {
                                      AutoCouponContentStatusToJpObj[
                                        item.coupon_content.status
                                      ]
                                    }
                                  </StyledStatusLabel>
                                }
                                memo={item.coupon_content.memo}
                              />
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </>
                  ) : (
                    <thead style={{ textAlign: 'center' }}>
                      <tr>
                        <td>
                          <b>表示できるポイント交換クーポンがありません</b>
                        </td>
                      </tr>
                    </thead>
                  )}
                </Table>
              </>
            }
            stats={
              pointCouponContentsResHeaders &&
              Number(pointCouponContentsResHeaders['total-pages']) > 1 ? (
                <Pagination
                  count={Number(pointCouponContentsResHeaders['total-pages'])}
                  page={Number(pointCouponContentsResHeaders['current-page'])}
                  onChange={(_, value) =>
                    getPointCouponContents({ page: value })
                  }
                  sx={{ fontSize: 24, color: 'black' }}
                />
              ) : null
            }
          />
        </Col>
      </Row>
    </div>
  )
}

const StyledStatusLabel = styled('div', {
  shouldForwardProp: (prop: string) => 'status' !== prop,
})<{ status: CouponStatusType }>(({ theme, status }) => ({
  backgroundColor: CouponStatusColors[status],
  display: 'inline',
  color: 'white',
  whiteSpace: 'nowrap',
  padding: theme.spacing(0.5, 1),
  border: 0,
  borderRadius: 6,
  fontWeight: 700,
}))

const StyledNoWrapTh = styled('th')({ whiteSpace: 'nowrap' })
const StyledNoWrapTd = styled('td')({ whiteSpace: 'nowrap' })
const StyledMemo = styled('div')({
  fontSize: 12,
  color: '#787878',
})
const StyledNoWrapTdSalesEffect = styled('td')({
  whiteSpace: 'nowrap',
  backgroundColor: '#EFF8F7',
})

export default PointCouponListTable
