import React, { useState, useEffect } from 'react'
import moment from 'moment'
import Flatpickr from 'react-flatpickr'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { padding, DATE_PICK_OPTION } from '../../../lib/general'
import DialogTitle from '../../../components/DialogTitle'
import FormLabel from '../../../components/FormInputs/FormLabel'

type Props = {
  defaultScheduledAt: string
  open: boolean
  disabled: boolean
  onClose: () => void
  onSubmit: (scheduledAt: string) => void
}

const OrderScheduledAtModal: React.FC<Props> = ({
  defaultScheduledAt,
  open,
  disabled,
  onClose,
  onSubmit,
}) => {
  const momented = moment(defaultScheduledAt)
  const defaultDate = momented.format('YYYY/MM/DD')
  const defaultHour = momented.hour()
  const defaultMinute = momented.minute() + (5 - (momented.minute() % 5))
  const [scheduledDate, setScheduledDate] = useState<string | null>(defaultDate)
  const [scheduledHour, setScheduledHour] = useState(defaultHour)
  const [scheduledMinute, setScheduledMinute] = useState(defaultMinute)

  useEffect(() => {
    if (open) {
      setScheduledDate(defaultDate)
      setScheduledHour(defaultHour)
      setScheduledMinute(defaultMinute)
    }
  }, [open, defaultDate, defaultHour, defaultMinute])

  return (
    <Dialog fullWidth open={open} onClose={onClose} maxWidth='xs'>
      <DialogTitle onClose={onClose}>受渡し日時の変更</DialogTitle>

      <DialogContent>
        <FormLabel label='受渡し日時' />
        <Box display='flex' alignItems='center'>
          <Box width={150}>
            <Flatpickr
              value={scheduledDate ?? ''}
              options={DATE_PICK_OPTION}
              onChange={(dates: Date[]) => {
                const newValue = dates[0]
                setScheduledDate(
                  newValue ? moment(newValue).format('YYYY/MM/DD') : null,
                )
              }}
            />
          </Box>
          <Select
            value={scheduledHour}
            sx={{ width: 70, ml: 1, mr: 0.5 }}
            onChange={(e) => setScheduledHour(Number(e.target.value))}>
            {[...Array(24)].map((_, i) => (
              <MenuItem key={i} value={i}>
                {padding(i, 2)}
              </MenuItem>
            ))}
          </Select>
          時
          <Select
            value={scheduledMinute}
            sx={{ width: 70, ml: 1, mr: 0.5 }}
            onChange={(e) => setScheduledMinute(Number(e.target.value))}>
            {[...Array(12)].map((_, i) => (
              <MenuItem key={i} value={i * 5}>
                {padding(i * 5, 2)}
              </MenuItem>
            ))}
          </Select>
          分
        </Box>
      </DialogContent>

      <DialogActions sx={{ pt: 2 }}>
        <Button
          variant='contained'
          color='submit'
          disabled={disabled}
          onClick={() =>
            onSubmit(`${scheduledDate} ${scheduledHour}:${scheduledMinute}`)
          }>
          変更する
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default OrderScheduledAtModal
