import React, { useMemo, ReactNode } from 'react'
import { useFormContext } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import styled from '@emotion/styled'
import { PencilSimple } from 'phosphor-react'
import CustomCheckbox from '../../../components/CustomCheckbox/CustomCheckbox'
import { useBooleanState } from '../../../lib/hooks'

type Props = {
  label: string
  name: string
  fixed?: boolean
  disabled?: boolean
  required: boolean
  children: ReactNode
}

const QuestionnaireAccordion = ({
  label,
  name,
  fixed = false,
  disabled = false,
  required,
  children,
}: Props): JSX.Element => {
  const { register, getValues } = useFormContext()
  const checked = getValues(name)
  const checkboxId = useMemo(uuid, [])
  const [expanded, , , toggle] = useBooleanState(false)

  const handleToggle = () => {
    if (checked) {
      toggle()
    }
  }

  return (
    <StyledItemContainer>
      <StyledItemHeader>
        <CustomCheckbox
          id={checkboxId}
          name={name}
          inputRef={register}
          label={label}
          labelStyle={{
            color: checked ? '#333' : '#ddd',
            fontSize: 16,
            marginBottom: 0,
          }}
          style={{ padding: '8px 4px 8px 16px', height: 'auto' }}
          disabled={fixed || disabled}
        />

        {checked && required && <StyledRequiredMark>*</StyledRequiredMark>}

        {fixed && (
          <StyledFixedNote>この項目は非表示にできません</StyledFixedNote>
        )}

        <StyledEditIconContainer onClick={handleToggle}>
          <PencilSimple size={20} color={checked ? '#777' : '#eee'} />
        </StyledEditIconContainer>
      </StyledItemHeader>

      <StyledAccordionContent expanded={checked && expanded}>
        {children}
      </StyledAccordionContent>
    </StyledItemContainer>
  )
}

const StyledItemContainer = styled('div')({
  backgroundColor: 'white',
  marginBottom: 8,
})

const StyledItemHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const StyledRequiredMark = styled('div')({
  color: 'red',
})

const StyledFixedNote = styled('div')({
  color: '#bbb',
  fontSize: 14,
  marginLeft: 8,
})

const StyledEditIconContainer = styled('div')({
  padding: 16,
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  cursor: 'pointer',
})

const StyledAccordionContent = styled('div')<{ expanded: boolean }>(
  ({ expanded }) => ({
    display: expanded ? 'block' : 'none',
    padding: 16,
  }),
)

export default QuestionnaireAccordion
