import React from 'react'
import { formatDate } from '../../../lib/general'
import {
  IllegalStatusLabel,
  IllegalLogStatusBackgroundColor,
  IllegalLogStatusFontColor,
  ActionTypeLabels,
} from '../../../constants/activityLog'
import { styled } from '@mui/material/styles'
import List from '@mui/material/List'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import ListItem from '@mui/material/ListItem'
import ActivityLogTagChip from './ActivityLogTagChip'
import {
  UserLogsResource,
  ActivityLogResource,
} from '../../../types/activityLog'
import ActivityLogBody from './ActivityLogBody'

type Props = {
  activityLogs: UserLogsResource[]
  onClickMenu: (
    e: React.MouseEvent<HTMLButtonElement>,
    activityLog: ActivityLogResource,
  ) => void
}

const MobileActivityLogTable = ({
  activityLogs,
  onClickMenu,
}: Props): JSX.Element => {
  return (
    <List sx={{ width: '100%', padding: 0 }}>
      {activityLogs.map(
        ({ activity_log, activity_log_content, store, user }) => {
          const isIllegalOrDoubtful = ['doubtful', 'illegal'].includes(
            activity_log.illegal_status,
          )
          const backgroundColor = isIllegalOrDoubtful
            ? IllegalLogStatusBackgroundColor[activity_log.illegal_status]
            : IllegalLogStatusBackgroundColor[activity_log.status]

          return (
            <ListItem sx={{ backgroundColor }}>
              <Box flex='1 0 0' display='flex' flexDirection='column' gap={1}>
                {isIllegalOrDoubtful && (
                  <Typography
                    sx={{
                      color:
                        IllegalLogStatusFontColor[activity_log.illegal_status],
                      fontSize: 12,
                      fontWeight: 'bold',
                      wordBreak: 'break-all',
                    }}>
                    <i className='fas fa-exclamation-triangle' />{' '}
                    {IllegalStatusLabel[activity_log.illegal_status]}
                    {activity_log.doubtful_reason_text &&
                      `: ${activity_log.doubtful_reason_text}`}
                  </Typography>
                )}
                <StyledIconWithTextContainer>
                  {activity_log.tags.map((tag) => (
                    <ActivityLogTagChip key={tag.name} tag={tag} />
                  ))}
                </StyledIconWithTextContainer>
                <Box>
                  <StyledLogTitle>
                    {ActionTypeLabels[activity_log.action_type] ?? ''}
                  </StyledLogTitle>
                  <ActivityLogBody activityLog={activity_log} />
                </Box>
                <StyledLogDetailContainer>
                  <Typography fontSize={13}>
                    {activity_log_content?.title || ''}
                  </Typography>
                  <Box display='flex' gap='12px'>
                    <StyledIconWithTextContainer>
                      <i className='ri-store-2-line'></i>
                      <Typography fontSize={13}>{store.name || ''}</Typography>
                    </StyledIconWithTextContainer>
                    <StyledIconWithTextContainer>
                      <i className='ri-user-3-line'></i>
                      <Typography fontSize={13}>
                        {user.name || user.id}
                      </Typography>
                    </StyledIconWithTextContainer>
                  </Box>
                  <Typography>{formatDate(activity_log.created_at)}</Typography>
                </StyledLogDetailContainer>
              </Box>

              <div>
                <IconButton
                  sx={{ lineHeight: 1 }}
                  onClick={(e) => onClickMenu(e, activity_log)}>
                  <i className='ri-more-fill' />
                </IconButton>
              </div>
            </ListItem>
          )
        },
      )}
    </List>
  )
}

const StyledLogTitle = styled(Typography)({
  fontWeight: 600,
  fontSize: 18,
  lineHeight: '25.2px',
})

const StyledLogDetailContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
  flexDirection: 'column',
  color: '#555555',
}))

const StyledIconWithTextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
}))

export default MobileActivityLogTable
