import React, { ComponentPropsWithoutRef } from 'react'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import { useBooleanState } from '../../lib/hooks'

type Props = ComponentPropsWithoutRef<'div'> & {
  label: React.ReactNode
  tooltipText?: string
  attachment?: string
}

const FormLabel = ({
  label,
  tooltipText = '',
  attachment = '',
  ...otherProps
}: Props): JSX.Element => {
  const [isTooltipOpen, openTooltip, closeTooltip] = useBooleanState(false)

  return (
    <StyledContainer {...otherProps}>
      {label}

      {Boolean(tooltipText) && (
        <Tooltip
          open={isTooltipOpen}
          onOpen={openTooltip}
          onClose={closeTooltip}
          title={tooltipText}
          placement='top'
          arrow>
          <i
            className='ri-question-fill'
            onClick={openTooltip}
            style={{ marginLeft: 4, fontSize: 20, fontWeight: 'normal' }}
          />
        </Tooltip>
      )}

      {Boolean(attachment) && <StyledChip>{attachment}</StyledChip>}
    </StyledContainer>
  )
}

const StyledContainer = styled('div')(({ theme }) => ({
  color: '#787878',
  display: 'flex',
  alignItems: 'center',
  fontSize: 14,
  fontWeight: 700,
  marginBottom: theme.spacing(1),
}))

const StyledChip = styled('div')(({ theme }) => ({
  display: 'inline-block',
  marginLeft: theme.spacing(1),
  backgroundColor: '#337ab7',
  color: '#fff',
  padding: '2px 8px',
  fontSize: 12,
  borderRadius: 4,
}))

export default FormLabel
