import { useState } from 'react'
import { createContainer } from 'unstated-next'
import { parsePhoneNumberFromString } from 'libphonenumber-js'
import {
  validateTextForm,
  validatePhoneNumberForm,
  validateBlankSpace,
  validateFirstMessage,
  validateFirstMessageStaffName,
} from '../../lib/validation'

const useStoreViewContainer = () => {
  const [offSet, setOffSet] = useState(0)
  const [parPage, setParPage] = useState(10)
  const [formName, setFormName] = useState(null)
  const [formAppIcon, setFormAppIcon] = useState(null)
  const [formSubImage, setFormSubImage] = useState(null)
  const [appIconUrl, setAppIconUrl] = useState(null)
  const [subImageUrl, setSubImageUrl] = useState([])
  const [formUrl, setFormUrl] = useState(null)
  const [twitterUrl, setTwitterUrl] = useState(null)
  const [qrscanMode, setQrscanMode] = useState(null)
  const [instagramUrl, setInstagramUrl] = useState(null)
  const [mapUrl, setMapUrl] = useState(null)
  const [facebookUrl, setFacebookUrl] = useState(null)
  const [formBusinessType, setFormBusinessType] = useState(null)
  const [formAppeal, setFormAppeal] = useState(null)
  const [customerUnitPrice, setCustomerUnitPrice] = useState(null)
  const [monthlyCustomer, setMonthlyCustomer] = useState(null)
  const [nameValidationMessage, setNameValidationMessage] = useState('')
  const [businessTypeValidationMessage, setBusinessTypeValidationMessage] =
    useState('')
  const [formFirstMessageStaffImage, setFormFirstMessageStaffImage] =
    useState(null)
  const [firstMessageStaffImageUrl, setFirstMessageStaffImageUrl] =
    useState(null)
  const [firstMessageStaffName, setFirstMessageStaffName] = useState(null)
  const [firstMessage, setFirstMessage] = useState(null)
  const [appealValidationMessage, setAppealValidationMessage] = useState('')
  const [formAddress, setFormAddress] = useState(null)
  const [formPhone, setFormPhone] = useState(null)
  const [formOpenTime, setFormOpenTime] = useState(null)
  const [formStatus, setFormStatus] = useState(null)
  const [formOpenOnMap, setFormOpenOnMap] = useState(null)
  const [formCode, setFormCode] = useState('')
  const [formFirstMessageStaffName, setFormFirstMessageStaffName] =
    useState(null)
  const [formFirstMessage, setFormFirstMessage] = useState(null)
  const [addressValidationMessage, setAddressValidationMessage] = useState('')
  const [phoneNumberValidationMessage, setPhoneNumberValidationMessage] =
    useState('')
  const [openTimeValidationMessage, setOpenTimeValidationMessage] = useState('')
  const [codeValidationMessage, setCodeValidationMessage] = useState('')
  const [firstMessageValidationMessage, setFirstMessageValidationMessage] =
    useState('')
  const [
    firstMessageStaffNameValidationMessage,
    setFirstMessageStaffNameValidationMessage,
  ] = useState('')
  const [isDirty, setIsDirty] = useState(false)

  const state = {
    formUrl,
    twitterUrl,
    instagramUrl,
    facebookUrl,
    mapUrl,
    offSet,
    parPage,
    formName,
    formBusinessType,
    formAppIcon,
    formSubImage,
    appIconUrl,
    subImageUrl,
    formAppeal,
    customerUnitPrice,
    monthlyCustomer,
    formFirstMessageStaffImage,
    firstMessageStaffImageUrl,
    firstMessageStaffName,
    firstMessage,
    nameValidationMessage,
    businessTypeValidationMessage,
    appealValidationMessage,
    formAddress,
    formPhone,
    formStatus,
    formOpenOnMap,
    formOpenTime,
    formCode,
    formFirstMessage,
    formFirstMessageStaffName,
    addressValidationMessage,
    phoneNumberValidationMessage,
    openTimeValidationMessage,
    codeValidationMessage,
    firstMessageValidationMessage,
    firstMessageStaffNameValidationMessage,
    isDirty,
    qrscanMode,
  }

  const setViewState = {
    initialize: (values) => {
      const {
        name,
        businessType,
        appeal,
        customerUnitPrice,
        monthlyCustomer,
        appIconUrl,
        subImageUrls,
        url,
        twitterUrl,
        instagramUrl,
        facebookUrl,
        mapUrl,
        address,
        phone_number,
        status,
        open_on_map,
        open_time,
        code,
        firstMessageStaffImageUrl,
        firstMessageStaffName,
        firstMessage,
        qrscan_mode,
      } = values

      setFormName(name)
      setTwitterUrl(twitterUrl)
      setQrscanMode(qrscan_mode)
      setInstagramUrl(instagramUrl)
      setFacebookUrl(facebookUrl)
      setMapUrl(mapUrl)
      setFormBusinessType(businessType)
      setFormAppeal(appeal)
      setCustomerUnitPrice(customerUnitPrice)
      setMonthlyCustomer(monthlyCustomer)
      setFormAppIcon(null)
      setAppIconUrl(appIconUrl)
      setFormSubImage(null)
      setSubImageUrl(subImageUrls)
      setFormUrl(url)
      setFormAddress(address)
      setFormPhone(phone_number)
      setFormOpenTime(open_time)
      setFormStatus(status)
      setFormOpenOnMap(open_on_map)
      setFormCode(code)
      setFormFirstMessageStaffImage(null)
      setFirstMessageStaffImageUrl(firstMessageStaffImageUrl)
      setFirstMessageStaffName(firstMessageStaffName)
      setFirstMessage(firstMessage)
      setFormFirstMessage(formFirstMessage)
      setFormFirstMessageStaffName(formFirstMessageStaffName)
    },
    offSet: setOffSet,
    parPage: setParPage,
    formName: setFormName,
    formAppIcon: setFormAppIcon,
    formSubImage: setFormSubImage,
    appIconUrl: setAppIconUrl,
    subImageUrl: setSubImageUrl,
    formBusinessType: setFormBusinessType,
    formAppeal: setFormAppeal,
    customerUnitPrice: setCustomerUnitPrice,
    monthlyCustomer: setMonthlyCustomer,
    nameValidationMessage: setNameValidationMessage,
    businessTypeValidationMessage: setBusinessTypeValidationMessage,
    appealValidationMessage: setAppealValidationMessage,
    formUrl: setFormUrl,
    twitterUrl: setTwitterUrl,
    qrscanMode: setQrscanMode,
    facebookUrl: setFacebookUrl,
    instagramUrl: setInstagramUrl,
    mapUrl: setMapUrl,
    formFirstMessageStaffImage: setFormFirstMessageStaffImage,
    firstMessageStaffImageUrl: setFirstMessageStaffImageUrl,
    firstMessageStaffName: setFirstMessageStaffName,
    firstMessage: setFirstMessage,
    formAddress: setFormAddress,
    formPhone: setFormPhone,
    formOpenTime: setFormOpenTime,
    formStatus: setFormStatus,
    formOpenOnMap: setFormOpenOnMap,
    formCode: setFormCode,
    formFirstMessage: setFormFirstMessage,
    formFirstMessageStaffName: setFirstMessageStaffName,
    addressValidationMessage: setAddressValidationMessage,
    phoneNumberValidationMessage: setPhoneNumberValidationMessage,
    openTimeValidationMessage: setOpenTimeValidationMessage,
    codeValidationMessage: setCodeValidationMessage,
    firstMessageValidationMessage: setFirstMessageValidationMessage,
    firstMessageStaffNameValidationMessage:
      setFirstMessageStaffNameValidationMessage,
    isDirty: setIsDirty,
  }

  const logic = {
    getInitialStore: () => ({
      name: '',
      businessType: '',
      appeal: '',
      appIconUrl: null,
    }),
    handleFormChange: (name, value) => {
      setViewState[name](value)
      setIsDirty(true)
    },
    handleFormStatusChange: (value) => {
      setViewState.formStatus(value)
    },
    handlePhoneNumber: (e) => {
      const phoneNumber = parsePhoneNumberFromString(e.target.value, 'JP')
      if (phoneNumber && phoneNumber.isValid()) {
        setViewState.formPhone(phoneNumber.formatNational())
      } else {
        setViewState.formPhone(e.target.value)
      }
      setIsDirty(true)
    },
    handleOpenTime: (e) => {
      this.setViewState.formOpenTime(e.target.value)
    },
    handleFirstMessage: (value) => {
      setViewState.formFirstMessage(value)
    },
    handleFirstMessageStaffName: (value) => {
      setViewState.formFirstMessageStaffName(value)
    },
    doDisplayImage: (imageUrl) =>
      // formImageもしくは、imageUrlが存在する場合、存在する方の画像を表示
      // 画像を削除する場合はimageUrlに値が入っていても表示しない
      formAppIcon || imageUrl,
    doDisplaySubImage: (imageUrl) =>
      // formSubImageもしくは、imageUrlが存在する場合、存在する方の画像を表示
      // 画像を削除する場合はimageUrlに値が入っていても表示しない
      formSubImage || imageUrl,
    doDisplayFirstMessageStaffImage: (firstMessageStaffImageUrl) =>
      // formImageもしくは、imageUrlが存在する場合、存在する方の画像を表示
      // 画像を削除する場合はimageUrlに値が入っていても表示しない
      formFirstMessageStaffImage || firstMessageStaffImageUrl,
    validate: ({
      name,
      address,
      phone_number,
      code,
      firstMessage,
      firstMessageStaffName,
    }) => {
      const resultName = validateTextForm(name)
      setViewState.nameValidationMessage(resultName.message)

      const resultAddress = validateTextForm(address)
      setViewState.addressValidationMessage(resultAddress.message)

      const resultPhone = validatePhoneNumberForm(phone_number)
      setViewState.phoneNumberValidationMessage(resultPhone.message)

      const resultFirstMessage = validateFirstMessage(firstMessage)
      setViewState.firstMessageValidationMessage(resultFirstMessage.message)

      const resultFirstMessageStaffName = validateFirstMessageStaffName(
        firstMessageStaffName,
      )
      setViewState.firstMessageStaffNameValidationMessage(
        resultFirstMessageStaffName.message,
      )

      const resultCode = validateBlankSpace(code)
      setViewState.codeValidationMessage(resultCode.message)

      return (
        resultName.validation &&
        resultAddress.validation &&
        resultPhone.validation &&
        resultCode.validation &&
        resultFirstMessage.validation &&
        resultFirstMessageStaffName.validation
      )
    },
  }
  return { state, setViewState, logic }
}

export default createContainer(useStoreViewContainer)
