import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '../../../components/DialogTitle'
import FormLabel from '../../../components/FormInputs/FormLabel'

type OperationType = 'add' | 'remove'

type Props = {
  currentPoint: number
  disabled: boolean
  open: boolean
  onAddPoint: (point: number) => void
  onRemovePoint: (removePoint: number) => void
  onClose: () => void
}

const PointModal = ({
  currentPoint,
  disabled,
  open,
  onAddPoint,
  onRemovePoint,
  onClose,
}: Props): JSX.Element => {
  const [point, setPoint] = useState(0)
  const [operationType, setOperationType] = useState<OperationType>('add')
  const operationLabel = operationType === 'add' ? '付与' : '削除'
  const resultPoint =
    operationType === 'add' ? currentPoint + point : currentPoint - point

  useEffect(() => {
    if (open) {
      setPoint(0)
    }
  }, [open])

  const submit = () => {
    if (operationType === 'add') {
      onAddPoint(point)
    } else {
      onRemovePoint(point)
    }
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose} maxWidth='xs'>
      <DialogTitle onClose={onClose}>ポイント{operationLabel}</DialogTitle>

      <DialogContent>
        <RadioGroup
          row
          value={operationType}
          sx={{ mb: 1 }}
          onChange={(e) => setOperationType(e.target.value as OperationType)}>
          <FormControlLabel
            value='add'
            control={<Radio />}
            label='ポイント付与'
          />
          <FormControlLabel
            value='remove'
            control={<Radio />}
            label='ポイント削除'
          />
        </RadioGroup>

        <FormLabel label='現在のポイント' />
        <Box fontSize={18} mb={2}>
          {currentPoint.toLocaleString()}pt
        </Box>

        <FormLabel label={`${operationLabel}するポイント数`} />
        <OutlinedInput
          type='number'
          value={point || ''}
          sx={{ mb: 2 }}
          onChange={(e) => setPoint(Math.max(0, Number(e.target.value)))}
        />

        <FormLabel label={`${operationLabel}後のポイント数`} />
        <Box fontSize={18}>
          {point > 0 ? <>{resultPoint.toLocaleString()}pt</> : <>-</>}
        </Box>
      </DialogContent>

      <DialogActions sx={{ pt: 2 }}>
        <Button
          fullWidth
          variant='outlined'
          color='cancel'
          disabled={disabled}
          onClick={onClose}>
          キャンセル
        </Button>

        <Button
          fullWidth
          variant='contained'
          color={operationType === 'add' ? 'submit' : 'danger'}
          disabled={point === 0 || resultPoint < 0 || disabled}
          onClick={submit}>
          ポイントを{operationLabel}する
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PointModal
