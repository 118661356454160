import React from 'react'
import { withRouter } from 'react-router'
import {
  Grid,
  Row,
  Col,
  FormGroup,
  FormControl,
  HelpBlock,
} from 'react-bootstrap'
import { Card } from '../components/Card/Card'
import LoadingButton from '../components/CustomButton/LoadingButton'
import { combineContainer } from '../lib/general'
import AppContainer from '../containers/AppContainer'
import PasswordResetViewContainer from '../containers/views/PasswordResetViewContainer'
import AuthEntityContainer from '../containers/entities/AuthEntityContainer'
import { useOnceEffect } from '../lib/hooks'

const PasswordResetViewWrapper = (props) => (
  <PasswordResetViewContainer.Provider>
    <PasswordResetView
      {...props}
      staffForm={props.location.pathname.indexOf('staff') !== -1}
    />
  </PasswordResetViewContainer.Provider>
)

export default withRouter(PasswordResetViewWrapper)

const PasswordResetView = (props) => {
  const container = combineContainer([
    AppContainer.useContainer(),
    PasswordResetViewContainer.useContainer(),
    AuthEntityContainer.useContainer(),
  ])

  const { state, api, logic, setViewState } = container

  useOnceEffect(() => {
    setViewState.initialize(logic.getInitialLogin())
  })

  const onClick = {
    postPasswordResetLink: async () => {
      const formData = logic.getFormData()
      if (!logic.validate(formData)) {
        return
      }
      try {
        props.staffForm
          ? await await api.postPasswordResetLink(formData)
          : await await api.postUserPasswordResetLink(formData)

        props.displayNotification({
          level: 'success',
          message: 'パスワードリセット用のメールを送信しました。',
        })
      } catch (error) {
        props.displayNotification({
          level: 'error',
          message: error.message,
        })
      }
    },
  }

  return (
    <div className='content'>
      <Grid fluid>
        <Row>
          <Col md={3} xs={1} />
          <Col md={6} xs={10}>
            <Card
              title='パスワードのリセット'
              category='登録に使用したメールアドレスを入力してください。
              パスワードをリセットするためのリンクを記載したメールをお送りします。'
              ctTableResponsive
              content={
                <form>
                  <Row>
                    <Col md={1} xs={0} />
                    <Col md={10} xs={12}>
                      <FormGroup controlId='formControlsText'>
                        <FormControl
                          name='formEmail'
                          placeholder='メールアドレスを入力する'
                          bsClass='form-control'
                          value={state.formEmail}
                          onChange={({ target }) =>
                            logic.handleFormChange(target.name, target.value)
                          }
                        />
                        <HelpBlock className='text-danger'>
                          {state.emailValidationMessage}
                        </HelpBlock>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: 30, marginTop: 20 }}>
                    <Col md={1} xs={0} />
                    <Col md={10} xs={12}>
                      <LoadingButton
                        label='送信'
                        loadingLabel='送信中...'
                        block
                        fill
                        onClick={onClick.postPasswordResetLink}
                      />
                    </Col>
                    <Col md={1} xs={0} />
                  </Row>
                </form>
              }
            />
          </Col>
        </Row>
      </Grid>
    </div>
  )
}
