/* eslint-disable react/require-default-props */
import React, { CSSProperties } from 'react'
import { usePcSizeFlag } from '../../../lib/hooks'
import { SpecialOpenTimeItem } from '../../../containers/entities/OpenTimeEntityContainer'
import { formatTimeByString } from '../../../lib/general'

type Props = {
  specialOpenTimes: SpecialOpenTimeItem[]
  onClickEditIcon: (SpecialOpenTimeItem: SpecialOpenTimeItem) => void
  onClickDeleteIcon: (id) => void
}

const SpecialOpenTimeList: React.FC<Props> = ({
  specialOpenTimes,
  onClickEditIcon,
  onClickDeleteIcon,
}) => {
  const isPcOrTablet = usePcSizeFlag()

  return (
    <>
      {specialOpenTimes.map((specialOpenTime) => (
        <div
          key={`special-open-time-${specialOpenTime.id}`}
          style={{
            backgroundColor: '#F4F4F5',
            padding: '16px',
            paddingTop: isPcOrTablet ? '16px' : '34px',
            position: 'relative',
            display: 'grid',
            rowGap: '12px',
          }}>
          <SpecialOpenTimeRow
            title='営業日名'
            content={specialOpenTime.title || '-'}
          />
          <SpecialOpenTimeRow
            title='日程'
            content={
              specialOpenTime.start_year &&
              specialOpenTime.start_month &&
              specialOpenTime.start_date
                ? `${specialOpenTime.start_year}年${specialOpenTime.start_month}月${specialOpenTime.start_date}日`
                : `毎月${specialOpenTime.start_date}日`
            }
          />

          {!specialOpenTime.is_holiday && (
            <SpecialOpenTimeRow
              title='営業時間'
              content={
                specialOpenTime.is_all_day
                  ? '24時間営業'
                  : `${formatTimeByString({
                      hour: specialOpenTime.start_hour,
                      minute: specialOpenTime.start_minute,
                    })}〜${formatTimeByString({
                      hour: specialOpenTime.end_hour,
                      minute: specialOpenTime.end_minute,
                    })}`
              }
            />
          )}

          <SpecialOpenTimeRow
            title='カラー'
            style={{
              width: '25px',
              height: '25px',
              borderRadius: '50%',
              backgroundColor: specialOpenTime.color,
            }}
          />

          {/* 編集・削除アイコン */}
          <div
            style={{
              position: 'absolute',
              top: 16,
              right: 16,
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              columnGap: '8px',
            }}>
            <i
              className='fa fa-pencil'
              style={{ fontSize: 18, color: '#757575', cursor: 'pointer' }}
              onClick={() => onClickEditIcon(specialOpenTime)}
            />
            <i
              className='fa fa-trash-o'
              style={{ fontSize: 18, color: '#757575', cursor: 'pointer' }}
              onClick={() => onClickDeleteIcon(specialOpenTime.id)}
            />
          </div>
        </div>
      ))}
    </>
  )
}

export default SpecialOpenTimeList

type RowProps = {
  title: string
  content?: string
  style?: CSSProperties
}

const SpecialOpenTimeRow = ({
  title,
  content,
  style,
}: RowProps): JSX.Element => {
  const isPcOrTablet = usePcSizeFlag()
  return (
    <div
      style={{
        display: 'grid',
        gridTemplateColumns: isPcOrTablet ? '3fr 7fr' : '2fr 5fr',
        alignItems: 'center',
      }}>
      <div style={{ color: '#787878', fontWeight: 'bold' }}>{title}</div>
      <div style={style}>{content}</div>
    </div>
  )
}
