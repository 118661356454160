import { useCallback, useState } from 'react'
import { createContainer } from 'unstated-next'
import { noticeApi } from '../../lib/api'

export interface NoticeContent {
  id: number
  store_id: number
  image_urls: Array<string>
  status: NoticeStatusType
  created_at: string
  title: string
  body: string
  do_push: boolean
  user_ids: Array<number>
  publish_at: string
  category: AutoNoticeCategory
  publish_hour: number
  publish_min: number
  publish_time: string
  days_ago: number
  button_url: string | null
  button_label: string | null
  days_later: number
  publish_weeks: number[]
  publish_dates: number[]
  enabled_auto_publish_condition: boolean
  enabled_target_user_condition: boolean
  target_user_condition?: NoticeContentTargetUserCondition | null
  memo: string | null
}

export const AutoNoticeCategory = {
  Register: 'register',
  NoCheckIn: 'no_check_in',
  Repeat: 'repeat',
  Birthday: 'birthday',
} as const

export type NoticeContentTargetUserCondition = {
  age_from: number | null
  age_to: number | null
  birth_month: number | null
  coupon_content_id: number | null
  created_at: string
  gender: 'male' | 'female' | 'other' | null
  id: number
  notice_content_id: number | null
  rank: number | null
  rank_ids: number[]
  updated_at: string
  user_group_collection_id: number
  user_group_ids: number[]
  user_ids: number[]
  users_count: number
}

export interface NoticeParamsInterface {
  template_id?: number
  title?: string
  body?: string
  image?: string
  do_delete_image?: string
  do_push?: boolean
  user_ids?: Array<number>
  publish_at?: Date | null
  status?: NoticeStatusType
  memo?: string
}

export const NoticeStatus = {
  Unpublished: 'unpublished',
  Publish: 'publish',
  Preview: 'preview',
  Reservation: 'reservation',
} as const

export type NoticeStatusType = (typeof NoticeStatus)[keyof typeof NoticeStatus]

export const NoticeContentStatusToJpObj = {
  [NoticeStatus.Unpublished]: '下書き',
  [NoticeStatus.Preview]: '下書き',
  [NoticeStatus.Reservation]: '配信予定',
  [NoticeStatus.Publish]: '配信済み',
} as const
export const AutoPublishNoticeStatusToJpObj = {
  [NoticeStatus.Unpublished]: '無効',
  [NoticeStatus.Publish]: '有効',
  [NoticeStatus.Preview]: 'プレビュー',
} as const
export const AutoNoticeContentStatusToJpObj = {
  [NoticeStatus.Unpublished]: '無効',
  [NoticeStatus.Publish]: '有効',
  [NoticeStatus.Preview]: '無効',
}

export const NoticeStatusColors = {
  [NoticeStatus.Unpublished]: '#b0b0b0',
  [NoticeStatus.Preview]: '#b0b0b0',
  [NoticeStatus.Reservation]: '#8577ff',
  [NoticeStatus.Publish]: '#00ddb4',
} as const

export type AutoNoticeCategory =
  (typeof AutoNoticeCategory)[keyof typeof AutoNoticeCategory]

export type AutoNoticeCategoryToJp =
  | '初回登録'
  | '再来店'
  | '定期配信'
  | '誕生日'

export const Categories = Object.values(AutoNoticeCategory)

export const CategoryToJpObj = {
  [AutoNoticeCategory.Register]: '初回登録',
  [AutoNoticeCategory.NoCheckIn]: '再来店',
  [AutoNoticeCategory.Repeat]: '定期配信',
  [AutoNoticeCategory.Birthday]: '誕生日',
} as const

export const statusToJp = (notice: NoticeContent): string => {
  switch (notice.status) {
    case NoticeStatus.Publish:
      return '配信済み'
    case NoticeStatus.Reservation:
      return '配信予定'
    default:
      return '下書き'
  }
}

const useNoticeEntityHook = () => {
  const [notices, setNotices] = useState<NoticeContent[]>([])
  const [autoPublishNotices, setAutoPublishNoticesNotices] = useState<
    NoticeContent[]
  >([])

  const updateNoticeState = useCallback(
    (newNotice: NoticeContent) => {
      if (!newNotice.enabled_auto_publish_condition) {
        const index = notices.findIndex((notice) => notice.id === newNotice.id)
        setNotices([
          ...notices.slice(0, index),
          newNotice,
          ...notices.slice(index + 1),
        ])
        return
      }
      const index = autoPublishNotices.findIndex(
        (notice) => notice.id === newNotice.id,
      )
      setAutoPublishNoticesNotices([
        ...autoPublishNotices.slice(0, index),
        newNotice,
        ...autoPublishNotices.slice(index + 1),
      ])
    },
    [notices, autoPublishNotices],
  )

  const addNoticeState = useCallback(
    (newNotice: NoticeContent) => {
      if (!newNotice.enabled_auto_publish_condition) {
        setNotices([newNotice, ...notices])
        return
      }
      setAutoPublishNoticesNotices([newNotice, ...autoPublishNotices])
    },
    [notices, autoPublishNotices],
  )

  return {
    state: { notices, autoPublishNotices },
    logic: {
      setNotices: setNotices,
      getNotice: async (id: number): Promise<NoticeContent> => {
        const newNotice = await noticeApi.getNotice(id)
        return newNotice
      },
      createNotice: async (
        params: NoticeParamsInterface,
      ): Promise<NoticeContent> => {
        const newNotice: NoticeContent = await noticeApi.createNotice({
          params,
        })
        addNoticeState(newNotice)
        return newNotice
      },
      updateNotice: async (
        id: number,
        params: NoticeParamsInterface,
      ): Promise<NoticeContent> => {
        const newNotice: NoticeContent = await noticeApi.updateNotice({
          id: id,
          params,
        })
        updateNoticeState(newNotice)
        return newNotice
      },
      publishNotice: async (id: number): Promise<NoticeContent> => {
        const newNotice: NoticeContent = await noticeApi.publishNotice(id)
        updateNoticeState(newNotice)
        return newNotice
      },
      autoPublishNoticeStatusLabelToJp: (
        status: NoticeStatusType,
      ): '無効' | '有効' | 'アプリでプレビュー中' =>
        AutoNoticeContentStatusToJpObj[status],
      statusLabelBackgroundColor: (notice: NoticeContent): string => {
        switch (notice.status) {
          case NoticeStatus.Publish:
            return '#00ddb4'
          case NoticeStatus.Reservation:
            return '#8577ff'
          default:
            return '#b0b0b0'
        }
      },
      categoryToJp: (
        noticeCategory: AutoNoticeCategory,
      ): AutoNoticeCategoryToJp => CategoryToJpObj[noticeCategory],
    },
  }
}

export default createContainer(useNoticeEntityHook)
