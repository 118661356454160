import React, { useEffect, useCallback } from 'react'
import moment from 'moment'
import { Pagination } from '@mui/material'
import { Table } from 'react-bootstrap'
import { TransactionOutlined } from '@ant-design/icons'
import { SubscriptionsResponse } from '../../../types/api/subscription.d'
import { useApi, usePcSizeFlag } from '../../../lib/hooks'
import {
  SubscriptionStatusColors,
  SubscriptionStatusLabels,
} from '../../../constants/subscription'
import SectionTitle from './SectionTitle'
import NoItemContent from '../../../components/Utils/NoItemContent'

type Props = {
  userId: number
}

const Subscription = ({ userId }: Props): JSX.Element => {
  const isPcOrTablet = usePcSizeFlag()

  const {
    api: subscriptionsGetApi,
    response: subscriptionsResponse,
    headers: subscriptionsResHeaders,
  } = useApi()
  const subscriptions: Array<SubscriptionsResponse> =
    subscriptionsResponse?.subscriptions || []

  const getSubscriptions = useCallback(
    (page: number = 1) => {
      subscriptionsGetApi.get(`/users/${userId}/subscriptions`, {
        page,
        limit: 5,
      })
    },
    [subscriptionsGetApi, userId],
  )

  useEffect(() => {
    getSubscriptions()
  }, [getSubscriptions])

  if (subscriptions.length === 0) {
    return (
      <div>
        <SectionTitle
          style={{ marginBottom: 12 }}
          title={<b>購入済みのサブスク</b>}
        />

        <NoItemContent
          label='購入済みのサブスクはありません'
          icon={<TransactionOutlined />}
        />
      </div>
    )
  }

  return (
    <div>
      <SectionTitle
        style={{ marginBottom: 12 }}
        title={<b>購入済みのサブスク</b>}
      />

      {isPcOrTablet ? (
        <Table striped hover>
          <thead>
            <tr>
              {['タイトル', '利用状況', '配信日時'].map((prop) => (
                <th key={prop}>{prop}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {subscriptions.map(
              ({ subscription, subscription_content, store }) => (
                <tr key={subscription.id}>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      {subscription_content.image_url && (
                        <img
                          key={subscription_content.id}
                          alt='サブスク画像'
                          src={subscription_content.image_url}
                          width={48}
                          height={30}
                          style={{
                            borderRadius: 2,
                            objectFit: 'cover',
                            marginRight: 8,
                          }}
                        />
                      )}

                      <div>
                        <div style={{ fontWeight: 700 }}>
                          {subscription_content.title}
                        </div>
                        <div style={{ color: '#9A9A9A', fontSize: 12 }}>
                          {store.name}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td style={{ fontWeight: 700 }}>
                    <div
                      style={{
                        color: SubscriptionStatusColors[subscription.status],
                      }}>
                      {SubscriptionStatusLabels[subscription.status]}
                    </div>
                  </td>
                  <td>
                    {subscription.created_at
                      ? moment(subscription.created_at).format(
                          'YYYY/M/D(dddd) HH:mm',
                        )
                      : '----'}
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </Table>
      ) : (
        <Table striped hover>
          <tbody>
            {subscriptions.map(
              ({ subscription, subscription_content, store }) => (
                <tr key={subscription.id}>
                  <td>
                    <div
                      style={{
                        display: 'flex',
                        gap: 16,
                        alignItems: 'flex-start',
                      }}>
                      {subscription_content.image_url && (
                        <img
                          key={subscription_content.id}
                          alt='サブスク画像'
                          src={subscription_content.image_url}
                          width={48}
                          height={30}
                          style={{
                            borderRadius: 2,
                            objectFit: 'cover',
                            marginRight: 8,
                          }}
                        />
                      )}

                      <div>
                        <div style={{ marginBottom: 4 }}>
                          <div style={{ fontWeight: 700 }}>
                            {subscription_content.title}
                          </div>
                          <div
                            style={{
                              fontWeight: 'normal',
                              color: '#9a9a9a',
                              fontSize: 12,
                            }}>
                            {store.name}
                          </div>
                        </div>
                        <div style={{ color: '#7d7d7d' }}>
                          利用況{' '}
                          <span
                            style={{
                              color:
                                SubscriptionStatusColors[subscription.status],
                              fontWeight: 700,
                            }}>
                            {SubscriptionStatusLabels[subscription.status]}
                          </span>
                        </div>
                        <div style={{ color: '#7d7d7d' }}>
                          配信日時{' '}
                          {subscription.created_at
                            ? moment(subscription.created_at).format(
                                'YYYY/M/D(dddd) HH:mm',
                              )
                            : '----'}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ),
            )}
          </tbody>
        </Table>
      )}

      {Number(subscriptionsResHeaders['total-pages']) > 1 && (
        <Pagination
          count={Number(subscriptionsResHeaders['total-pages']) || 1}
          page={Number(subscriptionsResHeaders['current-page']) || 1}
          onChange={(_, page) => getSubscriptions(page)}
          style={{ paddingBottom: 8, borderBottom: '1px solid #E8E8E8' }}
          sx={{ fontSize: 24, color: 'black' }}
        />
      )}
    </div>
  )
}

export default Subscription
