import React from 'react'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useApi, useBooleanState, useMobileFlag } from '../../../lib/hooks'
import { displayJpReadableNumber } from '../../../lib/general'
import { StoreBenchmarkResource } from '../../../types/storeBenchmark'
import { useNotification } from '../../../providers/NotificationProvider'
import Section from '../../../components/Section'
import BenchmarkProgressCard from './BenchmarkProgressCard'
import Calendar from './Calendar'
import RetrospectiveModal from './RetrospectiveModal'

type Props = {
  benchmark: StoreBenchmarkResource
  dailyBenchmarks: StoreBenchmarkResource[]
  disabled: boolean
  onUpdatedRetrospective: () => void
  onClickChangeDailyPerformance: () => void
}

const BenchmarkDetailPerformance = ({
  benchmark,
  dailyBenchmarks,
  disabled,
  onUpdatedRetrospective,
  onClickChangeDailyPerformance,
}: Props): JSX.Element => {
  const history = useHistory()
  const { showSuccessNotification } = useNotification()
  const { api, loading } = useApi()
  const [isModalOpen, openModal, closeModal] = useBooleanState(false)
  const dailyBenchmarkByDate = dailyBenchmarks.reduce(
    (results, benchmark) => ({
      ...results,
      [benchmark.start_date]: benchmark,
    }),
    {},
  )

  const updateRetrospective = async (retrospective: string) => {
    const response = await api.patch(`/store_benchmarks/${benchmark.id}`, {
      retrospective,
    })
    if (response) {
      showSuccessNotification('振り返り内容を更新しました。')
      closeModal()
      onUpdatedRetrospective()
    }
  }

  return (
    <>
      {benchmark.benchmark_progress === 'before' && (
        <Section>
          <Box fontSize={18}>この目標はまだ開始前です。</Box>
        </Section>
      )}

      {benchmark.benchmark_progress !== 'before' && (
        <Section>
          {benchmark.description && (
            <StyledDescription>{benchmark.description}</StyledDescription>
          )}

          <BenchmarkProgressCard
            benchmark={benchmark}
            dailyBenchmarks={dailyBenchmarks}
            style={{ padding: 0, marginBottom: 16 }}
          />

          {dailyBenchmarks.length > 0 && (
            <>
              <Box display='flex' gap={1} alignItems='center' mb={1}>
                <Typography variant='title'>日次</Typography>
                <Button color='submit' onClick={onClickChangeDailyPerformance}>
                  日次の目標を変更する
                </Button>
              </Box>

              <Calendar
                startDate={benchmark.start_date}
                endDate={benchmark.end_date}
                cellHeight={84}
                renderCell={(_, date) => {
                  const currentBenchmark =
                    dailyBenchmarkByDate[date.format('YYYY-MM-DD')]
                  const isToday = date.isSame(moment(), 'day')
                  const progress =
                    currentBenchmark &&
                    currentBenchmark.performance_value !== null
                      ? Math.floor(
                          (currentBenchmark.performance_value /
                            currentBenchmark.benchmark_value) *
                            100,
                        )
                      : null
                  const currentPerformance = currentBenchmark?.performance_value

                  return (
                    <StyledDailyPerformanceContainer isToday={isToday}>
                      <CalendarCell
                        benchmark={currentBenchmark?.benchmark_value}
                        performance={currentPerformance}
                        progress={progress}
                      />
                    </StyledDailyPerformanceContainer>
                  )
                }}
              />
            </>
          )}
        </Section>
      )}

      {benchmark.benchmark_progress !== 'before' && (
        <Section title='振り返り'>
          {benchmark.retrospective ? (
            <Box whiteSpace='pre-wrap'>{benchmark.retrospective}</Box>
          ) : (
            <Box color='#777'>
              目標に関する振り返りの内容を残しておきましょう。
            </Box>
          )}

          <Button
            size='small'
            color='submit'
            startIcon={<i className='ri-pencil-fill' />}
            disabled={disabled || loading}
            sx={{ mt: 1 }}
            onClick={openModal}>
            内容を編集する
          </Button>

          <RetrospectiveModal
            open={isModalOpen}
            defaultValue={benchmark.retrospective || ''}
            disabled={disabled || loading}
            onClose={closeModal}
            onSubmit={updateRetrospective}
          />
        </Section>
      )}

      <Box display='flex' justifyContent='center' my={2}>
        <Button
          fullWidth
          variant='outlined'
          color='cancel'
          disabled={loading}
          sx={{ width: 300 }}
          onClick={() => history.push('/admin/benchmark')}>
          もどる
        </Button>
      </Box>
    </>
  )
}

type CalendarCellProps = {
  benchmark?: number
  performance?: number | null
  progress: number | null
}

const CalendarCell = ({
  benchmark,
  performance,
  progress,
}: CalendarCellProps): JSX.Element => {
  const isMobile = useMobileFlag()

  return isMobile ? (
    <>
      <Box pt={4} fontSize={18} fontWeight={700}>
        {benchmark && performance !== undefined && performance !== null ? (
          <StyledProgressColor progress={progress}>
            {displayJpReadableNumber(performance)}
          </StyledProgressColor>
        ) : (
          '--'
        )}
      </Box>

      <Box style={{ marginTop: -2 }} fontSize={12}>
        / {benchmark || '--'}
      </Box>
    </>
  ) : (
    <>
      <Box fontSize={20} fontWeight={700} pt={3}>
        {benchmark && performance !== undefined && performance !== null
          ? displayJpReadableNumber(performance)
          : '--'}
      </Box>
      <StyledProgressContainer>
        目標
        {benchmark ? (
          <>
            {displayJpReadableNumber(benchmark)}
            <StyledProgressLabel>
              <StyledProgressColor progress={progress}>
                {progress ?? '--'}%
              </StyledProgressColor>
            </StyledProgressLabel>
          </>
        ) : (
          '--'
        )}
      </StyledProgressContainer>
    </>
  )
}

const StyledDescription = styled('div')(({ theme }) => ({
  backgroundColor: '#F8F9FA',
  borderRadius: 4,
  padding: theme.spacing(1.5),
  fontSize: 15,
  marginBottom: theme.spacing(2),
  whiteSpace: 'pre-wrap',
}))

const StyledDailyPerformanceContainer = styled('div', {
  shouldForwardProp: (props) => props !== 'isToday',
})<{ isToday: boolean }>(({ isToday }) => ({
  textAlign: 'center',
  backgroundColor: isToday ? '#F3F5F9' : 'transparent',
  height: '100%',
}))

const StyledProgressContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 14,
})
const StyledProgressLabel = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  fontSize: 16,
}))
const StyledProgressColor = styled('div', {
  shouldForwardProp: (props) => props !== 'progress',
})<{ progress: number | null }>(({ progress }) => ({
  color:
    progress === null ? 'inherit' : progress >= 100 ? '#2FB05B' : '#D74532',
}))

export default BenchmarkDetailPerformance
