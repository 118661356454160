import moment from 'moment'
import { RankingStatus } from '../types/ranking'

export const RankingStatusLabels = {
  finished: '終了',
  stopped: '停止中',
  waiting: '開始前',
  inProgress: '進行中',
} as const

export const RankingContentStatusLabels = {
  active: '有効',
  inactive: '無効',
} as const

export const RankingCategoryLabels = {
  point: 'ポイント獲得数',
  stamp: 'スタンプ獲得数',
  check_in: 'チェックイン数',
} as const

export const rankingProgressStatus = (
  status: string,
  startDatetime: string,
  endDatetime: string,
): RankingStatus => {
  if (status === 'finished') return 'finished'
  const now = moment()
  if (status === 'stopped' && moment(endDatetime).isBefore(now))
    return 'finished'
  if (status === 'stopped') return 'stopped'
  if (status === 'active' && moment(startDatetime).isAfter(now))
    return 'waiting'
  return 'inProgress'
}
