import React from 'react'
import Hint from '../../../assets/img/hint.png'

type Props = {
  onClick: () => void
}

const SupportGuide = ({ onClick }: Props) => (
  <div
    style={{
      backgroundColor: '#D4E3F2',
      display: 'flex',
      alignItems: 'center',
      borderRadius: 8,
      padding: 16,
      cursor: 'pointer',
    }}
    onClick={onClick}>
    <img
      src={Hint}
      alt='掲示物の制作・活用ガイド'
      style={{
        width: 30,
        height: 30,
      }}
    />

    <div style={{ marginLeft: 16 }}>
      <div style={{ fontWeight: 'bold', fontSize: 16 }}>
        掲示物の制作・活用ガイド
      </div>
      <div style={{ color: '#282828' }}>
        掲示物の活用方法や事例をご紹介します
      </div>
    </div>
  </div>
)

export default SupportGuide
