import React from 'react'
import { styled } from '@mui/material/styles'
import { Table } from 'react-bootstrap'
import { NoticeContentBlock } from '../../../types/api/notice.d'
import {
  CategoryToJpObj,
  AutoNoticeContentStatusToJpObj,
  NoticeStatusType,
  NoticeStatusColors,
} from '../../../containers/entities/NoticeEntityContainer'
import ContentMeasurementValueAndDiff from '../../../components/Utils/ContentMeasurementValueAndDiff'
import OptionButton from '../../../components/CustomButton/OptionButton'
import { useLoginContext } from '../../../providers/LoginContextProvider'

type Props = {
  autoNoticeContents: NoticeContentBlock[]
  goToEdit: (id: number) => void
  deleteNoticeContent: (id: number) => Promise<void>
}

const AutoNoticeTable = ({
  autoNoticeContents,
  goToEdit,
  deleteNoticeContent,
}: Props): JSX.Element => {
  const { currentStore } = useLoginContext()
  const showDeliveredCount = currentStore.show_notice_delivered_count
  const showArrivalCount = currentStore.show_notice_arrival_count
  const showReadCount = currentStore.show_notice_read_count

  return (
    <Table striped hover>
      <thead>
        <tr>
          <th> </th>
          <th>タイトル</th>
          {showDeliveredCount && <th>配信</th>}
          {showArrivalCount && <th>到達</th>}
          {showReadCount && <th>開封</th>}
          <th>カテゴリー</th>
          <th>状態</th>
          <th> </th>
        </tr>
      </thead>
      <tbody>
        {autoNoticeContents.map(
          ({
            notice_content,
            delivered_count,
            read_count,
            read_count_diff,
            arrival_count,
            auto_publish_condition,
            has_available_arrival_count,
          }) => (
            <tr style={{ cursor: 'pointer' }} key={notice_content.id}>
              <td onClick={() => goToEdit(notice_content.id)}>
                {notice_content.image_urls[0] && (
                  <img
                    key={notice_content.id}
                    alt='お知らせ画像'
                    src={notice_content.image_urls[0]}
                    width={48}
                    height={30}
                    style={{
                      borderRadius: 2,
                      objectFit: 'cover',
                    }}
                  />
                )}
              </td>
              <td
                style={{ width: 350 }}
                onClick={() => goToEdit(notice_content.id)}>
                <div>
                  <b>{notice_content.title}</b>
                </div>
                {notice_content.memo && (
                  <StyledMemo>{notice_content.memo}</StyledMemo>
                )}
              </td>

              {showDeliveredCount && (
                <td onClick={() => goToEdit(notice_content.id)}>
                  {delivered_count}
                </td>
              )}

              {showArrivalCount && (
                <td onClick={() => goToEdit(notice_content.id)}>
                  {has_available_arrival_count ? arrival_count : '---'}
                </td>
              )}

              {showReadCount && (
                <td onClick={() => goToEdit(notice_content.id)}>
                  <ContentMeasurementValueAndDiff
                    value={read_count}
                    diff={read_count_diff}
                    createdAt={notice_content.created_at}
                  />
                </td>
              )}

              <td onClick={() => goToEdit(notice_content.id)}>
                {auto_publish_condition &&
                  CategoryToJpObj[auto_publish_condition.category]}
              </td>
              <td onClick={() => goToEdit(notice_content.id)}>
                <StyledStatusLabel status={notice_content.status}>
                  {AutoNoticeContentStatusToJpObj[notice_content.status]}
                </StyledStatusLabel>
              </td>
              <td>
                {delivered_count === 0 && (
                  <OptionButton
                    options={[
                      {
                        label: '削除',
                        labelColor: '#FF4A55',
                        onClick: () => deleteNoticeContent(notice_content.id),
                      },
                    ]}
                  />
                )}
              </td>
            </tr>
          ),
        )}
      </tbody>
    </Table>
  )
}

const StyledStatusLabel = styled('div', {
  shouldForwardProp: (prop) => prop !== 'status',
})<{ status: NoticeStatusType }>(({ status, theme }) => ({
  backgroundColor: NoticeStatusColors[status],
  display: 'inline',
  color: 'white',
  padding: theme.spacing(0.5, 1),
  border: 0,
  borderRadius: 6,
  fontWeight: 700,
}))

const StyledMemo = styled('div')({
  fontSize: 12,
  color: '#787878',
})

export default AutoNoticeTable
