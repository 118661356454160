export const SubscriptionContentStatusLabel = {
  draft: '下書き',
  preview: '下書き',
  open: '公開',
  close: '販売停止',
  sold: '売り切れ',
  limited_open: '限定公開',
} as const

const SubscriptionStatuses = {
  Active: 'active',
  Paused: 'paused',
  Cancel: 'cancel',
  DailyUsed: 'daily_used',
  MonthlyUsed: 'monthly_used',
} as const

export const SubscriptionStatusColors = {
  [SubscriptionStatuses.Active]: '#888',
  [SubscriptionStatuses.Paused]: '#5c9ee0',
  [SubscriptionStatuses.Cancel]: '#ff6363',
  [SubscriptionStatuses.DailyUsed]: '#00ddb4',
  [SubscriptionStatuses.MonthlyUsed]: '#00ddb4',
} as const

export const SubscriptionStatusLabels = {
  [SubscriptionStatuses.Active]: '利用可能',
  [SubscriptionStatuses.Paused]: '一時停止中',
  [SubscriptionStatuses.Cancel]: '解約済み',
  [SubscriptionStatuses.DailyUsed]: '本日利用済み',
  [SubscriptionStatuses.MonthlyUsed]: '本月利用済み',
} as const
