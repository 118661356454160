import React from 'react'
import styled from '@emotion/styled/macro'
import { SortOrderType } from '../../types/common.d'

const StyledSortIcon = styled.i`
  opacity: 0;
`

const StyledSortIconThContainer = styled.th`
  cursor: pointer;

  &:hover ${StyledSortIcon} {
    opacity: 0.5;
  }
`

const StyledHeaderLabel = styled.span`
  margin-right: 4px;
`

type Props = {
  sortOrder: SortOrderType | null
  onChangeSortOrder: (sortOrder: SortOrderType) => void
  children: React.ReactNode
}

const SortIconTh = ({
  sortOrder,
  onChangeSortOrder,
  children,
  ...otherProps
}: Props): JSX.Element => {
  const newSortOrder: SortOrderType =
    !sortOrder || sortOrder === 'desc' ? 'asc' : 'desc'

  return (
    <StyledSortIconThContainer
      onClick={() => onChangeSortOrder(newSortOrder)}
      {...otherProps}>
      <StyledHeaderLabel>{children}</StyledHeaderLabel>
      <SortArrow sortOrder={sortOrder} />
    </StyledSortIconThContainer>
  )
}

type SortArrowProps = {
  sortOrder: SortOrderType | null
}

const SortArrow = ({ sortOrder }: SortArrowProps): JSX.Element => {
  if (!sortOrder) {
    return <StyledSortIcon className='fas fa-arrow-down' />
  }
  if (sortOrder === 'desc') {
    return <i className='fas fa-arrow-down' />
  }
  return <i className='fas fa-arrow-up' />
}

export default SortIconTh
