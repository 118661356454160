import { storage } from './general'

export const loadAuth = () => {
  const auth = storage.getItem('auth')
  if (auth) {
    return JSON.parse(auth)
  }
  return null
}

export const setAuth = ({ client, uid }) => {
  const auth = {
    client,
    uid,
  }

  storage.setItem('auth', JSON.stringify(auth))
}

export const setAuthFromHeader = (headers) => {
  const prevAuth = loadAuth()

  const nextAuth = {
    client: headers.client || prevAuth?.client,
    uid: headers.uid || prevAuth?.uid,
  }

  if (nextAuth.client && nextAuth.uid) {
    setAuth(nextAuth)
  } else {
    removeAuth()
  }

  if (window?.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify(nextAuth))
  }
}

export const removeAuth = () => {
  try {
    storage.removeItem('auth')
    return true
  } catch (error) {
    return false
  }
}

export const isLogin = () => {
  const auth = loadAuth()
  if (auth) {
    return auth.client && auth.uid
  }

  return false
}
