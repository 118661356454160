import { useMemo, useState } from 'react'
import { createContainer } from 'unstated-next'
import { userApi } from '../../lib/api'
import { ColorType } from './MembersCardContentEntityContainer'
import { UserGender } from '../../types/user'
import { Genders, GenderLabel } from '../../constants/user'

// interface
export interface UserInterface {
  id: number
  name: string
  user_address: UserAddress | null
  kana_name: string
  nick_name: string
  email: string
  phone_number?: string
  age: number
  birthday: Date
  sex: UserGender | null
  zipcode: string
  push_token: string
  sms_auth_active: boolean
  registered: boolean
  connected_at: string
  created_at: string
  notices: UserNotice[]
  coupons: UserCoupon[]
  services: UserService[]
  tickets: UserTicket[]
  stamp_cards: UserStampCard[]
  members_card: UserMemberCard[]
  avatar_url: string
  rank_color: ColorType
  member_code: string
  rank_name: string
  visit_count: number
  last_visit_at: string | null
}

export type UserAddress = {
  address: string
  building_name: string
  created_at: string
  house_number: string
  id: number
  latitude: number
  longitude: number
  updated_at: string
  user_id: number
  zipcode: string
}

export type UserNotice = {
  id: number
  title: string
  body: string
  child_stores: any[]
  image_url: string
  is_read: boolean
  notice_content_id: number
  publish_at: string
  user_id: number
  store_id: number
  store_name: string
  notice_id: number
}

export type UserCoupon = {
  id: number
  benefit: string
  is_read: boolean
  body: string
  child_stores: any[]
  coupon_content_id: number
  expiration_date: string
  has_usage_limit: boolean
  image_url: string
  publish_at: string
  store_id: number
  store_name: string
  terms: string[]
  user_id: number
}

export type UserService = {
  id: number
  title: string
  status: UserStatus
  body: string
  child_stores: any[]
  image_url: string
  user_id: number
  store_id: number
  store_name: string
  usable_count: number | null
  used_count: number | null
  daily_usable_count: number
  daily_used_count: number
  monthly_usable_count: number
  monthly_used_count: number
  service_content_id: number
}

export type UserTicket = {
  id: number
  body: string
  title: string
  child_stores: any[]
  image_url: string
  store_id: number
  store_name: string
  user_id: number
  expiration_from: string
  expiration_to: string
  ticket_content_id: number
  usable_count: number
  used_count: number
  purchase_count: number
  purchased_store_id: number
  purchased_store_name: string
  price: number
  daily_usable_count: number
  daily_used_count: number
  monthly_usable_count: number
  monthly_used_count: number
  status: UserStatus
}

export type UserStampCard = {
  check_in_enabled: boolean
  child_stores: any[]
  created_at: string
  how_to_uses: string
  id: number
  max_stamp: number
  privileges: any[]
  stamp: number
  stamp_image_url: string
  stamp_value: number
  store_id: number
  store_name: string
  total_stamp: number
  user_id: number
}

export type UserMemberCard = {
  id: number
  is_code_updated: boolean
  member_code: string
  point_enabled: boolean
  point: number
  rank_name: string
  rank_up_method: string
  rank_value: number
}

export type UserStatus =
  | 'inactive'
  | 'active'
  | 'used'
  | 'daily_used'
  | 'monthly_used'

export type UserStatusToJp =
  | '使用不可'
  | '使用可'
  | '使用済み'
  | '本日使用済み'
  | '本月使用済み'

export type UserSexToJp = '男性' | '女性' | 'その他'

export type UserFilteringCondition = {
  sex: UserGender | null
  members_card_rank_ids: number[]
  age_from: number | null
  age_to: number | null
}

const userStatusToJpObj = {
  inactive: '使用不可',
  active: '使用可',
  used: '使用済み',
  expired: '期限切れ',
  daily_used: '本日使用済み',
  monthly_used: '本月使用済み',
} as const

const userSexes = Genders

const userSexToJpObj = GenderLabel

export const userSexToJp = (sex: UserGender): UserSexToJp => userSexToJpObj[sex]

export const userStatusToJp = (status: UserStatus): UserStatusToJp =>
  userStatusToJpObj[status]

const useUserEntityHook = () => {
  const [users, setUsers] = useState<UserInterface[]>([])
  const [user, setUser] = useState<UserInterface>()

  // 絞り込み
  const [searchedKeyword, setSearchedKeyword] = useState('')

  const [userFilteringCondition, setUserFilteringCondition] =
    useState<UserFilteringCondition>({
      sex: null,
      members_card_rank_ids: [],
      age_from: null,
      age_to: null,
    })

  // 絞り込みしているか
  const isUserFiltering = useMemo(
    () =>
      !!userFilteringCondition?.sex ||
      !!userFilteringCondition?.members_card_rank_ids?.length ||
      !!userFilteringCondition?.age_from ||
      !!userFilteringCondition?.age_to,
    [userFilteringCondition],
  )

  // url
  const [urlParams, setUrlParams] = useState('')

  return {
    state: {
      users,
      user,
      searchedKeyword,
      userFilteringCondition,
      isUserFiltering,
      urlParams,
    },
    constant: { userSexes },
    logic: {
      setUrlParams,
      setUserFilteringCondition,
      setUsers,
      setUser,
      setSearchedKeyword,
      getUsers: async (newUrlParams?) => {
        const response = await userApi.getUsers(newUrlParams)
        setUsers(response.data.customers)
        return response
      },
      getUser: async (id: number): Promise<UserInterface> => {
        const newUser: UserInterface = await userApi.getUser(id)
        setUser(newUser)
        return newUser
      },
    },
  }
}

export default createContainer(useUserEntityHook)
