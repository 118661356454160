import React from 'react'
import { Card } from './Card'
import ContentSummaryCard from './ContentSummaryCard'
import PeriodSelector from '../PeriodSelector/PeriodSelector'

type Props = {
  title: string
  onChangePeriod: (...args: any[]) => void
  deliveredCount?: number
  arrivalCount?: number
  readCount?: number
  usedCount?: number
  salesEffect?: number
  hasAvailableArrivalCount?: boolean
}
const PeriodSpecifiableContentSummaryCard = ({
  title,
  onChangePeriod,
  deliveredCount,
  arrivalCount,
  readCount,
  usedCount,
  salesEffect,
  hasAvailableArrivalCount = true,
}: Props): JSX.Element => {
  return (
    <Card
      headerStyle={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
      title={title}
      rightContent={
        <PeriodSelector
          title='集計期間'
          description='指定した期間に配信したクーポンの配信数、開封数、利用数を表示できます。指定期間内の開封数、利用数ではありませんのでご注意下さい。（配信日での集計）'
          onChangePeriod={onChangePeriod}
        />
      }
      content={
        <ContentSummaryCard
          style={{ backgroundColor: 'white' }}
          {...(deliveredCount != null && { deliveredCount: deliveredCount })}
          {...(arrivalCount != null && { arrivalCount: arrivalCount })}
          {...(readCount != null && { readCount: readCount })}
          readCountDiff={0}
          {...(usedCount != null && { usedCount: usedCount })}
          {...(salesEffect != null && { salesEffect: salesEffect })}
          withHeader={false}
          hasAvailableArrivalCount={hasAvailableArrivalCount}
        />
      }
    />
  )
}

export default PeriodSpecifiableContentSummaryCard
