import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useBooleanState } from '../../../lib/hooks'
import { Card } from '../../../components/Card/Card'
import SelectorForm from '../../../components/FormInputs/SelectorForm'
import CustomButton from '../../../components/CustomButton/CustomButton'
import { TicketContentStatus } from '../../../containers/entities/TicketContentEntityContainer'
import { isViewObjGroup } from '../../../lib/viewObj'
import {
  Alert,
  FormChangedAlertModal,
} from '../../../components/Modal/FormChangedAlertModal'
import { TicketContentStatusLabels } from '../../../constants/ticket'

const modalTitle = (status: TicketContentStatus) => {
  switch (status) {
    case 'close':
      return 'チケットの公開を停止'
    case 'open':
      return 'チケットを公開'
    case 'limited_open':
      return 'チケットを限定公開'
    case 'sold':
      return 'チケットを売り切れに変更'
    default:
      return ''
  }
}

const modalSubmitButtonLabel = (status: TicketContentStatus) => {
  switch (status) {
    case 'close':
      return '公開を停止する'
    case 'open':
      return '公開する'
    case 'limited_open':
      return '限定公開する'
    case 'sold':
      return '売り切れにする'
    default:
      return ''
  }
}

const modalBody = (status: TicketContentStatus) => {
  switch (status) {
    case 'close':
      return 'チケットの公開を停止してよろしいですか？'
    case 'open':
      return 'チケットを公開してよろしいですか？'
    case 'limited_open':
      return 'チケットを限定公開してよろしいですか？'
    case 'sold':
      return 'チケットを売り切れにしてよろしいですか？'
    default:
      return ''
  }
}

type Props = {
  onClickStatusUpdateButton: (status: TicketContentStatus) => Promise<void>
  defaultStatus: TicketContentStatus
}

const optionArray = (status?: TicketContentStatus): TicketContentStatus[] => {
  // グループでの限定公開機能は現状対応していないため、
  // 「グループのときは限定公開選択不可」
  // 「すでにグループで限定公開にしてしまっている場合のみ限定公開がプルダウンに表示」
  // という分岐（一次対応）
  switch (status) {
    case 'draft':
    case undefined:
      return isViewObjGroup()
        ? ['draft', 'open']
        : ['draft', 'open', 'limited_open']
    case 'expired':
      return isViewObjGroup()
        ? ['expired', 'open', 'close']
        : ['expired', 'open', 'limited_open', 'close']
    case 'limited_open':
      return ['open', 'limited_open', 'close']
    default:
      return isViewObjGroup()
        ? ['open', 'close']
        : ['open', 'limited_open', 'close']
  }
}

const TicketPublishSettingCard = ({
  onClickStatusUpdateButton,
  defaultStatus,
}: Props): JSX.Element => {
  const [isOpenModal, openModal, closeModal] = useBooleanState(false)
  const [newStatus, setNewStatus] = useState<TicketContentStatus>(defaultStatus)
  const isChanged = defaultStatus !== newStatus
  const warnings = generateWarnings(newStatus)

  const openModalFor = (s: TicketContentStatus) => {
    setNewStatus(s)
    openModal()
  }

  return (
    <Card
      title='公開設定'
      content={
        <>
          <Row>
            <Col sm={12}>
              <SelectorForm
                name='status'
                width={180}
                style={{ marginBottom: 0 }}
                value={newStatus}
                onSelect={({ target }) => setNewStatus(target.value)}
                options={optionArray(defaultStatus).map((status) => ({
                  label: TicketContentStatusLabels[status],
                  value: status,
                }))}
                showChangeIndicator={isChanged}
              />
            </Col>
          </Row>

          <Row style={{ margin: '8px 0 0 0' }}>
            <Col>
              <CustomButton
                bsStyle='info'
                fill
                simple
                pullRight
                disabled={!isChanged}
                onClick={() => openModalFor(newStatus)}>
                <strong>変更する</strong>
              </CustomButton>
            </Col>
          </Row>

          <FormChangedAlertModal
            title={modalTitle(newStatus)}
            show={isOpenModal}
            submitButtonLabel={modalSubmitButtonLabel(newStatus)}
            onSubmit={() => onClickStatusUpdateButton(newStatus)}
            onCancel={closeModal}>
            {modalBody(newStatus)}
            {warnings.length > 0 && (
              <Alert severity='warning'>
                <ul>
                  {warnings.map((warning) => (
                    <li key={warning}>{warning}</li>
                  ))}
                </ul>
              </Alert>
            )}
          </FormChangedAlertModal>
        </>
      }
    />
  )
}

const generateWarnings = (status: TicketContentStatus) => {
  const warnings: string[] = []
  if (status === 'close') {
    warnings.push(
      '公開設定を販売停止にすると、ユーザーがこのチケットを購入できなくなります。ただし、購入済みのチケットは引き続き利用可能です。',
    )
  }
  if (status === 'limited_open') {
    warnings.push(
      '公開設定を限定公開にすると、チケットの一覧に表示されなくなります。ただし、すでに購入済みのチケットは、引き続き利用できます。',
    )
  }

  return warnings
}

export default TicketPublishSettingCard
