import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import LoginView from '../../../views/Login/LoginView'
import PasswordResetView from '../../../views/PasswordResetView'
import PasswordEditFormView from '../../../views/PasswordEditFormView'
import { useRenderer } from '../hooks'

const NoLoginRoutes = () => {
  const renderer = useRenderer()

  return (
    <Switch>
      <Route exact path='/_healthcheck' render={() => "I'm healty"} />
      <Route exact path='/admin/login' render={renderer(LoginView)} />
      <Route
        exact
        path='/admin/staff/password/reset'
        render={renderer(PasswordResetView)}
      />
      <Route
        exact
        path='/admin/staff/password/edit'
        render={renderer(PasswordEditFormView)}
      />
      <Route
        exact
        path='/admin/password/reset'
        render={renderer(PasswordResetView)}
      />
      <Route
        exact
        path='/admin/password/edit'
        render={renderer(PasswordEditFormView)}
      />
      <Redirect to='/admin/login' />
    </Switch>
  )
}

export default NoLoginRoutes
