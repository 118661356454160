import React, { useState } from 'react'
import moment from 'moment'
import Flatpickr from 'react-flatpickr'
import { styled } from '@mui/system'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { padding, DATE_PICK_OPTION, range } from '../../../lib/general'
import { StoreBenchmarkIndicator } from '../../../types/storeBenchmark'
import { StoreBenchmarkIndicatorLabels } from '../../../constants/storeBenchmark'
import FormLabel from '../../../components/FormInputs/FormLabel'
import YearMonthForm from '../../../components/FormInputs/YearMonthForm'
import { BenchmarkFilterCondition } from '../type'
import MultipleStoreSelectForm from '../../../components/FormInputs/MultipleStoreSelectForm'

type Props = {
  open: boolean
  monthly: boolean
  defaultCondition: BenchmarkFilterCondition
  onClose: () => void
  onSubmit: (condition: BenchmarkFilterCondition) => void
}

const ConditionModal = ({
  open,
  monthly,
  defaultCondition,
  onClose,
  onSubmit,
}: Props): JSX.Element => {
  // 2023年に目標機能がリリースされたので
  // 2023年〜現在の2年後くらいまでを検索可能な年の範囲にする
  const today = new Date()
  const thisYear = today.getFullYear()
  const thisMonth = today.getMonth() + 1
  const years = range(2023, thisYear + 3 - 2023)
  const [indicator, setIndicator] = useState<StoreBenchmarkIndicator | null>(
    defaultCondition.indicator,
  )
  const [dateFrom, setDateFrom] = useState<string | null>(
    defaultCondition.dateFrom,
  )
  const [dateTo, setDateTo] = useState<string | null>(defaultCondition.dateTo)
  const [storeIds, setStoreIds] = useState<number[]>(defaultCondition.storeIds)

  const reset = () => {
    setIndicator(null)
    setDateFrom(null)
    setDateTo(null)
    setStoreIds([])
  }

  const submit = () => {
    onSubmit({
      indicator,
      dateFrom,
      dateTo,
      storeIds,
    })
    onClose()
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose} maxWidth='sm'>
      <StyledDialogTitle>
        絞り込み条件
        <i onClick={onClose} className='ri-close-line' />
      </StyledDialogTitle>
      <DialogContent>
        <FormLabel label='指標' />
        <Select
          fullWidth
          value={indicator || 'empty'}
          sx={{ mb: 2 }}
          onChange={(e: SelectChangeEvent) => {
            const newIndicator = e.target.value
            setIndicator(
              newIndicator === 'empty'
                ? null
                : (newIndicator as StoreBenchmarkIndicator),
            )
          }}>
          <MenuItem value='empty'>すべて</MenuItem>
          {Object.entries(StoreBenchmarkIndicatorLabels).map(
            ([indicator, label]) => (
              <MenuItem key={indicator} value={indicator}>
                {label}
              </MenuItem>
            ),
          )}
        </Select>

        <FormLabel label='期間' />
        <Box display='flex' alignItems='center' gap={1} mb={2}>
          {monthly ? (
            <>
              <YearMonthForm
                years={years}
                enableEmpty
                year={dateFrom ? Number(dateFrom?.split('/')[0]) : null}
                month={dateFrom ? Number(dateFrom?.split('/')[1]) : thisMonth}
                onChange={(year, month) => {
                  if (year === null || month === null) {
                    setDateFrom(null)
                  } else {
                    setDateFrom(`${year}/${padding(month, 2)}/01`)
                  }
                }}
              />
              〜
              <YearMonthForm
                years={years}
                enableEmpty
                year={dateTo ? Number(dateTo?.split('/')[0]) : null}
                month={dateTo ? Number(dateTo?.split('/')[1]) : thisMonth}
                onChange={(year, month) => {
                  if (year === null || month === null) {
                    setDateTo(null)
                  } else {
                    setDateTo(`${year}/${padding(month, 2)}/01`)
                  }
                }}
              />
            </>
          ) : (
            <>
              <Flatpickr
                value={dateFrom || ''}
                options={DATE_PICK_OPTION}
                onChange={(dates: Date[]) => {
                  const newValue = dates[0]
                  setDateFrom(
                    newValue ? moment(newValue).format('YYYY/MM/DD') : null,
                  )
                }}
              />
              〜
              <Flatpickr
                value={dateTo || ''}
                options={DATE_PICK_OPTION}
                onChange={(dates: Date[]) => {
                  const newValue = dates[0]
                  setDateTo(
                    newValue ? moment(newValue).format('YYYY/MM/DD') : null,
                  )
                }}
              />
            </>
          )}
        </Box>

        <FormLabel label='店舗' />
        <MultipleStoreSelectForm
          fullWidth
          storeIds={storeIds}
          onChange={setStoreIds}
        />
      </DialogContent>

      <DialogActions sx={{ pt: 2 }}>
        <Button fullWidth variant='outlined' color='cancel' onClick={reset}>
          条件をリセットする
        </Button>

        <Button fullWidth variant='contained' color='submit' onClick={submit}>
          この条件で絞り込む
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const StyledDialogTitle = styled(DialogTitle)({
  display: 'flex',
  justifyContent: 'space-between',
  '& > i': {
    cursor: 'pointer',
  },
})

export default ConditionModal
