import React from 'react'
import { FormControl, HelpBlock } from 'react-bootstrap'
import FormLabel from './FormLabel'
import FormGroup from './FormGroup'
import ChangeIndicator from '../ChangeIndicator'

// !!! IMPORTANT !!!
// このコンポーネントは古いreact-bootstrapを利用しているためDeprecatedにします。
// 新しくMaterial-UI v5のOutlinedInputを利用するようにしましょう。
const FormInput = ({
  label = '',
  validationMessage = '',
  attachment = '',
  tooltipText = '',
  unit = '',
  note = '',
  ncol = '',
  width = 100,
  inVisible = false,
  formGroupStyle = {},
  showChangeIndicator = false,
  showChangeBorderIndicator = false,
  style = {},
  ...props
}) => {
  if (inVisible) return null

  return (
    <FormGroup style={formGroupStyle} className={ncol}>
      <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
        {label && (
          <FormLabel
            label={label}
            tooltipText={tooltipText}
            attachment={attachment}
          />
        )}

        {showChangeIndicator && <ChangeIndicator style={{ marginBottom: 8 }} />}
      </div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormControl
          style={{
            ...(showChangeBorderIndicator || showChangeIndicator
              ? { borderColor: '#1dc7ea' }
              : {}),
            ...style,
          }}
          {...props}
        />

        {unit && <span style={{ marginLeft: 8, width }}>{unit}</span>}
      </div>

      {note && (
        <HelpBlock className='text-muted' style={{ fontSize: 11 }}>
          {note}
        </HelpBlock>
      )}
      {validationMessage && (
        <HelpBlock className='text-danger'>{validationMessage}</HelpBlock>
      )}
    </FormGroup>
  )
}

export default FormInput
