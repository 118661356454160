import React from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useLoginContext } from '../../providers/LoginContextProvider'
import MultipleSelectForm from './MultipleSelectForm'

type Props = {
  fullWidth?: boolean
  storeIds: number[]
  onChange: (storeIds: number[]) => void
}

const MultipleStoreSelectForm = ({
  fullWidth = false,
  storeIds,
  onChange,
}: Props): JSX.Element => {
  const { currentStore } = useLoginContext()
  const items = currentStore.child_stores.map((store) => ({
    value: String(store.id),
    label: store.name,
  }))

  return currentStore.store_type === 'child' ? (
    <Select fullWidth={fullWidth} disabled value={currentStore.id}>
      <MenuItem value={currentStore.id}>{currentStore.name}</MenuItem>
    </Select>
  ) : (
    <MultipleSelectForm
      items={items}
      fullWidth={fullWidth}
      selectedValues={storeIds.map(String)}
      onChange={(values) => onChange(values.map(Number))}
    />
  )
}

export default MultipleStoreSelectForm
