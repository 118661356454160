import React from 'react'
import { useQuery } from '../../lib/hooks'
import CouponContentFrom from './parts/CouponContentForm'

const NewCouponFormView = (): JSX.Element => {
  const query = useQuery()
  const type = query.get('type')
  const userIds = query.get('user_ids')
  const userGroupIds = query.get('user_group_ids')
  const userGroupCollectionId = query.get('user_group_collection_id')
  const questionnaireId = Number(query.get('questionnaire_id'))

  const defaultTargetUserCondition = {
    user_ids: userIds?.split(',').map((v) => Number(v)) || [],
    user_group_ids: userGroupIds?.split(',').map((v) => Number(v)) || [],
    user_group_collection_id: userGroupCollectionId
      ? Number(userGroupCollectionId)
      : null,
  }

  return (
    <CouponContentFrom
      isAuto={type === 'auto'}
      defaultTargetUserCondition={defaultTargetUserCondition}
      defaultQuestionnaireId={questionnaireId}
    />
  )
}

export default NewCouponFormView
