import moment from 'moment'
import lodash from 'lodash'
import { Japanese } from 'flatpickr/dist/l10n/ja'
import { UserGeneration } from '../types/user'

export const EMAIL_REGEX =
  /^[A-Za-z0-9]{1}[A-Za-z0-9+_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/
export const isEmailValid = (email) => EMAIL_REGEX.test(email)

export const isEmpty = (obj) => !Object.keys(obj).length

export const isPresent = (obj) => !isEmpty(obj)

export const numberOrNull = (value) => (value === null ? null : Number(value))

export const getTimestamp = () => {
  // 現在日時からタイムスタンプのファイル名を生成
  const d = new Date() // Today
  const DateTimeFormat = 'YYYYMMDDhhmiss' // "2019/10/04 12:34:56" -> "20191004_123456"
  const toFileName = DateTimeFormat.replace(/YYYY/g, String(d.getFullYear()))
    .replace(/MM/g, `0${d.getMonth() + 1}`.slice(-2))
    .replace(/DD/g, `0${d.getDate()}`.slice(-2))
    .replace(/hh/g, `0${d.getHours()}`.slice(-2))
    .replace(/mi/g, `0${d.getMinutes()}`.slice(-2))
    .replace(/ss/g, `0${d.getSeconds()}`.slice(-2))
  return toFileName
}

export const delay = (func) => {
  setTimeout(() => {
    func()
  }, 200)
}

/**
 * delayAsMillisecondsミリ秒だけ待つPromiseを生成します。
 * 遅延を表現したい時にawait wait(2000)などとして使えます。
 * 主にデバッグ用途に使われることを想定
 */
export const wait = (delayAsMilliseconds) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, delayAsMilliseconds)
  })

export const copyText = (id) => {
  // コピー範囲
  const range = document.createRange()
  const copyElement = document.getElementById(id)
  if (copyElement) {
    range.selectNodeContents(copyElement)
    // コピー範囲を選択状態に
    const selection = document.getSelection()
    selection?.removeAllRanges()
    selection?.addRange(range)
    // コピー
    document.execCommand('copy')
  }
}

export const calcAge = (birthDay) => {
  if (!birthDay) {
    return null
  }
  const formatBirthDay = new Date(birthDay)
  // 今日
  const today = new Date()
  //  今年の誕生日
  const thisYearBirthday = new Date(
    today.getFullYear(),
    formatBirthDay.getMonth(),
    formatBirthDay.getDate(),
  )
  // 年齢
  const age = today.getFullYear() - formatBirthDay.getFullYear()

  return today < thisYearBirthday ? age - 1 : age
}

// 配列操作
export const rejectBlank = (array) => array.filter((term) => term !== '')

export const rejectDuplicate = (array) =>
  array.filter((x, i, self) => self.indexOf(x) === i)

export const sortAsc = (array) =>
  array.sort((a, b) => {
    if (a < b) return -1
    if (a > b) return 1
    return 0
  })

export const storage = localStorage

export const altFormat = 'Y/m/d H:00'

export const combineContainer = (containers) => {
  const state: any[] = []
  const setAppState: any[] = []
  const setEntityState: any[] = []
  const setViewState: any[] = []
  const logic: any[] = []
  const api: any[] = []
  const bind: any[] = []

  containers.forEach((container) => {
    state.push(container.state)
    setAppState.push(container.setAppState)
    setEntityState.push(container.setEntityState)
    setViewState.push(container.setViewState)
    logic.push(container.logic)
    api.push(container.api)
    bind.push(container.bind)
  })
  return {
    state: Object.assign({}, ...state),
    setAppState: Object.assign({}, ...setAppState),
    setEntityState: Object.assign({}, ...setEntityState),
    setViewState: Object.assign({}, ...setViewState),
    logic: Object.assign({}, ...logic),
    api: Object.assign({}, ...api),
    bind: Object.assign({}, ...bind),
  }
}

export const getMonth = (date) => {
  // 0 to 11
  const momentMonth = moment(date).get('month')
  return momentMonth + 1
}

export const getDate = (date) => moment(date).get('date')

export const formatDate = (date) => moment(date).format('YYYY/M/D HH:mm')

// includeUnitはどこまでを含むかを指す
// 例) includeUnit = "date"の場合は年月日まで
export const formatDateExceptTimeToJp = (date, includeUnit = '') => {
  switch (includeUnit) {
    case 'year':
      return moment(date).format('YYYY年')
    case 'month':
      return moment(date).format('YYYY年M月')
    case 'date':
      return moment(date).format('YYYY年M月D日')
    case 'hour':
      return moment(date).format('YYYY年M月D日 HH')
    case 'minute':
      return moment(date).format('YYYY年M月D日 HH:mm')
    default:
      return moment(date).format('YYYY年M月D日')
  }
}

export const formatDateExceptTime = (date) => moment(date).format('YYYY/M/D')

export const formatRelativeDate = (date) => {
  const DAYS_OF_MONTH = 31
  const MONTHS_OF_YEAR = 12
  const startOfToday = moment(new Date()).startOf('day')
  const startOfTargetDate = moment(date).startOf('day')
  const diffDays = moment(startOfToday).diff(startOfTargetDate, 'days')
  const diffMonths = Math.floor(diffDays / DAYS_OF_MONTH)
  const diffYears = Math.floor(diffMonths / MONTHS_OF_YEAR)

  if (diffDays === 0) {
    return '今日'
  }
  // DAYS_OF_MONTHの値を含む
  if (diffDays / DAYS_OF_MONTH <= 1) {
    return `${diffDays}日前`
  }
  if (diffMonths > 0 && diffMonths < MONTHS_OF_YEAR) {
    return `${diffMonths}ヶ月前`
  }
  return `${diffYears}年前`
}

export const formatDateExceptYearAndTime = (date) =>
  moment(date).format('M月D日')

export const formatDateExceptMin = (date) =>
  moment(date).format('YYYY/M/D HH:00')

export const formatDateSynbol = (date) => date.split('/').join('-')

export const formatTimeByString = ({ hour, minute }) => {
  // HH:mmの形で返す
  const HH = `00${hour}`.slice(-2)
  const mm = `00${minute}`.slice(-2)
  return `${HH}:${mm}`
}

export const isToday = (date) => {
  const compareDate = new Date(date)
  const today = new Date()

  return (
    compareDate.getFullYear() === today.getFullYear() &&
    compareDate.getMonth() === today.getMonth() &&
    compareDate.getDate() === today.getDate()
  )
}

export const splitDate = (date) => {
  if (!date) {
    return {
      year: '',
      month: '',
      day: '',
      hour: '',
      min: '',
    }
  }

  const splited1 = date.split('/')
  const splited2 = splited1[2].split(' ')
  const splited3 = splited2[1].split(':')

  return {
    year: splited1[0],
    month: splited1[1],
    day: splited2[0],
    hour: splited3[0],
    min: splited3[1],
  }
}

export const getObjectDiff = (before, after) =>
  lodash.omitBy(after, (v, k) => before[k] === v)

export const downloadFile = (blob, filename) => {
  const url = URL.createObjectURL(blob)
  const linkEl = document.createElement('a')
  linkEl.href = url
  linkEl.setAttribute('download', filename)
  document.body.appendChild(linkEl)
  linkEl.click()
  URL.revokeObjectURL(url)
  if (linkEl.parentNode) {
    linkEl.parentNode.removeChild(linkEl)
  }
}

export const downloadCSVFile = (response) => {
  const contentDisposition = response.headers['content-disposition']
  const filename = decodeURI(contentDisposition.split("'").slice(-1)[0])
  const blob = new Blob([response.data], { type: 'text/csv' })
  downloadFile(blob, decodeURI(filename))
}

export const padding = (value, pad, c = '0') =>
  (c.repeat(pad) + value).substr(-pad)

export const range = (start, length) =>
  [...Array(length)].map((_, i) => i + start)

export const displayJpReadableNumber = (value, unit = '') => {
  if (value > 99999999) {
    return `9,999万${unit}+`
  }

  if (value > 9999) {
    return `${Math.floor(value / 1000).toLocaleString()}万${unit}`
  }

  return value.toLocaleString()
}

export const open = (url: string) =>
  window.open(url, '_blank', 'noreferrer,noopener')

export const calculateGeneration = (age: number): UserGeneration => {
  if (age <= 9) return 'child'
  if (10 <= age && age <= 19) return 'teens'
  if (20 <= age && age <= 29) return 'twenties'
  if (30 <= age && age <= 39) return 'thirties'
  if (40 <= age && age <= 49) return 'forties'
  if (50 <= age && age <= 59) return 'fifties'
  if (60 <= age && age <= 69) return 'sixties'
  if (70 <= age && age <= 79) return 'seventies'
  return 'eighties'
}

// react-flatpickrのFlatpickrコンポーネントでカレンダーで
// YYYY/MM/DD形式で日付選択をするためのオプション値
export const DATE_PICK_OPTION = {
  altInput: true,
  allowInput: true,
  locale: Japanese,
  altFormat: 'Y/m/d',
}

export const WEEK_OF_DAYS_LABEL = ['日', '月', '火', '水', '木', '金', '土']

// https://github.com/toypo/toypo-api/releases/tag/release-20220721-2
// 上記リリースによってお知らせとクーポンに対する開封数及び来店数が集計されるようになりました。
// それ以前は開封数や来店数などのデータは集計されていなかったため
// リリースより前に作られたコンテンツに関しては、リリース以後の値しか集計されておらず
// そのような中途半端な状態のデータをそのまま表示すると顧客に誤解を与えてしまいます。
// ただし、リリース以前に作ったコンテンツであっても、リリース以後に
// 何かしら閲覧や来店があって、その値が集計されている場合は
// 「リリース以後の集計値ですよ」という注意書きを出した上で値を表示しています。
//
// shouldMaskMeasurementValueやshouldShowMeasurementValueNoteは
// それらを判定するために利用してください。
// これらの関数は以下のルールに則って実装されています。
// 1. データ集計リリース以前に作られたコンテンツ
//   - 集計値が0の場合: "---" という風にマスクする
//   - 集計値が1以上: 注意書きメッセージと一緒に値を表示
// 2. データ集計リリース以後に作られたコンテンツ
//   - 注意書きメッセージは非表示で値だけ表示
export const MEASURE_EVALUATION_RELEASE_DATE = moment({
  years: 2022,
  months: 6,
  days: 20,
})
export const isBeforeMeasureEvaluationRelease = (createdAt) =>
  moment(createdAt).toDate() <= MEASURE_EVALUATION_RELEASE_DATE.toDate()
export const shouldMaskMeasurementValue = (createdAt, value) =>
  isBeforeMeasureEvaluationRelease(createdAt) && value === 0
export const shouldShowMeasurementValueNote = (createdAt, value) =>
  isBeforeMeasureEvaluationRelease(createdAt) && value > 0
export const measurementValueNote = (content, createdAt) =>
  `この${content}は${moment(createdAt).format('YYYY/MM/DD')}に作られていますが、` +
  `この値は${MEASURE_EVALUATION_RELEASE_DATE.format('YYYY/MM/DD')}以降の集計値です`
