import axios from 'axios'
import { loadAuth, removeAuth, setAuthFromHeader } from '../auth'
import { removeViewObj } from '../viewObj'
import { removeStaff } from '../staff'
import { removeOwner } from '../owner'

const apiClient = async ({
  method,
  uri,
  params = {},
  config = {},
  auth = null,
  responseType = 'json',
  skipSetAuth = false,
  timeout = 15000,
}) => {
  const client = axios.create({
    baseURL:
      process.env.REACT_APP_API_URL || 'https://api.dev.toypo.me/api/v2/',
    timeout: timeout,
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: responseType,
    withCredentials: true,
  })

  let nextAuth = {}
  if (!auth) {
    nextAuth = loadAuth()
  } else {
    nextAuth = auth
  }

  if (nextAuth?.client && nextAuth?.uid) {
    client.defaults.headers.common.Client = nextAuth.client
    client.defaults.headers.common.Uid = nextAuth.uid
  }

  try {
    let res
    switch (method) {
      case 'GET':
        res = await client.get(uri, params)
        break
      case 'POST':
        res = await client.post(uri, params, config)
        break
      case 'PATCH':
        res = await client.patch(uri, params, config)
        break
      case 'PUT':
        res = await client.put(uri, params, config)
        break
      case 'DELETE':
        res = await client.delete(uri, params)
        break
      default:
        throw new Error('unavailable method')
    }

    // token更新
    if (!skipSetAuth) {
      setAuthFromHeader(res.headers)
    }

    return res
  } catch (error) {
    console.log(error)

    /* eslint-disable no-throw-literal */
    if (error.response) {
      // token更新
      setAuthFromHeader(error.response.headers)

      switch (error.response.status) {
        case 400:
          throw {
            status: 400,
            message: `リクエストの内容が適していません。`,
            response: error.response,
          }
        case 401:
          removeAuth()
          removeViewObj()
          removeStaff()
          removeOwner()
          if (uri.indexOf('sign_in') === -1) {
            window.location.reload()
          }
          if (window?.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(
              `${JSON.stringify({ status: 401 })}`,
            )
          }
          throw {
            status: 401,
            message: `認証に失敗しました。ログインし直して下さい。`,
            response: error.response,
          }
        case 403:
          throw {
            status: 403,
            message: `リクエスト権限がありません。`,
            response: error.response,
          }
        case 404:
          throw {
            status: 404,
            message: `データが存在しません。`,
            response: error.response,
          }
        case 409:
          throw {
            status: 409,
            message: '連続で同じリクエストを検知しました。',
            response: error.response,
          }
        case 422:
          throw {
            status: 422,
            message: `リクエストの内容が適していません。`,
            response: error.response,
          }
        case 500:
          throw {
            status: 500,
            message: `大変申し訳ございません。サーバー側でのエラーが発生しております。迅速に解決いたしますので今しばらくお待ちください。今後ともToypoをよろしくお願いします。`,
            response: error.response,
          }
        default:
          throw {
            status: error.response.status,
            type: `エラー: ${error.response.status}`,
            message: `予期せぬエラーが発生しました...\nもう一度お試しください。`,
            response: error.response,
          }
      }
    } else if (error.request) {
      throw {
        type: 'ネットワークエラー',
        message: `ネットワークの接続を確認してください。`,
        status: null,
      }
    } else {
      throw {
        type: 'エラー',
        message: `予期せぬエラーが発生しました。\nもう一度お試しください。`,
        status: null,
      }
    }
    /* eslint-enable no-throw-literal */
  }
}

export default apiClient
