import React from 'react'
import { DiscountLogFilteringCondition } from '../../../containers/entities/DiscountEntityContainer'
import {
  ActionType,
  ActivityLogContentResponse,
  ContentType,
  ActivityLogContent,
} from '../../../types/activityLog'

type Props = {
  store
  discountLogFilteringCondition: DiscountLogFilteringCondition
  actionTypesToJp: (actionType: ActionType) => string
  contents: ActivityLogContentResponse
  actionTypeToContentType: (actionType: ActionType) => ContentType
  isDiscountLogFiltering: boolean
}

const style: { [key: string]: string | number } = {
  fontSize: 14,
  fontWeight: 'bold',
  backgroundColor: '#F0F0F0',
  padding: 5,
  borderRadius: 5,
  marginLeft: 5,
  marginBottom: 5,
}

const DiscountLogFilteringConditionElements: React.FC<Props> = ({
  store,
  discountLogFilteringCondition,
  actionTypesToJp,
  contents,
  actionTypeToContentType,
  isDiscountLogFiltering,
}) => {
  let content: ActivityLogContent | undefined

  const contentType =
    discountLogFilteringCondition?.action_types?.length === 1
      ? actionTypeToContentType(discountLogFilteringCondition?.action_types[0])
      : undefined

  if (contentType === 'coupon') {
    content = contents.coupons.find(
      (c) => c.id === discountLogFilteringCondition.content_id,
    )
  } else if (contentType === 'ticket') {
    content = contents.tickets.find(
      (c) => c.id === discountLogFilteringCondition.content_id,
    )
  } else if (contentType === 'subscription') {
    content = contents.subscriptions.find(
      (c) => c.id === discountLogFilteringCondition.content_id,
    )
  } else if (contentType === 'stamp') {
    content = contents.stamp_cards.find(
      (c) => c.id === discountLogFilteringCondition.content_id,
    )
  } else if (contentType === 'service') {
    content = contents.services.find(
      (c) => c.id === discountLogFilteringCondition.content_id,
    )
  }

  return (
    <div
      style={{
        marginLeft: window.innerWidth > 800 ? 15 : 0,
        display: 'flex',
        flexWrap: 'wrap',
      }}>
      <span
        style={{
          fontSize: 14,
          fontWeight: 'bold',
          marginRight: 5,
          marginTop: 5,
        }}>
        絞り込み条件:
      </span>

      {!isDiscountLogFiltering && <span style={style}>指定なし</span>}

      {!!discountLogFilteringCondition.store_ids.length &&
        Object.keys(store).length &&
        discountLogFilteringCondition.store_ids.map((id) => (
          <span key={id} style={style}>
            {
              (Object.keys(store).length
                ? [store, ...store?.child_stores]
                : []
              ).find((s) => s.id === id)?.name
            }
          </span>
        ))}

      {!!discountLogFilteringCondition.action_types.length &&
        discountLogFilteringCondition.action_types.map((action_type, i) => (
          <span key={i} style={style}>
            {actionTypesToJp(action_type)}
          </span>
        ))}

      {!!content && <span style={style}>{content.title}</span>}

      {discountLogFilteringCondition.status === 'cancel' && (
        <span style={style}>取り消したログ</span>
      )}

      {!!discountLogFilteringCondition.illegal_statuses.find(
        (status) => status === 'doubtful' || status === 'illegal',
      ) && <span style={style}>不正なログ</span>}
    </div>
  )
}
export default DiscountLogFilteringConditionElements
