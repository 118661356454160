import React from 'react'

type Props = {
  totalCount: number
  doneCount: number
}

const ProgressBar = ({ totalCount, doneCount }: Props) => {
  const percent = Math.floor((doneCount / totalCount) * 100)

  return (
    <>
      <div>
        <span style={{ color: '#36393F', fontSize: '16px' }}>
          完了したリスト
        </span>
        <span
          style={{
            marginLeft: '5px',
            fontWeight: 'bold',
            color: '#999999',
            fontSize: '18px',
          }}>
          <span style={{ color: '#487ED9' }}>{doneCount}</span>/{totalCount}
        </span>
      </div>

      {/* progress bar */}
      <div
        style={{
          width: '100%',
          height: '25px',
          backgroundColor: '#DFDFDF',
          marginTop: '10px',
        }}>
        <div
          style={{
            width: `${percent}%`,
            height: '100%',
            backgroundColor: '#487ED9',
          }}
        />
      </div>
    </>
  )
}

export default ProgressBar
