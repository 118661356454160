import React from 'react'
import { Row, Col, FormGroup } from 'react-bootstrap'

// components
import { Card } from '../../../components/Card/Card'
import SelectorForm from '../../../components/FormInputs/SelectorForm'
import FormInputs from '../../../components/FormInputs/FormInputs'

// containers
import {
  ServiceType,
  ServiceTypeToJp,
} from '../../../containers/entities/CouponContentEntityContainer'

type Props = {
  serviceTypes: ServiceType[]
  serviceTypeToJp: (serviceType: ServiceType) => ServiceTypeToJp
  register
  serviceType
  errors
  active: boolean
  resourceName
}

const DiscountFormCard: React.FC<Props> = ({
  serviceTypes,
  serviceTypeToJp,
  register,
  serviceType,
  errors,
  active,
  resourceName,
}) => (
  <Card
    title='割引設定'
    category='割引設定を行うと、割引管理ページで割引額が自動で計算されます。（現在、％割引は対応しておりません）'
    content={
      <div>
        <Row>
          <Col md={6} sm={6} xs={12}>
            <FormGroup bsSize='large'>
              <SelectorForm
                label=''
                name={`${resourceName}.service_type`}
                disabled={active}
                inputRef={register}
                options={[
                  {
                    value: '',
                    label: '未設定',
                  },
                  ...serviceTypes.map((s) => ({
                    value: s,
                    label: serviceTypeToJp(s),
                  })),
                ]}
              />
            </FormGroup>
          </Col>
        </Row>
        {(serviceType === 'discount' || serviceType === 'free') && (
          <Row>
            <Col md={6} sm={8} xs={12}>
              <FormInputs
                properties={[
                  {
                    name: `${resourceName}.service_value`,
                    disabled: active,
                    label: '',
                    ncol: 'col-md-9',
                    type: 'number',
                    bsClass: 'form-control',
                    placeholder: serviceType === 'free' ? '原価' : '',
                    inputRef: register({
                      required: '値を入力してください。',
                      max: {
                        value: 1000000,
                        message: '1000000以下の数字を入力してください。',
                      },
                    }),
                    // width: 150,
                    unit:
                      serviceType === 'free'
                        ? '円（原価）'
                        : serviceTypeToJp(serviceType),
                    validationMessage:
                      errors[resourceName]?.service_value?.message,
                  },
                ]}
              />
            </Col>
          </Row>
        )}
      </div>
    }
  />
)

export default DiscountFormCard
