import React from 'react'
import { styled, useTheme } from '@mui/material/styles'
import { Controller, useFormContext } from 'react-hook-form'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormHelperText from '@mui/material/FormHelperText'
import Flatpickr from 'react-flatpickr'
import { Japanese } from 'flatpickr/dist/l10n/ja'
import { formatDateExceptTime } from '../../../lib/general'
import { useLoginContext } from '../../../providers/LoginContextProvider'
import { CheckInFormType } from './CheckInModal'

const ToshibaTecPosCheckInForm = () => {
  const { control, errors } = useFormContext<CheckInFormType>()
  const theme = useTheme()
  const { currentStore } = useLoginContext()

  return (
    <>
      <StyledSectionContainer>
        <StyledSectionTitleContainer>
          <SectionTitle title='店舗' />
        </StyledSectionTitleContainer>

        <Controller
          control={control}
          name='checkInByPos.checkInStoreId'
          render={() => <Box>{currentStore.name}</Box>}
        />
      </StyledSectionContainer>

      <StyledSectionContainer>
        <StyledSectionTitleContainer>
          <SectionTitle title='営業日' />
        </StyledSectionTitleContainer>

        <Controller
          control={control}
          name='checkInByPos.settlementDate'
          rules={{ required: '営業日を入力してください' }}
          render={({ onChange, value }) => (
            <Box width={200}>
              <Flatpickr
                options={{
                  locale: Japanese,
                  altInput: true,
                  altFormat: 'Y-m-d',
                  allowInput: true,
                  maxDate: new Date(),
                }}
                value={value}
                onChange={(date: Date[]) =>
                  onChange(formatDateExceptTime(date[0]))
                }
              />
            </Box>
          )}
        />
        {errors.checkInByPos?.settlementDate?.message && (
          <FormHelperText error>
            {errors.checkInByPos?.settlementDate?.message}
          </FormHelperText>
        )}
        <Box
          mt={1.5}
          color={theme.palette.danger.main}
          fontSize={12}
          fontWeight={800}>
          ※精算データがあがっていない場合、翌日にチェックインが反映されます
        </Box>
      </StyledSectionContainer>

      <StyledSectionContainer>
        <StyledSectionTitleContainer>
          <SectionTitle title='レシートNo.' />
        </StyledSectionTitleContainer>

        <Controller
          control={control}
          name='checkInByPos.receiptNo'
          rules={{
            required: 'レシートNo.を入力してください',
            pattern: {
              value: /^[0-9]{4}$/,
              message: '半角数字4文字で入力してください',
            },
          }}
          render={({ onChange, value }) => (
            <OutlinedInput
              type='string'
              value={value}
              onChange={(v) => onChange(v.target.value)}
              inputProps={{
                maxLength: 4,
              }}
            />
          )}
        />
        {errors.checkInByPos?.receiptNo?.message && (
          <FormHelperText error>
            {errors.checkInByPos?.receiptNo?.message}
          </FormHelperText>
        )}
      </StyledSectionContainer>
    </>
  )
}

type SectionTitleProps = {
  title: string
  label?: string
}

const SectionTitle = ({ title, label }: SectionTitleProps) => (
  <StyledTitleContainer>
    <StyledContentTitle>{title}</StyledContentTitle>
    {label && <StyledLabel>{label}</StyledLabel>}
  </StyledTitleContainer>
)

const StyledSectionContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(3),
}))
const StyledSectionTitleContainer = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
}))
const StyledTitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}))
const StyledContentTitle = styled('div')({
  fontSize: 16,
  fontWeight: 'bold',
})
const StyledLabel = styled('div')(({ theme }) => ({
  backgroundColor: '#4678B2',
  color: 'white',
  fontWeight: 'bold',
  padding: theme.spacing(0.5, 1),
  borderRadius: 4,
}))

export default ToshibaTecPosCheckInForm
