import React from 'react'
// import { FormControl } from "react-bootstrap";
import SelectorForm from './SelectorForm'

type Props = {
  name?: string
  inputRef?: any
  value: string
  onChange: (time: string) => void
  type?: 'hour' | 'minute'
  minHour?: number
  maxHour?: number
  minuteIncrement?: number // minuteの幅
  validationMessage?: string
}

const TimeSelectBox: React.FC<Props> = ({
  name,
  inputRef,
  value,
  onChange,
  type,
  minHour = 0,
  maxHour = 24,
  minuteIncrement = 1,
}) =>
  type === 'hour' ? (
    <SelectorForm
      style={{ marginBottom: 0 }}
      name={name}
      inputRef={inputRef}
      width='auto'
      value={value}
      onSelect={({ target }: { target: any }) => onChange(target.value)}
      options={[
        // minHour=maxHourのとき、minHourに設定した数字のみが選択可能になる
        ...Array(Math.max(maxHour - minHour + 1, 0)),
      ].map((_, hour) => ({
        label: String(hour + minHour).padStart(2, '0'),
        value: String(hour + minHour).padStart(2, '0'),
      }))}
    />
  ) : (
    <SelectorForm
      style={{ marginBottom: 0 }}
      name={name}
      inputRef={inputRef}
      width='auto'
      value={value}
      onSelect={({ target }: { target: any }) => onChange(target.value)}
      options={[...Array(60)]
        .map((_, minute) => {
          if (minute % minuteIncrement === 0) {
            return {
              label: String(minute).padStart(2, '0'),
              value: String(minute).padStart(2, '0'),
            }
          } else {
            return null
          }
        })
        .filter((v) => v)}
    />
  )

export default TimeSelectBox
