import apiClient from './apiClient'
import { loadViewObj } from '../viewObj'

export const getTicketFeatureContent = async () => {
  const GET_TICKET_FEATURE = `/staff/stores/${
    loadViewObj()?.id
  }/ticket_features`

  const response = await apiClient({
    method: 'GET',
    uri: GET_TICKET_FEATURE,
  })
  return response.data
}

export const updateTicketFeatureContent = async ({ params }) => {
  const UPDATE_TICKET_FEATURE = `/staff/stores/${
    loadViewObj()?.id
  }/ticket_features`

  const requestParams = {
    ticket_feature: params,
  }

  const response = await apiClient({
    method: 'PATCH',
    uri: UPDATE_TICKET_FEATURE,
    params: requestParams,
  })

  return response
}
