/* eslint @typescript-eslint/camelcase: 0 */
/* eslint camelcase: 0 */
import { createContainer } from 'unstated-next'
import { storeStaffApi, ownerStoreStaffApi } from '../../lib/api'
import { isOwner } from '../../lib/staff'

export interface StoreStaffInterface {
  id: number
  name: string
  email: string
  push_token: string
  registerd: boolean
  stores: Store[]
  groups: Store[]
  owner: {
    id: number
    name: string
    role: string
  }[]
  created_at: Date
}

export type StoreStatusType = 'close' | 'open' | 'archive'
export type StoreType = 'child' | 'store_group' | 'owner_group'
export interface Store {
  id: number
  name: string
  role: string
  store_type: StoreType
  image_url: string
}

const useStoreStaffEntityHook = () => ({
  logic: {
    giveAuthToStaff: async (
      staffId: number,
      storeId: number,
      groupId: number,
    ): Promise<StoreStaffInterface> => {
      if (isOwner()) {
        const staff: StoreStaffInterface =
          await ownerStoreStaffApi.giveAuthToStaff({ storeId, staffId })
        return staff
      }
      const staff: StoreStaffInterface = await storeStaffApi.giveAuthToStaff({
        storeId,
        staffId,
        groupId,
      })
      return staff
    },
    removeAuthFromStaff: async (
      storeIds: Array<number>,
      staffId: number,
      groupId: number,
    ): Promise<StoreStaffInterface> => {
      if (isOwner()) {
        const staff: StoreStaffInterface =
          await ownerStoreStaffApi.removeAuthFromStaff({ storeIds, staffId })
        return staff
      }

      const storeId = storeIds[0] // オーナー権限ではない場合は一店舗のみが削除対象
      const staff: StoreStaffInterface =
        await storeStaffApi.removeAuthFromStaff({ storeId, staffId, groupId })
      return staff
    },
  },
})

export default createContainer(useStoreStaffEntityHook)
