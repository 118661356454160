import { useState } from 'react'
import { createContainer } from 'unstated-next'
import { validateEmail } from '../../lib/validation'

const usePasswordResetViewContainer = () => {
  const [formEmail, setFormEmail] = useState('')
  const [emailValidationMessage, setEmailValidationMessage] = useState('')

  const state = {
    formEmail,
    emailValidationMessage,
  }

  const setViewState = {
    initialize: (values) => {
      const { email } = values

      setFormEmail(email)
      setEmailValidationMessage('')
    },
    formEmail: setFormEmail,
    emailValidationMessage: setEmailValidationMessage,
  }

  const logic = {
    getInitialLogin: () => {
      return {
        email: '',
      }
    },
    handleFormChange: (name, value) => {
      setViewState[name](value)
    },
    getFormData: () => {
      return {
        email: formEmail,
      }
    },
    validate: ({ email }) => {
      const resultEmail = validateEmail(email)
      setViewState.emailValidationMessage(resultEmail.message)

      return resultEmail.validation
    },
  }
  return { state, setViewState, logic }
}

export default createContainer(usePasswordResetViewContainer)
