import { AxiosResponse } from 'axios'
import { useApi } from '../../hooks'
import { useNotification } from '../../../providers/NotificationProvider'
import { UserGender } from '../../../types/user'

export const useAdminApi = ({
  userId,
  onUpdated,
}: {
  userId: number
  onUpdated: () => void
}) => {
  const adminApi = useApi('admin')
  const { showSuccessNotification } = useNotification()

  const notifying =
    (successNotification: string) =>
    async (call: () => Promise<AxiosResponse<Response>>) => {
      const success = await call()
      if (success) {
        showSuccessNotification(successNotification)
        onUpdated()
      }

      return success
    }

  const scheduleWithdrawal = async (): Promise<AxiosResponse<Response>> => {
    if (!window.confirm('本当に退会させますか？')) {
      return Promise.reject()
    }

    return notifying('退会処理を予約しました')(async () =>
      adminApi.api.patch(`/users/${userId}/schedule_withdrawal`),
    )
  }

  const revertWithdrawal = async (): Promise<AxiosResponse<Response>> =>
    notifying('退会予約をキャンセルしました。')(async () =>
      adminApi.api.put(`/users/${userId}`, { left_at: null }),
    )

  const setGender = async (sex: UserGender): Promise<AxiosResponse<Response>> =>
    notifying('性別を設定しました')(async () =>
      adminApi.api.patch(`/users/${userId}`, { sex }),
    )

  const setBirthday = async (
    birthday: string,
  ): Promise<AxiosResponse<Response>> =>
    notifying('生年月日を設定しました')(async () =>
      adminApi.api.patch(`/users/${userId}`, { birthday }),
    )

  const useCoupon = async (couponId: number) =>
    notifying('クーポンを使用しました')(async () =>
      adminApi.api.patch(`/coupons/${couponId}/use`),
    )

  const archiveCoupon = (
    couponId: number,
  ): Promise<AxiosResponse<Response>> => {
    if (!window.confirm('本当に削除しますか？')) {
      return Promise.reject()
    }

    return notifying('クーポンを削除しました')(async () =>
      adminApi.api.patch(`/coupons/${couponId}/archive`),
    )
  }

  return {
    scheduleWithdrawal,
    revertWithdrawal,
    setGender,
    setBirthday,
    useCoupon,
    archiveCoupon,
    loading: adminApi.loading,
  }
}
