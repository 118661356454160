import { useState } from 'react'
import { createContainer } from 'unstated-next'
import { presentCouponContentApi } from '../../lib/api'

export type CouponContent = {
  id: number
  status: CouponStatusType
  age_from: number
  age_to: number
  benefit: string
  birth_month
  body: string
  created_at: string
  do_push: boolean
  expiration: string | null
  gender: 'male' | 'female' | 'other' | null
  has_usage_limit: boolean
  image_url: string
  publish_at: string | null
  published_number: number
  rank_ids: number[]
  terms: string
  important_notes: string
  user_ids: number[]
  service_type: ServiceType | null
  service_value: number | null
  enabled_auto_publish_condition: boolean
  enabled_target_user_condition: boolean
  target_user_condition?: CouponContentTargetUserCondition | null
  type
  questionnaire_url: string | null
  memo: string
}

type CouponContentTargetUserCondition = {
  age_from: number | null
  age_to: number | null
  birth_month: number | null
  coupon_content_id: number | null
  created_at: string
  gender: 'male' | 'female' | 'other' | null
  id: number
  notice_content_id: number | null
  rank: number | null
  rank_ids: number[]
  updated_at: string
  user_group_ids: number[]
  user_group_collection_id: number
  user_ids: number[]
  users_count: number
}

export type ServiceType = 'discount' | 'free' | 'other'

export const CouponStatus = {
  Unpublished: 'unpublished',
  Publish: 'publish',
  Preview: 'preview',
  Reservation: 'reservation',
} as const

export const CouponContentStatusToJpObj = {
  [CouponStatus.Unpublished]: '下書き',
  [CouponStatus.Preview]: '下書き',
  [CouponStatus.Reservation]: '配信予定',
  [CouponStatus.Publish]: '配信済み',
} as const

export type CouponStatusType = typeof CouponStatus[keyof typeof CouponStatus]

export const CouponStatusColors = {
  [CouponStatus.Unpublished]: '#b0b0b0',
  [CouponStatus.Preview]: '#b0b0b0',
  [CouponStatus.Reservation]: '#8577ff',
  [CouponStatus.Publish]: '#00ddb4',
} as const

export const AutoCouponContentStatusToJpObj = {
  [CouponStatus.Unpublished]: '無効',
  [CouponStatus.Publish]: '有効',
  [CouponStatus.Preview]: '無効',
} as const

export type ServiceTypeToJp = '円引き' | '無料交換' | 'その他'

export const ServiceTypes: ServiceType[] = ['discount', 'free', 'other']

const ServiceTypeToJpObj = {
  discount: '円引き',
  free: '無料交換',
  other: 'その他',
} as const

const useCouponContentEntityHook = () => {
  const [activeCoupons, setActiveCoupons] = useState<CouponContent[]>([])
  const [unactiveCoupons, setUnactiveCoupons] = useState<CouponContent[]>([])

  return {
    state: { activeCoupons, unactiveCoupons },
    logic: {
      setActiveCoupons,
      setUnactiveCoupons,
      getPresentCouponContent: async (id: number): Promise<CouponContent> => {
        const res = await presentCouponContentApi.getPresentCouponContent(id)
        const coupon = res.data
        return coupon
      },
      publishPresentCouponContent: async ({ id, params }) => {
        const coupon = await presentCouponContentApi.publishCoupon({
          coupon_id: id,
          params: params,
        })
        return coupon
      },
      createPresentCouponContent: async (params) => {
        const coupon = await presentCouponContentApi.createPresentCouponContent(
          {
            params: params,
          },
        )
        return coupon
      },
      updatePresentCouponContent: async ({ id, params }) => {
        const coupon = await presentCouponContentApi.updatePresentCoupon({
          coupon_id: id,
          params: params,
        })
        return coupon
      },
      serviceTypeToJp: (serviceType: ServiceType): ServiceTypeToJp =>
        ServiceTypeToJpObj[serviceType],
      statusToJp: (
        coupon: CouponContent,
      ): '配信済み' | '配信予定' | '下書き' => {
        switch (coupon.status) {
          case CouponStatus.Publish:
            return '配信済み'
          case CouponStatus.Reservation:
            return '配信予定'
          default:
            return '下書き'
        }
      },
      statusLabelBackgroundColor: (coupon: CouponContent): string => {
        switch (coupon.status) {
          case CouponStatus.Publish:
            return '#00ddb4'
          case CouponStatus.Reservation:
            return '#8577ff'
          default:
            return '#b0b0b0'
        }
      },
    },
  }
}

export default createContainer(useCouponContentEntityHook)
