import React from 'react'
import { styled } from '@mui/system'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import { useHistory, useLocation } from 'react-router-dom'
import { useBooleanState } from '../../../lib/hooks'

type Props = {
  icon: string
  label: string
  path?: string
  children?: React.ReactNode
}

const MenuGroup = ({ icon, label, path, children }: Props): JSX.Element => {
  const history = useHistory()
  const { pathname } = useLocation()
  const expandable = Boolean(children)
  const active =
    (path && pathname.indexOf(path) > -1) ||
    React.Children.toArray(children).some(
      (child) =>
        pathname.indexOf((child as React.ReactElement).props.path) > -1,
    )
  const [expanded, , , toggleExpanded] = useBooleanState(active)

  const handleClick = () => {
    if (path) {
      history.push(path)
    } else {
      toggleExpanded()
    }
  }

  return (
    <>
      <StyledMenuButton
        fullWidth
        onClick={handleClick}
        active={active}
        startIcon={<MenuIcon icon={icon} />}
        endIcon={expandable ? <Chevron expanded={expanded} /> : null}>
        {label}
      </StyledMenuButton>

      <Collapse in={expanded}>{children}</Collapse>
    </>
  )
}

const MenuIcon = ({ icon }) => <i className={icon} style={{ fontSize: 20 }} />

const Chevron = ({ expanded }) => {
  const className = `ri-arrow-${expanded ? 'up' : 'down'}-s-line`
  return <i className={className} style={{ fontSize: 16 }} />
}

const StyledMenuButton = styled(Button, {
  shouldForwardProp: (name: string) => name !== 'active',
})<{ active: boolean }>(({ active }) => ({
  fontSize: 14,
  padding: '10px 20px',
  justifyContent: 'flex-start',
  ...(active
    ? {
        color: '#fff',
        fontWeight: 700,
        backgroundColor: '#2F3746',
      }
    : {
        color: '#D4D9E1',
        fontWeight: 400,
        backgroundColor: 'transparent',
      }),
  '&:hover': {
    backgroundColor: '#262F3E',
  },
  '& .MuiButton-startIcon': {
    marginRight: 12,
    marginLeft: 0,
  },
  '& .MuiButton-endIcon': {
    flex: 1,
    textAlign: 'right',
    display: 'block',
  },
}))

export default MenuGroup
