import React from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@mui/material'
import { useHistory } from 'react-router'
import { useApi } from '../../../lib/hooks'
import { useNotification } from '../../../providers/NotificationProvider'
import DialogTitle from '../../../components/DialogTitle'

type Props = {
  rankingId: number
  open: boolean
  onClose: () => void
  status: string
}

const RankingRestartModal = ({ rankingId, open, onClose, status }: Props) => {
  const { showSuccessNotification } = useNotification()
  const api = useApi()
  const history = useHistory()

  const restartRanking = async () => {
    const response = await api.api.patch(`/rankings/${rankingId}`, {
      status: 'active',
    })
    if (response !== null) {
      showSuccessNotification('ランキングの公開を再開しました。')
      onClose()
      history.goBack()
    }
  }

  return (
    <Dialog fullWidth open={open} onClose={onClose} maxWidth='sm'>
      <DialogTitle onClose={onClose}>ランキングの公開を再開</DialogTitle>
      <DialogContent>
        <Box>このランキングの公開を再開してよろしいですか？</Box>
      </DialogContent>
      <DialogActions sx={{ pt: 2 }}>
        <Button
          variant='contained'
          fullWidth
          style={{ background: '#BDBDBD' }}
          onClick={onClose}>
          キャンセル
        </Button>
        <Button
          variant='contained'
          fullWidth
          color='success'
          onClick={restartRanking}>
          公開を再開する
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RankingRestartModal
