import React from 'react'
import moment from 'moment'
import Box from '@mui/material/Box'
import Switch from '@mui/material/Switch'
import Button from '@mui/material/Button'
import { useForm, Controller } from 'react-hook-form'
import { useHistory } from 'react-router'
import Flatpickr from 'react-flatpickr'
import { Japanese } from 'flatpickr/dist/l10n/ja'
import FormControlLabel from '@mui/material/FormControlLabel'
import OutlinedInput from '@mui/material/OutlinedInput'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import MuiAlert from '@mui/material/Alert'
import { HelpBlock } from 'react-bootstrap'
import {
  DisplayErrorNotification,
  DisplaySuccessNotification,
} from '../../../types/common.d'
import { useApi, useGetApiCall, useBooleanState } from '../../../lib/hooks'
import { altFormat } from '../../../lib/general'
import Section from '../../../components/Section'
import FormLabel from '../../../components/FormInputs/FormLabel'
import TimeForm from '../../../components/FormInputs/TimeForm'
import RankingStopModal from './RankingStopModal'
import RankingRestartModal from './RankingRestartModal'
import { compareValidateDate, validateDate } from '../../../lib/validation'
import { useNotification } from '../../../providers/NotificationProvider'
import { useLoginContext } from '../../../providers/LoginContextProvider'
import { MembersCardContentInterface } from '../../../containers/entities/MembersCardContentEntityContainer'

type RankingContentFormType = {
  name: string
  start_datetime: string
  end_datetime: string
  period_in_months: number
  period_in_weeks: number
  period_in_days: number
  create_ranking_before_days: number
  push_notification_title: string
  push_notification_time: string
  status: string
  description: string
  category: string
  show_total_users: boolean
  per_page: number
  rewards: string[]
  daily_score_limit: number
  daily_limit_reset_at: string
}

type Target = 'ranking' | 'rankingContent'

type Props = {
  periodic: boolean
  target: Target
  defaultRankingContent?: any
  defaultRanking?: any
  displayErrorNotification: DisplayErrorNotification
  displaySuccessNotification: DisplaySuccessNotification
  rankingStarted: boolean
}

const Categories = [
  { id: 'check_in', label: 'チェックイン数', unit: '回', requires: [] },
  {
    id: 'point',
    label: 'ポイント獲得数',
    unit: 'pt',
    requires: ['membersCard', 'pointEnabled'],
  },
  { id: 'stamp', label: 'スタンプ獲得数', unit: '個', requires: ['stampCard'] },
]

const DailyLimitLisetTime = [
  { label: '24:00', value: '00:00' },
  { label: '25:00', value: '01:00' },
  { label: '26:00', value: '02:00' },
  { label: '27:00', value: '03:00' },
  { label: '28:00', value: '04:00' },
  { label: '29:00', value: '05:00' },
  { label: '30:00', value: '06:00' },
  { label: '31:00', value: '07:00' },
  { label: '32:00', value: '08:00' },
  { label: '33:00', value: '09:00' },
  { label: '34:00', value: '10:00' },
]

const RankingContentForm = ({
  periodic,
  target,
  defaultRankingContent,
  defaultRanking,
  displayErrorNotification,
  displaySuccessNotification,
  rankingStarted,
}: Props): JSX.Element => {
  const api = useApi()
  const history = useHistory()
  const { showErrorNotification } = useNotification()
  const { hasFunction } = useLoginContext()
  const membersCardContentApi = useGetApiCall<MembersCardContentInterface>(
    '/members_card_contents',
  )
  const membersCardContent = membersCardContentApi?.response
  const DefaultRankingContentValues = {
    name: 'ランキング',
    start_datetime: moment()
      .add(1, 'days')
      .set({ hour: 12, minute: 0 })
      .format('YYYY/MM/DD HH:mm'),
    end_datetime: moment()
      .add(1, 'days')
      .add(3, 'months')
      .set({ hour: 12, minute: 0 })
      .format('YYYY/MM/DD HH:mm'),
    period_in_months: periodic ? 3 : 0,
    period_in_weeks: 0,
    period_in_days: 0,
    create_ranking_before_days: periodic ? 1 : 0,
    push_notification_title:
      'ランキングの順位が確定しました！結果を確認しましょう👑',
    push_notification_time: '12:00',
    status: 'inactive',
    description: '',
    category: 'point',
    show_total_users: true,
    per_page: 50,
    rewards: [],
    publish_reward_time: '12:00',
    reward_description:
      '特典は集計期間終了日翌日の0:00に自動的に集計され、「クーポン」または「チケット」の画面で確認できます',
    daily_score_limit: 1,
    daily_limit_reset_at: '00:00',
  }

  const filterCategories = (
    membersCardEnabled: boolean,
    stampCardEnabled: boolean,
    pointEnabled: boolean,
  ) => {
    return Categories.filter((category) => {
      const { requires } = category
      return (
        (!requires.includes('membersCard') || membersCardEnabled) &&
        (!requires.includes('stampCard') || stampCardEnabled) &&
        (!requires.includes('pointEnabled') || pointEnabled)
      )
    })
  }

  const defaultValues =
    target === 'rankingContent'
      ? {
          ...(defaultRankingContent || DefaultRankingContentValues),
          push_notification_time:
            DefaultRankingContentValues.push_notification_time ||
            moment(defaultRankingContent.push_notification_time).format(
              'HH:mm',
            ),
        }
      : {
          ...defaultRanking,
          push_notification_time: moment(
            defaultRanking.push_notification_time,
          ).format('HH:mm'),
        }

  const { control, watch, handleSubmit, errors } =
    useForm<RankingContentFormType>({ defaultValues })
  const watchValues = watch()
  const [isOpenRankingStopModal, openRankingStopModal, closeRankingStopModal] =
    useBooleanState(false)
  const [
    isOpenRankingRestartModal,
    openRankingRestartpModal,
    closeRankingRestartModal,
  ] = useBooleanState(false)

  // 選択したCategoryに応じてunitを変更
  const getCategoryUnit = (categoryId: string): string => {
    const category = Categories.find((c) => c.id === categoryId)
    return category?.unit || '回'
  }
  const watchCategory = watch('category')
  const currentCategoryUnit = getCategoryUnit(watchCategory)

  const createRankingContent = async (
    formValues: Partial<RankingContentFormType>,
  ) => {
    const response = await api.api
      .post('/ranking_contents', { ...defaultValues, ...formValues })
      .catch((error) => {
        displayErrorNotification(error.message)
      })
    if (!response) return

    displaySuccessNotification('ランキングを作成しました。')
    history.goBack()
  }

  const updateRanking = async (formValues: Partial<RankingContentFormType>) => {
    const response = await api.api
      .patch(`/rankings/${defaultRanking?.id}`, {
        ...defaultValues,
        ...formValues,
      })
      .catch((error) => {
        displayErrorNotification(error.message)
      })
    if (!response) return

    displaySuccessNotification('ランキング設定を更新しました。')
    history.goBack()
  }

  const updateRankingContent = async (
    formValues: Partial<RankingContentFormType>,
  ) => {
    const response = await api.api
      .patch(`/ranking_contents/${defaultRankingContent?.id}`, {
        ...defaultValues,
        ...formValues,
      })
      .catch((error) => {
        displayErrorNotification(error.message)
      })
    if (!response) return

    displaySuccessNotification('ランキング設定を更新しました。')
    history.goBack()
  }

  const rankingStopHandler = () => openRankingStopModal()
  const rankingRestartHandler = () => openRankingRestartpModal()

  const { period_in_months } = watchValues
  const firstStart = moment(watchValues.start_datetime, 'YYYY/MM/DD HH:mm')
  const firstEnd = firstStart
    .clone()
    .add(period_in_months, 'months')
    .subtract(1, 'seconds')
  const nextStart = firstEnd.clone().add(1, 'seconds')
  const nextEnd = nextStart
    .clone()
    .add(period_in_months, 'months')
    .subtract(1, 'seconds')
  const isEdit = !!defaultRankingContent || !!defaultRanking
  const isMembersCardEnabled = hasFunction('members_card')
  const isStampCardEnabled = hasFunction('stamp_card')
  const isPointEnabled = membersCardContent?.point_enabled || false
  return (
    <>
      {periodic && target === 'rankingContent' && (
        <>
          <MuiAlert severity='warning' sx={{ mt: 2, mx: 2 }}>
            変更内容は発行前のランキングにのみ適用されます。発行済みのランキングには適用されませんのでご注意ください。
          </MuiAlert>
          <Section title='定期ランキング設定'>
            <Box mb={2} sx={{ maxWidth: 400 }}>
              <FormLabel label='開始日時' />
              <Controller
                control={control}
                name='start_datetime'
                rules={
                  !isEdit
                    ? {
                        validate: (data): boolean | string => {
                          const startDatetimeValidationResult =
                            validateDate(data)
                          if (!startDatetimeValidationResult.validation) {
                            showErrorNotification(
                              startDatetimeValidationResult.message,
                            )
                            return startDatetimeValidationResult.message
                          }
                          return true
                        },
                        required: '開始日時を入力してください。',
                      }
                    : undefined
                }
                render={({ onChange, value }) => (
                  <>
                    <Flatpickr
                      name='start_datetime'
                      data-enable-time
                      value={value}
                      disabled={rankingStarted}
                      options={{
                        altInput: true,
                        allowInput: true,
                        locale: Japanese,
                        altFormat: altFormat,
                        minuteIncrement: 0,
                      }}
                      onChange={(e) =>
                        onChange(e.toLocaleString().slice(0, -3))
                      }
                    />
                  </>
                )}
              />
              <HelpBlock className='text-danger'>
                {errors?.start_datetime?.message}
              </HelpBlock>
            </Box>
            <Box mb={4} sx={{ maxWidth: 400 }}>
              <FormLabel label='周期' />
              <Controller
                control={control}
                name='period_in_months'
                render={({ onChange, value }) => (
                  <OutlinedInput
                    type='number'
                    fullWidth
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    placeholder='3'
                    sx={{ maxWidth: 100 }}
                  />
                )}
              />
              　ヶ月ごと
              <Box sx={{ marginTop: 1 }}>
                初回の期間：{firstStart.format('YYYY/MM/DD HH:mm')} 〜{' '}
                {firstEnd.format('YYYY/MM/DD HH:mm')}
              </Box>
              <Box sx={{ marginTop: 1 }}>
                次回の期間：{nextStart.format('YYYY/MM/DD HH:mm')} 〜{' '}
                {nextEnd.format('YYYY/MM/DD HH:mm')}
              </Box>
            </Box>
            <Box mb={4} sx={{ maxWidth: 400 }}>
              <FormLabel label='定期ランキング自動発行日' />
              開始前の　
              <Controller
                control={control}
                name='create_ranking_before_days'
                render={({ onChange, value }) => (
                  <OutlinedInput
                    type='number'
                    fullWidth
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    placeholder='3'
                    sx={{ maxWidth: 100 }}
                  />
                )}
              />
              　日前
            </Box>
          </Section>
        </>
      )}

      <Section title='基本設定'>
        {(!periodic || target === 'ranking') && (
          <Box mb={2} sx={{ maxWidth: 400 }}>
            <FormLabel label='ランキング期間' />
            <Box alignItems='center' sx={{ display: 'flex', gap: 2 }}>
              <Controller
                control={control}
                name='start_datetime'
                rules={
                  !isEdit
                    ? {
                        validate: (data): boolean | string => {
                          const startDatetimeValidationResult =
                            validateDate(data)
                          if (!startDatetimeValidationResult.validation) {
                            showErrorNotification(
                              startDatetimeValidationResult.message,
                            )
                            return startDatetimeValidationResult.message
                          }
                          return true
                        },
                        required: '開始日時を入力してください。',
                      }
                    : undefined
                }
                render={({ onChange, value }) => (
                  <Flatpickr
                    name='start_datetime'
                    data-enable-time
                    value={value}
                    disabled={rankingStarted}
                    options={{
                      altInput: true,
                      allowInput: true,
                      locale: Japanese,
                      altFormat: altFormat,
                      minuteIncrement: 0,
                    }}
                    onChange={(e) => onChange(e.toLocaleString().slice(0, -3))}
                    style={{ maxWidth: 100 }}
                  />
                )}
              />
              〜
              <Controller
                control={control}
                name='end_datetime'
                rules={
                  !isEdit
                    ? {
                        validate: (data): boolean | string => {
                          const endDatetimeValidationResult = validateDate(data)
                          const compareValidationDateResult =
                            compareValidateDate(
                              watchValues.start_datetime,
                              data,
                            )
                          if (!endDatetimeValidationResult.validation) {
                            showErrorNotification(
                              endDatetimeValidationResult.message,
                            )
                            return endDatetimeValidationResult.message
                          }
                          if (!compareValidationDateResult.validation) {
                            showErrorNotification(
                              '開始日よりも過去の時刻を設定することはできません。',
                            )
                            return '開始日よりも過去の時刻を設定することはできません。'
                          }
                          return true
                        },
                        required: '終了日時を入力してください。',
                      }
                    : undefined
                }
                render={({ onChange, value }) => (
                  <Flatpickr
                    name='end_datetime'
                    data-enable-time
                    value={value}
                    options={{
                      altInput: true,
                      allowInput: true,
                      locale: Japanese,
                      altFormat: altFormat,
                      minuteIncrement: 0,
                    }}
                    onChange={(e) => onChange(e.toLocaleString().slice(0, -3))}
                    style={{ maxWidth: 100 }}
                  />
                )}
              />
            </Box>
            <HelpBlock className='text-danger'>
              <Box>{errors?.start_datetime?.message}</Box>
              <Box>{errors?.end_datetime?.message}</Box>
            </HelpBlock>
          </Box>
        )}
        <Box mb={2}>
          <FormLabel label='ランキング名' />
          <Controller
            control={control}
            name='name'
            render={({ onChange, value }) => (
              <OutlinedInput
                fullWidth
                value={value}
                onChange={(e) => onChange(e.target.value)}
                placeholder='ランキング'
              />
            )}
          />
        </Box>
        <Box mb={2}>
          <FormLabel label='ランキングの詳細説明' />
          <Controller
            control={control}
            name='description'
            render={({ onChange, value }) => (
              <OutlinedInput
                fullWidth
                multiline
                rows={5}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                placeholder='ランキングの詳細説明'
              />
            )}
          />
        </Box>
        <Box mb={2} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <FormLabel label='ランキング計測対象' />
          <Controller
            control={control}
            name='category'
            render={({ onChange, value }) => (
              <Select
                value={value}
                sx={{ mr: 2, width: 400 }}
                onChange={({ target }) => {
                  onChange(target.value)
                }}
                disabled={rankingStarted}>
                {filterCategories(
                  isMembersCardEnabled,
                  isStampCardEnabled,
                  isPointEnabled,
                ).map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.label}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Box>
        <Box mb={2} sx={{ maxWidth: 600 }}>
          <FormLabel label='1日あたりのランキング計測の制限' />
          <Box
            mb={2}
            alignItems='center'
            sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            1日
            <Controller
              control={control}
              name='daily_score_limit'
              render={({ onChange, value }) => (
                <OutlinedInput
                  type='number'
                  fullWidth
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  placeholder='1'
                  sx={{ maxWidth: 100 }}
                />
              )}
            />
            {currentCategoryUnit}
            <Controller
              control={control}
              name='daily_limit_reset_at'
              render={({ onChange, value }) => (
                <Select
                  value={value}
                  sx={{ mr: 2, width: 100, height: 40 }}
                  onChange={({ target }) => {
                    onChange(target.value)
                  }}>
                  {DailyLimitLisetTime.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            にリセット
          </Box>
        </Box>
        <Box mb={2}>
          <FormLabel label='ランキング参加者数の表示' />
          <Controller
            control={control}
            name='show_total_users'
            render={({ onChange, value }) => (
              <RadioGroup
                row
                value={value}
                onChange={(e) => onChange(e.target.value === 'true')}>
                <FormControlLabel value control={<Radio />} label='表示する' />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label='表示しない'
                />
              </RadioGroup>
            )}
          />
        </Box>
      </Section>

      <Section title='ランキング確定通知設定'>
        <Box mb={2}>
          <FormLabel label='プッシュ通知タイミング' />
          ランキング期間終了日から1日後の　
          <Controller
            control={control}
            name='push_notification_time'
            render={({ onChange, value }) => (
              <TimeForm
                value={value}
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
        </Box>

        <Box mb={2}>
          <FormLabel label='プッシュ通知メッセージ' />
          <Controller
            control={control}
            name='push_notification_title'
            render={({ onChange, value }) => (
              <OutlinedInput
                fullWidth
                value={value}
                onChange={(e) => onChange(e.target.value)}
                placeholder='ランキングの順位が確定しました！結果を確認しましょう👑'
              />
            )}
          />
        </Box>
      </Section>

      <Section title='特典設定'>
        <Box mb={2}>
          <FormLabel label='特典に関する注意事項' />
          <Controller
            control={control}
            name='reward_description'
            render={({ onChange, value }) => (
              <OutlinedInput
                fullWidth
                multiline
                rows={5}
                value={value}
                onChange={(e) => onChange(e.target.value)}
                placeholder='特典に関する注意事項'
              />
            )}
          />
        </Box>
      </Section>

      {periodic && (
        <Section title='有効設定'>
          <Box>
            <Controller
              control={control}
              name='status'
              render={({ onChange, value }) => (
                <FormControlLabel
                  control={
                    <Switch
                      checked={value === 'active'}
                      onChange={(e) =>
                        onChange(e.target.checked ? 'active' : 'inactive')
                      }
                    />
                  }
                  sx={{ color: value === 'active' ? undefined : '#aaa' }}
                  label='有効'
                />
              )}
            />
          </Box>
        </Section>
      )}

      {target === 'ranking' && defaultValues.status === 'active' && (
        <Section>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              color='error'
              variant='outlined'
              sx={{ width: 300, m: 1 }}
              onClick={rankingStopHandler}>
              このランキングを停止する
            </Button>
          </div>
        </Section>
      )}

      {target === 'ranking' && defaultValues.status === 'stopped' && (
        <Section>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              color='success'
              variant='outlined'
              sx={{ width: 300, m: 1 }}
              onClick={rankingRestartHandler}>
              このランキングを再開する
            </Button>
          </div>
        </Section>
      )}

      <Box display='flex' justifyContent='center' mb={5}>
        <Button
          color='cancel'
          variant='contained'
          sx={{ width: 300, m: 1 }}
          onClick={history.goBack}>
          もどる
        </Button>
        <Button
          variant='contained'
          color='submit'
          sx={{ width: 300, m: 1 }}
          onClick={handleSubmit(
            target === 'rankingContent' && isEdit
              ? updateRankingContent
              : target === 'rankingContent' && !isEdit
                ? createRankingContent
                : updateRanking,
          )}>
          {isEdit ? '変更を保存する' : '作成する'}
        </Button>
      </Box>

      <RankingStopModal
        rankingId={defaultRanking?.id}
        open={isOpenRankingStopModal}
        onClose={() => {
          closeRankingStopModal()
        }}
        status={defaultValues.status}
      />
      <RankingRestartModal
        rankingId={defaultRanking?.id}
        open={isOpenRankingRestartModal}
        onClose={() => {
          closeRankingRestartModal()
        }}
        status={defaultValues.status}
      />
    </>
  )
}

export default RankingContentForm
