import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { OrderStatus, OrderResource, OrderBlock } from '../../../types/order'
import { getOrderStatusLabel } from '../../../constants/order'
import { formatDate } from '../../../lib/general'
import OrderStatusChip from '../../../components/Chip/OrderStatusChip'
import { calculateNextStatus } from '../utils'

type Props = {
  orders: OrderBlock[]
  onClickOrder: (order: OrderResource) => void
  onClickMenu: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    order: OrderResource,
  ) => void
  onUpdateStatus: (id: number, status: OrderStatus) => void
}

const MobileEcOrderListTable = ({
  orders,
  onClickOrder,
  onClickMenu,
  onUpdateStatus,
}: Props): JSX.Element => {
  return (
    <div>
      {orders.map(({ order, menus }) => {
        const nextStatus = calculateNextStatus(order.order_type, order.status)

        return (
          <Box key={order.id} p={1} borderBottom='solid 1px #ddd'>
            <div onClick={() => onClickOrder(order)}>
              <Box
                display='flex'
                alignItems='center'
                justifyContent='space-between'>
                <OrderStatusChip
                  orderType={order.order_type}
                  status={order.status}
                />

                <IconButton
                  onClick={(e) => {
                    e.stopPropagation()
                    onClickMenu(e, order)
                  }}>
                  <i className='ri-more-fill' />
                </IconButton>
              </Box>

              <strong>
                <div>注文日時: {formatDate(order.created_at)}</div>
                <div>注文番号: {order.order_number}</div>
              </strong>

              <Box mb={1}>備考 : {order.description || 'なし'}</Box>

              <Box
                display='flex'
                flexDirection='column'
                gap={1}
                fontSize={13}
                mb={1}>
                {menus.map((menu) => (
                  <Box
                    key={menu.order_menu_id}
                    bgcolor='#f0f0f0'
                    borderRadius={1}
                    px={1}
                    py={0.5}>
                    <Box
                      display='flex'
                      justifyContent='space-between'
                      alignItems='center'
                      fontWeight={700}>
                      <Box mr={1}>{menu.title}</Box>
                      <div>x {menu.quantity}</div>
                    </Box>
                    {menu.option_items.map((item) => (
                      <Box key={item.id} fontSize={12}>
                        - {item.title}
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box>
            </div>

            {nextStatus && (
              <Button
                variant='contained'
                sx={{ backgroundColor: '#87cb16' }}
                fullWidth
                onClick={() => {
                  const label = getOrderStatusLabel(
                    order.order_type,
                    nextStatus,
                  )
                  const message = `注文のステータスを${label}に更新しますか`
                  if (window.confirm(message)) {
                    onUpdateStatus(order.id, nextStatus)
                  }
                }}>
                {getOrderStatusLabel(order.order_type, nextStatus)}にする
              </Button>
            )}
          </Box>
        )
      })}
    </div>
  )
}

export default MobileEcOrderListTable
