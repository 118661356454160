import { createContainer } from 'unstated-next'
import { privilegeCouponApi } from '../../lib/api'

export type PrivilegeCouponType = {
  id: number
  category: PrivilegeCouponCategoryType
  benefit: string
  body: string
  terms: string
  important_notes: string
  image: string
  active: boolean
  status: CouponStatusType
  image_url: string
  do_push: boolean
  has_usage_limit: boolean
  published_number: number
  expiration_day: number | null
  expiration_time: number | null
  service_type: ServiceType | null
  service_value: number | null
  threshold_point: number | null // Only stamp
  published_max_number: number | null // Only code
  qrcode_url: string // Only code
  multiple_get_enabled: boolean | null // Only code
  questionnaire_id: number | null
  [key: string]: number | string | boolean | undefined | null
  rank_id?: number | null
  memo: string
  start_rank?: number | null
  end_rank?: number | null
  user_content_usage_method: string
  auto_use_timer_minutes: number
  barcode_type: string
  coupon_code: string
}
export type PrivilegeCouponCategoryType =
  | 'stamp'
  | 'code'
  | 'point'
  | 'auto_point'
  | 'rank'
  | 'ranking'
  | undefined

export const CouponStatus = {
  Unpublished: 'unpublished',
  Publish: 'publish',
  Preview: 'preview',
} as const

export type CouponStatusType = typeof CouponStatus[keyof typeof CouponStatus]

const couponStatuses: CouponStatusType[] = ['unpublished', 'publish', 'preview']

const couponToJpObj = {
  unpublished: '無効',
  publish: '有効',
  preview: 'プレビュー',
}

export const privilegeCouponStatusToJp = (status: CouponStatusType): string =>
  couponToJpObj[status]

export type ServiceType = 'discount' | 'free' | 'other'

export type ServiceTypeToJp = '円引き' | '無料交換' | 'その他'

const serviceTypes: ServiceType[] = ['discount', 'free', 'other']

const ServiceTypeToJpObj = {
  discount: '円引き',
  free: '無料交換',
  other: 'その他',
} as const

export const serviceTypeToJp = (serviceType: ServiceType): ServiceTypeToJp =>
  ServiceTypeToJpObj[serviceType]

const usePrivilegeCouponContentEntityHook = () => ({
  constant: { serviceTypes, couponStatuses },
  logic: {
    createPrivilegeCoupon: async (params: PrivilegeCouponType) => {
      const privilegeCoupon = await privilegeCouponApi.createPrivilegeCoupon({
        params,
      })
      return privilegeCoupon
    },
    updatePrivilegeCoupon: async ({ id, params }: { id: number; params }) => {
      const privilegeCoupon = await privilegeCouponApi.updatePrivilegeCoupon({
        id,
        params,
      })
      return privilegeCoupon
    },
  },
})

export default createContainer(usePrivilegeCouponContentEntityHook)
