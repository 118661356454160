import React, { FC, memo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { Card } from '../../../components/Card/Card'
import SwitchForm from '../../../components/FormInputs/SwitchForm'
import LoadingButton from '../../../components/CustomButton/LoadingButton'
import {
  NoticeContent,
  NoticeStatus,
  NoticeStatusType,
} from '../../../containers/entities/NoticeEntityContainer'

type Props = {
  notice?: NoticeContent
  editMode: boolean
  onClickPublishAutoPublishNoticeButton: (
    status: NoticeStatusType,
  ) => Promise<void>
}

const AutoPublishNoticeActiveSettingCard: FC<Props> = ({
  notice,
  editMode,
  onClickPublishAutoPublishNoticeButton,
  ...otherProps
}) => {
  const { setValue, watch } = useFormContext<{
    notice: NoticeContent & { image: string }
  }>()

  const watchStatus = watch('notice.status') as NoticeStatusType

  return (
    <>
      <Row {...otherProps}>
        <Col md={12}>
          <Card
            title='有効設定'
            content={
              <>
                <Row>
                  <Col md={12}>
                    <SwitchForm
                      horizontal
                      formMarginBottom={8}
                      checked={watchStatus === NoticeStatus.Publish}
                      onChange={(checked: boolean): void => {
                        setValue(
                          'notice.status',
                          checked
                            ? NoticeStatus.Publish
                            : NoticeStatus.Unpublished,
                          { shouldDirty: true },
                        )
                      }}
                    />
                  </Col>
                </Row>

                {editMode && (
                  <Row style={{ marginRight: 0 }}>
                    <Col>
                      <LoadingButton
                        label='変更する'
                        loadingLabel='変更中...'
                        color='info'
                        fill
                        simple
                        pullRight
                        loadingColor='red'
                        disabled={notice?.status === watchStatus}
                        onClick={() =>
                          onClickPublishAutoPublishNoticeButton(watchStatus)
                        }
                      />
                    </Col>
                  </Row>
                )}
              </>
            }
          />
        </Col>
      </Row>
    </>
  )
}

export default memo(AutoPublishNoticeActiveSettingCard)
