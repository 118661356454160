import React, { useEffect, useState } from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { useBooleanState, useApi } from '../../../../lib/hooks'
import { SubscriptionContentsResponse } from '../../../../types/api/subscription.d'
import { SubscriptionContentResource } from '../../../../types/resource/subscriptionContent'
import SubscriptionContentStatusChip from '../../../Chip/SubscriptionContentStatusChip'
import { Condition } from '../../type'
import ConditionFormItem from './ConditionFormItem'
import InstanceItem from './InstanceItem'
import InstanceFilterDialog from './InstanceFilterDialog'

type Props = {
  condition: Condition
  onSelectSubscriptionContent: (
    subscriptionContent: SubscriptionContentResource,
  ) => void
}

const SubscriptionContentFormItem = ({
  condition,
  onSelectSubscriptionContent,
}: Props): JSX.Element => {
  const [isModalOpen, openModal, closeModal] = useBooleanState(false)
  const { api, loading, response } = useApi<SubscriptionContentsResponse>()
  const [filterText, setFilterText] = useState<string>('')
  const subscriptionContents = (response?.subscription_contents || [])
    .map((item) => item.subscription_content)
    .filter(({ title }) => !filterText || title.indexOf(filterText) > -1)

  useEffect(() => {
    if (isModalOpen) {
      api.get('/subscription_contents')
    }
  }, [isModalOpen, api])

  return (
    <>
      <ConditionFormItem label='サブスク'>
        <Select
          readOnly
          fullWidth
          value='default'
          style={{ backgroundColor: '#fff' }}
          sx={{ '& > .MuiSelect-select': { width: 370 } }}
          onClick={openModal}>
          <MenuItem value='default'>
            {condition.subscriptionContent?.title || 'すべてのサブスク'}
          </MenuItem>
        </Select>
      </ConditionFormItem>

      <InstanceFilterDialog
        show={isModalOpen}
        loading={loading}
        items={subscriptionContents}
        placeholder='サブスク名を入力して検索...'
        emptyLabel={
          Boolean(filterText)
            ? `${filterText}を含むサブスクが見つかりません。`
            : 'サブスクがありません。'
        }
        renderItem={(subscriptionContent) => (
          <InstanceItem
            key={subscriptionContent.id}
            imageUrl={subscriptionContent.image_url}
            title={subscriptionContent.title}
            status={
              <SubscriptionContentStatusChip
                status={subscriptionContent.status}
              />
            }
            onClick={() => {
              onSelectSubscriptionContent(subscriptionContent)
              closeModal()
            }}
          />
        )}
        onHide={closeModal}
        onFilter={setFilterText}
      />
    </>
  )
}

export default SubscriptionContentFormItem
