import React from 'react'
import { Check } from 'phosphor-react'
import styled from '@emotion/styled'
import LoadingButton from '../../../components/CustomButton/LoadingButton'
import { usePcSizeFlag } from '../../../lib/hooks'

type Props = {
  loading: boolean
  onCreateQuestionnaire: (questionnaireId: number) => void
}

const StartPage = ({ loading, onCreateQuestionnaire }: Props): JSX.Element => {
  const isPcOrTablet = usePcSizeFlag()

  return (
    <StyledContainer>
      <div style={{ maxWidth: 350 }}>
        <StyledTitle>
          アンケートで顧客満足度を可視化し店舗の改善に活用しましょう
        </StyledTitle>

        <StyledNote>
          プッシュ通知と組み合わせたお知らせや、アンケートに回答するともらえるクーポンとして配信することで通常より高い回答率を期待することができます。
        </StyledNote>

        <StyledList>
          <StyledListItem>
            <Check size={20} style={{ color: '#3172A7' }} />
            顧客満足度調査アンケートの作成
          </StyledListItem>
          <StyledListItem>
            <Check size={20} style={{ color: '#3172A7' }} />
            他機能との連携やURL等によるアンケートの配信
          </StyledListItem>
          <StyledListItem>
            <Check size={20} style={{ color: '#3172A7' }} />
            顧客満足度の集計・ダッシュボードによる可視化
          </StyledListItem>
        </StyledList>

        <LoadingButton
          type='submit'
          label='アンケートを作成'
          loadingLabel='アンケートを作成...'
          color='info'
          fill
          onClick={onCreateQuestionnaire}
          loading={loading}
        />
      </div>

      <StyledStartImage
        hide={!isPcOrTablet}
        src={require('../../../assets/img/questionnaire_start_page.png')}
        alt='顧客満足度調査'
      />
    </StyledContainer>
  )
}

const StyledContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '40px 0',
})

const StyledStartImage = styled('img')<{ hide: boolean }>(({ hide }) => ({
  width: '50%',
  maxWidth: 550,
  marginLeft: 40,
  display: hide ? 'none' : 'block',
}))

const StyledTitle = styled('div')({
  fontSize: 22,
  fontWeight: 700,
  marginBottom: 12,
  lineHeight: '160%',
})
const StyledNote = styled('div')({
  fontSize: 14,
  marginBottom: 20,
  lineHeight: '160%',
})

const StyledList = styled('ul')({
  margin: 0,
  padding: 0,
})
const StyledListItem = styled('li')({
  fontSize: 14,
  listStyle: 'none',
  display: 'flex',
  alignItems: 'center',
  marginBottom: 16,
  '& svg': { marginRight: 4 },
})

export default StartPage
