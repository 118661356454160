import { useMemo, useState, useCallback } from 'react'
import { createContainer } from 'unstated-next'
import { activityLogApi, discountApi } from '../../lib/api'
import { formatDateExceptTime } from '../../lib/general'
import {
  ActionType,
  IllegalStatus,
  ContentType,
  ActivityLogContentResponse,
} from '../../types/activityLog'

export type DiscountLog = {
  id: number
  action_type: ActionType
  amount: number
  name: string
  content: DicountLogContent
  created_at: string
  instance_id: number
  status: 'active'
  store_id: number
  store_image_url: string
  store_name: string
  user_id: number
  user_name: string
  value: number
  illegal_status: IllegalStatus
  doubtful_reason_text: string
  user_avatar_url: string
  discount_amount: number
  is_discount: boolean
}

type DicountLogContent = {
  activity_log_id: number
  content_id: number
  created_at: string
  id: number
  image_url: string
  is_read: boolean
  title: string
  updated_at: string
}

export type ServiceType = 'discount' | 'free' | 'other'

export type ServiceTypeToJp = '円引き' | '無料交換' | 'その他'

export type DiscountContentsType = 'coupon' | 'ticket' | 'service'

export type DiscountContentsTypeToJp = 'クーポン' | 'チケット' | 'サービス'

export type DiscountLogFilteringCondition = {
  store_ids: number[]
  action_types: ActionType[]
  content_id: number | null
  status: 'active' | 'cancel'
  illegal_statuses: IllegalStatus[]
}

export type FilteringPeriod = {
  from: string | null
  to: string | null
}

const serviceTypeToJpObj = {
  discount: '円引き',
  free: '無料交換',
  other: 'その他',
} as const

const discountContentTypeToJpObj = {
  coupon: 'クーポン',
  ticket: 'チケット',
  service: 'サービス',
} as const

const illegalStatuses: IllegalStatus[] = [
  'not_check',
  'legal',
  'illegal',
  'doubtful',
]

const useDiscountEntityHook = () => {
  const [discountLogs, setDiscountLogs] = useState<DiscountLog[]>([])
  const [discountContents, setDiscountContents] = useState([])
  const [contents, setContents] = useState<ActivityLogContentResponse>({
    coupons: [],
    services: [],
    subscriptions: [],
    stamp_cards: [],
    tickets: [],
  })
  const actionTypes = {
    data: [
      'use_coupon',
      'use_ticket',
      'use_service',
      // "use_subscription",
      // "use_point",
    ],
    content: {
      use_coupon: 'coupon',
      use_ticket: 'ticket',
      use_service: 'service',
      // use_subscription: "subscription",
      // use_point: "point",
    },
    jp: {
      use_coupon: 'クーポン利用',
      use_ticket: 'チケット利用',
      use_service: 'サービス利用',
      // use_subscription: "サブスク利用",
      // use_point: "ポイント交換",
    },
  } as const

  // filtering
  const [filteringPeriod, setFilteringPeriod] = useState<FilteringPeriod>({
    from: `${formatDateExceptTime(new Date())} 00:00`,
    to: `${formatDateExceptTime(new Date())} 23:59`,
  })
  const [discountLogFilteringCondition, setDiscountLogFilteringCondition] =
    useState<DiscountLogFilteringCondition>({
      store_ids: [],
      action_types: [],
      content_id: null,
      status: 'active',
      illegal_statuses: [],
    })

  // 絞り込みしているか
  const isDiscountLogFiltering = useMemo(
    () =>
      !!discountLogFilteringCondition.store_ids.length ||
      !!discountLogFilteringCondition.action_types.length ||
      !!discountLogFilteringCondition.content_id ||
      discountLogFilteringCondition.status === 'cancel' ||
      !!discountLogFilteringCondition.illegal_statuses.length,
    [discountLogFilteringCondition],
  )

  // 期間を指定しているか
  const isDiscountPeriod = useMemo(
    () => !!filteringPeriod.from && !!filteringPeriod.to,
    [filteringPeriod],
  )

  // url
  const [urlParams, setUrlParams] = useState('')

  // page
  const [contentPage, setContentPage] = useState(1)
  const [logPage, setLogPage] = useState(1)

  return {
    state: {
      discountContents,
      contentPage,
      logPage,
      urlParams,
      filteringPeriod,
      discountLogs,
      actionTypes,
      contents,
      discountLogFilteringCondition,
      isDiscountLogFiltering,
      isDiscountPeriod,
    },
    constant: {
      illegalStatuses,
    },
    logic: {
      setContentPage,
      setLogPage,
      setUrlParams,
      setFilteringPeriod,
      setDiscountLogs,
      setDiscountLogFilteringCondition,
      getDiscountLogs: async (newUrlParams: string) => {
        let replacedUrlParams = newUrlParams.replace('log_page', 'page')
        replacedUrlParams += '&is_discount=true'
        const response = await activityLogApi.getActivityLogs(replacedUrlParams)
        setDiscountLogs(response.data)
        return response
      },
      getContents:
        useCallback(async (): Promise<ActivityLogContentResponse> => {
          const newContents = await activityLogApi.getContents()
          setContents(newContents)
          return newContents
        }, []),
      getDiscountContents: async (newUrlParams: string) => {
        const replacedUrlParams = newUrlParams.replace('content_page', 'page')
        const response =
          await discountApi.getDiscountContents(replacedUrlParams)
        setDiscountContents(response.data)
        return response
      },
      updateDicountLog: async ({ id, params }) => {
        const response = await activityLogApi.updateActivityLog({
          id,
          params,
        })
        return response
      },
      deleteDiscountLog: async (id, doCancelDependentLogs) => {
        const response = await activityLogApi.deleteActivityLog(
          id,
          doCancelDependentLogs,
        )
        return response
      },
      downloadDiscountLogsCsv: async (newUrlParams: string): Promise<void> => {
        const replacedUrlParams = `${newUrlParams}&is_discount=true`
        await activityLogApi.downloadActivityLogsCsv(replacedUrlParams)
      },
      actionTypeToContentType: (actionType: ActionType): ContentType =>
        actionTypes.content[actionType],
      serviceTypeToJp: (serviceType: ServiceType): ServiceTypeToJp =>
        serviceTypeToJpObj[serviceType],
      discountContentTypeToJp: (
        discountContentsType: DiscountContentsType,
      ): DiscountContentsTypeToJp =>
        discountContentTypeToJpObj[discountContentsType],
    },
  }
}

export default createContainer(useDiscountEntityHook)
