import React from 'react'
import { styled } from '@mui/material/styles'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { formatDate } from '../../../lib/general'
import { StaffBlock } from '../../../types/staff'

type Props = {
  staffs: StaffBlock[]
  onClickStaff: (staff: StaffBlock) => void
  onClickOption: (
    e: React.MouseEvent<HTMLButtonElement>,
    staff: StaffBlock,
  ) => void
}
const LaptopStaffList = ({
  staffs,
  onClickStaff,
  onClickOption,
}: Props): JSX.Element => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>名前</TableCell>
          <TableCell>メールアドレス</TableCell>
          <TableCell>所属オーナー</TableCell>
          <TableCell>作成日</TableCell>
          <TableCell>プッシュ通知</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {staffs.map((staff) => (
          <StyledLaptopRow
            hover
            onClick={() => onClickStaff(staff)}
            data-cy='staff-list-staff-row'>
            <TableCell style={{ fontWeight: 'bold' }}>
              {staff.staff.name}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {staff.staff.email}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {staff.groups.find((v) => v.store_type === 'owner_group')?.name ||
                '----'}
            </TableCell>
            <TableCell style={{ fontWeight: 'bold' }}>
              {staff.staff.created_at
                ? formatDate(staff.staff.created_at)
                : '----'}
            </TableCell>
            <TableCell>
              {staff.staff.push_token && <StyledChip>有効</StyledChip>}
            </TableCell>
            <TableCell width={50}>
              <IconButton
                sx={{ lineHeight: 1 }}
                size='small'
                onClick={(e) => {
                  e.stopPropagation()
                  onClickOption(e, staff)
                }}>
                <i className='ri-more-fill' />
              </IconButton>
            </TableCell>
          </StyledLaptopRow>
        ))}
      </TableBody>
    </Table>
  )
}

const StyledChip = styled('div')(({ theme }) => ({
  backgroundColor: '#00ddb4',
  display: 'inline',
  color: 'white',
  padding: theme.spacing(0.5, 1),
  border: 0,
  borderRadius: 6,
  fontWeight: 'bold',
  fontSize: 13,
  whiteSpace: 'nowrap',
}))

const StyledLaptopRow = styled(TableRow)({
  cursor: 'pointer',
  '& td': {
    fontWeight: 700,
  },
})

export default LaptopStaffList
