import React, { useCallback, useEffect } from 'react'
import { Row, Col, Table } from 'react-bootstrap'
import * as H from 'history'

// components
import { Pagination } from '@mui/material'
import styled from '@emotion/styled'
import { Card } from '../../../components/Card/Card'
import Button from '../../../components/CustomButton/CustomButton'
import SmallTableBody from '../../../components/Table/SmallTableBody'

// container
import { useApi, usePcSizeFlag } from '../../../lib/hooks'
import { CouponContentsResponse } from '../../../types/api/coupon'
import {
  AutoCouponContentStatusToJpObj,
  CouponStatusColors,
  CouponStatusType,
} from '../../../containers/entities/CouponContentEntityContainer'

import ContentSalesEffect from '../../Coupon/parts/ContentSalesEffect'

const StyledStatusLabel = styled.div<{ status: CouponStatusType }>`
  background-color: ${(props) => CouponStatusColors[props.status]};
  display: inline;
  color: white;
  white-space: nowrap;
  padding: 5px 6px;
  border: 0;
  border-radius: 6px;
  font-weight: bold;
`

type RankUpCouponContentsProps = {
  page?: number
}

type PropsType = {
  history: H.History
  rankId: number
}

const RankupCouponListTable: React.FC<PropsType> = ({ history, rankId }) => {
  const isPcOrTablet = usePcSizeFlag()
  const PAGE_LIMIT = isPcOrTablet ? 20 : 4

  const {
    api: rankUpCouponContentsGetApi,
    response: rankUpCouponContentsResponse,
    headers: rankUpCouponContentsResHeaders,
  } = useApi()

  const getRankUpCouponContents = useCallback(
    async (params: RankUpCouponContentsProps = {}) => {
      const page = params.page || 1

      await rankUpCouponContentsGetApi.get('/coupon_contents', {
        type: 'rank',
        rank_id: rankId,
        page: page,
        sort: 'created_at',
        order: 'desc',
        limit: PAGE_LIMIT,
      })
    },
    [rankUpCouponContentsGetApi, rankId, PAGE_LIMIT],
  )

  useEffect(() => {
    getRankUpCouponContents()
  }, [getRankUpCouponContents])

  const rankUpCouponContents: CouponContentsResponse['coupon_contents'] =
    rankUpCouponContentsResponse?.coupon_contents || []

  return (
    <div>
      <Row style={{ marginBottom: 10 }}>
        <Col xs={12} lg={10}>
          <Button
            pullRight
            bsStyle='info'
            fill
            onClick={() =>
              history.push('/admin/privilege_rank/new', { rankId })
            }>
            <b>ランクアップクーポンを作成する</b>
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={10}>
          <Card
            title='ランクアップクーポン一覧'
            content={
              <>
                <Table striped hover>
                  {rankUpCouponContents.length ? (
                    <>
                      {isPcOrTablet && (
                        <thead>
                          <tr>
                            <th />
                            <th>タイトル</th>
                            <StyledNoWrapTh>発行</StyledNoWrapTh>
                            <StyledNoWrapTh>閲覧</StyledNoWrapTh>
                            <StyledNoWrapTh>利用</StyledNoWrapTh>
                            <StyledNoWrapTh>売上効果</StyledNoWrapTh>
                            <StyledNoWrapTh>状態</StyledNoWrapTh>
                          </tr>
                        </thead>
                      )}
                      <tbody>
                        {rankUpCouponContents.map((item) => (
                          <tr
                            key={item.coupon_content.id}
                            style={{ fontWeight: 'bold', cursor: 'pointer' }}
                            onClick={() =>
                              history.push('/admin/privilege_rank/edit', {
                                privilegeCoupon: {
                                  ...item.qrcode,
                                  ...item.obtain_condition,
                                  ...item.coupon_content,
                                },
                              })
                            }>
                            {isPcOrTablet ? (
                              <>
                                <td style={{ width: 80 }}>
                                  {item.coupon_content.image_url && (
                                    <img
                                      alt='画像'
                                      src={item.coupon_content.image_url}
                                      width={48}
                                      height={30}
                                      style={{
                                        borderRadius: 2,
                                        objectFit: 'cover',
                                      }}
                                    />
                                  )}
                                </td>
                                <td>
                                  <div>
                                    <b>{item.coupon_content.benefit}</b>
                                  </div>
                                  {item.coupon_content.memo && (
                                    <StyledMemo>
                                      {item.coupon_content.memo}
                                    </StyledMemo>
                                  )}
                                </td>
                                <StyledNoWrapTd>
                                  {item.delivered_count}
                                </StyledNoWrapTd>
                                <StyledNoWrapTd>
                                  {item.read_count}
                                </StyledNoWrapTd>
                                <StyledNoWrapTd>
                                  {item.used_count}
                                </StyledNoWrapTd>
                                <StyledNoWrapTdSalesEffect>
                                  <ContentSalesEffect
                                    value={item.sales_effect}
                                  />
                                </StyledNoWrapTdSalesEffect>
                                <StyledNoWrapTd>
                                  <StyledStatusLabel
                                    status={item.coupon_content.status}>
                                    {
                                      AutoCouponContentStatusToJpObj[
                                        item.coupon_content.status
                                      ]
                                    }
                                  </StyledStatusLabel>
                                </StyledNoWrapTd>
                              </>
                            ) : (
                              <SmallTableBody
                                image={item.coupon_content.image_url}
                                title={item.coupon_content.benefit}
                                contents={[]}
                                status={
                                  <StyledStatusLabel
                                    status={item.coupon_content.status}>
                                    {
                                      AutoCouponContentStatusToJpObj[
                                        item.coupon_content.status
                                      ]
                                    }
                                  </StyledStatusLabel>
                                }
                                memo={item.coupon_content.memo}
                              />
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </>
                  ) : (
                    <thead style={{ textAlign: 'center' }}>
                      <tr>
                        <td>
                          <b>表示できるランクアップクーポンがありません</b>
                        </td>
                      </tr>
                    </thead>
                  )}
                </Table>
              </>
            }
            stats={
              rankUpCouponContentsResHeaders &&
              Number(rankUpCouponContentsResHeaders['total-pages']) > 1 ? (
                <Pagination
                  count={Number(rankUpCouponContentsResHeaders['total-pages'])}
                  page={Number(rankUpCouponContentsResHeaders['current-page'])}
                  onChange={(_, value) =>
                    getRankUpCouponContents({ page: value })
                  }
                  sx={{ fontSize: 24, color: 'black' }}
                />
              ) : null
            }
          />
        </Col>
      </Row>
    </div>
  )
}

const StyledNoWrapTh = styled('th')({ whiteSpace: 'nowrap' })
const StyledNoWrapTd = styled('td')({ whiteSpace: 'nowrap' })
const StyledMemo = styled('div')({
  fontSize: 12,
  color: '#787878',
})
const StyledNoWrapTdSalesEffect = styled('td')({
  whiteSpace: 'nowrap',
  backgroundColor: '#EFF8F7',
})

export default RankupCouponListTable
