import apiClient from './apiClient'
import { loadViewObj } from '../viewObj'

export const createMembersCardContent = async ({ params }) => {
  const CREATE_MEMBERS_CARD_CONTENT = '/members_card_contents'

  const requestParams = {
    members_card_content: params,
  }

  const response = await apiClient({
    method: 'POST',
    uri: CREATE_MEMBERS_CARD_CONTENT,
    params: requestParams,
  })
  return response.data
}

export const getMembersCardContent = async () => {
  const GET_MEMBERS_CARD_CONTENT = `/staff/stores/${
    loadViewObj()?.id
  }/members_card_contents`

  const response = await apiClient({
    method: 'GET',
    uri: GET_MEMBERS_CARD_CONTENT,
  })
  return response.data
}

export const updateMembersCardContent = async ({ params }) => {
  const UPDATE_MEMBERS_CARD_CONTENT = `/staff/stores/${
    loadViewObj()?.id
  }/members_card_contents`

  const requestParams = {
    members_card_content: params,
  }

  const response = await apiClient({
    method: 'PATCH',
    uri: UPDATE_MEMBERS_CARD_CONTENT,
    params: requestParams,
  })
  return response.data
}

export const updateMembersCard = async ({ id, params }) => {
  const GIVE_MEMBERS_POINTS = `/staff/stores/${
    loadViewObj()?.id
  }/members_cards/${id}`

  const requestParams = {
    members_card: params,
  }

  const response = await apiClient({
    method: 'PUT',
    uri: GIVE_MEMBERS_POINTS,
    params: requestParams,
  })
  return response.data
}
