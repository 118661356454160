import React from 'react'
import { styled } from '@mui/system'
import MuiRating from '@mui/material/Rating'
import StarIcon from '@mui/icons-material/Star'

type Props = {
  good: boolean
  value: number
  size: number
}

const Rating = ({ good, value, size }: Props): JSX.Element => (
  <StyledRating
    readOnly
    value={value}
    good={good}
    iconSize={size}
    precision={0.1}
    emptyIcon={<StarIcon style={{ color: '#E8E8E8', fontSize: 'inherit' }} />}
  />
)

const StyledRating = styled(MuiRating, {
  shouldForwardProp: (prop: string) => !['good', 'iconSize'].includes(prop),
})<{ good: boolean; iconSize: number }>(({ good, iconSize }) => ({
  fontSize: iconSize,
  color: good ? '#FECD00' : '#E94C4C',
}))

export default Rating
