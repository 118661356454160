import apiClient from './apiClient'
import { loadViewObj } from '../viewObj'

export const getConnection = async (userId) => {
  const GET_CONNECTION = `/staff/stores/${
    loadViewObj()?.id
  }/connections/${userId}`

  const response = await apiClient({
    method: 'GET',
    uri: GET_CONNECTION,
  })
  return response
}

export const updateConnection = async ({ userId, params }) => {
  const UPDATE_CONNECTION = `/staff/stores/${
    loadViewObj()?.id
  }/connections/${userId}`

  const requestParams = {
    connection: params,
  }

  const response = await apiClient({
    method: 'PATCH',
    uri: UPDATE_CONNECTION,
    params: requestParams,
  })

  return response
}
