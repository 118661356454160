import React from 'react'
import { PlusOutlined, CheckOutlined } from '@ant-design/icons'

interface Props {
  title: string
  description: string
  icon: JSX.Element
  color: string
  showMoreInfoLink: boolean
  onClickDetail: () => void
  onClickAddButton: () => void
  onClickRemoveButton: () => void
  active: boolean
  note?: string
}

const FeatureCard: React.FC<Props> = ({
  title,
  description,
  icon,
  color,
  showMoreInfoLink,
  onClickDetail,
  onClickAddButton,
  onClickRemoveButton,
  active,
  note,
}) => (
  <div
    style={{
      boxShadow: 'rgba(0, 0, 0, 0.1) 2px 2px 20px',
      flexShrink: 0,
      width: 250,
      backgroundColor: 'white',
      padding: 16,
      borderRadius: 16,
    }}>
    <div
      style={{
        fontSize: 24,
        backgroundColor: color,
        color: 'white',
        padding: 8,
        display: 'inline-flex',
        borderRadius: 10,
      }}>
      {icon}
    </div>

    <div style={{ marginTop: 16, fontWeight: 'bold', fontSize: 16 }}>
      {title}
    </div>

    <div style={{ marginTop: 5 }}>{description}</div>

    {note && (
      <div style={{ color: '#757575', fontSize: 13, marginTop: 5 }}>{note}</div>
    )}

    {showMoreInfoLink && (
      <div
        style={{ marginTop: 5, color: '#0076D7', cursor: 'pointer' }}
        onClick={onClickDetail}>
        詳しく見る
      </div>
    )}

    {active ? (
      <div
        style={{
          marginTop: 20,
          backgroundColor: '#C2C2C2',
          color: 'white',
          display: 'inline-flex',
          alignItems: 'center',
          padding: '10px 16px',
          borderRadius: 45,
          cursor: 'pointer',
          fontWeight: 'bold',
        }}
        onClick={onClickRemoveButton}>
        <CheckOutlined style={{ marginRight: 6 }} /> 追加済み
      </div>
    ) : (
      <div
        style={{
          marginTop: 20,
          backgroundColor: '#000',
          color: 'white',
          display: 'inline-flex',
          alignItems: 'center',
          padding: '10px 16px',
          borderRadius: 45,
          cursor: 'pointer',
          fontWeight: 'bold',
        }}
        onClick={onClickAddButton}>
        <PlusOutlined style={{ marginRight: 6 }} /> 追加する
      </div>
    )}
  </div>
)

export default FeatureCard
